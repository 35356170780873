import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FilterData, FilterListDataExtended, TrialistSummaryResult } from 'src/app/shared/model/trial-list';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { SurveyResultsService } from 'src/app/shared/services/survey-results.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ChartComponent } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-trial-comorbidity',
  templateUrl: './trial-comorbidity.component.html',
  styleUrls: ['./trial-comorbidity.component.scss']
})
export class TrialComorbidityComponent implements OnInit {

  @ViewChild('chartComorbidities')
  chartComorbidities: ChartComponent = Object.create(null);
  @ViewChild('chartComedication')
  chartComedication: ChartComponent = Object.create(null);
  @ViewChild('chartAntiretroviral')
  chartAntiretroviral: ChartComponent = Object.create(null);

  public chartComorbiditiesOptions;
  public chartComedicationOptions;
  public chartAntiretroviralOptions;

  filterListDataExtended: FilterListDataExtended;

  data: TrialistSummaryResult = {
    total: 0,
    countries: null,
    states: null,
    cities: null,
    organizations: null,
    centers: null,
    summaryDataList: [],
    suppressedDataList: [],
    enrolledDataList: [],
    antiretroviralDataList: [],
    comedicationDataList: [],
    comorbiditiesDataList: [],
    mapSuppressedStatus: null,
    mapTreatmentStatus: null,
    mapGender: null,
    mapRace: null,
    mapType: null,
    mapAdherence: null,
    dataListMap: null,
    dataMapper: null
  };

  dropdownListCountry = [];
  selectedItemsCountry = [];
  dropdownSettingsCountry: IDropdownSettings = {};
  dropDownSelectCountry = false;

  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState: IDropdownSettings = {};
  dropDownSelectState = false;

  dropdownListCentre = [];
  selectedItemsCentre = [];
  dropdownSettingsCentre: IDropdownSettings = {};
  dropDownSelectCentre = false;

  filterDataList: Array<FilterData>;

  patientCount = 0;
  countries = new FormControl();
  centres = new FormControl();
  states = new FormControl();

  constructor(
    private router: Router,
    private storageService: StorageService,
    public surveyResultsService: SurveyResultsService,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {

    this.dropdownSettingsCountry = {
      singleSelection: false,
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      limitSelection: 10,
      allowSearchFilter: false
    };

    this.dropdownSettingsCentre = {
      singleSelection: false,
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      limitSelection: 10,
      allowSearchFilter: false
    };

    this.dropdownSettingsState = {
      singleSelection: false,
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      limitSelection: 10,
      allowSearchFilter: false
    };

    this.filterListDataExtended = JSON.parse(JSON.stringify(this.storageService.getTrialFilter()));

    if (!this.filterListDataExtended) {
      this.router.navigate(['smarthiv-trialist/trial-filter']);
      this.notificationService.error(this.translate.instant('TRIAL.SELECT_FILTERS'));
    }

    this.surveyResultsService.getTrialistComorbiditiesResults(this.filterListDataExtended.filterListData).subscribe(data => {
      if (this.filterListDataExtended.filterListData.countries) {
        this.dropdownListCountry = this.filterListDataExtended.allCountries;
        this.selectedItemsCountry = this.filterListDataExtended.selectedCountries;
        this.dropdownListState = this.filterListDataExtended.allStates;
        this.selectedItemsState = this.filterListDataExtended.selectedStates;
      } else {
        this.dropdownListCountry = data.summaryDataList;
      }

      this.patientCount = data.total;
      this.data = data;
      this.setCharts();
    });

  }

  setCharts() {

    const comedicationData: number[] = [];
    const comedicationNameData: string[] = [];

    const comorbiditiesData: number[] = [];
    const comorbiditiesNameData: string[] = [];

    const antiretroviralData: number[] = [];
    const antiretroviralNameData: string[] = [];

    this.data.comedicationDataList.forEach(summaryData => {
      comedicationData.push(parseFloat(((summaryData.count * 100) / this.patientCount).toFixed(2)));
      comedicationNameData.push(summaryData.name.split(' (')[0]);
    });

    this.data.comorbiditiesDataList.forEach(summaryData => {
      comorbiditiesData.push(parseFloat(((summaryData.count * 100) / this.patientCount).toFixed(2)));
      comorbiditiesNameData.push(summaryData.name);
    });

    this.data.antiretroviralDataList.forEach(summaryData => {
      antiretroviralData.push(summaryData.count);
      antiretroviralNameData.push(summaryData.name);
    });

    this.chartComedicationOptions = {
      series: [
        {
          name: this.translate.instant('TRIAL.PATIENTS_PERCENT'),
          data: comedicationData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        stacked: true,
        height: 347
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: false,
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      colors: [
        '#008efb',
        '#00e397',
        '#feb219',
        '#ff4561',
        '#785dd0',
        '#6b0914',
        '#f9a3a4',
        '#421243',
        '#7F94B0',
        '#EF6537',
        '#C0ADDB'
      ],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        title: {
          text: this.translate.instant('TRIAL.COMEDICATION')
        },
        categories: comedicationNameData
      },
      yaxis: {
        title: {
          text: this.translate.instant('TRIAL.PATIENTS_PERCENT')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartComorbiditiesOptions = {
      series: [
        {
          name: this.translate.instant('TRIAL.PATIENTS_PERCENT'),
          data: comorbiditiesData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        stacked: true,
        height: 347
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: false,
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      colors: [
        '#008efb',
        '#00e397',
        '#feb219',
        '#ff4561',
        '#785dd0',
        '#6b0914',
        '#f9a3a4',
        '#421243',
        '#7F94B0',
        '#EF6537',
        '#C0ADDB'
      ],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        title: {
          text: this.translate.instant('TRIAL.COMORBITIES')
        },
        categories: comorbiditiesNameData
      },
      yaxis: {
        title: {
          text: this.translate.instant('TRIAL.PATIENTS_PERCENT')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartAntiretroviralOptions = {
      series: [
        {
          name: this.translate.instant('TRIAL.PATIENTS'),
          data: antiretroviralData
        }
      ],
      chart: {
        type: 'bar',
        height: 380
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      colors: [
        '#008efb',
        '#00e397',
        '#feb219',
        '#ff4561',
        '#785dd0',
        '#6b0914',
        '#f9a3a4',
        '#421243',
        '#7F94B0',
        '#EF6537',
        '#C0ADDB'
      ],
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#fff']
        },
        offsetX: 0
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        title: {
          text: this.translate.instant('TRIAL.ANTIRETROVIRAL')
        },
        categories: antiretroviralNameData
      },
      yaxis: {
        labels: {
          show: false
        },
        title: {
          text: this.translate.instant('TRIAL.PATIENTS')
        }
      }
    };

  }

  onDeSelectCountry(item: any) {
  }

  onDeSelectState(item: any) {
  }

  onDeSelectCentre(item: any) {
  }

  onItemSelect(item: any) {
  }

  onItemSelectCountry(item: any) {
  }

  onItemSelectState(item: any) {
  }

  onItemSelectCentre(item: any) {
  }

  onDropDownCloseCountry() {
    if (this.dropDownSelectCountry) {
      const countries = [];
      this.selectedItemsCountry.forEach(country => {
        countries.push(country.name);
      });
      this.filterListDataExtended.filterListData.countries = countries;
      this.filterListDataExtended.filterListData.states = [];
      this.dropdownListState = [];
      this.selectedItemsState = [];

      this.filterListDataExtended.allCountries = this.dropdownListCountry;
      this.filterListDataExtended.selectedCountries = this.selectedItemsCountry;

      this.filterListDataExtended.allStates = this.dropdownListState;
      this.filterListDataExtended.selectedStates = this.selectedItemsState;

      this.storageService.setTrialFilter(JSON.stringify(this.filterListDataExtended));
      this.surveyResultsService.getTrialistComorbiditiesResults(this.filterListDataExtended.filterListData).subscribe(data => {
        if (this.selectedItemsCountry.length > 0) {
          this.dropdownListState = data.summaryDataList;
        } else {
          this.dropdownListState = [];
          this.selectedItemsState = [];
        }
        this.patientCount = data.total;
        this.data = data;
        if (countries.length > 0) {
          this.setCharts();
        } else {
          this.setCharts();
        }
      });
    }
    this.dropDownSelectCountry = false;
  }

  onDropDownCloseState() {
    if (this.dropDownSelectState) {
      const states = [];
      this.selectedItemsState.forEach(state => {
        states.push(state.name);
      });
      this.filterListDataExtended.filterListData.states = states;

      this.filterListDataExtended.allCountries = this.dropdownListCountry;
      this.filterListDataExtended.selectedCountries = this.selectedItemsCountry;

      this.filterListDataExtended.allStates = this.dropdownListState;
      this.filterListDataExtended.selectedStates = this.selectedItemsState;

      this.storageService.setTrialFilter(JSON.stringify(this.filterListDataExtended));
      this.surveyResultsService.getTrialistComorbiditiesResults(this.filterListDataExtended.filterListData).subscribe(data => {
        this.patientCount = data.total;
        this.data = data;
        this.setCharts();
      });
    }
    this.dropDownSelectState = false;
  }

  onDropDownCloseCentre() {
    this.dropDownSelectCentre = false;
  }

}
