import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ChartComponent } from 'ng-apexcharts';
import { Resource, Simulation } from 'src/app/shared/model/simulation';
import {
  ColumnDefintion,
  Pair, SimulationResultResourceListResponse,
  SimulationResultResourceResponse
} from 'src/app/shared/model/simulation-result';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { SimulationService } from 'src/app/shared/services/simulation.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-hr-planning-management',
  templateUrl: './hr-planning-management.component.html',
  styleUrls: ['./hr-planning-management.component.scss']
})
export class HrPlanningManagementComponent implements OnInit {

  @ViewChild('chartStaff')
  chartStaff: ChartComponent = Object.create(null);
  @ViewChild('chartStaffSc')
  chartStaffSc: ChartComponent = Object.create(null);

  @ViewChild('chartYearStaff')
  chartYearStaff: ChartComponent = Object.create(null);
  @ViewChild('chartYearStaffSc')
  chartYearStaffSc: ChartComponent = Object.create(null);

  @ViewChild('chartDoctor')
  chartDoctor: ChartComponent = Object.create(null);
  @ViewChild('chartPharmacist')
  chartPharmacist: ChartComponent = Object.create(null);
  @ViewChild('chartMedicalLabScientist')
  chartMedicalLabScientist: ChartComponent = Object.create(null);
  @ViewChild('chartHivSpecialistNurse')
  chartHivSpecialistNurse: ChartComponent = Object.create(null);
  @ViewChild('chartDietician')
  chartDietician: ChartComponent = Object.create(null);
  @ViewChild('chartGeneralNurse')
  chartGeneralNurse: ChartComponent = Object.create(null);
  @ViewChild('chartCounselor')
  chartCounselor: ChartComponent = Object.create(null);
  @ViewChild('chartShortage')
  chartShortage: ChartComponent = Object.create(null);

  @ViewChild('chartDoctorTotal')
  chartDoctorTotal: ChartComponent = Object.create(null);
  @ViewChild('chartPharmacistTotal')
  chartPharmacistTotal: ChartComponent = Object.create(null);
  @ViewChild('chartMedicalLabScientistTotal')
  chartMedicalLabScientistTotal: ChartComponent = Object.create(null);
  @ViewChild('chartHivSpecialistNurseTotal')
  chartHivSpecialistNurseTotal: ChartComponent = Object.create(null);
  @ViewChild('chartDieticianTotal')
  chartDieticianTotal: ChartComponent = Object.create(null);
  @ViewChild('chartGeneralNurseTotal')
  chartGeneralNurseTotal: ChartComponent = Object.create(null);
  @ViewChild('chartCounselorTotal')
  chartCounselorTotal: ChartComponent = Object.create(null);
  @ViewChild('chartShortageTotal')
  chartShortageTotal: ChartComponent = Object.create(null);

  @ViewChild('chartPieAnnual')
  chartPieAnnual: ChartComponent = Object.create(null);
  @ViewChild('chartPieAnnualService')
  chartPieAnnualService: ChartComponent = Object.create(null);

  public chartStaffOptions;
  public chartStaffScOptions;

  public chartYearStaffOptions;
  public chartYearStaffScOptions;

  public chartDoctorOptions;
  public chartPharmacistOptions;
  public chartMedicalLabScientistOptions;
  public chartHivSpecialistNurseOptions;
  public chartDieticianOptions;
  public chartGeneralNurseOptions;
  public chartCounselorOptions;
  public chartShortageOptions;

  public chartDoctorTotalOptions;
  public chartPharmacistTotalOptions;
  public chartMedicalLabScientistTotalOptions;
  public chartHivSpecialistNurseTotalOptions;
  public chartDieticianTotalOptions;
  public chartGeneralNurseTotalOptions;
  public chartCounselorTotalOptions;
  public chartShortageTotalOptions;

  public chartPieAnnualOptions;
  public chartPieAnnualServiceOptions;

  doctorHours = 0;
  pharmacistHours = 0;
  medicalLabScientistHours = 0;
  hivSpecialistNurseHours = 0;
  dieticianHours = 0;
  generalNurseHours = 0;
  counselorHours = 0;
  totalHours = 0;

  doctorAvailable = 0;
  doctorRequired = 0;
  hivNurseAvailable = 0;
  hivNurseRequired = 0;
  counsellorAvailable = 0;
  counsellorRequired = 0;

  doctorHoursSc = 0;
  pharmacistHoursSc = 0;
  medicalLabScientistHoursSc = 0;
  hivSpecialistNurseHoursSc = 0;
  dieticianHoursSc = 0;
  generalNurseHoursSc = 0;
  counselorHoursSc = 0;
  totalHoursSc = 0;

  doctorAvailableSc = 0;
  doctorRequiredSc = 0;
  hivNurseAvailableSc = 0;
  hivNurseRequiredSc = 0;
  counsellorAvailableSc = 0;
  counsellorRequiredSc = 0;

  childrenHours = 0;
  adolescentsHours = 0;
  adultsHours = 0;
  olderAdultsHours = 0;

  diagnosis = 0;
  prevention = 0;
  monitoring = 0;
  counselling = 0;

  layout = 'raw';
  layoutSize = '50';

  colDefinitions = [];

  displayedColumns: string[];
  dataSource: any[];
  dataSourceSc: any[];
  dataSourceOptimum: any[];
  dataSourceOptimumSc: any[];

  simulation: Simulation;
  scenario: Simulation;

  years;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private notificationService: NotificationService,
    public simulationService: SimulationService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    const result = JSON.parse(this.storageService.getSimulationResult());

    if (!result) {
      this.router.navigate(['smarthiv-manager/manager-form']);
      this.notificationService.error(this.translate.instant('SIMULATION.RUN_MODEL_EXCEPTION'));
    }

    this.simulation = JSON.parse(this.storageService.getSimulation());
    this.scenario = JSON.parse(this.storageService.getScenario());

    this.simulationService.getSimulationResultResources(result).subscribe(data => {
      this.years = data.resources[0].years;

      if (this.years > 5) {
        this.layout = 'column';
        this.layoutSize = '100';
      }
      this.setResourcesLabels(data);
      this.setResourcesCharts(
        this.getAverage(this.getArray(data.resources, 'patient', true)),
        this.getAverage(this.getArray(data.resources, 'patient', false)),
        this.getAverage(this.getArray(data.resources, 'demand', true)),
        this.getAverage(this.getArray(data.resources, 'demand', false)),
        this.getAverage(this.getArray(data.resources, 'counsel', true)),
        this.getAverage(this.getArray(data.resources, 'counsel', false)));
      this.setStaffs();
    }, (err) => {
      console.log('Error on get simulation. err : ' + err);
      this.notificationService.error('Error on get simulation');
    });
  }

  getOptimalStaffs(resource: Resource, annualWorkload: number, utilization: number, showLogs: boolean) {
    const offDays = (resource.daysOffPublicHolidays + resource.daysOffAnnualLeave + resource.daysOffSickLeave + resource.daysOffOtherLeave);
    const awt = (resource.workingDaysWeek * 52) - offDays;
    const awtHours = awt * resource.averageWorkingHoursDay;
    const cas = (resource.daysOtherDutiesWeek * 52) / awt;
    const caf = 1 / (1 - cas);
    const iaf = (resource.daysLeadActivitiesMonth * 12) / awt;
    const baselineStaffRequirement = annualWorkload / (awtHours * (resource.allocation / 100));
    const requiredStaff = (baselineStaffRequirement * caf) + iaf;
    const optimalStaff = this.getRoundedValue(requiredStaff) * utilization;
    const roundedOptimalStaff = this.getRoundedValue(optimalStaff);
    if (!showLogs) {
      console.log('annualWorkload : ' + annualWorkload);
      console.log('resource.staffNumber : ' + resource.staffNumber);
      console.log('offDays : ' + offDays);
      console.log('awt : ' + awt);
      console.log('awtHours : ' + awtHours);
      console.log('resource.daysOtherDutiesWeek : ' + resource.daysOtherDutiesWeek);
      console.log('cas : ' + cas);
      console.log('caf : ' + caf);
      console.log('iaf : ' + iaf);
      console.log('baselineStaffRequirement : ' + baselineStaffRequirement);
      console.log('requiredStaff : ' + requiredStaff);
      console.log('utilization : ' + utilization);
      console.log('optimalStaff : ' + optimalStaff);
      console.log('roundedOptimalStaff : ' + roundedOptimalStaff);
    }
    return Math.round(roundedOptimalStaff);
  }

  getRoundedValue(staff: number) {
    if (staff <= 0) {
      return 0;
    } else if (staff >= 1 || staff <= 6) {
      if (staff >= 1 && staff <= 1.1) {
        return 1;
      } else if (staff > 1.1 && staff < 2) {
        return 2;
      } else if (staff >= 2 && staff <= 2.2) {
        return 2;
      } else if (staff > 2.2 && staff < 3) {
        return 3;
      } else if (staff >= 3 && staff <= 3.3) {
        return 3;
      } else if (staff > 3.3 && staff < 4) {
        return 4;
      } else if (staff >= 4 && staff <= 4.4) {
        return 4;
      } else if (staff > 4.4 && staff < 5) {
        return 5;
      } else if (staff >= 5 && staff <= 5.5) {
        return 5;
      } else if (staff > 5.5 && staff <= 6) {
        return 6;
      }
    }
    return Math.ceil(staff);

  }

  setStaffs() {

    const types: string[] = [];

    types.push(this.translate.instant('SIMULATION.STAFF.DOCTOR_NAME'));
    types.push(this.translate.instant('SIMULATION.STAFF.DOCTOR_NAME'));
    types.push(this.translate.instant('SIMULATION.STAFF.PHARMACIST_NAME'));
    types.push(this.translate.instant('SIMULATION.STAFF.PHARMACIST_NAME'));
    types.push(this.translate.instant('SIMULATION.STAFF.LAB_NAME'));
    types.push(this.translate.instant('SIMULATION.STAFF.LAB_NAME'));
    types.push(this.translate.instant('SIMULATION.STAFF.HIV_NURSE_NAME'));
    types.push(this.translate.instant('SIMULATION.STAFF.HIV_NURSE_NAME'));
    types.push(this.translate.instant('SIMULATION.STAFF.DIETICIAN_NAME'));
    types.push(this.translate.instant('SIMULATION.STAFF.DIETICIAN_NAME'));
    types.push(this.translate.instant('SIMULATION.STAFF.GENERAL_NURSE_NAME'));
    types.push(this.translate.instant('SIMULATION.STAFF.GENERAL_NURSE_NAME'));
    types.push(this.translate.instant('SIMULATION.STAFF.COUNSELLOR_NAME'));
    types.push(this.translate.instant('SIMULATION.STAFF.COUNSELLOR_NAME'));

    const definitions: ColumnDefintion[] = [
      { label: this.translate.instant('SIMULATION.STAFF.TYPE'), sticky: true, position: 0 },
      { label: this.translate.instant('SIMULATION.STAFF.CURRENT'), sticky: false, position: 1 },
      { label: this.translate.instant('SIMULATION.STAFF.REQUIRED_STARRED'), sticky: false, position: 2 },
      { label: this.translate.instant('SIMULATION.STAFF.LEVEL'), sticky: false, position: 3 },
      { label: this.translate.instant('SIMULATION.STAFF.WORKFORCE'), sticky: false, position: 4 },
      { label: this.translate.instant('SIMULATION.STAFF.WISN_RATIO'), sticky: false, position: 5 },
      { label: this.translate.instant('SIMULATION.STAFF.PRESSURE'), sticky: false, position: 6 }
    ];

    const elemenData: string[][] = [];
    const doctorDataTable: string[] = [this.translate.instant('SIMULATION.STAFF.DOCTOR_NAME')];
    const pharmacistDataTable: string[] = [this.translate.instant('SIMULATION.STAFF.PHARMACIST_NAME')];
    const medicalLabScientistDataTable: string[] = [this.translate.instant('SIMULATION.STAFF.LAB_NAME')];
    const hivSpecialistNurseDataTable: string[] = [this.translate.instant('SIMULATION.STAFF.HIV_NURSE_NAME')];
    const dieticianDataTable: string[] = [this.translate.instant('SIMULATION.STAFF.DIETICIAN_NAME')];
    const generalNurseDataTable: string[] = [this.translate.instant('SIMULATION.STAFF.GENERAL_NURSE_NAME')];
    const counselorDataTable: string[] = [this.translate.instant('SIMULATION.STAFF.COUNSELLOR_NAME')];

    const elemenDataSc: string[][] = [];
    const doctorDataTableSc: string[] = [this.translate.instant('SIMULATION.STAFF.DOCTOR_NAME')];
    const pharmacistDataTableSc: string[] = [this.translate.instant('SIMULATION.STAFF.PHARMACIST_NAME')];
    const medicalLabScientistDataTableSc: string[] = [this.translate.instant('SIMULATION.STAFF.LAB_NAME')];
    const hivSpecialistNurseDataTableSc: string[] = [this.translate.instant('SIMULATION.STAFF.HIV_NURSE_NAME')];
    const dieticianDataTableSc: string[] = [this.translate.instant('SIMULATION.STAFF.DIETICIAN_NAME')];
    const generalNurseDataTableSc: string[] = [this.translate.instant('SIMULATION.STAFF.GENERAL_NURSE_NAME')];
    const counselorDataTableSc: string[] = [this.translate.instant('SIMULATION.STAFF.COUNSELLOR_NAME')];

    const elemenDataOptimum: string[][] = [];
    const doctorDataTableOptimum: string[] = [this.translate.instant('SIMULATION.STAFF.DOCTOR_NAME')];
    const pharmacistDataTableOptimum: string[] = [this.translate.instant('SIMULATION.STAFF.PHARMACIST_NAME')];
    const medicalLabScientistDataTableOptimum: string[] = [this.translate.instant('SIMULATION.STAFF.LAB_NAME')];
    const hivSpecialistNurseDataTableOptimum: string[] = [this.translate.instant('SIMULATION.STAFF.HIV_NURSE_NAME')];
    const dieticianDataTableOptimum: string[] = [this.translate.instant('SIMULATION.STAFF.DIETICIAN_NAME')];
    const generalNurseDataTableOptimum: string[] = [this.translate.instant('SIMULATION.STAFF.GENERAL_NURSE_NAME')];
    const counselorDataTableOptimum: string[] = [this.translate.instant('SIMULATION.STAFF.COUNSELLOR_NAME')];

    const elemenDataOptimumSc: string[][] = [];
    const doctorDataTableOptimumSc: string[] = [this.translate.instant('SIMULATION.STAFF.DOCTOR_NAME')];
    const pharmacistDataTableOptimumSc: string[] = [this.translate.instant('SIMULATION.STAFF.PHARMACIST_NAME')];
    const medicalLabScientistDataTableOptimumSc: string[] = [this.translate.instant('SIMULATION.STAFF.LAB_NAME')];
    const hivSpecialistNurseDataTableOptimumSc: string[] = [this.translate.instant('SIMULATION.STAFF.HIV_NURSE_NAME')];
    const dieticianDataTableOptimumSc: string[] = [this.translate.instant('SIMULATION.STAFF.DIETICIAN_NAME')];
    const generalNurseDataTableOptimumSc: string[] = [this.translate.instant('SIMULATION.STAFF.GENERAL_NURSE_NAME')];
    const counselorDataTableOptimumSc: string[] = [this.translate.instant('SIMULATION.STAFF.COUNSELLOR_NAME')];

    const shortageData: number[] = [];
    const shortageDataSc: number[] = [];

    this.simulation.resources.forEach(resource => {
      if (resource.resourceType === 'DOCTOR') {
        this.addToTableData(doctorDataTable, resource, this.doctorHours.toFixed(0), 1);
        this.doctorAvailable = parseInt(doctorDataTable[1], 10);
        this.addToTableData(doctorDataTableOptimum, resource, this.doctorHours.toFixed(0), 10 / 8);
        this.doctorRequired = parseInt(doctorDataTableOptimum[2], 10);
      } else if (resource.resourceType === 'PHARMACIST') {
        this.addToTableData(pharmacistDataTable, resource, this.pharmacistHours.toFixed(0), 1);
        this.addToTableData(pharmacistDataTableOptimum, resource, this.pharmacistHours.toFixed(0), 10 / 8);
      } else if (resource.resourceType === 'MEDICAL_LAB_SCIENTIST') {
        this.addToTableData(medicalLabScientistDataTable, resource, this.medicalLabScientistHours.toFixed(0), 1);
        this.addToTableData(medicalLabScientistDataTableOptimum, resource, this.medicalLabScientistHours.toFixed(0), 10 / 8);
      } else if (resource.resourceType === 'HIV_SPECIALIST_NURSE') {
        this.addToTableData(hivSpecialistNurseDataTable, resource, this.hivSpecialistNurseHours.toFixed(0), 1);
        this.hivNurseAvailable = parseInt(hivSpecialistNurseDataTable[1], 10);
        this.addToTableData(hivSpecialistNurseDataTableOptimum, resource, this.hivSpecialistNurseHours.toFixed(0), 10 / 8);
        this.hivNurseRequired = parseInt(hivSpecialistNurseDataTableOptimum[2], 10);
      } else if (resource.resourceType === 'DIETICIAN') {
        this.addToTableData(dieticianDataTable, resource, this.dieticianHours.toFixed(0), 1);
        this.addToTableData(dieticianDataTableOptimum, resource, this.dieticianHours.toFixed(0), 10 / 8);
      } else if (resource.resourceType === 'GENERAL_NURSE') {
        this.addToTableData(generalNurseDataTable, resource, this.generalNurseHours.toFixed(0), 1);
        this.addToTableData(generalNurseDataTableOptimum, resource, this.generalNurseHours.toFixed(0), 10 / 8);
      } else if (resource.resourceType === 'COUNSELOR') {
        this.addToTableData(counselorDataTable, resource, this.counselorHours.toFixed(0), 1);
        this.counsellorAvailable = parseInt(counselorDataTable[1], 10);
        this.addToTableData(counselorDataTableOptimum, resource, this.counselorHours.toFixed(0), 10 / 8);
        this.counsellorRequired = parseInt(counselorDataTableOptimum[2], 10);
      }
    });

    this.scenario.resources.forEach(resource => {
      if (resource.resourceType === 'DOCTOR') {
        this.addToTableData(doctorDataTableSc, resource, this.doctorHoursSc.toFixed(0), 1);
        this.doctorAvailableSc = parseInt(doctorDataTableSc[1], 10);
        this.addToTableData(doctorDataTableOptimumSc, resource, this.doctorHoursSc.toFixed(0), 10 / 8);
        this.doctorRequiredSc = parseInt(doctorDataTableOptimumSc[2], 10);
      } else if (resource.resourceType === 'PHARMACIST') {
        this.addToTableData(pharmacistDataTableSc, resource, this.pharmacistHoursSc.toFixed(0), 1);
        this.addToTableData(pharmacistDataTableOptimumSc, resource, this.pharmacistHoursSc.toFixed(0), 10 / 8);
      } else if (resource.resourceType === 'MEDICAL_LAB_SCIENTIST') {
        this.addToTableData(medicalLabScientistDataTableSc, resource, this.medicalLabScientistHoursSc.toFixed(0), 1);
        this.addToTableData(medicalLabScientistDataTableOptimumSc, resource, this.medicalLabScientistHoursSc.toFixed(0), 10 / 8);
      } else if (resource.resourceType === 'HIV_SPECIALIST_NURSE') {
        this.addToTableData(hivSpecialistNurseDataTableSc, resource, this.hivSpecialistNurseHoursSc.toFixed(0), 1);
        this.hivNurseAvailableSc = parseInt(hivSpecialistNurseDataTableSc[1], 10);
        this.addToTableData(hivSpecialistNurseDataTableOptimumSc, resource, this.hivSpecialistNurseHoursSc.toFixed(0), 10 / 8);
        this.hivNurseRequiredSc = parseInt(hivSpecialistNurseDataTableOptimumSc[2], 10);
      } else if (resource.resourceType === 'DIETICIAN') {
        this.addToTableData(dieticianDataTableSc, resource, this.dieticianHoursSc.toFixed(0), 1);
        this.addToTableData(dieticianDataTableOptimumSc, resource, this.dieticianHoursSc.toFixed(0), 10 / 8);
      } else if (resource.resourceType === 'GENERAL_NURSE') {
        this.addToTableData(generalNurseDataTableSc, resource, this.generalNurseHoursSc.toFixed(0), 1);
        this.addToTableData(generalNurseDataTableOptimumSc, resource, this.generalNurseHoursSc.toFixed(0), 10 / 8);
      } else if (resource.resourceType === 'COUNSELOR') {
        this.addToTableData(counselorDataTableSc, resource, this.counselorHours.toFixed(0), 1);
        this.counsellorAvailableSc = parseInt(counselorDataTableSc[1], 10);
        this.addToTableData(counselorDataTableOptimumSc, resource, this.counselorHours.toFixed(0), 10 / 8);
        this.counsellorRequiredSc = parseInt(counselorDataTableOptimumSc[2], 10);
      }
    });

    this.colDefinitions = definitions;

    elemenData.push(doctorDataTable);
    elemenData.push(pharmacistDataTable);
    elemenData.push(medicalLabScientistDataTable);
    elemenData.push(hivSpecialistNurseDataTable);
    elemenData.push(dieticianDataTable);
    elemenData.push(generalNurseDataTable);
    elemenData.push(counselorDataTable);

    this.dataSource = elemenData;

    elemenDataSc.push(doctorDataTableSc);
    elemenDataSc.push(pharmacistDataTableSc);
    elemenDataSc.push(medicalLabScientistDataTableSc);
    elemenDataSc.push(hivSpecialistNurseDataTableSc);
    elemenDataSc.push(dieticianDataTableSc);
    elemenDataSc.push(generalNurseDataTableSc);
    elemenDataSc.push(counselorDataTableSc);

    this.dataSourceSc = elemenDataSc;

    elemenDataOptimum.push(doctorDataTableOptimum);
    elemenDataOptimum.push(pharmacistDataTableOptimum);
    elemenDataOptimum.push(medicalLabScientistDataTableOptimum);
    elemenDataOptimum.push(hivSpecialistNurseDataTableOptimum);
    elemenDataOptimum.push(dieticianDataTableOptimum);
    elemenDataOptimum.push(generalNurseDataTableOptimum);
    elemenDataOptimum.push(counselorDataTableOptimum);

    this.dataSourceOptimum = elemenDataOptimum;

    elemenDataOptimumSc.push(doctorDataTableOptimumSc);
    elemenDataOptimumSc.push(pharmacistDataTableOptimumSc);
    elemenDataOptimumSc.push(medicalLabScientistDataTableOptimumSc);
    elemenDataOptimumSc.push(hivSpecialistNurseDataTableOptimumSc);
    elemenDataOptimumSc.push(dieticianDataTableOptimumSc);
    elemenDataOptimumSc.push(generalNurseDataTableOptimumSc);
    elemenDataOptimumSc.push(counselorDataTableOptimumSc);

    this.dataSourceOptimumSc = elemenDataOptimumSc;

    this.displayedColumns = definitions.map((def) => def.label);

    this.chartShortageOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: shortageData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: shortageDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347,
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },

      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: types
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.DEMAND.NO_CLASSES')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

  }

  addToTableData(dataTable: string[], resource: Resource, hours: string, utilization: number) {
    dataTable.push(resource.staffNumber.toFixed(0));
    const required = this.getOptimalStaffs(resource, parseFloat(hours), utilization, true);
    dataTable.push(required.toFixed(0));
    const staffingLevel = resource.staffNumber - required;
    dataTable.push(staffingLevel.toFixed(0));
    if (staffingLevel === 0) {
      dataTable.push(this.translate.instant('SIMULATION.STAFF.ADEQUATE_STAFFING'));
    } else if (staffingLevel < 0) {
      dataTable.push(this.translate.instant('SIMULATION.STAFF.STAFF_SHORTAGE'));
    } else if (staffingLevel > 0) {
      dataTable.push(this.translate.instant('SIMULATION.STAFF.STAFF_EXCESS'));
    }
    const wisnRatio = resource.staffNumber / required;
    dataTable.push(wisnRatio.toFixed(1));
    if (wisnRatio === 1) {
      dataTable.push(this.translate.instant('SIMULATION.STAFF.NORMAL'));
    } else if (wisnRatio < 1) {
      dataTable.push(this.translate.instant('SIMULATION.STAFF.HIGH'));
    } else if (wisnRatio > 1) {
      dataTable.push(this.translate.instant('SIMULATION.STAFF.NONE'));
    }
  }

  setResourcesLabels(data: SimulationResultResourceListResponse) {
    const staffData: number[] = [];
    const staffDataSc: number[] = [];

    const simSize = Math.floor(data.resources.length / 2);

    const mapCounselling: Map<string, Array<Pair>> = new Map(Object.entries(data.resources[0].visitsCounsellingMapByType));
    const mapCounsellingSc: Map<string, Array<Pair>> =
      new Map(Object.entries(data.resources[simSize].visitsCounsellingMapByType));
    const mapDemand: Map<string, Array<Pair>> = new Map(Object.entries(data.resources[0].visitsMapByDemand));
    const mapDemandSc: Map<string, Array<Pair>> =
      new Map(Object.entries(data.resources[simSize].visitsMapByDemand));
    const mapPatient: Map<string, Array<Pair>> = new Map(Object.entries(data.resources[0].visitMapByPatientType));
    const mapPatientSc: Map<string, Array<Pair>> =
      new Map(Object.entries(data.resources[simSize].visitMapByPatientType));

    const doctorResource = this.simulation.resources[0];
    const pharmacistResource = this.simulation.resources[1];
    const medicalLabScientistResource = this.simulation.resources[2];
    const hivSpecialistNurseResource = this.simulation.resources[3];
    const dieticianResource = this.simulation.resources[4];
    const generalNurseResource = this.simulation.resources[5];
    const counselorResource = this.simulation.resources[6];

    const doctorResourceSc = this.scenario.resources[0];
    const pharmacistResourceSc = this.scenario.resources[1];
    const medicalLabScientistResourceSc = this.scenario.resources[2];
    const hivSpecialistNurseResourceSc = this.scenario.resources[3];
    const dieticianResourceSc = this.scenario.resources[4];
    const generalNurseResourceSc = this.scenario.resources[5];
    const counselorResourceSc = this.scenario.resources[6];

    let diagnosisNaiveNumber = 0;
    let monitoringNaiveNumber = 0;
    let monitoringExperiencedNumber = 0;
    let counsellingNumber = 0;
    let preventionNumber = 0;

    let diagnosisNaiveNumberSc = 0;
    let monitoringNaiveNumberSc = 0;
    let monitoringExperiencedNumberSc = 0;
    let counsellingNumberSc = 0;
    let preventionNumberSc = 0;

    const key = mapCounselling.keys().next().value;

    if (mapDemand.get(key)) {
      const pairs: Pair[] = mapDemand.get(key);
      const pairsSc: Pair[] = mapDemandSc.get(key);
      for (let i = 0; i < pairs.length; i++) {
        const element = pairs[i];
        const elementSc = pairsSc[i];
        if (element.first === 'NAIVE') {
          diagnosisNaiveNumber = element.second;
          diagnosisNaiveNumberSc = elementSc.second;
        }
      }
    }

    if (mapPatient.get(key)) {
      const pairs: Pair[] = mapPatient.get(key);
      const pairsSc: Pair[] = mapPatientSc.get(key);
      for (let i = 0; i < pairs.length; i++) {
        const element = pairs[i];
        const elementSc = pairsSc[i];
        if (element.first === 'NAIVE') {
          monitoringNaiveNumber = element.second;
          monitoringNaiveNumberSc = elementSc.second;
        } else if (element.first === 'PREVENTION') {
          preventionNumber = element.second;
          preventionNumberSc = elementSc.second;
        } else if (element.first === 'TX_EXPERIENCED') {
          monitoringExperiencedNumber = element.second;
          monitoringExperiencedNumberSc = elementSc.second;
        }
      }
    }

    if (mapCounselling.get(key)) {
      const pairs: Pair[] = mapCounselling.get(key);
      const pairsSc: Pair[] = mapCounsellingSc.get(key);
      for (let i = 0; i < pairs.length; i++) {
        const element = pairs[i];
        const elementSc = pairsSc[i];
        counsellingNumber = counsellingNumber + element.second;
        counsellingNumberSc = counsellingNumberSc + elementSc.second;
      }
    }

    const diagnoseDurationNaive = (this.simulation.maxDiagnoseDurationNaive + this.simulation.minDiagnoseDurationNaive) / 2;
    const monitoringDurationNaive = (this.simulation.maxMonitoringDurationNaive + this.simulation.minMonitoringDurationNaive) / 2;
    const monitoringDurationExperienced = (this.simulation.maxMonitoringDurationExperienced
      + this.simulation.minMonitoringDurationExperienced) / 2;
    const counsellingDuration = (this.simulation.maxCounsellingDurationExperienced + this.simulation.minCounsellingDurationExperienced) / 2;
    const preventionDuration = (this.simulation.maxMonitoringDurationPrevention + this.simulation.minMonitoringDurationPrevention) / 2;

    const diagnoseDurationNaiveSc = (this.scenario.maxDiagnoseDurationNaive + this.scenario.minDiagnoseDurationNaive) / 2;
    const monitoringDurationNaiveSc = (this.scenario.maxMonitoringDurationNaive + this.scenario.minMonitoringDurationNaive) / 2;
    const monitoringDurationExperiencedSc = (this.scenario.maxMonitoringDurationExperienced
      + this.scenario.minMonitoringDurationExperienced) / 2;
    const counsellingDurationSc = (this.scenario.maxCounsellingDurationExperienced
      + this.scenario.minCounsellingDurationExperienced) / 2;
    const preventionDurationSc = (this.scenario.maxMonitoringDurationPrevention + this.scenario.minMonitoringDurationPrevention) / 2;

    const diagnoseDoctor = (diagnosisNaiveNumber * doctorResource.diagnoseNaive * diagnoseDurationNaive);
    const monitoringDoctor = (monitoringNaiveNumber * doctorResource.monitoringNaive * monitoringDurationNaive)
      + (monitoringExperiencedNumber * doctorResource.monitoringExperienced * monitoringDurationExperienced);
    const counsellingDoctor = (counsellingNumber * doctorResource.counselling * counsellingDuration);
    const preventionDoctor = (preventionNumber * doctorResource.prevention * preventionDuration);

    this.doctorHours = Math.round((diagnoseDoctor + monitoringDoctor + counsellingDoctor + preventionDoctor) / 60);
    staffData.push(this.doctorHours);

    this.doctorHoursSc = Math.round((((diagnosisNaiveNumberSc * doctorResourceSc.diagnoseNaive * diagnoseDurationNaiveSc)
      + (monitoringNaiveNumberSc * doctorResourceSc.monitoringNaive * monitoringDurationNaiveSc)
      + (monitoringExperiencedNumberSc * doctorResourceSc.monitoringExperienced * monitoringDurationExperiencedSc)
      + (counsellingNumberSc * doctorResourceSc.counselling * counsellingDurationSc)
      + (preventionNumberSc * doctorResourceSc.prevention * preventionDurationSc)) / 60));
    staffDataSc.push(this.doctorHoursSc);

    const diagnosePharmacist = (diagnosisNaiveNumber * pharmacistResource.diagnoseNaive);
    const monitoringPharmacist = (monitoringNaiveNumber * pharmacistResource.monitoringNaive)
      + (monitoringExperiencedNumber * pharmacistResource.monitoringExperienced);
    const counsellingPharmacist = (counsellingNumber * pharmacistResource.counselling);
    const preventionPharmacist = (preventionNumber * pharmacistResource.prevention);

    this.pharmacistHours = Math.round((diagnosePharmacist + monitoringPharmacist + counsellingPharmacist + preventionPharmacist) / 60);
    staffData.push(this.pharmacistHours);

    this.pharmacistHoursSc = Math.round((((diagnosisNaiveNumberSc * pharmacistResourceSc.diagnoseNaive)
      + (monitoringNaiveNumberSc * pharmacistResourceSc.monitoringNaive)
      + (monitoringExperiencedNumberSc * pharmacistResourceSc.monitoringExperienced)
      + (counsellingNumberSc * pharmacistResourceSc.counselling)
      + (preventionNumberSc * pharmacistResourceSc.prevention)) / 60));
    staffDataSc.push(this.pharmacistHoursSc);

    const diagnoseMedicalLabScientist = (diagnosisNaiveNumber * medicalLabScientistResource.diagnoseNaive);
    const monitoringMedicalLabScientist = (monitoringNaiveNumber * medicalLabScientistResource.monitoringNaive)
      + (monitoringExperiencedNumber * medicalLabScientistResource.monitoringExperienced);
    const counsellingMedicalLabScientist = (counsellingNumber * medicalLabScientistResource.counselling);
    const preventionMedicalLabScientist = (preventionNumber * medicalLabScientistResource.prevention);

    this.medicalLabScientistHours = Math.round((diagnoseMedicalLabScientist + monitoringMedicalLabScientist + counsellingMedicalLabScientist
      + preventionMedicalLabScientist) / 60);
    staffData.push(this.medicalLabScientistHours);

    this.medicalLabScientistHoursSc = Math.round((((diagnosisNaiveNumberSc * medicalLabScientistResourceSc.diagnoseNaive)
      + (monitoringNaiveNumberSc * medicalLabScientistResourceSc.monitoringNaive)
      + (monitoringExperiencedNumberSc * medicalLabScientistResourceSc.monitoringExperienced)
      + (counsellingNumberSc * medicalLabScientistResourceSc.counselling)
      + (preventionNumberSc * medicalLabScientistResourceSc.prevention)) / 60));
    staffDataSc.push(this.medicalLabScientistHoursSc);

    const diagnoseHivSpecialistNurse = (diagnosisNaiveNumber * hivSpecialistNurseResource.diagnoseNaive * diagnoseDurationNaive);
    const monitoringHivSpecialistNurse = (monitoringNaiveNumber * hivSpecialistNurseResource.monitoringNaive)
      + (monitoringExperiencedNumber * hivSpecialistNurseResource.monitoringExperienced);
    const counsellingHivSpecialistNurse = (counsellingNumber * hivSpecialistNurseResource.counselling);
    const preventionHivSpecialistNurse = (preventionNumber * hivSpecialistNurseResource.prevention);

    this.hivSpecialistNurseHours = Math.round((diagnoseHivSpecialistNurse + monitoringHivSpecialistNurse
      + counsellingHivSpecialistNurse + preventionHivSpecialistNurse) / 60);
    staffData.push(this.hivSpecialistNurseHours);

    this.hivSpecialistNurseHoursSc =
      Math.round((((diagnosisNaiveNumberSc * hivSpecialistNurseResourceSc.diagnoseNaive * diagnoseDurationNaiveSc)
        + (monitoringNaiveNumberSc * hivSpecialistNurseResourceSc.monitoringNaive)
        + (monitoringExperiencedNumberSc * hivSpecialistNurseResourceSc.monitoringExperienced)
        + (counsellingNumberSc * hivSpecialistNurseResourceSc.counselling)
        + (preventionNumberSc * hivSpecialistNurseResourceSc.prevention)) / 60));
    staffDataSc.push(this.hivSpecialistNurseHoursSc);

    const diagnoseDietician = (diagnosisNaiveNumber * dieticianResource.diagnoseNaive);
    const monitoringDietician = (monitoringNaiveNumber * dieticianResource.monitoringNaive)
      + (monitoringExperiencedNumber * dieticianResource.monitoringExperienced);
    const counsellingDietician = (counsellingNumber * dieticianResource.counselling);
    const preventionDietician = (preventionNumber * dieticianResource.prevention);

    this.dieticianHours = Math.round((diagnoseDietician + monitoringDietician + counsellingDietician
      + preventionDietician) / 60);
    staffData.push(this.dieticianHours);

    this.dieticianHoursSc = Math.round((((diagnosisNaiveNumberSc * dieticianResourceSc.diagnoseNaive)
      + (monitoringNaiveNumberSc * dieticianResourceSc.monitoringNaive)
      + (monitoringExperiencedNumberSc * dieticianResourceSc.monitoringExperienced)
      + (counsellingNumberSc * dieticianResourceSc.counselling)
      + (preventionNumberSc * dieticianResourceSc.prevention)) / 60));
    staffDataSc.push(this.dieticianHoursSc);

    const diagnoseGeneralNurse = (diagnosisNaiveNumber * generalNurseResource.diagnoseNaive * diagnoseDurationNaive);
    const monitoringGeneralNurse = (monitoringNaiveNumber * generalNurseResource.monitoringNaive)
      + (monitoringExperiencedNumber * generalNurseResource.monitoringExperienced);
    const counsellingGeneralNurse = (counsellingNumber * generalNurseResource.counselling);
    const preventionGeneralNurse = (preventionNumber * generalNurseResource.prevention);

    this.generalNurseHours = Math.round((diagnoseGeneralNurse + monitoringGeneralNurse + counsellingGeneralNurse
      + preventionGeneralNurse) / 60);
    staffData.push(this.generalNurseHours);

    this.generalNurseHoursSc = Math.round((((diagnosisNaiveNumberSc * generalNurseResourceSc.diagnoseNaive * diagnoseDurationNaiveSc)
      + (monitoringNaiveNumberSc * generalNurseResourceSc.monitoringNaive)
      + (monitoringExperiencedNumberSc * generalNurseResourceSc.monitoringExperienced)
      + (counsellingNumberSc * generalNurseResourceSc.counselling)
      + (preventionNumberSc * generalNurseResourceSc.prevention)) / 60));
    staffDataSc.push(this.generalNurseHoursSc);

    const diagnoseCounselor = (diagnosisNaiveNumber * counselorResource.diagnoseNaive);
    const monitoringCounselor = (monitoringNaiveNumber * counselorResource.monitoringNaive)
      + (monitoringExperiencedNumber * counselorResource.monitoringExperienced);
    const counsellingCounselor = (counsellingNumber * counselorResource.counselling * counsellingDuration);
    const preventionCounselor = (preventionNumber * counselorResource.prevention);

    this.counselorHours = Math.round((diagnoseCounselor + monitoringCounselor + counsellingCounselor + preventionCounselor) / 60);
    staffData.push(this.counselorHours);

    this.counselorHoursSc = Math.round((((diagnosisNaiveNumberSc * counselorResourceSc.diagnoseNaive)
      + (monitoringNaiveNumberSc * counselorResourceSc.monitoringNaive)
      + (monitoringExperiencedNumberSc * counselorResourceSc.monitoringExperienced)
      + (counsellingNumberSc * counselorResourceSc.counselling * counsellingDurationSc)
      + (preventionNumberSc * counselorResourceSc.prevention)) / 60));
    staffDataSc.push(this.counselorHoursSc);

    this.diagnosis = Math.round((diagnoseDoctor + diagnosePharmacist + diagnoseMedicalLabScientist
      + diagnoseHivSpecialistNurse + diagnoseDietician + diagnoseGeneralNurse + diagnoseCounselor) / 60);
    this.prevention = Math.round((preventionDoctor + preventionPharmacist + preventionMedicalLabScientist
      + preventionHivSpecialistNurse + preventionDietician + preventionGeneralNurse + preventionCounselor) / 60);
    this.monitoring = Math.round((monitoringDoctor + monitoringPharmacist + monitoringMedicalLabScientist
      + monitoringHivSpecialistNurse + monitoringDietician + monitoringGeneralNurse + monitoringCounselor) / 60);
    this.counselling = Math.round((counsellingDoctor + counsellingPharmacist + counsellingMedicalLabScientist
      + counsellingHivSpecialistNurse + counsellingDietician + counsellingGeneralNurse + counsellingCounselor) / 60);

    this.totalHours = Math.round(this.doctorHours + this.pharmacistHours
      + this.medicalLabScientistHours + this.hivSpecialistNurseHours
      + this.dieticianHours + this.generalNurseHours
      + this.counselorHours);

    this.childrenHours = Math.round(this.totalHours * (this.simulation.percentageChildren / 100));
    this.adolescentsHours = Math.round(this.totalHours * (this.simulation.percentageAdolescent / 100));
    this.adultsHours = Math.round(this.totalHours * (this.simulation.percentageAdults / 100));
    this.olderAdultsHours = Math.round(this.totalHours * (this.simulation.percentageOlderAdults / 100));

    this.totalHoursSc = Math.round(this.doctorHoursSc + this.pharmacistHoursSc
      + this.medicalLabScientistHoursSc + this.hivSpecialistNurseHoursSc
      + this.dieticianHoursSc + this.generalNurseHoursSc
      + this.counselorHoursSc);

    this.chartPieAnnualOptions = {
      series: [
        this.childrenHours,
        this.adolescentsHours,
        this.adultsHours,
        this.olderAdultsHours],
      chart: {
        width: 380,
        type: 'pie'
      },
      colors: ['#008efb', '#00e397', '#feb219', '#6b0914'],
      labels: [
        this.translate.instant('SIMULATION.DEMAND.CHILDREN'),
        this.translate.instant('SIMULATION.DEMAND.ADOLESCENTS'),
        this.translate.instant('SIMULATION.DEMAND.ADULT'),
        this.translate.instant('SIMULATION.DEMAND.OLDER_ADULT')],
      legend: {
        position: 'bottom',
        labels: {
          colors: ['#785dd0', '#008efb', '#00e397', '#feb219', '#6b0914'],
          useSeriesColors: false
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    };

    this.chartPieAnnualServiceOptions = {
      series: [
        this.diagnosis,
        this.prevention,
        this.monitoring,
        this.counselling],
      chart: {
        width: 380,
        type: 'pie'
      },
      colors: ['#008efb', '#00e397', '#feb219', '#6b0914'],
      labels: [
        this.translate.instant('SIMULATION.DIAGNOSIS'),
        this.translate.instant('SIMULATION.PREP_PEP'),
        this.translate.instant('SIMULATION.MONITORING'),
        this.translate.instant('SIMULATION.COUNSELLING')],
      legend: {
        position: 'bottom',
        labels: {
          colors: ['#785dd0', '#008efb', '#00e397', '#feb219', '#6b0914'],
          useSeriesColors: false
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    };

    this.chartYearStaffOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.STAFF.HOURS'),
          data: staffData
        }
      ],
      chart: {
        type: 'bar',
        height: 380
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      colors: ['#008efb', '#00e397', '#feb219', '#ff4561', '#785dd0', '#6b0914', '#f9a3a4'],
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#fff']
        },
        offsetX: 0
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.HOURS')
        },
        categories: [
          this.translate.instant('SIMULATION.STAFF.DOCTOR_NAME'),
          this.translate.instant('SIMULATION.STAFF.PHARMACIST_NAME'),
          this.translate.instant('SIMULATION.STAFF.LAB_NAME'),
          this.translate.instant('SIMULATION.STAFF.HIV_NURSE_NAME'),
          this.translate.instant('SIMULATION.STAFF.DIETICIAN_NAME'),
          this.translate.instant('SIMULATION.STAFF.GENERAL_NURSE_NAME'),
          this.translate.instant('SIMULATION.STAFF.COUNSELLOR_NAME')
        ]
      },
      yaxis: {
        labels: {
          show: false
        },
        title: {
          text: this.translate.instant('SIMULATION.STAFF.NO_STAFF')
        }
      }
    };

    this.chartYearStaffScOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.STAFF.HOURS'),
          data: staffDataSc
        }
      ],
      chart: {
        type: 'bar',
        height: 380
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      colors: ['#008efb', '#00e397', '#feb219', '#ff4561', '#785dd0', '#6b0914', '#f9a3a4'],
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#fff']
        },
        offsetX: 0
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.HOURS')
        },
        categories: [
          this.translate.instant('SIMULATION.STAFF.DOCTOR_NAME'),
          this.translate.instant('SIMULATION.STAFF.PHARMACIST_NAME'),
          this.translate.instant('SIMULATION.STAFF.LAB_NAME'),
          this.translate.instant('SIMULATION.STAFF.HIV_NURSE_NAME'),
          this.translate.instant('SIMULATION.STAFF.DIETICIAN_NAME'),
          this.translate.instant('SIMULATION.STAFF.GENERAL_NURSE_NAME'),
          this.translate.instant('SIMULATION.STAFF.COUNSELLOR_NAME')
        ]
      },
      yaxis: {
        labels: {
          show: false
        },
        title: {
          text: this.translate.instant('SIMULATION.STAFF.NO_STAFF')
        }
      }
    };
  }

  setResourcesCharts(patientMap: Map<string, Array<Pair>>, patientMapSc: Map<string, Array<Pair>>,
    demandMap: Map<string, Array<Pair>>, demandMapSc: Map<string, Array<Pair>>,
    counselMap: Map<string, Array<Pair>>, counselMapSc: Map<string, Array<Pair>>) {

    const years: string[] = [];

    const doctorData: number[] = [];
    const pharmacistData: number[] = [];
    const medicalLabScientistData: number[] = [];
    const hivSpecialistNurseData: number[] = [];
    const dieticianData: number[] = [];
    const generalNurseData: number[] = [];
    const counselorData: number[] = [];

    const doctorDataSc: number[] = [];
    const pharmacistDataSc: number[] = [];
    const medicalLabScientistDataSc: number[] = [];
    const hivSpecialistNurseDataSc: number[] = [];
    const dieticianDataSc: number[] = [];
    const generalNurseDataSc: number[] = [];
    const counselorDataSc: number[] = [];

    const doctorDataOptimum: number[] = [];
    const doctorDataOptimumSc: number[] = [];
    const pharmacistDataOptimum: number[] = [];
    const pharmacistDataOptimumSc: number[] = [];
    const medicalLabScientistDataOptimum: number[] = [];
    const medicalLabScientistDataOptimumSc: number[] = [];
    const hivSpecialistNurseDataOptimum: number[] = [];
    const hivSpecialistNurseDataOptimumSc: number[] = [];
    const dieticianDataOptimum: number[] = [];
    const dieticianDataOptimumSc: number[] = [];
    const generalNurseDataOptimum: number[] = [];
    const generalNurseDataOptimumSc: number[] = [];
    const counselorDataOptimum: number[] = [];
    const counselorDataOptimumSc: number[] = [];

    const doctorResource = this.simulation.resources[0];
    const pharmacistResource = this.simulation.resources[1];
    const medicalLabScientistResource = this.simulation.resources[2];
    const hivSpecialistNurseResource = this.simulation.resources[3];
    const dieticianResource = this.simulation.resources[4];
    const generalNurseResource = this.simulation.resources[5];
    const counselorResource = this.simulation.resources[6];

    const doctorResourceSc = this.scenario.resources[0];
    const pharmacistResourceSc = this.scenario.resources[1];
    const medicalLabScientistResourceSc = this.scenario.resources[2];
    const hivSpecialistNurseResourceSc = this.scenario.resources[3];
    const dieticianResourceSc = this.scenario.resources[4];
    const generalNurseResourceSc = this.scenario.resources[5];
    const counselorResourceSc = this.scenario.resources[6];

    const diagnoseDurationNaive = (this.simulation.maxDiagnoseDurationNaive + this.simulation.minDiagnoseDurationNaive) / 2;
    const monitoringDurationNaive = (this.simulation.maxMonitoringDurationNaive + this.simulation.minMonitoringDurationNaive) / 2;
    const monitoringDurationExperienced = (this.simulation.maxMonitoringDurationExperienced
      + this.simulation.minMonitoringDurationExperienced) / 2;
    const counsellingDuration = (this.simulation.maxCounsellingDurationExperienced + this.simulation.minCounsellingDurationExperienced) / 2;
    const preventionDuration = (this.simulation.maxMonitoringDurationPrevention + this.simulation.minMonitoringDurationPrevention) / 2;

    const diagnoseDurationNaiveSc = (this.scenario.maxDiagnoseDurationNaive + this.scenario.minDiagnoseDurationNaive) / 2;
    const monitoringDurationNaiveSc = (this.scenario.maxMonitoringDurationNaive + this.scenario.minMonitoringDurationNaive) / 2;
    const monitoringDurationExperiencedSc = (this.scenario.maxMonitoringDurationExperienced
      + this.scenario.minMonitoringDurationExperienced) / 2;
    const counsellingDurationSc = (this.scenario.maxCounsellingDurationExperienced
      + this.scenario.minCounsellingDurationExperienced) / 2;
    const preventionDurationSc = (this.scenario.maxMonitoringDurationPrevention + this.scenario.minMonitoringDurationPrevention) / 2;

    for (const key of demandMap.keys()) {
      let diagnosisNaiveNumber = 0;
      let monitoringNaiveNumber = 0;
      let monitoringExperiencedNumber = 0;
      let counsellingNumber = 0;
      let preventionNumber = 0;

      let diagnosisNaiveNumberSc = 0;
      let monitoringNaiveNumberSc = 0;
      let monitoringExperiencedNumberSc = 0;
      let counsellingNumberSc = 0;
      let preventionNumberSc = 0;

      years.push(key);

      if (demandMap.get(key)) {
        const pairs: Pair[] = demandMap.get(key);
        const pairsSc: Pair[] = demandMapSc.get(key);
        for (let i = 0; i < pairs.length; i++) {
          const element = pairs[i];
          const elementSc = pairsSc[i];
          if (element.first === 'NAIVE') {
            diagnosisNaiveNumber = element.second;
            diagnosisNaiveNumberSc = elementSc.second;
          }
        }
      }

      if (patientMap.get(key)) {
        const pairs: Pair[] = patientMap.get(key);
        const pairsSc: Pair[] = patientMapSc.get(key);
        for (let i = 0; i < pairs.length; i++) {
          const element = pairs[i];
          const elementSc = pairsSc[i];
          if (element.first === 'NAIVE') {
            monitoringNaiveNumber = element.second;
            monitoringNaiveNumberSc = elementSc.second;
          } else if (element.first === 'PREVENTION') {
            preventionNumber = element.second;
            preventionNumberSc = elementSc.second;
          } else if (element.first === 'TX_EXPERIENCED') {
            monitoringExperiencedNumber = element.second;
            monitoringExperiencedNumberSc = elementSc.second;
          }
        }
      }

      if (counselMap.get(key)) {
        const pairs: Pair[] = counselMap.get(key);
        const pairsSc: Pair[] = counselMapSc.get(key);
        for (let i = 0; i < pairs.length; i++) {
          const element = pairs[i];
          const elementSc = pairsSc[i];
          counsellingNumber = counsellingNumber + element.second;
          counsellingNumberSc = counsellingNumberSc + elementSc.second;
        }
      }

      const doctorHours = (((diagnosisNaiveNumber * doctorResource.diagnoseNaive * diagnoseDurationNaive)
        + (monitoringNaiveNumber * doctorResource.monitoringNaive * monitoringDurationNaive)
        + (monitoringExperiencedNumber * doctorResource.monitoringExperienced * monitoringDurationExperienced)
        + (counsellingNumber * doctorResource.counselling * counsellingDuration)
        + (preventionNumber * doctorResource.prevention * preventionDuration)) / 60);
      doctorData.push(Math.round(doctorHours));
      doctorDataOptimum.push(Math.round(this.getOptimalStaffs(doctorResource, doctorHours, 10 / 8, true)));

      const doctorHoursSc = (((diagnosisNaiveNumberSc * doctorResourceSc.diagnoseNaive * diagnoseDurationNaiveSc)
        + (monitoringNaiveNumberSc * doctorResourceSc.monitoringNaive * monitoringDurationNaiveSc)
        + (monitoringExperiencedNumberSc * doctorResourceSc.monitoringExperienced * monitoringDurationExperiencedSc)
        + (counsellingNumberSc * doctorResourceSc.counselling * counsellingDurationSc)
        + (preventionNumberSc * doctorResourceSc.prevention * preventionDurationSc)) / 60);
      doctorDataSc.push(Math.round(doctorHoursSc));
      doctorDataOptimumSc.push(Math.round(this.getOptimalStaffs(doctorResourceSc, doctorHoursSc, 10 / 8, true)));

      const pharmacistHours = (((diagnosisNaiveNumber * pharmacistResource.diagnoseNaive)
        + (monitoringNaiveNumber * pharmacistResource.monitoringNaive)
        + (monitoringExperiencedNumber * pharmacistResource.monitoringExperienced)
        + (counsellingNumber * pharmacistResource.counselling)
        + (preventionNumber * pharmacistResource.prevention)) / 60);
      pharmacistData.push(Math.round(pharmacistHours));
      pharmacistDataOptimum.push(Math.round(this.getOptimalStaffs(pharmacistResource, pharmacistHours, 10 / 8, true)));

      const pharmacistHoursSc = (((diagnosisNaiveNumberSc * pharmacistResourceSc.diagnoseNaive)
        + (monitoringNaiveNumberSc * pharmacistResourceSc.monitoringNaive)
        + (monitoringExperiencedNumberSc * pharmacistResourceSc.monitoringExperienced)
        + (counsellingNumberSc * pharmacistResourceSc.counselling)
        + (preventionNumberSc * pharmacistResourceSc.prevention)) / 60);
      pharmacistDataSc.push(Math.round(pharmacistHoursSc));
      pharmacistDataOptimumSc.push(Math.round(this.getOptimalStaffs(pharmacistResourceSc, pharmacistHoursSc, 10 / 8, true)));

      const medicalLabScientistHours = (((diagnosisNaiveNumber * medicalLabScientistResource.diagnoseNaive)
        + (monitoringNaiveNumber * medicalLabScientistResource.monitoringNaive)
        + (monitoringExperiencedNumber * medicalLabScientistResource.monitoringExperienced)
        + (counsellingNumber * medicalLabScientistResource.counselling)
        + (preventionNumber * medicalLabScientistResource.prevention)) / 60);
      medicalLabScientistData.push(Math.round(medicalLabScientistHours));
      medicalLabScientistDataOptimum
        .push(Math.round(this.getOptimalStaffs(medicalLabScientistResource, medicalLabScientistHours, 10 / 8, true)));

      const medicalLabScientistHoursSc = (((diagnosisNaiveNumberSc * medicalLabScientistResourceSc.diagnoseNaive)
        + (monitoringNaiveNumberSc * medicalLabScientistResourceSc.monitoringNaive)
        + (monitoringExperiencedNumberSc * medicalLabScientistResourceSc.monitoringExperienced)
        + (counsellingNumberSc * medicalLabScientistResourceSc.counselling)
        + (preventionNumberSc * medicalLabScientistResourceSc.prevention)) / 60);
      medicalLabScientistDataSc.push(Math.round(medicalLabScientistHoursSc));
      medicalLabScientistDataOptimumSc
        .push(Math.round(this.getOptimalStaffs(medicalLabScientistResourceSc, medicalLabScientistHoursSc, 10 / 8, true)));

      const hivSpecialistNurseHours = (((diagnosisNaiveNumber * hivSpecialistNurseResource.diagnoseNaive * diagnoseDurationNaive)
        + (monitoringNaiveNumber * hivSpecialistNurseResource.monitoringNaive)
        + (monitoringExperiencedNumber * hivSpecialistNurseResource.monitoringExperienced)
        + (counsellingNumber * hivSpecialistNurseResource.counselling)
        + (preventionNumber * hivSpecialistNurseResource.prevention)) / 60);
      hivSpecialistNurseData.push(Math.round(hivSpecialistNurseHours));
      hivSpecialistNurseDataOptimum
        .push(Math.round(this.getOptimalStaffs(hivSpecialistNurseResource, hivSpecialistNurseHours, 10 / 8, true)));

      const hivSpecialistNurseHoursSc = (((diagnosisNaiveNumberSc * hivSpecialistNurseResourceSc.diagnoseNaive * diagnoseDurationNaiveSc)
        + (monitoringNaiveNumberSc * hivSpecialistNurseResourceSc.monitoringNaive)
        + (monitoringExperiencedNumberSc * hivSpecialistNurseResourceSc.monitoringExperienced)
        + (counsellingNumberSc * hivSpecialistNurseResourceSc.counselling)
        + (preventionNumberSc * hivSpecialistNurseResourceSc.prevention)) / 60);
      hivSpecialistNurseDataSc.push(Math.round(hivSpecialistNurseHoursSc));
      hivSpecialistNurseDataOptimumSc
        .push(Math.round(this.getOptimalStaffs(hivSpecialistNurseResourceSc, hivSpecialistNurseHoursSc, 10 / 8, true)));

      const dieticianHours = (((diagnosisNaiveNumber * dieticianResource.diagnoseNaive)
        + (monitoringNaiveNumber * dieticianResource.monitoringNaive)
        + (monitoringExperiencedNumber * dieticianResource.monitoringExperienced)
        + (counsellingNumber * dieticianResource.counselling)
        + (preventionNumber * dieticianResource.prevention)) / 60);
      dieticianData.push(Math.round(dieticianHours));
      dieticianDataOptimum.push(Math.round(this.getOptimalStaffs(dieticianResource, dieticianHours, 10 / 8, true)));

      const dieticianHoursSc = (((diagnosisNaiveNumberSc * dieticianResourceSc.diagnoseNaive)
        + (monitoringNaiveNumberSc * dieticianResourceSc.monitoringNaive)
        + (monitoringExperiencedNumberSc * dieticianResourceSc.monitoringExperienced)
        + (counsellingNumberSc * dieticianResourceSc.counselling)
        + (preventionNumberSc * dieticianResourceSc.prevention)) / 60);
      dieticianDataSc.push(Math.round(dieticianHoursSc));
      dieticianDataOptimumSc.push(Math.round(this.getOptimalStaffs(dieticianResourceSc, dieticianHoursSc, 10 / 8, true)));

      const generalNurseHours = (((diagnosisNaiveNumber * generalNurseResource.diagnoseNaive * diagnoseDurationNaive)
        + (monitoringNaiveNumber * generalNurseResource.monitoringNaive)
        + (monitoringExperiencedNumber * generalNurseResource.monitoringExperienced)
        + (counsellingNumber * generalNurseResource.counselling)
        + (preventionNumber * generalNurseResource.prevention)) / 60);
      generalNurseData.push(Math.round(generalNurseHours));
      generalNurseDataOptimum.push(Math.round(this.getOptimalStaffs(generalNurseResource, generalNurseHours, 10 / 8, true)));

      const generalNurseHoursSc = (((diagnosisNaiveNumberSc * generalNurseResourceSc.diagnoseNaive * diagnoseDurationNaiveSc)
        + (monitoringNaiveNumberSc * generalNurseResourceSc.monitoringNaive)
        + (monitoringExperiencedNumberSc * generalNurseResourceSc.monitoringExperienced)
        + (counsellingNumberSc * generalNurseResourceSc.counselling)
        + (preventionNumberSc * generalNurseResourceSc.prevention)) / 60);
      generalNurseDataSc.push(Math.round(generalNurseHoursSc));
      generalNurseDataOptimumSc.push(Math.round(this.getOptimalStaffs(generalNurseResourceSc, generalNurseHoursSc, 10 / 8, true)));

      const counselorHours = (((diagnosisNaiveNumber * counselorResource.diagnoseNaive)
        + (monitoringNaiveNumber * counselorResource.monitoringNaive)
        + (monitoringExperiencedNumber * counselorResource.monitoringExperienced)
        + (counsellingNumber * counselorResource.counselling * counsellingDuration)
        + (preventionNumber * counselorResource.prevention)) / 60);
      counselorData.push(Math.round(counselorHours));
      counselorDataOptimum.push(Math.round(this.getOptimalStaffs(counselorResource, counselorHours, 10 / 8, true)));

      const counselorHoursSc = (((diagnosisNaiveNumberSc * counselorResourceSc.diagnoseNaive)
        + (monitoringNaiveNumberSc * counselorResourceSc.monitoringNaive)
        + (monitoringExperiencedNumberSc * counselorResourceSc.monitoringExperienced)
        + (counsellingNumberSc * counselorResourceSc.counselling * counsellingDurationSc)
        + (preventionNumberSc * counselorResourceSc.prevention)) / 60);
      counselorDataSc.push(Math.round(counselorHoursSc));
      counselorDataOptimumSc.push(Math.round(this.getOptimalStaffs(counselorResourceSc, counselorHoursSc, 10 / 8, true)));

    }

    this.chartStaffOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.STAFF.DOCTOR_NAME'),
          type: 'column',
          data: doctorData,
        },
        {
          name: this.translate.instant('SIMULATION.STAFF.PHARMACIST_NAME'),
          type: 'column',
          data: pharmacistData,
        },
        {
          name: this.translate.instant('SIMULATION.STAFF.LAB_NAME'),
          type: 'column',
          data: medicalLabScientistData,
        },
        {
          name: this.translate.instant('SIMULATION.STAFF.HIV_NURSE_NAME'),
          type: 'column',
          data: hivSpecialistNurseData,
        },
        {
          name: this.translate.instant('SIMULATION.STAFF.DIETICIAN_NAME'),
          type: 'column',
          data: dieticianData,
        },
        {
          name: this.translate.instant('SIMULATION.STAFF.GENERAL_NURSE_NAME'),
          type: 'column',
          data: generalNurseData,
        },
        {
          name: this.translate.instant('SIMULATION.STAFF.COUNSELLOR_NAME'),
          type: 'column',
          data: counselorData,
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        stacked: true,
        height: 347
      },
      colors: ['#008efb', '#00e397', '#feb219', '#ff4561', '#785dd0', '#6b0914', '#ffff45'],
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.NO_HOURS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },

      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartStaffScOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.STAFF.DOCTOR_NAME'),
          type: 'column',
          data: doctorDataSc,
        },
        {
          name: this.translate.instant('SIMULATION.STAFF.PHARMACIST_NAME'),
          type: 'column',
          data: pharmacistDataSc,
        },
        {
          name: this.translate.instant('SIMULATION.STAFF.LAB_NAME'),
          type: 'column',
          data: medicalLabScientistDataSc,
        },
        {
          name: this.translate.instant('SIMULATION.STAFF.HIV_NURSE_NAME'),
          type: 'column',
          data: hivSpecialistNurseDataSc,
        },
        {
          name: this.translate.instant('SIMULATION.STAFF.DIETICIAN_NAME'),
          type: 'column',
          data: dieticianDataSc,
        },
        {
          name: this.translate.instant('SIMULATION.STAFF.GENERAL_NURSE_NAME'),
          type: 'column',
          data: generalNurseDataSc,
        },
        {
          name: this.translate.instant('SIMULATION.STAFF.COUNSELLOR_NAME'),
          type: 'column',
          data: counselorDataSc,
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        stacked: true,
        height: 347
      },
      colors: ['#008efb', '#00e397', '#feb219', '#ff4561', '#785dd0', '#6b0914', '#ffff45'],
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },

      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.NO_HOURS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },

      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartDoctorOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: doctorDataOptimum
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: doctorDataOptimumSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },

      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.DOCTOR_REQUIRED')
        },
        labels: {
          show: true,
          formatter: (val) => val.toFixed(0),
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartDoctorTotalOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: doctorData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: doctorDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },

      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.DOCTOR_HOURS_REQUIRED')
        },
        labels: {
          show: true,
          formatter: (val) => val.toFixed(0),
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartPharmacistOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: pharmacistDataOptimum
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: pharmacistDataOptimumSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.PHARMACIST_REQUIRED')
        },
        labels: {
          show: true,
          formatter: (val) => val.toFixed(0),
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartPharmacistTotalOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: pharmacistData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: pharmacistDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.PHARMACIST_HOURS_REQUIRED')
        },
        labels: {
          show: true,
          formatter: (val) => val.toFixed(0),
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartMedicalLabScientistOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: medicalLabScientistDataOptimum
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: medicalLabScientistDataOptimumSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },

      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.LAB_REQUIRED')
        },
        labels: {
          show: true,
          formatter: (val) => val.toFixed(0),
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartMedicalLabScientistTotalOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: medicalLabScientistData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: medicalLabScientistDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.LAB_HOURS_REQUIRED')
        },
        labels: {
          show: true,
          formatter: (val) => val.toFixed(0),
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartHivSpecialistNurseOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: hivSpecialistNurseDataOptimum
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: hivSpecialistNurseDataOptimumSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.HIV_NURSE_REQUIRED')
        },
        labels: {
          show: true,
          formatter: (val) => val.toFixed(0),
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartHivSpecialistNurseTotalOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: hivSpecialistNurseData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: hivSpecialistNurseDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.HIV_NURSE_HOURS_REQUIRED')
        },
        labels: {
          show: true,
          formatter: (val) => val.toFixed(0),
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartDieticianOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: dieticianDataOptimum
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: dieticianDataOptimumSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.DIETICIAN_REQUIRED')
        },
        labels: {
          show: true,
          formatter: (val) => val.toFixed(0),
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartDieticianTotalOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: dieticianData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: dieticianDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.DIETICIAN_HOURS_REQUIRED')
        },
        labels: {
          show: true,
          formatter: (val) => val.toFixed(0),
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartGeneralNurseOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: generalNurseDataOptimum
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: generalNurseDataOptimumSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.GENERAL_NURSE_REQUIRED')
        },
        labels: {
          show: true,
          formatter: (val) => val.toFixed(0),
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartGeneralNurseTotalOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: generalNurseData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: generalNurseDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.GENERAL_NURSE_HOURS_REQUIRED')
        },
        labels: {
          show: true,
          formatter: (val) => val.toFixed(0),
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartCounselorOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: counselorDataOptimum
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: counselorDataOptimumSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.COUNSELOR_REQUIRED')
        },
        labels: {
          show: true,
          formatter: (val) => val.toFixed(0),
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartCounselorTotalOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: counselorData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: counselorDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.STAFF.COUNSELOR_HOURS_REQUIRED')
        },
        labels: {
          show: true,
          formatter: (val) => val.toFixed(0),
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };
  }

  getArray(mapArray: Array<SimulationResultResourceResponse>, type: string, baseline: boolean): Map<string, Array<Pair>>[] {
    const returnArrayMap: Map<string, Array<Pair>>[] = [];
    const arraySize = Math.floor(mapArray.length / 2);
    let startIndex = 0;
    let finishIndex = arraySize;
    if (!baseline) {
      startIndex = startIndex + arraySize;
      finishIndex = finishIndex + arraySize;
    }
    for (let i = startIndex; i < finishIndex; i++) {
      if (type === 'patient') {
        returnArrayMap.push(mapArray[i].visitMapByPatientType);
      } else if (type === 'demand') {
        returnArrayMap.push(mapArray[i].visitsMapByDemand);
      } else if (type === 'counsel') {
        returnArrayMap.push(mapArray[i].visitsCounsellingMapByType);
      }
    }
    return returnArrayMap;
  }

  getAverage(mapArray: Map<string, Array<Pair>>[]): Map<string, Array<Pair>> {
    const averageMap: Map<string, Array<Pair>> = new Map<string, Array<Pair>>();
    const mapSize = mapArray.length;
    mapArray.forEach(map => {
      const ttt = new Map(Object.entries(map));
      for (const key of ttt.keys()) {
        if (averageMap.get(key)) {
          const averagePairs: Pair[] = averageMap.get(key);
          const newPairs: Pair[] = [];
          const pairs: Pair[] = ttt.get(key);
          for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i];
            const averagePair = averagePairs[i];
            const second = averagePair.second + pair.second / mapSize;
            newPairs.push({ first: pair.first, second: parseFloat(second.toFixed(2)) });
          }
          averageMap.set(key, newPairs);
        } else {
          const newPairs: Pair[] = [];
          const pairs: Pair[] = ttt.get(key);
          pairs.forEach(pair => {
            newPairs.push({ first: pair.first, second: parseFloat((pair.second / mapSize).toFixed(2)) });
          });
          averageMap.set(key, newPairs);
        }
      }
    });
    return averageMap;
  }

  chooseColor(row: any, colDef: ColumnDefintion) {
    if (colDef.sticky === true) {
      return '#ff62b1';
    }
    if (row[colDef.position] === 'STAFF EXCESS') {
      return '#E8E9A1';
    } else if (row[colDef.position] === 'ADEQUATE STAFFING') {
      return '#90EE90'; //lightgreen
    } else if (row[colDef.position] === 'STAFF SHORTAGE') {
      return '#ff4561';
    } else {
      return '#FFFFFF';
    }
  }

  chooseStickyColor(row: any, colDef: ColumnDefintion) {
    if (colDef.position === 0) {
      return '#2841a5';
    } else {
      return '#FFFFFF';
    }
  }

}
