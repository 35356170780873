/* eslint-disable max-len */
/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/component/base.component';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { OtpType } from 'src/app/shared/model/enum/otp-type';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit, OnDestroy {

  captcha: string;
  recaptchaSiteKey = environment.recaptchaSiteKey;

  private returnUrl: string;

  formGroup: FormGroup = new FormGroup({
    captcha:  new FormControl('', [Validators.required])
  });


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private authenticationStateService: AuthenticationStateService,
    private userAuthService: UserAuthService
  ) {
    super();
  }

  ngOnInit() {
    const urlParam = 'returnUrl';
    this.returnUrl = this.route.snapshot.queryParams[urlParam] || '/dashboard';
    const isAuthenticated = this.authenticationStateService.isAuthenticated();
    if (isAuthenticated) {
      this.router.navigateByUrl(this.returnUrl);
    }
  }

  public login() {
    if (this.formGroup.valid ) {
      const emailOrPhoneNumberControl = this.formGroup.get('emailOrPhoneNumber');
      const emailOrPhoneNumber = emailOrPhoneNumberControl.get('emailOrPhoneType').value === 'EMAIL' ? emailOrPhoneNumberControl.get('email').value : emailOrPhoneNumberControl.get('phone').value;
      
      const password = this.formGroup.get('passwordFormGroup').get('password').value;

      this.userAuthService.login(emailOrPhoneNumber, password, this.captcha).subscribe(data => {
        if (data.otpEnabled === true) {
          this.router.navigate(['user/otp-confirm', OtpType[OtpType.LOGIN], emailOrPhoneNumber], { queryParams: { returnUrl: this.returnUrl }});
        } else {
          this.authenticationStateService.notifyLoginInfo(data);
          this.router.navigateByUrl(this.returnUrl);
        }
      }, (error) => {
        this.formGroup.get('captcha').reset();
        console.log('Error on login ' + JSON.stringify(error));
        if (error.status === 401) {
          this.notificationService.error(this.translate.instant('LOGIN_FORM.NOTIFICATION.BAD_CREDENTIALS'));
        } else {
          this.notificationService.error(this.translate.instant('LOGIN_FORM.NOTIFICATION.LOGIN_FAILED'));
        }
      });
    }
  }

  goToRegisterPage() {
    if (this.returnUrl) {
      this.router.navigate(['user/register'], { queryParams: { returnUrl: this.returnUrl }});
    } else {
      this.router.navigateByUrl('user/register');
    }
  }

  goToRegistrationOtpGeneration() {
    this.router.navigate(['user/otp-generate'], { queryParams: { otpType: OtpType.REGISTRATION}});
  }

  goToPasswordResetOtpGeneration() {
    this.router.navigate(['user/otp-generate'], { queryParams: { otpType: OtpType.PASSWORD_RESET}});
  }

  captchaResolved(capthcaResult) {
    this.captcha = capthcaResult;
  }
}
