/* eslint-disable @typescript-eslint/member-ordering */
import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dimensions, ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { faUndoAlt, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { DEFAULT_USER_PHOTO } from '../../constants';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {
  faUndoAlt = faUndoAlt; faRedoAlt = faRedoAlt;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  rotate = 0;
  imageLoaded = false;
  cropper = { x1: 10, y1: 10, x2: 30, y2: 30 };
  imageUrl;
  transform: ImageTransform = {};

  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;
  @ViewChild('imageCropperCanvas', { static: true }) imageCropperCanvas: ElementRef;

  @HostListener('window:keyup.esc') onKeyUp() {
    this.onCancel();
  }

  constructor(
    public dialogRef: MatDialogRef<ImageUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }

  ngOnInit(): void {
    this.imageUrl = DEFAULT_USER_PHOTO === this.dialogData.imageUrl ? null : this.dialogData.imageUrl;
  }

  onFileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  onImageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log('Image cropped');
  }

  onImageLoaded() {
    console.log('Image loaded');
    this.imageLoaded = true;
  }

  onLoadImageFailed() {
    console.log('Load failed');
  }

  onCropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  onRotateLeft() {
    this.rotate = ((this.rotate - 90) + 360) % 360;
    this.rotateImage(this.rotate);
    // this.imageCropper.rotateLeft();
  }

  onRotateRight() {
    this.rotate += 90;
    this.rotate = this.rotate % 360;
    this.rotateImage(this.rotate);

    // this.imageCropper.rotateRight();
  }

  private rotateImage(rotateAngle) {
    this.rotate = rotateAngle;
    this.transform = {
      ...this.transform,
      rotate: rotateAngle
    };
  }

  onAccept() {
    this.dialogRef.close({ status: 'ok', croppedImage: this.croppedImage });
  }

  onCancel() {
    this.dialogRef.close({ status: 'cancel', croppedImage: this.imageUrl });
  }

  onRemoveImage() {
    this.dialogRef.close({ status: 'remove', croppedImage: null });
  }

}
