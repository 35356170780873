import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Condition, QuestionGroup, QuestionOption } from 'src/app/shared/model/question';
import { QuestionsService } from 'src/app/shared/services/questions.service';
import { QuestionComponent } from '../question/question.component';

@Component({
  selector: 'app-question-group',
  templateUrl: './question-group.component.html',
  styleUrls: ['./question-group.component.scss']
})
export class QuestionGroupComponent implements OnInit {
  @ViewChildren(QuestionComponent) viewQuestions: QueryList<QuestionComponent>;

  @Input() questionGroup: QuestionGroup;
  @Input() questionGroups: QuestionGroup[];

  showQuestionGroup = true;

  constructor(
    private questionService: QuestionsService
  ) { }

  ngOnInit(): void {

    if (this.questionGroup.conditions) {
      const conditionSelected = this.isConditionOptionSelected(this.questionGroup.conditions);
      if (conditionSelected === false) {
        this.showQuestionGroup = false;
      }
    }

    this.questionService.getQuestionSelectionChange().subscribe(changedQuestion => {
      if (this.questionGroup.conditions) {
        this.updateConditionsSelection();
      }
    });
  }

  isConditionOptionSelected(conditions: Condition[]) {
    for (const condition of conditions) {
      const conditionGroup = this.questionGroups.find(group => group.id === condition.questionGroupId);
      const conditionQuestion = conditionGroup.questions.find(question => question.id === condition.questionId);
      const conditionOption = conditionQuestion.options.find(option => option.id === condition.optionId);
      if (conditionOption.selected === true) {
        return true;
      }
    }
    return false;
  }

  updateConditionsSelection() {
    if (!this.questionGroup.conditions) {
      // this control required for validation method
      this.showQuestionGroup = true;
      return;
    }

    const conditionSelected = this.isConditionOptionSelected(this.questionGroup.conditions);
    if (conditionSelected === true) {
      this.showQuestionGroup = true;
      return;
    }

    this.destroyQuestionGroup();
    return;
  }

  destroyQuestionGroup() {
    this.showQuestionGroup = false;
    const questions = this.questionGroup.questions;
    for (const question of questions) {
      const options = question.options;
      let fireChanges = false;
      for (const o of options) {
        if (o.selected === true) {
          o.selected = false;
          fireChanges = true;
        }
      }
      if (fireChanges) {
        this.questionService.notifyQuestionChange(question);
      }
    }
  }


  validate() {
    let allQuestionValid = true;
    this.viewQuestions.forEach(qv => {
      const valid = qv.validate();
      if (valid === false) {
        allQuestionValid = false;
      }
    });
    return allQuestionValid;
  }

}
