import { BrowserModule, HAMMER_LOADER } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TokenInterceptor } from './shared/services/interceptor/token.interceptor';
import { ErrorInterceptor } from './shared/services/interceptor/error.interceptor';
import { AuthenticationGuardService } from './shared/services/guard/authentication-guard.service';
import { RoleGuardService } from './shared/services/guard/role-guard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxPermissionsModule } from 'ngx-permissions';


import { NgxSpinnerModule } from 'ngx-spinner';
import { CurrencyMaskModule } from 'ng2-currency-mask';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';

import { QuillModule } from 'ngx-quill';
import { SpinnerInterceptor } from './shared/services/interceptor/spinner.interceptor';


// fusioncharts imports
import { FusionChartsModule } from './angular-fusioncharts/fusioncharts.module';
import * as FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import * as CandyTheme from 'fusioncharts/themes/fusioncharts.theme.candy';
import * as Maps from 'fusioncharts/fusioncharts.maps';
import * as World from 'fusioncharts/maps/fusioncharts.world';
import * as Asia from '../assets/fusioncharts.asia';
import * as Africa from '../assets/fusioncharts.africa';
import * as Australia from '../assets/fusioncharts.australia';
import * as Europe from '../assets/fusioncharts.europe';
import * as Northamerica from '../assets/fusioncharts.northamerica';
import * as Southamerica from '../assets/fusioncharts.southamerica';

import { NotificationModule } from './shared/module/notification/notification.module';
import { LayoutModule } from './shared/module/layout/layout.module';
import { UserModule } from './modules/user/user.module';
import { MainModule } from './modules/main/main.module';
import { UserProfileModule } from './modules/user-profile/user-profile.module';
import { ThirdPartyModule } from './modules/third-party/third-party.module';
import { AdministratorModule } from './modules/administrator/administrator.module';
import { SmarthivClinicianModule } from './modules/smarthiv-clinician/smarthiv-clinician.module';
import { SmarthivManagerModule } from './modules/smarthiv-manager/smarthiv-manager.module';
import { SmarthivTrialistModule } from './modules/smarthiv-trialist/smarthiv-trialist.module';
import { SubscriptionModule } from './modules/subscription/subscription.module';
import { OrganisationModule } from './modules/organisation/organisation.module';
import { environment } from 'src/environments/environment';
import { ChatModule } from './modules/chat/chat.module';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }
    ),
    FlexLayoutModule,
    PerfectScrollbarModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    NgxPermissionsModule.forRoot(),
    CurrencyMaskModule,
    FusionChartsModule.forRoot(FusionCharts, Charts, Maps, World, Asia, Africa, Australia, Europe,
      Northamerica, Southamerica, FusionTheme),
    QuillModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    LayoutModule,
    NotificationModule,
    UserModule,
    MainModule,
    AdministratorModule,
    SmarthivClinicianModule,
    SmarthivManagerModule,
    SmarthivTrialistModule,
    SubscriptionModule,
    OrganisationModule,
    UserProfileModule,
    ThirdPartyModule,
    ChatModule
  ],
  providers: [
    AuthenticationGuardService,
    RoleGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true
    },
    {
      provide: HAMMER_LOADER,
      useValue: () => new Promise(() => { })
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
