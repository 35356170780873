import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from './pages/account/account.component';
import { IncomingInvitationsComponent } from './pages/incoming-invitations/incoming-invitations.component';
import { IndividualClinicianListComponent } from './pages/individual-clinician-list/individual-clinician-list.component';
import { IndividualPatientListComponent } from './pages/individual-patient-list/individual-patient-list.component';
import { OutgoingInvitationsComponent } from './pages/outgoing-invitations/outgoing-invitations.component';
import { UserProfileMainComponent } from './pages/user-profile-main/user-profile-main.component';

const routes: Routes = [
  {
    path: '',
    component: UserProfileMainComponent,
    children: [
      {
        path: 'update-account',
        component: AccountComponent
      },
      {
        path: 'incoming-invitation',
        component: IncomingInvitationsComponent
      },
      {
        path: 'outgoing-invitation',
        component: OutgoingInvitationsComponent
      },
      {
        path: 'individual-clinician-list',
        component: IndividualClinicianListComponent
      },
      {
        path: 'individual-patient-list',
        component: IndividualPatientListComponent
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserProfileRoutingModule { }
