<div class="section-title-bar justify-content-between mb-16">
    <h2 class="content-box__title mb-0">{{ 'TRIAL.TRIALIST_FORM' | translate }}</h2>
    <mat-icon matTooltip="{{ 'TRIAL.TRIALIST_FORM' | translate }}">info</mat-icon>
</div>

<div class="content-box">
    <div class="d-flex align-items-center justify-content-between mb-24">
        <h2 class="content-box__title mb-0">{{ 'TRIAL.PATIENT_CHARACTERISTICS' | translate }}</h2>
        <span class="example-list-section">
            <mat-checkbox class="custom-mat-checkbox" 
                [checked]="allSelected"
                (change)="selectAll($event.checked)">
                {{ 'GENERAL.SELECT_ALL' | translate }}
            </mat-checkbox>
        </span>
    </div>

    <div class="form-box-grid">
        <div class="form-box-grid__column">
            <div class="form-box">
                <div class="form-box__header">
                    <h3 class="title">
                        <mat-checkbox [ngClass]="ageControl ? 'example-margin' : 'example-margin-red'"
                            [checked]="allCompleteAge" [indeterminate]="someComplete('age')"
                            (change)="setAll('age', $event.checked)" required>
                            {{taskAge.name}}
                        </mat-checkbox>
                    </h3>
                </div>
                <div class="form-box__body">
                    <p class="m-0 mb-10" *ngFor="let subtask of taskAge.subtasks">
                        <mat-checkbox [(ngModel)]="subtask.completed"
                            (ngModelChange)="updateAllComplete('age')">
                            {{subtask.name}}
                        </mat-checkbox>
                    </p>
                </div>
            </div>

            <div class="form-box">
                <div class="form-box__header">
                    <h3 class="title">
                        <mat-checkbox [ngClass]="genderControl ? 'example-margin' : 'example-margin-red'"
                            [checked]="allCompleteGender" [indeterminate]="someComplete('gender')"
                            (change)="setAll('gender', $event.checked)">
                            {{taskGender.name}}
                        </mat-checkbox>
                    </h3>
                </div>
                <div class="form-box__body">
                    <p class="m-0 mb-10" *ngFor="let subtask of taskGender.subtasks">
                        <mat-checkbox [(ngModel)]="subtask.completed"
                            (ngModelChange)="updateAllComplete('gender')">
                            {{subtask.name}}
                        </mat-checkbox>
                    </p>
                </div>
            </div>
        </div>

        <div class="form-box-grid__column">
            <div class="form-box">
                <div class="form-box__header">
                    <h3 class="title">
                        <mat-checkbox [ngClass]="contraceptivesUseControl ? 'example-margin' : 'example-margin-red'"
                            [checked]="allCompleteContraceptivesUse"
                            [indeterminate]="someComplete('contraceptivesUse')"
                            (change)="setAll('contraceptivesUse', $event.checked)">
                            {{taskContraceptivesUse.name}}
                        </mat-checkbox>
                    </h3>
                </div>
                <div class="form-box__body">
                    <p class="m-0 mb-10" *ngFor="let subtask of taskContraceptivesUse.subtasks">
                        <mat-checkbox [(ngModel)]="subtask.completed"
                            (ngModelChange)="updateAllComplete('contraceptivesUse')">
                            {{subtask.name}}
                        </mat-checkbox>
                    </p>
                </div>
            </div>

            <div class="form-box">
                <div class="form-box__header">
                    <h3 class="title">
                        <mat-checkbox [ngClass]="raceControl ? 'example-margin' : 'example-margin-red'"
                            [checked]="allCompleteRace" [indeterminate]="someComplete('race')"
                            (change)="setAll('race', $event.checked)">
                            {{taskRace.name}}
                        </mat-checkbox>
                    </h3>
                </div>
                <div class="form-box__body">
                    <p class="m-0 mb-10" *ngFor="let subtask of taskRace.subtasks">
                        <mat-checkbox [(ngModel)]="subtask.completed"
                            (ngModelChange)="updateAllComplete('race')">
                            {{subtask.name}}
                        </mat-checkbox>
                    </p>
                </div>
            </div>

            <div class="form-box">
                <div class="form-box__header">
                    <h3 class="title">
                        <mat-checkbox [ngClass]="treatmentStatusControl ? 'example-margin' : 'example-margin-red'"
                            [checked]="allCompleteTreatmentStatus" [indeterminate]="someComplete('treatmentStatus')"
                            (change)="setAll('treatmentStatus', $event.checked)">
                            {{taskTreatmentStatus.name}}
                        </mat-checkbox>
                    </h3>
                </div>
                <div class="form-box__body">
                    <p class="m-0 mb-10" *ngFor="let subtask of taskTreatmentStatus.subtasks">
                        <mat-checkbox [(ngModel)]="subtask.completed"
                            (ngModelChange)="updateAllComplete('treatmentStatus')">
                            {{subtask.name}}
                        </mat-checkbox>
                    </p>
                </div>
            </div>

            <div class="form-box">
                <div class="form-box__header">
                    <h3 class="title">
                        <mat-checkbox [ngClass]="timeFromDiagnosisControl ? 'example-margin' : 'example-margin-red'"
                            [checked]="allCompleteTimeFromDiagnosis"
                            [indeterminate]="someComplete('timeFromDiagnosis')"
                            (change)="setAll('timeFromDiagnosis', $event.checked)">
                            {{taskTimeFromDiagnosis.name}}
                        </mat-checkbox>
                    </h3>
                </div>
                <div class="form-box__body">
                    <p class="m-0 mb-10" *ngFor="let subtask of taskTimeFromDiagnosis.subtasks">
                        <mat-checkbox [(ngModel)]="subtask.completed"
                            (ngModelChange)="updateAllComplete('timeFromDiagnosis')">
                            {{subtask.name}}
                        </mat-checkbox>
                    </p>
                </div>
            </div>
        </div>

        <div class="form-box-grid__column">
            <div class="form-box">
                <div class="form-box__header">
                    <h3 class="title">
                        <mat-checkbox [ngClass]="weightControl ? 'example-margin' : 'example-margin-red'"
                            [checked]="allCompleteWeight" [indeterminate]="someComplete('weight')"
                            (change)="setAll('weight', $event.checked)">
                            {{taskWeight.name}}
                        </mat-checkbox>
                    </h3>
                </div>
                <div class="form-box__body">
                    <p class="m-0 mb-10" *ngFor="let subtask of taskWeight.subtasks">
                        <mat-checkbox [(ngModel)]="subtask.completed"
                            (ngModelChange)="updateAllComplete('weight')">
                            {{subtask.name}}
                        </mat-checkbox>
                    </p>
                </div>
            </div>

            <div class="form-box">
                <div class="form-box__header">
                    <h3 class="title">
                        <mat-checkbox [ngClass]="timeOnTreatmentControl ? 'example-margin' : 'example-margin-red'"
                            [checked]="allCompleteTimeOnTreatment" [indeterminate]="someComplete('timeOnTreatment')"
                            (change)="setAll('timeOnTreatment', $event.checked)">
                            {{taskTimeOnTreatment.name}}
                        </mat-checkbox>
                    </h3>
                </div>
                <div class="form-box__body">
                    <p class="m-0 mb-10" *ngFor="let subtask of taskTimeOnTreatment.subtasks">
                        <mat-checkbox [(ngModel)]="subtask.completed"
                            (ngModelChange)="updateAllComplete('timeOnTreatment')">
                            {{subtask.name}}
                        </mat-checkbox>
                    </p>
                </div>
            </div>

            <div class="form-box">
                <div class="form-box__header">
                    <h3 class="title">
                        <mat-checkbox [ngClass]="vaccinationControl ? 'example-margin' : 'example-margin-red'"
                            [checked]="allCompleteVaccination" [indeterminate]="someComplete('vaccination')"
                            (change)="setAll('vaccination', $event.checked)">
                            {{taskVaccination.name}}
                        </mat-checkbox>
                    </h3>
                </div>
                <div class="form-box__body">
                    <p class="m-0 mb-10" *ngFor="let subtask of taskVaccination.subtasks">
                        <mat-checkbox [(ngModel)]="subtask.completed"
                            (ngModelChange)="updateAllComplete('vaccination')">
                            {{subtask.name}}
                        </mat-checkbox>
                    </p>
                </div>
            </div>

            <div class="form-box">
                <div class="form-box__header">
                    <h3 class="title">
                        <mat-checkbox
                            [ngClass]="currentlySuppressedControl ? 'example-margin' : 'example-margin-red'"
                            [checked]="allCompleteCurrentlySuppressed"
                            [indeterminate]="someComplete('currentlySuppressed')"
                            (change)="setAll('currentlySuppressed', $event.checked)">
                            {{taskCurrentlySuppressed.name}}
                        </mat-checkbox>
                    </h3>
                </div>
                <div class="form-box__body">
                    <p class="m-0 mb-10" *ngFor="let subtask of taskCurrentlySuppressed.subtasks">
                        <mat-checkbox [(ngModel)]="subtask.completed"
                            (ngModelChange)="updateAllComplete('currentlySuppressed')">
                            {{subtask.name}}
                        </mat-checkbox>
                    </p>
                </div>
            </div>

            <div class="form-box">
                <div class="form-box__header">
                    <h3 class="title">
                        <mat-checkbox
                            [ngClass]="opportunisticInfectionsControl ? 'example-margin' : 'example-margin-red'"
                            [checked]="allCompleteOpportunisticInfections"
                            [indeterminate]="someComplete('opportunisticInfections')"
                            (change)="setAll('opportunisticInfections', $event.checked)">
                            {{taskOpportunisticInfections.name}}
                        </mat-checkbox>
                    </h3>
                </div>
                <div class="form-box__body">
                    <ng-multiselect-dropdown
                        [placeholder]="'Comorbidities'"
                        [settings]="dropdownSettings"
                        [data]="dropdownList"
                        [(ngModel)]="selectedItems"
                        (onSelect)="onItemSelect($event)"
                        (onDeSelect)="onDeSelect($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="d-flex justify-content-center mt-24">
    <button class="filled-btn secondary lg rounded px-45" (click)="onOutcomes()">{{'TRIAL.OUTCOMES' | translate}}</button>
</div>
