import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuppressionListComponent } from './pages/suppression-list/suppression-list.component';
import { AdministratorRoutingModule } from './administrator-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SuppressionUploadComponent } from './component/suppression-upload/suppression-upload.component';
import { ArtCodeListComponent } from './pages/art-code-list/art-code-list.component';
import { ArtCodeUploadComponent } from './component/art-code-upload/art-code-upload.component';
import { MutationUploadComponent } from './component/mutation-upload/mutation-upload.component';
import { MutationListComponent } from './pages/mutation-list/mutation-list.component';
import { DrugInteractionListComponent } from './pages/drug-interaction-list/drug-interaction-list.component';
import { DrugInteractionUploadComponent } from './component/drug-interaction-upload/drug-interaction-upload.component';
import { AdverseEventListComponent } from './pages/adverse-event-list/adverse-event-list.component';
import { AdverseEventUploadComponent } from './component/adverse-event-upload/adverse-event-upload.component';
import { CustomCityListComponent } from './pages/custom-city-list/custom-city-list.component';
import { CustomCityUploadComponent } from './component/custom-city-upload/custom-city-upload.component';
import { SubscriptionPlanListComponent } from './pages/subscription-plan-list/subscription-plan-list.component';
import { SubscriptionPlanDetailComponent } from './component/subscription-plan-detail/subscription-plan-detail.component';
import { FeedbackListComponent } from './pages/feedback-list/feedback-list.component';
import { SurveyResultListComponent } from './pages/survey-result-list/survey-result-list.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { UserDetailComponent } from './component/user-detail/user-detail.component';
import { AuditlyticsPermissionListComponent } from './pages/auditlytics-permission-list/auditlytics-permission-list.component';
import { AuditlyticsPermissionDetailComponent } from './component/auditlytics-permission-detail/auditlytics-permission-detail.component';
import { ClinicianPatientListComponent } from './pages/clinician-patient-list/clinician-patient-list.component';
import { KinPatientListComponent } from './pages/kin-patient-list/kin-patient-list.component';
import { InvitationListAdminComponent } from './pages/invitation-list-admin/invitation-list-admin.component';
import { AtRiskListComponent } from './pages/at-risk-list/at-risk-list.component';



@NgModule({
  declarations: [
    SuppressionListComponent,
    SuppressionUploadComponent,
    ArtCodeListComponent,
    ArtCodeUploadComponent,
    MutationListComponent,
    MutationUploadComponent,
    DrugInteractionListComponent,
    DrugInteractionUploadComponent,
    AdverseEventListComponent,
    AdverseEventUploadComponent,
    CustomCityListComponent,
    CustomCityUploadComponent,
    SubscriptionPlanListComponent,
    SubscriptionPlanDetailComponent,
    FeedbackListComponent,
    SurveyResultListComponent,
    UserListComponent,
    UserDetailComponent,
    AuditlyticsPermissionListComponent,
    AuditlyticsPermissionDetailComponent,
    ClinicianPatientListComponent,
    KinPatientListComponent,
    InvitationListAdminComponent,
    AtRiskListComponent
  ],
  imports: [
    CommonModule,
    AdministratorRoutingModule,
    SharedModule
  ]
})
export class AdministratorModule { }
