/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/shared/component/base.component';
import { UserSelectionDialogComponent } from 'src/app/shared/component/user-selection-dialog/user-selection-dialog.component';
import { InvitationType } from 'src/app/shared/model/enum/invitation-type';
import { PageMode } from 'src/app/shared/model/enum/page-mode';
import { Role } from 'src/app/shared/model/enum/role';
import { InvitationRequest } from 'src/app/shared/model/invitation';
import { OrganisationBranch } from 'src/app/shared/model/organisation';
import { InvitationService } from 'src/app/shared/services/invitation.service';
import { OrganisationBranchService } from 'src/app/shared/services/organisation-branch.service';
import { NotificationService } from '../../notification/service/notification.service';

@Component({
  selector: 'app-send-invitation-dialog',
  templateUrl: './send-invitation-dialog.component.html',
  styleUrls: ['./send-invitation-dialog.component.scss']
})
export class SendInvitationDialogComponent extends BaseComponent implements OnInit {

  InvitationType: typeof InvitationType = InvitationType;
  PageMode: typeof PageMode = PageMode;

  pageMode: PageMode = PageMode.CREATE;

  invitationTypeOptions: string[];
  baseInvitationTypeTranslationKey = 'ENUM.INVITATION_TYPE';

  organisationBranchList: OrganisationBranch[] = [];
  selectedClinicians = [];
  selectedOrganisationBranchId;

  formGroup: FormGroup = new FormGroup({
    invitationType: new FormControl('', [Validators.required]),
    invitorNote: new FormControl('', [Validators.required, Validators.maxLength(150)]),
    organisationBranchId: new FormControl('', [Validators.required]),
    organisationClinicianList: new FormControl({value: '', disabled: true}, [Validators.required]),
  });

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SendInvitationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private invitationService: InvitationService,
    private organisationBranchService: OrganisationBranchService,
  ) { super(); }

  ngOnInit(): void {
    const itOptions: string[] = Object.keys(InvitationType);
    this.invitationTypeOptions = itOptions.slice(itOptions.length / 2);

    if (this.data.selectableInvitationTypes) {
      this.invitationTypeOptions = this.data.selectableInvitationTypes.map(it => InvitationType[it]);
    }

    if (this.data.invitationType >= 0) {
      this.data.invitationType = InvitationType[this.data.invitationType];
    }

    if (this.data.baseInvitationTypeTranslationKey) {
      this.baseInvitationTypeTranslationKey = this.data.baseInvitationTypeTranslationKey;
    }

    if (this.data.organisationId) {
      this.organisationBranchService.getAllOrganisationBranches(this.data.organisationId).subscribe(page => {
        this.organisationBranchList = page.content?.filter(branch => branch.id === this.data.organisationBranchId);
        if (this.data.organisationOwner === true) {
          this.organisationBranchList = page.content;
        }
      });
    }

    this.formGroup
    .get('organisationBranchId')
    .valueChanges
    .subscribe((obId) => {
      this.selectedOrganisationBranchId = obId;
      this.selectedClinicians = null;
      this.formGroup.get('organisationClinicianList').setValue('');
    });
  }
  
  isPatientInvitation() {
    return (this.data.invitationType === InvitationType[InvitationType.BECOME_PATIENT_OF_MY_ORGANISATION]
      || this.data.invitationType === InvitationType[InvitationType.BECOME_PATIENT_OF_MY_ORGANISATION_AND_ME]);
  }

  sendInvitation() {
    if (this.formGroup.valid) {

      if (this.isPatientInvitation()) {
          if (!this.selectedClinicians || this.selectedClinicians.length <= 0) {
            this.notificationService.error(this.translate.instant('SEND_INVITATION.NOTIFICATION.INVITATION_CLINICIAN_LIST.REQUIRED'));
            return;
          }
      }

      const rawVal = this.formGroup.getRawValue();
      const item = { ...rawVal};

      item.emailOrPhoneNumber = item.emailOrPhoneNumber.emailOrPhoneType === 'EMAIL' ? item.emailOrPhoneNumber.email : item.emailOrPhoneNumber.phone;

      const request = new InvitationRequest();
      request.invitorNote = item.invitorNote;
      request.type = item.invitationType;
      request.organisationBranchId = item.organisationBranchId;
      if (this.selectedClinicians) {
        request.clinicianIdList = this.selectedClinicians.map(c => c.id);
      }

      if (this.data.inviteeId && this.data.inviteeId > 0) {
        this.invitationService.sendInvitationToRegistered(this.data.inviteeId, request).subscribe(invitation => {
          this.closeDialog('sent', item.emailOrPhoneNumber);
        }, error => {
          console.log('Error on sending invitation : ' + JSON.stringify(error));
          this.closeDialog('sent-error', item.emailOrPhoneNumber);
        });
      } else {
        this.invitationService.sendInvitationToUnregistered(item.emailOrPhoneNumber, request).subscribe(invitation => {
          this.closeDialog('sent', item.emailOrPhoneNumber);
        }, error => {
          console.log('Error on sending invitation : ' + JSON.stringify(error));
          this.closeDialog('sent-error', item.emailOrPhoneNumber);
        });

      }
    }

  }

  openClinicianSelectionDialog() {
    const dialogConfig = this.createDialogConfig({
      searchInOrganisationBranch: true,
      organisationBranchId: this.selectedOrganisationBranchId,
      role: Role.CLINICIAN, title: 'Select Clinicians to Connect Patient',
      rowSelectionType: 'multiple',
      defaultRowSelections: this.selectedClinicians
    });
    const userSelectionDialogRef = this.dialog.open(UserSelectionDialogComponent, dialogConfig);

    userSelectionDialogRef.afterClosed().subscribe(data => {
      if (data?.selectedRows) {
        this.selectedClinicians = data.selectedRows;
        const selectedCliniciansStr = this.selectedClinicians.map(c => c.firstName + ' ' + c.lastName);
        this.formGroup.get('organisationClinicianList').setValue(selectedCliniciansStr);
      }
    });
  }

  closeDialog(closeStatus: string, emailOrPhone: string) {
    if (closeStatus === 'sent') {
      this.notificationService.success(this.translate.instant('SEND_INVITATION.NOTIFICATION.INVITATION_SEND.SUCCESS', { email: emailOrPhone }));
    } else {
      this.notificationService.error(this.translate.instant('SEND_INVITATION.NOTIFICATION.INVITATION_SEND.FAILED', { email: emailOrPhone }));
    }
    this.dialogRef.close({ status: closeStatus });
  }
  
  getFormControl(group: FormGroup, controlName: string){
    return group.get(controlName) as FormControl
  }

}
