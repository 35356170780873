import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { ClinicalOutcome, ClinicalOutcomeDashboardResponse } from '../model/clinical-outcome';

@Injectable({
    providedIn: 'root'
})
export class ClinicalOutcomeService {

    private baseUrl = '/clinical-outcomes';

    constructor(private http: HttpClient) {
    }

    createClinicalOutcome(clinicalOutcome: ClinicalOutcome): Observable<ClinicalOutcome> {
        return this.http.post<ClinicalOutcome>(environment.url + `${this.baseUrl}`, clinicalOutcome)
        .pipe(map((res: any) => res || {}));
    }

    updateClinicalOutcome(id: number, clinicalOutcome: ClinicalOutcome): Observable<ClinicalOutcome> {
        return this.http.put<ClinicalOutcome>(environment.url + `${this.baseUrl}/${id}`, clinicalOutcome)
        .pipe(map((res: any) => res || {}));
    }

    getClicalOutcomeResult(clinicalOutcomeId: number): Observable<ClinicalOutcomeDashboardResponse> {
        return this.http.get<ClinicalOutcomeDashboardResponse>(environment.url + `${this.baseUrl}/${clinicalOutcomeId}`)
            .pipe(map((res: any) => res || {}));
    }

    getLatestClicalOutcomeResult(): Observable<ClinicalOutcomeDashboardResponse> {
        return this.http.get<ClinicalOutcomeDashboardResponse>(environment.url + `${this.baseUrl}/latest`)
            .pipe(map((res: any) => res || {}));
    }

}
