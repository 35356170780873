import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { faUsers, faInfoCircle, faEnvelope, faPaperPlane, faUserDoctor } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-user-profile-main',
  templateUrl: './user-profile-main.component.html',
  styleUrls: ['./user-profile-main.component.scss']
})
export class UserProfileMainComponent implements OnInit {
  faInfoCircle = faInfoCircle; faEnvelope = faEnvelope; faPaperPlane = faPaperPlane; faUserDoctor=faUserDoctor; faUsers=faUsers;

  public config: PerfectScrollbarConfigInterface = {};

  sidePanelOpened = true;
  displayMode = 'default';
  p = 1;

  private mediaMatcher: MediaQueryList = matchMedia(`(max-width: 960px)`);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
  }

  isOver(): boolean {
    return this.mediaMatcher.matches;
  }

  menuChanged(item) {
    this.router.navigate([item]);
  }
}
