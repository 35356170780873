<div class="toast-container toast-top-right">  
    <div class="toast" aria-live="polite" *ngFor="let item of notifications">  
        <div class="toast-message-container">
            <div class="toast-image {{cssClass(item)}}"></div>  
            <span class="toast-message">{{item.message}}</span>  
        </div>  
        <div class="toast-button-container">
            <button class="toast-close-button" (click)="removeNotification(item)">{{ 'GENERAL.OK' | translate }}</button>  
        </div>
    </div>  
</div> 