import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationMessageComponent } from '../component/validation-message/validation-message.component';
import { CustomMaterialModule } from './material/material.module';
import { EmailOrPhoneNumberComponent } from '../component/email-or-phone-number/email-or-phone-number.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { PasswordFormFieldComponent } from '../component/password-form-field/password-form-field.component';
import { SearchableDropdownComponent } from '../component/searchable-dropdown/searchable-dropdown.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CustomMaterialModule,
        TranslateModule,
        NgxMatIntlTelInputComponent,
        NgxMatSelectSearchModule
    ],
    declarations: [
        ValidationMessageComponent,
        EmailOrPhoneNumberComponent,
        PasswordFormFieldComponent,
        SearchableDropdownComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CustomMaterialModule,
        TranslateModule,
        NgxMatSelectSearchModule,
        NgxMatIntlTelInputComponent,
        ValidationMessageComponent,
        EmailOrPhoneNumberComponent,
        PasswordFormFieldComponent,
        SearchableDropdownComponent
    ]
})
export class SharedComponentsModule {

}
