/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Page, Pageable, PageableSort } from '../model/pagination';
import { map } from 'rxjs/operators';
import { createPageParams } from '../util';
import { OrganisationalSubscription } from '../model/subscription';

@Injectable({
    providedIn: 'root'
})
export class OrganisationalSubscriptionService {

    private baseUrl = '/subscription-svc/v1/organisational-subscriptions';

    constructor(private http: HttpClient) {
    }

    subscribeOrganisation(planId: number): Observable<OrganisationalSubscription> {
        return this.http.post<OrganisationalSubscription>(environment.url + `${this.baseUrl}/plan/${planId}`, null)
        .pipe(map((res: any) => res || {}));
    }

    getActiveSubscriptionOfOrganisation(): Observable<OrganisationalSubscription> {
        return this.http.get<OrganisationalSubscription>(environment.url + `${this.baseUrl}/organisation/active`)
            .pipe(map((res: any) => res || {}));
    }

    getSubscriptionById(organisationalSubscriptionId: number): Observable<OrganisationalSubscription> {
        return this.http.get<OrganisationalSubscription>(environment.url + `${this.baseUrl}/${organisationalSubscriptionId}`)
            .pipe(map((res: any) => res || {}));
    }

    cancelSubscription(): Observable<void> {
        return this.http.delete<void>(environment.url + `${this.baseUrl}/cancel-subscription`)
            .pipe(map((res: any) => res || {}));
    }

}
