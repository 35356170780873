import { Component, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faBuilding, faComments } from '@fortawesome/free-solid-svg-icons';
import { DEFAULT_USER_PHOTO } from 'src/app/shared/constants';
import { RouteStateService } from 'src/app/shared/state/route-state.services';
import { Invitation } from 'src/app/shared/model/invitation';
import { InvitationService } from 'src/app/shared/services/invitation.service';
import { PushNotificationService } from 'src/app/shared/services/push-notification.service';
import { ChatRoomResponse } from 'src/app/shared/model/push-notification';
import { PageableSort } from 'src/app/shared/model/pagination';
import { DocumentStateService } from 'src/app/shared/state/document-state.service';

@Component({
  selector: 'app-vertical-header',
  templateUrl: './vertical-header.component.html',
  styleUrls: ['./vertical-header.component.scss']
})

export class VerticalAppHeaderComponent implements OnInit {
  faBuilding = faBuilding;
  faComments = faComments;

  public config: PerfectScrollbarConfigInterface = {};
  photoUrl = DEFAULT_USER_PHOTO;

  waitingInvitationCount: number;
  invitations: Invitation[];

  unreadPushNotificationCount: number;
  chatRoomResponseList: ChatRoomResponse[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private authenticationStateService: AuthenticationStateService,
    private routeStateService: RouteStateService,
    private invitationService: InvitationService,
    private pushNotificationService: PushNotificationService,
    private documentStateService: DocumentStateService
  ) {}

  ngOnInit() {
    this.authenticationStateService.getLoginChangeObservable().subscribe(info => {
      if (info) {
        this.photoUrl = info.photoUrl ? info.photoUrl : DEFAULT_USER_PHOTO;
      }
    });

    this.pushNotificationService.getUnreadPushNotificationCountObservable().subscribe(count => {
      this.updateUnreadMessageCount(count);
    });

    this.routeStateService.getRouteChange().subscribe(routes => {
      if (routes) {
        if(this.authenticationStateService.isAuthenticated()) {
          this.getMyUnreadPushNotificationCount();
          this.getWaitingInvitationCount();
        }
      }
    });

    this.documentStateService.getDocumentVisibilityChange().subscribe(data => {
      if (data.currentState === 'visible') {
        this.getMyUnreadPushNotificationCount();
        this.getWaitingInvitationCount();
      }
    });
  }

  updateUnreadMessageCount(count) {
    this.unreadPushNotificationCount =count > 0 ? count : 0 ;
  }

  getMyUnreadPushNotificationCount() {
    this.pushNotificationService.getMyUnReadPushNotificationCount().subscribe(response => {
        this.updateUnreadMessageCount(response?.unReadNotificationCount);
    });
  }

  listMyUnreadPushNotifications() {
    this.pushNotificationService.listMyUnReadPushNotifications(0, 5, new PageableSort({ property: 'id', direction: 'DESC' })).subscribe(page => {
      if (page) {
        this.chatRoomResponseList = page.content;
        this.updateUnreadMessageCount(page.totalElements);
      }
    });
  }

  getWaitingInvitationCount() {
    this.invitationService.getWaitingInvitationsCount().subscribe(count => {
      if (count) {
        this.waitingInvitationCount = count > 0 ? count : 0;
      }
    });
  }

  loadWaitingInvitations() {
    this.invitationService.getWaitingInvitations().subscribe(invitationsPage => {
      if (invitationsPage) {
        this.invitations = invitationsPage.content;
        this.waitingInvitationCount = invitationsPage.totalElements > 0 ? invitationsPage.totalElements : 0 ;
      }
    });
  }

  logout() {
    this.authenticationStateService.logout();
  }

  goToAccountUpdatePage() {
    this.router.navigateByUrl('user-profile/update-account');
  }

  goToOrganisationDetailPage() {
    this.router.navigateByUrl('organisation/detail');
  }

  goToChatPage() {
    this.router.navigateByUrl('chat/main');
  }

  goToIncomingInvitations() {
    this.router.navigateByUrl('user-profile/incoming-invitation');
  }
  openUserManual() {
    const userManualLink = 'https://www.bsmartlytics.com/files/BSmartlytics_Platform_User_Manual.pdf';
    window.open(userManualLink, '_blank');
  }
}
