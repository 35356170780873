<div class="login-main">
  <div class="login-content register">
    <div class="login-content__bg"></div>
    <div class="login-content__form">
    <div class="logo-container">
      <img class="register-logo" width="200" height="100" src="/assets/images/logo-wide.svg" alt="logo">
    </div>
    <div class="register-title-container">
      <h3 class="">{{ 'REGISTER_FORM.TITLE' | translate }}</h3>
      <small>{{ 'REGISTER_FORM.SUBTITLE' | translate }}</small>
    </div>

    <form [formGroup]="formGroup" autocomplete="off" novalidate (ngSubmit) = "register()">

      <app-email-or-phone-number></app-email-or-phone-number>

      <div class="pb-1 cust-form-field-container">
        <mat-form-field class="cust-form-field">
          <mat-label>{{ 'REGISTER_FORM.FIRST_NAME' | translate }}</mat-label>
          <input matInput formControlName="firstName">
        </mat-form-field>
        <app-validation-message [control]="formGroup.get('firstName')"></app-validation-message>
      </div>

      <div class="pb-1 cust-form-field-container">
        <mat-form-field class="cust-form-field">
          <mat-label>{{ 'REGISTER_FORM.LAST_NAME' | translate }}</mat-label>
          <input matInput formControlName="lastName">
        </mat-form-field>
        <app-validation-message [control]="formGroup.get('lastName')"></app-validation-message>
      </div>

      <app-password-form-field 
      [passwordLabel]="'REGISTER_FORM.PASSWORD'"
      [passwordFormGroupName]="'passwordFormGroup'"
      [verifyPasswordLabel]="'REGISTER_FORM.PASSWORD_VERIFY'"
      [enableVerifyPassword]="true">
     </app-password-form-field>

      <div class="pb-1 cust-form-field-container">
        <mat-form-field appearance="outline" class="cust-form-field">
          <mat-label>{{ 'REGISTER_FORM.GENDER' | translate }}</mat-label>
          <mat-select placeholder="{{ 'REGISTER_FORM.GENDER' | translate }}" formControlName="gender">
            <mat-option *ngFor="let gen of genderOptions" [value]="gen">
              {{ 'ENUM.GENDER.' + gen  | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-validation-message [control]="formGroup.get('gender')"></app-validation-message>
      </div>

      <div class="pb-1 cust-form-field-container">
        <mat-form-field appearance="outline" class="cust-form-field">
          <mat-label>{{ 'REGISTER_FORM.USER_TYPE' | translate }}</mat-label>
          <mat-select placeholder="{{ 'REGISTER_FORM.USER_TYPE' | translate }}" formControlName="userType">
            <mat-option *ngFor="let role of roleOptions" [value]="role">
              {{ 'ENUM.ROLE.' + role  | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-validation-message [control]="formGroup.get('userType')"></app-validation-message>
      </div>

      <div class="pb-1 cust-form-field-container">
        <mat-checkbox color="primary" formControlName="otpEnabled">{{ 'REGISTER_FORM.OTP_ENABLED' | translate }}</mat-checkbox>
        <app-validation-message [control]="formGroup.get('otpEnabled')"></app-validation-message>
      </div>
      
      <div class="register-terms-of-use-container">
        <mat-checkbox color="primary" formControlName="termsOfServiceChecked">
          {{ 'REGISTER_FORM.TERMS_OF_SERVICE1' | translate }}
          <a (click)="goToTermsOfUse()" target="_blank">{{ 'REGISTER_FORM.TERMS_OF_SERVICE2' | translate }}</a>
        </mat-checkbox>
        <app-validation-message [control]="formGroup.get('termsOfServiceChecked')"></app-validation-message>
      </div>

      <div class="pb-1 cust-form-field-container">
        <re-captcha
        (resolved)="captchaResolved($event)"
        siteKey="{{recaptchaSiteKey}}"
        formControlName="captcha"></re-captcha>
      </div>

    </form>


    <button mat-raised-button color="warn" type="submit" class="btn-block btn-lg m-t-20 register-button" (click) = "register()"
    [disabled]="!formGroup.valid">{{ 'REGISTER_FORM.REGISTER_LABEL' | translate }}</button>

    <div class="register-login-container">
      <span>{{ 'REGISTER_FORM.LOGIN_DESC' | translate }}</span>
      <a (click)="goToLogin()">{{ 'REGISTER_FORM.LOGIN_LABEL' | translate }}</a>
    </div>
    </div>
  </div>

</div>
