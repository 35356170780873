import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationStateService } from '../../state/authentication-state.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    excludedUrl = [
        'https://api.cloudinary.com/v1_1'
    ];

    private authService: AuthenticationStateService;
    constructor(private injector: Injector) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.excludedUrl.some(x => request.url.includes(x))) {
            return next.handle(request);
        } else {
            this.authService = this.injector.get(AuthenticationStateService);
            const token: string = this.authService.getAccessToken();
            if (token != null) {
                request = request.clone({
                    setHeaders: {
                        authorization: `${token}`
                    }
                });
            }
            return next.handle(request);
        }
    }
}
