<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-wrapper" [ngClass]="{'blue': blue}">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <nav class="top-nav">
    <div class="d-flex align-items-center">
      <!-- ============================================================== -->
      <!-- Logo - style you can find in header.scss -->
      <!-- ============================================================== -->
      <button href="/" class="top-nav__menu" (click)="snav.toggle() && clickEvent()">
          <i class="bs-menu"></i>
      </button>

      <a class="top-nav__logo" href="/">
          <img class="top-nav__logo--symbol" src="assets/images/logo-symbol.svg" alt="">
          <img class="top-nav__logo--text" src="assets/images/logo-text.svg" alt="">
      </a>
      <!-- ============================================================== -->
      <!-- sidebar toggle -->
      <!-- ============================================================== -->

      <!-- <button mat-icon-button (click)="snav.toggle() && clickEvent()" class="sidebar-toggle" value="sidebarclosed">
        <mat-icon>menu</mat-icon>
      </button> -->

      
    </div>
    <app-vertical-header></app-vertical-header>

  </nav>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #snav id="snav" class="pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0"
      [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true"
      (close)="sidebarOpened = false">

      <perfect-scrollbar class="scbar" [config]="config">
        <app-vertical-sidebar class="app-sidebar"></app-vertical-sidebar>
      </perfect-scrollbar>

    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''">

      <!-- <app-breadcrumb></app-breadcrumb> -->
      <div class="page-content">
        <router-outlet>
          <!--TODO app-spinner></app-spinner-->
        </router-outlet>

      </div>
    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>