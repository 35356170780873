/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { ComponentType } from '@angular/cdk/portal';
import { Component, Input, OnInit} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import { DEFAULT_ORG_BRANCH_ICON } from 'src/app/shared/constants';
import { PageMode } from 'src/app/shared/model/enum/page-mode';
import { OrganisationBranch, OrganisationBranchFilterRequest } from 'src/app/shared/model/organisation';
import { LoginResponse } from 'src/app/shared/model/user-profile';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { OrganisationBranchService } from 'src/app/shared/services/organisation-branch.service';
import { OrganisationService } from 'src/app/shared/services/organisation.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';
import { hasUserAnyClinicianRelatedRole } from 'src/app/shared/util';
import { OrganisationBranchDetailComponent } from '../../component/organisation-branch-detail/organisation-branch-detail.component';

@Component({
  selector: 'app-organisation-branch-list',
  templateUrl: './organisation-branch-list.component.html',
  styleUrls: ['./organisation-branch-list.component.scss']
})
export class OrganisationBranchListComponent extends BaseListComponent implements OnInit {
  PageMode: typeof PageMode = PageMode;

  organisationId: number = null;
  pageMode: PageMode;
  loginInfo: LoginResponse;

  pageLimitOptions = [6, 12, 24, 48, 96];
  sortFields = ['name', 'organisationName'];

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private organisationService: OrganisationService,
    private authenticationStateService: AuthenticationStateService,
    private organisationBranchService: OrganisationBranchService,
  ) {
    super();
   }

  ngOnInit(): void {

    this.page.size = this.pageLimitOptions[0];

    this.loginInfo = this.authenticationStateService.getLoginInfo();
    // this.isUserClinician = hasUserAnyClinicianRelatedRole(this.loginInfo.roles);

    if (this.loginInfo.clinicianOrganisationId) {
      this.pageMode = this.loginInfo.organisationOwner === true ? PageMode.EDIT : PageMode.VIEW;
      this.organisationId = this.loginInfo.clinicianOrganisationId;
    }

    if (this.organisationId && this.organisationId > 0) {
      this.loadData();
    }
  }

  loadData() {
    const filterRequest = new OrganisationBranchFilterRequest();
    filterRequest.organisationId = this.organisationId;
    this.organisationBranchService.getOrganisationBranches(this.page.pageNumber, this.page.size, this.sort, this.searchTerm, filterRequest).subscribe(page => {
      this.page.pageNumber = page.number;
      this.page.totalElements = page.totalElements;
      this.page.size = page.size;
      this.rows = page.content;
    });
  }

  getImageUrl(organisationBranch: OrganisationBranch): string {
    return DEFAULT_ORG_BRANCH_ICON;
  }


  onCreateOrganisationBranch() {
    this.openDialog(OrganisationBranchDetailComponent, PageMode.CREATE, this.organisationId, null);
  }

  onEditOrganisationBranch(row: OrganisationBranch) {
    this.openDialog(OrganisationBranchDetailComponent, PageMode.EDIT, this.organisationId, row.id);
  }

  onDeleteOrganisationBranch(row: OrganisationBranch) {
    this.openDialog(OrganisationBranchDetailComponent, PageMode.DELETE, this.organisationId, row.id);
  }

  openDialog<T>(component: ComponentType<T>, pageMode: PageMode, organisationId: number, organisationBranchId: number) {
    const dialogConfig = this.createDialogConfig({pageMode, organisationId, organisationBranchId});
    const dialogRef = this.dialog.open(component, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data?.status === 'ok') {
        this.loadData();
      }
    });
  }


}
