/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/shared/component/base.component';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PageMode } from 'src/app/shared/model/enum/page-mode';
import { AuditlyticsPermissionLevel } from 'src/app/shared/model/enum/auditlytics-permission-level';
import { AuditlyticsPermission } from 'src/app/shared/model/auditlytics-permission';
import { GeoLocationService } from 'src/app/shared/services/geo-location.service';
import { AuditlyticsPermissionService } from 'src/app/shared/services/auditlytics-permission.service';
import { Role } from 'src/app/shared/model/enum/role';
import { UserSelectionDialogComponent } from 'src/app/shared/component/user-selection-dialog/user-selection-dialog.component';
import { OrganisationBranchSelectionDialogComponent } from 'src/app/shared/component/organisation-branch-selection-dialog/organisation-branch-selection-dialog.component';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';
@Component({
  selector: 'app-organisation-auditlytics-permission-detail',
  templateUrl: './organisation-auditlytics-permission-detail.component.html',
  styleUrls: ['./organisation-auditlytics-permission-detail.component.scss']
})
export class OrganisationAuditlyticsPermissionDetailComponent extends BaseComponent implements OnInit {

  AuditlyticsPermissionLevel: typeof AuditlyticsPermissionLevel = AuditlyticsPermissionLevel;
  PageMode: typeof PageMode = PageMode;

  pageMode: PageMode;

  permissionLevelOptions: string[];

  auditlyticsPermission;
  orgasationBranchSelectionButtonEnable = true;

  clinicianOrganisationId;

  formGroup: FormGroup = new FormGroup({
    userFullName: new FormControl({value: '', disabled: true}, [Validators.required, Validators.minLength(2)]),
    permissionLevel: new FormControl('', [Validators.required]),
    organisationBranchIds: new FormControl({value: '', disabled: true})
  });

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<OrganisationAuditlyticsPermissionDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private authenticationStateService: AuthenticationStateService,
    private auditlyticsPermissionService: AuditlyticsPermissionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.permissionLevelOptions = [AuditlyticsPermissionLevel[AuditlyticsPermissionLevel.ORGANISATION_BRANCH]];

    const loginInfo = this.authenticationStateService.getLoginInfo();
    this.clinicianOrganisationId = loginInfo.clinicianOrganisationId;

    if (this.data.row) {
      this.auditlyticsPermission = this.data.row;
    }
    this.pageMode = this.data.pageMode ? this.data.pageMode : PageMode.CREATE;

    if (this.pageMode === PageMode.CREATE) {
      this.auditlyticsPermission = new AuditlyticsPermission();
    }

    if (this.pageMode === PageMode.VIEW || this.pageMode === PageMode.DELETE) {
      this.formGroup.disable();
    }

    if (this.pageMode === PageMode.EDIT) {
      if (this.auditlyticsPermission.permissionLevel) {
        this.updateAllPermissionControlStatus(this.auditlyticsPermission.permissionLevel);
      }
    }

    this.patchForms(this.auditlyticsPermission);

    this.formGroup
    .get('permissionLevel')
    .valueChanges
    .subscribe((pl) => {
      this.updateAllPermissionControlStatus(pl);

    });
  }

  updateAllPermissionControlStatus(pl) {
    const permissionLevels = [AuditlyticsPermissionLevel.ORGANISATION_BRANCH];
    const activePermission = permissionLevels.find(l => AuditlyticsPermissionLevel[l] === pl);

    this.updateControlStatus('organisationBranchIds', AuditlyticsPermissionLevel.ORGANISATION_BRANCH, activePermission, false);
    this.orgasationBranchSelectionButtonEnable = activePermission >= AuditlyticsPermissionLevel.ORGANISATION_BRANCH;

  }

  updateControlStatus(controlName, enumIndex, activePermission, canEnable = true) {
    if (activePermission >= enumIndex) {
      const control = this.formGroup.get(controlName);
      if (canEnable) {
        control.enable();
      }
      control.setValidators([Validators.required]);
      control.updateValueAndValidity();
    } else {
      const control = this.formGroup.get(controlName);
      control.setValue('');
      control.disable();
      control.clearValidators();
      control.updateValueAndValidity();
    }
  }

  onCreate(): void {
    if (this.formGroup.valid) {
      const rawVal = this.formGroup.getRawValue();

      const item = { ...rawVal};

      const selectedUser = this.auditlyticsPermission.user;
      delete item.userFullName;
      item.organisationId = this.auditlyticsPermission.organisationId;
      item.country = this.auditlyticsPermission.country;
      item.state = this.auditlyticsPermission.state;
      item.city = this.auditlyticsPermission.city;

      if (!selectedUser?.id) {
        this.notificationService.error(this.translate.instant('AUDITLYTICS_PERMISSION.DETAIL.NOTIFICATION.VALIDATION.USER_REQUIRED'));
        return;
      }
      
      this.auditlyticsPermissionService.createAuditlyticsPermissionByOrgManager(selectedUser.id, item).subscribe(data => {
        this.notificationService.success(this.translate.instant('AUDITLYTICS_PERMISSION.DETAIL.NOTIFICATION.CREATE.SUCCESS'));
        this.closeDialog(true);
      }, error => {
        console.log('Error on create auditlytics permission : ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('AUDITLYTICS_PERMISSION.DETAIL.NOTIFICATION.CREATE.FAIL'));
      });
    }
  }

  onEdit(): void {
    if (this.formGroup.valid) {
      const rawVal = this.formGroup.getRawValue();
      const item = { ...rawVal};

      delete item.userFullName;
      item.organisationId = this.auditlyticsPermission.organisationId;
      item.country = this.auditlyticsPermission.country;
      item.state = this.auditlyticsPermission.state;
      item.city = this.auditlyticsPermission.city;

      this.auditlyticsPermissionService.updateAuditlyticsPermissionByOrgManager(this.data.row.id, item).subscribe(data => {
        this.notificationService.success(this.translate.instant('AUDITLYTICS_PERMISSION.DETAIL.NOTIFICATION.EDIT.SUCCESS'));
        this.closeDialog(true);
      }, error => {
        console.log('Error on edit auditlytics permission : ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('AUDITLYTICS_PERMISSION.DETAIL.NOTIFICATION.EDIT.FAIL'));
      });
    }
  }

  onDelete(): void {

    this.auditlyticsPermissionService.deleteAuditlyticsPermissionByOrgManager(this.data.row.id).subscribe(() => {
      this.notificationService.success(this.translate.instant('AUDITLYTICS_PERMISSION.DETAIL.NOTIFICATION.DELETE.SUCCESS'));
      this.closeDialog(true);
    }, error => {
      console.log('Error on delete auditlytics permission : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('AUDITLYTICS_PERMISSION.DETAIL.NOTIFICATION.DELETE.FAIL'));
    });
  }

  closeDialog(isChanged: boolean) {
    this.dialogRef.close({status: 'dialog closed', refreshTable: isChanged});
  }

  patchForms(permission) {
    if (permission) {
      permission.userFullName = permission.user ? (permission.user.firstName + ' ' + permission.user.lastName) : '';

      if (permission.organisationBranches) {
        const selectedBranches = permission.organisationBranches;
        permission.organisationBranchIds = selectedBranches.map(b => b.id);
        permission.organisationId = selectedBranches[0].organisation?.id;
      }

      this.formGroup.patchValue(permission);
      this.auditlyticsPermission = permission;
    }
  }

  openUserSelectionDialog() {
    const dialogConfig = this.createDialogConfig({searchInOrganisation: true, organisationId: this.clinicianOrganisationId, role: Role.CLINICIAN, title: 'Select User'});
    const dialogRef = this.dialog.open(UserSelectionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data?.selectedRows) {
        this.auditlyticsPermission.user = data.selectedRows[0];
        this.patchForms(this.auditlyticsPermission);
      }
    });
  }

  openOrganisationBranchSelectionDialog() {
    const rawVal = this.formGroup.getRawValue();
    const defaultFilter = {
      organisationId: this.clinicianOrganisationId
    };
    const dialogConfig = this.createDialogConfig({
      rowSelectionType: 'multiple',
      // defaultRowSelections: this.auditlyticsPermission.organisationBranchIds?.map(bid => ({id: bid})),
      defaultFilter
    });
    const dialogRef = this.dialog.open(OrganisationBranchSelectionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data?.selectedRows) {
        this.auditlyticsPermission.organisationBranches = data.selectedRows;
        this.patchForms(this.auditlyticsPermission);
      }
    });
  }


}
