export class Organisation {
  id: number;
  name: string;
  title: string;
  surveyReminderIntervalCount: number;
  surveyReminderInterval: string;
  active: boolean;
}

export class OrganisationBranch {
  id: number;
  name: string;
  title: string;
  sector: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  town: string;
  address: string;
  phone: string;
  active: boolean;
  organisation: Organisation;
}

export class OrganisationFilterRequest {
  searchTerm: string;
  name: string;
}

export class OrganisationBranchFilterRequest {
  searchTerm: string;
  ciso: string;
  siso: string;
  city: string;
  organisationId: number;
  countryName: string;
  branchIds: number[];
}


