/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import { OtpType } from 'src/app/shared/model/enum/otp-type';
import { LoginResponse, OtpResponse } from 'src/app/shared/model/user-profile';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-otp-confirm',
  templateUrl: './otp-confirm.component.html',
  styleUrls: ['./otp-confirm.component.scss']
})
export class OtpConfirmComponent implements OnInit {
  captcha: string;
  recaptchaSiteKey = environment.recaptchaSiteKey;

  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  OtpType: typeof OtpType = OtpType;

  private returnUrl: string;
  emailOrPhoneNumber;
  otpType;
  passwordResetFormEnabled = false;
  countDownConfig: CountdownConfig = {};

  formGroup: FormGroup = new FormGroup({
    token: new FormControl('', [Validators.required, Validators.minLength(6)]),
    captcha:  new FormControl('', [Validators.required])
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private authenticationStateService: AuthenticationStateService,
    private userAuthService: UserAuthService
  ) { }

  ngOnInit(): void {
    const urlParam = 'returnUrl';
    const otpKey = 'otp';
    this.returnUrl = this.route.snapshot.queryParams[urlParam] || '/dashboard';

    const isAuthenticated = this.authenticationStateService.isAuthenticated();
    if (isAuthenticated) {
      this.router.navigateByUrl(this.returnUrl);
    } else {
      const otp = this.route.snapshot.queryParams[otpKey] || '';
      if (otp) {
        this.formGroup.get('token').setValue(otp);
      }

      this.route.params.subscribe(params => {
        this.emailOrPhoneNumber = params.emailOrPhoneNumber;
        if (this.emailOrPhoneNumber) {
          this.formGroup.enable();
        } else {
          this.formGroup.disable();
          this.notificationService.error('No email or phone number');
        }
        this.setOtpType(params.otpType);
        this.getWaitingOtp();
      });
    }
  }

  setOtpType(type: string) {
    this.otpType = type;

    if (OtpType[OtpType.PASSWORD_RESET] === this.otpType) {
      this.passwordResetFormEnabled = true;
    }
  }

  goToLogin(data: LoginResponse) {
    this.authenticationStateService.notifyLoginInfo(data);
    this.router.navigateByUrl(this.returnUrl);
  }


  getWaitingOtp() {
    this.userAuthService.getWaitingOtp(this.emailOrPhoneNumber).subscribe(data => {
      if (data.type !== this.otpType) {
        // do something
      }
      this.startCountDown(data);
    }, (error) => {
      console.log('Error on getting waiting otp ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('OTP_CONFIRM.GET_WAITING_OTP.ERROR'));
    });
  }

  confirmToken() {
    if (OtpType[OtpType.LOGIN] === this.otpType) {
      this.confirmLogin();
    } else  if (OtpType[OtpType.REGISTRATION] === this.otpType) {
      this.confirmRegistration();
    } else  if (OtpType[OtpType.PASSWORD_RESET] === this.otpType) {
      this.confirmPasswordReset();
    }
  }

  confirmLogin() {
    if (this.formGroup.valid) {
      const token = this.formGroup.get('token').value;
      this.userAuthService.validateOtpAndLogin(this.emailOrPhoneNumber, token, this.captcha).subscribe(res => {
        if (res) {
          this.notificationService.success(this.translate.instant('OTP_CONFIRM.CONFIRM_LOGIN_MESSAGE.SUCCESS'));
          this.goToLogin(res);
        }
      }, (error) => {
        this.formGroup.get('captcha').reset();
        console.log('Error on login confirmation ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('OTP_CONFIRM.CONFIRM_LOGIN_MESSAGE.ERROR'));
      });
    }
  }

  confirmRegistration() {
    if (this.formGroup.valid) {
      const token = this.formGroup.get('token').value;
      this.userAuthService.validateOtpAndRegister(this.emailOrPhoneNumber, token, this.captcha).subscribe(res => {
        if (res) {
          this.notificationService.success(this.translate.instant('OTP_CONFIRM.CONFIRM_REGISTRATION_MESSAGE.SUCCESS'));
          this.router.navigate(['login'], { queryParams: { returnUrl: this.returnUrl }});
        }
      }, (error) => {
        this.formGroup.get('captcha').reset();
        console.log('Error on registration confirmation ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('OTP_CONFIRM.CONFIRM_REGISTRATION_MESSAGE.ERROR'));
      });
    }
  }

  confirmPasswordReset() {
    if (this.formGroup.valid) {
      const token = this.formGroup.get('token').value;
      const password = this.formGroup.get('passwordFormGroup').get('password').value;;
      this.userAuthService.validateOtpAndResetPassword(this.emailOrPhoneNumber, token, password, this.captcha).subscribe(res => {
        if (res) {
          this.notificationService.success(this.translate.instant('OTP_CONFIRM.CONFIRM_RESET_PASSWORD_MESSAGE.SUCCESS'));
          this.router.navigate(['login'], { queryParams: { returnUrl: this.returnUrl }});
        }
      }, (error) => {
        this.formGroup.get('captcha').reset();
        console.log('Error on password reset confirmation ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('OTP_CONFIRM.CONFIRM_RESET_PASSWORD_MESSAGE.ERROR'));
      });
    }
  }

  regenerateOtp() {
      if (!this.captcha) {
        this.notificationService.error(this.translate.instant('NOTIFICATION.ERROR.INVALID_CAPTCHA'));
        return;
      }
      this.userAuthService.regenerateOtp(this.emailOrPhoneNumber, this.otpType, this.captcha).subscribe(res => {
        if (res) {
          this.formGroup.get('token').setValue('');
          this.startCountDown(res);
          this.notificationService.success(this.translate.instant('OTP_CONFIRM.RESEND_MESSAGE.SUCCESS'));
        }
      }, (error) => {
        console.log('Error on resend confirmation ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('OTP_CONFIRM.RESEND_MESSAGE.ERROR'));
      });
  }

  startCountDown(otpResponse: OtpResponse) {
    const expire = new Date(otpResponse.expireAt);
    const now = new Date();

    if (now.getTime() <= expire.getTime()) {
      const config: CountdownConfig = {format: 'HH:mm:ss', stopTime: expire.getTime()};
      this.countDownConfig = config;
    } else {
      this.notificationService.error(this.translate.instant('OTP_CONFIRM.GET_WAITING_OTP.EXPIRE_ERROR'));
    }
  }

  captchaResolved(capthcaResult) {
    this.captcha = capthcaResult;
  }
}
