export class SimulationList {
    userResponse: UserResponse;
    simulations: Array<Simulation>;
}

export class UserResponse {
    id: number;
    name: string;
    email: string;
    roles: number;

    country: string;
    countryCode: string;
    countryFlag: string;
    countryFlagEmojiUnicode: string;
}

export class Simulation {
    id: number;

    years: number;
    simCount: number;

    percentageKnowStatus: number;
    percentageArt: number;
    percentageViralSuppression: number;

    mortalityRatio: number;
    prevalenceRatio: number;

    demandNaive: number;
    demandExperienced: number;
    demandPrevention: number;
    demandIncrease: number;
    demandIncreasePrevention: number;

    artFirstCost: number;
    artSecondCost: number;
    laboratoryCost: number;
    overheadCost: number;
    preventionCost: number;

    percentageMale: number;
    percentageFemale: number;
    percentageChildren: number;
    percentageAdolescent: number;
    percentageAdults: number;
    percentageOlderAdults: number;
    percentagePregnant: number;
    percentagePrep: number;
    percentagePep: number;

    minReceptionDurationNaive: number;
    maxReceptionDurationNaive: number;
    minReceptionDurationExperienced: number;
    maxReceptionDurationExperienced: number;
    minReceptionDurationPrevention: number;
    maxReceptionDurationPrevention: number;

    minDiagnoseDurationNaive: number;
    maxDiagnoseDurationNaive: number;
    minDiagnoseDurationExperienced: number;
    maxDiagnoseDurationExperienced: number;
    minDiagnoseDurationPrevention: number;
    maxDiagnoseDurationPrevention: number;

    minCounsellingDurationNaive: number;
    maxCounsellingDurationNaive: number;
    minCounsellingDurationExperienced: number;
    maxCounsellingDurationExperienced: number;
    minCounsellingDurationPrevention: number;
    maxCounsellingDurationPrevention: number;

    minMonitoringDurationNaive: number;
    maxMonitoringDurationNaive: number;
    minMonitoringDurationExperienced: number;
    maxMonitoringDurationExperienced: number;
    minMonitoringDurationPrevention: number;
    maxMonitoringDurationPrevention: number;

    visitTimes: Array<VisitTime>;
    arts: Array<Art>;
    resources: Array<Resource>;
}


export class SimulationRunPesponse {
    resultKey: string;
}

export class SimulationDemandUpdate {

    years: number;

    demandIncreaseDefault: number;

    simulationId: number;
    demandExperienced: number;
    demandNaive: number;
    demandPrevention: number;
    demandIncrease: number;
    demandIncreasePrevention: number;
    simCount: number;

    scenarioId: number;
    scDemandExperienced: number;
    scDemandNaive: number;
    scDemandPrevention: number;
    scDemandIncrease: number;
    scDemandIncreasePrevention: number;
    scSimCount: number;
}

export class SimulationUnaidsUpdate {

    years: number;

    percentageKnowStatusDefault: number;
    percentageArtDefault: number;
    percentageViralSuppressionDefault: number;
    mortalityRatioDefault: number;
    prevalenceRatioDefault: number;

    simulationId: number;
    percentageKnowStatus: number;
    percentageArt: number;
    percentageViralSuppression: number;
    demandNaive: number;
    demandExperienced: number;

    mortalityRatio: number;
    prevalenceRatio: number;

    scenarioId: number;
    scPercentageKnowStatus: number;
    scPercentageArt: number;
    scPercentageViralSuppression: number;
    demandNaiveSc: number;
    scDemandExperienced: number;
    scNewDiagnosis: number;
    scDemandNaiveCalculated: number;
    scDemandExperiencedCalculated: number;
}

export class SimulationResourceUpdate {
    doctor: Resource;
    pharmacist: Resource;
    medicalLabScientist: Resource;
    hivSpecialistNurse: Resource;
    dietician: Resource;
    generalNurse: Resource;
    counselor: Resource;

    doctorSc: Resource;
    pharmacistSc: Resource;
    medicalLabScientistSc: Resource;
    hivSpecialistNurseSc: Resource;
    dieticianSc: Resource;
    generalNurseSc: Resource;
    counselorSc: Resource;
}

export class SimulationCostUpdate {
    simulationId: number;
    artFirstCost: number;
    artSecondCost: number;
    laboratoryCost: number;
    overheadCost: number;
    preventionCost: number;

    scenarioId: number;
    scArtFirstCost: number;
    scArtSecondCost: number;
    scLaboratoryCost: number;
    scOverheadCost: number;
    scPreventionCost: number;

    artFirstCostDefault: number;
    artSecondCostDefault: number;
    laboratoryCostDefault: number;
    overheadCostDefault: number;
    preventionCostDefault: number;
}

export class SimulationStaffUpdate {
    simulationId: number;
    doctorNumber: number;
    pharmacistNumber: number;
    medicalLabScientistNumber: number;
    hivSpecialistNurseNumber: number;
    dieticianNumber: number;
    generalNurseNumber: number;
    counselorNumber: number;
    allocation: number;

    scenarioId: number;
    scDoctorNumber: number;
    scPharmacistNumber: number;
    scMedicalLabScientistNumber: number;
    scHivSpecialistNurseNumber: number;
    scDieticianNumber: number;
    scGeneralNurseNumber: number;
    scCounselorNumber: number;
    scAllocation: number;
}

export class SimulationCountrySelection {
    costs: Array<CountryCost>;
    selectedCost: CountryCost;
    updateDefault: boolean;
    userId: number;
    country: string;
    code: string;
    flag: string;
}

export class SimulationCountryUpdate {
    userId: number;
    country: string;
    code: string;
    flag: string;
    updateDefault: boolean;
    artFirstCost: number;
    artSecondCost: number;
    laboratoryCost: number;
    overheadCost: number;
    preventionCost: number;

    percentageKnowStatus: number;
    percentageArt: number;
    percentageViralSuppression: number;
    mortalityRatio: number;
    prevalenceRatio: number;
}

export class CountryCostListResponse {
    costs: Array<CountryCost>;
}

export class CountryCost {
    id: number;
    country: string;
    region: string;
    code: string;
    flag: string;

    artFirstCost: number;
    artSecondCost: number;
    laboratoryCost: number;
    overheadCost: number;
    preventionCost: number;

    percentageKnowStatus: number;
    percentageArt: number;
    percentageViralSuppression: number;
    mortalityRatio: number;
    prevalenceRatio: number;

}

export class VisitTimeListResponse {
    visits: Array<VisitTime>;
}

export class SimulationServiceUpdate {

    allVisitTimes: Array<VisitTime>;

    simulationId: number;
    percentageMale: number;
    percentageFemale: number;
    percentageChildren: number;
    percentageAdolescent: number;
    percentageAdults: number;
    percentageOlderAdults: number;
    percentagePregnant: number;
    percentagePrep: number;
    percentagePep: number;

    minDiagnoseDurationNaive: number;
    maxDiagnoseDurationNaive: number;
    minDiagnoseDurationExperienced: number;
    maxDiagnoseDurationExperienced: number;
    minDiagnoseDurationPrevention: number;
    maxDiagnoseDurationPrevention: number;

    minCounsellingDurationNaive: number;
    maxCounsellingDurationNaive: number;
    minCounsellingDurationExperienced: number;
    maxCounsellingDurationExperienced: number;
    minCounsellingDurationPrevention: number;
    maxCounsellingDurationPrevention: number;

    minMonitoringDurationNaive: number;
    maxMonitoringDurationNaive: number;
    minMonitoringDurationExperienced: number;
    maxMonitoringDurationExperienced: number;
    minMonitoringDurationPrevention: number;
    maxMonitoringDurationPrevention: number;

    visitTimes: Array<VisitTime>;

    artId: number;
    percentageNrti2Nrti: number;
    percentagePi2Nrti: number;
    percentageIn2Nrti: number;
    percentageEi2Nrti: number;
    percentage2dr: number;

    scenarioId: number;
    scPercentageMale: number;
    scPercentageFemale: number;
    scPercentageChildren: number;
    scPercentageAdolescent: number;
    scPercentageAdults: number;
    scPercentageOlderAdults: number;
    scPercentagePregnant: number;
    scPercentagePrep: number;
    scPercentagePep: number;

    scMinDiagnoseDurationNaive: number;
    scMaxDiagnoseDurationNaive: number;
    scMinDiagnoseDurationExperienced: number;
    scMaxDiagnoseDurationExperienced: number;
    scMinDiagnoseDurationPrevention: number;
    scMaxDiagnoseDurationPrevention: number;

    scMinCounsellingDurationNaive: number;
    scMaxCounsellingDurationNaive: number;
    scMinCounsellingDurationExperienced: number;
    scMaxCounsellingDurationExperienced: number;
    scMinCounsellingDurationPrevention: number;
    scMaxCounsellingDurationPrevention: number;

    scMinMonitoringDurationNaive: number;
    scMaxMonitoringDurationNaive: number;
    scMinMonitoringDurationExperienced: number;
    scMaxMonitoringDurationExperienced: number;
    scMinMonitoringDurationPrevention: number;
    scMaxMonitoringDurationPrevention: number;

    scVisitTimes: Array<VisitTime>;

    scArtId: number;
    scPercentageNrti2Nrti: number;
    scPercentagePi2Nrti: number;
    scPercentageIn2Nrti: number;
    scPercentageEi2Nrti: number;
    scPercentage2dr: number;

}

export class VisitTime {
    id: number;
    numberOfVisit: number;
    timeType: string;
    controlVisits: Array<string>;
    waitingTime: Array<string>;
    continuous: boolean;
}

export class Art {
    id: number;
    percentageNrti2Nrti: number;
    percentagePi2Nrti: number;
    percentageIn2Nrti: number;
    percentageEi2Nrti: number;
    percentage2dr: number;
}

export class Resource {
    id: number;
    simulationId: number;
    resourceType: string;
    diagnoseNaive: number;
    monitoringNaive: number;
    monitoringExperienced: number;
    counselling: number;
    prevention: number;

    staffNumber: number;
    workingDaysWeek: number;
    daysOffPublicHolidays: number;
    daysOffAnnualLeave: number;
    daysOffSickLeave: number;
    daysOffOtherLeave: number;
    averageWorkingHoursDay: number;
    daysOtherDutiesWeek: number;
    daysLeadActivitiesMonth: number;
    allocation: number;
}


