/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrganisationMainComponent } from './pages/organisation-main/organisation-main.component';
import { OrganisationClinicianListComponent } from './pages/organisation-clinician-list/organisation-clinician-list.component';
import { OrganisationPatientListComponent } from './pages/organisation-patient-list/organisation-patient-list.component';
import { OrganisationBranchListComponent } from './pages/organisation-branch-list/organisation-branch-list.component';
import { OrganisationDetailComponent } from './pages/organisation-detail/organisation-detail.component';
import { OrganisationAuditlyticsPermissionListComponent } from './pages/organisation-auditlytics-permission-list/organisation-auditlytics-permission-list.component';

const routes: Routes = [
  {
    path: '',
    component: OrganisationMainComponent,
    children: [
      {
        path: 'detail',
        component: OrganisationDetailComponent
      },
      {
        path: 'organisation-branches',
        component: OrganisationBranchListComponent
      },
      {
        path: 'clinician-list',
        component: OrganisationClinicianListComponent
      },
      {
        path: 'patient-list',
        component: OrganisationPatientListComponent
      },
      {
        path: 'auditlytics-permissions',
        component: OrganisationAuditlyticsPermissionListComponent
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganisationRoutingModule { }
