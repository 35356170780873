    <div fxLayout="row wrap" class="oh bg-white" style="border-radius: 0 1.2rem 1.2rem 0;">
        <!-- 2 -->
        <div fxFlex.lg="40" fxFlex.gt-lg="50" fxFlex.sm="100" fxFlex="100" class="b-r">
            <div class="px-20 h-100 b-b d-flex flex-column justify-content-center">
                <h5 class="font-size-2 font-medium mb-12">{{title}}</h5>
                <small *ngIf="inboxType === InboxType.INCOMING_INVITATIONS" class="line-height-1-4">{{"USER_PROFILE.INCOMING_INVITATION.TITLE" | translate}}</small>
                <small *ngIf="inboxType === InboxType.OUTGOING_INVITATIONS" class="line-height-1-4">{{"USER_PROFILE.OUTGOING_INVITATION.TITLE" | translate}}</small>
            </div>
            
            <div class="relative" style="height: calc(100vh - 28rem);" [perfectScrollbar]="config">
                <div class="mail-list d-flex p-20 b-b" *ngFor="let row of rows | paginate : {
                    itemsPerPage: page.size,
                    currentPage: page.pageNumber+1,
                    totalItems: page.totalElements
                  }; let i = index" (click)="rowSelected(row)">
                    <!-- <div class="mail-list p-20 b-b"> -->
                        <!-- <div class="d-flex align-items-start"> -->
                            <img [src]="getRowPhotoUrl(row)" class="img-circle" alt="userimg" width="50px"
                                height="50px">
                            <div class="pl-10 flex-grow-1 d-flex flex-column justify-content-center">
                                <div class="d-flex align-items-center">
                                    <h5 class="m-0 flex-grow-1 font-medium">{{getRowUsername(row)}}</h5>
                                    <span class="text-muted font-14 text-nowrap">{{row.invitationTime | date:'dd-MMM'}}</span>
                                </div>
                                <p class="font-14 text-muted text-truncate"> {{ ('ENUM.INVITATION_TYPE.' + row.type  | translate) }}</p>
                            </div>
                        <!-- </div> -->
                        <!--p>
                            <span class="font-medium" *ngIf='!row.responseTime' [innerHtml]="row.invitorNote | slice:0:70 "> </span>
                            <span *ngIf='row.responseTime' [innerHtml]="row.invitorNote | slice:0:100 "> </span>
                        </p-->
                    <!-- </div> -->
                </div>
            </div>

            <div class="p-20 b-t d-flex align-items-center">
                <div class="m-auto">
                    <pagination-controls
                    previousLabel=""
                    nextLabel=""
                    (pageChange)="setPageNumber($event)">
                  </pagination-controls>

                </div>
            </div>

            <!-- <pagination-controls (pageChange)="p=$event"></pagination-controls> -->
        </div>

        <!-- 3 -->
        <div fxFlex.lg="60" fxFlex.gt-lg="50" fxFlex.sm="100" fxFlex="100" class="d-flex align-items-center justify-content-center">
            <div *ngIf='!selectedInvitation' class="text-center d-md-none">
                <mat-icon class="display-5 w-30 h-30">mail_outline</mat-icon>
                <h3> Select a invitation to read. </h3>
            </div>
            <div *ngIf='selectedInvitation' class="w-100p">
                <app-invitation-detail [invitation]="selectedInvitation" [inboxType]="inboxType"></app-invitation-detail>
            </div>

        </div>
    </div>