<div class="title-container">
    <span>{{'SUBSCRIPTION.PLAN.DETAIL.TITLE' | translate}}</span>
    <small>{{'ENUM.PAGE_MODE.' + PageMode[this.data.pageMode] | translate}}</small>
</div>

<mat-dialog-content>
    <form [formGroup]="formGroup" autocomplete="on" novalidate>
        <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.NAME' | translate }}</mat-label>
                <input matInput formControlName="name">
            </mat-form-field>
            <app-validation-message [control]="formGroup.get('name')"></app-validation-message>
        </div>
    
        <div class="pb-1 cust-form-field-container" fxLayout="row" fxLayoutWrap="wrap">
            <!-- column -->
            <div fxFlex.gt-sm="50" fxFlex="50">
                <mat-form-field appearance="outline" class="cust-form-field">
                    <mat-label>{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.AMOUNT' | translate }}</mat-label>
                    <input matInput formControlName="amount" type="number">
                </mat-form-field>
                <app-validation-message [control]="formGroup.get('amount')"></app-validation-message>
            </div>
            <!-- column -->
            <div fxFlex.gt-sm="50" fxFlex="50">
                <mat-form-field appearance="outline" class="cust-form-field">
                    <mat-label>{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.CURRENCY' | translate }}</mat-label>
                    <mat-select placeholder="{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.CURRENCY' | translate }}"
                        formControlName="currency">
                        <mat-option *ngFor="let cur of currencyOptions" [value]="cur">
                            {{ 'ENUM.CURRENCY.' + cur | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <app-validation-message [control]="formGroup.get('currency')"></app-validation-message>
            </div>
        </div>
    
        <div class="pb-1 cust-form-field-container" fxLayout="row" fxLayoutWrap="wrap">
            <!-- column -->
            <div fxFlex.gt-sm="50" fxFlex="50">
                <mat-form-field appearance="outline" class="cust-form-field">
                    <mat-label>{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.INTERVAL_COUNT' | translate }}</mat-label>
                    <input matInput formControlName="intervalCount" type="number">
                </mat-form-field>
                <app-validation-message [control]="formGroup.get('intervalCount')"></app-validation-message>
            </div>
            <!-- column -->
            <div fxFlex.gt-sm="50" fxFlex="50">
                <mat-form-field appearance="outline" class="cust-form-field">
                    <mat-label>{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.INTERVAL' | translate }}</mat-label>
                    <mat-select placeholder="{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.INTERVAL' | translate }}"
                        formControlName="interval">
                        <mat-option *ngFor="let interval of intervalOptions" [value]="interval">
                            {{ 'ENUM.INTERVAL.' + interval | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <app-validation-message [control]="formGroup.get('interval')"></app-validation-message>
            </div>
        </div>
    
        <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.APPLICATION_TYPE' | translate }}</mat-label>
                <mat-select placeholder="{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.APPLICATION_TYPE' | translate }}"
                    formControlName="applicationType">
                    <mat-option *ngFor="let aType of applicationTypeOptions" [value]="aType">
                        {{ 'ENUM.APPLICATION_TYPE.' + aType | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <app-validation-message [control]="formGroup.get('applicationType')"></app-validation-message>
        </div>
    
        <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.MAX_CLINICIAN_COUNT' | translate }}</mat-label>
                <input matInput formControlName="maxClinicianCount"  type="number">
            </mat-form-field>
            <app-validation-message [control]="formGroup.get('maxClinicianCount')"></app-validation-message>
        </div>
    
    
        <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.MAX_PATIENT_COUNT' | translate }}</mat-label>
                <input matInput formControlName="maxPatientCount"  type="number">
            </mat-form-field>
            <app-validation-message [control]="formGroup.get('maxPatientCount')"></app-validation-message>
        </div>
    
        <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.ADDITIONAL_USER_AMOUNT' | translate }}</mat-label>
                <input matInput formControlName="additionalUserAmount"  type="number">
            </mat-form-field>
            <app-validation-message [control]="formGroup.get('additionalUserAmount')"></app-validation-message>
        </div>
    
        <div class="pb-1 cust-form-field-container" fxLayout="row" fxLayoutWrap="wrap">
            <div fxFlex.gt-sm="50" fxFlex="50">
                <mat-checkbox color="primary" formControlName="organisationalPlan">{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.ORGANISATIONAL_PLAN' | translate }}</mat-checkbox>
                <app-validation-message [control]="formGroup.get('organisationalPlan')"></app-validation-message>
            </div>
            <div fxFlex.gt-sm="50" fxFlex="50">
                <mat-checkbox color="primary" formControlName="publish">{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.PUBLISH' | translate }}</mat-checkbox>
                <app-validation-message [control]="formGroup.get('publish')"></app-validation-message>
            </div>
        </div>
    
        <div class="pb-1 cust-form-field-container" fxLayout="row" fxLayoutWrap="wrap">
            <div fxFlex.gt-sm="50" fxFlex="50">
                <mat-checkbox color="primary" formControlName="renewable">{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.RENEWABLE' | translate }}</mat-checkbox>
                <app-validation-message [control]="formGroup.get('renewable')"></app-validation-message>
            </div>
            <div fxFlex.gt-sm="50" fxFlex="50">
                <mat-checkbox color="primary" formControlName="active">{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.ACTIVE' | translate }}</mat-checkbox>
                <app-validation-message [control]="formGroup.get('active')"></app-validation-message>
            </div>
        </div>

        <div class="pb-1 cust-form-field-container" fxLayout="row" fxLayoutWrap="wrap">
            <div fxFlex.gt-sm="50" fxFlex="50">
                <mat-checkbox color="primary" formControlName="defaultPlan">{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.DEFAULT_PLAN' | translate }}</mat-checkbox>
                <app-validation-message [control]="formGroup.get('defaultPlan')"></app-validation-message>
            </div>
            <div fxFlex.gt-sm="50" fxFlex="50">
                <mat-checkbox color="primary" formControlName="popular">{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.POPULAR' | translate }}</mat-checkbox>
                <app-validation-message [control]="formGroup.get('popular')"></app-validation-message>
            </div>
        </div>
    
        <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'SUBSCRIPTION.PLAN.DETAIL.FORM.DESCRIPTION' | translate }}</mat-label>
                <textarea matInput formControlName="description" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" dkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
            <app-validation-message [control]="formGroup.get('description')"></app-validation-message>
        </div>
    </form>
    
</mat-dialog-content>


<mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit" class="btn-lg"
        *ngIf="this.data.pageMode === PageMode.CREATE" (click)="onCreate()" [disabled]="!formGroup.valid">
        {{ 'GENERAL.CREATE' | translate }}</button>

    <button mat-raised-button color="warn" type="submit" class="btn-lg" *ngIf="this.data.pageMode === PageMode.EDIT"
        (click)="onEdit()" [disabled]="!formGroup.valid">
        {{ 'GENERAL.EDIT' | translate }}</button>
    <button mat-raised-button color="warn" type="submit" class="btn-lg" *ngIf="this.data.pageMode === PageMode.DELETE"
        (click)="onDelete()">
        {{ 'GENERAL.DELETE' | translate }}</button>
    <button mat-raised-button color="basic" class="btn-lg m-l-10" (click)="closeDialog(false)">
        {{ 'GENERAL.CANCEL' | translate }}</button>
</mat-dialog-actions>