<h1 mat-dialog-title>
    {{custDialogModel.title}}
</h1>

<div mat-dialog-content>
    <p>{{custDialogModel.message}}</p>
</div>

<div mat-dialog-actions>
    <button mat-button matDialogClose="cancel" *ngIf="custDialogModel.type === 'confirm'">Cancel</button>
    <button mat-raised-button color="primary" matDialogClose="ok">OK</button>
</div>