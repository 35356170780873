import { DatePipe, formatDate } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewChecked,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/shared/component/base.component';
import { CostDialogComponent } from 'src/app/shared/component/cost-dialog/cost-dialog.component';
import { CountryDialogComponent } from 'src/app/shared/component/country-dialog/country-dialog.component';
import { DemandDialogComponent } from 'src/app/shared/component/demand-dialog/demand-dialog.component';
import { ServiceDialogComponent } from 'src/app/shared/component/service-dialog/service-dialog.component';
import { StaffDialogComponent } from 'src/app/shared/component/staff-dialog/staff-dialog.component';
import {
  CountryCost,
  Simulation,
  SimulationCostUpdate,
  SimulationCountrySelection,
  SimulationCountryUpdate,
  SimulationDemandUpdate,
  SimulationServiceUpdate,
  SimulationStaffUpdate,
  SimulationResourceUpdate,
  UserResponse,
  SimulationUnaidsUpdate,
  VisitTime,
} from 'src/app/shared/model/simulation';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { SimulationService } from 'src/app/shared/services/simulation.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResourceDialogComponent } from 'src/app/shared/component/resource-dialog/resource-dialog.component';
import { UnaidsDialogComponent } from 'src/app/shared/component/unaids-dialog/unaids-dialog.component';
import { OrganisationService } from 'src/app/shared/services/organisation.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';
import {
  Pair,
  SimulationResultSummaryResponse,
} from 'src/app/shared/model/simulation-result';
import { NgxSpinnerService } from 'ngx-spinner';
import { downloadFile } from 'src/app/shared/util';

@Component({
  selector: 'app-manager-form',
  templateUrl: './manager-form.component.html',
  styleUrls: ['./manager-form.component.scss'],
})
export class ManagerFormComponent
  extends BaseComponent
  implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('blockImg') blockImg: ElementRef;
  timer: number;
  rxjsTimer = timer(1000, 1000);
  pathTimeExt = [0, 0, 0, 0, 0];
  demandData: SimulationDemandUpdate;
  costData: SimulationCostUpdate;
  countryData: SimulationCountrySelection;
  countryUpdateData: SimulationCountryUpdate;
  serviceData: SimulationServiceUpdate;
  staffData: SimulationStaffUpdate;
  resourceData: SimulationResourceUpdate;
  unaidsData: SimulationUnaidsUpdate;

  costs: Array<CountryCost>;
  defaultCost: CountryCost;

  name: string;
  simulation: Simulation;
  scenario: Simulation;
  userResponse: UserResponse;
  flag: string;
  country: string;
  code: string;
  result: string;
  bntStyle: string;
  bntStyle2: string;
  bntStyle3: string;
  bntStyle4: string;
  bntStyle5: string;
  bntMiddleStyle: string;
  bntMiddleStyle2: string;
  bntMiddleStyle3: string;
  bntMiddleStyle4: string;
  bntMiddleStyle5: string;
  animationStarted: boolean;
  loadAnimation: boolean;
  timerStarted: boolean;
  errorOccured: boolean;
  lowSpeedpaths = ['path1', 'path2', 'path3', 'path1-w', 'path2-w', 'path3-w'];
  lowSpeedPathTimes = [48, 37, 39, 48, 37, 39];
  middleSpeedPaths = [
    'path-reception',
    'path-diagnostic',
    'path-counselling',
    'path-monitoring',
    'path-treatment',
    'path-reception-w',
    'path-diagnostic-w',
    'path-counselling-w',
    'path-monitoring-w',
    'path-treatment-w',
  ];
  middleSpeedPathTimes = [34, 26, 19, 11, 12, 34, 26, 19, 11, 12];
  fastSpeedpaths = ['path1-fast', 'path2-fast', 'path3-fast'];
  fastSpeedpathTimes = [33, 39, 33];
  random: number;
  randomPath: string;
  randomM: number;
  randomPathM: string;
  pathTime = [0, 0, 0, 0, 0];
  pathMiddleTime = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  timeOut: any;
  showWalkingMan: boolean;
  showWalkingMan2: boolean;
  showWalkingMan3: boolean;
  showWalkingMan4: boolean;
  showWalkingMan5: boolean;

  showMiddleWalkingMan: boolean;
  showMiddleWalkingMan2: boolean;
  showMiddleWalkingMan3: boolean;
  showMiddleWalkingMan4: boolean;
  showMiddleWalkingMan5: boolean;
  test: string;
  organizationName = '---';

  patients = '0';
  visits = '0';
  hours = '0';

  patientsSc = '0';
  visitsSc = '0';
  hoursSc = '0';

  simulationDate;
  visitTimes: Array<VisitTime>;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private storageService: StorageService,
    private notificationService: NotificationService,
    private simulationService: SimulationService,
    private organisationService: OrganisationService,
    private spinner: NgxSpinnerService,
    private authenticationStateService: AuthenticationStateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.animationStarted = false;
    this.loadAnimation = true;
    this.timerStarted = false;
    this.errorOccured = false;
    this.bntStyle = 'none-style';
    this.bntStyle2 = 'none-style';
    this.bntStyle3 = 'none-style';
    this.bntStyle4 = 'none-style';
    this.bntStyle5 = 'none-style';
    this.bntMiddleStyle = 'none-style';
    this.bntMiddleStyle2 = 'none-style';
    this.bntMiddleStyle3 = 'none-style';
    this.bntMiddleStyle4 = 'none-style';
    this.bntMiddleStyle5 = 'none-style';
    this.showWalkingMan = false;
    this.showWalkingMan2 = false;
    this.showWalkingMan3 = false;
    this.showWalkingMan4 = false;
    this.showWalkingMan5 = false;
    this.showMiddleWalkingMan = false;
    this.showMiddleWalkingMan2 = false;
    this.showMiddleWalkingMan3 = false;
    this.showMiddleWalkingMan4 = false;
    this.showMiddleWalkingMan5 = false;

    this.patients = this.storageService.getPatients() ? this.storageService.getPatients() : '0';
    this.patientsSc = this.storageService.getPatientsSc() ? this.storageService.getPatientsSc() : '0';
    this.visits = this.storageService.getVisits() ? this.storageService.getVisits() : '0';
    this.visitsSc = this.storageService.getVisitsSc() ? this.storageService.getVisitsSc() : '0';
    this.hours = this.storageService.getHours() ? this.storageService.getHours() : '0';
    this.hoursSc = this.storageService.getHoursSc() ? this.storageService.getHoursSc() : '0';

    this.startAnimation();

    this.simulationService.getSimulations().subscribe(
      (data) => {
        this.userResponse = data.userResponse;
        this.country = data.userResponse.country;
        this.code = data.userResponse.countryCode.toUpperCase();
        this.flag = data.userResponse.countryFlag;
        this.simulation = data.simulations[0];
        this.scenario = data.simulations[1];
        this.storageService.setSimulation(JSON.stringify(this.simulation));
        this.storageService.setScenario(JSON.stringify(this.scenario));
        this.storageService.setCountry(this.country);

        this.simulationDate = formatDate(
          new Date(new Date().getFullYear(), 0, 1),
          'EEEE d MMMM yyyy',
          'en'
        );

        this.simulationService
          .getSimulationCost(this.country)
          .subscribe((result) => {
            this.defaultCost = result;
          });

        this.simulationService.getSimulationCosts().subscribe((result) => {
          this.costs = result.costs;
        });

        const orgId =
          this.authenticationStateService.getLoginInfo().clinicianOrganisationId;
        if (orgId) {
          this.organisationService
            .getOrganisation(orgId)
            .subscribe((result) => {
              this.organizationName = result.name;
            });
        }

        this.simulationService.getVisitTimes().subscribe((result) => {
          this.visitTimes = result.visits;
        });
      },
      (err) => {
        console.log('Error on get simulation. err : ' + err);
        this.notificationService.error(
          this.translate.instant(
            'Simulation info could not retrieved from server.'
          )
        );
      }
    );
  }

  ngAfterViewChecked() {
    const datas = this.blockImg.nativeElement.getBoundingClientRect();
    /*console.log(
      'AAA' + this.blockImg.nativeElement.getBoundingClientRect().left
    );
    console.log('datas = ', datas);*/
    const sideSize =
      (this.blockImg.nativeElement.getBoundingClientRect().width - 823) / 2;
    //if(sideSize < 240) {
    document.documentElement.style.setProperty('--side-size', sideSize + 'px');
    //}else {
    //  document.documentElement.style.setProperty('--side-size', '0px');
    //}
  }

  openUnaidsDialog(): void {
    const dialogRef = this.dialog.open(UnaidsDialogComponent, {
      width: '700px',

      data: {
        percentageKnowStatusDefault: this.defaultCost.percentageKnowStatus,
        percentageArtDefault: this.defaultCost.percentageArt,
        percentageViralSuppressionDefault:
          this.defaultCost.percentageViralSuppression,
        mortalityRatioDefault: this.defaultCost.mortalityRatio,
        prevalenceRatioDefault: this.defaultCost.prevalenceRatio,

        years: this.simulation.years,
        simulationId: this.simulation.id,
        percentageKnowStatus: this.simulation.percentageKnowStatus,
        percentageArt: this.simulation.percentageArt,
        percentageViralSuppression: this.simulation.percentageViralSuppression,
        mortalityRatio: this.simulation.mortalityRatio,
        prevalenceRatio: this.simulation.prevalenceRatio,
        demandNaive: this.simulation.demandNaive,
        demandExperienced: this.simulation.demandExperienced,

        scenarioId: this.scenario.id,
        scPercentageKnowStatus: this.scenario.percentageKnowStatus,
        scPercentageArt: this.scenario.percentageArt,
        scPercentageViralSuppression: this.scenario.percentageViralSuppression,
        scDemandNaiveCalculated: 0,
        scDemandExperiencedCalculated: 0,
        demandNaiveSc: this.scenario.demandNaive,
        scDemandNaive: this.scenario.demandNaive,
        scDemandExperienced: this.scenario.demandExperienced,
        scNewDiagnosis: 0,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.unaidsData = result;
        this.simulationService
          .updateSimulationUnaids(this.unaidsData)
          .subscribe(
            (data) => {
              this.simulation.percentageKnowStatus = this.unaidsData.percentageKnowStatus;
              this.simulation.percentageArt = this.unaidsData.percentageArt;
              this.simulation.percentageViralSuppression = this.unaidsData.percentageViralSuppression;

              this.simulation.mortalityRatio = this.unaidsData.mortalityRatio;
              this.simulation.prevalenceRatio = this.unaidsData.prevalenceRatio;

              this.scenario.percentageKnowStatus = this.unaidsData.scPercentageKnowStatus;
              this.scenario.percentageArt = this.unaidsData.scPercentageArt;
              this.scenario.percentageViralSuppression = this.unaidsData.scPercentageViralSuppression;

              this.storageService.setSimulation(
                JSON.stringify(this.simulation)
              );
              this.storageService.setScenario(JSON.stringify(this.scenario));

              this.notificationService.info(
                this.translate.instant('UNAIDS updated')
              );
            },
            (err) => {
              this.notificationService.error(
                this.translate.instant('Error on update UNAIDS')
              );
            }
          );
      }
    });
  }

  openResourceDialog(): void {
    const dialogRef = this.dialog.open(ResourceDialogComponent, {
      width: '700px',

      data: {
        doctor: this.simulation.resources[0],
        pharmacist: this.simulation.resources[1],
        medicalLabScientist: this.simulation.resources[2],
        hivSpecialistNurse: this.simulation.resources[3],
        dietician: this.simulation.resources[4],
        generalNurse: this.simulation.resources[5],
        counselor: this.simulation.resources[6],

        doctorSc: this.scenario.resources[0],
        pharmacistSc: this.scenario.resources[1],
        medicalLabScientistSc: this.scenario.resources[2],
        hivSpecialistNurseSc: this.scenario.resources[3],
        dieticianSc: this.scenario.resources[4],
        generalNurseSc: this.scenario.resources[5],
        counselorSc: this.scenario.resources[6],
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.resourceData = result;
        this.simulationService
          .updateSimulationResource(this.resourceData)
          .subscribe(
            (data) => {
              this.simulation.resources = [];
              this.simulation.resources.push(this.resourceData.doctor);
              this.simulation.resources.push(this.resourceData.pharmacist);
              this.simulation.resources.push(
                this.resourceData.medicalLabScientist
              );
              this.simulation.resources.push(
                this.resourceData.hivSpecialistNurse
              );
              this.simulation.resources.push(this.resourceData.dietician);
              this.simulation.resources.push(this.resourceData.generalNurse);
              this.simulation.resources.push(this.resourceData.counselor);

              this.scenario.resources = [];
              this.scenario.resources.push(this.resourceData.doctorSc);
              this.scenario.resources.push(this.resourceData.pharmacistSc);
              this.scenario.resources.push(
                this.resourceData.medicalLabScientistSc
              );
              this.scenario.resources.push(
                this.resourceData.hivSpecialistNurseSc
              );
              this.scenario.resources.push(this.resourceData.dieticianSc);
              this.scenario.resources.push(this.resourceData.generalNurseSc);
              this.scenario.resources.push(this.resourceData.counselorSc);

              this.storageService.setSimulation(
                JSON.stringify(this.simulation)
              );
              this.storageService.setScenario(JSON.stringify(this.scenario));
              this.notificationService.info(
                this.translate.instant('Resource updated')
              );
            },
            (err) => {
              this.notificationService.error(
                this.translate.instant('Error on update Resource')
              );
            }
          );
      }
    });
  }

  openStaffDialog(): void {
    const dialogRef = this.dialog.open(StaffDialogComponent, {
      width: '900px',
      data: {
        simulationId: this.simulation.id,
        doctorNumber: this.simulation.resources[0].staffNumber,
        pharmacistNumber: this.simulation.resources[1].staffNumber,
        medicalLabScientistNumber: this.simulation.resources[2].staffNumber,
        hivSpecialistNurseNumber: this.simulation.resources[3].staffNumber,
        dieticianNumber: this.simulation.resources[4].staffNumber,
        generalNurseNumber: this.simulation.resources[5].staffNumber,
        counselorNumber: this.simulation.resources[6].staffNumber,
        allocation: this.simulation.resources[0].allocation,

        scenarioId: this.scenario.id,
        scDoctorNumber: this.scenario.resources[0].staffNumber,
        scPharmacistNumber: this.scenario.resources[1].staffNumber,
        scMedicalLabScientistNumber: this.scenario.resources[2].staffNumber,
        scHivSpecialistNurseNumber: this.scenario.resources[3].staffNumber,
        scDieticianNumber: this.scenario.resources[4].staffNumber,
        scGeneralNurseNumber: this.scenario.resources[5].staffNumber,
        scCounselorNumber: this.scenario.resources[6].staffNumber,
        scAllocation: this.scenario.resources[0].allocation,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.staffData = result;
        this.simulationService.updateSimulationStaff(this.staffData).subscribe(
          (data) => {
            this.simulation.resources[0].staffNumber =
              this.staffData.doctorNumber;
            this.simulation.resources[0].allocation = this.staffData.allocation;
            this.simulation.resources[1].staffNumber =
              this.staffData.pharmacistNumber;
            this.simulation.resources[1].allocation = this.staffData.allocation;
            this.simulation.resources[2].staffNumber =
              this.staffData.medicalLabScientistNumber;
            this.simulation.resources[2].allocation = this.staffData.allocation;
            this.simulation.resources[3].staffNumber =
              this.staffData.hivSpecialistNurseNumber;
            this.simulation.resources[3].allocation = this.staffData.allocation;
            this.simulation.resources[4].staffNumber =
              this.staffData.dieticianNumber;
            this.simulation.resources[4].allocation = this.staffData.allocation;
            this.simulation.resources[5].staffNumber =
              this.staffData.generalNurseNumber;
            this.simulation.resources[5].allocation = this.staffData.allocation;
            this.simulation.resources[6].staffNumber =
              this.staffData.counselorNumber;
            this.simulation.resources[6].allocation = this.staffData.allocation;

            this.scenario.resources[0].staffNumber =
              this.staffData.scDoctorNumber;
            this.scenario.resources[0].allocation = this.staffData.scAllocation;
            this.scenario.resources[1].staffNumber =
              this.staffData.scPharmacistNumber;
            this.scenario.resources[1].allocation = this.staffData.scAllocation;
            this.scenario.resources[2].staffNumber =
              this.staffData.scMedicalLabScientistNumber;
            this.scenario.resources[2].allocation = this.staffData.scAllocation;
            this.scenario.resources[3].staffNumber =
              this.staffData.scHivSpecialistNurseNumber;
            this.scenario.resources[3].allocation = this.staffData.scAllocation;
            this.scenario.resources[4].staffNumber =
              this.staffData.scDieticianNumber;
            this.scenario.resources[4].allocation = this.staffData.scAllocation;
            this.scenario.resources[5].staffNumber =
              this.staffData.scGeneralNurseNumber;
            this.scenario.resources[5].allocation = this.staffData.scAllocation;
            this.scenario.resources[6].staffNumber =
              this.staffData.scCounselorNumber;
            this.scenario.resources[6].allocation = this.staffData.scAllocation;

            this.storageService.setSimulation(JSON.stringify(this.simulation));
            this.storageService.setScenario(JSON.stringify(this.scenario));
            this.notificationService.info(
              this.translate.instant('Staff updated')
            );
          },
          (err) => {
            this.notificationService.error(
              this.translate.instant('Error on update staff')
            );
          }
        );
      }
    });
  }

  openCostDialog(): void {
    const dialogRef = this.dialog.open(CostDialogComponent, {
      width: '900px',
      data: {
        simulationId: this.simulation.id,
        artFirstCost: this.simulation.artFirstCost,
        artSecondCost: this.simulation.artSecondCost,
        laboratoryCost: this.simulation.laboratoryCost,
        overheadCost: this.simulation.overheadCost,
        preventionCost: this.simulation.preventionCost,

        scenarioId: this.scenario.id,
        scArtFirstCost: this.scenario.artFirstCost,
        scArtSecondCost: this.scenario.artSecondCost,
        scLaboratoryCost: this.scenario.laboratoryCost,
        scOverheadCost: this.scenario.overheadCost,
        scPreventionCost: this.scenario.preventionCost,

        artFirstCostDefault: this.defaultCost.artFirstCost,
        artSecondCostDefault: this.defaultCost.artSecondCost,
        laboratoryCostDefault: this.defaultCost.laboratoryCost,
        overheadCostDefault: this.defaultCost.overheadCost,
        preventionCostDefault: this.defaultCost.preventionCost,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.costData = result;
        this.simulationService.updateSimulationCosts(this.costData).subscribe(
          (data) => {
            this.simulation.artFirstCost = this.costData.artFirstCost;
            this.simulation.artSecondCost = this.costData.artSecondCost;
            this.simulation.laboratoryCost = this.costData.laboratoryCost;
            this.simulation.overheadCost = this.costData.overheadCost;
            this.simulation.preventionCost = this.costData.preventionCost;

            this.scenario.artFirstCost = this.costData.scArtFirstCost;
            this.scenario.artSecondCost = this.costData.scArtSecondCost;
            this.scenario.laboratoryCost = this.costData.scLaboratoryCost;
            this.scenario.overheadCost = this.costData.scOverheadCost;
            this.scenario.preventionCost = this.costData.scPreventionCost;

            this.storageService.setSimulation(JSON.stringify(this.simulation));
            this.storageService.setScenario(JSON.stringify(this.scenario));
            this.notificationService.info(
              this.translate.instant('Cost updated')
            );
          },
          (err) => {
            this.notificationService.error(
              this.translate.instant('Error on cost update')
            );
          }
        );
      }
    });
  }

  openCountryDialog(): void {
    const dialogRef = this.dialog.open(CountryDialogComponent, {
      width: '700px',
      data: {
        costs: this.costs,
        selectedCost: this.defaultCost,
        updateDefault: true,
        userId: this.userResponse.id,
        country: this.userResponse.country,
        code: this.userResponse.countryCode,
        flag: this.userResponse.countryFlag,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.countryData = result;
        this.countryUpdateData = {
          code: result.code,
          country: result.country,
          flag: result.flag,
          userId: result.userId,
          artFirstCost: result.selectedCost.artFirstCost,
          artSecondCost: result.selectedCost.artSecondCost,
          laboratoryCost: result.selectedCost.laboratoryCost,
          overheadCost: result.selectedCost.overheadCost,
          preventionCost: result.selectedCost.preventionCost,
          updateDefault: result.updateDefault,
          mortalityRatio: result.selectedCost.mortalityRatio,
          prevalenceRatio: result.selectedCost.prevalenceRatio,
          percentageArt: result.selectedCost.percentageArt,
          percentageViralSuppression:
            result.selectedCost.percentageViralSuppression,
          percentageKnowStatus: result.selectedCost.percentageKnowStatus,
        };

        this.simulationService
          .updateSimulationCuntry(this.countryUpdateData)
          .subscribe(
            (data) => {
              this.userResponse.country = this.countryData.country;
              this.userResponse.countryCode = this.countryData.code;
              this.userResponse.countryFlag = this.countryData.flag;
              this.country = this.countryData.country;
              this.code = this.countryData.code.toUpperCase();
              this.flag = this.countryData.flag;
              this.defaultCost = result.selectedCost;

              this.defaultCost.artFirstCost =
                this.countryUpdateData.artFirstCost;
              this.defaultCost.artSecondCost =
                this.countryUpdateData.artSecondCost;
              this.defaultCost.laboratoryCost =
                this.countryUpdateData.laboratoryCost;
              this.defaultCost.overheadCost =
                this.countryUpdateData.overheadCost;
              this.defaultCost.country = this.countryUpdateData.country;
              this.defaultCost.code = this.countryUpdateData.code;
              this.defaultCost.flag = this.countryUpdateData.flag;

              if (this.countryUpdateData.updateDefault) {
                this.simulation.artFirstCost = this.defaultCost.artFirstCost;
                this.simulation.artSecondCost = this.defaultCost.artSecondCost;
                this.simulation.laboratoryCost =
                  this.defaultCost.laboratoryCost;
                this.simulation.overheadCost = this.defaultCost.overheadCost;

                this.simulation.percentageKnowStatus =
                  this.countryUpdateData.percentageKnowStatus;
                this.simulation.percentageArt =
                  this.countryUpdateData.percentageArt;
                this.simulation.percentageViralSuppression =
                  this.countryUpdateData.percentageViralSuppression;
                this.simulation.mortalityRatio =
                  this.countryUpdateData.mortalityRatio;
                this.simulation.prevalenceRatio =
                  this.countryUpdateData.prevalenceRatio;
                this.simulation.demandIncrease =
                  this.countryUpdateData.prevalenceRatio;

                this.scenario.artFirstCost = this.defaultCost.artFirstCost;
                this.scenario.artSecondCost = this.defaultCost.artSecondCost;
                this.scenario.laboratoryCost = this.defaultCost.laboratoryCost;
                this.scenario.overheadCost = this.defaultCost.overheadCost;

                this.scenario.percentageKnowStatus = 0;
                this.scenario.percentageArt = 0;
                this.scenario.percentageViralSuppression = 0;
                this.scenario.mortalityRatio =
                  this.countryUpdateData.mortalityRatio;
                this.scenario.prevalenceRatio =
                  this.countryUpdateData.prevalenceRatio;
                this.scenario.demandIncrease =
                  this.countryUpdateData.prevalenceRatio;
              }

              this.storageService.setSimulation(
                JSON.stringify(this.simulation)
              );
              this.storageService.setScenario(JSON.stringify(this.scenario));
              this.storageService.setCountry(this.country);
              this.notificationService.info(
                this.translate.instant('Country updated')
              );
            },
            (err) => {
              this.notificationService.error(
                this.translate.instant('Error on country update')
              );
            }
          );
      }
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DemandDialogComponent, {
      width: '920px',
      data: {
        demandIncreaseDefault: this.defaultCost.prevalenceRatio,
        simulationId: this.simulation.id,
        demandExperienced: this.simulation.demandExperienced,
        demandNaive: this.simulation.demandNaive,
        demandPrevention: this.simulation.demandPrevention,
        demandIncrease: this.simulation.demandIncrease,
        demandIncreasePrevention: this.simulation.demandIncreasePrevention,
        years: this.simulation.years,
        simCount: this.simulation.simCount,

        scenarioId: this.scenario.id,
        scDemandExperienced: this.scenario.demandExperienced,
        scDemandNaive: this.scenario.demandNaive,
        scDemandPrevention: this.scenario.demandPrevention,
        scDemandIncrease: this.scenario.demandIncrease,
        scDemandIncreasePrevention: this.scenario.demandIncreasePrevention,
        scSimCount: this.scenario.simCount,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.demandData = result;
        this.simulationService
          .updateSimulationDemands(this.demandData)
          .subscribe(
            (data) => {
              this.simulation.demandExperienced =
                this.demandData.demandExperienced;
              this.simulation.demandNaive = this.demandData.demandNaive;
              this.simulation.demandPrevention =
                this.demandData.demandPrevention;
              this.simulation.demandIncrease = this.demandData.demandIncrease;
              this.simulation.demandIncreasePrevention =
                this.demandData.demandIncreasePrevention;
              this.simulation.years = this.demandData.years;

              this.scenario.demandExperienced =
                this.demandData.scDemandExperienced;
              this.scenario.demandNaive = this.demandData.scDemandNaive;
              this.scenario.demandPrevention =
                this.demandData.scDemandPrevention;
              this.scenario.demandIncrease = this.demandData.scDemandIncrease;
              this.scenario.demandIncreasePrevention =
                this.demandData.scDemandIncreasePrevention;
              this.scenario.years = this.demandData.years;

              this.storageService.setSimulation(
                JSON.stringify(this.simulation)
              );
              this.storageService.setScenario(JSON.stringify(this.scenario));

              this.notificationService.info(
                this.translate.instant('Demand updated')
              );
            },
            (err) => {
              this.notificationService.error(
                this.translate.instant('Error on get simulation')
              );
            }
          );
      }
    });
  }

  openServiceDialog(): void {
    const dialogServiceRef = this.dialog.open(ServiceDialogComponent, {
      width: '1000px',
      data: {
        allVisitTimes: this.visitTimes,

        simulationId: this.simulation.id,
        percentageMale: this.simulation.percentageMale,
        percentageFemale: this.simulation.percentageFemale,
        percentageChildren: this.simulation.percentageChildren,
        percentageAdolescent: this.simulation.percentageAdolescent,
        percentageAdults: this.simulation.percentageAdults,
        percentageOlderAdults: this.simulation.percentageOlderAdults,
        percentagePregnant: this.simulation.percentagePregnant,
        percentagePrep: this.simulation.percentagePrep,
        percentagePep: this.simulation.percentagePep,

        minDiagnoseDurationNaive: this.simulation.minDiagnoseDurationNaive,
        maxDiagnoseDurationNaive: this.simulation.maxDiagnoseDurationNaive,
        minDiagnoseDurationExperienced:
          this.simulation.minDiagnoseDurationExperienced,
        maxDiagnoseDurationExperienced:
          this.simulation.maxDiagnoseDurationExperienced,
        minDiagnoseDurationPrevention:
          this.simulation.minDiagnoseDurationPrevention,
        maxDiagnoseDurationPrevention:
          this.simulation.maxDiagnoseDurationPrevention,

        minCounsellingDurationNaive:
          this.simulation.minCounsellingDurationNaive,
        maxCounsellingDurationNaive:
          this.simulation.maxCounsellingDurationNaive,
        minCounsellingDurationExperienced:
          this.simulation.minCounsellingDurationExperienced,
        maxCounsellingDurationExperienced:
          this.simulation.maxCounsellingDurationExperienced,
        minCounsellingDurationPrevention:
          this.simulation.minCounsellingDurationPrevention,
        maxCounsellingDurationPrevention:
          this.simulation.maxCounsellingDurationPrevention,

        minMonitoringDurationNaive: this.simulation.minMonitoringDurationNaive,
        maxMonitoringDurationNaive: this.simulation.maxMonitoringDurationNaive,
        minMonitoringDurationExperienced:
          this.simulation.minMonitoringDurationExperienced,
        maxMonitoringDurationExperienced:
          this.simulation.maxMonitoringDurationExperienced,
        minMonitoringDurationPrevention:
          this.simulation.minMonitoringDurationPrevention,
        maxMonitoringDurationPrevention:
          this.simulation.maxMonitoringDurationPrevention,

        visitTimes: this.simulation.visitTimes,

        artId: this.simulation.arts[0].id,
        percentageNrti2Nrti: this.simulation.arts[0].percentageNrti2Nrti,
        percentagePi2Nrti: this.simulation.arts[0].percentagePi2Nrti,
        percentageIn2Nrti: this.simulation.arts[0].percentageIn2Nrti,
        percentageEi2Nrti: this.simulation.arts[0].percentageEi2Nrti,
        percentage2dr: this.simulation.arts[0].percentage2dr,

        scenarioId: this.scenario.id,
        scPercentageMale: this.scenario.percentageMale,
        scPercentageFemale: this.scenario.percentageFemale,
        scPercentageChildren: this.scenario.percentageChildren,
        scPercentageAdolescent: this.scenario.percentageAdolescent,
        scPercentageAdults: this.scenario.percentageAdults,
        scPercentageOlderAdults: this.scenario.percentageOlderAdults,
        scPercentagePregnant: this.scenario.percentagePregnant,
        scPercentagePrep: this.scenario.percentagePrep,
        scPercentagePep: this.scenario.percentagePep,

        scMinDiagnoseDurationNaive: this.scenario.minDiagnoseDurationNaive,
        scMaxDiagnoseDurationNaive: this.scenario.maxDiagnoseDurationNaive,
        scMinDiagnoseDurationExperienced:
          this.scenario.minDiagnoseDurationExperienced,
        scMaxDiagnoseDurationExperienced:
          this.scenario.maxDiagnoseDurationExperienced,
        scMinDiagnoseDurationPrevention:
          this.scenario.minDiagnoseDurationPrevention,
        scMaxDiagnoseDurationPrevention:
          this.scenario.maxDiagnoseDurationPrevention,

        scMinCounsellingDurationNaive:
          this.scenario.minCounsellingDurationNaive,
        scMaxCounsellingDurationNaive:
          this.scenario.maxCounsellingDurationNaive,
        scMinCounsellingDurationExperienced:
          this.scenario.minCounsellingDurationExperienced,
        scMaxCounsellingDurationExperienced:
          this.scenario.maxCounsellingDurationExperienced,
        scMinCounsellingDurationPrevention:
          this.scenario.minCounsellingDurationPrevention,
        scMaxCounsellingDurationPrevention:
          this.scenario.maxCounsellingDurationPrevention,

        scMinMonitoringDurationNaive: this.scenario.minMonitoringDurationNaive,
        scMaxMonitoringDurationNaive: this.scenario.maxMonitoringDurationNaive,
        scMinMonitoringDurationExperienced:
          this.scenario.minMonitoringDurationExperienced,
        scMaxMonitoringDurationExperienced:
          this.scenario.maxMonitoringDurationExperienced,
        scMinMonitoringDurationPrevention:
          this.scenario.minMonitoringDurationPrevention,
        scMaxMonitoringDurationPrevention:
          this.scenario.maxMonitoringDurationPrevention,

        scVisitTimes: this.scenario.visitTimes,

        scArtId: this.scenario.arts[0].id,
        scPercentageNrti2Nrti: this.scenario.arts[0].percentageNrti2Nrti,
        scPercentagePi2Nrti: this.scenario.arts[0].percentagePi2Nrti,
        scPercentageIn2Nrti: this.scenario.arts[0].percentageIn2Nrti,
        scPercentageEi2Nrti: this.scenario.arts[0].percentageEi2Nrti,
        scPercentage2dr: this.scenario.arts[0].percentage2dr,
      },
    });

    dialogServiceRef.afterClosed().subscribe((result) => {
      if (result) {
        this.serviceData = result;
        this.simulationService
          .updateSimulationService(this.serviceData)
          .subscribe(
            (data) => {
              this.simulation.percentageMale = this.serviceData.percentageMale;
              this.simulation.percentageFemale =
                this.serviceData.percentageFemale;
              this.simulation.percentageChildren =
                this.serviceData.percentageChildren;
              this.simulation.percentageAdolescent =
                this.serviceData.percentageAdolescent;
              this.simulation.percentageAdults =
                this.serviceData.percentageAdults;
              this.simulation.percentageOlderAdults =
                this.serviceData.percentageOlderAdults;
              this.simulation.percentagePregnant =
                this.serviceData.percentagePregnant;

              this.simulation.percentagePrep = this.serviceData.percentagePrep;
              this.simulation.percentagePep = this.serviceData.percentagePep;

              this.simulation.minDiagnoseDurationNaive =
                this.serviceData.minDiagnoseDurationNaive;
              this.simulation.maxDiagnoseDurationNaive =
                this.serviceData.maxDiagnoseDurationNaive;
              this.simulation.minDiagnoseDurationExperienced =
                this.serviceData.minDiagnoseDurationExperienced;
              this.simulation.maxDiagnoseDurationExperienced =
                this.serviceData.maxDiagnoseDurationExperienced;
              this.simulation.minDiagnoseDurationPrevention =
                this.serviceData.minDiagnoseDurationPrevention;
              this.simulation.maxDiagnoseDurationPrevention =
                this.serviceData.maxDiagnoseDurationPrevention;
              this.simulation.minCounsellingDurationNaive =
                this.serviceData.minCounsellingDurationNaive;
              this.simulation.maxCounsellingDurationNaive =
                this.serviceData.maxCounsellingDurationNaive;
              this.simulation.minCounsellingDurationExperienced =
                this.serviceData.minCounsellingDurationExperienced;
              this.simulation.maxCounsellingDurationExperienced =
                this.serviceData.maxCounsellingDurationExperienced;
              this.simulation.minCounsellingDurationPrevention =
                this.serviceData.minCounsellingDurationPrevention;
              this.simulation.maxCounsellingDurationPrevention =
                this.serviceData.maxCounsellingDurationPrevention;
              this.simulation.minMonitoringDurationNaive =
                this.serviceData.minMonitoringDurationNaive;
              this.simulation.maxMonitoringDurationNaive =
                this.serviceData.maxMonitoringDurationNaive;
              this.simulation.minMonitoringDurationExperienced =
                this.serviceData.minMonitoringDurationExperienced;
              this.simulation.maxMonitoringDurationExperienced =
                this.serviceData.maxMonitoringDurationExperienced;
              this.simulation.minMonitoringDurationPrevention =
                this.serviceData.minMonitoringDurationPrevention;
              this.simulation.maxMonitoringDurationPrevention =
                this.serviceData.maxMonitoringDurationPrevention;

              this.simulation.arts[0].percentageNrti2Nrti =
                this.serviceData.percentageNrti2Nrti;
              this.simulation.arts[0].percentagePi2Nrti =
                this.serviceData.percentagePi2Nrti;
              this.simulation.arts[0].percentageIn2Nrti =
                this.serviceData.percentageIn2Nrti;
              this.simulation.arts[0].percentageEi2Nrti =
                this.serviceData.percentageEi2Nrti;
              this.simulation.arts[0].percentage2dr =
                this.serviceData.percentage2dr;

              this.scenario.percentageMale = this.serviceData.scPercentageMale;
              this.scenario.percentageFemale =
                this.serviceData.scPercentageFemale;
              this.scenario.percentageChildren =
                this.serviceData.scPercentageChildren;
              this.scenario.percentageAdolescent =
                this.serviceData.scPercentageAdolescent;
              this.scenario.percentageAdults =
                this.serviceData.scPercentageAdults;
              this.scenario.percentageOlderAdults =
                this.serviceData.scPercentageOlderAdults;
              this.scenario.percentagePregnant =
                this.serviceData.scPercentagePregnant;

              this.scenario.percentagePrep = this.serviceData.scPercentagePrep;
              this.scenario.percentagePep = this.serviceData.scPercentagePep;

              this.scenario.minDiagnoseDurationNaive =
                this.serviceData.scMinDiagnoseDurationNaive;
              this.scenario.maxDiagnoseDurationNaive =
                this.serviceData.scMaxDiagnoseDurationNaive;
              this.scenario.minDiagnoseDurationExperienced =
                this.serviceData.scMinDiagnoseDurationExperienced;
              this.scenario.maxDiagnoseDurationExperienced =
                this.serviceData.scMaxDiagnoseDurationExperienced;
              this.scenario.minDiagnoseDurationPrevention =
                this.serviceData.scMinDiagnoseDurationPrevention;
              this.scenario.maxDiagnoseDurationPrevention =
                this.serviceData.scMaxDiagnoseDurationPrevention;
              this.scenario.minCounsellingDurationNaive =
                this.serviceData.scMinCounsellingDurationNaive;
              this.scenario.maxCounsellingDurationNaive =
                this.serviceData.scMaxCounsellingDurationNaive;
              this.scenario.minCounsellingDurationExperienced =
                this.serviceData.scMinCounsellingDurationExperienced;
              this.scenario.maxCounsellingDurationExperienced =
                this.serviceData.scMaxCounsellingDurationExperienced;
              this.scenario.minCounsellingDurationPrevention =
                this.serviceData.scMinCounsellingDurationPrevention;
              this.scenario.maxCounsellingDurationPrevention =
                this.serviceData.scMaxCounsellingDurationPrevention;
              this.scenario.minMonitoringDurationNaive =
                this.serviceData.scMinMonitoringDurationNaive;
              this.scenario.maxMonitoringDurationNaive =
                this.serviceData.scMaxMonitoringDurationNaive;
              this.scenario.minMonitoringDurationExperienced =
                this.serviceData.scMinMonitoringDurationExperienced;
              this.scenario.maxMonitoringDurationExperienced =
                this.serviceData.scMaxMonitoringDurationExperienced;
              this.scenario.minMonitoringDurationPrevention =
                this.serviceData.scMinMonitoringDurationPrevention;
              this.scenario.maxMonitoringDurationPrevention =
                this.serviceData.scMaxMonitoringDurationPrevention;

              this.scenario.arts[0].percentageNrti2Nrti =
                this.serviceData.scPercentageNrti2Nrti;
              this.scenario.arts[0].percentagePi2Nrti =
                this.serviceData.scPercentagePi2Nrti;
              this.scenario.arts[0].percentageIn2Nrti =
                this.serviceData.scPercentageIn2Nrti;
              this.scenario.arts[0].percentageEi2Nrti =
                this.serviceData.scPercentageEi2Nrti;
              this.scenario.arts[0].percentage2dr =
                this.serviceData.scPercentage2dr;

              this.storageService.setSimulation(
                JSON.stringify(this.simulation)
              );
              this.storageService.setScenario(JSON.stringify(this.scenario));
              this.notificationService.info(
                this.translate.instant('Service updated')
              );
            },
            (err) => {
              this.notificationService.error(
                this.translate.instant('Error on get simulation')
              );
            }
          );
      }
    });
  }

  runSimulations(): void {
    if (!this.animationStarted && !this.timerStarted) {
      this.spinner.show('simulator');
      this.notificationService.info('Simulation started');
      this.animationStarted = true;
      this.timerStarted = true;
      this.errorOccured = false;
      this.startAnimation();
      this.patients = 'Simulating...';
      this.visits = 'Simulating...';
      this.hours = 'Simulating...';

      this.patientsSc = 'Simulating...';
      this.visitsSc = 'Simulating...';
      this.hoursSc = 'Simulating...';

      this.authenticationStateService.updateGlobalVar(this.animationStarted);
      this.simulationService.runSimulation(this.simulation.id).subscribe(
        (data) => {
          this.animationStarted = false;
          this.loadAnimation = false;
          this.storageService.setSimulationResult(
            JSON.stringify(data.resultKey)
          );
          this.result = data.resultKey;
          this.simulationService
            .getSimulationResultSummary(data.resultKey)
            .subscribe((summary) => {
              this.setPatientType(
                this.getAverage(
                  this.getArray(summary.results, 'patient_net', true)
                ),
                this.getAverage(
                  this.getArray(summary.results, 'patient_net', false)
                )
              );
              this.setVisits(
                this.getAverage(
                  this.getArray(summary.results, 'patient_type', true)
                ),
                this.getAverage(
                  this.getArray(summary.results, 'patient_type', false)
                )
              );
              this.setHours(
                this.getAverage(
                  this.getArray(summary.results, 'patient_type', true)
                ),
                this.getAverage(
                  this.getArray(summary.results, 'patient_type', false)
                ),
                this.getAverage(this.getArray(summary.results, 'demand', true)),
                this.getAverage(
                  this.getArray(summary.results, 'demand', false)
                ),
                this.getAverage(
                  this.getArray(summary.results, 'counsel', true)
                ),
                this.getAverage(
                  this.getArray(summary.results, 'counsel', false)
                )
              );

              if (!this.timerStarted) {
                this.authenticationStateService.updateGlobalVar(
                  this.animationStarted
                );
                const year =
                  new Date().getFullYear() + this.simulation.years - 1;
                this.simulationDate = formatDate(
                  new Date(year, 12, 0),
                  'EEEE d MMMM yyyy',
                  'en'
                );
                this.spinner.hide('simulator');
                this.notificationService.info('Simulation finished');
              }
            });
        },
        (err) => {
          console.error('error : ' + err);
          if (!this.timerStarted) {
            this.authenticationStateService.updateGlobalVar(
              this.animationStarted
            );
            this.spinner.hide('simulator');
            this.notificationService.error('Error on run simulation');
          } else {
            this.errorOccured = true;
          }
        }
      );
    }
  }

  public isAnimationExist() {
    return this.animationStarted || this.timerStarted;
  }

  public startAnimation() {
    if (this.loadAnimation && !this.animationStarted) {
      this.runAnimation();
    } else if (!this.loadAnimation && this.animationStarted) {
      this.runAnimation();
    }
  }

  public runAnimation() {
    const destroy = new Subject();
    let timerAnimation: number;
    // console.log('Animation started');
    this.setMiddleAnimationParams();
    this.setAnimationParams(0);
    this.pathTimeExt[0] = this.pathTime[0];
    this.pathTimeExt[1] = 5;
    this.pathTimeExt[2] = 10;
    this.pathTimeExt[3] = 15;
    this.pathTimeExt[4] = 20;
    // console.log('Path Time => ' + this.pathTime[0]);
    this.rxjsTimer.pipe(takeUntil(destroy)).subscribe((val) => {
      timerAnimation = val;
      //console.log(val);
      if (timerAnimation >= 16) {
        if (!this.animationStarted && !this.loadAnimation) {
          this.timerStarted = false;
          this.authenticationStateService.updateGlobalVar(
            this.animationStarted
          );
          if (!this.animationStarted) {
            if (this.errorOccured) {
              this.notificationService.error('Error on run simulation');
            } else {
              this.notificationService.info('Simulation finished');
              const year =
                new Date().getFullYear() + this.simulation.years - 1;
              this.simulationDate = formatDate(
                new Date(year, 12, 0),
                'EEEE d MMMM yyyy',
                'en'
              );
            }
            this.spinner.hide('simulator');
          }
          destroy.next({});
          destroy.complete();
          this.loadAnimation = false;
          this.stopAnimationAll();
        }
      }

      // if (timerAnimation === this.pathTimeExt[0] - 1) {
      //   console.log('STOP');
      //   this.stopAnimation(0);
      // }

      // if (timerAnimation === this.pathTimeExt[0]) {
      //   this.setAnimationParams(0);
      //   this.pathTimeExt[0] = this.pathTimeExt[0] + this.pathTime[0];
      //   console.log('PATH CHANGED' + this.pathTime[0]);
      // }

      for (let i = 0; i < 5; i++) {
        if (timerAnimation === this.pathMiddleTime[i]) {
          this.stopMiddleAnimation(i);
        }
      }

      for (let i = 0; i < 5; i++) {
        if (timerAnimation === this.pathTimeExt[i] - 1) {
          // console.log('STOP');
          this.stopAnimation(i);
        }

        if (timerAnimation === this.pathTimeExt[i]) {
          this.setAnimationParams(i);
          this.pathTimeExt[i] = this.pathTimeExt[i] + this.pathTime[i];
          // console.log('PATH CHANGED' + this.pathTime[i]);
        }
      }
    });
  }

  public setAnimationParams(order: number) {
    this.random = Math.floor(Math.random() * this.lowSpeedpaths.length);
    this.randomPath = this.lowSpeedpaths[this.random];
    this.pathTime[order] = this.lowSpeedPathTimes[this.random];
    if (order === 0) {
      this.showWalkingMan = true;
      this.bntStyle = this.randomPath;
    } else if (order === 1) {
      this.showWalkingMan2 = true;
      this.bntStyle2 = this.randomPath;
    } else if (order === 2) {
      this.showWalkingMan3 = true;
      this.bntStyle3 = this.randomPath;
    } else if (order === 3) {
      this.showWalkingMan4 = true;
      this.bntStyle4 = this.randomPath;
    } else if (order === 4) {
      this.showWalkingMan5 = true;
      this.bntStyle5 = this.randomPath;
    }
    //this.bntStyle = "path3-slow";
    // console.log('PATH ' + this.bntStyle);
  }

  public setMiddleAnimationParams() {
    //console.log('MIDDLE');
    for (let i = 0; i < 5; i++) {
      this.randomM = Math.floor(Math.random() * this.middleSpeedPaths.length);
      this.randomPathM = this.middleSpeedPaths[this.randomM];
      //console.log('MIDDLE PATH => ' + this.randomPathM);
      if (i === 0) {
        //console.log('MIDDLE PATH => ' + this.randomPathM );
        this.showMiddleWalkingMan = true;
        if (this.randomM > 4) {
          this.bntMiddleStyle = this.middleSpeedPaths[0];
          this.pathMiddleTime[0] = this.middleSpeedPathTimes[0];
        } else {
          this.bntMiddleStyle = this.middleSpeedPaths[5];
          this.pathMiddleTime[0] = this.middleSpeedPathTimes[5];
        }
      }
      if (i === 1) {
        //console.log('MIDDLE 2 ==> ' + this.randomPathM);
        this.showMiddleWalkingMan2 = true;
        if (this.randomM > 4) {
          this.bntMiddleStyle2 = this.middleSpeedPaths[1];
          this.pathMiddleTime[1] = this.middleSpeedPathTimes[1];
        } else {
          this.bntMiddleStyle2 = this.middleSpeedPaths[6];
          this.pathMiddleTime[1] = this.middleSpeedPathTimes[6];
        }
      }
      if (i === 2) {
        //console.log('MIDDLE 3 ==> ' + this.randomPathM);
        this.showMiddleWalkingMan3 = true;
        if (this.randomM > 4) {
          this.bntMiddleStyle3 = this.middleSpeedPaths[2];
          this.pathMiddleTime[2] = this.middleSpeedPathTimes[2];
        } else {
          this.bntMiddleStyle3 = this.middleSpeedPaths[7];
          this.pathMiddleTime[2] = this.middleSpeedPathTimes[7];
        }
      }
      if (i === 3) {
        //console.log('MIDDLE 4 ==> ' + this.randomPathM);
        this.showMiddleWalkingMan4 = true;
        if (this.randomM > 4) {
          this.bntMiddleStyle4 = this.middleSpeedPaths[3];
          this.pathMiddleTime[3] = this.middleSpeedPathTimes[3];
        } else {
          this.bntMiddleStyle4 = this.middleSpeedPaths[8];
          this.pathMiddleTime[3] = this.middleSpeedPathTimes[8];
        }
      }
      if (i === 4) {
        //console.log('MIDDLE 5 ==> ' + this.randomPathM);
        this.showMiddleWalkingMan5 = true;
        if (this.randomM > 4) {
          this.bntMiddleStyle5 = this.middleSpeedPaths[4];
          this.pathMiddleTime[4] = this.middleSpeedPathTimes[4];
        } else {
          this.bntMiddleStyle5 = this.middleSpeedPaths[9];
          this.pathMiddleTime[4] = this.middleSpeedPathTimes[9];
        }
      }
    }
  }

  public stopAnimation(order: number) {
    if (order === 0) {
      this.showWalkingMan = false;
      this.bntStyle = 'non-style';
    } else if (order === 1) {
      this.showWalkingMan2 = false;
      this.bntStyle2 = 'non-style';
    } else if (order === 2) {
      this.showWalkingMan3 = false;
      this.bntStyle3 = 'non-style';
    } else if (order === 3) {
      this.showWalkingMan4 = false;
      this.bntStyle4 = 'non-style';
    } else if (order === 4) {
      this.showWalkingMan5 = false;
      this.bntStyle5 = 'non-style';
    }
  }

  public stopMiddleAnimation(order: number) {
    if (order === 0) {
      this.showMiddleWalkingMan = false;
      this.bntMiddleStyle = 'non-style';
    } else if (order === 1) {
      this.showMiddleWalkingMan2 = false;
      this.bntMiddleStyle2 = 'non-style';
    } else if (order === 2) {
      this.showMiddleWalkingMan3 = false;
      this.bntMiddleStyle3 = 'non-style';
    } else if (order === 3) {
      this.showMiddleWalkingMan4 = false;
      this.bntMiddleStyle4 = 'non-style';
    } else if (order === 4) {
      this.showMiddleWalkingMan5 = false;
      this.bntMiddleStyle5 = 'non-style';
    }
  }

  public stopAnimationAll() {
    this.showWalkingMan = false;
    this.showWalkingMan2 = false;
    this.showWalkingMan3 = false;
    this.showWalkingMan4 = false;
    this.showWalkingMan5 = false;
    this.showMiddleWalkingMan = false;
    this.showMiddleWalkingMan2 = false;
    this.showMiddleWalkingMan3 = false;
    this.showMiddleWalkingMan4 = false;
    this.showMiddleWalkingMan5 = false;
    this.bntStyle = 'non-style';
    this.bntStyle2 = 'non-style';
    this.bntStyle3 = 'non-style';
    this.bntStyle4 = 'non-style';
    this.bntStyle5 = 'non-style';
    this.bntMiddleStyle = 'non-style';
    this.bntMiddleStyle2 = 'non-style';
    this.bntMiddleStyle3 = 'non-style';
    this.bntMiddleStyle4 = 'non-style';
    this.bntMiddleStyle5 = 'non-style';
  }

  public downloadResultFile() {
    if (this.storageService.getSimulationResult()) {
      this.subscription(
        this.simulationService
          .downloadResultFile(
            JSON.parse(this.storageService.getSimulationResult())
          )
          .subscribe((dataResult) => {
            const fileName = 'sim_result-' + this.datePipe.transform(new Date(), 'yyyy-MM-dd_HH-mm-ss') + '.xlsx';
            const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            downloadFile(fileName, contentType, dataResult);
          })
      );
    } else {
      this.notificationService.error('Simulation result could not find.');
    }
  }

  setPatientType(
    patientsMap: Map<string, Array<Pair>>,
    patientsScMap: Map<string, Array<Pair>>
  ) {
    let patients = 0;
    let patientsSc = 0;
    for (const key of patientsMap.keys()) {
      patients = 0;
      patientsSc = 0;
      const patientPairs: Pair[] = patientsMap.get(key);
      const patientScPairs: Pair[] = patientsScMap.get(key);
      patientPairs.forEach((element) => {
        if (element.first !== 'PREP'
          && element.first !== 'PEP') {
            patients = patients + element.second;
          }
      });

      patientScPairs.forEach((element) => {
        if (element.first !== 'PREP'
          && element.first !== 'PEP') {
            patientsSc = patientsSc + element.second;
          }
      });
    }
    this.patients = patients.toFixed(0);
    this.storageService.setPatients(this.patients);
    this.patientsSc = patientsSc.toFixed(0);
    this.storageService.setPatientsSc(this.patientsSc);
  }

  setVisits(
    patientsMap: Map<string, Array<Pair>>,
    patientsScMap: Map<string, Array<Pair>>
  ) {
    let visits = 0;
    let visitsSc = 0;

    for (const key of patientsMap.keys()) {
      const patientPairs: Pair[] = patientsMap.get(key);
      const patientScPairs: Pair[] = patientsScMap.get(key);
      patientPairs.forEach((element) => {
        visits = visits + element.second;
      });
      patientScPairs.forEach((element) => {
        visitsSc = visitsSc + element.second;
      });
    }
    this.visits = visits.toFixed(0);
    this.storageService.setVisits(this.visits);
    this.visitsSc = visitsSc.toFixed(0);
    this.storageService.setVisitsSc(this.visitsSc);
  }

  setHours(
    patientMap: Map<string, Array<Pair>>,
    patientMapSc: Map<string, Array<Pair>>,
    demandMap: Map<string, Array<Pair>>,
    demandMapSc: Map<string, Array<Pair>>,
    counselMap: Map<string, Array<Pair>>,
    counselMapSc: Map<string, Array<Pair>>
  ) {
    let hours = 0;
    let hoursSc = 0;

    const doctorResource = this.simulation.resources[0];
    const pharmacistResource = this.simulation.resources[1];
    const medicalLabScientistResource = this.simulation.resources[2];
    const hivSpecialistNurseResource = this.simulation.resources[3];
    const dieticianResource = this.simulation.resources[4];
    const generalNurseResource = this.simulation.resources[5];
    const counselorResource = this.simulation.resources[6];

    const doctorResourceSc = this.scenario.resources[0];
    const pharmacistResourceSc = this.scenario.resources[1];
    const medicalLabScientistResourceSc = this.scenario.resources[2];
    const hivSpecialistNurseResourceSc = this.scenario.resources[3];
    const dieticianResourceSc = this.scenario.resources[4];
    const generalNurseResourceSc = this.scenario.resources[5];
    const counselorResourceSc = this.scenario.resources[6];

    const diagnoseDurationNaive =
      (this.simulation.maxDiagnoseDurationNaive +
        this.simulation.minDiagnoseDurationNaive) /
      2;
    const monitoringDurationNaive =
      (this.simulation.maxMonitoringDurationNaive +
        this.simulation.minMonitoringDurationNaive) /
      2;
    const monitoringDurationExperienced =
      (this.simulation.maxMonitoringDurationExperienced +
        this.simulation.minMonitoringDurationExperienced) /
      2;
    const counsellingDuration =
      (this.simulation.maxCounsellingDurationExperienced +
        this.simulation.minCounsellingDurationExperienced) /
      2;
    const preventionDuration =
      (this.simulation.maxMonitoringDurationPrevention +
        this.simulation.minMonitoringDurationPrevention) /
      2;

    const diagnoseDurationNaiveSc =
      (this.scenario.maxDiagnoseDurationNaive +
        this.scenario.minDiagnoseDurationNaive) /
      2;
    const monitoringDurationNaiveSc =
      (this.scenario.maxMonitoringDurationNaive +
        this.scenario.minMonitoringDurationNaive) /
      2;
    const monitoringDurationExperiencedSc =
      (this.scenario.maxMonitoringDurationExperienced +
        this.scenario.minMonitoringDurationExperienced) /
      2;
    const counsellingDurationSc =
      (this.scenario.maxCounsellingDurationExperienced +
        this.scenario.minCounsellingDurationExperienced) /
      2;
    const preventionDurationSc =
      (this.scenario.maxMonitoringDurationPrevention +
        this.scenario.minMonitoringDurationPrevention) /
      2;

    for (const key of demandMap.keys()) {
      let diagnosisNaiveNumber = 0;
      let monitoringNaiveNumber = 0;
      let monitoringExperiencedNumber = 0;
      let counsellingNumber = 0;
      let preventionNumber = 0;

      let diagnosisNaiveNumberSc = 0;
      let monitoringNaiveNumberSc = 0;
      let monitoringExperiencedNumberSc = 0;
      let counsellingNumberSc = 0;
      let preventionNumberSc = 0;

      if (demandMap.get(key)) {
        const pairs: Pair[] = demandMap.get(key);
        const pairsSc: Pair[] = demandMapSc.get(key);
        for (let i = 0; i < pairs.length; i++) {
          const element = pairs[i];
          const elementSc = pairsSc[i];
          if (element.first === 'NAIVE') {
            diagnosisNaiveNumber = element.second;
            diagnosisNaiveNumberSc = elementSc.second;
          }
        }
      }

      if (patientMap.get(key)) {
        const pairs: Pair[] = patientMap.get(key);
        const pairsSc: Pair[] = patientMapSc.get(key);
        for (let i = 0; i < pairs.length; i++) {
          const element = pairs[i];
          const elementSc = pairsSc[i];
          if (element.first === 'NAIVE') {
            monitoringNaiveNumber = element.second;
            monitoringNaiveNumberSc = elementSc.second;
          } else if (element.first === 'PREVENTION') {
            preventionNumber = element.second;
            preventionNumberSc = elementSc.second;
          } else if (element.first === 'TX_EXPERIENCED') {
            monitoringExperiencedNumber = element.second;
            monitoringExperiencedNumberSc = elementSc.second;
          }
        }
      }

      if (counselMap.get(key)) {
        const pairs: Pair[] = counselMap.get(key);
        const pairsSc: Pair[] = counselMapSc.get(key);
        for (let i = 0; i < pairs.length; i++) {
          const element = pairs[i];
          const elementSc = pairsSc[i];
          counsellingNumber = counsellingNumber + element.second;
          counsellingNumberSc = counsellingNumberSc + elementSc.second;
        }
      }

      const doctorHours =
        (diagnosisNaiveNumber *
          doctorResource.diagnoseNaive *
          diagnoseDurationNaive +
          monitoringNaiveNumber *
          doctorResource.monitoringNaive *
          monitoringDurationNaive +
          monitoringExperiencedNumber *
          doctorResource.monitoringExperienced *
          monitoringDurationExperienced +
          counsellingNumber * doctorResource.counselling * counsellingDuration +
          preventionNumber * doctorResource.prevention * preventionDuration) /
        60;
      hours = hours + parseFloat(doctorHours.toFixed(0));

      const doctorHoursSc =
        (diagnosisNaiveNumberSc *
          doctorResourceSc.diagnoseNaive *
          diagnoseDurationNaiveSc +
          monitoringNaiveNumberSc *
          doctorResourceSc.monitoringNaive *
          monitoringDurationNaiveSc +
          monitoringExperiencedNumberSc *
          doctorResourceSc.monitoringExperienced *
          monitoringDurationExperiencedSc +
          counsellingNumberSc *
          doctorResourceSc.counselling *
          counsellingDurationSc +
          preventionNumberSc *
          doctorResourceSc.prevention *
          preventionDurationSc) /
        60;
      hoursSc = hoursSc + parseFloat(doctorHoursSc.toFixed(0));

      const pharmacistHours =
        (diagnosisNaiveNumber * pharmacistResource.diagnoseNaive +
          monitoringNaiveNumber * pharmacistResource.monitoringNaive +
          monitoringExperiencedNumber *
          pharmacistResource.monitoringExperienced +
          counsellingNumber * pharmacistResource.counselling +
          preventionNumber * pharmacistResource.prevention) /
        60;
      hours = hours + parseFloat(pharmacistHours.toFixed(0));

      const pharmacistHoursSc =
        (diagnosisNaiveNumberSc * pharmacistResourceSc.diagnoseNaive +
          monitoringNaiveNumberSc * pharmacistResourceSc.monitoringNaive +
          monitoringExperiencedNumberSc *
          pharmacistResourceSc.monitoringExperienced +
          counsellingNumberSc * pharmacistResourceSc.counselling +
          preventionNumberSc * pharmacistResourceSc.prevention) /
        60;
      hoursSc = hoursSc + parseFloat(pharmacistHoursSc.toFixed(0));

      const medicalLabScientistHours =
        (diagnosisNaiveNumber * medicalLabScientistResource.diagnoseNaive +
          monitoringNaiveNumber * medicalLabScientistResource.monitoringNaive +
          monitoringExperiencedNumber *
          medicalLabScientistResource.monitoringExperienced +
          counsellingNumber * medicalLabScientistResource.counselling +
          preventionNumber * medicalLabScientistResource.prevention) /
        60;
      hours = hours + parseFloat(medicalLabScientistHours.toFixed(0));

      const medicalLabScientistHoursSc =
        (diagnosisNaiveNumberSc * medicalLabScientistResourceSc.diagnoseNaive +
          monitoringNaiveNumberSc *
          medicalLabScientistResourceSc.monitoringNaive +
          monitoringExperiencedNumberSc *
          medicalLabScientistResourceSc.monitoringExperienced +
          counsellingNumberSc * medicalLabScientistResourceSc.counselling +
          preventionNumberSc * medicalLabScientistResourceSc.prevention) /
        60;
      hoursSc = hoursSc + parseFloat(medicalLabScientistHoursSc.toFixed(0));

      const hivSpecialistNurseHours =
        (diagnosisNaiveNumber *
          hivSpecialistNurseResource.diagnoseNaive *
          diagnoseDurationNaive +
          monitoringNaiveNumber * hivSpecialistNurseResource.monitoringNaive +
          monitoringExperiencedNumber *
          hivSpecialistNurseResource.monitoringExperienced +
          counsellingNumber * hivSpecialistNurseResource.counselling +
          preventionNumber * hivSpecialistNurseResource.prevention) /
        60;
      hours = hours + parseFloat(hivSpecialistNurseHours.toFixed(0));

      const hivSpecialistNurseHoursSc =
        (diagnosisNaiveNumberSc *
          hivSpecialistNurseResourceSc.diagnoseNaive *
          diagnoseDurationNaiveSc +
          monitoringNaiveNumberSc *
          hivSpecialistNurseResourceSc.monitoringNaive +
          monitoringExperiencedNumberSc *
          hivSpecialistNurseResourceSc.monitoringExperienced +
          counsellingNumberSc * hivSpecialistNurseResourceSc.counselling +
          preventionNumberSc * hivSpecialistNurseResourceSc.prevention) /
        60;
      hoursSc = hoursSc + parseFloat(hivSpecialistNurseHoursSc.toFixed(0));

      const dieticianHours =
        (diagnosisNaiveNumber * dieticianResource.diagnoseNaive +
          monitoringNaiveNumber * dieticianResource.monitoringNaive +
          monitoringExperiencedNumber *
          dieticianResource.monitoringExperienced +
          counsellingNumber * dieticianResource.counselling +
          preventionNumber * dieticianResource.prevention) /
        60;
      hours = hours + parseFloat(dieticianHours.toFixed(0));

      const dieticianHoursSc =
        (diagnosisNaiveNumberSc * dieticianResourceSc.diagnoseNaive +
          monitoringNaiveNumberSc * dieticianResourceSc.monitoringNaive +
          monitoringExperiencedNumberSc *
          dieticianResourceSc.monitoringExperienced +
          counsellingNumberSc * dieticianResourceSc.counselling +
          preventionNumberSc * dieticianResourceSc.prevention) /
        60;
      hoursSc = hoursSc + parseFloat(dieticianHoursSc.toFixed(0));

      const generalNurseHours =
        (diagnosisNaiveNumber *
          generalNurseResource.diagnoseNaive *
          diagnoseDurationNaive +
          monitoringNaiveNumber * generalNurseResource.monitoringNaive +
          monitoringExperiencedNumber *
          generalNurseResource.monitoringExperienced +
          counsellingNumber * generalNurseResource.counselling +
          preventionNumber * generalNurseResource.prevention) /
        60;
      hours = hours + parseFloat(generalNurseHours.toFixed(0));

      const generalNurseHoursSc =
        (diagnosisNaiveNumberSc *
          generalNurseResourceSc.diagnoseNaive *
          diagnoseDurationNaiveSc +
          monitoringNaiveNumberSc * generalNurseResourceSc.monitoringNaive +
          monitoringExperiencedNumberSc *
          generalNurseResourceSc.monitoringExperienced +
          counsellingNumberSc * generalNurseResourceSc.counselling +
          preventionNumberSc * generalNurseResourceSc.prevention) /
        60;
      hoursSc = hoursSc + parseFloat(generalNurseHoursSc.toFixed(0));

      const counselorHours =
        (diagnosisNaiveNumber * counselorResource.diagnoseNaive +
          monitoringNaiveNumber * counselorResource.monitoringNaive +
          monitoringExperiencedNumber *
          counselorResource.monitoringExperienced +
          counsellingNumber *
          counselorResource.counselling *
          counsellingDuration +
          preventionNumber * counselorResource.prevention) /
        60;
      hours = hours + parseFloat(counselorHours.toFixed(0));

      const counselorHoursSc =
        (diagnosisNaiveNumberSc * counselorResourceSc.diagnoseNaive +
          monitoringNaiveNumberSc * counselorResourceSc.monitoringNaive +
          monitoringExperiencedNumberSc *
          counselorResourceSc.monitoringExperienced +
          counsellingNumberSc *
          counselorResourceSc.counselling *
          counsellingDurationSc +
          preventionNumberSc * counselorResourceSc.prevention) /
        60;
      hoursSc = hoursSc + parseFloat(counselorHoursSc.toFixed(0));
    }

    this.hours = hours.toFixed(0);
    this.storageService.setHours(this.hours);
    this.hoursSc = hoursSc.toFixed(0);
    this.storageService.setHoursSc(this.hoursSc);
  }

  getArray(
    mapArray: Array<SimulationResultSummaryResponse>,
    type: string,
    baseline: boolean
  ): Map<string, Array<Pair>>[] {
    const returnArrayMap: Map<string, Array<Pair>>[] = [];
    const arraySize = Math.floor(mapArray.length / 2);
    let startIndex = 0;
    let finishIndex = arraySize;
    if (!baseline) {
      startIndex = startIndex + arraySize;
      finishIndex = finishIndex + arraySize;
    }
    for (let i = startIndex; i < finishIndex; i++) {
      if (type === 'patient_type') {
        returnArrayMap.push(mapArray[i].visitMapByPatientType);
      } else if (type === 'patient') {
        returnArrayMap.push(mapArray[i].yearlyPatientMap);
      } else if (type === 'patient_net') {
        returnArrayMap.push(mapArray[i].yearlyNetPatientMap);
      } else if (type === 'demand') {
        returnArrayMap.push(mapArray[i].visitsMapByDemand);
      } else if (type === 'counsel') {
        returnArrayMap.push(mapArray[i].visitsCounsellingMapByType);
      }
    }
    return returnArrayMap;
  }

  getAverage(mapArray: Map<string, Array<Pair>>[]): Map<string, Array<Pair>> {
    const averageMap: Map<string, Array<Pair>> = new Map<string, Array<Pair>>();
    const mapSize = mapArray.length;
    mapArray.forEach((map) => {
      const ttt = new Map(Object.entries(map));
      for (const key of ttt.keys()) {
        if (averageMap.get(key)) {
          const averagePairs: Pair[] = averageMap.get(key);
          const newPairs: Pair[] = [];
          const pairs: Pair[] = ttt.get(key);
          for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i];
            const averagePair = averagePairs[i];
            const second = averagePair.second + pair.second / mapSize;
            newPairs.push({
              first: pair.first,
              second: parseFloat(second.toFixed(2)),
            });
          }
          averageMap.set(key, newPairs);
        } else {
          const newPairs: Pair[] = [];
          const pairs: Pair[] = ttt.get(key);
          pairs.forEach((pair) => {
            newPairs.push({
              first: pair.first,
              second: parseFloat((pair.second / mapSize).toFixed(2)),
            });
          });
          averageMap.set(key, newPairs);
        }
      }
    });
    return averageMap;
  }
}
