/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { AccessTokenResponse, CheckEmailOrPhoneResponse, LoginResponse, OtpResponse, RegisterRequest, RegisterResponse, ResetPasswordValidateOtpRequest, UserProfile, ValidateOtpRequest } from '../../shared/model/user-profile';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { createQueryParamString } from '../util';

@Injectable({
    providedIn: 'root'
})
export class UserAuthService {

    private baseUrl = '/ums/v1/users';

    constructor(private http: HttpClient) {
    }

    uploadBulkUsersFile(file: File, role: string, invitationType: string, organisationBranchId: number, clinicianIdList: number[]): Observable<HttpEvent<{}>> {
        const fileAlias = 'file';
        const formData = new FormData();
        formData.set(fileAlias, file, file.name);

        const queryParams = {
            organisationBranchId,
            clinicianIdList
        };

        const queryStr = createQueryParamString(queryParams);

        let url = `${environment.url}/ums/v1/users/bulk-operations/organisation-member-upload/role/${role}/invitation-type/${invitationType}`;
        url = queryStr ? (url + '?' + queryStr) : url;

        return this.http.post<any>(url, formData, {
            observe: 'events',
            reportProgress: true,
            responseType: 'json'
        });
    }


    downloadOrgUserUploadTemplate(role: string): Observable<Blob> {
        return this.http.get(environment.url + `/ums/v1/users/bulk-operations/organisation-member-download/template/${role}`, { responseType: 'blob' as 'json' })
            .pipe(map((res: any) => {
                return (res || {});
            }
            ));
    }

    registerUserProfile(register: RegisterRequest, capthca?: string): Observable<RegisterResponse> {
        let url = `${environment.url}${this.baseUrl}/signup`;
        url = this.addCaptchaQueryString(url, capthca);

        return this.http.post<RegisterResponse>(url, register)
        .pipe(map((res: any) => res || {}));
    }

    checkEmailOrPhoneIsAvailable(emailOrPhone: string): Observable<CheckEmailOrPhoneResponse> {
        return this.http.get<CheckEmailOrPhoneResponse>(environment.url + `${this.baseUrl}/exist/${emailOrPhone}`)
            .pipe(map((res: any) => res || {}));
    }

    login(emailOrPhoneNumber: string, password: string, capthca?: string): Observable<LoginResponse> {
        let url = `${environment.url}${this.baseUrl}/signin`;
        url = this.addCaptchaQueryString(url, capthca);

        return this.http.post<LoginResponse>(url, { emailOrPhoneNumber, password })
            .pipe(map((res: any) => res || {}));
    }

    logout(): Observable<void> {
        return this.http.post<void>(environment.url + `${this.baseUrl}/me/logout`, {  })
            .pipe(map((res: any) => res || {}));
    }

    refreshToken(refreshToken: string): Observable<AccessTokenResponse> {
        let myheaders = new HttpHeaders();
        myheaders = myheaders.append('Content-Type', 'application/json');
        myheaders = myheaders.append('X-Authorization', refreshToken);
        const options = {headers: myheaders};

        return this.http.post<AccessTokenResponse>(environment.url + `${this.baseUrl}/access-token/refresh`, {}, options)
            .pipe(map((res: any) => res || {}));
    }

    regenerateOtp(emailOrPhone: string, otpType: string, capthca?: string): Observable<OtpResponse> {
        let url = `${environment.url}${this.baseUrl}/otp/regenerate/${emailOrPhone}/${otpType}`;
        url = this.addCaptchaQueryString(url, capthca);

        return this.http.post<OtpResponse>(url, null)
            .pipe(map((res: any) => res || {}));
    }

    getWaitingOtp(emailOrPhone: string): Observable<OtpResponse> {
        return this.http.get<OtpResponse>(environment.url + `${this.baseUrl}/otp/waiting/${emailOrPhone}`)
            .pipe(map((res: any) => res || {}));
    }

    validateOtpAndLogin(emailOrPhoneNumber: string, otpShortCode: string, capthca?: string): Observable<LoginResponse> {
        const request = new ValidateOtpRequest();
        request.emailOrPhoneNumber = emailOrPhoneNumber;
        request.otp = otpShortCode;

        let url = `${environment.url}${this.baseUrl}/otp/validate/signin`;
        url = this.addCaptchaQueryString(url, capthca);

        return this.http.post<LoginResponse>(url, request)
            .pipe(map((res: any) => res || {}));
    }

    validateOtpAndRegister(emailOrPhoneNumber: string, otpShortCode: string, capthca?: string): Observable<LoginResponse> {
        const request = new ValidateOtpRequest();
        request.emailOrPhoneNumber = emailOrPhoneNumber;
        request.otp = otpShortCode;

        let url = `${environment.url}${this.baseUrl}/otp/validate/register`;
        url = this.addCaptchaQueryString(url, capthca);

        return this.http.post<LoginResponse>(url, request)
            .pipe(map((res: any) => res || {}));
    }

    validateOtpAndResetPassword(emailOrPhoneNumber: string, otpShortCode: string, password: string, capthca?: string): Observable<LoginResponse> {
        const request = new ResetPasswordValidateOtpRequest();
        request.emailOrPhoneNumber = emailOrPhoneNumber;
        request.otp = otpShortCode;
        request.password = password;

        let url = `${environment.url}${this.baseUrl}/otp/validate/reset-password`;
        url = this.addCaptchaQueryString(url, capthca);

        return this.http.post<LoginResponse>(url, request)
            .pipe(map((res: any) => res || {}));
    }


    private addCaptchaQueryString(url, capthca?) {
        if (capthca) {
            const reqParam = {'g-recaptcha-response': capthca};
            const queryParamsStr = createQueryParamString(reqParam);
            url = url + '?' + queryParamsStr;
        }
        return url;
    }

}
