import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { MenuItemService } from 'src/app/shared/services/menu-item.service';
import { PushNotificationService } from 'src/app/shared/services/push-notification.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList;
  blue = false;

  url = '';
  sidebarOpened = false;
  status = false;

  public showSearch = false;

  public config: PerfectScrollbarConfigInterface = {};
  private mobileQueryListener: () => void;

  constructor(
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItemService: MenuItemService,
    private pushNotificationService: PushNotificationService,
    private notificationService: NotificationService
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 1023px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
  }

  ngOnInit(): void {
    this.pushNotificationService.requestPermission();
    this.checkBrowserNotificationPermission();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
    this.pushNotificationService.destroy();
  }

  clickEvent() {
    this.status = !this.status;
  }

  checkBrowserNotificationPermission() {
       
    if (!("Notification" in window)) {
      this.notificationService.warn("This browser does not support desktop notification, so you cannot get push notifications");
    } else if (Notification.permission === "granted") {
      this.notificationService.success("Notification permissions have been granted");
    } else {
      console.log("Notification status =>>> " + Notification.permission);
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          console.log('Notification granted');
        } else {
          this.notificationService.error("You haven't granted for notification permission, so you cannot get push notifications");
        }
      });
    }
  }

}
