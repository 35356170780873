<div class="w-100p">
    <div *ngIf='invitation' class="d-flex align-items-center p-12 m-b-5">
        <!--button mat-icon-button (click)="removeClass()" class="d-lg-none">
            <mat-icon>arrow_back</mat-icon>
        </button-->
    </div>
    <div *ngIf='invitation' class="relative b-t" style="height: calc(100vh - 255px);" [perfectScrollbar]="config">
        <div class="p-20">
            <div>
                {{ 'ENUM.INVITATION_TYPE.' + invitation.type  | translate }}
            </div>
            <span class="m-t-10">
                <span class="label {{getTypeColorClass(invitation)}} m-l-5"> {{ 'ENUM.INVITATION_TYPE.' + invitation.type  | translate }}</span>
            </span>

            <div class="d-flex align-items-center m-t-20 p-t-20 b-t">
                <img [src]="getRowPhotoUrl(invitation)" class="img-circle" alt="userimg" width="50px" height="50px">
                <div class="m-l-10">
                    <h5 class="m-0 font-medium">{{invitation.invitor.firstName + ' ' + invitation.invitor.lastName}}</h5>
                    <span class="text-muted">To {{getInviteeUsername(invitation)}},</span>
                </div>
            </div>

            <div class="m-t-15">
                <div class="font-medium">
                    InvitorNote
                </div>
                {{invitation.invitorNote}}
            </div>

            <div class="m-t-15" *ngIf="invitation.responseTime">
                <span class="m-t-10">
                    <span class="font-medium"> InviteeNote </span>
                    <span class="label {{getStatusColorClass(invitation)}} m-l-5"> {{ 'ENUM.INVITATION_STATUS.' + invitation.status  | translate }}</span>
                </span>
                <div>
                    {{invitation.inviteeNote}}
                </div>
            </div>

            <div class="m-t-10" *ngIf="!invitation.responseTime && inboxType === InboxType.INCOMING_INVITATIONS">
                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>InviteeNote</mat-label>
                        <textarea matInput rows="5" [(ngModel)]="invitation.inviteeNote" ></textarea>
                    </mat-form-field>
                    <!-- <textarea id="w3mission" rows="5" placeholder="Type your reply here">Type your reply here </textarea> -->
                    <div class="d-flex align-items-center bg-light-info p-10" *ngIf="inboxType === InboxType.INCOMING_INVITATIONS">
                        <div class="ml-auto">
                            <button mat-raised-button color="warn" (click)='onSendReject()'>Reject</button>
                            <button mat-raised-button color="accent" class="m-l-10" (click)='onSendConfirm()'>Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>