<div fxLayout="row wrap" *ngIf="suppressionList">
  <div fxFlex="100">
    <app-clinical-outcome-summary [clinicalOutcome]="clinicalOutcome"
                                  [suppressionList]="suppressionList"></app-clinical-outcome-summary>
  </div>
  <div fxFlex="100">
    <app-suppression-list-summary [clinicalOutcome]="clinicalOutcome"
                                  [suppressionList]="suppressionList"></app-suppression-list-summary>
  </div>
</div>

<div class="content-box d-flex flex-column" *ngIf="drugInteractionNewResponse">
  <h2 class="content-box__title">Drug Drug Interaction</h2>

  <div class="flex-grow-1 overflow-auto" *ngIf="drugInteractionNewResponse">
    <table class="clinician-summary-table">
      <tr *ngFor="let response of drugInteractionNewResponse | slice:0:1; let i=index">
        <!--              <tr>-->
        <td class="font-medium m-b-0 m-t-0 pl-16"><strong>ARTs</strong></td>
        <td class="table_title pb-8" *ngFor="let r1 of response.medicines">
            <span class="font-medium m-b-0 m-t-0"
            ><strong>{{r1}}</strong></span>
        </td>
        <!--              </tr>-->
      </tr>

      <tbody>
      <tr *ngFor="let r2 of drugInteractionNewResponse">
        <td class="art_title">{{r2.art}}</td>
        <td class="{{m}}" *ngFor="let m of r2.medicinesResult">
            <span class="{{m}} align-center mat-cell cdk-cell cdk-column-label mat-column-label ng-star-inserted"
            >{{m}}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<!--
<mat-card>
  <mat-card-header>
    <mat-card-title>
      Drug Drug Interaction
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>

    &lt;!&ndash;The content below is only a placeholder and can be replaced.&ndash;&gt;
    <div id="chart">
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
        [stroke]="chartOptions.stroke"
        [markers]="chartOptions.markers"
        [fill]="chartOptions.fill"
        [title]="chartOptions.title"
      ></apx-chart>
    </div>
  </mat-card-content>
</mat-card>
-->


<div fxLayout="row wrap" fxLayoutAlign="center start" *ngIf="!suppressionList">
  <mat-card fxFlex.gt-sm="50%" fxFlex="50">

    <mat-card-content>
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <img fxFlexAlign="center" src="assets/images/smarthiv-clinician_800x800.png" width="300" height="300"
             alt="Smarthic Clinician">
      </div>
      <p>You haven't got any filled form, please fill a form before to see your outcomes.</p>
      <button mat-raised-button color="warn" (click)="goToFormPage()">Go to form page</button>
    </mat-card-content>
  </mat-card>
</div>
