<div class="section-title-bar justify-content-between mb-16">
    <h2 class="content-box__title mb-0">{{ 'TRIAL.SUMMARY_DASHBOARD' | translate }}</h2>
    <mat-icon matTooltip="{{ 'TRIAL.SUMMARY_DASHBOARD' | translate }}" class="flex-shrink-0">info</mat-icon>
</div>

<div class="content-box mb-24">
    <h2 class="content-box__title mb-16">{{ 'TRIAL.FILTERS' | translate }}</h2>

    <div class="form-box mb-0 pt-20 summary-filter-box">
        <div fxFlex="20" fxLayout="column" fxLayoutAlign="space-around center">
            <label class="filter-label">{{ 'TRIAL.TOTAL_PATIENTS' | translate }}</label>
            <label class="patient-count-label">{{patientCount}}</label>
        </div>
        <div fxFlex="25">
            <label class="filter-label">{{ 'TRIAL.COUNTRY' | translate }}</label>
            <ng-multiselect-dropdown 
                [placeholder]="'Country'"
                [settings]="dropdownSettingsCountry" 
                [data]="dropdownListCountry"
                [(ngModel)]="selectedItemsCountry"
                (onSelect)="onItemSelectCountry($event)"
                (onDeSelect)="onDeSelectCountry($event)" 
                (onDropDownClose)="onDropDownCloseCountry()"
                (click)="dropDownSelectCountry = true">
            </ng-multiselect-dropdown>
        </div>
        <div fxFlex="25">
            <label class="filter-label">{{ 'TRIAL.STATE' | translate }}</label>
            <ng-multiselect-dropdown [placeholder]="'State'"
                [settings]="dropdownSettingsState" 
                [data]="dropdownListState"
                [(ngModel)]="selectedItemsState" 
                (onSelect)="onItemSelectState($event)"
                (onDeSelect)="onDeSelectState($event)"
                (onDropDownClose)="onDropDownCloseState()"
                (click)="dropDownSelectState = true">
            </ng-multiselect-dropdown>
        </div>
        <div fxFlex="25">
            <label class="filter-label">{{ 'TRIAL.CENTRE' | translate }}</label>
            <ng-multiselect-dropdown [placeholder]="'Centre'"
                [settings]="dropdownSettingsCentre" 
                [data]="dropdownListCentre"
                [(ngModel)]="selectedItemsCentre" 
                (onSelect)="onItemSelectCentre($event)"
                (onDeSelect)="onDeSelectCentre($event)"
                (onDropDownClose)="onDropDownCloseCentre()"
                (click)="dropDownSelectCentre = true">
            </ng-multiselect-dropdown>
        </div>
    </div>
</div>                 


<div class="outcomes-charts">
    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'TRIAL.NUMBER_PATIENT_PER_STATE' | translate }}</h3>
            <mat-icon matTooltip="{{ 'TRIAL.NUMBER_PATIENT_PER_STATE' | translate }}" class="flex-shrink-0">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'TRIAL.PATIENTS' | translate }}</p>

        <div id="chartPatientPerState" class="d-flex flex-column" *ngIf="chartPatientPerStateOptions">
            <apx-chart [series]="chartPatientPerStateOptions.series" 
                [chart]="chartPatientPerStateOptions.chart"
                [colors]="chartPatientPerStateOptions.colors" 
                [dataLabels]="chartPatientPerStateOptions.dataLabels"
                [plotOptions]="chartPatientPerStateOptions.plotOptions" 
                [yaxis]="chartPatientPerStateOptions.yaxis"
                [legend]="chartPatientPerStateOptions.legend" 
                [fill]="chartPatientPerStateOptions.fill"
                [stroke]="chartPatientPerStateOptions.stroke" 
                [tooltip]="chartPatientPerStateOptions.tooltip"
                [xaxis]="chartPatientPerStateOptions.xaxis" 
                [grid]="chartPatientPerStateOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'TRIAL.NUMBER_PATIENT_PER_TREATMENT' | translate }}</h3>
            <mat-icon matTooltip="{{ 'TRIAL.NUMBER_PATIENT_PER_TREATMENT' | translate }}" class="flex-shrink-0" class="flex-shrink-0">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'TRIAL.PATIENTS' | translate }}</p>

        <div id="chartTreatmentStatus" class="d-flex flex-column" *ngIf="chartTreatmentStatusOptions">
            <apx-chart 
                [series]="chartTreatmentStatusOptions.series"
                [xaxis]="chartTreatmentStatusOptions.xaxis"
                [yaxis]="chartTreatmentStatusOptions.yaxis" 
                [chart]="chartTreatmentStatusOptions.chart"
                [dataLabels]="chartTreatmentStatusOptions.dataLabels" 
                [plotOptions]="chartTreatmentStatusOptions.plotOptions"
                [legend]="chartTreatmentStatusOptions.legend" 
                [fill]="chartTreatmentStatusOptions.fill"
                [stroke]="chartTreatmentStatusOptions.stroke"
                [tooltip]="chartTreatmentStatusOptions.tooltip"
                [grid]="chartTreatmentStatusOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'TRIAL.SUPPRESSED' | translate }}</h3>
            <mat-icon matTooltip="{{ 'TRIAL.SUPPRESSED' | translate }}" class="flex-shrink-0">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'TRIAL.PATIENTS' | translate }}</p>

        <div id="chartSuppressed" class="d-flex flex-column" *ngIf="chartSuppressedOptions">
            <apx-chart [series]="chartSuppressedOptions.series" 
                [chart]="chartSuppressedOptions.chart"
                [colors]="chartSuppressedOptions.colors" 
                [dataLabels]="chartSuppressedOptions.dataLabels"
                [plotOptions]="chartSuppressedOptions.plotOptions" 
                [yaxis]="chartSuppressedOptions.yaxis"
                [legend]="chartSuppressedOptions.legend" 
                [fill]="chartSuppressedOptions.fill"
                [stroke]="chartSuppressedOptions.stroke" 
                [tooltip]="chartSuppressedOptions.tooltip"
                [xaxis]="chartSuppressedOptions.xaxis" 
                [grid]="chartSuppressedOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'TRIAL.NUMBER_PATIENT_PER_SUPPRESSION' | translate }}</h3>
            <mat-icon matTooltip="{{ 'TRIAL.NUMBER_PATIENT_PER_SUPPRESSION' | translate }}" class="flex-shrink-0">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'TRIAL.PATIENTS' | translate }}</p>

        <div id="chartSuppressionStatus" class="d-flex flex-column" *ngIf="chartSuppressionStatusOptions">
            <apx-chart 
                [series]="chartSuppressionStatusOptions.series"
                [xaxis]="chartSuppressionStatusOptions.xaxis"
                [yaxis]="chartSuppressionStatusOptions.yaxis" 
                [chart]="chartSuppressionStatusOptions.chart"
                [dataLabels]="chartSuppressionStatusOptions.dataLabels" 
                [plotOptions]="chartSuppressionStatusOptions.plotOptions"
                [legend]="chartSuppressionStatusOptions.legend" 
                [fill]="chartSuppressionStatusOptions.fill"
                [stroke]="chartSuppressionStatusOptions.stroke"
                [tooltip]="chartSuppressionStatusOptions.tooltip"
                [grid]="chartSuppressionStatusOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'TRIAL.ENROLL' | translate }}</h3>
            <mat-icon matTooltip="{{ 'TRIAL.ENROLL' | translate }}" class="flex-shrink-0">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'TRIAL.PATIENTS_PERCENT' | translate }}</p>

        <div id="chartPieEnroll" class="d-flex flex-column" *ngIf="chartPieEnrollOptions">
            <apx-chart 
                [series]="chartPieEnrollOptions.series"
                [chart]="chartPieEnrollOptions.chart"
                [labels]="chartPieEnrollOptions.labels"
                [legend]="chartPieEnrollOptions.legend"
                [responsive]="chartPieEnrollOptions.responsive"
                [colors]="chartPieEnrollOptions.colors"
                [tooltip]="chartPieEnrollOptions.tooltip">
            </apx-chart>
        </div>
    </div>
</div>
    


