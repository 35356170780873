/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColumnMode, SelectionType, TableColumn } from '@swimlane/ngx-datatable';
import { DatatablePage } from '../../model/datatable-page';
import { faEye, faEyeSlash, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit {
  faEye = faEye; faEyeSlash = faEyeSlash; faLock = faLock; faUnlock = faUnlock;

  selectionType = SelectionType;
  columnMode = ColumnMode;
  pageLimitOptions = [10, 20, 50, 100];
  pristineColumns: TableColumn[];
  allColumnToggled = false;
  showToggleColumnButton = false;

  @Input() allColumnList: TableColumn[];
  @Input() rows = [];
  @Input() columns: TableColumn[];
  @Input() page: DatatablePage = new DatatablePage();
  @Input() sort = { prop: 'id', dir: 'desc' };
  @Input() styleClass = 'cust-striped-datatable';

  @Input() showPageLimit = true;
  @Input() showPageSummary = true;
  @Input() showPagination = true;

  @Output() pageChanged = new EventEmitter<DatatablePage>();
  @Output() sortChanged = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.pristineColumns = this.columns;
    if (this.allColumnList) {
      this.allColumnToggled = this.columns.length === this.allColumnList.length;
      this.showToggleColumnButton = this.columns.length !== this.allColumnList.length;
    }
  }

  onPageEvent(pageInfo) {
    this.page.pageNumber = pageInfo.page-1;
    this.pageChanged.emit(this.page);
  }

  onPageLimitChanged(limit) {
    this.page.size = limit;
    this.pageChanged.emit(this.page);
  }

  onSortChanged(event) {
    const sort = event.sorts[0];
    this.sortChanged.emit(sort);
  }

  onToggleAll(checked: boolean) {
    if (checked === true) {
      this.columns = this.allColumnList;
    } else {
      this.columns = this.pristineColumns;
    }
    this.allColumnToggled = checked;
  }

  isColumnShowing(col: TableColumn): boolean {
    for (const c of this.columns) {
      if (c.prop === col.prop) {
        return true;
      }
    }
    return false;
  }

  onToggle(col) {
    const isShowing = this.isColumnShowing(col);
    if (isShowing === false) {
      this.columns = [...this.columns, col];
    } else {
      this.columns = this.columns.filter(c => c.prop !== col.prop);
    }
    this.allColumnToggled = this.columns.length === this.allColumnList.length;
  }


  onFreeze(col: TableColumn) {
    col.frozenLeft = !col.frozenLeft;
    this.columns = [...this.columns];
  }

}
