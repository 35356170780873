import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../module/notification/service/notification.service';
import { UserProfileService } from '../user-profile.service';
import { UserAccount } from '../../model/user-account';
import { AuthenticationStateService } from '../../state/authentication-state.service';

@Injectable({
    providedIn: 'root'
})
export class UserEnabledGuardService implements CanActivate {

    constructor(
        private translate: TranslateService,
        private notificationService: NotificationService,
        private userProfileService: UserProfileService,
        private authenticationStateService: AuthenticationStateService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.userProfileService.getUserAccount().pipe(map(user => {
            this.authenticationStateService.notifyLoginInfoWithAccount(user);
            if (!user.enabled) {
                this.notificationService.error(this.translate.instant('NOTIFICATION.ERROR.USER_NOT_ENABLED'));
            }
            return user.enabled;
        }), catchError( error => {
            console.log('There is an error while getting account' + JSON.stringify(error));
            this.authenticationStateService.logout();
            return of(false);
        }));
    }
}
