/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/shared/component/base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';
import { Gender } from 'src/app/shared/model/enum/gender';
import { Role } from 'src/app/shared/model/enum/role';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { OtpType } from 'src/app/shared/model/enum/otp-type';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends BaseComponent implements OnInit, OnDestroy {

  private returnUrl: string;
  captcha: string;
  recaptchaSiteKey = environment.recaptchaSiteKey;

  Gender: typeof Gender = Gender;
  Role: typeof Role = Role;

  genderOptions: string[];
  roleOptions: string[];

  formGroup: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    gender: new FormControl('', [Validators.required]),
    userType: new FormControl('', [Validators.required]),
    otpEnabled: new FormControl('', []),
    termsOfServiceChecked: new FormControl('', []),
    captcha:  new FormControl('', [Validators.required])
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private userAuthService: UserAuthService,
    private authenticationStateService: AuthenticationStateService) {
    super();
  }

  ngOnInit() {
    const urlParam = 'returnUrl';
    this.returnUrl = this.route.snapshot.queryParams[urlParam] || '/';

    const gOptions: string[] = Object.keys(Gender);
    this.genderOptions = gOptions.slice(gOptions.length / 2);

    const rOptions: string[] = Object.keys(Role);
    this.roleOptions = rOptions.slice(rOptions.length / 2);
    this.roleOptions = this.roleOptions.filter(r => Role[Role.SYSTEM_ADMINISTRATOR] !== r);

    const isAuthenticated = this.authenticationStateService.isAuthenticated();
    if (isAuthenticated) {
      this.router.navigateByUrl(this.returnUrl);
    }

  }

  public register() {
    if (this.formGroup.valid) {
      const rawVal = this.formGroup.getRawValue();
      const item = {...rawVal};

      if (item.termsOfServiceChecked !== true) {
        this.notificationService.error(this.translate.instant('REGISTER_FORM.NOTIFICATION.TERM_OF_SERVICE_NOT_SELECTED'));
      } else {
        item.roles = Role[item.userType];
        item.emailOrPhoneNumber = item.emailOrPhoneNumber.emailOrPhoneType === 'EMAIL' ? item.emailOrPhoneNumber.email : item.emailOrPhoneNumber.phone;

        item.password = this.formGroup.get('passwordFormGroup').get('password').value;

        delete item.passwordFormGroup;
        delete item.termsOfServiceChecked;

        delete item.userType;
        delete item.captcha;

        this.userAuthService.registerUserProfile(item, this.captcha).subscribe(data => {
          this.notificationService.success(this.translate.instant('REGISTER_FORM.NOTIFICATION.USER_CREATED'));
          this.goToOtpConfirm(data.email ? data.email : data.phoneNumber);
        }, (error) => {
          this.formGroup.get('captcha').reset();
          console.log('Error on register ' + JSON.stringify(error));
          this.notificationService.error(this.translate.instant('REGISTER_FORM.NOTIFICATION.REGISTER_FAILED'));
        });
      }

    }
  }

  goToLogin() {
    if (this.returnUrl) {
      this.router.navigate(['login'], { queryParams: { returnUrl: this.returnUrl }});
    } else {
      this.router.navigateByUrl('login');
    }
  }

  goToOtpConfirm(emailOrPhoneNumber) {
    this.router.navigate(['user/otp-confirm', OtpType[OtpType.REGISTRATION], emailOrPhoneNumber], { queryParams: { returnUrl: this.returnUrl }});
  }

  goToTermsOfUse() {
    this.router.navigateByUrl('user/terms-of-use');
  }

  captchaResolved(capthcaResult) {
    this.captcha = capthcaResult;
  }

}
