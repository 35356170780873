/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Invitation, InvitationConfirmRequest, InvitationRequest } from '../model/invitation';
import { Page, PageableSort } from '../model/pagination';
import { InvitationType } from '../model/enum/invitation-type';
import { createPageParams } from '../util';

@Injectable({
    providedIn: 'root'
})
export class InvitationService {

    private baseUrl = '/ums/v1/invitations';
    private baseAdminUrl = '/ums/v1/admin-ops/invitations';

    constructor(private http: HttpClient) {
    }

    sendInvitationToUnregistered(unregisteredInviteeEmail: string, request: InvitationRequest): Observable<Invitation> {
        return this.http.post<Invitation>(environment.url + `${this.baseUrl}/unregistered/${unregisteredInviteeEmail}`, request)
        .pipe(map((res: any) => res || {}));
    }

    sendInvitationToRegistered(inviteeId: number, request: InvitationRequest): Observable<Invitation> {
        return this.http.post<Invitation>(environment.url + `${this.baseUrl}/${inviteeId}`, request)
        .pipe(map((res: any) => res || {}));
    }

    confirmInvitation(invitationId: number, request: InvitationConfirmRequest): Observable<Invitation> {
        return this.http.patch<Invitation>(environment.url + `${this.baseUrl}/${invitationId}`, request)
        .pipe(map((res: any) => res || {}));
    }

    getInvitation(id: number): Observable<Invitation> {
        return this.http.get<Invitation>(environment.url + `${this.baseUrl}/${id}`)
            .pipe(map((res: any) => res || {}));
    }

    getInvitorInvitations(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<Invitation>> {
        const pageParams = createPageParams(page, pageSize, sort, searchTerm);
        return this.http.get<Page<Invitation>>(environment.url + `${this.baseUrl}/as-invitor`, { params: pageParams })
            .pipe(map((res: any) => res || {}));
    }

    getInviteeInvitations(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<Invitation>> {
        const pageParams = createPageParams(page, pageSize, sort, searchTerm);
        return this.http.get<Page<Invitation>>(environment.url + `${this.baseUrl}/as-invitee`, { params: pageParams })
            .pipe(map((res: any) => res || {}));
    }

    getWaitingInvitations(): Observable<Page<Invitation>> {
        return this.http.get<Page<Invitation>>(environment.url + `${this.baseUrl}/waiting`)
            .pipe(map((res: any) => res || {}));
    }

    getWaitingInvitationsByType(type: InvitationType): Observable<Page<Invitation>> {
        const typeStr = InvitationType[type];
        return this.http.get<Page<Invitation>>(environment.url + `${this.baseUrl}/${typeStr}/waiting`)
            .pipe(map((res: any) => res || {}));
    }

    getWaitingInvitationsCount(): Observable<number> {
        return this.http.get<number>(environment.url + `${this.baseUrl}/waiting/count`)
            .pipe(map((res: any) => res || -1));
    }

    getWaitingInvitationsCountByType(type: InvitationType): Observable<number> {
        const typeStr = InvitationType[type];
        return this.http.get<number>(environment.url + `${this.baseUrl}/${typeStr}/waiting/count`)
            .pipe(map((res: any) => res || -1));
    }

    listAllInvitationsByAdmin(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<Invitation>> {
        const pageParams = createPageParams(page, pageSize, sort, searchTerm);
        return this.http.get<Page<Invitation>>(environment.url + `${this.baseAdminUrl}`, { params: pageParams })
            .pipe(map((res: any) => res || {}));
    }

}
