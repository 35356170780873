import {Component,ViewChild, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Suppression} from '../../../../shared/model/suppression';
import {SuppressionService} from '../../../../shared/services/suppression.service';
import {QuestionsService} from '../../../../shared/services/questions.service';
import {ClinicalOutcomeService} from '../../../../shared/services/clinical-outcome.service';
import {ClinicalOutcome, ClinicalOutcomeDashboardResponse} from '../../../../shared/model/clinical-outcome';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../../shared/module/notification/service/notification.service';
import {DrugInteractionService} from '../../../../shared/services/drug-interaction.service';
import {
  DrugInteractionChartOptions, DrugInteractionChartOptionsXAxis,
  DrugInteractionNewResponse,
  DrugInteractionRequest,
  DrugInteractionResponse,
  SuppressionData
} from '../../../../shared/model/drug-interaction';

import {
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexFill,
  ChartComponent,
  ApexStroke,
  ApexMarkers
} from 'ng-apexcharts';
import {ApexChartOptions} from '../../../../shared/model/chart-options';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  title: ApexTitleSubtitle;
  stroke: ApexStroke;
  fill: ApexFill;
  markers: ApexMarkers;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
};

@Component({
  selector: 'app-drug-interaction',
  templateUrl: './drug-interaction.component.html',
  styleUrls: ['./drug-interaction.component.scss']
})

export class DrugInteractionComponent implements OnInit {

  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;


  clinicalOutcome: ClinicalOutcome;
  drugInteractionRequest: DrugInteractionRequest;
  drugInteractionResponse: DrugInteractionResponse[];
  drugInteractionNewResponse: DrugInteractionNewResponse[];
  suppressionList: Suppression[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private suppressionService: SuppressionService,
    private questionsService: QuestionsService,
    private clinicalOutcomeService: ClinicalOutcomeService,
    private drugInteractionService: DrugInteractionService
  ) {
    this.chartOptions = {
      series: [],
      chart: {
        height: 450,
        type: 'radar',
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1
        }
      },
      stroke: {
        width: 0
      },
      fill: {
        opacity: 0.4
      },
      markers: {
        size: 0
      },
      xaxis: {
        tickPlacement: 'between',
        labels: {
          show:true,
        }
      },
      yaxis: {
        tickAmount: 4,
        min:0,
        max:20,
        opposite:true,
        labels: {
          show:true,
          formatter: (value) => {
            if (value === 5) {
              return 'NONE';
            } else if (value === 10){
              return 'LIKELY';
            } else if (value === 15){
              return 'HIGHLY LIKELY';
            } else if (value === 20){
              return 'DEFINITE';
            }else {
              return '';
            }
          }
        }

      }
    };
  }

  ngOnInit(): void {
    this.clinicalOutcomeService.getLatestClicalOutcomeResult().subscribe(result => {
      this.suppressionList = result.suppressionList;
      this.clinicalOutcome = result.clinicalOutcome;
      this.drugInteractionRequest = this.initializeDrugInteractionRequest(result);
      this.drugInteractionService.getNewDrugInteractionResults(this.drugInteractionRequest).subscribe(response => {
        this.drugInteractionNewResponse = response;
        this.chartOptions.series = this.getDrugInteractionChartOptions(response);
        this.chartOptions.xaxis = this.getDrugInteractionChartXAxis(response);
      }, error => {
        if (error.status !== 404) {
          console.log('Error on getting drug interaction result: ' + JSON.stringify(error));
          this.notificationService.error(this.translate.instant('CLINICAL_OUTCOME.NOTIFICATION.GET_FAILED'));
        }
      });
    }, error => {
      if (error.status !== 404) {
        console.log('Error on create clinical outcome create: ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('CLINICAL_OUTCOME.NOTIFICATION.GET_FAILED'));
      }
    });
  }

  goToFormPage() {
    this.router.navigateByUrl('smarthiv-clinician/clinician-form');
  }

  isValueExistInList(propertyName: string) {
    for (const sup of this.suppressionList) {
      if (sup[propertyName]) {
        return true;
      }
    }
    return false;
  }

  initializeDrugInteractionRequest(result: ClinicalOutcomeDashboardResponse): DrugInteractionRequest {
    const request: DrugInteractionRequest = {
      medicines: this.clinicalOutcome.medication,
      suppressionData: this.getSuppressionData(result)
    };
    return request;
  }

  getSuppressionData(result: ClinicalOutcomeDashboardResponse): SuppressionData[] {
    const suppressionData: SuppressionData[] = [];
    for (const r in result.suppressionList) {
      if (result.suppressionList.hasOwnProperty(r)) {
        const newSuppressionData = {
          art: result.suppressionList[r].artCodes,
          name: result.suppressionList[r].artCodeNames,
          artAgents: result.suppressionList[r].artCodeCombination.split(','),
          artAgentNames: result.suppressionList[r].artCodeNamesTokens.split(',')
        };
        suppressionData.push(newSuppressionData);
      }
    }
    return suppressionData;
  }


  getDrugInteractionChartOptions(result: DrugInteractionNewResponse[]): DrugInteractionChartOptions[] {
    const drugInteractionChartOptions: DrugInteractionChartOptions[] = [];
    for (const r in result) {
      if (result.hasOwnProperty(r)) {
        const newDrugInteractionChartOptions = {
          name: result[r].art,
          data: result[r].medicinesResultInNumber,
        };
        drugInteractionChartOptions.push(newDrugInteractionChartOptions);
      }
    }
    return drugInteractionChartOptions;
  }
  getDrugInteractionChartXAxis(result: DrugInteractionNewResponse[]): DrugInteractionChartOptionsXAxis {
    const newDrugInteractionChartOptionsXAxis = {
      categories: result[0].medicines,
    };
    return newDrugInteractionChartOptionsXAxis;
  }

}
