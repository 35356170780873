export class UserAccount {
    email: string;
    phoneNumber: string;

    firstName: string;
    lastName: string;
    gender: string;
    photoUrl: string;
    active: boolean;
    enabled: boolean;

    roles: number;
    hospitalId: string;
    postalCode: string;

    birthday: string;

    permissionLevel: string;

    organisationOwner: boolean;

    clinicianOrganisationId: number;
    clinicianOrganisationName: string;
  
    clinicianOrganisationBranchId: number;
    clinicianOrganisationBranchName: string;
  
    patientOrganisationId: number;
    patientOrganisationName: string;
    
    patientOrganisationBranchId: number;
    patientOrganisationBranchName: string;
}

export class UpdateAccountRequest {
    email: string;
    phoneNumber: string;

    firstName: string;
    lastName: string;
    gender: string;
    hospitalId: string;
    postalCode: string;
    birthday: Date;
}

export class UserConsent {
    newsletterSubscribed: boolean;
    notificationsSubscribed: boolean;
    medicalDataAllowed: boolean;
    accessYourDataAllowed: boolean;
    clinicalTrialsAllowed: boolean;
}

export class UserProfilePhotoUpdateRequest {
    base64Photo: string;
}
export class UserSummary {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    photoUrl: string;
    roles: number;
    userUniqueId: string;
    hospitalId: string;

    organisationOwner: boolean;
    clinicianOrganisationId: number;
    clinicianOrganisationBranchId: number;
    clinicianOrganisationBranchName: string;

    patientOrganisationId: number;
    patientOrganisationBranchId: number;
    patientOrganisationBranchName: string;
}

export class UserSummaryAdmin {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    photoUrl: string;
    roles: number;

    lastLoginAt: string;
    emailConfirmed: boolean;
    phoneNumberConfirmed: boolean;
    otpEnabled: boolean;
    enabled: boolean;
    active: boolean;

    organisationOwner: boolean;
    clinicianOrganisationId: number;
    clinicianOrganisationName: string;

    clinicianOrganisationBranchId: number;
    clinicianOrganisationBranchName: string;

    patientOrganisationId: number;
    patientOrganisationName: string;

    patientOrganisationBranchId: number;
    patientOrganisationBranchName: string;
}

export class UserFilterRequest {
    searchTerm: string;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    roles: string[];

    clinicianOrganisationId: number;
    clinicianOrganisationBranchId: number;
    clinicianOrganisationBranchName: string;

    patientOrganisationId: number;
    patientOrganisationBranchId: number;
}

