/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/shared/component/base.component';
import { Interval } from 'src/app/shared/model/enum/interval';
import { PageMode } from 'src/app/shared/model/enum/page-mode';
import { Organisation } from 'src/app/shared/model/organisation';
import { LoginResponse } from 'src/app/shared/model/user-profile';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { OrganisationService } from 'src/app/shared/services/organisation.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';
import { hasUserAnyClinicianRelatedRole } from 'src/app/shared/util';

@Component({
  selector: 'app-organisation-detail',
  templateUrl: './organisation-detail.component.html',
  styleUrls: ['./organisation-detail.component.scss']
})
export class OrganisationDetailComponent extends BaseComponent implements OnInit {
  PageMode: typeof PageMode = PageMode;
  Interval: typeof Interval = Interval;

  organisation: Organisation;
  pageMode: PageMode;
  managedOrganisationId: number;

  loginInfo: LoginResponse;
  isUserClinician = false;
  intervalOptions: string[];

  formGroupOrganisation: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(2)]),
    surveyReminderIntervalCount: new FormControl('', [Validators.required, Validators.min(1)]),
    surveyReminderInterval: new FormControl('', [Validators.required])
  });


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private authenticationStateService: AuthenticationStateService,
    private organisationService: OrganisationService
  ) {
    super();
  }

  ngOnInit(): void {

    const iOptions: string[] = Object.keys(Interval);
    this.intervalOptions = iOptions.slice(iOptions.length / 2);

    const authSubscription = this.authenticationStateService.getLoginChangeObservable().subscribe(info => {
      this.setLoginInfo(info);
    });
    this.subscription(authSubscription);
  }

  setLoginInfo(loginInfo: LoginResponse) {
    this.loginInfo = loginInfo;

    this.isUserClinician = hasUserAnyClinicianRelatedRole(loginInfo.roles);


    this.pageMode = PageMode.CREATE;
    let organisationId = null;

    if (this.loginInfo.patientOrganisationId) {
      this.pageMode = PageMode.VIEW;
      organisationId = this.loginInfo.patientOrganisationId;
    }

    if (this.loginInfo.clinicianOrganisationId) {
      this.pageMode = PageMode.VIEW;
      organisationId = this.loginInfo.clinicianOrganisationId;
    }

    if (this.loginInfo.clinicianOrganisationId && this.loginInfo.organisationOwner === true) {
      this.pageMode = PageMode.EDIT;
      organisationId = this.loginInfo.clinicianOrganisationId;
    }

    if (this.pageMode === PageMode.CREATE) {
      this.organisation = new Organisation();
      this.organisation.surveyReminderIntervalCount = 3;
      this.organisation.surveyReminderInterval = Interval[Interval.DAY];
      this.patchForms(this.organisation);
    }

    if (this.pageMode === PageMode.VIEW) {
      this.formGroupOrganisation.disable();
    }

    if (organisationId) {
      this.organisationService.getOrganisation(organisationId).subscribe(organisation => {
        this.patchForms(organisation);
      }, error => {
        console.log('Error on get organisation : ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('ORGANISATION.NOTIFICATION.ORGANISATION_FAILED'));
      });
    }
  }

  onCreateOrganisation() {
    if (this.formGroupOrganisation.valid) {
      const rawVal = this.formGroupOrganisation.getRawValue();
      const item = { ...rawVal};
      delete item.id;

      this.organisationService.createOrganisation(item).subscribe(organisation => {
        this.patchForms(organisation);
        this.pageMode = PageMode.EDIT;
        this.notificationService.success(this.translate.instant('ORGANISATION.NOTIFICATION.ORGANISATION_CREATED'));
        this.authenticationStateService.notifyOrganisationInfoChanges(this.organisation.id, this.organisation.name, null, null);
      }, error => {
        console.log('Error on create organisation : ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('ORGANISATION.NOTIFICATION.ORGANISATION_SAVE_FAILED'));
      });
    }
  }

  onEditOrganisation() {
    if (this.formGroupOrganisation.valid) {
      const rawVal = this.formGroupOrganisation.getRawValue();
      const item = { ...rawVal};

      this.organisationService.updateOrganisation(this.organisation.id, item).subscribe(organisation => {
        this.notificationService.success(this.translate.instant('ORGANISATION.NOTIFICATION.ORGANISATION_UPDATED'));
        this.patchForms(organisation);
      }, error => {
        console.log('Error on edit organisation : ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('ORGANISATION.NOTIFICATION.ORGANISATION_UPDATE_FAILED'));
      });
    }
  }

  patchForms(organisation: Organisation) {
    this.formGroupOrganisation.patchValue(organisation);
    this.organisation = organisation;
  }

}
