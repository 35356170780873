import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/shared/component/base.component';
import { Plan } from 'src/app/shared/model/subscription';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { OrganisationalSubscriptionService } from 'src/app/shared/services/organisational-subscription.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';
import {PlanService} from '../../../../shared/services/plan.service';

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss']
})
export class SubscriptionPlanComponent extends BaseComponent implements OnInit, OnDestroy {

  activeOrganisationalPlans: Plan[];
  activeIndividualPlans: Plan[];

  clinicianOrganisationId;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private authenticationStateService: AuthenticationStateService,
    private planService: PlanService,
    private subscriptionService: SubscriptionService,
    private organisationalSubscriptionService: OrganisationalSubscriptionService) {
      super();
    }

  ngOnInit(): void {
    const authSubscription = this.authenticationStateService.getLoginChangeObservable().subscribe(info => {
        this.clinicianOrganisationId = info.clinicianOrganisationId;
        this.loadPlanList();
    });
    this.subscription(authSubscription);
  }

  loadPlanList() {
    this.planService.getActivePlans().subscribe(data => {
      if (data && data.planList) {
        this.activeIndividualPlans = data.planList.filter(p => p.organisationalPlan === false);
        this.activeOrganisationalPlans = data.planList.filter(p => p.organisationalPlan === true);
      }
    }, (error) => {
      console.log('Error on loading plans ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('SUBSCRIPTION.NOTIFICATION.GET_ACTIVE_PLANS_FAILED'));
    });
  }

  maxClinicianCountText(plan) {
    const unlimitedTxt = this.translate.instant('GENERAL.UNLIMITED');
    const clinicianTxt = this.translate.instant('GENERAL.CLINICIAN');
    const defaultVal = plan.organisationalPlan ? unlimitedTxt : 1;
    return (plan.maxClinicianCount ? plan.maxClinicianCount : defaultVal) + ' ' + clinicianTxt;
  }

  maxPatientCountText(plan) {
    const unlimitedTxt = this.translate.instant('GENERAL.UNLIMITED');
    const patientTxt = this.translate.instant('GENERAL.PATIENT');
    const defaultVal = plan.organisationalPlan ? unlimitedTxt : 0;
    return (plan.maxPatientCount ? plan.maxPatientCount : defaultVal) + ' ' + patientTxt;
  }

  additionalUserAmountText(plan) {
    const currencyTxt = this.translate.instant('ENUM.CURRENCY.' + plan.currency);
    const noText = this.translate.instant('GENERAL.NO');
    const additionalTxt = this.translate.instant('SUBSCRIPTION.PLAN.DETAIL.FORM.ADDITIONAL_USER_AMOUNT');

    const str = (plan.additionalUserAmount === 0 ? noText : (plan.additionalUserAmount + ' ' + currencyTxt)) + ' ' + additionalTxt;
    return str;
  }

  renewableText(plan) {
    const renewableTxt = this.translate.instant('SUBSCRIPTION.PLAN.DETAIL.GENERAL.RENEWABLE');
    const notRenewableTxt = this.translate.instant('SUBSCRIPTION.PLAN.DETAIL.GENERAL.NOT_RENEWABLE');
    return plan.renewable ? renewableTxt : notRenewableTxt;
  }

  subscribeUser(planId: number) {
    this.subscriptionService.subscribeUser(planId).subscribe(response => {
      this.notificationService.info(this.translate.instant('SUBSCRIPTION.NOTIFICATION.USER_SUBSCRIPTION.SUCCESS'));
    }, error => {
      console.log('Error on subscribe user : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('SUBSCRIPTION.NOTIFICATION.USER_SUBSCRIPTION.FAIL'));
    });
  }

  subscribeOrganisation(planId: number) {
    this.organisationalSubscriptionService.subscribeOrganisation(planId).subscribe(response => {
      this.notificationService.info(this.translate.instant('SUBSCRIPTION.NOTIFICATION.ORGANISATION_SUBSCRIPTION.SUCCESS'));
    }, error => {
      console.log('Error on subscribe user : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('SUBSCRIPTION.NOTIFICATION.ORGANISATION_SUBSCRIPTION.FAIL'));
    });
  }

}
