import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './pages/login/login.component';
import { UserRoutingModule } from './user-routing.module';
import { RegisterComponent } from './pages/register/register.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CountdownModule } from 'ngx-countdown';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { OtpConfirmComponent } from './pages/otp-confirm/otp-confirm.component';
import { OtpGenerateComponent } from './pages/otp-generate/otp-generate.component';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    OtpConfirmComponent,
    OtpGenerateComponent
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    SharedModule,
    CountdownModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ]
})
export class UserModule {
  constructor() {}
}
