<div *ngIf="suppressionList && chartOptions.series.length > 0">
    <!-- <mat-card>
        <mat-card-content> -->
            <h2 class="content-box__title">{{title}}</h2>
            <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [dataLabels]="chartOptions.dataLabels"
            [plotOptions]="chartOptions.plotOptions"
            [yaxis]="chartOptions.yaxis"
            [xaxis]="chartOptions.xaxis"
            [legend]="chartOptions.legend"
            [colors]="chartOptions.colors"
            [grid]="chartOptions.grid"
            ></apx-chart>
        <!-- </mat-card-content>
    </mat-card> -->
</div>
