<div class="content-box chart d-flex flex-column h-100p pb-0">
    <h3 class="content-box__title">{{'USER_ADMIN.LIST.TITLE' | translate}}</h3>

    <div fxLayout="row wrap" class="align-items-center flex-shrink-0">
        <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100">
            <app-search-field [label]="'USER_ADMIN.LIST.SEARCH_FIELD.LABEL'" 
            [hint]="'USER_ADMIN.LIST.SEARCH_FIELD.HINT'" 
            (searchTermChanged)="onSearchTermChanged($event)">
            </app-search-field>
        </div>
        <!--div fxFlex.gt-md="75" class="text-right">
            <button mat-raised-button color="primary">Add </button>
        </div-->
    </div>

    <div class="flex-grow-1 overflow-auto">
        <app-datatable
            [styleClass]="'cust-no-border-datatable'"
            [allColumnList]="allColumnList"
            [columns]="columns"
            [rows]="rows"
            [page]="page"
            (pageChanged)="onPageChanged($event)"
            (sortChanged)="onSortChanged($event)">
        </app-datatable>
    </div>
</div>

<ng-template #rowNumberTemplate let-row="row" let-value="value" let-rowIndex="rowIndex">
    <span class="ng-star-inserted">{{(page.pageNumber * page.size) + rowIndex + 1}}</span>
</ng-template>

<ng-template #nameInfoTemplate let-row="row" let-value="value">
      <div class="d-flex">
        <img class="mat-card-avatar" [src]='getProfilePhotoUrl(row)'>
        <div class="m-l-15">
          <p class="font-bold m-b-0 m-t-0">{{ getNameStr(row) }}</p>
          <small class="text-muted" matTooltip="{{getRolesAsString(row.roles)}}">{{getRolesAsString(row.roles)}}</small>
        </div>
      </div>
</ng-template>

<ng-template #activeTemplate let-row="row" let-value="value">
    <mat-checkbox [checked]="row.active" [disabled]="'true'"></mat-checkbox>
</ng-template>

<ng-template #clinicianOrganistionTemplate let-row="row" let-value="value">
    {{getStr(row.clinicianOrganisationName) + ' / ' + getStr(row.clinicianOrganisationBranchName)}}
</ng-template>

<ng-template #patientOrganistionTemplate let-row="row" let-value="value">
    {{getStr(row.patientOrganisationName) + ' / ' + getStr(row.patientOrganisationBranchName)}}
</ng-template>

<ng-template #actionTemplate let-row="row" let-value="value">
    <div class="d-flex">
        <a (click)="openDetailDialog(PageMode.VIEW, row)" class="m-r-10 cursor-pointer">
            <i class="fa fa-eye fa-lg text-info"></i>
        </a>
        <a (click)="openDetailDialog(PageMode.EDIT, row)" class="m-r-10 cursor-pointer">
            <i class="fa fa-pencil fa-lg text-warning"></i>
        </a>
        <a (click)="openDetailDialog(PageMode.DELETE, row)" class="m-r-10 cursor-pointer">
            <i class="fa fa-trash fa-lg text-danger"></i>
        </a>
        <a [matMenuTriggerFor]="overflowRowMenu" [matMenuTriggerData]="{rowUserSummary: row}" class="m-r-10 cursor-pointer">
            <i class="fa fa-ellipsis-v fa-lg"></i>
        </a>
    </div>

</ng-template>


<!-- ============================================================== -->
<!-- Row overflow menu-->
<!-- ============================================================== -->
<mat-menu #overflowRowMenu="matMenu">
    <ng-template matMenuContent let-selectedRow="rowUserSummary" >
        <button mat-menu-item *ngIf="hasUserPatientRole(selectedRow)" (click)="openConnectToClinicianDialog(selectedRow)">Connect To Clinician</button>
        <button mat-menu-item *ngIf="hasUserClinicianRole(selectedRow)" (click)="openConnectToPatientDialog(selectedRow)">Connect To Patient</button>
        <button mat-menu-item *ngIf="hasUserPatientRole(selectedRow)" (click)="openConnectPatientToOrganisationBranchDialog(selectedRow)">Connect Patient To Organisation Branch</button>
        <button mat-menu-item *ngIf="hasUserClinicianRole(selectedRow)" (click)="openConnectClinicianToOrganisationBranchDialog(selectedRow)">Connect Clinician To Organisation Branch</button>
    </ng-template>
    
</mat-menu>