<div class="content-box p-0" style="height: calc(100vh - 11.5rem);">
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start none" fxFlex="100">
            <div class="cust-sidebar-navigation" fxShow="false" fxShow.gt-sm>
              <ng-container  [ngTemplateOutlet]="menuContent"></ng-container>
            </div>
            <div fxHide="false" fxHide.gt-sm>
              <ng-container  [ngTemplateOutlet]="menuContent"></ng-container>
            </div>

            <div fxFlex>
                <router-outlet></router-outlet>
            </div>
        </div>
</div>


<ng-template #menuContent>
  <mat-nav-list class="menu-list p-0">
    <mat-list-item [routerLink]="['update-account']" routerLinkActive="active-list-item" class="m-b-20">
      <div class="list-item">
        <fa-icon class="list-item-img" [icon]="faInfoCircle" size="lg" ></fa-icon>
        <a matLine class="list-item-link"> {{ 'USER_PROFILE.MENU.UPDATE_ACCOUNT' | translate }}</a>
      </div>
    </mat-list-item>
    <mat-list-item [routerLink]="['incoming-invitation']" routerLinkActive="active-list-item" class="m-b-20">
      <div class="list-item">
        <fa-icon  class="list-item-img" [icon]="faEnvelope" size="lg"></fa-icon>
        <a matLine class="list-item-link">{{ 'USER_PROFILE.MENU.INCOMING_INVITATION' | translate }}</a>
      </div>
    </mat-list-item>
    <mat-list-item [routerLink]="['outgoing-invitation']" routerLinkActive="active-list-item" class="m-b-20">
      <div class="list-item">
        <fa-icon  class="list-item-img" [icon]="faPaperPlane" size="lg"></fa-icon>
        <a matLine class="list-item-link">{{ 'USER_PROFILE.MENU.OUTGOING_INVITATION' | translate }}</a>
      </div>
    </mat-list-item>

     <mat-list-item [routerLink]="['individual-clinician-list']" routerLinkActive="active-list-item" class="m-b-20" *ngxPermissionsOnly="['SYSTEM_ADMINISTRATOR','PATIENT']">
      <div class="list-item">
        <fa-icon  class="list-item-img" [icon]="faUserDoctor" size="lg"></fa-icon>
        <a matLine class="list-item-link">{{ 'USER_PROFILE.MENU.INDIVIDUAL_CLINICIAN_LIST' | translate }}</a>
      </div>
    </mat-list-item>
     <mat-list-item [routerLink]="['individual-patient-list']" routerLinkActive="active-list-item" class="m-b-20" *ngxPermissionsOnly="['SYSTEM_ADMINISTRATOR','CLINICIAN','STANDALONE_CLINICIAN','SMART_HIV_MANAGER','SMART_HIV_TRIALIST','SMART_HIV_AUDITLYTICS', 'SMART_HIV_CLINICIAN']">
      <div class="list-item">
        <fa-icon  class="list-item-img" [icon]="faUsers" size="lg"></fa-icon>
        <a matLine class="list-item-link">{{ 'USER_PROFILE.MENU.INDIVIDUAL_PATIENT_LIST' | translate }}</a>
      </div>
    </mat-list-item>

  </mat-nav-list>

</ng-template>
