<div>
  <h3 class="m-t-0">{{'SEND_INVITATION.TITLE' | translate}}</h3>
  <div class="m-b-20" *ngIf="data.subTitle">
    <small>{{data.subTitle}}</small>
  </div>
</div>

<form [formGroup]="formGroup" autocomplete="off" novalidate>
    <div fxLayout="row" fxLayoutWrap="wrap">
        <!-- column -->
      <div fxFlex.gt-sm="100" fxFlex="100">
        <app-email-or-phone-number></app-email-or-phone-number>
      </div>

    </div>
    <div fxLayout="row" fxLayoutWrap="wrap">
      <!-- column -->
      <div fxFlex.gt-sm="100" fxFlex="100">
        <mat-form-field>
          <mat-label>{{'SEND_INVITATION.LABEL.INVITATION_TYPE' | translate}}</mat-label>
          <mat-select placeholder="{{'SEND_INVITATION.LABEL.INVITATION_TYPE' | translate}}" formControlName="invitationType">
            <mat-option *ngFor="let it of invitationTypeOptions" [value]="it">
              {{ baseInvitationTypeTranslationKey + '.' + it  | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-validation-message [control]="formGroup.get('invitationType')"></app-validation-message>
      </div>
    </div>
    <div fxLayout="row" fxLayoutWrap="wrap">
      <!-- column -->     
      <div fxFlex.gt-sm="100" fxFlex="100" *ngIf="data.organisationId">

        <app-searchable-dropdown 
        [items]="organisationBranchList" 
        [label]="'SEND_INVITATION.LABEL.ORGANISATION_BRANCH'"
        [itemDisplayProperty]="'name'"
        [itemValueProperty]="'id'"
        [control]="getFormControl(formGroup,'organisationBranchId')"
        >
        </app-searchable-dropdown>

        <app-validation-message [control]="formGroup.get('organisationBranchId')"></app-validation-message>
      </div>
    </div>
    <div fxLayout="row" fxLayoutWrap="wrap">
      <!-- column -->
      <div fxFlex.gt-sm="100" fxFlex="100" *ngIf="isPatientInvitation() && selectedOrganisationBranchId" class="m-l-5 cust-form-field-container">
        <mat-form-field class="cust-form-field">
            <mat-label>{{ 'SEND_INVITATION.LABEL.CLINICIAN_LIST' | translate }}</mat-label>
            <input matInput formControlName="organisationClinicianList">
            <button *ngIf = "pageMode === PageMode.CREATE" matSuffix mat-icon-button (click)="openClinicianSelectionDialog()">
                <mat-icon>person_search</mat-icon>
            </button>
        </mat-form-field>
        <app-validation-message [control]="formGroup.get('organisationClinicianList')"></app-validation-message>
      </div>
    </div>
    
    <div fxLayout="row" fxLayoutWrap="wrap">
      <!-- column -->
      <div fxFlex.gt-sm="100" fxFlex="100">
        <mat-form-field appearance="fill">
          <textarea matInput rows="5" maxlength="150" formControlName="invitorNote"></textarea>
        </mat-form-field>
        <app-validation-message [control]="formGroup.get('invitorNote')"></app-validation-message>

      </div>
    </div>

</form>

<div fxLayout="row" fxLayoutWrap="wrap">
  <!-- column -->
  <div fxFlex.gt-sm="100" fxFlex="100" class="mini-spacer m-t-5">
      <button mat-raised-button color="primary" (click)="sendInvitation()" [disabled]="!this.formGroup.valid">{{ 'GENERAL.SEND' | translate }}</button>
  </div>
</div>
