<div *ngIf="message">
    <ng-container *ngIf="message.fromId === chatRoomUser.id" [ngTemplateOutlet]="incoming"></ng-container>
    <ng-container *ngIf="message.fromId !== chatRoomUser.id" [ngTemplateOutlet]="outgoing"></ng-container>
</div>

<ng-template #incoming>
    <div class="chat-list odd">
        <div class="chat-message">
            <div class="chat-block">
                <span>
                    <img src="{{getOrDefaultPhoto(chatRoomUser?.photoUrl)}}" class="img-circle" width="40">
                </span>
                <span>{{message.content}}</span>
            </div>
            <span class="chat-date" matTooltip="{{ message.createdAt | date: 'MMMM d, y, h:mm:ss a' }}" >{{ message.createdAt | date: 'MMMM d, h:mm a' }}</span>
        </div>
    </div>
</ng-template>

<ng-template #outgoing>
    <div class="chat-list even">
        <div class="chat-message">
            <div class="chat-block bg-light-info">{{message.content}}</div>
            <span class="chat-date" matTooltip="{{ message.createdAt | date: 'MMMM d, y, h:mm:ss a' }}" >{{ message.createdAt | date: 'MMMM d, h:mm a'}}</span>
        </div>
    </div>
</ng-template>