import { Subscription } from 'rxjs';

export class FileUploadModel {
    file: File;
    state: string;
    progressPercentage: number;
    loadedSize: number;
    totalSize: number;
    inProgress: boolean;
    isCompleted: boolean;
    canRetry: boolean;
    canCancel: boolean;
    sub?: Subscription;
    errorMessage: string;
}
