/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Page, PageableSort } from '../model/pagination';
import { ClinicianPatient } from '../model/clinician-patient';
import { createPageParams } from '../util';

@Injectable({
    providedIn: 'root'
})
export class ClinicianPatientService {

    private baseUrl = '/ums/v1/clinician-patient';
    private baseAdminUrl = '/ums/v1/admin-ops/clinician-patient';

    constructor(private http: HttpClient) {
    }

    connectClinicianPatientByOrganisationClinician(patientId: number): Observable<ClinicianPatient> {
        return this.http.post<ClinicianPatient>(environment.url + `${this.baseUrl}/patient/${patientId}/connect-to-me-by-org-clinician`, {})
            .pipe(map((res: any) => res || {}));
    }

    disconnectFromMyPatient(patientId: number): Observable<ClinicianPatient> {
        return this.http.patch<ClinicianPatient>(environment.url + `${this.baseUrl}/patient/${patientId}/disconnect-from-my-patient`, {})
            .pipe(map((res: any) => res || {}));
    }

    getCliniciansOfPatient(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<ClinicianPatient>> {
        const pageParams = createPageParams(page, pageSize, sort, searchTerm);
        return this.http.get<Page<ClinicianPatient>>(environment.url + `${this.baseUrl}/clinicians-of-patient`, { params: pageParams })
            .pipe(map((res: any) => res || {}));
    }

    getPatientsOfClinician(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<ClinicianPatient>> {
        const pageParams = createPageParams(page, pageSize, sort, searchTerm);
        return this.http.get<Page<ClinicianPatient>>(environment.url + `${this.baseUrl}/patients-of-clinician`, { params: pageParams })
            .pipe(map((res: any) => res || {}));
    }

    listAllClinicianPatientByAdmin(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<ClinicianPatient>> {
        const pageParams = createPageParams(page, pageSize, sort, searchTerm);
        return this.http.get<Page<ClinicianPatient>>(environment.url + `${this.baseAdminUrl}`, { params: pageParams })
            .pipe(map((res: any) => res || {}));
    }

}
