import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { AtRiskResultsService } from '../../../../shared/services/at-risk-results.service';

@Component({
  selector: 'app-at-risk-list',
  templateUrl: './at-risk-list.component.html',
  styleUrls: ['./at-risk-list.component.scss']
})
export class AtRiskListComponent extends BaseListComponent implements OnInit {

  @ViewChild('idTemplate', {static: true}) idTemplate: TemplateRef<any>;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private atRiskResultsService: AtRiskResultsService
  ) {
    super();
   }

  ngOnInit(): void {
    this.allColumnList = [
      {
        prop: 'userId',
        name: this.translate.instant('AT_RISK_LIST.COL_LBL.USER_ID'),
        cellTemplate: this.idTemplate,
        width: 100,
        maxWidth: 100
      },
      {prop: 'ques0001', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0001'), cellClass: ''},
      {prop: 'ques0002', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0002'), cellClass: ''},
      {prop: 'ques0003', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0003'), cellClass: ''},
      {prop: 'ques0004', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0004'), cellClass: ''},
      {prop: 'ques0004a', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0004_A'), cellClass: ''},
      {prop: 'ques0004b', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0004_B'), cellClass: ''},
      {prop: 'ques0005', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0005'), cellClass: ''},
      {prop: 'ques0005a', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0005_A'), cellClass: ''},
      {prop: 'ques0006', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0006'), cellClass: ''},
      {prop: 'ques0006a', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0006_A'), cellClass: ''},
      {prop: 'ques0006b', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0006_B'), cellClass: ''},
      {prop: 'ques0006c', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0006_C'), cellClass: ''},
      {prop: 'ques0007', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0007'), cellClass: ''},
      {prop: 'ques0007a', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0007_A'), cellClass: ''},
      {prop: 'ques0007b', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0007_B'), cellClass: ''},
      {prop: 'ques0007c', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0007_C'), cellClass: ''},
      {prop: 'ques0007d', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0007_D'), cellClass: ''},
      {prop: 'ques0007e', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0007_E'), cellClass: ''},
      {prop: 'ques0007f', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0007_F'), cellClass: ''},
      {prop: 'ques0007g', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0007_G'), cellClass: ''},
      {prop: 'ques0007h', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0007_H'), cellClass: ''},
      {prop: 'ques0007i', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0007_I'), cellClass: ''},
      {prop: 'ques0008', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0008'), cellClass: ''},
      {prop: 'ques0009', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0009'), cellClass: ''},
      {prop: 'ques0010', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0010'), cellClass: ''},
      {prop: 'ques0011', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0011'), cellClass: ''},
      {prop: 'ques0011a', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0011_A'), cellClass: ''},
      {prop: 'ques0011b', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0011_B'), cellClass: ''},
      {prop: 'ques0011c', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0011_C'), cellClass: ''},
      {prop: 'ques0011d', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0011_D'), cellClass: ''},
      {prop: 'ques0011e', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0011_E'), cellClass: ''},
      {prop: 'ques0012', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0012'), cellClass: ''},
      {prop: 'ques0012a', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0012_A'), cellClass: ''},
      {prop: 'ques0012b', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0012_B'), cellClass: ''},
      {prop: 'ques0013', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0013'), cellClass: ''},
      {prop: 'ques0013a', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_0013_A'), cellClass: ''},
      {prop: 'ques9999', name: this.translate.instant('AT_RISK_LIST.COL_LBL.QUES_9999'), cellClass: ''},
      {prop: 'isComplete', name: this.translate.instant('AT_RISK_LIST.COL_LBL.IS_COMPLETE'), cellClass: ''},
      {prop: 'dateCreated', name: this.translate.instant('AT_RISK_LIST.COL_LBL.DATE_CREATED'), cellClass: ''},
      {prop: 'lastUpdated', name: this.translate.instant('AT_RISK_LIST.COL_LBL.LAST_UPDATED'), cellClass: ''}
    ];

    this.columns = [
      this.allColumnList[0],
      this.allColumnList[1],
      this.allColumnList[2],
      this.allColumnList[3],
      this.allColumnList[4],
      this.allColumnList[5],
      this.allColumnList[6],
      this.allColumnList[7],
      this.allColumnList[8],
      this.allColumnList[9]
    ];
   this.loadData()
  }


  loadData() {
    this.atRiskResultsService.getAllAtRiskResults(this.page.pageNumber, this.page.size, this.sort).subscribe(page => {
      this.page.pageNumber = page.number;
      this.page.totalElements = page.totalElements;
      this.page.size = page.size;
      this.rows = page.content;
      console.log(this.rows);
    });
  }

}
