<div *ngIf="suppressionList">
    <!-- <mat-card>
        <mat-card-content> -->
            <h2 class="content-box__title">{{'CLINICAL_OUTCOME.TITLE.EXPECTED_OVERALL_SUPPRESSION' | translate}}</h2>
            <div id="chartView">
                <apx-chart 
                    [series]="chartOptions.series"
                    [xaxis]="chartOptions.xaxis"
                    [yaxis]="chartOptions.yaxis" 
                    [chart]="chartOptions.chart"
                    [dataLabels]="chartOptions.dataLabels" 
                    [plotOptions]="chartOptions.plotOptions"
                    [responsive]="chartOptions.responsive"
                    [legend]="chartOptions.legend" 
                    [fill]="chartOptions.fill"
                    [stroke]="chartOptions.stroke"
                    [tooltip]="chartOptions.tooltip"
                    [grid]="chartOptions.grid">
                </apx-chart>
            </div>
        <!-- </mat-card-content>
    </mat-card> -->
</div>