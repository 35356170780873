/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PageMode } from 'src/app/shared/model/enum/page-mode';
import { City, Country, State } from 'src/app/shared/model/geo-location';
import { OrganisationBranch } from 'src/app/shared/model/organisation';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { GeoLocationService } from 'src/app/shared/services/geo-location.service';
import { OrganisationBranchService } from 'src/app/shared/services/organisation-branch.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';

@Component({
  selector: 'app-branch-organisation-detail',
  templateUrl: './organisation-branch-detail.component.html',
  styleUrls: ['./organisation-branch-detail.component.scss']
})
export class OrganisationBranchDetailComponent implements OnInit {
  PageMode: typeof PageMode = PageMode;

  organisationBranch: OrganisationBranch;

  pageMode: PageMode;
  organisationId: number;
  organisationBranchId: number;

  countryList: Country[];
  stateList: State[];
  cityList: City[];

  formGroupDetail: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(2)]),
    sector: new FormControl('', [Validators.required, Validators.minLength(2)]),
    phone: new FormControl('', [Validators.required, Validators.minLength(2)])
  });

  formGroupAddress: FormGroup = new FormGroup({
    country: new FormControl('', [Validators.required, Validators.minLength(2)]),
    state: new FormControl('', [Validators.required, Validators.minLength(2)]),
    city: new FormControl('', [Validators.required, Validators.minLength(2)]),
    postalCode: new FormControl('', [Validators.minLength(2)]),
    town: new FormControl('', [Validators.minLength(2)]),
    address: new FormControl('', [Validators.minLength(2)])
  });


  constructor(
    public dialogRef: MatDialogRef<OrganisationBranchDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {pageMode: PageMode; organisationId: number; organisationBranchId: number},
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private authenticationStateService: AuthenticationStateService,
    private organisationBranchService: OrganisationBranchService,
    private geoLocationService: GeoLocationService
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.pageMode = this.data.pageMode;
      this.organisationBranchId = this.data.organisationBranchId;
      this.organisationId = this.data.organisationId;
    }

    if (!this.pageMode) {
      this.pageMode = PageMode.CREATE;
    }

    if (this.pageMode === PageMode.CREATE) {
      this.organisationBranch = new OrganisationBranch();
      this.loadCountries();
    }

    if (this.pageMode === PageMode.VIEW || this.pageMode === PageMode.DELETE) {
      this.formGroupDetail.disable();
      this.formGroupAddress.disable();
    }

    if (this.organisationBranchId && this.organisationBranchId > 0) {
      this.organisationBranchService.getOrganisationBranch(this.organisationBranchId).subscribe(organisationBranch => {
        this.patchForms(organisationBranch);
        this.loadCountries();
      }, error => {
        console.log('Error on get organisationBranch : ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('ORGANISATION_BRANCH.NOTIFICATION.ORGANISATION_BRANCH_FAILED'));
      });
    }
  }

  loadCountries() {
    const tempCountry = this.formGroupAddress.get('country').value;

    this.geoLocationService.getCountries().subscribe(countryList => {
      this.countryList = countryList;
      if (tempCountry) {
        this.loadStates();
      }
    }, error => {
      console.log('Error on loading countries : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('GEO_LOCATION.NOTIFICATION.COUNTRY.LOAD_FAIL'));
    });
  }

  loadStates() {
    const tempCountry = this.formGroupAddress.get('country').value;
    const tempState = this.formGroupAddress.get('state').value;
    this.geoLocationService.getStatesOfCountry(tempCountry).subscribe(stateList => {
      this.stateList = stateList;
      const foundState = stateList.find(s => s.iso2 === tempState);
      if (!foundState) {
        this.formGroupAddress.get('state').setValue(null);
        this.formGroupAddress.get('city').setValue(null);
      } else {
        this.loadCities();
      }
    }, error => {
      console.log('Error on loading states : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('GEO_LOCATION.NOTIFICATION.STATE.LOAD_FAIL'));
    });
  }

  loadCities() {
    const tempCountry = this.formGroupAddress.get('country').value;
    const tempState = this.formGroupAddress.get('state').value;
    const tempCity = this.formGroupAddress.get('city').value;

    this.geoLocationService.getCitiesOfState(tempCountry, tempState).subscribe(cityList => {
      this.cityList = cityList;
      const foundCity = cityList.find(c => c.name === tempCity);
      if (!foundCity) {
        this.formGroupAddress.get('city').setValue(null);
      }
    }, error => {
      console.log('Error on loading cities : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('GEO_LOCATION.NOTIFICATION.CITY.LOAD_FAIL'));
    });
  }

  onCreateOrganisationBranch() {
    if (this.formGroupDetail.valid && this.formGroupAddress.valid) {
      const rawVal = this.formGroupDetail.getRawValue();
      let item = { ...rawVal};

      const rawValAddress = this.formGroupAddress.getRawValue();
      item = Object.assign(item, rawValAddress);
      delete item.id;

      this.organisationBranchService.createOrganisationBranch(this.organisationId, item).subscribe(organisationBranch => {
        this.patchForms(organisationBranch);
        this.pageMode = PageMode.EDIT;
        this.notificationService.success(this.translate.instant('ORGANISATION_BRANCH.NOTIFICATION.ORGANISATION_BRANCH_CREATED'));
        this.authenticationStateService.notifyOrganisationInfoChanges(organisationBranch.organisation.id, organisationBranch.organisation.name, organisationBranch.id, organisationBranch.name);
        this.dialogRef.close({status: 'ok'});
      }, error => {
        console.log('Error on create organisation branch: ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('ORGANISATION_BRANCH.NOTIFICATION.ORGANISATION_BRANCH_SAVE_FAILED'));
      });
    }
  }

  onEditOrganisationBranch() {
    if (this.formGroupDetail.valid && this.formGroupAddress.valid) {
      const rawVal = this.formGroupDetail.getRawValue();
      let item = { ...rawVal};

      const rawValAddress = this.formGroupAddress.getRawValue();
      item = Object.assign(item, rawValAddress);

      this.organisationBranchService.updateOrganisationBranch(this.organisationBranch.id, item).subscribe(organisationBranch => {
        this.notificationService.success(this.translate.instant('ORGANISATION_BRANCH.NOTIFICATION.ORGANISATION_BRANCH_UPDATED'));
        this.patchForms(organisationBranch);
        this.dialogRef.close({status: 'ok'});
      }, error => {
        console.log('Error on update organisation branch : ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('ORGANISATION_BRANCH.NOTIFICATION.ORGANISATION_BRANCH_UPDATE_FAILED'));
      });
    }
  }

  onDeleteOrganisationBranch() {
    this.organisationBranchService.deleteOrgansationBranch(this.organisationBranch.id).subscribe(() => {
      this.notificationService.success(this.translate.instant('ORGANISATION_BRANCH.NOTIFICATION.ORGANISATION_BRANCH_DELETED'));
      this.dialogRef.close({status: 'ok'});
    }, error => {
      console.log('Error on delete organisation branch : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('ORGANISATION_BRANCH.NOTIFICATION.ORGANISATION_BRANCH_DELETE_FAILED'));
    });
  }

  patchForms(organisationBranch: OrganisationBranch) {
    this.formGroupDetail.patchValue(organisationBranch);
    this.formGroupAddress.patchValue(organisationBranch);
    this.organisationBranch = organisationBranch;
  }

  getFormControl(group: FormGroup, controlName: string){
    return group.get(controlName) as FormControl
  }
}
