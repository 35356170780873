/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserProfile, ChangeMyPasswordRequest} from '../../shared/model/user-profile';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { GenericResponse } from '../model/generic-response';
import { UserAccount, UpdateAccountRequest, UserProfilePhotoUpdateRequest, UserSummary } from '../model/user-account';
import { Page, PageableSort } from '../model/pagination';
import { createPageParams } from '../util';
import { IdList } from '../model/id-list';
import { MAX_PAGE_SIZE_FOR_UNLIMITED_LIST } from '../constants';

@Injectable({
    providedIn: 'root'
})
export class UserProfileService {

    private baseUrl = '/ums/v1/users';

    constructor(private http: HttpClient) {
    }

    getUserProfile(userId: number): Observable<UserProfile> {
        return this.http.get<UserProfile>(environment.url + `${this.baseUrl}/${userId}`)
            .pipe(map((res: any) => res || {}));
    }

    updateUserAccount(updateAccountRequest: UpdateAccountRequest): Observable<UserAccount> {
        return this.http.put<UserProfile>(environment.url + `${this.baseUrl}/me`, updateAccountRequest)
            .pipe(map((res: any) => res || {}));
    }

    deleteAccount(): Observable<void> {
        return this.http.delete<void>(environment.url + `${this.baseUrl}/me/delete`);
    }

    getUserAccount(): Observable<UserAccount> {
        return this.http.get<UserAccount>(environment.url + `${this.baseUrl}/me`)
            .pipe(map((res: any) => res || {}));
    }

    changeMyPassword(request: ChangeMyPasswordRequest): Observable<GenericResponse> {
        return this.http.post<UserProfile>(environment.url + `${this.baseUrl}/me/change-password`, request)
            .pipe(map((res: any) => res || {}));
    }

    updatePhoto(updateAccountPhotoRequest: UserProfilePhotoUpdateRequest): Observable<UserAccount> {
        return this.http.put<UserProfile>(environment.url + `${this.baseUrl}/me/profile-photo`, updateAccountPhotoRequest)
            .pipe(map((res: any) => res || {}));
    }

    deletePhoto(): Observable<void> {
        return this.http.delete<void>(environment.url + `${this.baseUrl}/me/profile-photo`)
            .pipe(map((res: any) => res || {}));
    }

    getUsersByOrganisation(organisationId: number): Observable<Page<UserSummary>> {
        return this.listUsersByOrganisation(organisationId, null, null, MAX_PAGE_SIZE_FOR_UNLIMITED_LIST, null, null);
    }

    listUsersByOrganisation(organisationId: number, roles: string[], page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<UserSummary>> {
        const queryParams = createPageParams(page, pageSize, sort, searchTerm);
        if (roles) {
            queryParams.roles = roles.join(',');
        }
        return this.http.get<Page<UserSummary>>(environment.url + `${this.baseUrl}/organisation/${organisationId}`, { params: queryParams })
            .pipe(map((res: any) => res || {}));
    }

    listAllUserIdsByOrganisation(organisationId: number, roles: string[], searchTerm?: string): Observable<IdList> {
        const queryParams: any = {};

        if (searchTerm) {
            queryParams.searchTerm = searchTerm;
        }

        if (roles) {
            queryParams.roles = roles.join(',');
        }
        return this.http.get<IdList>(environment.url + `${this.baseUrl}/organisation/${organisationId}/list-all-user-ids`, { params: queryParams })
            .pipe(map((res: any) => res || {}));
    }

    listUsersByOrganisationBranch(organisationBranchId: number, roles: string[], page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<UserSummary>> {
        const queryParams = createPageParams(page, pageSize, sort, searchTerm);
        if (roles) {
            queryParams.roles = roles.join(',');
        }
        return this.http.get<Page<UserSummary>>(environment.url + `${this.baseUrl}/organisation-branch/${organisationBranchId}`, { params: queryParams })
            .pipe(map((res: any) => res || {}));
    }

    listAllUserIdsByOrganisationBranch(organisationBranchId: number, roles: string[], searchTerm?: string): Observable<IdList> {
        const queryParams: any = {};

        if (searchTerm) {
            queryParams.searchTerm = searchTerm;
        }

        if (roles) {
            queryParams.roles = roles.join(',');
        }
        return this.http.get<IdList>(environment.url + `${this.baseUrl}/organisation-branch/${organisationBranchId}/list-all-user-ids`, { params: queryParams })
            .pipe(map((res: any) => res || {}));
    }

    removePatientFromOrganisation(removeUserId: number): Observable<void> {
        return this.http.delete<void>(environment.url + `${this.baseUrl}/${removeUserId}/organisation/remove-patient`)
            .pipe(map((res: any) => res || {}));
    }

    removeClinicianFromOrganisation(removeUserId: number): Observable<void> {
        return this.http.delete<void>(environment.url + `${this.baseUrl}/${removeUserId}/organisation/remove-clinician`)
            .pipe(map((res: any) => res || {}));
    }

    setClinicianAsOrganisationManager(updateUserId: number): Observable<UserSummary> {
        return this.http.put<UserSummary>(environment.url + `${this.baseUrl}/${updateUserId}/organisation/manager/set`, {})
            .pipe(map((res: any) => res || {}));
    }
}
