/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Page, Pageable, PageableSort } from '../model/pagination';
import { Suppression } from '../model/suppression';
import { map } from 'rxjs/operators';
import { createPageParams } from '../util';

@Injectable({
    providedIn: 'root'
})
export class SuppressionService {

    private baseUrl = '/suppressions';

    constructor(private http: HttpClient) {
    }

    uploadFile(file: File): Observable<HttpEvent<{}>> {
        const fileAlias = 'file';
        const formData = new FormData();
        formData.set(fileAlias, file, file.name);

        let myheaders = new HttpHeaders();
        myheaders = myheaders.append('Accept', 'application/json');
        myheaders = myheaders.append('Content-Type', 'multipart/form-data');

        return this.http.post<any>(environment.url + `${this.baseUrl}/upload`, formData, {
            observe: 'events',
            reportProgress: true,
            responseType: 'json'
        });
    }

    getSuppressions(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<Suppression>> {
        const pageParams = createPageParams(page, pageSize, sort, searchTerm);

        return this.http.get<Page<Suppression>>(environment.url + `${this.baseUrl}`, { params: pageParams })
            .pipe(map((res: any) => (res || {})
            ));
    }

    getArtCodeNames(): Observable<string[]> {
        return this.http.get<string[]>(environment.url + `${this.baseUrl}/art-code-names`)
            .pipe(map((res: any) => (res || [])
            ));
    }

}
