import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  CountryCost, CountryCostListResponse, SimulationCostUpdate,
  SimulationCountryUpdate, SimulationDemandUpdate,
  SimulationList, SimulationResourceUpdate, SimulationRunPesponse,
  SimulationServiceUpdate, SimulationStaffUpdate, SimulationUnaidsUpdate, VisitTimeListResponse
} from '../model/simulation';
import {
  SimulationResultCostListResponse, SimulationResultDemandListResponse,
  SimulationResultPreventionListResponse,
  SimulationResultResourceListResponse,
  SimulationResultSummaryListResponse,
  SimulationResultUnaidsListResponse
} from '../model/simulation-result';

@Injectable({
  providedIn: 'root'
})
export class SimulationService {

  private baseUrl = '/simulations';
  private fileDownloadUrl = '/simulation-results/file';
  private resultDemandUrl = '/simulation-results/demands';
  private resultPreventionUrl = '/simulation-results/preventions';
  private resultCostUrl = '/simulation-results/costs';
  private resultUnaidsUrl = '/simulation-results/unaids';
  private resultSummaryUrl = '/simulation-results/summary';
  private resultResourceUrl = '/simulation-results/resources';

  constructor(private http: HttpClient) {}

  getSimulations(): Observable<SimulationList> {
    return this.http.get<SimulationList>(environment.url + `${this.baseUrl}`)
      .pipe(map((res: any) => res || {}));
  }

  getSimulationResultDemands(simKey: string): Observable<SimulationResultDemandListResponse> {
    return this.http.get<SimulationResultDemandListResponse>(environment.url + `${this.resultDemandUrl}/${simKey}`)
      .pipe(map((res: any) => res || {}));
  }

  getSimulationResultUnaids(simKey: string): Observable<SimulationResultUnaidsListResponse> {
    return this.http.get<SimulationResultUnaidsListResponse>(environment.url + `${this.resultUnaidsUrl}/${simKey}`)
      .pipe(map((res: any) => res || {}));
  }

  getSimulationResultSummary(simKey: string): Observable<SimulationResultSummaryListResponse> {
    return this.http.get<SimulationResultSummaryListResponse>(environment.url + `${this.resultSummaryUrl}/${simKey}`)
      .pipe(map((res: any) => res || {}));
  }

  getSimulationResultPreventions(simKey: string): Observable<SimulationResultPreventionListResponse> {
    return this.http.get<SimulationResultPreventionListResponse>(environment.url + `${this.resultPreventionUrl}/${simKey}`)
      .pipe(map((res: any) => res || {}));
  }

  getSimulationResultCosts(simKey: string): Observable<SimulationResultCostListResponse> {
    return this.http.get<SimulationResultCostListResponse>(environment.url + `${this.resultCostUrl}/${simKey}`)
      .pipe(map((res: any) => res || {}));
  }

  getSimulationCost(country: string): Observable<CountryCost> {
    return this.http.get<CountryCost>(environment.url + '/costs' + `/${country}`)
      .pipe(map((res: any) => res || {}));
  }

  getSimulationCosts(): Observable<CountryCostListResponse> {
    return this.http.get<CountryCostListResponse>(environment.url + '/costs')
      .pipe(map((res: any) => res || {}));
  }

  getVisitTimes(): Observable<VisitTimeListResponse> {
    return this.http.get<VisitTimeListResponse>(environment.url + '/visits')
      .pipe(map((res: any) => res || {}));
  }

  getSimulationResultResources(simKey: string): Observable<SimulationResultResourceListResponse> {
    return this.http.get<SimulationResultCostListResponse>(environment.url + `${this.resultResourceUrl}/${simKey}`)
      .pipe(map((res: any) => res || {}));
  }

  runSimulation(id: number): Observable<SimulationRunPesponse> {
    return this.http.post<SimulationRunPesponse>(environment.url + `${this.baseUrl}/${id}`, null)
      .pipe(map((res: any) => res || {}));
  }

  updateSimulationDemands(request: SimulationDemandUpdate): Observable<void> {
    return this.http.put<void>(environment.url + `${this.baseUrl}/demands`, request)
      .pipe(map((res: any) => res || {}));
  }

  updateSimulationCosts(request: SimulationCostUpdate): Observable<void> {
    return this.http.put<void>(environment.url + `${this.baseUrl}/costs`, request)
      .pipe(map((res: any) => res || {}));
  }

  updateSimulationCuntry(request: SimulationCountryUpdate): Observable<void> {
    return this.http.put<void>(environment.url + `${this.baseUrl}/users`, request)
      .pipe(map((res: any) => res || {}));
  }

  updateSimulationService(request: SimulationServiceUpdate): Observable<void> {
    return this.http.put<void>(environment.url + `${this.baseUrl}/services`, request)
      .pipe(map((res: any) => res || {}));
  }

  updateSimulationStaff(request: SimulationStaffUpdate): Observable<void> {
    return this.http.put<void>(environment.url + `${this.baseUrl}/staffs`, request)
      .pipe(map((res: any) => res || {}));
  }

  updateSimulationResource(request: SimulationResourceUpdate): Observable<void> {
    return this.http.put<void>(environment.url + `${this.baseUrl}/resources`, request)
      .pipe(map((res: any) => res || {}));
  }

  updateSimulationUnaids(request: SimulationUnaidsUpdate): Observable<void> {
    return this.http.put<void>(environment.url + `${this.baseUrl}/unaids`, request)
      .pipe(map((res: any) => res || {}));
  }

  downloadResultFile(simKey: string): Observable<Blob> {
    return this.http.get(environment.url + `${this.fileDownloadUrl}/${simKey}`, { responseType: 'blob' as 'json' })
      .pipe(map((res: any) => (res || {})
      ));
  }

}
