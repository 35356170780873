import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ChartComponent } from 'ng-apexcharts';
import { Pair, SimulationResultUnaidsResponse } from 'src/app/shared/model/simulation-result';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { SimulationService } from 'src/app/shared/services/simulation.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-unaids',
  templateUrl: './unaids.component.html',
  styleUrls: ['./unaids.component.scss']
})
export class UnaidsComponent implements OnInit {

  @ViewChild('chartRadialFirstSummary')
  chartRadialFirstSummary: ChartComponent = Object.create(null);
  @ViewChild('chartRadialSecondSummary')
  chartRadialSecondSummary: ChartComponent = Object.create(null);
  @ViewChild('chartRadialThirdSummary')
  chartRadialThirdSummary: ChartComponent = Object.create(null);

  @ViewChild('chartRadialFirstBaseline')
  chartRadialFirstBaseline: ChartComponent = Object.create(null);
  @ViewChild('chartRadialSecondBaseline')
  chartRadialSecondBaseline: ChartComponent = Object.create(null);
  @ViewChild('chartRadialThirdBaseline')
  chartRadialThirdBaseline: ChartComponent = Object.create(null);
  @ViewChild('chartRadialViralBaseline')
  chartRadialViralBaseline: ChartComponent = Object.create(null);

  @ViewChild('chartRadialFirstScenario')
  chartRadialFirstScenario: ChartComponent = Object.create(null);
  @ViewChild('chartRadialSecondScenario')
  chartRadialSecondScenario: ChartComponent = Object.create(null);
  @ViewChild('chartRadialThirdScenario')
  chartRadialThirdScenario: ChartComponent = Object.create(null);
  @ViewChild('chartRadialViralScenario')
  chartRadialViralScenario: ChartComponent = Object.create(null);

  @ViewChild('chartDiagnosis')
  chartDiagnosis: ChartComponent = Object.create(null);
  @ViewChild('chartOnArt')
  chartOnArt: ChartComponent = Object.create(null);

  @ViewChild('chartBaseline')
  chartBaseline: ChartComponent = Object.create(null);
  @ViewChild('chartScenario')
  chartScenario: ChartComponent = Object.create(null);

  @ViewChild('chartPatientsLastYear')
  chartPatientsLastYear: ChartComponent = Object.create(null);
  @ViewChild('chartPatientsLastYearPer')
  chartPatientsLastYearPer: ChartComponent = Object.create(null);
  @ViewChild('chartTargetCurrentPracticePer')
  chartTargetCurrentPracticePer: ChartComponent = Object.create(null);
  @ViewChild('chartTargetScenarioPer')
  chartTargetScenarioPer: ChartComponent = Object.create(null);

  @ViewChild('chartSum')
  chartSum: ChartComponent = Object.create(null);
  @ViewChild('chartSumPer')
  chartSumPer: ChartComponent = Object.create(null);

  public chartSumOptions;
  public chartSumPerOptions;

  public chartPatientsLastYearOptions;
  public chartPatientsLastYearPerOptions;
  public chartTargetCurrentPracticePerOptions;
  public chartTargetScenarioPerOptions;

  public chartRadialFirstSummaryOptions;
  public chartRadialSecondSummaryOptions;
  public chartRadialThirdSummaryOptions;
  public chartRadialViralBaselineOptions;

  public chartRadialFirstBaselineOptions;
  public chartRadialSecondBaselineOptions;
  public chartRadialThirdBaselineOptions;
  public chartRadialViralScenarioOptions;

  public chartRadialFirstScenarioOptions;
  public chartRadialSecondScenarioOptions;
  public chartRadialThirdScenarioOptions;

  public chartDiagnosisOptions: Partial<any>;
  public chartOnArtOptions: Partial<any>;

  public chartBaselineOptions: Partial<any>;
  public chartScenarioOptions: Partial<any>;

  country: string;

  countryKnowStatus: number;
  countryOnArt: number;
  countryViralSuppression: number;

  percentageKnowStatus: number;
  percentageArt: number;
  percentageViralSuppression: number;

  mortalityRatio: number;
  prevalenceRatio: number;

  demandExperienced: number;
  demandNaive: number;
  demandPrevention: number;

  demandExperiencedSc: number;
  demandNaiveSc: number;
  demandPreventionSc: number;

  demandIncrease: number;
  demandIncreasePrevention: number;

  demandIncreaseSc: number;
  demandIncreasePreventionSc: number;

  avertedRatio: number;

  avertedRatioSc: number;

  diagnosedPercentage = 0;
  onArtPercentage = 0;
  viralSuppressionPercentage = 0;
  isNoScenario = true;

  layout = 'raw';
  layoutSize = '50';

  years;
  startYear;
  finishYear;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private notificationService: NotificationService,
    public simulationService: SimulationService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    const result = JSON.parse(this.storageService.getSimulationResult());

    if (!result) {
      this.router.navigate(['smarthiv-manager/manager-form']);
      this.notificationService.error(this.translate.instant('SIMULATION.RUN_MODEL_EXCEPTION'));
    }

    this.country = this.storageService.getCountry();

    this.simulationService.getSimulationCost(this.country).subscribe(data => {
      this.countryKnowStatus = data.percentageKnowStatus;
      this.countryOnArt = data.percentageArt;
      this.countryViralSuppression = data.percentageViralSuppression;
    });

    this.simulationService.getSimulationResultUnaids(result).subscribe(data => {

      this.years = data.unaids[0].years;

      this.demandExperienced = data.unaids[0].demandExperienced;
      this.demandNaive = data.unaids[0].demandNaive;
      this.demandPrevention = data.unaids[0].demandPrevention;

      this.demandIncrease = data.unaids[0].demandIncrease;
      this.demandIncreasePrevention = data.unaids[0].demandIncreasePrevention;

      this.avertedRatio = data.unaids[0].avertedRatio;

      this.demandExperiencedSc = data.unaids[data.unaids.length - 1].demandExperienced;
      this.demandNaiveSc = data.unaids[data.unaids.length - 1].demandNaive;
      this.demandPreventionSc = data.unaids[data.unaids.length - 1].demandPrevention;

      this.demandIncreaseSc = data.unaids[data.unaids.length - 1].demandIncrease;
      this.demandIncreasePreventionSc = data.unaids[data.unaids.length - 1].demandIncreasePrevention;

      this.avertedRatioSc = data.unaids[data.unaids.length - 1].avertedRatio;

      if (this.years > 5) {
        this.layout = 'column';
        this.layoutSize = '100';
      }

      this.percentageKnowStatus = data.unaids[0].percentageKnowStatus;
      this.percentageArt = data.unaids[0].percentageArt;
      this.percentageViralSuppression = data.unaids[0].percentageViralSuppression;
      this.mortalityRatio = data.unaids[0].mortalityRatio;
      this.prevalenceRatio = data.unaids[0].prevalenceRatio;

      this.diagnosedPercentage = data.unaids[data.unaids.length - 1].percentageKnowStatus;
      this.onArtPercentage = data.unaids[data.unaids.length - 1].percentageArt;
      this.viralSuppressionPercentage = data.unaids[data.unaids.length - 1].percentageViralSuppression;

      if (this.diagnosedPercentage === 0) {
        this.diagnosedPercentage = 90;
        this.onArtPercentage = 90;
        this.viralSuppressionPercentage = 90;
        this.setAllDataForNoScenario(this.getAverage(this.getArray(data.unaids, 'patient', true)),
          this.getAverage(this.getArray(data.unaids, 'patient', false)),
          this.getAverage(this.getArray(data.unaids, 'viral_load', true)),
          this.getAverage(this.getArray(data.unaids, 'viral_load', false)),
          this.getAverage(this.getArray(data.unaids, 'demand', true)),
          this.getAverage(this.getArray(data.unaids, 'demand', false))
        );
      } else {
        this.isNoScenario = false;
        this.setAllData(this.getAverage(this.getArray(data.unaids, 'patient', true)),
          this.getAverage(this.getArray(data.unaids, 'patient', false)),
          this.getAverage(this.getArray(data.unaids, 'viral_load', true)),
          this.getAverage(this.getArray(data.unaids, 'viral_load', false)),
          this.getAverage(this.getArray(data.unaids, 'demand', true)),
          this.getAverage(this.getArray(data.unaids, 'demand', false)),
          this.getAverage(this.getArray(data.unaids, 'averted', true)),
          this.getAverage(this.getArray(data.unaids, 'averted', false))
        );
      }
    });
  }

  setAllData(netPatientMap: Map<string, Array<Pair>>,
    netPatientMapSc: Map<string, Array<Pair>>,
    viralLoadMap: Map<string, Array<Pair>>,
    viralLoadMapSc: Map<string, Array<Pair>>,
    demandMap: Map<string, Array<Pair>>,
    demandMapSc: Map<string, Array<Pair>>,
    avertedMap: Map<string, Array<Pair>>,
    avertedMapSc: Map<string, Array<Pair>>) {

    const years: string[] = [];
    const yearsPlus: string[] = [];

    const naiveDemandData: number[] = [];
    const naiveDemandDataSc: number[] = [];

    const plwhaData: number[] = [];
    const plwhaDataSc: number[] = [];

    const diagnosedData: number[] = [];
    const diagnosedDataSc: number[] = [];

    const onArtData: number[] = [];
    const onArtDataSc: number[] = [];

    const viralLoadData: number[] = [];
    const viralLoadDataSc: number[] = [];

    const avertedData: number[] = [];
    const avertedDataSc: number[] = [];

    const percentageDiagnoseData: number[] = [];
    const percentageOnartData: number[] = [];
    const percentageViralLoadData: number[] = [];
    const percentagePlwhaData: number[] = [];

    const percentageDiagnoseDataSc: number[] = [];
    const percentageOnartDataSc: number[] = [];
    const percentageViralLoadDataSc: number[] = [];
    const percentagePlwhaDataSc: number[] = [];

    const newDiagnosisData: number[] = [];
    const newDiagnosisDataSc: number[] = [];

    let onartFirst = 0;
    let onartFirstSc = 0;

//    let onartStart = 0;
    let onartStartSc = 0;

    let onartTarget = 0;
    let onartTargetSc = 0;

    let diagnosedFirst = 0;
    let diagnosedFirstSc = 0;

//    let diagnosedStart = 0;
    let diagnosedStartSc = 0;

    let diagnosedTarget = 0;
    let diagnosedTargetSc = 0;

    let plwhFirst = 0;
    let plwhFirstSc = 0;

//    let plwhStart = 0;
    let plwhStartSc = 0;

    let plwhTarget = 0;
    let plwhTargetSc = 0;

    let viralLoadFirst = 0;
    let viralLoadFirstSc = 0;

//    let viralLoadStart = 0;
    let viralLoadStartSc = 0;

    let viralLoadTarget = 0;
    let viralLoadTargetSc = 0;

    let isFirst = true;

    for (const key of netPatientMap.keys()) {
      let naiveDemand = 0;
      let naiveDemandSc = 0;

      let onart = 0;
      let onartSc = 0;

      let viralLoad = 0;
      let viralLoadSc = 0;

      let averted = 0;
      let avertedSc = 0;

      years.push(key);

      const patientPairs: Pair[] = netPatientMap.get(key);
      const patientPairsSc: Pair[] = netPatientMapSc.get(key);

      const demandPairs: Pair[] = demandMap.get(key);
      const demandPairsSc: Pair[] = demandMapSc.get(key);

      const viralLoadPairs: Pair[] = viralLoadMap.get(key);
      const viralLoadPairsSc: Pair[] = viralLoadMapSc.get(key);

      const avertedPairs: Pair[] = avertedMap.get(key);
      const avertedPairsSc: Pair[] = avertedMapSc.get(key);

      avertedPairs.forEach(element => {
        averted = averted + Math.round(element.second);
      });

      avertedPairsSc.forEach(element => {
        avertedSc = avertedSc + Math.round(element.second);
      });

      demandPairs.forEach(element => {
        if (element.first === 'NAIVE'
          || element.first === 'NAIVE_PREGNANT'
          || element.first === 'NAIVE_IAC'
          || element.first === 'NAIVE_TL') {
          naiveDemand = naiveDemand + Math.round(element.second);
        }

      });

      demandPairsSc.forEach(element => {
        if (element.first === 'NAIVE'
          || element.first === 'NAIVE_PREGNANT'
          || element.first === 'NAIVE_IAC'
          || element.first === 'NAIVE_TL') {
          naiveDemandSc = naiveDemandSc + Math.round(element.second);
        }
      });

      patientPairs.forEach(element => {
        if (element.first === 'NAIVE'
          || element.first === 'NAIVE_PREGNANT'
          || element.first === 'NAIVE_IAC'
          || element.first === 'NAIVE_TL') {
          onart = onart + Math.round(element.second);
        } else if (element.first === 'TX_EXPERIENCED'
          || (element.first === 'TX_EXPERIENCED_TL')
          || (element.first === 'TX_EXPERIENCED_PREGNANT')
          || (element.first === 'TX_EXPERIENCED_IAC')) {
          onart = onart + Math.round(element.second);
        }

      });

      patientPairsSc.forEach(element => {
        if (element.first === 'NAIVE'
          || element.first === 'NAIVE_PREGNANT'
          || element.first === 'NAIVE_IAC'
          || element.first === 'NAIVE_TL') {
          onartSc = onartSc + Math.round(element.second);
        } else if (element.first === 'TX_EXPERIENCED'
          || (element.first === 'TX_EXPERIENCED_TL')
          || (element.first === 'TX_EXPERIENCED_PREGNANT')
          || (element.first === 'TX_EXPERIENCED_IAC')) {
          onartSc = onartSc + Math.round(element.second);
        }
      });

      viralLoadPairs.forEach(element => {
        if (element.first === 'SUPPRESSED') {
          viralLoad = viralLoad + Math.round(element.second);
        }
      });

      viralLoadPairsSc.forEach(element => {
        if (element.first === 'SUPPRESSED') {
          viralLoadSc = viralLoadSc + Math.round(element.second);
        }
      });

      if (isFirst === true) {
        isFirst = false;
        onartFirst = onart;
        diagnosedFirst = Math.round((onartFirst / this.percentageArt) * 100);
        plwhFirst = Math.round((diagnosedFirst / this.percentageKnowStatus) * 100);
        viralLoadFirst = viralLoad;

        onartFirstSc = onartSc;
        diagnosedFirstSc = Math.round((onartFirstSc / this.percentageArt) * 100);
        plwhFirstSc = Math.round((diagnosedFirstSc / this.percentageKnowStatus) * 100);
        viralLoadFirstSc = viralLoadSc;
      }

      naiveDemandData.push(Math.round(naiveDemand));
      naiveDemandDataSc.push(Math.round(naiveDemandSc));

      onArtData.push(Math.round(onart));
      onArtDataSc.push(Math.round(onartSc));

      viralLoadData.push(Math.round(viralLoad));
      viralLoadDataSc.push(Math.round(viralLoadSc));

      avertedData.push(Math.round(averted));
      avertedDataSc.push(Math.round(avertedSc));

      onartTarget = onart;
      onartTargetSc = onartSc;

      viralLoadTarget = viralLoad;
      viralLoadTargetSc = viralLoadSc;
    }


//    const annualOnArtChange = Math.round((onartTarget - onartFirst) / this.years);
    const annualOnArtChangeSc = Math.round((onartTargetSc - onartFirstSc) / this.years);

//    const annualViralLoadChange = Math.round((viralLoadTarget - viralLoadFirst) / this.years);
    const annualViralLoadChangeSc = Math.round((viralLoadTargetSc - viralLoadFirstSc) / this.years);

//    onartStart = Math.round(onartFirst - annualOnArtChange);
//    diagnosedStart = Math.round((onartStart / this.percentageArt) * 100);
//    plwhStart = Math.round((diagnosedStart / this.percentageKnowStatus) * 100);
//    viralLoadStart = Math.round(viralLoadFirst - annualViralLoadChange);

    onartStartSc = Math.round(onartFirstSc - annualOnArtChangeSc);
    diagnosedStartSc = Math.round((onartStartSc / this.percentageArt) * 100);
    plwhStartSc = Math.round((diagnosedStartSc / this.percentageKnowStatus) * 100);
    viralLoadStartSc = Math.round(viralLoadFirstSc - annualViralLoadChangeSc);

    console.log('onartStart     : ' + onartStartSc);
    console.log('diagnosedStart : ' + diagnosedStartSc);
    console.log('plwhStart      : ' + plwhStartSc);

    console.log('*******************************************');
    console.log('viralLoadFirstSc : ' + viralLoadFirstSc);
    console.log('onartFirst       : ' + onartFirstSc);
    console.log('diagnosedFirst   : ' + diagnosedFirstSc);
    console.log('plwhFirst        : ' + plwhFirstSc);
    console.log('*******************************************');

    plwhTarget = plwhFirst;
    plwhTargetSc = plwhFirstSc;

    plwhaData.push(Math.round(plwhTarget));
    plwhaDataSc.push(Math.round(plwhTargetSc));

    for (let index = 1; index < this.years; index++) {

      plwhTarget = Math.round(plwhTarget * (1 - (this.mortalityRatio / 100) + (this.prevalenceRatio / 100)))
        - Math.round(avertedData[index -1]);
      plwhTargetSc = Math.round(plwhTargetSc * (1 - (this.mortalityRatio / 100) + (this.prevalenceRatio / 100)))
        - Math.round(avertedDataSc[index -1]);

      plwhaData.push(Math.round(plwhTarget));
      plwhaDataSc.push(Math.round(plwhTargetSc));
    }

    diagnosedTarget = Math.round((onartTarget + plwhTarget) / 2);
    diagnosedTargetSc = Math.round((plwhTargetSc / 100) * this.diagnosedPercentage);


    const mvalue = 1 - (this.mortalityRatio / 100);
    let denominator = 0;
    for (let index = 0; index < this.years; index++) {
      denominator = denominator + Math.pow(mvalue, index);
    }


    const annualDiagnoseChange = (diagnosedTarget - (diagnosedFirst * Math.pow(mvalue, this.years))) / denominator;
    const annualDiagnoseChangeSc = (diagnosedTargetSc - (diagnosedFirstSc * Math.pow(mvalue, this.years))) / denominator;

    let diagnosedAnnual = diagnosedFirst;
    let diagnosedAnnualSc = diagnosedFirstSc;

    diagnosedData.push(Math.round(diagnosedAnnual));
    diagnosedDataSc.push(Math.round(diagnosedAnnualSc));

    newDiagnosisData.push(Math.round(annualDiagnoseChange));
    newDiagnosisDataSc.push(Math.round(annualDiagnoseChangeSc));

    for (let index = 1; index < this.years; index++) {

      diagnosedAnnual = annualDiagnoseChange + (diagnosedAnnual * (1 - (this.mortalityRatio / 100)));
      diagnosedAnnualSc = annualDiagnoseChangeSc + (diagnosedAnnualSc * (1 - (this.mortalityRatio / 100)));

      diagnosedData.push(Math.round(diagnosedAnnual));
      diagnosedDataSc.push(Math.round(diagnosedAnnualSc));

      newDiagnosisData.push(Math.round(annualDiagnoseChange));
      newDiagnosisDataSc.push(Math.round(annualDiagnoseChangeSc));
    }

    console.log('viralLoadData    : ' + viralLoadDataSc);
    console.log('onArtData        : ' + onArtDataSc);
    console.log('diagnosedData    : ' + diagnosedDataSc);
    console.log('plwhaData        : ' + plwhaDataSc);
    console.log('*******************************************');
    console.log('viralLoadTarget : ' + viralLoadTargetSc);
    console.log('onartTarget     : ' + onartTargetSc);
    console.log('diagnosedTarget : ' + diagnosedTargetSc);
    console.log('plwhTarget      : ' + plwhTargetSc);
    console.log('*******************************************');
    console.log('mvalue      : ' + mvalue);
    console.log('denominator : ' + denominator);
    console.log('annualDiagnoseChange : ' + annualDiagnoseChangeSc);
    console.log('*******************************************');
    console.log('naiveDemandData  : ' + naiveDemandDataSc);
    console.log('avertedData      : ' + avertedDataSc);
    console.log('newDiagnosisData : ' + newDiagnosisDataSc);

    for (let index = 0; index < this.years; index++) {

      percentageDiagnoseData.push(Math.round(diagnosedData[index] / plwhaData[index] * 100));
      percentageOnartData.push(Math.round(onArtData[index] / diagnosedData[index] * 100));
      percentageViralLoadData.push(Math.round(viralLoadData[index] / diagnosedData[index] * 100));

      percentagePlwhaData.push(Math.round(viralLoadData[index] / plwhaData[index] * 100));

      percentageDiagnoseDataSc.push(Math.round(diagnosedDataSc[index] / plwhaDataSc[index] * 100));
      percentageOnartDataSc.push(Math.round(onArtDataSc[index] / diagnosedDataSc[index] * 100));
      percentageViralLoadDataSc.push(Math.round(viralLoadDataSc[index] / diagnosedDataSc[index] * 100));

      percentagePlwhaDataSc.push(Math.round(viralLoadDataSc[index] / plwhaDataSc[index] * 100));
    }

    let lastYear;

    years.forEach(year => {
      yearsPlus.push(year);
      lastYear = year;
    });

    this.startYear = (parseFloat(years[0])).toFixed(0);
    this.finishYear = lastYear;

    const xxxLabel = this.translate.instant('SIMULATION.UNAIDS.TARGET') + ' '
      + this.percentageKnowStatus + '-' + this.percentageArt + '-' + this.percentageViralSuppression;

    const yvaluesSum: string[] = [];
    yvaluesSum.push(this.translate.instant('SIMULATION.UNAIDS.CURRENT') + ' (' + this.startYear + ')');
    yvaluesSum.push(this.translate.instant('SIMULATION.CURRENT_PRACTICE') + ' (' + lastYear + ')');
    yvaluesSum.push(this.translate.instant('SIMULATION.SCENARIO') + ' (' + lastYear + ')');

    const yvaluesSumPer: string[] = [];
    yvaluesSumPer.push(this.translate.instant('SIMULATION.UNAIDS.CURRENT') + ' (' + this.startYear + ')');
    yvaluesSumPer.push(this.translate.instant('SIMULATION.CURRENT_PRACTICE') + ' (' + lastYear + ')');
    yvaluesSumPer.push(this.translate.instant('SIMULATION.SCENARIO') + ' (' + lastYear + ')');

    const yvalues: string[] = [];
    yvalues.push(this.translate.instant('SIMULATION.CURRENT_PRACTICE'));
    yvalues.push(this.translate.instant('SIMULATION.SCENARIO'));
    yvalues.push(xxxLabel);
    yvalues.push(this.translate.instant('SIMULATION.UNAIDS.TARGET') +
      ' ' + this.translate.instant('SIMULATION.UNAIDS.NINE_NINE_NINE'));

    const yvaluesPer: string[] = [];
    yvaluesPer.push(this.translate.instant('SIMULATION.CURRENT_PRACTICE'));
    yvaluesPer.push(this.translate.instant('SIMULATION.SCENARIO'));
    yvaluesPer.push(xxxLabel);
    yvaluesPer.push(this.translate.instant('SIMULATION.UNAIDS.TARGET') +
      ' ' + this.translate.instant('SIMULATION.UNAIDS.NINE_NINE_NINE'));

    const diagnosedSumPerData: number[] = [];
    diagnosedSumPerData.push(Math.round(this.percentageKnowStatus));
    diagnosedSumPerData.push(Math.round(this.percentageKnowStatus));
    diagnosedSumPerData.push(Math.round(this.percentageKnowStatus));

    const onArtSumPerData: number[] = [];
    onArtSumPerData.push(Math.round(onartFirst / diagnosedFirst * 100));
    onArtSumPerData.push(percentageOnartData[percentageOnartData.length - 1]);
    onArtSumPerData.push(percentageOnartDataSc[percentageOnartDataSc.length - 1]);

    const viralSuppSumPerData: number[] = [];
    viralSuppSumPerData.push(Math.round(diagnosedFirst / plwhFirst * 100));
    viralSuppSumPerData.push(percentageViralLoadData[percentageViralLoadData.length - 1]);
    viralSuppSumPerData.push(percentageViralLoadDataSc[percentageViralLoadDataSc.length - 1]);

    this.chartSumPerOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.UNAIDS.FIRST') + ' ' + this.diagnosedPercentage + '%',
          type: 'column',
          data: diagnosedSumPerData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.SECOND') + ' ' + this.onArtPercentage + '%',
          type: 'column',
          data: onArtSumPerData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.THIRD') + ' ' + this.viralSuppressionPercentage + '%',
          type: 'column',
          data: viralSuppSumPerData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yvaluesSumPer
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PERCENTAGE')
        },
        max: 100
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    const hivSumData: number[] = [];
    hivSumData.push(plwhFirst);
    hivSumData.push(plwhTarget);
    hivSumData.push(plwhTargetSc);

    const diagnosedSumData: number[] = [];
    diagnosedSumData.push(diagnosedFirst);
    diagnosedSumData.push(diagnosedTarget);
    diagnosedSumData.push(diagnosedTargetSc);

    const onArtSumData: number[] = [];
    onArtSumData.push(onartFirst);
    onArtSumData.push(onartTarget);
    onArtSumData.push(onartTargetSc);

    const viralSuppSumData: number[] = [];
    viralSuppSumData.push(viralLoadFirst);
    viralSuppSumData.push(viralLoadTarget);
    viralSuppSumData.push(viralLoadTargetSc);

    this.chartSumOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.UNAIDS.HIV_PLUS'),
          type: 'column',
          data: hivSumData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.DIAGNOSED'),
          type: 'column',
          data: diagnosedSumData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.ART'),
          type: 'column',
          data: onArtSumData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.VIRAL_SUPPRESSION'),
          type: 'column',
          data: viralSuppSumData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yvaluesSum
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.UNAIDS.NO_PATIENTS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    const green = '#1cbd00';
    const red = '#8b0000';
    this.chartRadialFirstSummaryOptions = {
      series: [this.percentageKnowStatus],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        colors: [this.percentageKnowStatus >= 90 ? green : red],
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    this.chartRadialSecondSummaryOptions = {
      series: [this.percentageArt],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        colors: [this.percentageArt >= 90 ? green : red],
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    this.chartRadialThirdSummaryOptions = {
      series: [this.percentageViralSuppression],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [this.percentageViralSuppression >= 90 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    const firstValue = percentageDiagnoseData[percentageDiagnoseData.length - 1];
    const secondValue = percentageOnartData[percentageOnartData.length - 1];
    const thirdValue = percentageViralLoadData[percentageViralLoadData.length - 1];
    const fourthValue = percentagePlwhaData[percentagePlwhaData.length - 1];

    const firstValueSc = percentageDiagnoseDataSc[percentageDiagnoseDataSc.length - 1];
    const secondValueSc = percentageOnartDataSc[percentageOnartDataSc.length - 1];
    const thirdValueSc = percentageViralLoadDataSc[percentageViralLoadDataSc.length - 1];
    const fourthValueSc = percentagePlwhaDataSc[percentagePlwhaDataSc.length - 1];

    this.chartRadialFirstBaselineOptions = {
      series: [firstValue],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [firstValue >= 90 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      },
      labels: ['Average Results']
    };

    this.chartRadialSecondBaselineOptions = {
      series: [secondValue],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [secondValue >= 90 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      },
      labels: ['Average Results']
    };

    this.chartRadialThirdBaselineOptions = {
      series: [thirdValue],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [thirdValue >= 90 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    this.chartRadialViralBaselineOptions = {
      series: [fourthValue],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [fourthValue >= 73 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    this.chartRadialFirstScenarioOptions = {
      series: [firstValueSc],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [firstValueSc >= 90 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    this.chartRadialSecondScenarioOptions = {
      series: [secondValueSc],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [secondValueSc >= 90 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    this.chartRadialThirdScenarioOptions = {
      series: [thirdValueSc],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [thirdValueSc >= 90 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    this.chartRadialViralScenarioOptions = {
      series: [fourthValueSc],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [fourthValueSc >= 73 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    const newDiagnosisDataTmp: string[] = [];
    const newDiagnosisIprDataTmp: string[] = [];

    newDiagnosisData.forEach(data => {
      newDiagnosisDataTmp.push(data.toFixed(0));
    });

    newDiagnosisDataSc.forEach(data => {
      newDiagnosisIprDataTmp.push(data.toFixed(0));
    });

    this.chartDiagnosisOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: newDiagnosisDataTmp
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: newDiagnosisIprDataTmp
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.UNAIDS.NO_PATIENTS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    const naiveDemandDataTmp: string[] = [];
    const naiveDemandIprDataTmp: string[] = [];

    naiveDemandData.forEach(data => {
      naiveDemandDataTmp.push(data.toFixed(0));
    });

    naiveDemandDataSc.forEach(data => {
      naiveDemandIprDataTmp.push(data.toFixed(0));
    });

    this.chartOnArtOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: naiveDemandDataTmp
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: naiveDemandIprDataTmp
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.UNAIDS.NO_PATIENTS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    const diagnosedLastData: number[] = [];
    diagnosedLastData.push(Math.round(diagnosedData[diagnosedData.length - 1]));
    diagnosedLastData.push(Math.round(diagnosedDataSc[diagnosedDataSc.length - 1]));
    diagnosedLastData.push(Math.round(diagnosedTarget));
    const diagnosed909090 = plwhaData[plwhaData.length - 1] * 0.9;
    diagnosedLastData.push(Math.round(diagnosed909090));

    const onArtLastData: number[] = [];
    onArtLastData.push(Math.round(onArtData[onArtData.length - 1]));
    onArtLastData.push(Math.round(onArtDataSc[onArtDataSc.length - 1]));
    onArtLastData.push(Math.round(onartTarget));
    const onArt909090 = diagnosed909090 * 0.9;
    onArtLastData.push(Math.round(onArt909090));

    const viralLastData: number[] = [];
    viralLastData.push(Math.round(viralLoadData[viralLoadData.length - 1]));
    viralLastData.push(Math.round(viralLoadDataSc[viralLoadDataSc.length - 1]));
    viralLastData.push(Math.round(viralLoadTarget));
    const viral909090 = onArt909090 * 0.9;
    viralLastData.push(Math.round(viral909090));

    this.chartPatientsLastYearOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.UNAIDS.DIAGNOSED'),
          type: 'column',
          data: diagnosedLastData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.ART'),
          type: 'column',
          data: onArtLastData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.VIRAL_SUPPRESSION'),
          type: 'column',
          data: viralLastData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yvalues
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.UNAIDS.NO_PATIENTS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    const diagnosedLastPerData: number[] = [];
    diagnosedLastPerData.push(this.percentageKnowStatus);
    diagnosedLastPerData.push(this.percentageKnowStatus);
    diagnosedLastPerData.push(this.percentageKnowStatus);
    diagnosedLastPerData.push(this.diagnosedPercentage);

    const onArtLastPerData: number[] = [];
    onArtLastPerData.push(this.percentageArt);
    onArtLastPerData.push(this.percentageArt);
    onArtLastPerData.push(this.percentageArt);
    onArtLastPerData.push(this.onArtPercentage);

    const viralLastPerData: number[] = [];
    viralLastPerData.push(this.percentageViralSuppression);
    viralLastPerData.push(this.percentageViralSuppression);
    viralLastPerData.push(this.percentageViralSuppression);
    viralLastPerData.push(this.viralSuppressionPercentage);

    const viralVsPlwhaPerData: number[] = [];
    viralVsPlwhaPerData.push(percentagePlwhaData[percentagePlwhaData.length - 1]);
    viralVsPlwhaPerData.push(percentagePlwhaDataSc[percentagePlwhaDataSc.length - 1]);
    viralVsPlwhaPerData.push(Math.round(this.diagnosedPercentage * this.onArtPercentage
      * this.viralSuppressionPercentage / 10000));
    viralVsPlwhaPerData.push(this.percentageKnowStatus);

    this.chartPatientsLastYearPerOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.UNAIDS.FIRST') + ' ' + this.diagnosedPercentage + '%',
          type: 'column',
          data: diagnosedLastPerData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.SECOND') + ' ' + this.onArtPercentage + '%',
          type: 'column',
          data: onArtLastPerData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.THIRD') + ' ' + this.viralSuppressionPercentage + '%',
          type: 'column',
          data: viralLastPerData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.PLWHA_VS'),
          type: 'column',
          data: viralVsPlwhaPerData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yvaluesPer
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PERCENTAGE')
        },
        max: 100
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartBaselineOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.UNAIDS.PLWHA'),
          type: 'column',
          data: plwhaData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.DIAGNOSED'),
          type: 'column',
          data: diagnosedData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.ART'),
          type: 'column',
          data: onArtData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.VIRAL_SUPPRESSION'),
          type: 'column',
          data: viralLoadData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },

      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yearsPlus
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.UNAIDS.NO_PATIENTS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },

      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartScenarioOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.UNAIDS.PLWHA'),
          type: 'column',
          data: plwhaDataSc
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.DIAGNOSED'),
          type: 'column',
          data: diagnosedDataSc
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.ART'),
          type: 'column',
          data: onArtDataSc
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.VIRAL_SUPPRESSION'),
          type: 'column',
          data: viralLoadDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yearsPlus
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.UNAIDS.NO_PATIENTS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartTargetCurrentPracticePerOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.UNAIDS.FIRST') + ' ' + this.diagnosedPercentage + '%',
          type: 'column',
          data: percentageDiagnoseData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.SECOND') + ' ' + this.onArtPercentage + '%',
          type: 'column',
          data: percentageOnartData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.THIRD') + ' ' + this.viralSuppressionPercentage + '%',
          type: 'column',
          data: percentageViralLoadData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.PLWHA_VS'),
          type: 'column',
          data: percentagePlwhaData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yearsPlus
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PERCENTAGE')
        },
        max: 100
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartTargetScenarioPerOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.UNAIDS.FIRST') + ' ' + this.diagnosedPercentage + '%',
          type: 'column',
          data: percentageDiagnoseDataSc
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.SECOND') + ' ' + this.onArtPercentage + '%',
          type: 'column',
          data: percentageOnartDataSc
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.THIRD') + ' ' + this.viralSuppressionPercentage + '%',
          type: 'column',
          data: percentageViralLoadDataSc
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.PLWHA_VS'),
          type: 'column',
          data: percentagePlwhaDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yearsPlus
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PERCENTAGE')
        },
        max: 100
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

  }

  setAllDataForNoScenario(netPatientMap: Map<string, Array<Pair>>,
    netPatientMapSc: Map<string, Array<Pair>>,
    viralLoadMap: Map<string, Array<Pair>>,
    viralLoadMapSc: Map<string, Array<Pair>>,
    demandMap: Map<string, Array<Pair>>,
    demandMapSc: Map<string, Array<Pair>>) {

    const years: string[] = [];
    const yearsPlus: string[] = [];

    const naiveDemandData: number[] = [];
    const naiveDemandDataSc: number[] = [];

    const plwhaData: number[] = [];
    const plwhaDataSc: number[] = [];

    const diagnosedData: number[] = [];
    const diagnosedDataSc: number[] = [];

    const onArtData: number[] = [];
    const onArtDataSc: number[] = [];

    const viralLoadData: number[] = [];
    const viralLoadDataSc: number[] = [];

    const percentageDiagnoseData: number[] = [];
    const percentageOnartData: number[] = [];
    const percentageViralLoadData: number[] = [];
    const percentagePlwhaData: number[] = [];

    const percentageDiagnoseDataSc: number[] = [];
    const percentageOnartDataSc: number[] = [];
    const percentageViralLoadDataSc: number[] = [];
    const percentagePlwhaDataSc: number[] = [];

    const newDiagnosisData: number[] = [];
    const newDiagnosisDataSc: number[] = [];

    let onartFirst = 0;
    let onartFirstSc = 0;

    let onartStart = 0;
    let onartStartSc = 0;

    let onartTarget = 0;
    let onartTargetSc = 0;

    let diagnosedFirst = 0;
    let diagnosedFirstSc = 0;

    let diagnosedStart = 0;
    let diagnosedStartSc = 0;

    let diagnosedTarget = 0;
    let diagnosedTargetSc = 0;

    let plwhFirst = 0;
    let plwhFirstSc = 0;

    let plwhStart = 0;
    let plwhStartSc = 0;

    let plwhTarget = 0;
    let plwhTargetSc = 0;

    let viralLoadFirst = 0;
    let viralLoadFirstSc = 0;

    let viralLoadStart = 0;
    let viralLoadStartSc = 0;

    let viralLoadTarget = 0;
    let viralLoadTargetSc = 0;

    let isFirst = true;

    for (const key of netPatientMap.keys()) {
      let naiveDemand = 0;
      let naiveDemandSc = 0;

      let onart = 0;
      let onartSc = 0;

      let viralLoad = 0;
      let viralLoadSc = 0;

      years.push(key);

      const patientPairs: Pair[] = netPatientMap.get(key);
      const patientPairsSc: Pair[] = netPatientMapSc.get(key);

      const demandPairs: Pair[] = demandMap.get(key);
      const demandPairsSc: Pair[] = demandMapSc.get(key);

      const viralLoadPairs: Pair[] = viralLoadMap.get(key);
      const viralLoadPairsSc: Pair[] = viralLoadMapSc.get(key);

      demandPairs.forEach(element => {
        if (element.first === 'NAIVE'
          || element.first === 'NAIVE_PREGNANT'
          || element.first === 'NAIVE_IAC'
          || element.first === 'NAIVE_TL') {
          naiveDemand = naiveDemand + Math.round(element.second);
        }

      });

      demandPairsSc.forEach(element => {
        if (element.first === 'NAIVE'
          || element.first === 'NAIVE_PREGNANT'
          || element.first === 'NAIVE_IAC'
          || element.first === 'NAIVE_TL') {
          naiveDemandSc = naiveDemandSc + Math.round(element.second);
        }
      });

      patientPairs.forEach(element => {
        if (element.first === 'NAIVE'
          || element.first === 'NAIVE_PREGNANT'
          || element.first === 'NAIVE_IAC'
          || element.first === 'NAIVE_TL') {
          onart = onart + Math.round(element.second);
        } else if (element.first === 'TX_EXPERIENCED'
          || (element.first === 'TX_EXPERIENCED_TL')
          || (element.first === 'TX_EXPERIENCED_PREGNANT')
          || (element.first === 'TX_EXPERIENCED_IAC')) {
          onart = onart + Math.round(element.second);
        }

      });

      patientPairsSc.forEach(element => {
        if (element.first === 'NAIVE'
          || element.first === 'NAIVE_PREGNANT'
          || element.first === 'NAIVE_IAC'
          || element.first === 'NAIVE_TL') {
          onartSc = onartSc + Math.round(element.second);
        } else if (element.first === 'TX_EXPERIENCED'
          || (element.first === 'TX_EXPERIENCED_TL')
          || (element.first === 'TX_EXPERIENCED_PREGNANT')
          || (element.first === 'TX_EXPERIENCED_IAC')) {
          onartSc = onartSc + Math.round(element.second);
        }
      });

      viralLoadPairs.forEach(element => {
        if (element.first === 'SUPPRESSED') {
          viralLoad = viralLoad + Math.round(element.second);
        }
      });

      viralLoadPairsSc.forEach(element => {
        if (element.first === 'SUPPRESSED') {
          viralLoadSc = viralLoadSc + Math.round(element.second);
        }
      });

      if (isFirst === true) {
        isFirst = false;
        onartFirst = onart;
        diagnosedFirst = Math.round((onartFirst / this.percentageArt) * 100);
        plwhFirst = Math.round((diagnosedFirst / this.percentageViralSuppression) * 100);
        viralLoadFirst = viralLoad;

        onartFirstSc = onartSc;
        diagnosedFirstSc = Math.round((onartFirstSc / this.percentageArt) * 100);
        plwhFirstSc = Math.round((diagnosedFirstSc / this.percentageViralSuppression) * 100);
        viralLoadFirstSc = viralLoadSc;
      }

      naiveDemandData.push(Math.round(naiveDemand));
      naiveDemandDataSc.push(Math.round(naiveDemandSc));

      onArtData.push(Math.round(onart));
      onArtDataSc.push(Math.round(onartSc));

      viralLoadData.push(Math.round(viralLoad));
      viralLoadDataSc.push(Math.round(viralLoadSc));

      onartTarget = onart;
      onartTargetSc = onartSc;

      viralLoadTarget = viralLoad;
      viralLoadTargetSc = viralLoadSc;
    }

    const annualOnArtChange = Math.round((onartTarget - onartFirst) / this.years);
    const annualOnArtChangeSc = Math.round((onartTargetSc - onartFirstSc) / this.years);

    const annualViralLoadChange = Math.round((viralLoadTarget - viralLoadFirst) / this.years);
    const annualViralLoadChangeSc = Math.round((viralLoadTargetSc - viralLoadFirstSc) / this.years);

    onartStart = onartFirst - annualOnArtChange;
    diagnosedStart = Math.round((onartStart / this.percentageArt) * 100);
    plwhStart = Math.round((diagnosedStart / this.percentageViralSuppression) * 100);
    viralLoadStart = viralLoadFirst - annualViralLoadChange;

    onartStartSc = onartFirstSc - annualOnArtChangeSc;
    diagnosedStartSc = Math.round((onartStartSc / this.percentageArt) * 100);
    plwhStartSc = Math.round((diagnosedStartSc / this.percentageViralSuppression) * 100);
    viralLoadStartSc = viralLoadFirstSc - annualViralLoadChangeSc;

    diagnosedTarget = Math.round((onartTarget / this.percentageArt) * 100);
    diagnosedTargetSc = Math.round((onartTargetSc / this.percentageArt) * 100);

    plwhTarget = Math.round((diagnosedTarget / this.percentageViralSuppression) * 100);
    plwhTargetSc = Math.round((diagnosedTargetSc / this.percentageViralSuppression) * 100);

    const annualPlwhaChange = Math.round((plwhTarget - plwhFirst) / this.years);
    const annualPlwhaChangeSc = Math.round((plwhTargetSc - plwhFirstSc) / this.years);

    const annualDiagnoseChange = Math.round((diagnosedTarget - diagnosedFirst) / this.years);
    const annualDiagnoseChangeSc = Math.round((diagnosedTargetSc - diagnosedFirstSc) / this.years);

    let previousDiagnose = diagnosedFirst;
    let previousDiagnoseSc = diagnosedFirstSc;

    let plwhaAnnual = plwhFirst;
    let plwhaAnnualSc = plwhFirstSc;

    let diagnosedAnnual = diagnosedFirst;
    let diagnosedAnnualSc = diagnosedFirstSc;

    for (let index = 0; index < this.years; index++) {

      plwhaAnnual = plwhaAnnual + annualPlwhaChange;
      plwhaAnnualSc = plwhaAnnualSc + annualPlwhaChangeSc;

      diagnosedAnnual = diagnosedAnnual + annualDiagnoseChange;
      diagnosedAnnualSc = diagnosedAnnualSc + annualDiagnoseChangeSc;

      const newDiagnose = Math.round(diagnosedAnnual - (previousDiagnose * (1 - (this.mortalityRatio / 100))));
      const newDiagnoseSc = Math.round(diagnosedAnnualSc - (previousDiagnoseSc * (1 - (this.mortalityRatio / 100))));

      plwhaData.push(Math.round(plwhaAnnual));
      plwhaDataSc.push(Math.round(plwhaAnnualSc));

      diagnosedData.push(Math.round(diagnosedAnnual));
      diagnosedDataSc.push(Math.round(diagnosedAnnualSc));

      previousDiagnose = diagnosedAnnual;
      previousDiagnoseSc = diagnosedAnnualSc;

      newDiagnosisData.push(Math.round(newDiagnose));
      newDiagnosisDataSc.push(Math.round(newDiagnoseSc));
    }

    for (let index = 0; index < this.years; index++) {
      percentageDiagnoseData.push(this.percentageKnowStatus);
      percentageOnartData.push(Math.round(onArtData[index] / diagnosedData[index] * 100));
      percentageViralLoadData.push(Math.round(diagnosedData[index] / plwhaData[index] * 100));
      percentagePlwhaData.push(Math.round(viralLoadData[index] / plwhaData[index] * 100));

      percentageDiagnoseDataSc.push(this.percentageKnowStatus);
      percentageOnartDataSc.push(Math.round(onArtDataSc[index] / diagnosedDataSc[index] * 100));
      percentageViralLoadDataSc.push(Math.round(diagnosedDataSc[index] / plwhaDataSc[index] * 100));
      percentagePlwhaDataSc.push(Math.round(viralLoadDataSc[index] / plwhaDataSc[index] * 100));
    }

    let lastYear;

    years.forEach(year => {
      yearsPlus.push(year);
      lastYear = year;
    });

    this.startYear = (parseFloat(years[0])).toFixed(0);
    this.finishYear = lastYear;

    const xxxLabel = this.translate.instant('SIMULATION.UNAIDS.TARGET') + ' '
      + this.percentageKnowStatus + '-' + this.percentageArt + '-' + this.percentageViralSuppression;

    const yvaluesSum: string[] = [];
    yvaluesSum.push(this.translate.instant('SIMULATION.UNAIDS.CURRENT') + ' (' + this.startYear + ')');
    yvaluesSum.push(this.translate.instant('SIMULATION.CURRENT_PRACTICE') + ' (' + lastYear + ')');
    yvaluesSum.push(this.translate.instant('SIMULATION.SCENARIO') + ' (' + lastYear + ')');

    const yvaluesSumPer: string[] = [];
    yvaluesSumPer.push(this.translate.instant('SIMULATION.UNAIDS.CURRENT') + ' (' + this.startYear + ')');
    yvaluesSumPer.push(this.translate.instant('SIMULATION.CURRENT_PRACTICE') + ' (' + lastYear + ')');
    yvaluesSumPer.push(this.translate.instant('SIMULATION.SCENARIO') + ' (' + lastYear + ')');

    const yvalues: string[] = [];
    yvalues.push(this.translate.instant('SIMULATION.CURRENT_PRACTICE'));
    yvalues.push(this.translate.instant('SIMULATION.SCENARIO'));
    yvalues.push(xxxLabel);
    yvalues.push(this.translate.instant('SIMULATION.UNAIDS.TARGET') +
      ' ' + this.translate.instant('SIMULATION.UNAIDS.NINE_NINE_NINE'));

    const yvaluesPer: string[] = [];
    yvaluesPer.push(this.translate.instant('SIMULATION.CURRENT_PRACTICE'));
    yvaluesPer.push(this.translate.instant('SIMULATION.SCENARIO'));
    yvaluesPer.push(xxxLabel);
    yvaluesPer.push(this.translate.instant('SIMULATION.UNAIDS.TARGET') +
      ' ' + this.translate.instant('SIMULATION.UNAIDS.NINE_NINE_NINE'));

    const diagnosedSumPerData: number[] = [];
    diagnosedSumPerData.push(Math.round(viralLoadStart / onartStart * 100));
    diagnosedSumPerData.push(percentageDiagnoseData[percentageDiagnoseData.length - 1]);
    diagnosedSumPerData.push(percentageDiagnoseDataSc[percentageDiagnoseDataSc.length - 1]);

    const onArtSumPerData: number[] = [];
    onArtSumPerData.push(Math.round(onartStart / diagnosedStart * 100));
    onArtSumPerData.push(percentageOnartData[percentageOnartData.length - 1]);
    onArtSumPerData.push(percentageOnartDataSc[percentageOnartDataSc.length - 1]);

    const viralSuppSumPerData: number[] = [];
    viralSuppSumPerData.push(Math.round(diagnosedStart / plwhStart * 100));
    viralSuppSumPerData.push(percentageViralLoadData[percentageViralLoadData.length - 1]);
    viralSuppSumPerData.push(percentageViralLoadDataSc[percentageViralLoadDataSc.length - 1]);

    this.chartSumPerOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.UNAIDS.FIRST') + ' ' + this.diagnosedPercentage + '%',
          type: 'column',
          data: diagnosedSumPerData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.SECOND') + ' ' + this.onArtPercentage + '%',
          type: 'column',
          data: onArtSumPerData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.THIRD') + ' ' + this.viralSuppressionPercentage + '%',
          type: 'column',
          data: viralSuppSumPerData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yvaluesSumPer
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PERCENTAGE')
        },
        max: 100
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    const hivSumData: number[] = [];
    hivSumData.push(plwhStart);
    hivSumData.push(plwhTarget);
    hivSumData.push(plwhTargetSc);

    const diagnosedSumData: number[] = [];
    diagnosedSumData.push(diagnosedStart);
    diagnosedSumData.push(diagnosedTarget);
    diagnosedSumData.push(diagnosedTargetSc);

    const onArtSumData: number[] = [];
    onArtSumData.push(onartStart);
    onArtSumData.push(onartTarget);
    onArtSumData.push(onartTargetSc);

    const viralSuppSumData: number[] = [];
    viralSuppSumData.push(viralLoadStart);
    viralSuppSumData.push(viralLoadTarget);
    viralSuppSumData.push(viralLoadTargetSc);

    this.chartSumOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.UNAIDS.HIV_PLUS'),
          type: 'column',
          data: hivSumData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.DIAGNOSED'),
          type: 'column',
          data: diagnosedSumData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.ART'),
          type: 'column',
          data: onArtSumData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.VIRAL_SUPPRESSION'),
          type: 'column',
          data: viralSuppSumData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yvaluesSum
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.UNAIDS.NO_PATIENTS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    const green = '#1cbd00';
    const red = '#8b0000';
    this.chartRadialFirstSummaryOptions = {
      series: [this.percentageKnowStatus],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        colors: [this.percentageKnowStatus >= 90 ? green : red],
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    this.chartRadialSecondSummaryOptions = {
      series: [this.percentageArt],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        colors: [this.percentageArt >= 90 ? green : red],
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    this.chartRadialThirdSummaryOptions = {
      series: [this.percentageViralSuppression],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [this.percentageViralSuppression >= 90 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    const firstValue = percentageDiagnoseData[percentageDiagnoseData.length - 1];
    const secondValue = percentageOnartData[percentageOnartData.length - 1];
    const thirdValue = percentageViralLoadData[percentageViralLoadData.length - 1];
    const fourthValue = percentagePlwhaData[percentagePlwhaData.length - 1];

    const firstValueSc = percentageDiagnoseDataSc[percentageDiagnoseDataSc.length - 1];
    const secondValueSc = percentageOnartDataSc[percentageOnartDataSc.length - 1];
    const thirdValueSc = percentageViralLoadDataSc[percentageViralLoadDataSc.length - 1];
    const fourthValueSc = percentagePlwhaDataSc[percentagePlwhaDataSc.length - 1];

    this.chartRadialFirstBaselineOptions = {
      series: [firstValue],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [firstValue >= 90 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      },
      labels: ['Average Results']
    };

    this.chartRadialSecondBaselineOptions = {
      series: [secondValue],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [secondValue >= 90 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      },
      labels: ['Average Results']
    };

    this.chartRadialThirdBaselineOptions = {
      series: [thirdValue],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [thirdValue >= 90 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    this.chartRadialViralBaselineOptions = {
      series: [fourthValue],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [fourthValue >= 73 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    this.chartRadialFirstScenarioOptions = {
      series: [firstValueSc],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [firstValueSc >= 90 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    this.chartRadialSecondScenarioOptions = {
      series: [secondValueSc],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [secondValueSc >= 90 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    this.chartRadialThirdScenarioOptions = {
      series: [thirdValueSc],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [thirdValueSc >= 90 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    this.chartRadialViralScenarioOptions = {
      series: [fourthValueSc],
      chart: {
        type: 'radialBar',
        offsetY: -20
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      fill: {
        colors: [fourthValueSc >= 73 ? green : red],
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        }
      }
    };

    const newDiagnosisDataTmp: string[] = [];
    const newDiagnosisIprDataTmp: string[] = [];

    newDiagnosisData.forEach(data => {
      newDiagnosisDataTmp.push(data.toFixed(0));
    });

    newDiagnosisDataSc.forEach(data => {
      newDiagnosisIprDataTmp.push(data.toFixed(0));
    });

    this.chartDiagnosisOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: newDiagnosisDataTmp
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: newDiagnosisIprDataTmp
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.UNAIDS.NO_PATIENTS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    const naiveDemandDataTmp: string[] = [];
    const naiveDemandIprDataTmp: string[] = [];

    naiveDemandData.forEach(data => {
      naiveDemandDataTmp.push(data.toFixed(0));
    });

    naiveDemandDataSc.forEach(data => {
      naiveDemandIprDataTmp.push(data.toFixed(0));
    });

    this.chartOnArtOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: naiveDemandDataTmp
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: naiveDemandIprDataTmp
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.UNAIDS.NO_PATIENTS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    const diagnosedLastData: number[] = [];
    diagnosedLastData.push(Math.round(diagnosedData[diagnosedData.length - 1]));
    diagnosedLastData.push(Math.round(diagnosedDataSc[diagnosedDataSc.length - 1]));
    diagnosedLastData.push(Math.round(diagnosedTarget));
    const diagnosed909090 = plwhaData[plwhaData.length - 1] * 0.9;
    diagnosedLastData.push(Math.round(diagnosed909090));

    const onArtLastData: number[] = [];
    onArtLastData.push(Math.round(onArtData[onArtData.length - 1]));
    onArtLastData.push(Math.round(onArtDataSc[onArtDataSc.length - 1]));
    onArtLastData.push(Math.round(onartTarget));
    const onArt909090 = diagnosed909090 * 0.9;
    onArtLastData.push(Math.round(onArt909090));

    const viralLastData: number[] = [];
    viralLastData.push(Math.round(viralLoadData[viralLoadData.length - 1]));
    viralLastData.push(Math.round(viralLoadDataSc[viralLoadDataSc.length - 1]));
    viralLastData.push(Math.round(viralLoadTarget));
    const viral909090 = onArt909090 * 0.9;
    viralLastData.push(Math.round(viral909090));

    this.chartPatientsLastYearOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.UNAIDS.DIAGNOSED'),
          type: 'column',
          data: diagnosedLastData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.ART'),
          type: 'column',
          data: onArtLastData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.VIRAL_SUPPRESSION'),
          type: 'column',
          data: viralLastData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yvalues
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.UNAIDS.NO_PATIENTS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    const diagnosedLastPerData: number[] = [];
    diagnosedLastPerData.push(this.percentageKnowStatus);
    diagnosedLastPerData.push(this.percentageKnowStatus);
    diagnosedLastPerData.push(this.percentageKnowStatus);
    diagnosedLastPerData.push(Math.round(diagnosed909090 / plwhaData[plwhaData.length - 1] * 100));

    const onArtLastPerData: number[] = [];
    onArtLastPerData.push(this.percentageArt);
    onArtLastPerData.push(this.percentageArt);
    onArtLastPerData.push(this.percentageArt);
    onArtLastPerData.push(Math.round(onArt909090 / diagnosed909090 * 100));

    const viralLastPerData: number[] = [];
    viralLastPerData.push(this.percentageViralSuppression);
    viralLastPerData.push(this.percentageViralSuppression);
    viralLastPerData.push(this.percentageViralSuppression);
    viralLastPerData.push(Math.round(viral909090 / onArt909090 * 100));

    const viralVsPlwhaPerData: number[] = [];
    viralVsPlwhaPerData.push(percentagePlwhaData[percentagePlwhaData.length - 1]);
    viralVsPlwhaPerData.push(percentagePlwhaDataSc[percentagePlwhaDataSc.length - 1]);
    viralVsPlwhaPerData.push(Math.round(this.diagnosedPercentage * this.onArtPercentage
      * this.viralSuppressionPercentage / 10000));
    viralVsPlwhaPerData.push(this.percentageKnowStatus);

    this.chartPatientsLastYearPerOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.UNAIDS.FIRST') + ' ' + this.diagnosedPercentage + '%',
          type: 'column',
          data: diagnosedLastPerData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.SECOND') + ' ' + this.onArtPercentage + '%',
          type: 'column',
          data: onArtLastPerData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.THIRD') + ' ' + this.viralSuppressionPercentage + '%',
          type: 'column',
          data: viralLastPerData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.PLWHA_VS'),
          type: 'column',
          data: viralVsPlwhaPerData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yvaluesPer
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PERCENTAGE')
        },
        max: 100
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartBaselineOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.UNAIDS.PLWHA'),
          type: 'column',
          data: plwhaData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.DIAGNOSED'),
          type: 'column',
          data: diagnosedData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.ART'),
          type: 'column',
          data: onArtData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.VIRAL_SUPPRESSION'),
          type: 'column',
          data: viralLoadData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },

      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yearsPlus
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.UNAIDS.NO_PATIENTS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },

      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartScenarioOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.UNAIDS.PLWHA'),
          type: 'column',
          data: plwhaDataSc
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.DIAGNOSED'),
          type: 'column',
          data: diagnosedDataSc
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.ART'),
          type: 'column',
          data: onArtDataSc
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.VIRAL_SUPPRESSION'),
          type: 'column',
          data: viralLoadDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yearsPlus
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.UNAIDS.NO_PATIENTS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartTargetCurrentPracticePerOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.UNAIDS.FIRST') + ' ' + this.diagnosedPercentage + '%',
          type: 'column',
          data: percentageDiagnoseData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.SECOND') + ' ' + this.onArtPercentage + '%',
          type: 'column',
          data: percentageOnartData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.THIRD') + ' ' + this.viralSuppressionPercentage + '%',
          type: 'column',
          data: percentageViralLoadData
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.PLWHA_VS'),
          type: 'column',
          data: percentagePlwhaData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yearsPlus
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PERCENTAGE')
        },
        max: 100
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartTargetScenarioPerOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.UNAIDS.FIRST') + ' ' + this.diagnosedPercentage + '%',
          type: 'column',
          data: percentageDiagnoseDataSc
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.SECOND') + ' ' + this.onArtPercentage + '%',
          type: 'column',
          data: percentageOnartDataSc
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.THIRD') + ' ' + this.viralSuppressionPercentage + '%',
          type: 'column',
          data: percentageViralLoadDataSc
        },
        {
          name: this.translate.instant('SIMULATION.UNAIDS.PLWHA_VS'),
          type: 'column',
          data: percentagePlwhaDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yearsPlus
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PERCENTAGE')
        },
        max: 100
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

  }

  getArray(mapArray: Array<SimulationResultUnaidsResponse>, type: string, baseline: boolean): Map<string, Array<Pair>>[] {
    const returnArrayMap: Map<string, Array<Pair>>[] = [];
    const arraySize = Math.floor(mapArray.length / 2);
    let startIndex = 0;
    let finishIndex = arraySize;
    if (!baseline) {
      startIndex = startIndex + arraySize;
      finishIndex = finishIndex + arraySize;
    }
    for (let i = startIndex; i < finishIndex; i++) {
      if (type === 'patient') {
        returnArrayMap.push(mapArray[i].yearlyNetPatientMap);
      } else if (type === 'viral_load') {
        returnArrayMap.push(mapArray[i].viralLoadPatientMap);
      } else if (type === 'demand') {
        returnArrayMap.push(mapArray[i].visitsMapByDemand);
      } else if (type === 'averted') {
        returnArrayMap.push(mapArray[i].yearlyAvertedMap);
      }
    }
    return returnArrayMap;
  }

  getAverage(mapArray: Map<string, Array<Pair>>[]): Map<string, Array<Pair>> {
    const averageMap: Map<string, Array<Pair>> = new Map<string, Array<Pair>>();
    const mapSize = mapArray.length;
    mapArray.forEach(map => {
      const ttt = new Map(Object.entries(map));
      for (const key of ttt.keys()) {
        if (averageMap.get(key)) {
          const averagePairs: Pair[] = averageMap.get(key);
          const newPairs: Pair[] = [];
          const pairs: Pair[] = ttt.get(key);
          for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i];
            const averagePair = averagePairs[i];
            const second = averagePair.second + pair.second / mapSize;
            newPairs.push({ first: pair.first, second: parseFloat(second.toFixed(2)) });
          }
          averageMap.set(key, newPairs);
        } else {
          const newPairs: Pair[] = [];
          const pairs: Pair[] = ttt.get(key);
          pairs.forEach(pair => {
            newPairs.push({ first: pair.first, second: parseFloat((pair.second / mapSize).toFixed(2)) });
          });
          averageMap.set(key, newPairs);
        }
      }
    });
    return averageMap;
  }

}
