export class FilterListData {
    level: string;
    countries: Array<string>;
    states: Array<string>;
    cities: Array<string>;
    organizations: Array<string>;
    centers: Array<string>;
    filterList: Array<FilterData>;
}

export class FilterListDataExtended {
    filterListData: FilterListData;
    allCountries: Array<SummaryData>;
    selectedCountries: Array<SummaryData>;
    allStates: Array<SummaryData>;
    selectedStates: Array<SummaryData>;
    allCities: Array<SummaryData>;
    selectedCities: Array<SummaryData>;
    allOrganizations: Array<SummaryData>;
    selectedOrganizations: Array<SummaryData>;
    allCenters: Array<SummaryData>;
    selectedCenters: Array<SummaryData>;
}

export class FilterData {
    name: string;
    field: string;
    selected: boolean;
}

export class TrialistCountryResult {

    continentalDataList: Array<ContinentalData>;

    africaDataList: Array<CountryData>;
    asiaDataList: Array<CountryData>;
    europeDataList: Array<CountryData>;
    oceaniaDataList: Array<CountryData>;
    northAmericaDataList: Array<CountryData>;
    southAmericaDataList: Array<CountryData>;

}
export class ContinentalData {
    id: string;
    value: string;
    link: string;
}
export class CountryData {
    id: string;
    value: string;
}

export class TrialistSummaryResult {

    total: number;

    countries: Array<SummaryData>;
    states: Array<SummaryData>;
    cities: Array<SummaryData>;
    organizations: Array<SummaryData>;
    centers: Array<SummaryData>;

    summaryDataList: Array<SummaryData>;

    suppressedDataList: Array<SummaryData>;
    enrolledDataList: Array<SummaryData>;
    comorbiditiesDataList: Array<SummaryData>;
    antiretroviralDataList: Array<SummaryData>;
    comedicationDataList: Array<SummaryData>;

    dataListMap: Map<string, Array<SummaryData>>;
    dataMapper: Map<string, Map<string, Map<string, number>>>;

    mapTreatmentStatus: Map<string, Map<string, number>>;

    mapAdherence: Map<string, Map<string, number>>;
    mapSuppressedStatus: Map<string, Map<string, number>>;
    mapRace: Map<string, Map<string, number>>;
    mapGender: Map<string, Map<string, number>>;
    mapType: Map<string, Map<string, number>>;
}

export class SummaryData {
    id: number;
    name: string;
    count: number;
}
