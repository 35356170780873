<div class="title-container">
    <span>{{'AUDITLYTICS_PERMISSION.DETAIL.TITLE' | translate}}</span>
    <small>{{'ENUM.PAGE_MODE.' + PageMode[data.pageMode] | translate}}</small>
</div>

<mat-dialog-content>
    <form [formGroup]="formGroup" autocomplete="on" novalidate>
        <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'AUDITLYTICS_PERMISSION.DETAIL.LBL.USER' | translate }}</mat-label>
                <input matInput formControlName="userFullName">
                <button *ngIf = "data.pageMode === PageMode.CREATE" matSuffix mat-icon-button aria-label="Open user selection dialog" (click)="openUserSelectionDialog()">
                    <mat-icon>person_search</mat-icon>
                </button>
            </mat-form-field>
            <app-validation-message [control]="formGroup.get('userFullName')"></app-validation-message>
        </div>
       
        <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'AUDITLYTICS_PERMISSION.DETAIL.LBL.PERMISSION_LEVEL' | translate }}</mat-label>
                <mat-select placeholder="{{ 'AUDITLYTICS_PERMISSION.DETAIL.LBL.PERMISSION_LEVEL' | translate }}"
                    formControlName="permissionLevel">
                    <mat-option *ngFor="let pl of permissionLevelOptions" [value]="pl">
                        {{ 'ENUM.AUDITLYTICS_PERMISSION_LEVEL.' + pl | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <app-validation-message [control]="formGroup.get('permissionLevel')"></app-validation-message>
        </div>

        <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'AUDITLYTICS_PERMISSION.DETAIL.LBL.ORGANISATION_BRANCH_ID' | translate }}</mat-label>
                <input matInput formControlName="organisationBranchIds">
                <button *ngIf="orgasationBranchSelectionButtonEnable" matSuffix mat-icon-button aria-label="Open organisation branch selection dialog" (click)="openOrganisationBranchSelectionDialog()">
                    <mat-icon>saved_search</mat-icon>
                </button>
            </mat-form-field>
            <app-validation-message [control]="formGroup.get('organisationBranchIds')"></app-validation-message>
        </div>
    </form>
    
</mat-dialog-content>


<mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit" class="btn-lg"
        *ngIf="data.pageMode === PageMode.CREATE" (click)="onCreate()" [disabled]="!formGroup.valid">
        {{ 'GENERAL.CREATE' | translate }}</button>

    <button mat-raised-button color="warn" type="submit" class="btn-lg" *ngIf="data.pageMode === PageMode.EDIT"
        (click)="onEdit()" [disabled]="!formGroup.valid">
        {{ 'GENERAL.EDIT' | translate }}</button>
    <button mat-raised-button color="warn" type="submit" class="btn-lg" *ngIf="data.pageMode === PageMode.DELETE"
        (click)="onDelete()">
        {{ 'GENERAL.DELETE' | translate }}</button>
    <button mat-raised-button color="basic" class="btn-lg m-l-10" (click)="closeDialog(false)">
        {{ 'GENERAL.CANCEL' | translate }}</button>
</mat-dialog-actions>