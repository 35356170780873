import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthenticationGuardService } from 'src/app/shared/services/guard/authentication-guard.service';
import { UserEnabledGuardService } from 'src/app/shared/services/guard/user-enabled-guard.service';
import { Dhis2MainComponent } from './pages/dhis2-main/dhis2-main.component';
import { OpenmrsMainComponent } from './pages/openmrs-main/openmrs-main.component';

const routes: Routes = [
  {
    path: 'dhis2-main',
    component: Dhis2MainComponent,
    canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard],
  },
  {
    path: 'openmrs-main',
    component: OpenmrsMainComponent,
    canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ThirdPartyRoutingModule { }
