import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdverseEventListComponent } from './pages/adverse-event-list/adverse-event-list.component';
import { ArtCodeListComponent } from './pages/art-code-list/art-code-list.component';
import { AuditlyticsPermissionListComponent } from './pages/auditlytics-permission-list/auditlytics-permission-list.component';
import { ClinicianPatientListComponent } from './pages/clinician-patient-list/clinician-patient-list.component';
import { CustomCityListComponent } from './pages/custom-city-list/custom-city-list.component';
import { DrugInteractionListComponent } from './pages/drug-interaction-list/drug-interaction-list.component';
import { FeedbackListComponent } from './pages/feedback-list/feedback-list.component';
import { InvitationListAdminComponent } from './pages/invitation-list-admin/invitation-list-admin.component';
import { KinPatientListComponent } from './pages/kin-patient-list/kin-patient-list.component';
import { MutationListComponent } from './pages/mutation-list/mutation-list.component';
import { SubscriptionPlanListComponent } from './pages/subscription-plan-list/subscription-plan-list.component';
import { SuppressionListComponent } from './pages/suppression-list/suppression-list.component';
import { SurveyResultListComponent } from './pages/survey-result-list/survey-result-list.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { AtRiskListComponent } from './pages/at-risk-list/at-risk-list.component';

const routes: Routes = [
  {
    path: 'suppression',
    children: [
      {
        path: 'suppression-list',
        component: SuppressionListComponent
      },
      {
        path: 'art-code-list',
        component: ArtCodeListComponent
      },
      {
        path: 'mutation-list',
        component: MutationListComponent
      },
      {
        path: 'drug-interaction-list',
        component: DrugInteractionListComponent
      },
      {
        path: 'adverse-event-list',
        component: AdverseEventListComponent
      }
    ]
  },
  {
    path: 'survey',
    children: [
      {
        path: 'feedback-list',
        component: FeedbackListComponent
      },
      {
        path: 'survey-result-list',
        component: SurveyResultListComponent
      },
      {
        path: 'at-risk-list',
        component: AtRiskListComponent
      }
    ]
  },
  {
    path: 'subscription',
    children: [
      {
        path: 'plan-list',
        component: SubscriptionPlanListComponent
      }
    ]
  },
  {
    path: 'user',
    children: [
      {
        path: 'user-list',
        component: UserListComponent
      },
      {
        path: 'clinician-patient-list',
        component: ClinicianPatientListComponent
      },
      {
        path: 'kin-patient-list',
        component: KinPatientListComponent
      },
      {
        path: 'invitation-list',
        component: InvitationListAdminComponent
      },
      {
        path: 'auditlytics-permission-list',
        component: AuditlyticsPermissionListComponent
      }
    ]
  },
  {
    path: 'general',
    children: [
      {
        path: 'custom-city-list',
        component: CustomCityListComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministratorRoutingModule { }
