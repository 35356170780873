
<ng-container *ngIf="activeIndividualPlans">
  <div fxLayout="row wrap" fxLayoutAlign="center start" class="pricing-box">
    <div fxFlex.gt-sm="100" fxFlex="100">
  
      <div class="card-group text-center">
        <ng-container *ngFor="let plan of activeIndividualPlans; let i=index;">
  
          <mat-card class="pricing-plan {{plan.popular === true ? ' popular-plan' : ''}}">
            <mat-card-content>
              <span class="pop-tag" *ngIf="plan.popular === true">{{'GENERAL.POPULAR' | translate}}</span>
              <mat-card-title>{{plan.name}}</mat-card-title>
  
              <h1>{{plan.amount}}<sup>{{'ENUM.CURRENCY.' + plan.currency | translate}}</sup></h1>
              <mat-card-subtitle class="mat-text-primary">{{plan.intervalCount + ' ' + ('ENUM.INTERVAL.' + plan.interval | translate)}}
              </mat-card-subtitle>
  
              <mat-list class="price-listing">
                <span class="label label-success">{{ 'SUBSCRIPTION.PLAN.DETAIL.GENERAL.INDIVIDUAL_PLAN' | translate }}</span>
                <mat-list-item>{{maxClinicianCountText(plan)}}</mat-list-item>
                <mat-list-item>{{maxPatientCountText(plan)}}</mat-list-item>
                <mat-list-item>{{additionalUserAmountText(plan)}}</mat-list-item>
                <mat-list-item>{{renewableText(plan)}}</mat-list-item>
              </mat-list>
              <button (click)="subscribeUser(plan.id)" *ngIf="!plan.defaultPlan" mat-raised-button color="{{plan.popular === true ? 'warn' : 'primary'}}">{{'SUBSCRIPTION.PLAN.DETAIL.GENERAL.CHOOSE_PLAN' | translate}}</button>
            </mat-card-content>
  
          </mat-card>
  
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="activeOrganisationalPlans">
  <div fxLayout="row wrap" fxLayoutAlign="center start" class="pricing-box">
    <div fxFlex.gt-sm="100" fxFlex="100">
  
      <div class="card-group text-center">
        <ng-container *ngFor="let plan of activeOrganisationalPlans; let i=index;">
  
          <mat-card class="pricing-plan {{plan.popular === true ? ' popular-plan' : ''}}">
            <mat-card-content>
              <span class="pop-tag" *ngIf="plan.popular === true">{{'GENERAL.POPULAR' | translate}}</span>
              <mat-card-title>{{plan.name}}</mat-card-title>
  
              <h1>{{plan.amount}}<sup>{{'ENUM.CURRENCY.' + plan.currency | translate}}</sup></h1>
              <mat-card-subtitle class="mat-text-primary">{{plan.intervalCount + ' ' + ('ENUM.INTERVAL.' + plan.interval | translate)}}
              </mat-card-subtitle>
  
              <mat-list class="price-listing">
                <span class="label label-warning">{{ 'SUBSCRIPTION.PLAN.DETAIL.GENERAL.ORGANISATIOANAL_PLAN' | translate }}</span>
                <mat-list-item>{{maxClinicianCountText(plan)}}</mat-list-item>
                <mat-list-item>{{maxPatientCountText(plan)}}</mat-list-item>
                <mat-list-item>{{additionalUserAmountText(plan)}}</mat-list-item>
                <mat-list-item>{{renewableText(plan)}}</mat-list-item>
              </mat-list>
              <button (click)="subscribeOrganisation(plan.id)" *ngIf="clinicianOrganisationId && !plan.defaultPlan" mat-raised-button color="{{plan.popular === true ? 'warn' : 'primary'}}">{{'SUBSCRIPTION.PLAN.DETAIL.GENERAL.CHOOSE_PLAN' | translate}}</button>
            </mat-card-content>
  
          </mat-card>
  
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
