import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  count = 0;
  excludedUrl = [
    '/api/simulations/',
    '/api/ums/v1/invitations/waiting/count/',
    '/api/art-codes/upload/',
    '/api/suppressions/upload/',
    '/api/mutations/upload/',
    '/api/simulation-results/summary/',
    '/api/notification-svc/v1/push-notifications/me/chat-message/rooms',
    '/api/notification-svc/v1/push-notifications/send',
    '/api/notification-svc/v1/push-notifications/send-multicast',
    '/api/notification-svc/v1/push-notifications/me/unread-notification-count',
    '/api/notification-svc/v1/push-notifications/read-message'
  ];

  constructor(private spinnerService: NgxSpinnerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.excludedUrl.some(x => request.url.includes(x))) {
      return next.handle(request);
    } else {
      this.spinnerService.show();
      this.count++;
      return next.handle(request).pipe(
        finalize(() => {
          this.count--;
          if (this.count === 0) {
            this.spinnerService.hide();
          }
        })
      );
    }

  }
}
