<h1 mat-dialog-title>{{ 'SIMULATION.UNAIDS.HEADER' | translate }}</h1>
<div mat-dialog-content>
    <h4>
        {{ 'SIMULATION.CURRENT_PRACTICE' | translate }}
    </h4>
    <mat-card class="blue-background">
        <mat-card-header>
            <mat-card-title class="text-white">
                {{ 'SIMULATION.UNAIDS.PERCENTAGE' | translate }}
            </mat-card-title>
            <div fxFlex></div>
            <div fxLayoutAlign="center center">
                <mat-icon matTooltip="{{ 'SIMULATION.UNAIDS.INFO_TOOLTIP' | translate }}">info</mat-icon>
            </div>
        </mat-card-header>
        <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <mat-form-field fxFlex="30">
                    <input currencyMask [(ngModel)]="data.percentageKnowStatus"
                        [formControl]="percentageKnowStatusControl" maxlength="4"
                        [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                        matNativeControl placeholder="{{ 'SIMULATION.UNAIDS.KNOWN' | translate }}"
                        (keypress)="onChangeEvent()"
                        matTooltip="{{ 'SIMULATION.UNAIDS.KNOWN' | translate }}"  required/>
                    <mat-error *ngIf="percentageKnowStatusControl.invalid">{{ 'SIMULATION.UNAIDS.MIN_MAX_ERROR' |
                        translate }}</mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="30">
                    <input currencyMask [(ngModel)]="data.percentageArt" [formControl]="percentageArtControl"
                        maxlength="4"
                        [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                        matNativeControl placeholder="{{ 'SIMULATION.UNAIDS.ON_ART' | translate }}"
                        (keypress)="onChangeEvent()"
                        matTooltip="{{ 'SIMULATION.UNAIDS.ON_ART' | translate }}"  required/>
                    <mat-error *ngIf="percentageArtControl.invalid">{{ 'SIMULATION.UNAIDS.MIN_MAX_ERROR' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="30">
                    <input currencyMask [(ngModel)]="data.percentageViralSuppression"
                        [formControl]="percentageViralSuppressionControl" maxlength="4"
                        [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                        matNativeControl placeholder="{{ 'SIMULATION.UNAIDS.VIRAL' | translate }}"
                        (keypress)="onChangeEvent()"
                        matTooltip="{{ 'SIMULATION.UNAIDS.VIRAL' | translate }}"  required/>
                    <mat-error *ngIf="percentageViralSuppressionControl.invalid">{{ 'SIMULATION.UNAIDS.MIN_MAX_ERROR' |
                        translate }}</mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <mat-form-field fxFlex="30">
                    <input currencyMask [(ngModel)]="data.mortalityRatio" [formControl]="mortalityRatioControl"
                        maxlength="7"
                        [options]="{ thousands: ',', precision: '2', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                        matNativeControl placeholder="{{ 'SIMULATION.UNAIDS.MORTAL' | translate }}"
                        (keypress)="onChangeEvent()"
                        matTooltip="{{ 'SIMULATION.UNAIDS.MORTAL' | translate }}"  required/>
                    <mat-error *ngIf="mortalityRatioControl.invalid">{{ 'SIMULATION.UNAIDS.MIN_MAX_ERROR' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="30">
                    <input currencyMask [(ngModel)]="data.prevalenceRatio" [formControl]="prevalenceRatioControl"
                        maxlength="7"
                        [options]="{ thousands: ',', precision: '2', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                        matNativeControl placeholder="{{ 'SIMULATION.UNAIDS.PREVALENCE' | translate }}"
                        (keypress)="onChangeEvent()"
                        matTooltip="{{ 'SIMULATION.UNAIDS.PREVALENCE' | translate }}"  required/>
                    <mat-error *ngIf="prevalenceRatioControl.invalid">{{ 'SIMULATION.UNAIDS.MIN_MAX_ERROR' | translate }}</mat-error>
                </mat-form-field>
            </div>
        </mat-card-content>
    </mat-card>

    <h4>
        {{ 'SIMULATION.SCENARIO' | translate }}
    </h4>
    <mat-card class="pink-background">
        <mat-card-header>
            <mat-card-title class="text-white">
                {{ 'SIMULATION.UNAIDS.UNAIDS_TARGET' | translate }}
            </mat-card-title>
            <div fxFlex></div>
            <div fxLayoutAlign="center center">
                <mat-icon matTooltip="{{ 'SIMULATION.UNAIDS.INFO_TOOLTIP_UNAIDS_TARGET' | translate }}">info</mat-icon>
            </div>
        </mat-card-header>
        <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <mat-form-field fxFlex="60">
                    <mat-select placeholder="{{ 'SIMULATION.UNAIDS.TARGET' | translate }}" 
                        matTooltip="{{ 'SIMULATION.UNAIDS.INFO_TOOLTIP_TARGET' | translate }}"
                        [(ngModel)]="selectedChoice"
                        (selectionChange)="onSelectChoice($event.value)">
                        <mat-option *ngFor="let opt of choices" [value]="opt">{{opt}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <mat-form-field fxFlex="30">
                    <input matInput [(ngModel)]="data.scDemandExperiencedCalculated" [readonly]="true"
                        placeholder="{{ 'SIMULATION.UNAIDS.EXPERIENCED' | translate }}"
                        matTooltip="{{ 'SIMULATION.UNAIDS.EXPERIENCED' | translate }}" />
                </mat-form-field>
                <mat-form-field fxFlex="30">
                    <input matInput [(ngModel)]="data.scDemandNaiveCalculated" [readonly]="true"
                        placeholder="{{ 'SIMULATION.UNAIDS.NAIVE' | translate }}"
                        matTooltip="{{ 'SIMULATION.UNAIDS.NAIVE' | translate }}" />
                </mat-form-field>
                <mat-form-field fxFlex="30">
                    <input matInput [(ngModel)]="data.scNewDiagnosis" [readonly]="true"
                        placeholder="{{ 'SIMULATION.UNAIDS.NEW_DIAGNOSE' | translate }}"
                        matTooltip="{{ 'SIMULATION.UNAIDS.NEW_DIAGNOSE' | translate }}" />
                </mat-form-field>
            </div>
        </mat-card-content>
    </mat-card>
    <span>{{ 'SIMULATION.REQUIRED' | translate }}</span>
</div>

<div mat-dialog-actions>
    <button mat-button color="primary" (click)="onNoClick()">{{ 'SIMULATION.CANCEL' | translate }}</button>
    <button mat-button color="primary" (click)="onDefaultClick()">{{ 'SIMULATION.DEFAULT' | translate }}</button>
    <button mat-button [mat-dialog-close]="data" [disabled]="!isInfoValid()" cdkFocusInitial color="accent">{{ 'SIMULATION.OK' | translate }}</button>