/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { OrgUnit } from '../model/dhis2';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Dhis2Service {

  private baseUrl = '/dhis2/organization-units';

  constructor(private http: HttpClient) {
  }

  getOrgUnits(): Observable<OrgUnit[]> {
    return this.http.get<string[]>(environment.url + `${this.baseUrl}`)
      .pipe(map((res: any) => (res || [])
      ));
  }

}
