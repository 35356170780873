<mat-form-field *ngIf="control" [appearance]="formFieldAppearance" class="{{formFieldStyleClass}}">
  <mat-label>{{ label | translate }}</mat-label>
  <mat-select placeholder="{{ label | translate }}" [formControl]="control" (selectionChange)="onSelectionChange($event.value)">
    <mat-option>
      <ngx-mat-select-search ngModel
      [placeholderLabel]="searchLabel" 
      [noEntriesFoundLabel]="notFoundLabel"
      (ngModelChange)="filterValueChanged($event)"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let item of filteredItems | async" [value]="item[itemValueProperty]">

      <ng-container *ngIf="!itemTemplate">
        {{ item[itemDisplayProperty]}}
      </ng-container>

      <ng-container *ngIf="itemTemplate" >
        <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-container>
      </ng-container>

    </mat-option>
  </mat-select>
</mat-form-field>


<mat-form-field *ngIf="!control" [appearance]="formFieldAppearance" class="{{formFieldStyleClass}}">
  <mat-label>{{ label | translate }}</mat-label>
  <mat-select placeholder="{{ label | translate }}" (selectionChange)="onSelectionChange($event.value)">
    <mat-option>
      <ngx-mat-select-search ngModel
      [placeholderLabel]="searchLabel" 
      [noEntriesFoundLabel]="notFoundLabel"
      (ngModelChange)="filterValueChanged($event)"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let item of filteredItems | async" [value]="item[itemValueProperty]">

      <ng-container *ngIf="!itemTemplate">
        {{ item[itemDisplayProperty]}}
      </ng-container>

      <ng-container *ngIf="itemTemplate" >
        <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-container>
      </ng-container>

    </mat-option>
  </mat-select>
</mat-form-field>