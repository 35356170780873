/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { UserSummaryAdmin, UserFilterRequest } from '../model/user-account';
import { Page, PageableSort } from '../model/pagination';
import { createPageParams, createQueryParamString } from '../util';
import { ClinicianPatient } from '../model/clinician-patient';

@Injectable({
    providedIn: 'root'
})
export class UserAdminService {

    private baseUrl = '/ums/v1/admin-ops/users';

    constructor(private http: HttpClient) {
    }


    getUserByAdmin(userId: number): Observable<UserSummaryAdmin> {
        return this.http.get<UserSummaryAdmin>(environment.url + `${this.baseUrl}/${userId}`)
            .pipe(map((res: any) => res || {}));
    }

    updateUserByAdmin(userId: number, request: UserSummaryAdmin): Observable<UserSummaryAdmin> {
        return this.http.put<UserSummaryAdmin>(environment.url + `${this.baseUrl}/${userId}`, request)
            .pipe(map((res: any) => res || {}));
    }

    deleteUserByAdmin(userId: number): Observable<void> {
        return this.http.delete<void>(environment.url + `${this.baseUrl}/${userId}`);
    }

    getUsersByAdmin(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string, filterRequest?: UserFilterRequest): Observable<Page<UserSummaryAdmin>> {
        let queryParams = createPageParams(page, pageSize, sort, searchTerm);
        if (filterRequest) {
            queryParams = {...queryParams, ...filterRequest};
        }

        const queryParamsStr = createQueryParamString(queryParams);
        let url = `${environment.url}${this.baseUrl}`;
        url = queryParamsStr ? (url + '?' + queryParamsStr): url;
        return this.http.get<Page<UserSummaryAdmin>>(url, {})
            .pipe(map((res: any) => res || {}));
    }

    connectClinicianPatientByAdmin(clinicianId: number, patientId: number): Observable<ClinicianPatient> {
        return this.http.patch<ClinicianPatient>(environment.url + `${this.baseUrl}/connect/clinician/${clinicianId}/patient/${patientId}`, {})
            .pipe(map((res: any) => res || {}));
    }

    connectPatientToOrganisationBranchByAdmin(userId: number, organisationBranchId: number): Observable<UserSummaryAdmin> {
        return this.http.patch<UserSummaryAdmin>(environment.url + `${this.baseUrl}/connect/user/${userId}/organisation-branch/${organisationBranchId}/patient`, {})
            .pipe(map((res: any) => res || {}));
    }
    connectClinicianToOrganisationBranchByAdmin(userId: number, organisationBranchId: number): Observable<UserSummaryAdmin> {
        return this.http.patch<UserSummaryAdmin>(environment.url + `${this.baseUrl}/connect/user/${userId}/organisation-branch/${organisationBranchId}/clinician`, {})
            .pipe(map((res: any) => res || {}));
    }

}
