import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Plan, PlanListResponse } from '../model/subscription';
import { Page, Pageable, PageableSort } from '../model/pagination';
import { createPageParams } from '../util';

@Injectable({
    providedIn: 'root'
})
export class PlanService {

    private baseUrl = '/subscription-svc/v1/plans';

    constructor(private http: HttpClient) {
    }

    createPlan(plan: Plan): Observable<Plan> {
        return this.http.post<Plan>(environment.url + `${this.baseUrl}`, plan)
        .pipe(map((res: any) => res || {}));
    }

    updatePlan(id: number, plan: Plan): Observable<Plan> {
        return this.http.put<Plan>(environment.url + `${this.baseUrl}/${id}`, plan)
            .pipe(map((res: any) => res || {}));
    }

    deletePlan(id: number): Observable<void> {
        return this.http.delete<any>(environment.url + `${this.baseUrl}/${id}`);
    }


    getPlans(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<Plan>> {
        const pageParams = createPageParams(page, pageSize, sort, searchTerm);

        return this.http.get<Page<Plan>>(environment.url + `${this.baseUrl}/list`, { params: pageParams })
            .pipe(map((res: any) => (res || {})
            ));
    }

    getPlan(id: number): Observable<Plan> {
        return this.http.get<Plan>(environment.url + `${this.baseUrl}/view/${id}`)
            .pipe(map((res: any) => res || {}));
    }

    getActivePlans(): Observable<PlanListResponse> {
        return this.http.get<PlanListResponse>(environment.url + `${this.baseUrl}/list/active`)
            .pipe(map((res: any) => res || {}));
    }

}
