<div class="d-flex flex-column p-24 h-100p">
    <div class="d-flex align-items-center justify-content-between mb-16">
        <mat-card-title class="m-0">{{'ORGANISATION.OVERVIEW.ORGANISATION_BRANCH_TITLE' | translate}}</mat-card-title>
    <!-- <mat-card-content> -->
        <div class="add-new-container" *ngIf="pageMode === PageMode.EDIT">
            <button mat-raised-button color="primary" class="m-0"
                (click)="onCreateOrganisationBranch()">{{ 'ORGANISATION.OVERVIEW.BUTTON.ORGANISATION_BRANCH.CREATE' |
                translate }}</button>
        </div>
    </div>

        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100">
                <app-search-field [label]="'ORGANISATION_BRANCH.LIST.SEARCH_FIELD.LABEL'"
                    [hint]="'ORGANISATION_BRANCH.LIST.SEARCH_FIELD.HINT'"
                    (searchTermChanged)="onSearchTermChanged($event)">
                </app-search-field>
            </div>
            <div fxFlex.gt-md="25" class="text-right">
                <app-sort-field [fields]="sortFields" (sortChanged)="onSortChanged($event)">
                </app-sort-field>
            </div>
        </div>

        <div fxLayout="row wrap" class="flex-grow-1 overflow-auto">
            <div fxFlex.gt-lg="33.33" fxFlex.gt-md="33.33" fxFlex.gt-xs="100" fxFlex="100" *ngFor="let row of rows | paginate : {
                        itemsPerPage: page.size,
                        currentPage: page.pageNumber+1,
                        totalItems: page.totalElements
                        }; let i = index">
                <mat-card class="cust-darker-shadow">
                    <mat-card-content class="text-center">
                        <img [src]='getImageUrl(row)' class="img-circle border p-5 cust-img-max-width" height="100">
                        <h4 class="card-title m-t-15 m-b-0">{{ row.name}}</h4>
                        <small class="text-muted cust-text-truncate" matTooltip="{{row.city}}">{{row.city}}</small>
                        <div class="m-t-20">
                            <span style="word-wrap:break-word;"><i class="fa fa-phone m-r-10"></i>{{row.phone ?
                                row.phone : 'No Phone'}}</span>
                        </div>
                    </mat-card-content>
                    <div fxLayout="row wrap" class="text-center bg-light border-top" *ngIf="pageMode !== PageMode.VIEW">
                        <div fxFlex.gt-xs="50" fxFlex="50" class="p-t-10 p-b-10">
                            <a href="javascript:void(0)" title="Edit" class="link"
                                (click)="onEditOrganisationBranch(row)">
                                <i class="fa fa-pencil fa-2x m-r-10 m-t-0 m-b-5 text-info"></i>
                            </a>
                        </div>
                        <div fxFlex.gt-xs="50" fxFlex="50" class="p-t-10 p-b-10">
                            <a href="javascript:void(0)" title="Delete" class="link"
                                (click)="onDeleteOrganisationBranch(row)">
                                <i class="fa fa-trash fa-2x m-r-10 m-t-0 m-b-5 text-danger"></i>
                            </a>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>


        <div class="d-flex justify-content-center m-t-20">
            <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="setPageNumber($event)">
            </pagination-controls>
        </div>

    <!-- </mat-card-content> -->
</div>