import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { BaseComponent } from 'src/app/shared/component/base.component';
import { DEFAULT_USER_PHOTO } from 'src/app/shared/constants';
import { PushNotificationType } from 'src/app/shared/model/enum/push-notification-type';
import { Page, PageableSort } from 'src/app/shared/model/pagination';
import { ChatRoomUserResponse, NewChatRoom, PushNotification, PushNotificationMulticastRequest, PushNotificationRequest } from 'src/app/shared/model/push-notification';
import { UserAccount } from 'src/app/shared/model/user-account';
import { LoginResponse } from 'src/app/shared/model/user-profile';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { PushNotificationService } from 'src/app/shared/services/push-notification.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';

@Component({
  selector: 'app-chat-main',
  templateUrl: './chat-main.component.html',
  styleUrls: ['./chat-main.component.scss']
})
export class ChatMainComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('messageListContainer') messageListContainer: ElementRef;
  @ViewChildren('chatMessageElement') chatMessageElements: QueryList<any>;


  from: LoginResponse;
  fromId;
  me: UserAccount;
  selectedChatRoomUser: ChatRoomUserResponse;
  newChatRoom: NewChatRoom;

  pushNotificationList: PushNotification[];
  pushNotificationPage: Page<PushNotification>;

  sidePanelOpened = true;
  messageText = '';
  replyMessage = null;

  private readonly typingSubject = new Subject<string | undefined>();

  constructor(
    private translate: TranslateService,
    private notificationService: NotificationService,
    private authenticationStateService: AuthenticationStateService,
    private pushNotificationService: PushNotificationService
  ) {
    super();
  }

  ngOnInit(): void {

    const decodedToken = this.authenticationStateService.getDecodedToken();
    if (decodedToken) {
      this.fromId = +decodedToken.sub;
    }    

    const subsAuthStateChange = this.authenticationStateService.getLoginChangeObservable().subscribe(info => {
      if (info) {
        this.from = info;
      }
    });

    const subsTyping = this.typingSubject.pipe(debounceTime(500), distinctUntilChanged())
    .subscribe((text) => {
      this.sendTyping(text ? true: false);
    });

    const subsNotificationchange = this.pushNotificationService.getPushNotificationChangeObservable().subscribe(notification => {
      if (this.selectedChatRoomUser?.id == notification.fromId || this.selectedChatRoomUser?.id == notification.toId) {
        if (this.pushNotificationList?.length > 0) {
          const lastMessage = this.pushNotificationList[this.pushNotificationList.length - 1];
          if (lastMessage?.id !== notification.id) {
            this.pushNotificationList.push(notification);
          }
        } else {
          this.pushNotificationList.push(notification);
        }
        
        this.readPushNotificationsOfMyRoom();
      }
    });

    this.addAllSubscriptions([subsAuthStateChange, subsTyping, subsNotificationchange]);
  }

  ngAfterViewInit(): void {
    const subsElementChanges = this.chatMessageElements.changes.subscribe(() => {
      this.scrollToBottom();
    });
    this.subscription(subsElementChanges);
  }

  isOver(): boolean {
    return window.matchMedia(`(max-width: 960px)`).matches;
  }

  onEnter(event: Event) {
    if(this.messageText?.trim()) {
      this.onSendMessage();
      event.preventDefault();
    }
  }

  onTyping(event: Event) {
    const val = (event.target as HTMLInputElement).value;
    this.typingSubject.next(val);
  }

  onSendMessage() {
    if (this.messageText?.trim()) {
      if (!this.selectedChatRoomUser && !this.newChatRoom) {
        this.notificationService.error(this.translate.instant('You have to select a user to send message'));
        return;
      }

      if (this.selectedChatRoomUser) {
        this.sendMessage();
      }

      if (this.newChatRoom) {
        this.sendMulticastMessage();
      }
    }
  }

  sendMessage() {
    this.sendTyping(false);

    const req = new PushNotificationRequest();
    req.toId = this.selectedChatRoomUser.id;
    req.content = this.messageText;
    req.type = PushNotificationType[PushNotificationType.CHAT];

    this.pushNotificationService.sendChatMessage(req).subscribe(response => {
      this.messageText = '';
      this.replyMessage = null;
    }, error => {
      console.log('Error while sending chat message : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('Message could not send'));
    });
  }

  sendMulticastMessage() {
    const req = new PushNotificationMulticastRequest();
    req.toIds = this.newChatRoom.userList.map(u => u.id);
    req.content = this.messageText;
    req.type = PushNotificationType[this.newChatRoom.type];

    this.pushNotificationService.sendMulticastChatMessage(req).subscribe(response => {
      this.newChatRoom = null;
      this.messageText = '';
      this.replyMessage = null;
      if (response.failedUserIds?.length > 0) {
        this.notificationService.warn(this.translate.instant('Some of the users could not get your message'));
      }
    }, error => {
      console.log('Error while sending multicast chat message : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('Message could not send'));
    });
  }

  sendTyping(typing: boolean) {
    // TODO api call
  }

  getOrDefaultPhoto(photoUrl: string) {
    return photoUrl || DEFAULT_USER_PHOTO;
  }

  getChatRoomUserName() {
    if (this.selectedChatRoomUser) {
      return this.selectedChatRoomUser.firstName + ' ' + this.selectedChatRoomUser.lastName;
    }
    if (this.newChatRoom?.userList) {
      if (this.newChatRoom.type === PushNotificationType.NOTIFICATION) {
        return 'Notification (They will not be on chat user list)'
      }
      const userNames = this.newChatRoom.userList.map(user => user.firstName + ' ' + user.lastName);
      return userNames.join(',');
    }
    return '';
  }

  onNewChatRoom(newChatRoom: NewChatRoom) {
    this.selectedChatRoomUser = null;
    this.newChatRoom = newChatRoom;
    this.pushNotificationList = [];
    this.messageText = '';
  }

  onSelectedRoomChange(chatRoomUser: ChatRoomUserResponse) {
    this.selectedChatRoomUser = chatRoomUser;
    this.newChatRoom = null;
    this.pushNotificationList = [];
    this.messageText = '';

    const sort = new PageableSort({ property: 'createdAt', direction: 'DESC' });
    this.pushNotificationService
    .listPushNotificationsBetweenUsers(this.selectedChatRoomUser.id, 0, 1000, sort, '')
    .subscribe(response => {
      this.pushNotificationPage = response;
      this.pushNotificationList = response?.content?.reverse();
      this.readPushNotificationsOfMyRoom();
    }, error => {
        console.log('Error while sending chat message : ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('Message list could not load'));
    });
  }

  readPushNotificationsOfMyRoom() {
    this.pushNotificationService.readPushNotificationsOfMyRoom(this.selectedChatRoomUser.id).subscribe(response => {
      this.pushNotificationService.publishUnreadPushNotificationCount(response.unReadNotificationCount);
    }, error => {
      console.log('Error while readPushNotificationsOfMyRoom chat message : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('Room message read error'));
    });
  }

  scrollToBottom() {
    this.messageListContainer.nativeElement.scroll({
      top: this.messageListContainer.nativeElement.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

}
