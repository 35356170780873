<div *ngIf="suppressionList">
  <!-- <mat-card>
      <mat-card-content> -->
          <h2 class="content-box__title">{{'CLINICAL_OUTCOME.TITLE.EXPECTED_PATIENT_SUPPRESSION' | translate}}</h2>
          <div id="chartView">
              <apx-chart
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [xaxis]="chartOptions.xaxis"
                [stroke]="chartOptions.stroke"
                [colors]="chartOptions.colors"
                [dataLabels]="chartOptions.dataLabels"
                [responsive]="chartOptions.responsive"
                [legend]="chartOptions.legend"
                [markers]="chartOptions.markers"
                [grid]="chartOptions.grid"
                [yaxis]="chartOptions.yaxis"
                [title]="chartOptions.title"
              ></apx-chart>
            </div>
      <!-- </mat-card-content>
  </mat-card> -->
</div>
