/* eslint-disable max-len */
/* eslint-disable no-bitwise */
import { Role } from './model/enum/role';
import { PageableSort } from './model/pagination';

export function rolesToArray(roles: number): Role[] {

    const roleValues: string[] = Object.keys(Role);
    const roleOptions = roleValues.slice(roleValues.length / 2);
    const roleTypes = new Array<Role>();

    for (const rStr of roleOptions) {
        const role = Role[rStr];
        if ((role & roles) !== 0) {
            roleTypes.push(role);
        }
    }
    return roleTypes;
}

export function rolesToStringArray(roles: number): string[] {

    const roleValues: string[] = Object.keys(Role);
    const roleOptions = roleValues.slice(roleValues.length / 2);
    const roleTypes = new Array<string>();

    for (const rStr of roleOptions) {
        const role = Role[rStr];
        if ((role & roles) !== 0) {
            roleTypes.push(rStr);
        }
    }
    return roleTypes;
}

export function stringArrayToRoleValue(selectedRoles: string[]): number {
    let roleVal = 0;
    for (const rStr of selectedRoles) {
        const role = Role[rStr];
        roleVal = roleVal | role;
    }
    return roleVal;
}

export function userHasRole(roleValue: number, userRoleValue: number): boolean {
    return (roleValue & userRoleValue) !== 0;
}

export function getClinicianRelatedRoles(): Role[] {
    return [Role.CLINICIAN, Role.STANDALONE_CLINICIAN, Role.SMART_HIV_MANAGER, Role.SMART_HIV_TRIALIST, Role.SMART_HIV_AUDITLYTICS, Role.SMART_HIV_CLINICIAN];
}

export function convertToRoleStringArray(roles: Role[]): string[] {
    return roles.map(r => Role[r]);
}

export function hasUserAnyClinicianRelatedRole(userRoleValue: number): boolean {
    const clinicianRoles = getClinicianRelatedRoles();
    const foundClinicianRole = clinicianRoles.find(c => userHasRole(c, userRoleValue));
    return foundClinicianRole ? true : false;
}

export function createPageParams(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string) {
    const params: any = {};

    if (searchTerm) {
        params.searchTerm = searchTerm;
    }
    if (pageSize) {
        params.size = pageSize;
    }
    if (page) {
        params.page = page;
    }
    if (sort) {
        params.sort = sort.property + ',' + sort.direction;
    }
    return params;
}

export function createQueryParamString(obj) {
    const str = [];
    for (const p in obj) {
        if (obj.hasOwnProperty(p) && obj[p]) {
            let val = encodeURIComponent(obj[p]);
            if (isArray(obj[p])) {
                val = obj[p].map(v => encodeURIComponent(v)).join(',');
            }
            str.push(encodeURIComponent(p) + '=' + val);
        }
    }
    return str.join('&');
}

export function objectCopy(from: any, to: any): any {
    const fromKeys: string[] = Object.keys(from);
    for (const k of fromKeys) {
        to[k] = from[k];
    }
    return to;
}

export function isEmpty(obj: any): boolean {
    return isNull(obj)
        || isUndefined(obj)
        || (isString(obj) && obj === '');
}

export function isUndefined(value: any): value is undefined {
    return typeof value === 'undefined';
}

export function isNull(value: any): value is null {
    return value === null;
}

export function isString(value: any): value is string {
    return typeof value === 'string';
}

export function isObject(value: any): boolean {
    return value !== null && typeof value === 'object';
}

export function isArray(value: any): boolean {
    return Array.isArray(value);
}

export function downloadFile(fileName, contentType, dataResult) {
    const newBlob = new Blob([dataResult], { type: contentType });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator) {
      const nav = (window.navigator as any);
      if (nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
        return;
      }
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    // link.target = '_blank';
    link.href = data;
    link.download = fileName;
    document.body.appendChild(link);

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout( y => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}
