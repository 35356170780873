/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { OrganisationBranch, OrganisationBranchFilterRequest } from '../../model/organisation';
import { OrganisationBranchService } from '../../services/organisation-branch.service';

@Component({
  selector: 'app-organisation-branch-selection-dialog',
  templateUrl: './organisation-branch-selection-dialog.component.html',
  styleUrls: ['./organisation-branch-selection-dialog.component.scss']
})
export class OrganisationBranchSelectionDialogComponent extends BaseListComponent implements OnInit {

  @ViewChild('rowNumberTemplate', { static: true }) rowNumberTemplate: TemplateRef<any>;
  @ViewChild('countryStateCityTemplate', { static: true }) countryStateCityTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate', { static: true }) actionTemplate: TemplateRef<any>;


  constructor(
    public dialogRef: MatDialogRef<OrganisationBranchSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private organisationBranchService: OrganisationBranchService,
    public dialog: MatDialog,
  ) {
    super();
   }

  ngOnInit(): void {

    this.allColumnList = [
      { prop: 'id', name: '#', maxWidth: 50, cellClass: '', cellTemplate: this.rowNumberTemplate, sortable: false, draggable: false },
      { prop: 'name', name: this.translate.instant('Name'), cellClass: '', draggable: false},
      { prop: 'title', name: this.translate.instant('Title'), cellClass: '', draggable: false},
      { prop: 'organisation.name', name: this.translate.instant('Organisation'), cellClass: '', draggable: false},
      { prop: 'csc', name: this.translate.instant('Country/State/City'), cellTemplate: this.countryStateCityTemplate, sortable: false, draggable: false}
    ];

    this.columns = [...this.allColumnList];

    this.rowSelectionType = this.data.rowSelectionType ?  this.data.rowSelectionType : 'single';
    this.selectedRows = this.data.defaultRowSelections ?  this.data.defaultRowSelections : [];

    this.loadData();
  }

  loadData() {
    let filterRequest = new OrganisationBranchFilterRequest();
    if (this.data.defaultFilter) {
      filterRequest = {...this.data.defaultFilter, ...filterRequest};
    }
    this.organisationBranchService.getOrganisationBranches(this.page.pageNumber, this.page.size, this.sort, this.searchTerm, filterRequest).subscribe(page => {
      this.page.pageNumber = page.number;
      this.page.totalElements = page.totalElements;
      this.page.size = page.size;
      this.rows = page.content;

    });
  }

  onSelect() {
    this.dialogRef.close({selectedRows: this.selectedRows});
  }

}
