<div class="content-box mb-24 overflow-auto" *ngIf="columnDefs">
    <mat-table #table [dataSource]="dataSource">

        <ng-container *ngFor="let column of columnDefs" [matColumnDef]="column.name">
            <mat-header-cell *matHeaderCellDef class="cust-column-header"> {{ column.displayName | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let row">
                <span class="header-label">{{ column.displayName ? (column.displayName | translate) + ':' : '' }}</span>
                <span [ngClass]="column.class">{{column.formatCellValue(row, column.name) }}</span>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>