<div class="content-box chart">
  <h3 class="content-box__title">{{ 'DHIS2.TITLE' | translate }}</h3>
  
  <div class="add-new-container">
  </div>

  <app-datatable
    [allColumnList]="allColumnList"
    [columns]="columns"
    [rows]="rows"
    [page]="page"
    >
    >
  </app-datatable>
</div>
