/* eslint-disable @typescript-eslint/ban-types */
import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Page, Pageable, PageableSort} from '../model/pagination';
import {map} from 'rxjs/operators';
import {createPageParams} from '../util';
import {Mutation, MutationModelResponse} from '../model/mutation';
import {MutationRequest, MutationResponse} from '../model/clinical-outcome';

@Injectable({
  providedIn: 'root'
})
export class MutationService {

  private baseUrl = '/mutations';

  constructor(private http: HttpClient) {
  }

  uploadFile(file: File): Observable<HttpEvent<{}>> {
    const fileAlias = 'file';
    const formData = new FormData();
    formData.set(fileAlias, file, file.name);

    let myheaders = new HttpHeaders();
    myheaders = myheaders.append('Accept', 'application/json');
    myheaders = myheaders.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(environment.url + `${this.baseUrl}/upload`, formData, {
      observe: 'events',
      reportProgress: true,
      responseType: 'json'
    });
  }

  getMutations(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<Mutation>> {
    const pageParams = createPageParams(page, pageSize, sort, searchTerm);

    return this.http.get<Page<Mutation>>(environment.url + `${this.baseUrl}`, {params: pageParams})
      .pipe(map((res: any) => (res || {})
      ));
  }


  getMutationClasses(): Observable<string[]> {
    return this.http.get<string[]>(environment.url + `${this.baseUrl}/unique-classes`)
      .pipe(map((res: any) => (res || [])
      ));
  }

  getMutationsData(request: string[]): Observable<string[]> {
    return this.http.post<string[]>(environment.url + `${this.baseUrl}/get-mutations/`,request)
      .pipe(map((res: any) => (res || [])
      ));
  }

  getMutationsModelData(request: string[]): Observable<MutationModelResponse[]> {
    return this.http.post<MutationModelResponse[]>(environment.url + `${this.baseUrl}/get-mutations-models/`,request)
      .pipe(map((res: any) => (res || [])
      ));
  }

  getMutationsResults(request: MutationRequest): Observable<MutationResponse[]> {
    return this.http.post<MutationResponse[]>(environment.url + `${this.baseUrl}/get-results-new/`,request)
      .pipe(map((res: any) => (res || [])
      ));
  }



}
