<div class="content-box chart pb-0">
    <div class="d-flex align-items-center justify-content-between pb-16">
        <h3 class="content-box__title mb-0">{{'AUDITLYTICS_PERMISSION.LIST.TITLE' | translate}}</h3>
        <button mat-raised-button color="primary" (click)="openDetailDialog(PageMode.CREATE, null)">{{'AUDITLYTICS_PERMISSION.LIST.BUTTON.CREATE' | translate}}</button>
    </div>
    <!-- <div fxLayout="row wrap" class="align-items-center">
        <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100"> -->
            <!--app-search-field [label]="'AUDITLYTICS_PERMISSION.LIST.SEARCH_FIELD.LABEL'" 
            [hint]="'USER_ADMIN.LIST.SEARCH_FIELD.HINT'" 
            (searchTermChanged)="onSearchTermChanged($event)">
            </app-search-field-->
        <!-- </div>
        <div fxFlex.gt-md="75" class="text-right"> -->
        <!-- </div>
        </div> -->

    <div>
        <app-datatable
            [styleClass]="'cust-no-border-datatable'"
            [allColumnList]="allColumnList"
            [columns]="columns"
            [rows]="rows"
            [page]="page"
            (pageChanged)="onPageChanged($event)"
            (sortChanged)="onSortChanged($event)">
        </app-datatable>
    </div>
</div>

<ng-template #rowNumberTemplate let-row="row" let-value="value" let-rowIndex="rowIndex">
    <span class="ng-star-inserted">{{(page.pageNumber * page.size) + rowIndex + 1}}</span>
</ng-template>

<ng-template #nameInfoTemplate let-row="row" let-value="value">
      <div class="d-flex">
        <img class="mat-card-avatar" [src]='getProfilePhotoUrl(row.user)'>
        <div class="m-l-15">
          <p class="font-bold m-b-0 m-t-0">{{ getNameStr(row.user) }}</p>
        </div>
      </div>
</ng-template>

<ng-template #permissionLevelTemplate let-row="row" let-value="value">
    <span class="ng-star-inserted">{{('ENUM.AUDITLYTICS_PERMISSION_LEVEL.' + row.permissionLevel) | translate}}</span>
</ng-template>
<ng-template #countryStateCityTemplate let-row="row" let-value="value">
    <span class="ng-star-inserted">{{(row.country ? row.country : '-') + '/' + (row.state ? row.state : '-') + '/' + (row.city ? row.city : '-')}}</span>
</ng-template>

<ng-template #actionTemplate let-row="row" let-value="value">
    <div class="d-flex">
        <a (click)="openDetailDialog(PageMode.VIEW, row)" class="m-r-10 cursor-pointer">
            <i class="fa fa-eye fa-lg text-info"></i>
        </a>
        <a (click)="openDetailDialog(PageMode.EDIT, row)" class="m-r-10 cursor-pointer">
            <i class="fa fa-pencil fa-lg text-warning"></i>
        </a>
        <a (click)="openDetailDialog(PageMode.DELETE, row)" class="m-r-10 cursor-pointer">
            <i class="fa fa-trash fa-lg text-danger"></i>
        </a>
    </div>

</ng-template>

