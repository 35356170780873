<div class="content-box chart pb-0">
  <h3 class="content-box__title">{{'INVITATION_ADMIN.LIST.TITLE' | translate}}</h3>

      <div fxLayout="row wrap" class="align-items-center">
          <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100">
            <app-search-field [label]="'INVITATION_ADMIN.LIST.SEARCH_FIELD.LABEL'" 
              [hint]="'INVITATION_ADMIN.LIST.SEARCH_FIELD.HINT'" 
              (searchTermChanged)="onSearchTermChanged($event)">
            </app-search-field>
          </div>
          <div fxFlex.gt-md="75" class="text-right">
              <!--div class="add-new-container m-b-20" *ngIf="pageMode !== PageMode.VIEW">
                  <button mat-raised-button color="primary" class="m-r-5 m-t-5" (click)="openSendInvitationToUnregisteredUserDialog()" >{{'ORGANISATION.INVITE_UNREGISTERED_CLINICIAN' | translate}}</button>
              </div-->
          </div>
        </div>

      <div>
          <app-datatable
              [styleClass]="'cust-no-border-datatable'"
              [allColumnList]="allColumnList"
              [columns]="columns"
              [rows]="rows"
              [page]="page"
              (pageChanged)="onPageChanged($event)"
              (sortChanged)="onSortChanged($event)">
          </app-datatable>
      </div>
</div>

<ng-template #rowNumberTemplate let-row="row" let-value="value" let-rowIndex="rowIndex">
  <span class="ng-star-inserted">{{(page.pageNumber * page.size) + rowIndex + 1}}</span>
</ng-template>

<ng-template #invitorInfoTemplate let-row="row" let-value="value">
    <div class="d-flex">
      <img class="mat-card-avatar" [src]='getProfilePhotoUrl(row.invitor)'>
      <div class="m-l-15">
        <p class="font-bold m-b-0 m-t-0">{{ getNameStr(row.invitor) }}</p>
        <small class="text-muted" matTooltip="{{getRolesAsString(row.invitor.roles)}}">{{getRolesAsString(row.invitor.roles)}}</small>
      </div>
    </div>
</ng-template>

<ng-template #inviteeInfoTemplate let-row="row" let-value="value">
  <div class="d-flex">
    <img class="mat-card-avatar" [src]='getProfilePhotoUrl(row.invitee)'>
    <div class="m-l-15">
      <p class="font-bold m-b-0 m-t-0">{{ getNameStr(row.invitee) }}</p>
      <small class="text-muted" matTooltip="{{getRolesAsString(row.invitee?.roles)}}">{{getRolesAsString(row.invitee?.roles)}}</small>
    </div>
  </div>
</ng-template>

<ng-template #invitorEmailOrPhoneTemplate let-row="row" let-value="value">
  <div class="d-flex">
    <div class="m-l-0">
      <p class="font-bold m-b-0 m-t-0">{{ getEmailOrPhone(row.invitor) }}</p>
      <small class="text-muted">{{getUserUniqueId(row.invitor)}}</small>
    </div>
  </div>
</ng-template>

<ng-template #inviteeEmailOrPhoneTemplate let-row="row" let-value="value">
<div class="d-flex">
  <div class="m-l-0">
    <p class="font-bold m-b-0 m-t-0">{{ getEmailOrPhone(row.invitee) }}</p>
    <small class="text-muted">{{getUserUniqueId(row.invitee)}}</small>
  </div>
</div>
</ng-template>

<ng-template #unregisteredInviteeEmailOrPhoneTemplate let-row="row" let-value="value">
<div class="d-flex">
  <div class="m-l-0">
    <p class="font-bold m-b-0 m-t-0">{{ getOrDefault(row.unregisteredInviteeEmail, 'No Email') + '/' + getOrDefault(row.unregisteredInviteePhone, 'No Phone') }}</p>
  </div>
</div>
</ng-template>

<ng-template #actionTemplate let-row="row" let-value="value">
  <div class="d-flex">
      <!--a (click)="openDetailDialog(PageMode.VIEW, row)" class="m-r-10 cursor-pointer">
          <i class="fa fa-eye fa-lg text-info"></i>
      </a-->
      <a [matMenuTriggerFor]="overflowRowMenu" [matMenuTriggerData]="{rowInvitationSummary: row}" class="m-r-10 cursor-pointer">
          <i class="fa fa-ellipsis-v fa-lg"></i>
      </a>
  </div>

</ng-template>


<!-- ============================================================== -->
<!-- Row overflow menu-->
<!-- ============================================================== -->
<mat-menu #overflowRowMenu="matMenu">
  <ng-template matMenuContent let-selectedRow="rowInvitationSummary" >
      <button mat-menu-item *ngIf="!isMe(selectedRow.invitor)" (click)="deleteRow(selectedRow)">Delete Invitation</button>
  </ng-template>
</mat-menu>
