/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganisationRoutingModule } from './organisation-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { OrganisationMainComponent } from './pages/organisation-main/organisation-main.component';
import { OrganisationClinicianListComponent } from './pages/organisation-clinician-list/organisation-clinician-list.component';
import { OrganisationPatientListComponent } from './pages/organisation-patient-list/organisation-patient-list.component';
import { UserUploadComponent } from './component/user-upload/user-upload.component';
import { OrganisationBranchDetailComponent } from './component/organisation-branch-detail/organisation-branch-detail.component';
import { OrganisationBranchListComponent } from './pages/organisation-branch-list/organisation-branch-list.component';
import { OrganisationDetailComponent } from './pages/organisation-detail/organisation-detail.component';
import { OrganisationAuditlyticsPermissionListComponent } from './pages/organisation-auditlytics-permission-list/organisation-auditlytics-permission-list.component';
import { OrganisationAuditlyticsPermissionDetailComponent } from './component/organisation-auditlytics-permission-detail/organisation-auditlytics-permission-detail.component';


@NgModule({
  declarations: [
    OrganisationMainComponent,
    OrganisationDetailComponent,
    OrganisationBranchDetailComponent,
    OrganisationClinicianListComponent,
    OrganisationPatientListComponent,
    UserUploadComponent,
    OrganisationBranchListComponent,
    OrganisationAuditlyticsPermissionListComponent,
    OrganisationAuditlyticsPermissionDetailComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    OrganisationRoutingModule
  ]
})
export class OrganisationModule { }
