<div class="content-box chart">
  <h3 class="content-box__title">{{ 'ADVERSE_EVENTS_LIST.TITLE' | translate }}</h3>

  <div class="add-new-container">
    <button mat-raised-button color="primary" (click)="openUploadDialog()">{{'ADVERSE_EVENTS_LIST.UPLOAD_NEW_DATA' | translate}}</button>
  </div>
  <app-datatable
    [allColumnList]="allColumnList"
    [columns]="columns"
    [rows]="rows"
    [page]="page"
    (pageChanged)="onPageChanged($event)"
    (sortChanged)="onSortChanged($event)">
  </app-datatable>
</div>

<ng-template #idTemplate let-row="row" let-value="value">
  <!--span>{{ value }}</span-->
  <a *ngIf="value" href="{{ '/adverse-events/' + row.id }}" target="_blank">{{value}}</a>
</ng-template>
