<div [formGroup]="formGroupEmailOrPhone">
      <div class="pb-1 cust-form-field-container" [hidden]="selectionList.length < 2">
        <mat-radio-group formControlName="emailOrPhoneType">
          <ng-container *ngFor="let opt of selectionList">
            <mat-radio-button [value]="opt" style="margin-right: 10px;">{{ 'EMAIL_OR_PHONE.LABEL.' + opt | translate }}</mat-radio-button>
          </ng-container>
        </mat-radio-group>
        <app-validation-message [control]="formGroupEmailOrPhone.get('emailOrPhoneType')"></app-validation-message>
      </div>

      <div class="pb-1 cust-form-field-container">
        <div *ngIf="formGroupEmailOrPhone.get('emailOrPhoneType').value === 'EMAIL'">
          <mat-form-field class="cust-form-field">
            <mat-label>{{ 'EMAIL_OR_PHONE.LABEL.EMAIL' | translate }}</mat-label>
            <input matInput formControlName="email">
          </mat-form-field>
          <app-validation-message [control]="formGroupEmailOrPhone.get('email')"></app-validation-message>
        </div>

        <div *ngIf="formGroupEmailOrPhone.get('emailOrPhoneType').value === 'PHONE'">
          <mat-form-field class="cust-form-field">
            <mat-label>{{ 'EMAIL_OR_PHONE.LABEL.PHONE' | translate }}</mat-label>
            <ngx-mat-intl-tel-input
              [enablePlaceholder]="true"
              [enableSearch]="true"
              name="phone"
              formControlName="phone"
              #phoneComp>
            </ngx-mat-intl-tel-input>
          </mat-form-field>
          <mat-hint>e.g. {{phoneComp.selectedCountry.placeHolder}}</mat-hint>
          <app-validation-message [control]="formGroupEmailOrPhone.get('phone')"></app-validation-message>
        </div>

      </div>
</div>
