/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/shared/component/base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';
import { Gender } from 'src/app/shared/model/enum/gender';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { UserAccount, UserConsent, UserProfilePhotoUpdateRequest } from 'src/app/shared/model/user-account';
import { MatDialog } from '@angular/material/dialog';
import { ImageUploadComponent } from 'src/app/shared/component/image-upload/image-upload.component';
import { DEFAULT_USER_PHOTO, DATE_FORMAT_BACKEND, DATE_FORMAT_UI } from 'src/app/shared/constants';
import { hasUserAnyClinicianRelatedRole, objectCopy, userHasRole } from 'src/app/shared/util';
import { DatePipe } from '@angular/common';
import { UserConsentService } from 'src/app/shared/services/user-consent.service';
import { Role } from 'src/app/shared/model/enum/role';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent extends BaseComponent implements OnInit, OnDestroy {
  userAccount: UserAccount;
  userConsent: UserConsent;
  photoUrl = DEFAULT_USER_PHOTO;

  Gender: typeof Gender = Gender;
  genderOptions: string[];

  minBirthday = new Date(1900, 1, 1);
  maxBirthday = Date.now();

  showConsentSection = false;

  formGroupUserAccount: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    gender: new FormControl('', [Validators.required]),
  });

  formGroupUserAccountDetail: FormGroup = new FormGroup({
    hospitalId: new FormControl('', [Validators.minLength(4)]),
    postalCode: new FormControl('', [Validators.minLength(4)]),
    birthday: new FormControl('', []),
  });

  formGroupChangePassword: FormGroup = new FormGroup({
  });

  formGroupUserConsent: FormGroup = new FormGroup({
    newsletterSubscribed: new FormControl(''),
    notificationsSubscribed: new FormControl(''),
    medicalDataAllowed: new FormControl(''),
    accessYourDataAllowed: new FormControl(''),
    clinicalTrialsAllowed: new FormControl(''),
  });

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public datePipe: DatePipe,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private userProfileService: UserProfileService,
    private userConsentService: UserConsentService,
    private authenticationStateService: AuthenticationStateService) {
    super();
  }

  ngOnInit() {
    const gOptions: string[] = Object.keys(Gender);
    this.genderOptions = gOptions.slice(gOptions.length / 2);

    this.getUserAccount();
    this.getUserConsent();

  }

  getUserAccount() {
    this.userProfileService.getUserAccount().subscribe(user => {
      if (user) {
        this.formGroupUserAccount.patchValue(user);
        this.formGroupUserAccountDetail.patchValue(user);
        this.userAccount = user;
        this.notifyForChangedAccount(this.userAccount);
        this.showConsentSection = userHasRole(Role.PATIENT, user.roles) || userHasRole(Role.SYSTEM_ADMINISTRATOR, user.roles);
      }
    }, error => {
      console.log('Error on get account : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('ACCOUNT.NOTIFICATION.USER_GET_FAILED'));
    });
  }

  getUserConsent() {
    this.userConsentService.getUserConsent().subscribe(consent => {
      if (consent) {
        this.formGroupUserConsent.patchValue(consent);
        this.userConsent = consent;
      }
    }, error => {
      console.log('Error on get user consent : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('ACCOUNT.NOTIFICATION.CONSENT_GET_FAILED'));
    });
  }

  public onUpdateAccount() {
    if (this.formGroupUserAccount.valid) {
      const rawVal = this.formGroupUserAccount.getRawValue();
      let item = Object.assign({}, rawVal);
      item = objectCopy(item, this.userAccount);
      this.removeUnupdatableUserFields(item);

      this.userProfileService.updateUserAccount(item).subscribe(user => {
        this.userAccount = user;
        this.notifyForChangedAccount(this.userAccount);
        this.notificationService.success(this.translate.instant('ACCOUNT.NOTIFICATION.ACCOUNT_UPDATE_SUCCESS'));
      }, (error) => {
        console.log('Error on update account ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('ACCOUNT.NOTIFICATION.ACCOUNT_UPDATE_FAILED'));
      });
    }
  }

  public onUpdateDetail() {
    if (this.formGroupUserAccountDetail.valid) {
      const rawVal = this.formGroupUserAccountDetail.getRawValue();
      let item = Object.assign({}, rawVal);
      item = objectCopy(item, this.userAccount);
      this.removeUnupdatableUserFields(item);
      item.birthday = this.datePipe.transform(item.birthday, DATE_FORMAT_BACKEND);

      this.userProfileService.updateUserAccount(item).subscribe(user => {
        this.userAccount = user;
        this.notifyForChangedAccount(this.userAccount);
        this.notificationService.success(this.translate.instant('ACCOUNT.NOTIFICATION.ACCOUNT_UPDATE_SUCCESS'));
      }, (error) => {
        console.log('Error on update user detail ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('ACCOUNT.NOTIFICATION.ACCOUNT_UPDATE_FAILED'));
      });
    }
  }

  public onChangePassword() {
    if (this.formGroupChangePassword.valid) {
      const rawVal = this.formGroupChangePassword.getRawValue();
      
      const item = Object.assign({}, rawVal);

      item.currentPassword = this.formGroupChangePassword.get('currentPasswordFormGroup').get('password').value;
      item.newPassword = this.formGroupChangePassword.get('newPasswordFormGroup').get('password').value;
      
      delete item.currentPasswordFormGroup;
      delete item.newPasswordFormGroup;

      this.userProfileService.changeMyPassword(item).subscribe(data => {
        this.notificationService.success(this.translate.instant('ACCOUNT.NOTIFICATION.PASSWORD_CHANGED_SUCCESSFULLY'));
      }, (error) => {
        console.log('Error on change password ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('ACCOUNT.NOTIFICATION.PASSWORD_CHANGE_FAILED'));
      });
    }

  }

  public onUpdateConsent() {
    if (this.formGroupUserConsent.valid) {
      const rawVal = this.formGroupUserConsent.getRawValue();
      const item = Object.assign({}, rawVal);

      this.userConsentService.updateUserConsent(item).subscribe(consent => {
        this.userConsent = consent;
        this.notificationService.success(this.translate.instant('ACCOUNT.NOTIFICATION.CONSENT_UPDATE_SUCCESS'));
      }, (error) => {
        console.log('Error on update consent ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('ACCOUNT.NOTIFICATION.CONSENT_UPDATE_FAILED'));
      });
    }
  }

  public onUploadProfilePhoto() {
    const dialogRef = this.dialog.open(ImageUploadComponent, {
      data: { imageUrl: this.photoUrl },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.status === 'ok') {
          this.uploadPhoto(result.croppedImage);
        } else if (result.status === 'remove') {
          this.deletePhoto();
        }
      }
    });
  }

  uploadPhoto(base64Image) {
    const req = new UserProfilePhotoUpdateRequest();
    req.base64Photo = base64Image;
    this.userProfileService.updatePhoto(req).subscribe(user => {
      if (user) {
        this.userAccount = user;
        this.notifyForChangedAccount(this.userAccount);
        this.notificationService.success(this.translate.instant('ACCOUNT.NOTIFICATION.PROFILE_PHOTO_UPLOADED'));
      }
    }, error => {
      console.log('Error on uplaod photo : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('ACCOUNT.NOTIFICATION.UPLOAD_PHOTO_FAILED'));
    });
  }

  deletePhoto() {
    this.userProfileService.deletePhoto().subscribe(() => {
      this.userAccount.photoUrl = null;
      this.notifyForChangedAccount(this.userAccount);
      this.notificationService.success(this.translate.instant('ACCOUNT.NOTIFICATION.PROFILE_PHOTO_DELETED'));
    }, error => {
      console.log('Error on uplaod photo : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('ACCOUNT.NOTIFICATION.UPLOAD_PHOTO_FAILED'));
    });
  }

  notifyForChangedAccount(account) {
    this.photoUrl = account.photoUrl ? account.photoUrl : DEFAULT_USER_PHOTO;
    this.authenticationStateService.notifyLoginInfoWithAccount(account);
  }

  goToHome() {
    this.router.navigateByUrl('/');
  }

  removeUnupdatableUserFields(item) {
    delete item.email;
    delete item.phoneNumber;
    delete item.photoUrl;
    delete item.active;
    delete item.enabled;
    delete item.roles;
    delete item.organisationOwner;
    delete item.clinicianOrganisationId;
    delete item.clinicianOrganisationName;
    delete item.clinicianOrganisationBranchId;
    delete item.clinicianOrganisationBranchName;
    delete item.patientOrganisationId;
    delete item.patientOrganisationBranchId;
    delete item.patientOrganisationBranchName;
    delete item.permissionLevel;
    delete item.auditlyticsPermission;
    delete item.otpEnabled;
  }

}
