import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimulationCostUpdate } from '../../model/simulation';

@Component({
  selector: 'app-cost-dialog',
  templateUrl: './cost-dialog.component.html',
  styleUrls: ['./cost-dialog.component.scss']
})
export class CostDialogComponent implements OnInit {

  artFirstCostControl = new FormControl('', [Validators.min(0), Validators.max(50000)]);
  scArtFirstCostControl = new FormControl('', [Validators.min(0), Validators.max(50000)]);
  artSecondCostControl = new FormControl('', [Validators.min(0), Validators.max(50000)]);
  scArtSecondCostControl = new FormControl('', [Validators.min(0), Validators.max(50000)]);
  laboratoryCostControl = new FormControl('', [Validators.min(0), Validators.max(50000)]);
  scLaboratoryCostControl = new FormControl('', [Validators.min(0), Validators.max(50000)]);
  overheadCostControl = new FormControl('', [Validators.min(0), Validators.max(50000)]);
  scOverheadCostControl = new FormControl('', [Validators.min(0), Validators.max(50000)]);
  preventionCostControl = new FormControl('', [Validators.min(0), Validators.max(50000)]);
  scPreventionCostControl = new FormControl('', [Validators.min(0), Validators.max(50000)]);

  demandForm: FormGroup = new FormGroup({
    artFirstCost: this.artFirstCostControl,
    scArtFirstCost: this.scArtFirstCostControl,
    artSecondCost: this.artSecondCostControl,
    scArtSecondCost: this.scArtSecondCostControl,
    laboratoryCost: this.laboratoryCostControl,
    scLaboratoryCost: this.scLaboratoryCostControl,
    overheadCost: this.overheadCostControl,
    scOverheadCost: this.scOverheadCostControl,
    preventionCost: this.preventionCostControl,
    scPreventionCost: this.scPreventionCostControl
  });

  constructor(
    public dialogRef: MatDialogRef<CostDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SimulationCostUpdate) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onDefaultClick(): void {
    this.data.artFirstCost = this.data.artFirstCostDefault;
    this.data.artSecondCost = this.data.artSecondCostDefault;
    this.data.laboratoryCost = this.data.laboratoryCostDefault;
    this.data.overheadCost = this.data.overheadCostDefault;
    this.data.preventionCost = this.data.preventionCostDefault;

    this.data.scArtFirstCost = this.data.artFirstCostDefault;
    this.data.scArtSecondCost = this.data.artSecondCostDefault;
    this.data.scLaboratoryCost = this.data.laboratoryCostDefault;
    this.data.scOverheadCost = this.data.overheadCostDefault;
    this.data.scPreventionCost = this.data.preventionCostDefault;
  }

  public isInfoValid() {
    return this.demandForm.valid;
  }

  keyPressNumbers(value: number, event) {
    console.log(value);
    console.log(event.key);
    event.preventDefault();
  }
}
