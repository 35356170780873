import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FilterData, FilterListDataExtended, TrialistSummaryResult } from 'src/app/shared/model/trial-list';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { SurveyResultsService } from 'src/app/shared/services/survey-results.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ChartComponent } from '@swimlane/ngx-charts';

export interface Phase {
  x: string;
  y: number;
}

@Component({
  selector: 'app-trial-summary',
  templateUrl: './trial-summary.component.html',
  styleUrls: ['./trial-summary.component.scss']
})
export class TrialSummaryComponent implements OnInit {

  @ViewChild('chartPatientPerState')
  chartPatientPerState: ChartComponent = Object.create(null);
  @ViewChild('chartSuppressed')
  chartSuppressed: ChartComponent = Object.create(null);

  @ViewChild('chartTreatmentStatus')
  chartTreatmentStatus: ChartComponent = Object.create(null);
  @ViewChild('chartSuppressionStatus')
  chartSuppressionStatus: ChartComponent = Object.create(null);

  @ViewChild('chartPieEnroll')
  chartPieEnroll: ChartComponent = Object.create(null);

  public chartPatientPerStateOptions;
  public chartSuppressedOptions;

  public chartTreatmentStatusOptions;
  public chartSuppressionStatusOptions;

  public chartPieEnrollOptions;

  filterListDataExtended: FilterListDataExtended;

  data: TrialistSummaryResult = {
    total: 0,
    countries: null,
    states: null,
    cities: null,
    organizations: null,
    centers: null,
    summaryDataList: [],
    suppressedDataList: [],
    enrolledDataList: [],
    antiretroviralDataList: [],
    comedicationDataList: [],
    comorbiditiesDataList: [],
    mapSuppressedStatus: new Map(),
    mapTreatmentStatus: new Map(),
    mapGender: null,
    mapRace: null,
    mapType: null,
    mapAdherence: null,
    dataListMap: null,
    dataMapper: null
  };

  dropdownListCountry = [];
  selectedItemsCountry = [];
  dropdownSettingsCountry: IDropdownSettings = {};
  dropDownSelectCountry = false;

  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState: IDropdownSettings = {};
  dropDownSelectState = false;

  dropdownListCentre = [];
  selectedItemsCentre = [];
  dropdownSettingsCentre: IDropdownSettings = {};
  dropDownSelectCentre = false;

  filterDataList: Array<FilterData>;

  patientCount = 0;
  countries = new FormControl();
  centres = new FormControl();
  states = new FormControl();

  constructor(
    private router: Router,
    private storageService: StorageService,
    public surveyResultsService: SurveyResultsService,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {

    this.dropdownSettingsCountry = {
      singleSelection: false,
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      limitSelection: 10,
      allowSearchFilter: false
    };

    this.dropdownSettingsCentre = {
      singleSelection: false,
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      limitSelection: 10,
      allowSearchFilter: false
    };

    this.dropdownSettingsState = {
      singleSelection: false,
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      limitSelection: 10,
      allowSearchFilter: false
    };

    this.filterListDataExtended = JSON.parse(JSON.stringify(this.storageService.getTrialFilter()));

    if (!this.filterListDataExtended) {
      this.router.navigate(['smarthiv-trialist/trial-filter']);
      this.notificationService.error(this.translate.instant('TRIAL.SELECT_FILTERS'));
    }

    this.surveyResultsService.getTrialistSummaryResults(this.filterListDataExtended.filterListData).subscribe(data => {
      if (this.filterListDataExtended.filterListData.countries) {
        this.dropdownListCountry = this.filterListDataExtended.allCountries;
        this.selectedItemsCountry = this.filterListDataExtended.selectedCountries;
        this.dropdownListState = this.filterListDataExtended.allStates;
        this.selectedItemsState = this.filterListDataExtended.selectedStates;
      } else {
        this.dropdownListCountry = data.summaryDataList;
      }

      this.patientCount = data.total;
      this.data = data;
      this.setCharts(this.translate.instant('TRIAL.COUNTRIES'));
    });

  }

  setCharts(textForPatient: string) {
    const patientPerStateData: number[] = [];
    const stateNameData: string[] = [];

    const suppressedData: number[] = [];

    const yesData: number[] = [];
    const noData: number[] = [];
    const unkData: number[] = [];
    const onTreatmentData: number[] = [];
    const notOnTreatmentData: number[] = [];

    let enrolled = 0;
    let notEnrolled = 0;
    let unkEnrolled = 0;

    let count = 1;
    this.data.summaryDataList.forEach(summaryData => {
      if (count <= 10) {
        patientPerStateData.push(summaryData.count);
        stateNameData.push(summaryData.name.split(',')[0]);

        const mapSuppressedStatus = new Map(Object.entries(this.data.mapSuppressedStatus));
        const mapSuppressedStatusAll: Map<string, number> = new Map(Object.entries(mapSuppressedStatus.get(summaryData.name)));
        yesData.push(mapSuppressedStatusAll.get('yes'));
        noData.push(mapSuppressedStatusAll.get('no'));
        unkData.push(mapSuppressedStatusAll.get('unk'));

        const mapTreatmentStatus = new Map(Object.entries(this.data.mapTreatmentStatus));
        const mapTreatmentStatusAll: Map<string, number> = new Map(Object.entries(mapTreatmentStatus.get(summaryData.name)));
        onTreatmentData.push(mapTreatmentStatusAll.get('onTreatment'));
        notOnTreatmentData.push(mapTreatmentStatusAll.get('notOnTreatment'));

        count++;
      }
    });

    this.data.suppressedDataList.forEach(summaryData => {
      suppressedData.push(summaryData.count);
    });

    this.data.enrolledDataList.forEach(summaryData => {
      if (summaryData.name === 'unk') {
        unkEnrolled = summaryData.count;
      } else if (summaryData.name === 'true') {
        enrolled = summaryData.count;
      } else if (summaryData.name === 'false') {
        notEnrolled = summaryData.count;
      }
    });

    this.chartPieEnrollOptions = {
      series: [
        enrolled,
        notEnrolled,
        unkEnrolled
      ],
      chart: {
        type: 'pie'
      },
      labels: [
        this.translate.instant('TRIAL.ENROLLED'),
        this.translate.instant('TRIAL.NOT_ENROLLED'),
        this.translate.instant('TRIAL.UNKNOWN')
      ],
      legend: {
        position: 'bottom',
        labels: {
          colors: ['#785dd0', '#008efb'],
          useSeriesColors: false
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            dataLabels: {
              enabled: true
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    };

    this.chartTreatmentStatusOptions = {
      series: [
        {
          name: this.translate.instant('TRIAL.ON_TREATMENT'),
          type: 'column',
          data: onTreatmentData
        },
        {
          name: this.translate.instant('TRIAL.NOT_ON_TREATMENT'),
          type: 'column',
          data: notOnTreatmentData
        }
      ],
      xaxis: {
        categories: stateNameData
      },
      yaxis: {
        title: {
          text: this.translate.instant('TRIAL.PATIENTS')
        }
      },
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        stacked: true,
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartSuppressionStatusOptions = {
      series: [
        {
          name: this.translate.instant('TRIAL.YES'),
          type: 'column',
          data: yesData
        },
        {
          name: this.translate.instant('TRIAL.NO'),
          type: 'column',
          data: noData
        },
        {
          name: this.translate.instant('TRIAL.UNKNOWN'),
          type: 'column',
          data: unkData
        }
      ],
      xaxis: {
        categories: stateNameData
      },
      yaxis: {
        title: {
          text: this.translate.instant('TRIAL.PATIENTS')
        }
      },
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        stacked: true,
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartPatientPerStateOptions = {
      series: [
        {
          name: this.translate.instant('TRIAL.PATIENTS'),
          data: patientPerStateData
        }
      ],
      chart: {
        type: 'bar',
        height: 380
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      colors: [
        '#008efb',
        '#00e397',
        '#feb219',
        '#ff4561',
        '#785dd0',
        '#6b0914',
        '#f9a3a4',
        '#421243',
        '#7F94B0',
        '#EF6537',
        '#C0ADDB'
      ],
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#fff']
        },
        offsetX: 0
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        title: {
          text: this.translate.instant('TRIAL.PATIENTS')
        },
        categories: stateNameData
      },
      yaxis: {
        labels: {
          show: false
        },
        title: {
          text: textForPatient
        }
      }
    };

    this.chartSuppressedOptions = {
      series: [
        {
          name: this.translate.instant('TRIAL.PATIENTS'),
          data: suppressedData
        }
      ],
      chart: {
        type: 'bar',
        height: 380
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: false,
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      colors: ['#008efb', '#00e397', '#feb219', '#ff4561', '#785dd0', '#6b0914', '#f9a3a4'],
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#fff']
        },
        offsetX: 0
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        title: {
          text: this.translate.instant('TRIAL.SUPPRESSED')
        },
        categories: [
          this.translate.instant('TRIAL.YES'),
          this.translate.instant('TRIAL.NO'),
          this.translate.instant('TRIAL.UNKNOWN')
        ]
      },
      yaxis: {
        labels: {
          show: false
        },
        title: {
          text: this.translate.instant('TRIAL.PATIENTS')
        }
      }
    };
  }

  onDeSelectCountry(item: any) {
  }

  onDeSelectState(item: any) {
  }

  onDeSelectCentre(item: any) {
  }

  onItemSelect(item: any) {
  }

  onItemSelectCountry(item: any) {
  }

  onItemSelectState(item: any) {
  }

  onItemSelectCentre(item: any) {
  }

  onDropDownCloseCountry() {
    if (this.dropDownSelectCountry) {
      const countries = [];
      this.selectedItemsCountry.forEach(country => {
        countries.push(country.name);
      });
      this.filterListDataExtended.filterListData.countries = countries;
      this.filterListDataExtended.filterListData.states = [];
      this.dropdownListState = [];
      this.selectedItemsState = [];

      this.filterListDataExtended.allCountries = this.dropdownListCountry;
      this.filterListDataExtended.selectedCountries = this.selectedItemsCountry;

      this.filterListDataExtended.allStates = this.dropdownListState;
      this.filterListDataExtended.selectedStates = this.selectedItemsState;

      this.storageService.setTrialFilter(JSON.stringify(this.filterListDataExtended));
      this.surveyResultsService.getTrialistSummaryResults(this.filterListDataExtended.filterListData).subscribe(data => {
        if (this.selectedItemsCountry.length > 0) {
          this.dropdownListState = data.summaryDataList;
        } else {
          this.dropdownListState = [];
          this.selectedItemsState = [];
        }
        this.patientCount = data.total;
        this.data = data;
        if (countries.length > 0) {
          this.setCharts(this.translate.instant('TRIAL.STATES'));
        } else {
          this.setCharts(this.translate.instant('TRIAL.COUNTRIES'));
        }
      });
    }
    this.dropDownSelectCountry = false;
  }

  onDropDownCloseState() {
    if (this.dropDownSelectState) {
      const states = [];
      this.selectedItemsState.forEach(state => {
        states.push(state.name);
      });
      this.filterListDataExtended.filterListData.states = states;

      this.filterListDataExtended.allCountries = this.dropdownListCountry;
      this.filterListDataExtended.selectedCountries = this.selectedItemsCountry;

      this.filterListDataExtended.allStates = this.dropdownListState;
      this.filterListDataExtended.selectedStates = this.selectedItemsState;

      this.storageService.setTrialFilter(JSON.stringify(this.filterListDataExtended));
      this.surveyResultsService.getTrialistSummaryResults(this.filterListDataExtended.filterListData).subscribe(data => {
        this.patientCount = data.total;
        this.data = data;
        this.setCharts(this.translate.instant('TRIAL.STATES'));
      });
    }
    this.dropDownSelectState = false;
  }

  onDropDownCloseCentre() {
    this.dropDownSelectCentre = false;
  }

}
