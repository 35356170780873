<h1 mat-dialog-title>{{ 'SIMULATION.DEMAND.HEADER' | translate }}</h1>
<div mat-dialog-content>
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        {{ 'SIMULATION.DEMAND.PERIOD' | translate }}
      </mat-card-title>
      <div fxFlex></div>
      <div fxLayoutAlign="center center">
        <mat-icon matTooltip="{{ 'SIMULATION.DEMAND.INFO_TOOLTIP_PERIOD' | translate }}">info</mat-icon>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
        <mat-form-field fxFlex="20">
          <mat-select placeholder="Years" [(ngModel)]="selectedChoice" (selectionChange)="onSelectYear($event.value)"
            placeholder="{{ 'SIMULATION.DEMAND.YEARS' | translate }}"
            matTooltip="{{ 'SIMULATION.DEMAND.YEARS' | translate }}">
            <mat-option *ngFor="let opt of years" [value]="opt">{{opt}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>

  <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
    <div fxFlex="50">
      <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
        <h4>
          {{ 'SIMULATION.CURRENT_PRACTICE' | translate }}
        </h4>
        <mat-card class="blue-background">
          <mat-card-header>
            <mat-card-title class="text-white">
              {{ 'SIMULATION.PATIENTS' | translate }}
            </mat-card-title>
            <div fxFlex></div>
            <div fxLayoutAlign="center center">
              <mat-icon matTooltip="{{ 'SIMULATION.DEMAND.INFO_TOOLTIP' | translate }}">info</mat-icon>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
              <mat-form-field fxFlex="40">
                <input currencyMask [(ngModel)]="data.demandExperienced" [formControl]="experiencedControl"
                  maxlength="8"
                  [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                  matNativeControl placeholder="{{ 'SIMULATION.DEMAND.EXPERIENCED' | translate }}"
                  matTooltip="{{ 'SIMULATION.DEMAND.EXPERIENCED' | translate }}" required/>
                <mat-error *ngIf="experiencedControl.invalid">{{ 'SIMULATION.DEMAND.MIN_MAX_ERROR_EXPERIENCED' |
                  translate }}</mat-error>
              </mat-form-field>
              <div fxFlex="40"></div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
              <mat-form-field fxFlex="40">
                <input currencyMask [(ngModel)]="data.demandNaive" [formControl]="naiveControl" maxlength="7"
                  [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                  matNativeControl placeholder="{{ 'SIMULATION.DEMAND.NAIVE' | translate }}"
                  matTooltip="{{ 'SIMULATION.DEMAND.NAIVE' | translate }}" required/>
                <mat-error *ngIf="naiveControl.invalid">{{ 'SIMULATION.DEMAND.MIN_MAX_ERROR_NAIVE' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="40">
                <input currencyMask [(ngModel)]="data.demandIncrease" 
                  [formControl]="demandControl" 
                  maxlength="8"
                  [options]="{ thousands: ',', precision: '2', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                  matNativeControl 
                  placeholder="{{ 'SIMULATION.DEMAND.NAIVE_PERCENTAGE' | translate }}"
                  matTooltip="{{ 'SIMULATION.DEMAND.NAIVE_PERCENTAGE' | translate }}" required/>
                <mat-error *ngIf="demandControl.invalid">{{ 'SIMULATION.DEMAND.MIN_MAX_ERROR_PERCENTAGE' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
              <mat-form-field fxFlex="40">
                <input currencyMask [(ngModel)]="data.demandPrevention" [formControl]="preventionControl" maxlength="8"
                  [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                  matNativeControl placeholder="{{ 'SIMULATION.DEMAND.PREVENTION' | translate }}"
                  matTooltip="{{ 'SIMULATION.DEMAND.PREVENTION' | translate }}" required/>
                <mat-error *ngIf="preventionControl.invalid">{{ 'SIMULATION.DEMAND.MIN_MAX_ERROR_PREVENTION' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="40">
                <input currencyMask [(ngModel)]="data.demandIncreasePrevention" [formControl]="demandPreventionControl"
                  maxlength="7"
                  [options]="{ thousands: ',', precision: '2', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                  matNativeControl placeholder="{{ 'SIMULATION.DEMAND.PREVENTION_PERCENTAGE' | translate }}"
                  matTooltip="{{ 'SIMULATION.DEMAND.PREVENTION_PERCENTAGE' | translate }}" required/>
                <mat-error *ngIf="demandPreventionControl.invalid">{{ 'SIMULATION.DEMAND.MIN_MAX_ERROR_PERCENTAGE' |
                  translate }}</mat-error>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div fxFlex="50">
      <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
        <h4>
          {{ 'SIMULATION.SCENARIO' | translate }}
        </h4>
        <mat-card class="pink-background">
          <mat-card-header class="text-white">
            <mat-card-title>
              {{ 'SIMULATION.PATIENTS' | translate }}
            </mat-card-title>
            <div fxFlex></div>
            <div fxLayoutAlign="center center">
              <mat-icon matTooltip="{{ 'SIMULATION.DEMAND.INFO_TOOLTIP' | translate }}">info</mat-icon>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
              <mat-form-field fxFlex="40">
                <input currencyMask [(ngModel)]="data.scDemandExperienced" [formControl]="experiencedControlSc"
                  maxlength="8"
                  [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                  matNativeControl placeholder="{{ 'SIMULATION.DEMAND.EXPERIENCED' | translate }}"
                  matTooltip="{{ 'SIMULATION.DEMAND.EXPERIENCED' | translate }}" required/>
                <mat-error *ngIf="experiencedControlSc.invalid">{{ 'SIMULATION.DEMAND.MIN_MAX_ERROR_EXPERIENCED' |
                  translate }}</mat-error>
              </mat-form-field>
              <div fxFlex="40"></div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
              <mat-form-field fxFlex="40">
                <input currencyMask [(ngModel)]="data.scDemandNaive" [formControl]="naiveControlSc" maxlength="7"
                  [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                  matNativeControl placeholder="{{ 'SIMULATION.DEMAND.NAIVE' | translate }}"
                  matTooltip="{{ 'SIMULATION.DEMAND.NAIVE' | translate }}" required/>
                <mat-error *ngIf="naiveControlSc.invalid">{{ 'SIMULATION.DEMAND.MIN_MAX_ERROR_NAIVE' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="40">
                <input currencyMask [(ngModel)]="data.scDemandIncrease" [formControl]="demandControlSc" maxlength="8"
                  [options]="{ thousands: ',', precision: '2', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                  matNativeControl placeholder="{{ 'SIMULATION.DEMAND.NAIVE_PERCENTAGE' | translate }}"
                  matTooltip="{{ 'SIMULATION.DEMAND.NAIVE_PERCENTAGE' | translate }}" required/>
                <mat-error *ngIf="demandControlSc.invalid">{{ 'SIMULATION.DEMAND.MIN_MAX_ERROR_PERCENTAGE' | translate
                  }}</mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
              <mat-form-field fxFlex="40">
                <input currencyMask [(ngModel)]="data.scDemandPrevention" [formControl]="preventionControlSc"
                  maxlength="8"
                  [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                  matNativeControl placeholder="{{ 'SIMULATION.DEMAND.PREVENTION' | translate }}"
                  matTooltip="{{ 'SIMULATION.DEMAND.PREVENTION' | translate }}" required/>
                <mat-error *ngIf="preventionControlSc.invalid">{{ 'SIMULATION.DEMAND.MIN_MAX_ERROR_PREVENTION' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="40">
                <input currencyMask [(ngModel)]="data.scDemandIncreasePrevention"
                  [formControl]="demandPreventionControlSc" maxlength="7"
                  [options]="{ thousands: ',', precision: '2', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                  matNativeControl placeholder="{{ 'SIMULATION.DEMAND.PREVENTION_PERCENTAGE' | translate }}"
                  matTooltip="{{ 'SIMULATION.DEMAND.PREVENTION_PERCENTAGE' | translate }}" required/>
                <mat-error *ngIf="demandPreventionControlSc.invalid">{{ 'SIMULATION.DEMAND.MIN_MAX_ERROR_PERCENTAGE' |
                  translate }}</mat-error>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  <span>{{ 'SIMULATION.REQUIRED' | translate }}</span>
</div>

<div mat-dialog-actions>
  <button mat-button color="primary" (click)="onNoClick()">{{ 'SIMULATION.CANCEL' | translate }}</button>
  <button mat-button color="primary" (click)="onDefaultClick()">{{ 'SIMULATION.DEFAULT' | translate }}</button>
  <button mat-button [mat-dialog-close]="data" [disabled]="!isInfoValid()" cdkFocusInitial color="accent">{{
    'SIMULATION.OK' | translate }}</button>