/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export enum InboxType {
    OUTGOING_INVITATIONS=0,
    INCOMING_INVITATIONS=1
}

export interface Category {
    id: number;
    name: string;
    icon: string;
    count: number;
    color?: string;
    active: boolean;
}



export const INVITATION_TYPE_LABELS: Category[] = [
    {
        id: 701,
        name: 'BECOME_CLINICIAN_OF_MY_ORGANISATION',
        icon: 'label',
        count: 0,
        color: '#1e88e5',
        active: false
    },
    {
        id: 702,
        name: 'BECOME_PATIENT_OF_MY_ORGANISATION',
        icon: 'label',
        count: 0,
        color: '#1e88e5',
        active: false
    },
    {
        id: 703,
        name: 'BECOME_CLINICIAN_OF_ME',
        icon: 'label',
        count: 0,
        color: '#26c6da',
        active: false
    },
    {
        id: 704,
        name: 'BECOME_PATIENT_OF_ME',
        icon: 'label',
        count: 0,
        color: '#fc4b6c',
        active: false
    },
    {
        id: 705,
        name: 'BECOME_KIN_OF_ME',
        icon: 'label',
        count: 0,
        color: '#7460ee',
        active: false
    },
    {
        id: 706,
        name: 'REGISTRATION',
        icon: 'label',
        count: 0,
        color: '#ffb22b',
        active: false
    },
    {
        id: 707,
        name: 'BECOME_PATIENT_OF_MY_ORGANISATION_AND_ME',
        icon: 'label',
        count: 0,
        color: '#ffb22b',
        active: false
    },
];
