/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import { PageMode } from 'src/app/shared/model/enum/page-mode';
import { Plan } from 'src/app/shared/model/subscription';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { PlanService } from 'src/app/shared/services/plan.service';
import { SubscriptionPlanDetailComponent } from '../../component/subscription-plan-detail/subscription-plan-detail.component';

@Component({
  selector: 'app-subscription-plan-list',
  templateUrl: './subscription-plan-list.component.html',
  styleUrls: ['./subscription-plan-list.component.scss']
})
export class SubscriptionPlanListComponent extends BaseListComponent implements OnInit {
  @ViewChild('activeTemplate', { static: true }) activeTemplate: TemplateRef<any>;
  @ViewChild('rowActionButtonsTemplate', { static: true }) rowActionButtonsTemplate: TemplateRef<any>;

  PageMode: typeof PageMode = PageMode;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private planService: PlanService
  ) {
    super();
  }

  ngOnInit(): void {
    this.allColumnList = [
      { prop: 'id', name: this.translate.instant('SUBSCRIPTION.PLAN.LIST.COL_LBL.ID'), width: 100, maxWidth: 100 },
      { prop: 'name', name: this.translate.instant('SUBSCRIPTION.PLAN.LIST.COL_LBL.NAME'), cellClass: '' },
      { prop: 'amount', name: this.translate.instant('SUBSCRIPTION.PLAN.LIST.COL_LBL.AMOUNT'), cellClass: ''},
      { prop: 'currency', name: this.translate.instant('SUBSCRIPTION.PLAN.LIST.COL_LBL.CURRENCY'), cellClass: '' },
      { prop: 'intervalCount', name: this.translate.instant('SUBSCRIPTION.PLAN.LIST.COL_LBL.INTERVAL_COUNT'), cellClass: ''},
      { prop: 'interval', name: this.translate.instant('SUBSCRIPTION.PLAN.LIST.COL_LBL.INTERVAL'), cellClass: '' },
      { prop: 'active', name: this.translate.instant('SUBSCRIPTION.PLAN.LIST.COL_LBL.ACTIVE'), maxWidth: 80, cellTemplate: this.activeTemplate, cellClass: '' },
      { prop: 'action', name: '', maxWidth: 120, cellTemplate: this.rowActionButtonsTemplate, sortable: false, draggable: false}
    ];

    this.columns = [...this.allColumnList];

    this.loadData();
  }

  openDetailDialog(mode: PageMode, plan: Plan): void {
    const dialogConfig = this.createDialogConfig({pageMode: mode, row: plan});
    const dialogRef = this.dialog.open(SubscriptionPlanDetailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data && data.refreshTable && data.refreshTable === true) {
        this.loadData();
      }
    });
  }

  loadData() {
    this.planService.getPlans(this.page.pageNumber, this.page.size, this.sort).subscribe(page => {
      this.page.pageNumber = page.number;
      this.page.totalElements = page.totalElements;
      this.page.size = page.size;
      this.rows = page.content;
    });
  }

}
