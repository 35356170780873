import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import { ValidationService } from '../../services/validation.service';

@Component({
    selector: 'app-validation-message',
    templateUrl: 'validation-message.component.html',
    styleUrls: ['./validation-message.component.scss']
})
export class ValidationMessageComponent {
    @Input() control: AbstractControl;

    constructor(private validationService: ValidationService) {
    }

    get errorMessage() {
        for (const propertyName in this.control.errors) {
            if (this.control.errors.hasOwnProperty(propertyName) && (propertyName === 'error' || this.control.touched)) {
                return this.validationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
            }
        }
        return null;
    }
}
