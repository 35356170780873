
<!-- <mat-card *ngIf="questionGroups">
    <mat-card-content> -->
        <div class="clinician-form" *ngIf="questionGroups">
            <ng-container *ngFor="let qg of questionGroups">
                <div class="content-box">
                    <app-question-group [questionGroup]="qg" [questionGroups]="questionGroups"></app-question-group>
                </div>
            </ng-container>
        </div>

        <div class="d-flex justify-content-center mt-24">
            <button class="filled-btn secondary lg rounded px-45" (click)="onClinicalOutcomes()">{{'CLINICIAN_FORM.BUTTON_OPEN_CLINICAL_OUTCOME' | translate}}</button>
        </div>
    <!-- </mat-card-content>
</mat-card> -->
