/* eslint-disable id-blacklist */
export class PageableSort {
    direction: string;
    property: string;
    public constructor(data: any = {}) {
        this.property = data.property || '';
        this.direction = data.direction || '';
    }
}

export class Page<T> {
    content?: Array<T>;
    empty?: boolean;
    first?: boolean;
    last?: boolean;
    number: number;
    size: number;
    totalPages?: number;
    totalElements: number;
    numberOfElements: number;
    sort?: Array<PageableSort>;
}

export class Pageable {
    page: number;
    pageSize: number;
    sort?: PageableSort;
    searchTerm: string;
    public constructor(data: any = {}) {
        this.page = data.page || -1;
        this.pageSize = data.pageSize || 25;
        this.sort = data.sort || null;
        this.searchTerm = data.searchTerm || '';
    }
}
