<div class="section-title-bar bg-primary justify-content-between border-radius-bottom-0">
    <h2 class="content-box__title font-size-2 mb-0">{{ 'SIMULATION.UNAIDS.CURRENT_SUMMARY' | translate }}</h2>
    <mat-icon matTooltip="{{ 'SIMULATION.UNAIDS.CURRENT_SUMMARY' | translate }}">info</mat-icon>
</div>

<div class="content-box blue-background border-radius-top-0">
    <p class="mb-8">{{ 'SIMULATION.UNAIDS.CURRENT_SITUATION' | translate:{startYear: startYear} }}</p>
    <div class="service-demand-grid mb-24">
        <div class="content-box chart px-0 pink-background">
            <h3 class="content-box__title sub">{{ 'SIMULATION.UNAIDS.FIRST_XX' | translate:{firstX: diagnosedPercentage} }}</h3>
            <div id="chartRadialFirstSummary" class="d-flex flex-column"
                *ngIf="chartRadialFirstSummaryOptions">
                <apx-chart [series]="chartRadialFirstSummaryOptions.series"
                    [chart]="chartRadialFirstSummaryOptions.chart"
                    [plotOptions]="chartRadialFirstSummaryOptions.plotOptions"
                    [labels]="chartRadialFirstSummaryOptions.labels"
                    [fill]="chartRadialFirstSummaryOptions.fill"></apx-chart>
            </div>
            <p class="pl-24">{{ 'SIMULATION.UNAIDS.KNOW_STATUS' | translate }}</p>
            <p class="pl-24">{{ 'SIMULATION.UNAIDS.COUNTRY_LEVEL' | translate:{ country:country } }} : {{countryKnowStatus}}%</p>
        </div>

        <div class="content-box chart px-0 pink-background">
            <h3 class="content-box__title sub">{{ 'SIMULATION.UNAIDS.SECOND_XX' | translate:{secondX: onArtPercentage} }}</h3>
            <div id="chartRadialSecondSummary" class="d-flex flex-column"
                *ngIf="chartRadialSecondSummaryOptions">
                <apx-chart [series]="chartRadialSecondSummaryOptions.series"
                    [chart]="chartRadialSecondSummaryOptions.chart"
                    [plotOptions]="chartRadialSecondSummaryOptions.plotOptions"
                    [labels]="chartRadialSecondSummaryOptions.labels"
                    [fill]="chartRadialSecondSummaryOptions.fill"></apx-chart>
            </div>
            <p class="pl-24">{{ 'SIMULATION.UNAIDS.KNOW_ON_ART' | translate }}</p>
            <p class="pl-24">{{ 'SIMULATION.UNAIDS.COUNTRY_LEVEL' | translate:{ country:country } }} : {{countryOnArt}}%</p>
        </div>

        <div class="content-box chart px-0 pink-background">
            <h3 class="content-box__title sub">{{ 'SIMULATION.UNAIDS.THIRD_XX' | translate:{thirdX: viralSuppressionPercentage} }}</h3>
            <div id="chartRadialThirdSummary" class="d-flex flex-column"
                *ngIf="chartRadialThirdSummaryOptions">
                <apx-chart [series]="chartRadialThirdSummaryOptions.series"
                    [chart]="chartRadialThirdSummaryOptions.chart"
                    [plotOptions]="chartRadialThirdSummaryOptions.plotOptions"
                    [labels]="chartRadialThirdSummaryOptions.labels"
                    [fill]="chartRadialThirdSummaryOptions.fill"></apx-chart>
            </div>
            <p class="pl-24">{{ 'SIMULATION.UNAIDS.ACHIEVE_VIRAL' | translate }}</p>
            <p class="pl-24">{{ 'SIMULATION.UNAIDS.COUNTRY_LEVEL' | translate:{ country:country } }} : {{countryViralSuppression}}%</p>
        </div>
    </div>

    <div class="hr-planning-management-grid">
        <div class="content-box chart">
            <h3 class="content-box__title">{{ 'SIMULATION.UNAIDS.NO_PATIENTS' | translate }}</h3>

            <div id="chartSum" class="d-flex flex-column" *ngIf="chartSumOptions">
                <apx-chart [series]="chartSumOptions.series" 
                    [chart]="chartSumOptions.chart"
                    [dataLabels]="chartSumOptions.dataLabels"
                    [plotOptions]="chartSumOptions.plotOptions" 
                    [yaxis]="chartSumOptions.yaxis"
                    [legend]="chartSumOptions.legend" 
                    [fill]="chartSumOptions.fill"
                    [stroke]="chartSumOptions.stroke" 
                    [tooltip]="chartSumOptions.tooltip"
                    [xaxis]="chartSumOptions.xaxis" 
                    [grid]="chartSumOptions.grid">
                </apx-chart>
            </div>
        </div>

        <div class="content-box chart">
            <h3 class="content-box__title">{{ 'SIMULATION.UNAIDS.PERCENTAGE' | translate }}</h3>

            <div id="chartSumPer" class="d-flex flex-column" *ngIf="chartSumPerOptions">
                <apx-chart [series]="chartSumPerOptions.series" 
                    [chart]="chartSumPerOptions.chart"
                    [dataLabels]="chartSumPerOptions.dataLabels"
                    [plotOptions]="chartSumPerOptions.plotOptions" 
                    [yaxis]="chartSumPerOptions.yaxis"
                    [legend]="chartSumPerOptions.legend" 
                    [fill]="chartSumPerOptions.fill"
                    [stroke]="chartSumPerOptions.stroke" 
                    [tooltip]="chartSumPerOptions.tooltip"
                    [xaxis]="chartSumPerOptions.xaxis" 
                    [grid]="chartSumPerOptions.grid">
                </apx-chart>
            </div>
        </div>
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.UNAIDS.YEAR' | translate:{year: finishYear} }}</p>

<div class="outcomes-charts mb-24">
    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.UNAIDS.NEW_DIAGNOSE' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.UNAIDS.NEW_DIAGNOSE' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.UNAIDS.DIAGNOSE' | translate }}</p>

        <div id="chartDiagnosis" class="d-flex flex-column" *ngIf="chartDiagnosisOptions">
            <apx-chart [series]="chartDiagnosisOptions.series" 
                [chart]="chartDiagnosisOptions.chart"
                [dataLabels]="chartDiagnosisOptions.dataLabels"
                [plotOptions]="chartDiagnosisOptions.plotOptions" 
                [yaxis]="chartDiagnosisOptions.yaxis"
                [legend]="chartDiagnosisOptions.legend" 
                [fill]="chartDiagnosisOptions.fill"
                [stroke]="chartDiagnosisOptions.stroke" 
                [tooltip]="chartDiagnosisOptions.tooltip"
                [xaxis]="chartDiagnosisOptions.xaxis" 
                [grid]="chartDiagnosisOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.UNAIDS.NEW_PATIENTS' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.UNAIDS.NEW_PATIENTS' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.UNAIDS.ART' | translate }}</p>

        <div id="chartOnArt" class="d-flex flex-column" *ngIf="chartOnArtOptions">
            <apx-chart [series]="chartOnArtOptions.series" 
                [chart]="chartOnArtOptions.chart"
                [dataLabels]="chartOnArtOptions.dataLabels" 
                [plotOptions]="chartOnArtOptions.plotOptions"
                [yaxis]="chartOnArtOptions.yaxis" 
                [legend]="chartOnArtOptions.legend"
                [fill]="chartOnArtOptions.fill" 
                [stroke]="chartOnArtOptions.stroke"
                [tooltip]="chartOnArtOptions.tooltip" 
                [xaxis]="chartOnArtOptions.xaxis"
                [grid]="chartOnArtOptions.grid">
            </apx-chart>
        </div>
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.UNAIDS.FIVE_YEAR_FORECAST' | translate:{years: years} }}</p>

<div class="outcomes-charts mb-24">
    <div class="content-box chart">
        <h3 class="content-box__title">{{ 'SIMULATION.UNAIDS.NO_PATIENTS_WITH_YEAR' | translate:{year:finishYear} }}</h3>

        <div id="chartPatientsLastYear" class="d-flex flex-column" *ngIf="chartPatientsLastYearOptions">
            <apx-chart [series]="chartPatientsLastYearOptions.series" 
                [chart]="chartPatientsLastYearOptions.chart"
                [dataLabels]="chartPatientsLastYearOptions.dataLabels"
                [plotOptions]="chartPatientsLastYearOptions.plotOptions" 
                [yaxis]="chartPatientsLastYearOptions.yaxis"
                [legend]="chartPatientsLastYearOptions.legend" 
                [fill]="chartPatientsLastYearOptions.fill"
                [stroke]="chartPatientsLastYearOptions.stroke" 
                [tooltip]="chartPatientsLastYearOptions.tooltip"
                [xaxis]="chartPatientsLastYearOptions.xaxis" 
                [grid]="chartPatientsLastYearOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <h3 class="content-box__title">{{ 'SIMULATION.UNAIDS.UNAIDS_WITH_YEAR' | translate:{year:finishYear} }}</h3>

        <div id="chartPatientsLastYearPer" class="d-flex flex-column" *ngIf="chartPatientsLastYearPerOptions">
            <apx-chart [series]="chartPatientsLastYearPerOptions.series" 
                [chart]="chartPatientsLastYearPerOptions.chart"
                [dataLabels]="chartPatientsLastYearPerOptions.dataLabels" 
                [plotOptions]="chartPatientsLastYearPerOptions.plotOptions"
                [yaxis]="chartPatientsLastYearPerOptions.yaxis" 
                [legend]="chartPatientsLastYearPerOptions.legend"
                [fill]="chartPatientsLastYearPerOptions.fill" 
                [stroke]="chartPatientsLastYearPerOptions.stroke"
                [tooltip]="chartPatientsLastYearPerOptions.tooltip" 
                [xaxis]="chartPatientsLastYearPerOptions.xaxis"
                [grid]="chartPatientsLastYearPerOptions.grid">
            </apx-chart>
        </div>
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.UNAIDS.FIVE_YEAR_FORECAST' | translate:{years: years} }}</p>

<div class="content-box chart mb-24">
    <div class="d-flex justify-content-between mb-24">
        <h3 class="content-box__title mb-0">{{ 'SIMULATION.CURRENT_PRACTICE_YEAR' | translate:{year: finishYear} }}</h3>
        <mat-icon matTooltip="{{ 'SIMULATION.CURRENT_PRACTICE_YEAR' | translate:{year: finishYear} }}">info</mat-icon>
    </div>

    <div class="four-column-grid">
        <div class="content-box chart light-bg px-0">
            <h3 class="content-box__title font-size-1-4 pl-16">{{ 'SIMULATION.UNAIDS.FIRST_XX' | translate:{firstX: diagnosedPercentage} }}</h3>
            <div id="chartRadialFirstBaseline" class="d-flex flex-column"
                *ngIf="chartRadialFirstBaselineOptions">
                <apx-chart [series]="chartRadialFirstBaselineOptions.series"
                    [chart]="chartRadialFirstBaselineOptions.chart"
                    [plotOptions]="chartRadialFirstBaselineOptions.plotOptions"
                    [labels]="chartRadialFirstBaselineOptions.labels"
                    [fill]="chartRadialFirstBaselineOptions.fill"></apx-chart>
            </div>
            <p class="font-size-1-2 pl-16">{{ 'SIMULATION.UNAIDS.KNOW_STATUS' | translate }}</p>
        </div>

        <div class="content-box chart light-bg px-0">
            <h3 class="content-box__title font-size-1-4 pl-16">{{ 'SIMULATION.UNAIDS.SECOND_XX' | translate:{secondX: onArtPercentage} }}</h3>
            <div id="chartRadialSecondBaseline" class="d-flex flex-column"
                *ngIf="chartRadialSecondBaselineOptions">
                <apx-chart [series]="chartRadialSecondBaselineOptions.series"
                    [chart]="chartRadialSecondBaselineOptions.chart"
                    [plotOptions]="chartRadialSecondBaselineOptions.plotOptions"
                    [labels]="chartRadialSecondBaselineOptions.labels"
                    [fill]="chartRadialSecondBaselineOptions.fill"></apx-chart>
            </div>
            <p class="font-size-1-2 pl-16">{{ 'SIMULATION.UNAIDS.KNOW_ON_ART' | translate }}</p>
        </div>

        <div class="content-box chart light-bg px-0">
            <h3 class="content-box__title font-size-1-4 pl-16">{{ 'SIMULATION.UNAIDS.THIRD_XX' | translate:{thirdX: viralSuppressionPercentage} }}</h3>
            <div id="chartRadialThirdBaseline" class="d-flex flex-column"
                *ngIf="chartRadialThirdBaselineOptions">
                <apx-chart [series]="chartRadialThirdBaselineOptions.series"
                    [chart]="chartRadialThirdBaselineOptions.chart"
                    [plotOptions]="chartRadialThirdBaselineOptions.plotOptions"
                    [labels]="chartRadialThirdBaselineOptions.labels"
                    [fill]="chartRadialThirdBaselineOptions.fill"></apx-chart>
            </div>
            <p class="font-size-1-2 pl-16">{{ 'SIMULATION.UNAIDS.ACHIEVE_VIRAL' | translate }}</p>
        </div>

        <div class="content-box chart light-bg px-0">
            <h3 class="content-box__title font-size-1-4 pl-16">{{ 'SIMULATION.UNAIDS.HIV_POPULATION' | translate }}</h3>
            <div id="chartRadialViralBaseline" class="d-flex flex-column"
                *ngIf="chartRadialViralBaselineOptions">
                <apx-chart [series]="chartRadialViralBaselineOptions.series"
                    [chart]="chartRadialViralBaselineOptions.chart"
                    [plotOptions]="chartRadialViralBaselineOptions.plotOptions"
                    [labels]="chartRadialViralBaselineOptions.labels"
                    [fill]="chartRadialViralBaselineOptions.fill"></apx-chart>
            </div>
            <p class="font-size-1-2 pl-16">{{ 'SIMULATION.UNAIDS.HIV_POPULATION_PERCENTAGE' | translate }}</p>
        </div>
    </div>
</div>

<div class="content-box chart mb-24">
    <div class="d-flex justify-content-between mb-24">
        <h3 class="content-box__title mb-0">{{ 'SIMULATION.SCENARIO_YEAR' | translate:{year: finishYear} }}</h3>
        <mat-icon matTooltip="{{ 'SIMULATION.SCENARIO_YEAR' | translate:{year: finishYear} }}">info</mat-icon>
    </div>

    <div class="four-column-grid">
        <div class="content-box chart light-bg px-0">
            <h3 class="content-box__title font-size-1-4 pl-16">{{ 'SIMULATION.UNAIDS.FIRST_XX' | translate:{firstX: diagnosedPercentage} }}</h3>
            <div id="chartRadialFirstScenario" class="d-flex flex-column"
                *ngIf="chartRadialFirstScenarioOptions">
                <apx-chart [series]="chartRadialFirstScenarioOptions.series"
                    [chart]="chartRadialFirstScenarioOptions.chart"
                    [plotOptions]="chartRadialFirstScenarioOptions.plotOptions"
                    [labels]="chartRadialFirstScenarioOptions.labels"
                    [fill]="chartRadialFirstScenarioOptions.fill"></apx-chart>
            </div>
            <p class="font-size-1-2 pl-16">{{ 'SIMULATION.UNAIDS.KNOW_STATUS' | translate }}</p>
        </div>

        <div class="content-box chart light-bg px-0">
            <h3 class="content-box__title font-size-1-4 pl-16">{{ 'SIMULATION.UNAIDS.SECOND_XX' | translate:{secondX: onArtPercentage} }}</h3>
            <div id="chartRadialSecondScenario" class="d-flex flex-column"
                *ngIf="chartRadialSecondScenarioOptions">
                <apx-chart [series]="chartRadialSecondScenarioOptions.series"
                    [chart]="chartRadialSecondScenarioOptions.chart"
                    [plotOptions]="chartRadialSecondScenarioOptions.plotOptions"
                    [labels]="chartRadialSecondScenarioOptions.labels"
                    [fill]="chartRadialSecondScenarioOptions.fill"></apx-chart>
            </div>
            <p class="font-size-1-2 pl-16">{{ 'SIMULATION.UNAIDS.KNOW_ON_ART' | translate }}</p>
        </div>

        <div class="content-box chart light-bg px-0">
            <h3 class="content-box__title font-size-1-4 pl-16">{{ 'SIMULATION.UNAIDS.THIRD_XX' | translate:{thirdX: viralSuppressionPercentage} }}</h3>
            <div id="chartRadialThirdScenario" class="d-flex flex-column"
                *ngIf="chartRadialThirdScenarioOptions">
                <apx-chart [series]="chartRadialThirdScenarioOptions.series"
                    [chart]="chartRadialThirdScenarioOptions.chart"
                    [plotOptions]="chartRadialThirdScenarioOptions.plotOptions"
                    [labels]="chartRadialThirdScenarioOptions.labels"
                    [fill]="chartRadialThirdScenarioOptions.fill"></apx-chart>
            </div>
            <p class="font-size-1-2 pl-16">{{ 'SIMULATION.UNAIDS.ACHIEVE_VIRAL' | translate }}</p>
        </div>

        <div class="content-box chart light-bg px-0">
            <h3 class="content-box__title font-size-1-4 pl-16">{{ 'SIMULATION.UNAIDS.HIV_POPULATION' | translate }}</h3>
            <div id="chartRadialViralScenario" class="d-flex flex-column"
                *ngIf="chartRadialViralScenarioOptions">
                <apx-chart [series]="chartRadialViralScenarioOptions.series"
                    [chart]="chartRadialViralScenarioOptions.chart"
                    [plotOptions]="chartRadialViralScenarioOptions.plotOptions"
                    [labels]="chartRadialViralScenarioOptions.labels"
                    [fill]="chartRadialViralScenarioOptions.fill"></apx-chart>
            </div>
            <p class="font-size-1-2 pl-16">{{ 'SIMULATION.UNAIDS.HIV_POPULATION_PERCENTAGE' | translate }}</p>
        </div>
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.UNAIDS.CURRENT_PRACTICE' | translate:{years: years} }}</p>

<div class="outcomes-charts mb-24">
    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.UNAIDS.NO_PATIENTS_PER_YEAR' | translate }}</h3>
            <mat-icon class="flex-shrink-0" matTooltip="{{ 'SIMULATION.UNAIDS.NO_PATIENTS_PER_YEAR' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.CURRENT_PRACTICE' | translate }}</p>

        <div id="chartBaseline" class="d-flex flex-column" *ngIf="chartBaselineOptions">
            <apx-chart 
                [series]="chartBaselineOptions.series"
                [xaxis]="chartBaselineOptions.xaxis"
                [yaxis]="chartBaselineOptions.yaxis" 
                [chart]="chartBaselineOptions.chart"
                [dataLabels]="chartBaselineOptions.dataLabels" 
                [plotOptions]="chartBaselineOptions.plotOptions"
                [legend]="chartBaselineOptions.legend" 
                [fill]="chartBaselineOptions.fill"
                [stroke]="chartBaselineOptions.stroke"
                [tooltip]="chartBaselineOptions.tooltip"
                [grid]="chartBaselineOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.UNAIDS.TARGET_PERCENTAGES_PER_YEAR' | translate }}</h3>
            <mat-icon class="flex-shrink-0" matTooltip="{{ 'SIMULATION.UNAIDS.TARGET_PERCENTAGES_PER_YEAR' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.CURRENT_PRACTICE' | translate }}</p>

        <div id="chartTargetCurrentPracticePer" class="d-flex flex-column" *ngIf="chartTargetCurrentPracticePerOptions">
            <apx-chart [series]="chartTargetCurrentPracticePerOptions.series" 
                [chart]="chartTargetCurrentPracticePerOptions.chart"
                [dataLabels]="chartTargetCurrentPracticePerOptions.dataLabels"
                [plotOptions]="chartTargetCurrentPracticePerOptions.plotOptions" 
                [yaxis]="chartTargetCurrentPracticePerOptions.yaxis"
                [legend]="chartTargetCurrentPracticePerOptions.legend" 
                [fill]="chartTargetCurrentPracticePerOptions.fill"
                [stroke]="chartTargetCurrentPracticePerOptions.stroke" 
                [tooltip]="chartTargetCurrentPracticePerOptions.tooltip"
                [xaxis]="chartTargetCurrentPracticePerOptions.xaxis" 
                [grid]="chartTargetCurrentPracticePerOptions.grid">
            </apx-chart>
        </div>
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.UNAIDS.SCENARIO' | translate:{years: years} }}</p>

<div class="outcomes-charts">
    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.UNAIDS.NO_PATIENTS_PER_YEAR' | translate }}</h3>
            <mat-icon class="flex-shrink-0" matTooltip="{{ 'SIMULATION.UNAIDS.NO_PATIENTS_PER_YEAR' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.SCENARIO' | translate }}</p>

        <div id="chartScenario" class="d-flex flex-column" *ngIf="chartScenarioOptions">
            <apx-chart 
                [series]="chartScenarioOptions.series"
                [xaxis]="chartScenarioOptions.xaxis"
                [yaxis]="chartScenarioOptions.yaxis" 
                [chart]="chartScenarioOptions.chart"
                [dataLabels]="chartScenarioOptions.dataLabels" 
                [plotOptions]="chartScenarioOptions.plotOptions"
                [legend]="chartScenarioOptions.legend" 
                [fill]="chartScenarioOptions.fill"
                [stroke]="chartScenarioOptions.stroke"
                [tooltip]="chartScenarioOptions.tooltip"
                [grid]="chartScenarioOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.UNAIDS.TARGET_PERCENTAGES_PER_YEAR' | translate }}</h3>
            <mat-icon class="flex-shrink-0" matTooltip="{{ 'SIMULATION.UNAIDS.TARGET_PERCENTAGES_PER_YEAR' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.SCENARIO' | translate }}</p>

        <div id="chartTargetScenarioPer" class="d-flex flex-column" *ngIf="chartTargetScenarioPerOptions">
            <apx-chart [series]="chartTargetScenarioPerOptions.series" 
                [chart]="chartTargetScenarioPerOptions.chart"
                [dataLabels]="chartTargetScenarioPerOptions.dataLabels"
                [plotOptions]="chartTargetScenarioPerOptions.plotOptions" 
                [yaxis]="chartTargetScenarioPerOptions.yaxis"
                [legend]="chartTargetScenarioPerOptions.legend" 
                [fill]="chartTargetScenarioPerOptions.fill"
                [stroke]="chartTargetScenarioPerOptions.stroke" 
                [tooltip]="chartTargetScenarioPerOptions.tooltip"
                [xaxis]="chartTargetScenarioPerOptions.xaxis" 
                [grid]="chartTargetScenarioPerOptions.grid">
            </apx-chart>
        </div>
    </div>
</div>