/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export enum InvitationType {

  BECOME_PATIENT_OF_MY_ORGANISATION = 0,
  BECOME_CLINICIAN_OF_MY_ORGANISATION = 1,
  BECOME_PATIENT_OF_MY_ORGANISATION_AND_ME = 2,
  BECOME_CLINICIAN_OF_ME = 3,
  BECOME_PATIENT_OF_ME = 4,
  BECOME_KIN_OF_ME = 5,
  REGISTRATION = 6
}
