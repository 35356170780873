import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Notification, NotificationType } from './../model/notification-model';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private subject = new Subject<Notification>();
    private keepAfterRouteChange = false;

    constructor(private router: Router) {
        // clear notification messages on route change unless 'keepAfterRouteChange' flag is true
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear notification messages
                    this.clear();
                }
            }
        });
    }

    // enable subscribing to notifications observable
    onNotification(notificationId?: string): Observable<Notification> {
        return this.subject.asObservable().pipe(filter(x => x && x.notificationId === notificationId));
    }

    // convenience methods
    success(message: string, notificationId?: string) {
        this.notification(new Notification({ message, type: NotificationType.Success, notificationId, keepAfterRouteChange: true }));
    }

    error(message: string, notificationId?: string) {
        this.notification(new Notification({ message, type: NotificationType.Error, notificationId, keepAfterRouteChange: true }));
    }

    info(message: string, notificationId?: string) {
        this.notification(new Notification({ message, type: NotificationType.Info, notificationId, keepAfterRouteChange: true }));
    }

    warn(message: string, notificationId?: string) {
        this.notification(new Notification({ message, type: NotificationType.Warning, notificationId, keepAfterRouteChange: true }));
    }

    // main notification method
    notification(notification: Notification) {
        this.keepAfterRouteChange = notification.keepAfterRouteChange;
        this.subject.next(notification);
    }

    // clear notifications
    clear(notificationId?: string) {
        this.subject.next(new Notification({ notificationId }));
    }
}
