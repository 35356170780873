/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import { DEFAULT_USER_PHOTO } from 'src/app/shared/constants';
import { DatatablePage } from 'src/app/shared/model/datatable-page';
import { PageMode } from 'src/app/shared/model/enum/page-mode';
import { Role } from 'src/app/shared/model/enum/role';
import { Invitation } from 'src/app/shared/model/invitation';
import { PageableSort } from 'src/app/shared/model/pagination';
import { UserSummary } from 'src/app/shared/model/user-account';
import { LoginResponse } from 'src/app/shared/model/user-profile';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { InvitationService } from 'src/app/shared/services/invitation.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';
import { rolesToStringArray } from 'src/app/shared/util';

@Component({
  selector: 'app-invitation-list-admin',
  templateUrl: './invitation-list-admin.component.html',
  styleUrls: ['./invitation-list-admin.component.scss']
})
export class InvitationListAdminComponent extends BaseListComponent implements OnInit {
  PageMode: typeof PageMode = PageMode;
  Role: typeof Role = Role;

  @ViewChild('rowNumberTemplate', { static: true }) rowNumberTemplate: TemplateRef<any>;
  @ViewChild('invitorInfoTemplate', { static: true }) invitorInfoTemplate: TemplateRef<any>;
  @ViewChild('inviteeInfoTemplate', { static: true }) inviteeInfoTemplate: TemplateRef<any>;
  @ViewChild('invitorEmailOrPhoneTemplate', { static: true }) invitorEmailOrPhoneTemplate: TemplateRef<any>;
  @ViewChild('inviteeEmailOrPhoneTemplate', { static: true }) inviteeEmailOrPhoneTemplate: TemplateRef<any>;
  @ViewChild('unregisteredInviteeEmailOrPhoneTemplate', { static: true }) unregisteredInviteeEmailOrPhoneTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate', { static: true }) actionTemplate: TemplateRef<any>;

  pageMode: PageMode;
  loginInfo: LoginResponse;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private authenticationStateService: AuthenticationStateService,
    private invitationService: InvitationService
  ) {
    super();
   }

  ngOnInit(): void {
    this.allColumnList = [
      { prop: 'id', name: '#', maxWidth: 50, cellClass: '', cellTemplate: this.rowNumberTemplate, sortable: false, draggable: false },
      { prop: 'invitor.firstName', name: this.translate.instant('INVITATION_ADMIN.LIST.COL_LBL.INVITOR_NAME'), cellTemplate: this.invitorInfoTemplate, sortable: false, draggable: false},
      { prop: 'invitor.emailOrPhone', name: this.translate.instant('INVITATION_ADMIN.LIST.COL_LBL.INVITOR_EMAIL_OR_PHONE'), cellTemplate: this.invitorEmailOrPhoneTemplate, sortable: false, draggable: false},
      { prop: 'invitee.firstName', name: this.translate.instant('INVITATION_ADMIN.LIST.COL_LBL.INVITEE_NAME'), cellTemplate: this.inviteeInfoTemplate, sortable: false, draggable: false},
      { prop: 'invitee.emailOrPhone', name: this.translate.instant('INVITATION_ADMIN.LIST.COL_LBL.INVITEE_EMAIL_OR_PHONE'), cellTemplate: this.inviteeEmailOrPhoneTemplate, sortable: false, draggable: false},
      { prop: 'unregisteredEmailOrPhone', name: this.translate.instant('INVITATION_ADMIN.LIST.COL_LBL.UNREGISTERED_INVITEE_EMAIL_OR_PHONE'), cellTemplate: this.unregisteredInviteeEmailOrPhoneTemplate, sortable: false, draggable: false},
      { prop: 'type', name: this.translate.instant('INVITATION_ADMIN.LIST.COL_LBL.TYPE'), cellClass: '', sortable: false, draggable: false},
      { prop: 'action', name: '', maxWidth: 50, cellTemplate: this.actionTemplate, sortable: false, draggable: false}
    ];
    this.columns = [...this.allColumnList];

    this.loginInfo = this.authenticationStateService.getLoginInfo();

    // this.sort = new PageableSort({ property: 'startTime', direction: 'DESC' });
    this.loadData();
  }

  loadData() {
    this.invitationService.listAllInvitationsByAdmin(this.page.pageNumber, this.page.size, this.sort, this.searchTerm).subscribe(page => {
      this.page.pageNumber = page.number;
      this.page.totalElements = page.totalElements;
      this.page.size = page.size;
      this.rows = page.content;
    });
  }

  getProfilePhotoUrl(userSummary: UserSummary): string {
    return userSummary && userSummary.photoUrl ? userSummary.photoUrl : DEFAULT_USER_PHOTO;
  }

  getNameStr(user: UserSummary): string {
    return user && user.firstName ? (user.firstName + ' ' + user.lastName) : 'No Name';
  }

  getRolesAsString(roleValue): string {
    if(roleValue) {
      const roles = rolesToStringArray(roleValue);
      return roles.map(r => this.translate.instant('ENUM.ROLE.' + r)).join(', ');
    }
    return 'No Role';
  }

  getUserUniqueId(user: UserSummary): string {
    return user && user.userUniqueId ? user.userUniqueId : 'No Folio Number';
  }

  getEmailOrPhone(user: UserSummary): string {
    const email = user && user.email ? user.email : 'No Email';
    const phone = user && user.phoneNumber ? user.phoneNumber : 'No Phone';
    return email + '/' + phone;
  }

  getOrDefault(val: string, defaultVal: string): string {
    return val ? val : defaultVal;
  }

  isMe(user: UserSummary): boolean {
    return (user.phoneNumber && user.phoneNumber === this.loginInfo.phoneNumber) || (user.email && user.email === this.loginInfo.email);
  }

  deleteRow(row: Invitation) {
    this.notificationService.warn('This feature will be implemented later');
  }

}
