<div *ngIf="roleValue >= 0">
    <mat-select *ngIf="componentMode === 'edit'" 
        [(ngModel)]="selectedRoles" 
        (selectionChange)="onRoleChanged($event.value)"
        matTooltip="{{selectedRolesString}}"
        placeholder="{{'USER_ADMIN.DETAIL.FORM.ROLES' | translate}}" 
        multiple>

        <mat-option *ngFor= "let r of roleOptions" [value]="r">
            {{'ENUM.ROLE.' + r | translate}}
        </mat-option>
    </mat-select>

    <span *ngIf="componentMode === 'view'" matTooltip="{{selectedRolesString}}">{{selectedRolesString}}</span>

</div>
