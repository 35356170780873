import { Component, ContentChild, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-searchable-dropdown',
  templateUrl: './searchable-dropdown.component.html',
  styleUrls: ['./searchable-dropdown.component.scss']
})
export class SearchableDropdownComponent implements OnInit, OnDestroy, OnChanges {

  @ContentChild('itemTemplate') itemTemplate: TemplateRef<any>;

  @Input() control: FormControl;

  @Input() items: any[];
  filteredItems: Observable<any[]>;

  @Input() itemDisplayProperty: string;
  @Input() itemValueProperty: string = 'id';

  @Input() label = 'Label';
  @Input() searchLabel = 'Search';
  @Input() notFoundLabel = 'No item found';

  @Input() formFieldAppearance: MatFormFieldAppearance = 'standard';
  @Input() formFieldStyleClass = '';

  @Output() selectionChange = new EventEmitter<any>();

   // searchCtrl = new FormControl();
   private readonly typingSubject = new Subject<string | undefined>();
   typingSubscription: Subscription;

  constructor() { }

  ngOnInit(): void {
    // this.filteredItems = this.searchCtrl.valueChanges.pipe(
    this.typingSubscription = this.typingSubject.pipe(
      startWith(''),
      debounceTime(200),
      distinctUntilChanged(),
    ).subscribe((value) => {
      this.filterItems(value);
    });
  }

  ngOnDestroy(): void {
    this.typingSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {
      let change = changes[propName];
     
      if (propName === "items") {
        this.items = change.currentValue;
        this.filterItems('');
      }
    }
  }

  filterItems(value: string) {
    if (!value || value === "") {
      this.filteredItems = of(this.items);
      return;
    }
    const list = this.items?.filter(item => ('' + item[this.itemDisplayProperty]).toLowerCase().includes(value.toLowerCase()));
    this.filteredItems = of(list);
  }

  onSelectionChange(value) {
    this.selectionChange.emit(value);
  }

  filterValueChanged(value) {
    this.typingSubject.next(value);
  }
  

}
