import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimulationUnaidsUpdate } from '../../model/simulation';

@Component({
  selector: 'app-unaids-dialog',
  templateUrl: './unaids-dialog.component.html',
  styleUrls: ['./unaids-dialog.component.scss']
})
export class UnaidsDialogComponent implements OnInit {


  selectedChoice: string;
  choices: string[] = ['No Scenario',
    '95-95-95',
    '90-90-90',
    '85-85-85',
    '80-80-80',
    '75-75-75',
    '70-70-70',
    '65-65-65',
    '60-60-60'
  ];

  percentageKnowStatusControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  percentageArtControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  percentageViralSuppressionControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  mortalityRatioControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  prevalenceRatioControl = new FormControl('', [Validators.min(0), Validators.max(100)]);

  unaidsForm: FormGroup = new FormGroup({
    percentageKnowStatus: this.percentageKnowStatusControl,
    percentageArt: this.percentageArtControl,
    percentageViralSuppression: this.percentageViralSuppressionControl,
    mortalityRatio: this.mortalityRatioControl,
    prevalenceRatio: this.prevalenceRatioControl
  });

  constructor(
    public dialogRef: MatDialogRef<UnaidsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SimulationUnaidsUpdate) {
    if (data.scPercentageKnowStatus === 0) {
      this.selectedChoice = 'No Scenario';
      this.calculateDemands(true);
    } else if (data.scPercentageKnowStatus === 60) {
      this.selectedChoice = '60-60-60';
      this.calculateDemands(false);
    } else if (data.scPercentageKnowStatus === 65) {
      this.selectedChoice = '65-65-65';
      this.calculateDemands(false);
    } else if (data.scPercentageKnowStatus === 70) {
      this.selectedChoice = '70-70-70';
      this.calculateDemands(false);
    } else if (data.scPercentageKnowStatus === 75) {
      this.selectedChoice = '75-75-75';
      this.calculateDemands(false);
    } else if (data.scPercentageKnowStatus === 80) {
      this.selectedChoice = '80-80-80';
      this.calculateDemands(false);
    } else if (data.scPercentageKnowStatus === 85) {
      this.selectedChoice = '85-85-85';
      this.calculateDemands(false);
    } else if (data.scPercentageKnowStatus === 90) {
      this.selectedChoice = '90-90-90';
      this.calculateDemands(false);
    } else if (data.scPercentageKnowStatus === 95) {
      this.selectedChoice = '95-95-95';
      this.calculateDemands(false);
    }
  }

  ngOnInit(): void {
  }

  onSelectChoice(value) {
    if (value === 'No Scenario') {
      this.data.scPercentageArt = 0;
      this.data.scPercentageKnowStatus = 0;
      this.data.scPercentageViralSuppression = 0;
      this.calculateDemands(true);
    } else if (value === '60-60-60') {
      this.data.scPercentageArt = 60;
      this.data.scPercentageKnowStatus = 60;
      this.data.scPercentageViralSuppression = 60;
      this.calculateDemands(false);
    } else if (value === '65-65-65') {
      this.data.scPercentageArt = 65;
      this.data.scPercentageKnowStatus = 65;
      this.data.scPercentageViralSuppression = 65;
      this.calculateDemands(false);
    } else if (value === '70-70-70') {
      this.data.scPercentageArt = 70;
      this.data.scPercentageKnowStatus = 70;
      this.data.scPercentageViralSuppression = 70;
      this.calculateDemands(false);
    } else if (value === '75-75-75') {
      this.data.scPercentageArt = 75;
      this.data.scPercentageKnowStatus = 75;
      this.data.scPercentageViralSuppression = 75;
      this.calculateDemands(false);
    } else if (value === '80-80-80') {
      this.data.scPercentageArt = 80;
      this.data.scPercentageKnowStatus = 80;
      this.data.scPercentageViralSuppression = 80;
      this.calculateDemands(false);
    } else if (value === '85-85-85') {
      this.data.scPercentageArt = 85;
      this.data.scPercentageKnowStatus = 85;
      this.data.scPercentageViralSuppression = 85;
      this.calculateDemands(false);
    } else if (value === '90-90-90') {
      this.data.scPercentageArt = 90;
      this.data.scPercentageKnowStatus = 90;
      this.data.scPercentageViralSuppression = 90;
      this.calculateDemands(false);
    } else if (value === '95-95-95') {
      this.data.scPercentageArt = 95;
      this.data.scPercentageKnowStatus = 95;
      this.data.scPercentageViralSuppression = 95;
      this.calculateDemands(false);
    }
  }

  calculateDemands(useSimulationData: boolean) {
    if (useSimulationData) {
      this.data.scDemandNaiveCalculated = this.data.demandNaiveSc;
      this.data.scDemandExperiencedCalculated = this.data.scDemandExperienced;
      this.data.scNewDiagnosis = parseFloat((this.data.scDemandNaiveCalculated * 100 / this.data.percentageArt).toFixed(0));
      if (!this.data.scNewDiagnosis || this.data.scNewDiagnosis < 0) {
        this.data.scNewDiagnosis = 0;
      }
    } else {
      const initialDemandData: number[] = [];
      const initialNewDiagnosisData: number[] = [];
      const remainingInitialDemandData: number[] = [];
      const remainingInitialNewDiagnosisData: number[] = [];
      const plwhaData: number[] = [];
      const remainingData: number[] = [];
      const multiplierFactorData: number[] = [];
      let totalMultiplierFactor = 0;

      const plwha = ((this.data.demandExperienced / this.data.percentageKnowStatus) * 100);

      for (let index = 0; index < this.data.years; index++) {
        if (index > 0) {
          multiplierFactorData[index] = multiplierFactorData[index - 1] * ((100 + this.data.prevalenceRatio) / 100);
          totalMultiplierFactor = totalMultiplierFactor + multiplierFactorData[index];
          remainingData[index] = remainingData[index - 1] * ((100 - this.data.mortalityRatio) / 100);
        } else {
          multiplierFactorData[index] = 1;
          totalMultiplierFactor = totalMultiplierFactor + 1;
          remainingData[index] = this.data.demandExperienced * ((100 - this.data.mortalityRatio) / 100);
        }
      }

      for (let index = 0; index < this.data.years; index++) {
        if (index > 0) {
          plwhaData[index] = plwhaData[index - 1] * ((100 - this.data.mortalityRatio) / 100) * ((100 + this.data.prevalenceRatio) / 100);
        } else {
          plwhaData[index] = plwha * ((100 - this.data.mortalityRatio) / 100) * ((100 + this.data.prevalenceRatio) / 100);
        }
      }

      const targetDiagnosed = plwhaData[plwhaData.length - 1] * this.data.scPercentageKnowStatus / 100;
      const targetOnArt = targetDiagnosed * this.data.scPercentageArt / 100;
      const underCountOnArt = targetOnArt - remainingData[remainingData.length - 1];
      const initalTarget = underCountOnArt / this.data.years;

      const underCountDiagnosed = targetDiagnosed - remainingData[remainingData.length - 1];
      const newDiagnosisTarget = underCountDiagnosed / this.data.years;

      for (let index = 0; index < this.data.years; index++) {
        initialNewDiagnosisData.push(newDiagnosisTarget);
        initialDemandData.push(initalTarget);
      }

      for (let index = 0; index < this.data.years; index++) {
        if (index > 0) {
          remainingInitialDemandData[index] = (initialDemandData[index - 1]
            + remainingInitialDemandData[index - 1]) * ((100 - this.data.mortalityRatio) / 100);
          remainingInitialNewDiagnosisData[index] = (initialNewDiagnosisData[index - 1]
            + remainingInitialNewDiagnosisData[index - 1]) * ((100 - this.data.mortalityRatio) / 100);
        } else {
          remainingInitialDemandData[index] = initialDemandData[0] * (100 - this.data.mortalityRatio) / 100;
          remainingInitialNewDiagnosisData[index] = initialNewDiagnosisData[0] * (100 - this.data.mortalityRatio) / 100;
        }
      }

      const underCountNewDiagnosis = underCountDiagnosed - remainingInitialNewDiagnosisData[remainingInitialNewDiagnosisData.length - 1];
      const underCountNaive = underCountOnArt - remainingInitialDemandData[remainingInitialDemandData.length - 1];
      const adjustedUnderCountOnArt = underCountOnArt + underCountNaive;
      const adjustedUnderDiagnosed = underCountDiagnosed + underCountNewDiagnosis;

      this.data.scDemandNaiveCalculated =
        parseFloat((adjustedUnderCountOnArt / (totalMultiplierFactor / multiplierFactorData[0])).toFixed());

      this.data.scNewDiagnosis = parseFloat((adjustedUnderDiagnosed / (totalMultiplierFactor / multiplierFactorData[0])).toFixed());

      if (!this.data.scNewDiagnosis || this.data.scNewDiagnosis < 0) {
        this.data.scNewDiagnosis = 0;
      }
      if (!this.data.scDemandNaiveCalculated || this.data.scDemandNaiveCalculated < 0) {
        this.data.scDemandNaiveCalculated = 0;
      }
      this.data.scDemandExperiencedCalculated = this.data.demandExperienced;
    }
  }

  onDefaultClick(): void {
    this.data.percentageKnowStatus = this.data.percentageKnowStatusDefault;
    this.data.percentageArt = this.data.percentageArtDefault;
    this.data.percentageViralSuppression = this.data.percentageViralSuppressionDefault;
    this.data.mortalityRatio = this.data.mortalityRatioDefault;
    this.data.prevalenceRatio = this.data.prevalenceRatioDefault;
    this.calculateDemands(true);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public isInfoValid() {
    return this.unaidsForm.valid;
  }

  onChangeEvent() {
    if (this.data.scPercentageArt !== 0) {
      this.calculateDemands(false);
    }
  }

}
