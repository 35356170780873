<div class="section-title-bar justify-content-between mb-16">
    <h2 class="content-box__title mb-0">{{ 'TRIAL.COUNTRY' | translate }}</h2>
    <mat-icon matTooltip="{{ 'TRIAL.COUNTRY' | translate }}">info</mat-icon>
</div>

<div class="content-box overflow-auto">
    <fusioncharts 
        [width]="width" 
        [height]="height" 
        [type]="type" 
        [dataFormat]="dataFormat"
        [dataSource]="dataSource"
        (initialized)="initialized($event)"
        >
    </fusioncharts>
</div>