export const MAX_IMG_WIDTH = 400;
export const MAX_IMG_HEIGHT = 300;
export const BASE64_IMAGE_PREFIX = 'data:image/jpg;base64,';

export const DEFAULT_ORG_BRANCH_ICON = 'assets/images/no-org-branch.jpg';
export const DEFAULT_USER_PHOTO = 'assets/images/no-avatar.jpg';
export const DEFAULT_USER_BACKGROUND_PHOTO = 'assets/images/dashboard-page.png';
export const DEFAULT_EMAIL = 'no@email.com';

export const DATE_FORMAT_BACKEND = 'yyyy-MM-dd';
export const DATE_TIME_FORMAT_BACKEND = 'yyyy-MM-dd HH:mm:ss.SSSZ';
export const DATE_FORMAT_UI = 'dd/MM/yyyy';

export const ROUTE_HISTORY_LIMIT = 5;

export const MAX_PAGE_SIZE_FOR_UNLIMITED_LIST = 10000;

