import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClinicalOutcome } from 'src/app/shared/model/clinical-outcome';
import { Question, QuestionGroup } from 'src/app/shared/model/question';
import { Suppression } from 'src/app/shared/model/suppression';
import { QuestionsService } from 'src/app/shared/services/questions.service';
import { isArray } from 'src/app/shared/util';

@Component({
  selector: 'app-clinical-outcome-summary',
  templateUrl: './clinical-outcome-summary.component.html',
  styleUrls: ['./clinical-outcome-summary.component.scss']
})
export class ClinicalOutcomeSummaryComponent implements OnInit {
  @Input() clinicalOutcome: ClinicalOutcome;
  @Input() suppressionList: Suppression[];
  questionGroups: QuestionGroup[];
  cardStyleClasses = ['info', 'warning', 'success', 'danger'];
  convertedInfoList = [];

  icons = {
    ageclassification: 'bs-age font-size-2-7',
    gender: 'bs-gender font-size-2-5',
    pregnancy: 'bs-pregnancy font-size-2-8',
    race: 'bs-race font-size-2-6',
    patienttype: 'bs-patient-type font-size-2-3',
    timeontreatment: 'bs-treatment-time font-size-2-5',
    anymutation: 'bs-any-mutation font-size-2-3',
    mutationclass: 'bs-mutation-class font-size-2-6',
    mutations: 'bs-mutations font-size-2-1',
    medication: 'bs-medication font-size-2-9',
    categoryofmedication: 'bs-medication-category font-size-2-5',
    genotype: 'bs-geno-type font-size-2-5',
    opportunisticinfections: 'bs-infections font-size-3',
    treatmentstrategyswitch: 'bs-treatment-strategy font-size-2-4',
    currentlysuppressed: 'bs-suppressed font-size-2',
    timefromdiagnosis: 'fa fa-clock-o font-size-2-4',
  };

  constructor(
    private questionsService: QuestionsService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.questionsService.loadClinicianFormQuestions().subscribe(data => {
      this.questionGroups = data;
      this.convertQuestionToInfoList();
    });
  }

  convertQuestionToInfoList() {
    let index = 0;
    for (const group of this.questionGroups) {
      for (const question of group.questions) {
        const answer = this.clinicalOutcome[question.id];
        const isAnswerExist = (isArray(answer) && answer.length > 0 && answer[0]) || (!isArray(answer) && answer);
        if (isAnswerExist) {
          const questionText = question.name ? question.name : group.name;
          const answerTextList = this.convertToAnswerString(question);
          const styleClass = 'cust-property-' + this.cardStyleClasses[index % this.cardStyleClasses.length];
          this.convertedInfoList.push({ questionText, answerTextList, styleClass });
          index++;
        }
      }
    }
  }

  convertToAnswerString(question: Question) {
    const answer = this.clinicalOutcome[question.id];
    const strList = [];
    if (!isArray(answer)) {
      let text = answer;
      if (question.optionsType !== 'DYNAMIC') {
        const foundAnswer = question.options.find(option => option.id === answer);
        text = foundAnswer ? foundAnswer.name : '';
      }
      strList.push(text);
    } else {
      for (const a of answer) {
        let text = a;
        if (question.optionsType !== 'DYNAMIC') {
          const foundAnswer = question.options.find(option => option.id === a);
          text = foundAnswer.name;
        }
        strList.push(text);
      }
    }
    return strList;
  }

  onEdit() {
    this.router.navigateByUrl('smarthiv-clinician/clinician-form/edit');
  }

  getIconName(name: string) {
    const icon = name.toLowerCase().replace(/\s/g, '');
    return this.icons[icon];
  }
}
