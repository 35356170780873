/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Organisation, OrganisationFilterRequest } from '../model/organisation';
import { Page, PageableSort } from '../model/pagination';
import { createPageParams } from '../util';

@Injectable({
    providedIn: 'root'
})
export class OrganisationService {

    private baseUrl = '/organisation-svc/v1/organisations';

    constructor(private http: HttpClient) {
    }

    createOrganisation(organisation: Organisation): Observable<Organisation> {
        return this.http.post<Organisation>(environment.url + `${this.baseUrl}`, organisation)
        .pipe(map((res: any) => res || {}));
    }

    updateOrganisation(id: number, organisation: Organisation): Observable<Organisation> {
        return this.http.put<Organisation>(environment.url + `${this.baseUrl}/${id}`, organisation)
            .pipe(map((res: any) => res || {}));
    }

    deleteOrgansation(id: number): Observable<void> {
        return this.http.delete<any>(environment.url + `${this.baseUrl}/${id}`);
    }

    getOrganisation(id: number): Observable<Organisation> {
        return this.http.get<Organisation>(environment.url + `${this.baseUrl}/${id}`)
            .pipe(map((res: any) => res || {}));
    }

    getOrganisations(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string, filterRequest?: OrganisationFilterRequest): Observable<Page<Organisation>> {
        let queryParams = createPageParams(page, pageSize, sort, searchTerm);
        if (filterRequest) {
            queryParams = {...queryParams, ...filterRequest};
        }
        return this.http.get<Page<Organisation>>(environment.url + `${this.baseUrl}`, { params: queryParams })
            .pipe(map((res: any) => res || {}));
    }

    getOrganisationsByCountryName(countryName: string): Observable<Organisation[]> {
        return this.http.get<Organisation[]>(environment.url + `${this.baseUrl}/country-name/${countryName}`)
            .pipe(map((res: any) => res || []));
    }

    getOrganisationsByCountryNameAndCity(countryName: string, cityName: string): Observable<Organisation[]> {
        return this.http.get<Organisation[]>(environment.url + `${this.baseUrl}/country-name/${countryName}/city/${cityName}`)
            .pipe(map((res: any) => res || []));
    }

}
