import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdverseEventComponent } from './pages/adverse-event/adverse-event.component';
import { ClinicalOutcomesComponent } from './pages/clinical-outcomes/clinical-outcomes.component';
import { ClinicianFormComponent } from './pages/clinician-form/clinician-form.component';
import { ComobidityManagementComponent } from './pages/comobidity-management/comobidity-management.component';
import { DrugInteractionComponent } from './pages/drug-interaction/drug-interaction.component';
import { RiskPredectionComponent } from './pages/risk-predection/risk-predection.component';

const routes: Routes = [
  {
    path: 'clinician-form',
    // canActivate: [AuthenticationGuardService, UserEnabledGuardService],
    component: ClinicianFormComponent
  },
  {
    path: 'clinician-form/edit',
    component: ClinicianFormComponent
  },
  {
    path: 'clinical-outcomes',
    component: ClinicalOutcomesComponent
  },
  {
    path: 'comobidity-management',
    component: ComobidityManagementComponent
  },
  {
    path: 'risk-predection',
    component: RiskPredectionComponent
  },
  {
    path: 'drug-interaction',
    component: DrugInteractionComponent
  },
  {
    path: 'adverse-event',
    component: AdverseEventComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SmarthivClinicianRoutingModule { }
