/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuardService } from './shared/services/guard/authentication-guard.service';
import { UserEnabledGuardService } from './shared/services/guard/user-enabled-guard.service';
import { NotFoundComponent } from './shared/module/layout/not-found/not-found.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ForbiddenComponent } from './shared/module/layout/forbidden/forbidden.component';
import { LoginComponent } from './modules/user/pages/login/login.component';
import { RegisterComponent } from './modules/user/pages/register/register.component';
import { MainComponent } from './modules/main/pages/main/main.component';
import { OtpConfirmComponent } from './modules/user/pages/otp-confirm/otp-confirm.component';
import { OtpGenerateComponent } from './modules/user/pages/otp-generate/otp-generate.component';
import { AuditlyticsPermissionGuardService } from './shared/services/guard/auditlytics-permission-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthenticationGuardService, UserEnabledGuardService, AuditlyticsPermissionGuardService],
    children: [
        {
            path: '',
            loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule)
        },
        {
          path: 'smarthiv-clinician',
          loadChildren: () => import('./modules/smarthiv-clinician/smarthiv-clinician.module').then(m => m.SmarthivClinicianModule),
          canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['SYSTEM_ADMINISTRATOR', 'SMART_HIV_CLINICIAN'],
              redirectTo: '/forbidden'
            }
          }
        },
        {
          path: 'smarthiv-manager',
          loadChildren: () => import('./modules/smarthiv-manager/smarthiv-manager.module').then(m => m.SmarthivManagerModule),
          canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['SYSTEM_ADMINISTRATOR', 'SMART_HIV_MANAGER'],
              redirectTo: '/forbidden'
            }
          }
        },
        {
          path: 'smarthiv-trialist',
          loadChildren: () => import('./modules/smarthiv-trialist/smarthiv-trialist.module').then(m => m.SmarthivTrialistModule),
          canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['SYSTEM_ADMINISTRATOR', 'SMART_HIV_TRIALIST'],
              redirectTo: '/forbidden'
            }
          }
        },
        {
          path: 'smarthiv-auditlytics',
          loadChildren: () => import('./modules/smarthiv-auditlytics/smarthiv-auditlytics.module').then(m => m.SmarthivAuditlyticsModule),
          canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard, AuditlyticsPermissionGuardService],
          data: {
            permissions: {
              only: ['SYSTEM_ADMINISTRATOR', 'SMART_HIV_AUDITLYTICS'],
              redirectTo: '/forbidden'
            }
          }
        },
        {
          path: 'subscription',
          loadChildren: () => import('./modules/subscription/subscription.module').then(m => m.SubscriptionModule),
          canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard],
          data: {
            permissions: {
              only: [],
              redirectTo: '/forbidden'
            }
          }
        },
        {
          path: 'chat',
          loadChildren: () => import('./modules/chat/chat.module').then(m => m.ChatModule),
          canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard],
          data: {
            permissions: {
              only: [],
              redirectTo: '/forbidden'
            }
          }
        },
        {
          path: 'administrator',
          loadChildren: () => import('./modules/administrator/administrator.module').then(m => m.AdministratorModule),
          canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['SYSTEM_ADMINISTRATOR'],
              redirectTo: '/forbidden'
            }
          }
        },
        {
          path: 'third-party',
          loadChildren: () => import('./modules/third-party/third-party.module').then(m => m.ThirdPartyModule),
          canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['SYSTEM_ADMINISTRATOR'],
              redirectTo: '/forbidden'
            }
          }
        },
        {
          path: 'organisation',
          loadChildren: () => import('./modules/organisation/organisation.module').then(m => m.OrganisationModule),
          canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard],
          data: {
            permissions: {
              only: ['SYSTEM_ADMINISTRATOR','CLINICIAN','STANDALONE_CLINICIAN','SMART_HIV_MANAGER','SMART_HIV_TRIALIST','SMART_HIV_AUDITLYTICS', 'SMART_HIV_CLINICIAN'],
              redirectTo: '/forbidden'
            }
          }
        },
        {
          path: 'user-profile',
          loadChildren: () => import('./modules/user-profile/user-profile.module').then(m => m.UserProfileModule),
          canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard],
          data: {
            permissions: {
              only: [],
              redirectTo: '/forbidden'
            }
          }
        }
    ]
  },
  {
    path: 'demographics',
    loadChildren: () => import('./modules/demographics/demographics.module').then(m => m.DemographicsModule)
  },
  {
    path: 'disclosure',
    loadChildren: () => import('./modules/disclosure/disclosure.module').then(m => m.DisclosureModule)
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'user/register',
    component: RegisterComponent
  },
  {
    path: 'user/otp-generate',
    component: OtpGenerateComponent
  },
  {
    path: 'user/otp-confirm/:otpType/:emailOrPhoneNumber',
    component: OtpConfirmComponent
  },
  {path: 'forbidden', component: ForbiddenComponent},
  {path: 'error404', component: NotFoundComponent},
  {path: '**', redirectTo: '/error404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
