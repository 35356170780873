import {Component, OnInit} from '@angular/core';
import {ClinicalOutcome, ClinicalOutcomeDashboardResponse} from '../../../../shared/model/clinical-outcome';
import {Suppression} from '../../../../shared/model/suppression';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../../shared/module/notification/service/notification.service';
import {SuppressionService} from '../../../../shared/services/suppression.service';
import {QuestionsService} from '../../../../shared/services/questions.service';
import {ClinicalOutcomeService} from '../../../../shared/services/clinical-outcome.service';
import {AdverseEventRequest, AdverseEventResponse, SuppressionData} from '../../../../shared/model/adverse-event';
import {AdverseEventService} from '../../../../shared/services/adverse-event.service';

@Component({
  selector: 'app-adverse-event',
  templateUrl: './adverse-event.component.html',
  styleUrls: ['./adverse-event.component.scss']
})
export class AdverseEventComponent implements OnInit {


  clinicalOutcome: ClinicalOutcome;
  adverseEventRequest: AdverseEventRequest;
  suppressionList: Suppression[];
  adverseEventResponse: AdverseEventResponse[];


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private adverseEventService: AdverseEventService,
    private ad: SuppressionService,
    private questionsService: QuestionsService,
    private clinicalOutcomeService: ClinicalOutcomeService
  ) {
  }

  ngOnInit(): void {
    this.clinicalOutcomeService.getLatestClicalOutcomeResult().subscribe(result => {
      this.suppressionList = result.suppressionList;
      this.clinicalOutcome = result.clinicalOutcome;
      this.adverseEventRequest = this.initializeAdverseEventRequest(result);
      this.adverseEventService.getResults(this.adverseEventRequest).subscribe(response => {
        this.adverseEventResponse = response;
      }, error => {
        if (error.status !== 404) {
          console.log('Error on getting drug interaction result: ' + JSON.stringify(error));
          this.notificationService.error(this.translate.instant('CLINICAL_OUTCOME.NOTIFICATION.GET_FAILED'));
        }
      });
    });

  }

  goToFormPage() {
    this.router.navigateByUrl('smarthiv-clinician/clinician-form');
  }

  isValueExistInList(propertyName: string) {
    for (const sup of this.suppressionList) {
      if (sup[propertyName]) {
        return true;
      }
    }
    return false;
  }

  initializeAdverseEventRequest(result: ClinicalOutcomeDashboardResponse): AdverseEventRequest {
    const request: AdverseEventRequest = {
      suppressionData: this.getSuppressionData(result)
    };
    return request;
  }

  getSuppressionData(result: ClinicalOutcomeDashboardResponse): SuppressionData[] {
    const suppressionData: SuppressionData[] = [];
    for (const r in result.suppressionList) {
      if (result.suppressionList.hasOwnProperty(r)) {
        const newSuppressionData = {
          art: result.suppressionList[r].artCodes,
          name: result.suppressionList[r].artCodeNames,
          artAgents: result.suppressionList[r].artCodeCombination.split(','),
          artAgentNames: result.suppressionList[r].artCodeNamesTokens.split(',')
        };
        suppressionData.push(newSuppressionData);
      }
    }
    return suppressionData;
  }


  getSuppressionDataDetails(result: ClinicalOutcomeDashboardResponse): SuppressionData[] {
    const suppressionData: SuppressionData[] = [];
    for (const r in result.suppressionList) {
      if (result.suppressionList.hasOwnProperty(r)) {
        const newSuppressionData = {
          art: result.suppressionList[r].artCodes,
          name: result.suppressionList[r].artCodeNames,
          artAgents: result.suppressionList[r].artCodeCombination.split(','),
          artAgentNames: result.suppressionList[r].artCodeNamesTokens.split(',')
        };
        suppressionData.push(newSuppressionData);
      }
    }
    return suppressionData;
  }

  calculateClasses(value) {
    return {message: value <= 10,
      likely: value > 10 && value <= 30,
      definite: value > 30};

  }
}
