/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/semi */
import { Component, OnDestroy } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { TableColumn } from '@swimlane/ngx-datatable';
import { DatatablePage } from '../model/datatable-page';
import { PageableSort } from '../model/pagination';
import { Subscription } from 'rxjs';

@Component({
    template: ''
})
export abstract class BaseListComponent implements OnDestroy {

    rows = [];
    columns: TableColumn[];
    allColumnList: TableColumn[];
    page: DatatablePage = new DatatablePage();
    sort: PageableSort;
    searchTerm = '';

    selectedRows = [];
    rowSelectionType: 'multiple'|'single' = 'single';
    rowSelectionProperty = 'id';
    selectAllRowsVisible = false;

    private subscriptions: Array<Subscription> = [];

    constructor() { }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    public abstract loadData();

    public subscription(subscription: Subscription) {
        this.subscriptions.push(subscription);
    }

    setPageNumber(pageNumber) {
        this.page.pageNumber = pageNumber-1;
        this.onPageChanged(this.page);
    }

    onPageChanged(changedPage) {
        this.page = changedPage;
        this.loadData();
    }

    onSortChanged(sort) {
        this.sort = new PageableSort({ property: sort.prop, direction: sort.dir });
        this.loadData();
    }

    onSearchTermChanged(text: string) {
        this.searchTerm = text;
        this.page.pageNumber = 0;
        this.onPageChanged(this.page);
    }

    onTableRowSelected(rowVal, selected) {
        if (selected) {
            if (this.rowSelectionType === 'single') {
                this.selectedRows = [];
            }
            const found = this.isRowSelected(rowVal);
            if (!found) {
                this.selectedRows.push(rowVal);
            }
        } else {
            this.selectedRows = this.selectedRows.filter(obj => obj[this.rowSelectionProperty] !== rowVal[this.rowSelectionProperty]);
        }
    }

    isRowSelected(rowVal) {
        for (const obj of this.selectedRows) {
            if (obj[this.rowSelectionProperty] === rowVal[this.rowSelectionProperty]) {
                return true;
            }
        }
        return false;
    }

    onSelectAllRows() {
        console.log('Select all rows clicked');
    }

     createDialogConfig(data): MatDialogConfig {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = false;
        dialogConfig.minWidth = '60vw';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = data;

        return dialogConfig;
    }
}
