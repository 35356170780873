/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Page, Pageable, PageableSort } from '../model/pagination';
import { map } from 'rxjs/operators';
import { ArtCode } from '../model/art-code';
import { createPageParams } from '../util';

@Injectable({
    providedIn: 'root'
})
export class ArtCodeService {

    private baseUrl = '/art-codes';

    constructor(private http: HttpClient) {
    }

    uploadFile(file: File): Observable<HttpEvent<{}>> {
        const fileAlias = 'file';
        const formData = new FormData();
        formData.set(fileAlias, file, file.name);

        let myheaders = new HttpHeaders();
        myheaders = myheaders.append('Accept', 'application/json');
        myheaders = myheaders.append('Content-Type', 'multipart/form-data');

        return this.http.post<any>(environment.url + `${this.baseUrl}/upload`, formData, {
            observe: 'events',
            reportProgress: true,
            responseType: 'json'
        });
    }

    getArtCodes(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<ArtCode>> {
        const pageParams = createPageParams(page, pageSize, sort, searchTerm);

        return this.http.get<Page<ArtCode>>(environment.url + `${this.baseUrl}`, { params: pageParams })
            .pipe(map((res: any) => (res || {})
            ));
    }

}
