import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ClinicalOutcome} from 'src/app/shared/model/clinical-outcome';
import {QuestionOptionType} from 'src/app/shared/model/enum/question-option-type';
import {QuestionGroup, QuestionOption} from 'src/app/shared/model/question';
import {NotificationService} from 'src/app/shared/module/notification/service/notification.service';
import {ClinicalOutcomeService} from 'src/app/shared/services/clinical-outcome.service';
import {QuestionsService} from 'src/app/shared/services/questions.service';
import {QuestionGroupComponent} from '../../component/question-group/question-group.component';
import {SuppressionService} from 'src/app/shared/services/suppression.service';
import {MutationService} from 'src/app/shared/services/mutation.service';
import {DrugInteractionService} from 'src/app/shared/services/drug-interaction.service';
import {DrugInteractionMedicationModelResponse} from '../../../../shared/model/drug-interaction';
import {MutationModelResponse} from '../../../../shared/model/mutation';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-clinician-form',
  templateUrl: './clinician-form.component.html',
  styleUrls: ['./clinician-form.component.scss']
})
export class ClinicianFormComponent implements OnInit {

  @ViewChildren(QuestionGroupComponent) viewQuestionGroups: QueryList<QuestionGroupComponent>;
  questionGroups: QuestionGroup[];
  clinicalOutcome: ClinicalOutcome;
  isEditMode = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private suppressionService: SuppressionService,
    private mutationService: MutationService,
    private drugInteractionService: DrugInteractionService,
    private questionsService: QuestionsService,
    private clinicalOutcomeService: ClinicalOutcomeService
  ) {
  }

  ngOnInit(): void {
    this.isEditMode = this.router.url.endsWith('edit');
    if (this.isEditMode) {
      this.loadClinicalOutcome();
    } else {
      this.loadQuestions();
    }

    this.questionsService.getQuestionSelectionChange().subscribe(changedQuestion => {
      if (changedQuestion.id === 'categoryOfMedication') {
        const selectedOptionCategoryOfMedication = changedQuestion.options.filter(opt => opt.selected === true);
        if (selectedOptionCategoryOfMedication) {
          const r: string[] = [];
          for (const item of selectedOptionCategoryOfMedication) {
            r.push(item.name);
          }
          this.loadMedications(this.questionGroups, r);
        }
      }

      if (changedQuestion.id === 'mutationClass') {
        const selectedOptionMutationClass = changedQuestion.options.filter(opt => opt.selected === true);
        if (selectedOptionMutationClass) {
          const r: string[] = [];
          for (const item of selectedOptionMutationClass) {
            r.push(item.name);
          }
          this.loadMutations(this.questionGroups, r);
        }
      }
    });
  }

  loadClinicalOutcome() {
    this.clinicalOutcomeService.getLatestClicalOutcomeResult().subscribe(result => {
      this.clinicalOutcome = result.clinicalOutcome;
      this.loadQuestions();
    }, error => {
      if (error.status !== 404) {
        console.log('Error on clinical outcome fetch: ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('CLINICAL_OUTCOME.NOTIFICATION.GET_FAILED'));
      }
    });
  }

  loadQuestions() {
    this.questionsService.loadClinicianFormQuestions().subscribe(data => {
      this.loadDynamicData(data);
    });
  }

  loadDynamicData(qgList: QuestionGroup[]) {

    const observables = [];

    const observableArtCodeNames = this.suppressionService.getArtCodeNames();
    const observableCategoryOfMedication = this.drugInteractionService.getCategoriesOfMedications();
    const observableMutationClass = this.mutationService.getMutationClasses();

    observables.push(observableArtCodeNames);
    observables.push(observableCategoryOfMedication);
    observables.push(observableMutationClass);
    if (this.clinicalOutcome && this.clinicalOutcome.categoryOfMedication) {
      const observableMedication = this.drugInteractionService.getMedications(this.clinicalOutcome.categoryOfMedication);
      observables.push(observableMedication);
    }

    if (this.clinicalOutcome && this.clinicalOutcome.mutationClass) {
      const observableMutations = this.mutationService.getMutationsModelData(this.clinicalOutcome.mutationClass);
      observables.push(observableMutations);
    }



    forkJoin(observables).subscribe(result => {
      const resultArray = result.map(a => a);

      for (const qg of qgList) {
        for (const q of qg.questions) {
          if (q.id === 'mutationClass') {
            const tempOptions = this.convertToQuestionOptions(resultArray[2] as string[]);
            q.options = tempOptions;
          }
          if (q.id === 'artCodeNameChoiceMain') {
            const tempOptions = this.convertToQuestionOptions(resultArray[0] as string[]);
            q.options = tempOptions;
          }
          if (q.id === 'categoryOfMedication') {
            const tempOptions = this.convertToQuestionOptions(resultArray[1] as string[]);
            q.options = tempOptions;
          }
          if (q.id === 'medication' && this.clinicalOutcome && this.clinicalOutcome.categoryOfMedication) {
            const tempOptions = this.convertToQuestionOptions(resultArray[3] as string[]);
            q.options = tempOptions;
          }
          if (q.id === 'mutationName' && this.clinicalOutcome && this.clinicalOutcome.mutationClass) {
            const tempOptions = this.convertToQuestionOptions(resultArray[4] as string[]);
            q.options = tempOptions;
          }
        }
      }
      if (this.clinicalOutcome) {
        this.questionsService.fillSelectedOptionsOfQuestions(this.clinicalOutcome, qgList);
      }
      this.questionGroups = qgList;
    });

  }

  loadMedications(qgList: QuestionGroup[], categoryOfMedication: string[]) {
    this.drugInteractionService.getMedicationsModels(categoryOfMedication).subscribe(data => {

      for (const qg of qgList) {
        for (const q of qg.questions) {
          if (q.id === 'medication') {
            const tempOptions = this.convertToQuestionOptionsForMedication(data);
            q.options = tempOptions;
          }
        }
      }
    });
  }

  loadMutations(qgList: QuestionGroup[], mutationClass: string[]) {
    this.mutationService.getMutationsModelData(mutationClass).subscribe(data => {
      for (const qg of qgList) {
        for (const q of qg.questions) {
          if (q.id === 'mutationName') {
            const tempOptions = this.convertToQuestionOptionsForMutation(data);
            q.options = tempOptions;
          }
        }
      }
    });
  }

  convertToQuestionOptionsForMedication(data: DrugInteractionMedicationModelResponse[]) {
    const opts: QuestionOption[] = [];
    for (const d of data) {
      const opt = new QuestionOption();
      opt.id = d.medicineName;
      opt.name = d.medicineName + '\t\t(Category: '+d.medicineCategory+')';
      opt.selected = false;
      opts.push(opt);
    }
    return opts;
  }

  convertToQuestionOptionsForMutation(data: MutationModelResponse[]) {
    const opts: QuestionOption[] = [];
    for (const d of data) {
      const opt = new QuestionOption();
      opt.id = d.mutationName+'_'+d.mutationClass;

      opt.name = d.mutationName + '\t\t(Class: '+d.mutationClass+')';
      opt.selected = false;
      opts.push(opt);
    }
    return opts;
  }

  convertToQuestionOptions(data: string[]) {
    const opts: QuestionOption[] = [];
    for (const d of data) {
      const opt = new QuestionOption();
      opt.id = d;
      opt.name = d;
      opt.selected = false;
      opts.push(opt);
    }
    return opts;
  }

  onClinicalOutcomes() {
    const newClinicalOutcome = new ClinicalOutcome();
    let allQuestionGroupsValid = true;
    this.viewQuestionGroups.forEach(qv => {
      const valid = qv.validate();
      if (valid === false) {
        allQuestionGroupsValid = false;
      }
    });

    if (allQuestionGroupsValid === true) {
      for (const questionGroup of this.questionGroups) {
        for (const question of questionGroup.questions) {
          const selectedOpts = question.options.filter(o => o.selected).map(o => o.id);
          const isSingleSelect = QuestionOptionType[question.type] === QuestionOptionType.SINGLE_SELECT
            || QuestionOptionType[question.type] === QuestionOptionType.COMBO_SINGLE_SELECT;
          newClinicalOutcome[question.id] = isSingleSelect ? selectedOpts[0] : selectedOpts;
        }
      }
      if (this.isEditMode) {
        this.updateClinicalOutcome(newClinicalOutcome);
      } else {
        this.saveClinicalOutcome(newClinicalOutcome);
      }

    } else {
      this.notificationService.error(this.translate.instant('CLINICAL_OUTCOME.NOTIFICATION.QUESTIONS_NOT_VALID'));
    }
  }

  saveClinicalOutcome(newClinicalOutcome: ClinicalOutcome) {
    this.clinicalOutcomeService.createClinicalOutcome(newClinicalOutcome).subscribe(result => {
      this.router.navigateByUrl('smarthiv-clinician/clinical-outcomes');
    }, error => {
      console.log('Error on create clinical outcome: ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('CLINICAL_OUTCOME.NOTIFICATION.SAVE_FAILED'));
    });
  }

  updateClinicalOutcome(newClinicalOutcome: ClinicalOutcome) {
    this.clinicalOutcomeService.updateClinicalOutcome(this.clinicalOutcome.id, newClinicalOutcome).subscribe(result => {
      this.router.navigateByUrl('smarthiv-clinician/clinical-outcomes');
    }, error => {
      console.log('Error on update clinical outcome: ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('CLINICAL_OUTCOME.NOTIFICATION.UPDATE_FAILED'));
    });

  }

}
