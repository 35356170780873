/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/shared/component/base.component';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Plan } from '../../../../shared/model/subscription';
import { PlanService } from '../../../../shared/services/plan.service';

import { Currency } from 'src/app/shared/model/enum/currency';
import { ApplicationType } from 'src/app/shared/model/enum/application-type';
import { Interval } from 'src/app/shared/model/enum/interval';
import { PageMode } from 'src/app/shared/model/enum/page-mode';
@Component({
  selector: 'app-subscription-plan-detail',
  templateUrl: './subscription-plan-detail.component.html',
  styleUrls: ['./subscription-plan-detail.component.scss']
})
export class SubscriptionPlanDetailComponent extends BaseComponent implements OnInit {

  Currency: typeof Currency = Currency;
  ApplicationType: typeof ApplicationType = ApplicationType;
  Interval: typeof Interval = Interval;
  PageMode: typeof PageMode = PageMode;

  currencyOptions: string[];
  applicationTypeOptions: string[];
  intervalOptions: string[];

  plan: Plan;

  formGroup: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(200)]),
    description: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(250)]),
    applicationType: new FormControl('', [Validators.required]),
    amount: new FormControl('', [Validators.required]),
    currency: new FormControl('', [Validators.required]),
    intervalCount: new FormControl('', [Validators.required, Validators.min(1)]),
    interval: new FormControl('', [Validators.required]),
    maxClinicianCount: new FormControl(''),
    maxPatientCount: new FormControl(''),
    additionalUserAmount: new FormControl(''),
    organisationalPlan: new FormControl(''),
    active: new FormControl(''),
    publish: new FormControl(''),
    renewable: new FormControl(''),
    defaultPlan: new FormControl(''),
    popular: new FormControl('')
  });

  constructor(
    public dialogRef: MatDialogRef<SubscriptionPlanDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private planService: PlanService
  ) {
    super();
  }

  ngOnInit(): void {
    const cOptions: string[] = Object.keys(Currency);
    this.currencyOptions = cOptions.slice(cOptions.length / 2);

    const atOptions: string[] = Object.keys(ApplicationType);
    this.applicationTypeOptions = atOptions.slice(atOptions.length / 2);

    const iOptions: string[] = Object.keys(Interval);
    this.intervalOptions = iOptions.slice(iOptions.length / 2);

    this.patchForms(this.data.row);

    if (this.data.pageMode && !(this.data.pageMode === PageMode.CREATE || this.data.pageMode === PageMode.EDIT)) {
      this.formGroup.disable();
    }
  }

  submitForm() {
    console.log('Form submitted : ' + JSON.stringify(this.data.row));

  }

  onCreate(): void {
    if (this.formGroup.valid) {
      const rawVal = this.formGroup.getRawValue();

      this.planService.createPlan(rawVal).subscribe(plan => {
        this.notificationService.success(this.translate.instant('SUBSCRIPTION.PLAN.DETAIL.NOTIFICATION.PLAN_CREATE.SUCCESS'));
        this.closeDialog(true);
      }, error => {
        console.log('Error on create subscription plan : ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('SUBSCRIPTION.PLAN.DETAIL.NOTIFICATION.PLAN_CREATE.FAIL'));
      });
    }
  }

  onEdit(): void {
    if (this.formGroup.valid) {
      const rawVal = this.formGroup.getRawValue();

      this.planService.updatePlan(this.data.row.id, rawVal).subscribe(plan => {
        this.notificationService.success(this.translate.instant('SUBSCRIPTION.PLAN.DETAIL.NOTIFICATION.PLAN_EDIT.SUCCESS'));
        this.closeDialog(true);
      }, error => {
        console.log('Error on edit subscription plan : ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('SUBSCRIPTION.PLAN.DETAIL.NOTIFICATION.PLAN_EDIT.FAIL'));
      });
    }
  }

  onDelete(): void {

    this.planService.deletePlan(this.data.row.id).subscribe(() => {
      this.notificationService.success(this.translate.instant('SUBSCRIPTION.PLAN.DETAIL.NOTIFICATION.PLAN_DELETE.SUCCESS'));
      this.closeDialog(true);
    }, error => {
      console.log('Error on delete subscription plan : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('SUBSCRIPTION.PLAN.DETAIL.NOTIFICATION.PLAN_DELETE.FAIL'));
    });
  }

  closeDialog(isChanged: boolean) {
    this.dialogRef.close({status: 'dialog closed', refreshTable: isChanged});
  }

  patchForms(plan: Plan) {
    if (plan) {
      this.formGroup.patchValue(plan);
      this.plan = plan;
    }
  }

}
