/* eslint-disable @typescript-eslint/ban-types */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Page, PageableSort } from '../model/pagination';
import { createPageParams } from '../util';
import {
  AtRiskResults
} from '../model/at-risk-result';

@Injectable({
  providedIn: 'root'
})
export class AtRiskResultsService {
  private baseUrl = '/at-risk-result';

  constructor(private http: HttpClient) {
  }

  getAllAtRiskResults(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<AtRiskResults>> {
    const pageParams = createPageParams(page, pageSize, sort, searchTerm);
    return this.http.get<Page<AtRiskResults>>(environment.url + `${this.baseUrl}/find-all-pages`)
      .pipe(map((res: any) => res || {}));
  }
}
