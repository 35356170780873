/* eslint-disable max-len */
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import {NotificationService} from 'src/app/shared/module/notification/service/notification.service';
import {DrugInteractionService} from 'src/app/shared/services/drug-interaction.service';
import {DrugInteractionUploadComponent} from '../../component/drug-interaction-upload/drug-interaction-upload.component';

@Component({
  selector: 'app-drug-interaction-list',
  templateUrl: './drug-interaction-list.component.html',
  styleUrls: ['./drug-interaction-list.component.scss']
})
export class DrugInteractionListComponent extends BaseListComponent implements OnInit {
  @ViewChild('idTemplate', {static: true}) idTemplate: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private drugInteractionService: DrugInteractionService
  ) {
    super();
  }

  ngOnInit(): void {

    this.allColumnList = [
      {
        prop: 'id',
        name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.ID'),
        cellTemplate: this.idTemplate,
        width: 100,
        maxWidth: 100
      },
      { prop: 'categoryOfMedications', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CATEGORY_OF_MEDICATION'), cellClass: '' },
      { prop: 'medications', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.MEDICATIONS'), cellClass: '' },
      { prop: 'code0020Code0005Code0007', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0020CODE0005CODE0007'), cellClass: '' },
      { prop: 'code0019', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0019'), cellClass: '' },
      { prop: 'code0001Code0002Code0019', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0001CODE0002CODE0019'), cellClass: '' },
      { prop: 'code0019Code0002', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0019CODE0002'), cellClass: '' },
      { prop: 'code0019Code0014', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0019CODE0014'), cellClass: '' },
      { prop: 'code0017Code0005Code0007', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0017CODE0005CODE0007'), cellClass: '' },
      { prop: 'code0017Code0005Code0008', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0017CODE0005CODE0008'), cellClass: '' },
      { prop: 'code0035', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0035'), cellClass: '' },
      { prop: 'code0016', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0016'), cellClass: '' },
      { prop: 'code0021Code0002Code0008', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0021CODE0002CODE0008'), cellClass: '' },
      { prop: 'code0011', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0011'), cellClass: '' },
      { prop: 'code0050', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0050'), cellClass: '' },
      { prop: 'code0004', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0004'), cellClass: '' },
      { prop: 'code0014', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0014'), cellClass: '' },
      { prop: 'code0014Code0019', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0014CODE0019'), cellClass: '' },
      { prop: 'code0014Code0005Code0007', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0014CODE0005CODE0007'), cellClass: '' },
      { prop: 'code0001', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0001'), cellClass: '' },
      { prop: 'code0028', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0028'), cellClass: '' },
      { prop: 'code0005', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0005'), cellClass: '' },
      { prop: 'code0005Code0007', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0005CODE0007'), cellClass: '' },
      { prop: 'code0005Code0008', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0005CODE0008'), cellClass: '' },
      { prop: 'code0002', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0002'), cellClass: '' },
      { prop: 'code0012', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0012'), cellClass: '' },
      { prop: 'code0008', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0008'), cellClass: '' },
      { prop: 'code0010', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0010'), cellClass: '' },
      { prop: 'code0025', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0025'), cellClass: '' },
      { prop: 'code0006', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0006'), cellClass: '' },
      { prop: 'code0009', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0009'), cellClass: '' },
      { prop: 'code0013', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0013'), cellClass: '' },
      { prop: 'code0013Code0005Code0007', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0013CODE0005CODE0007'), cellClass: '' },
      { prop: 'code0015', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0015'), cellClass: '' },
      { prop: 'code0043', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0043'), cellClass: '' },
      { prop: 'code0044', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0044'), cellClass: '' },
      { prop: 'code0046', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0046'), cellClass: '' },
      { prop: 'code0037', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0037'), cellClass: '' },
      { prop: 'code0048', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0048'), cellClass: '' },
      { prop: 'code0051', name: this.translate.instant('DRUG_INTERACTION_LIST.COL_LBL.CODE0051'), cellClass: '' },

    ];

    this.columns = [
      this.allColumnList[0],
      this.allColumnList[1],
      this.allColumnList[2],
      this.allColumnList[3],
      this.allColumnList[4],
      this.allColumnList[5],
      this.allColumnList[6],
      this.allColumnList[7]
    ];


    this.loadData();
  }


  openUploadDialog(): void {
    const dialogConfig =  this.createDialogConfig(null);
    const dialogRef = this.dialog.open(DrugInteractionUploadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.loadData();
    });
  }

  loadData() {
    this.drugInteractionService.getDrugInteractions(this.page.pageNumber, this.page.size, this.sort).subscribe(page => {
      this.page.pageNumber = page.number;
      this.page.totalElements = page.totalElements;
      this.page.size = page.size;
      this.rows = page.content;
    });
  }

}
