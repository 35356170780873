import { AuditlyticsPermission } from './auditlytics-permission';

export class UserProfile {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  password: string;
  roles: number;
}
export class ChangeMyPasswordRequest {
  currentPassword: string;
  newPassword: string;
}

export class CheckEmailOrPhoneResponse {
  emailOrPhoneNumber: string;
  exist: boolean;
}

export class RegisterRequest {
  email: string;
  emailOrPhoneNumber: string;
  firstName: string;
  lastName: string;
  password: string;
  roles: number;
  gender: string;
  communicationType: string;
  returnUrl: string;
  otpEnabled: boolean;
}

export class LoginRequest {
  emailOrPhoneNumber: string;
  password: string;
  returnUrl: string;
}

export class AccessTokenResponse {
  newAccessToken: string;
  message: string;
}

export class LoginResponse {
  email: string;
  phoneNumber: string;
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  photoUrl: string;
  roles: number;
  otpEnabled: boolean;
  auditlyticsPermission: AuditlyticsPermission;
  permissionLevel: string;
  organisationOwner: boolean;

  clinicianOrganisationId: number;
  clinicianOrganisationName: string;

  clinicianOrganisationBranchId: number;
  clinicianOrganisationBranchName: string;

  patientOrganisationId: number;
  patientOrganisationName: string;

  patientOrganisationBranchId: number;
  patientOrganisationBranchName: string;
}

export class RegisterResponse {
  email: string;
  phoneNumber: string;
  roles: number;
  communicationType: string;
  otpEnabled: boolean;
}

export class OtpResponse {
  email: string;
  phoneNumber: string;
  type: string;
  expireAt: string;
  result: boolean;
  message: string;
  payload: string;
}

export class ValidateOtpRequest {
  emailOrPhoneNumber: string;
  otp: string;
}

export class ResetPasswordValidateOtpRequest {
  emailOrPhoneNumber: string;
  otp: string;
  password: string;
}




