/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Page, PageableSort } from '../model/pagination';
import { createPageParams } from '../util';
import { AuditlyticsPermission } from '../model/auditlytics-permission';

@Injectable({
    providedIn: 'root'
})
export class AuditlyticsPermissionService {

    private baseUrl = '/ums/v1/auditlytics-permissions';
    private baseUrlAdmnin = '/ums/v1/admin-ops/auditlytics-permissions';

    constructor(private http: HttpClient) {
    }

    createAuditlyticsPermissionByAdmin(userId: number, request: AuditlyticsPermission): Observable<AuditlyticsPermission> {
        return this.http.post<AuditlyticsPermission>(environment.url + `${this.baseUrlAdmnin}/user/${userId}`, request)
        .pipe(map((res: any) => res || {}));
    }

    createAuditlyticsPermissionByOrgManager(userId: number, request: AuditlyticsPermission): Observable<AuditlyticsPermission> {
        return this.http.post<AuditlyticsPermission>(environment.url + `${this.baseUrl}/user/${userId}`, request)
        .pipe(map((res: any) => res || {}));
    }

    updateAuditlyticsPermissionByAdmin(auditlyticsPermissionId: number, request: AuditlyticsPermission): Observable<AuditlyticsPermission> {
        return this.http.put<AuditlyticsPermission>(environment.url + `${this.baseUrlAdmnin}/${auditlyticsPermissionId}`, request)
        .pipe(map((res: any) => res || {}));
    }

    updateAuditlyticsPermissionByOrgManager(auditlyticsPermissionId: number, request: AuditlyticsPermission): Observable<AuditlyticsPermission> {
        return this.http.put<AuditlyticsPermission>(environment.url + `${this.baseUrl}/${auditlyticsPermissionId}`, request)
        .pipe(map((res: any) => res || {}));
    }

    deleteAuditlyticsPermissionByAdmin(auditlyticsPermissionId: number): Observable<void> {
        return this.http.delete<void>(environment.url + `${this.baseUrlAdmnin}/${auditlyticsPermissionId}`);
    }

    deleteAuditlyticsPermissionByOrgManager(auditlyticsPermissionId: number): Observable<void> {
        return this.http.delete<void>(environment.url + `${this.baseUrl}/${auditlyticsPermissionId}`);
    }

    getAuditlyticsPermissionByAdmin(auditlyticsPermissionId: number): Observable<AuditlyticsPermission> {
        return this.http.get<AuditlyticsPermission>(environment.url + `${this.baseUrlAdmnin}/${auditlyticsPermissionId}`)
            .pipe(map((res: any) => res || {}));
    }
    getAuditlyticsPermissionByOrgManager(auditlyticsPermissionId: number): Observable<AuditlyticsPermission> {
        return this.http.get<AuditlyticsPermission>(environment.url + `${this.baseUrl}/${auditlyticsPermissionId}/by-org-manager`)
            .pipe(map((res: any) => res || {}));
    }
    getAuditlyticsPermissionByMe(): Observable<AuditlyticsPermission> {
        return this.http.get<AuditlyticsPermission>(environment.url + `${this.baseUrl}/me`)
            .pipe(map((res: any) => res || {}));
    }

    listAuditlyticsPermissionsByAdmin(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<AuditlyticsPermission>> {
        const queryParams = createPageParams(page, pageSize, sort, searchTerm);
        return this.http.get<Page<AuditlyticsPermission>>(environment.url + `${this.baseUrlAdmnin}`, { params: queryParams })
            .pipe(map((res: any) => res || {}));
    }

    listAuditlyticsPermissionsByOrgManager(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<AuditlyticsPermission>> {
        const queryParams = createPageParams(page, pageSize, sort, searchTerm);
        return this.http.get<Page<AuditlyticsPermission>>(environment.url + `${this.baseUrl}`, { params: queryParams })
            .pipe(map((res: any) => res || {}));
    }


}
