import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { City, Country, State } from '../model/geo-location';

@Injectable({
    providedIn: 'root'
})
export class GeoLocationService {

    private baseUrl = '/geo-locations';

    constructor(private http: HttpClient) {
    }

    getCountry(ciso: string): Observable<Country> {
        return this.http.get<Country>(environment.url + `${this.baseUrl}/countries/${ciso}`)
            .pipe(map((res: any) => res || {}));
    }

    getState(ciso: string, siso: string): Observable<State> {
        return this.http.get<State>(environment.url + `${this.baseUrl}/countries/${ciso}/states/${siso}`)
            .pipe(map((res: any) => res || {}));
    }

    getCity(ciso: string, siso: string, cityName: string): Observable<City> {
        return this.http.get<City>(environment.url + `${this.baseUrl}/countries/${ciso}/states/${siso}/cities/${cityName}`)
            .pipe(map((res: any) => res || {}));
    }

    getCountries(): Observable<Country[]> {
        return this.http.get<Country[]>(environment.url + `${this.baseUrl}/countries`)
            .pipe(map((res: any) => res || []));
    }

    getStates(): Observable<State[]> {
        return this.http.get<State[]>(environment.url + `${this.baseUrl}/states`)
            .pipe(map((res: any) => res || []));
    }

    getStatesOfCountry(ciso: string): Observable<State[]> {
        return this.http.get<State[]>(environment.url + `${this.baseUrl}/countries/${ciso}/states`)
            .pipe(map((res: any) => res || []));
    }

    getCitiesOfCountry(ciso: string): Observable<City[]> {
        return this.http.get<City[]>(environment.url + `${this.baseUrl}/countries/${ciso}/cities`)
            .pipe(map((res: any) => res || []));
    }

    getCitiesOfState(ciso: string, siso: string): Observable<City[]> {
        return this.http.get<City[]>(environment.url + `${this.baseUrl}/countries/${ciso}/states/${siso}/cities`)
            .pipe(map((res: any) => res || []));
    }

}
