/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ROUTE_HISTORY_LIMIT } from 'src/app/shared/constants';

export class RouteChangeModel {
  history: string[];
  newRoute: string;
}

@Injectable({
  providedIn: 'root'
})
export class RouteStateService {
  private history = [];
  routeChangeSubject: BehaviorSubject<RouteChangeModel>;

  constructor(
    private http: HttpClient,
    private router: Router) {
    const url = location.pathname.split('/')[2];
    const temp = this.createRouteChangeModel(url);
    this.routeChangeSubject = new BehaviorSubject<RouteChangeModel>(temp);
  }

  public addNavigationListener(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        const temp = this.createRouteChangeModel(urlAfterRedirects);
        this.notifyRouteChange(temp);
      });
  }

  getRouteChange(): Observable<RouteChangeModel> {
    return this.routeChangeSubject.asObservable();
  }

  notifyRouteChange(routeChange: RouteChangeModel) {
    this.routeChangeSubject.next(routeChange);
  }

  private createRouteChangeModel(urlAfterRedirects: string): RouteChangeModel {
    this.history = [...this.history, urlAfterRedirects];
    const arrayLength = this.history.length;
    if (arrayLength > ROUTE_HISTORY_LIMIT) {
      this.history = this.history.slice(arrayLength - ROUTE_HISTORY_LIMIT, arrayLength);
    }
    const temp = new RouteChangeModel();
    temp.history = this.history;
    temp.newRoute = urlAfterRedirects;
    return temp;
  }

}
