/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  Feedback
} from '../model/feedback';
import { map } from 'rxjs/operators';
import {Page, PageableSort} from '../model/pagination';
import {createPageParams} from '../util';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  private baseUrl = '/feedbacks';

  constructor(private http: HttpClient) {
  }

  getAllFeedbacks(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<Feedback>>{
    const pageParams = createPageParams(page, pageSize, sort, searchTerm);
    return this.http.get<Page<Feedback>>(environment.url + `${this.baseUrl}/find-all-responses-page`)
      .pipe(map((res: any) => res || {}));
  }
}
