<div mat-dialog-content>
    <mat-card-header>
        <mat-card-title>{{data.title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div fxLayout="row wrap" class="align-items-center">
            <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100">
                <app-search-field [label]="'USER_ADMIN.LIST.SEARCH_FIELD.LABEL'" 
                    [hint]="'USER_ADMIN.LIST.SEARCH_FIELD.HINT'"
                    (searchTermChanged)="onSearchTermChanged($event)">
                </app-search-field>
            </div>
        </div>
        <div>
            <app-datatable
                [styleClass]="'cust-no-border-datatable'"
                [allColumnList]="allColumnList"
                [columns]="columns"
                [rows]="rows"
                [page]="page"
                (pageChanged)="onPageChanged($event)"
                (sortChanged)="onSortChanged($event)">
            </app-datatable>
        </div>
    </mat-card-content>
</div>

<div mat-dialog-actions>
    <button mat-raised-button color="basic" matDialogClose="cancel">{{'GENERAL.CLOSE' | translate}}</button>
    <button mat-raised-button color="primary" (click)="onSelect()" [disabled]="selectedRows?.length === 0">{{'GENERAL.SELECT' | translate}}</button>
</div>

<ng-template #rowNumberTemplate let-row="row" let-value="value" let-rowIndex="rowIndex">
    <mat-checkbox (click)="$event.stopPropagation()"
        (change)="onTableRowSelected(row, $event.checked)"
        [checked]="isRowSelected(row)">
        <span class="ng-star-inserted">{{(page.pageNumber * page.size) + rowIndex + 1}}</span>
    </mat-checkbox>
</ng-template>
  
  <ng-template #clinicianInfoTemplate let-row="row" let-value="value">
      <div class="d-flex">
        <img class="mat-card-avatar" [src]='getProfilePhotoUrl(row.clinician)' alt=" Profile photo">
        <div class="m-l-15">
          <p class="font-bold m-b-0 m-t-0">{{ getNameStr(row.clinician) }}</p>
          <small class="text-muted" matTooltip="{{getRolesAsString(row.clinician.roles)}}">{{getRolesAsString(row.clinician.roles)}}</small>
        </div>
      </div>
  </ng-template>
  
  <ng-template #patientInfoTemplate let-row="row" let-value="value">
    <div class="d-flex">
      <img class="mat-card-avatar" [src]='getProfilePhotoUrl(row.patient)' alt=" Profile photo">
      <div class="m-l-15">
        <p class="font-bold m-b-0 m-t-0">{{ getNameStr(row.patient) }}</p>
        <small class="text-muted" matTooltip="{{getRolesAsString(row.patient.roles)}}">{{getRolesAsString(row.patient.roles)}}</small>
      </div>
    </div>
  </ng-template>
  
  <ng-template #clinicianEmailOrPhoneTemplate let-row="row" let-value="value">
    <div class="d-flex">
      <div class="m-l-0">
        <p class="font-bold m-b-0 m-t-0">{{ getEmailOrPhone(row.clinician) }}</p>
        <small class="text-muted">{{getUserUniqueId(row.clinician)}}</small>
      </div>
    </div>
  </ng-template>
  
  <ng-template #patientEmailOrPhoneTemplate let-row="row" let-value="value">
  <div class="d-flex">
    <div class="m-l-0">
      <p class="font-bold m-b-0 m-t-0">{{ getEmailOrPhone(row.patient) }}</p>
      <small class="text-muted">{{getUserUniqueId(row.patient)}}</small>
    </div>
  </div>
  </ng-template>

