import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimulationDemandUpdate } from '../../model/simulation';

@Component({
  selector: 'app-demand-dialog',
  templateUrl: './demand-dialog.component.html',
  styleUrls: ['./demand-dialog.component.scss']
})
export class DemandDialogComponent implements OnInit {

  selectedChoice: number;
  years: number[] = [3, 5, 10];

  demandControl = new FormControl('', [Validators.min(-100), Validators.max(100), Validators.required]);
  demandControlSc = new FormControl('', [Validators.min(-100), Validators.max(100), Validators.required]);
  demandPreventionControl = new FormControl('', [Validators.min(0), Validators.max(100), Validators.required]);
  demandPreventionControlSc = new FormControl('', [Validators.min(0), Validators.max(100), Validators.required]);

  experiencedControl = new FormControl('', [Validators.min(0), Validators.max(2000000), Validators.required]);
  naiveControl = new FormControl('', [Validators.min(0), Validators.max(500000), Validators.required]);
  preventionControl = new FormControl('', [Validators.min(0), Validators.max(5000000), Validators.required]);

  experiencedControlSc = new FormControl('', [Validators.min(0), Validators.max(2000000), Validators.required]);
  naiveControlSc = new FormControl('', [Validators.min(0), Validators.max(500000), Validators.required]);
  preventionControlSc = new FormControl('', [Validators.min(0), Validators.max(5000000), Validators.required]);

  demandForm: FormGroup = new FormGroup({
    demand: this.demandControl,
    demandPrevention: this.demandPreventionControl,
    demandSc: this.demandControlSc,
    demandPreventionSc: this.demandPreventionControlSc,
    experienced: this.experiencedControl,
    naive: this.naiveControl,
    prevention: this.preventionControl,
    experiencedSc: this.experiencedControlSc,
    naiveSc: this.naiveControlSc,
    preventionSc: this.preventionControlSc,
  });

  constructor(
    public dialogRef: MatDialogRef<DemandDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SimulationDemandUpdate) {
    this.selectedChoice = data.years;
  }

  ngOnInit(): void {
  }

  onSelectYear(value) {
    this.years.forEach(year => {
      if (year === value) {
        this.data.years = year;
      }
    });
  }

  onDefaultClick(): void {
    this.data.demandIncrease = this.data.demandIncreaseDefault;
    this.data.demandIncreasePrevention = 5.00;

    this.data.scDemandIncrease = this.data.demandIncreaseDefault;
    this.data.scDemandIncreasePrevention = 10.00;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public isInfoValid() {
    return this.demandForm.valid;
  }

}

