/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { UserConsent } from '../model/user-account';

@Injectable({
    providedIn: 'root'
})
export class UserConsentService {

    private baseUrl = '/ums/v1/users';

    constructor(private http: HttpClient) {
    }

    updateUserConsent(userConsent: UserConsent): Observable<UserConsent> {
        return this.http.put<UserConsent>(environment.url + `${this.baseUrl}/me/consents`, userConsent)
            .pipe(map((res: any) => res || {}));
    }

    getUserConsent(): Observable<UserConsent> {
        return this.http.get<UserConsent>(environment.url + `${this.baseUrl}/me/consents`)
            .pipe(map((res: any) => res || {}));
    }

}
