/* eslint-disable @typescript-eslint/ban-types */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Page, PageableSort } from '../model/pagination';
import {
  SurveyResults
} from '../model/survey-results';
import { FilterListData, TrialistCountryResult, TrialistSummaryResult } from '../model/trial-list';
import { createPageParams } from '../util';

@Injectable({
  providedIn: 'root'
})
export class SurveyResultsService {

  private baseUrl = '/survey-results';

  constructor(private http: HttpClient) {
  }

  getAllSurveyResults(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<SurveyResults>> {
    const pageParams = createPageParams(page, pageSize, sort, searchTerm);
    return this.http.get<Page<SurveyResults>>(environment.url + `${this.baseUrl}/find-all-pages`)
      .pipe(map((res: any) => res || {}));
  }

  getTrialistCountryResults(filterListData: FilterListData): Observable<TrialistCountryResult> {
    return this.http.post<TrialistCountryResult>(environment.url + `${this.baseUrl}/trialist/country`, filterListData)
      .pipe(map((res: any) => res || {}));
  }

  getTrialistSummaryResults(filterListData: FilterListData): Observable<TrialistSummaryResult> {
    return this.http.post<TrialistSummaryResult>(environment.url + `${this.baseUrl}/trialist/summary`, filterListData)
      .pipe(map((res: any) => res || {}));
  }

  getTrialistComorbiditiesResults(filterListData: FilterListData): Observable<TrialistSummaryResult> {
    return this.http.post<TrialistSummaryResult>(environment.url + `${this.baseUrl}/trialist/comorbidities`, filterListData)
      .pipe(map((res: any) => res || {}));
  }

  getTrialistDemographicResults(filterListData: FilterListData): Observable<TrialistSummaryResult> {
    return this.http.post<TrialistSummaryResult>(environment.url + `${this.baseUrl}/trialist/demographics`, filterListData)
      .pipe(map((res: any) => res || {}));
  }

  getAuditlyticsPatientResults(filterListData: FilterListData): Observable<TrialistSummaryResult> {
    return this.http.post<TrialistSummaryResult>(environment.url + `${this.baseUrl}/trialist/auditlytics-patient`, filterListData)
      .pipe(map((res: any) => res || {}));
  }

  getAuditlyticsComorbiditiesResults(filterListData: FilterListData): Observable<TrialistSummaryResult> {
    return this.http.post<TrialistSummaryResult>(environment.url + `${this.baseUrl}/trialist/auditlytics-comorbidities`, filterListData)
      .pipe(map((res: any) => res || {}));
  }

  getAuditlyticsAdherenceResults(filterListData: FilterListData): Observable<TrialistSummaryResult> {
    return this.http.post<TrialistSummaryResult>(environment.url + `${this.baseUrl}/trialist/auditlytics-adherence`, filterListData)
      .pipe(map((res: any) => res || {}));
  }

  getAuditlyticsRetentionResults(filterListData: FilterListData): Observable<TrialistSummaryResult> {
    return this.http.post<TrialistSummaryResult>(environment.url + `${this.baseUrl}/trialist/auditlytics-retention`, filterListData)
      .pipe(map((res: any) => res || {}));
  }

  getAuditlyticsSuppressionResults(filterListData: FilterListData): Observable<TrialistSummaryResult> {
    return this.http.post<TrialistSummaryResult>(environment.url + `${this.baseUrl}/trialist/auditlytics-suppression`, filterListData)
      .pipe(map((res: any) => res || {}));
  }

  getAuditlyticsStigmaResults(filterListData: FilterListData): Observable<TrialistSummaryResult> {
    return this.http.post<TrialistSummaryResult>(environment.url + `${this.baseUrl}/trialist/auditlytics-stigma`, filterListData)
      .pipe(map((res: any) => res || {}));
  }

  getAuditlyticsMentalResults(filterListData: FilterListData): Observable<TrialistSummaryResult> {
    return this.http.post<TrialistSummaryResult>(environment.url + `${this.baseUrl}/trialist/auditlytics-mental`, filterListData)
      .pipe(map((res: any) => res || {}));
  }

  getAuditlyticsPhysicalResults(filterListData: FilterListData): Observable<TrialistSummaryResult> {
    return this.http.post<TrialistSummaryResult>(environment.url + `${this.baseUrl}/trialist/auditlytics-physical`, filterListData)
      .pipe(map((res: any) => res || {}));
  }

  getAuditlyticsCognitiveResults(filterListData: FilterListData): Observable<TrialistSummaryResult> {
    return this.http.post<TrialistSummaryResult>(environment.url + `${this.baseUrl}/trialist/auditlytics-cognitive`, filterListData)
      .pipe(map((res: any) => res || {}));
  }

  getAuditlyticsSexualResults(filterListData: FilterListData): Observable<TrialistSummaryResult> {
    return this.http.post<TrialistSummaryResult>(environment.url + `${this.baseUrl}/trialist/auditlytics-sexual`, filterListData)
      .pipe(map((res: any) => res || {}));
  }

}
