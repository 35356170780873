/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { ApexChartOptions, CHART_COLORS_SCHEME1 } from 'src/app/shared/model/chart-options';
import { Suppression } from 'src/app/shared/model/suppression';

/* eslint-disable max-len */
@Component({
  selector: 'app-expected-patient-suppression',
  templateUrl: './expected-patient-suppression.component.html',
  styleUrls: ['./expected-patient-suppression.component.scss']
})
export class ExpectedPatientSuppressionComponent implements OnInit {
  @Input() suppressionList: Suppression[];
  @ViewChild('chartView') chart: ChartComponent = Object.create(null);

  public lineChartLabels: Array<any> = ['Week 24', 'Week 48', 'Week 96', 'Week 144'];

  public chartOptions: Partial<ApexChartOptions> = {
    series: [],
    chart: {
        type: 'line',
        height: 370,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: false
        },
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        }
    },
    responsive: [{
      breakpoint: 600,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    colors: CHART_COLORS_SCHEME1,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    markers: {
      size: 0
    },
    xaxis: {},
    yaxis: {
      title: {
        text: 'Percentage(%)'
      },
      labels: {
        formatter: (val) => val ? val.toFixed(1) : '',
      },
      min: 0,
      max: 100,
    },
    legend: {
        show: true,
        position: 'bottom',
    },
    fill: {
        colors: CHART_COLORS_SCHEME1,
        opacity: 1
    },
    tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
            show: true,
        },
    }
};

  constructor() { }

  ngOnInit(): void {

    let i = 0;
    for (const sup of this.suppressionList) {
      const tempData = [sup.pswk24, sup.pswk48, sup.pswk96, sup.pswk144];
      this.chartOptions.series.push({ data: tempData, name: sup.artCodeNames, color: CHART_COLORS_SCHEME1[i % CHART_COLORS_SCHEME1.length] });
      i++;
    }

    this.chartOptions.xaxis.categories = this.lineChartLabels;

  }
}
