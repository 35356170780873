import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FilterData, FilterListData, FilterListDataExtended } from 'src/app/shared/model/trial-list';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { StorageService } from 'src/app/shared/services/storage.service';

export interface Task {
  name: string;
  column: string;
  completed: boolean;
  color: ThemePalette;
  id: number;
  subtasks?: Task[];
}

@Component({
  selector: 'app-trial-filter',
  templateUrl: './trial-filter.component.html',
  styleUrls: ['./trial-filter.component.scss']
})
export class TrialFilterComponent implements OnInit {

  filterDataList: Array<FilterData>;

  ageControl = true;
  genderControl = true;
  raceControl = true;
  treatmentStatusControl = true;
  timeFromDiagnosisControl = true;
  opportunisticInfectionsControl = true;
  weightControl = true;
  timeOnTreatmentControl = true;
  vaccinationControl = true;
  currentlySuppressedControl = true;
  contraceptivesUseControl = true;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  taskAge: Task = {
    name: this.translate.instant('TRIAL.AGE_CLASSIFICATION'),
    id: 1,
    column: 'age',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: this.translate.instant('TRIAL.AGE_UNDER_13'), column: 'ageUnder13', completed: false, color: 'primary', id: 1 },
      { name: this.translate.instant('TRIAL.AGE_13_14'), column: 'age1314', completed: false, color: 'primary', id: 2 },
      { name: this.translate.instant('TRIAL.AGE_15_19'), column: 'age1519', completed: false, color: 'primary', id: 3 },
      { name: this.translate.instant('TRIAL.AGE_20_24'), column: 'age2024', completed: false, color: 'primary', id: 4 },
      { name: this.translate.instant('TRIAL.AGE_25_29'), column: 'age2529', completed: false, color: 'primary', id: 5 },
      { name: this.translate.instant('TRIAL.AGE_30_34'), column: 'age3034', completed: false, color: 'primary', id: 6 },
      { name: this.translate.instant('TRIAL.AGE_35_39'), column: 'age3539', completed: false, color: 'primary', id: 7 },
      { name: this.translate.instant('TRIAL.AGE_40_44'), column: 'age4044', completed: false, color: 'primary', id: 8 },
      { name: this.translate.instant('TRIAL.AGE_45_49'), column: 'age4549', completed: false, color: 'primary', id: 9 },
      { name: this.translate.instant('TRIAL.AGE_50_54'), column: 'age5054', completed: false, color: 'primary', id: 10 },
      { name: this.translate.instant('TRIAL.AGE_55_59'), column: 'age5559', completed: false, color: 'primary', id: 11 },
      { name: this.translate.instant('TRIAL.AGE_60_64'), column: 'age6064', completed: false, color: 'primary', id: 12 },
      { name: this.translate.instant('TRIAL.AGE_OLDER_65'), column: 'ageOlder65', completed: false, color: 'primary', id: 13 }
    ]
  };

  taskGender: Task = {
    name: this.translate.instant('TRIAL.GENDER'),
    id: 2,
    column: 'gender',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: this.translate.instant('TRIAL.MALE'), column: 'male', completed: false, color: 'primary', id: 1 },
      { name: this.translate.instant('TRIAL.FEMALE'), column: 'female', completed: false, color: 'primary', id: 2 },
      { name: this.translate.instant('TRIAL.MALE_FEMALE'), column: 'male_female', completed: false, color: 'primary', id: 3 },
      { name: this.translate.instant('TRIAL.FEMALE_MALE'), column: 'female_male', completed: false, color: 'primary', id: 4 },
      { name: this.translate.instant('TRIAL.NON_BINARY'), column: 'non_binary', completed: false, color: 'primary', id: 5 }
    ]
  };

  taskRace: Task = {
    name: this.translate.instant('TRIAL.RACE'),
    id: 3,
    column: 'race',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: this.translate.instant('TRIAL.AMERICAN'), column: 'american', completed: false, color: 'primary', id: 1 },
      { name: this.translate.instant('TRIAL.ASIAN'), column: 'asian', completed: false, color: 'primary', id: 2 },
      { name: this.translate.instant('TRIAL.BLACK'), column: 'black', completed: false, color: 'primary', id: 3 },
      { name: this.translate.instant('TRIAL.HISPANIC'), column: 'hispanic', completed: false, color: 'primary', id: 4 },
      { name: this.translate.instant('TRIAL.HAWAIIAN'), column: 'hawaiian', completed: false, color: 'primary', id: 5 },
      { name: this.translate.instant('TRIAL.WHITE'), column: 'white', completed: false, color: 'primary', id: 6 },
      { name: this.translate.instant('TRIAL.OTHER'), column: 'other', completed: false, color: 'primary', id: 7 }
    ]
  };

  taskContraceptivesUse: Task = {
    name: this.translate.instant('TRIAL.CONTRACEPTIVES_USE'),
    id: 4,
    column: 'contraceptives_use',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: this.translate.instant('TRIAL.YES'), column: 'yes', completed: false, color: 'primary', id: 1 },
      { name: this.translate.instant('TRIAL.NO'), column: 'no', completed: false, color: 'primary', id: 2 }
    ]
  };

  taskTreatmentStatus: Task = {
    name: this.translate.instant('TRIAL.TREATMENT_STATUS'),
    id: 5,
    column: 'treatment_status',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: this.translate.instant('TRIAL.ON_TREATMENT'), column: 'on_treatment', completed: false, color: 'primary', id: 1 },
      { name: this.translate.instant('TRIAL.NOT_ON_TREATMENT'), column: 'not_on_treatment', completed: false, color: 'primary', id: 2 }
    ]
  };

  taskTimeFromDiagnosis: Task = {
    name: this.translate.instant('TRIAL.TIME_FROM_DIAGNOSIS'),
    id: 6,
    column: 'time_from_diagnosis',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: this.translate.instant('TRIAL.THIRTY_DAYS'), column: 'thirty_days', completed: false, color: 'primary', id: 1 },
      { name: this.translate.instant('TRIAL.THIRTY_NINETY_DAYS'), column: 'thirty_ninety_days', completed: false, color: 'primary', id: 2 },
      { name: this.translate.instant('TRIAL.NINETY_DAYS'), column: 'ninety_days', completed: false, color: 'primary', id: 3 }
    ]
  };

  taskOpportunisticInfections: Task = {
    name: this.translate.instant('TRIAL.OPPORTUNUSTIC_INFECTIONS'),
    id: 7,
    column: 'opportunistic_infections',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: this.translate.instant('TRIAL.NONE'), column: 'none', completed: false, color: 'primary', id: 1 },
      { name: this.translate.instant('TRIAL.HBP'), column: 'hbp', completed: false, color: 'primary', id: 2 },
      { name: this.translate.instant('TRIAL.OCP'), column: 'ocp', completed: false, color: 'primary', id: 3 },
      { name: this.translate.instant('TRIAL.HEP'), column: 'hep', completed: false, color: 'primary', id: 4 },
      { name: this.translate.instant('TRIAL.ART'), column: 'art', completed: false, color: 'primary', id: 5 },
      { name: this.translate.instant('TRIAL.CBP'), column: 'cbp', completed: false, color: 'primary', id: 6 },
      { name: this.translate.instant('TRIAL.DIA'), column: 'dia', completed: false, color: 'primary', id: 7 },
      { name: this.translate.instant('TRIAL.URI'), column: 'uri', completed: false, color: 'primary', id: 8 },
      { name: this.translate.instant('TRIAL.CH'), column: 'ch', completed: false, color: 'primary', id: 9 },
      { name: this.translate.instant('TRIAL.LOV'), column: 'lov', completed: false, color: 'primary', id: 10 },
      { name: this.translate.instant('TRIAL.HD'), column: 'hd', completed: false, color: 'primary', id: 11 },
      { name: this.translate.instant('TRIAL.SP'), column: 'sp', completed: false, color: 'primary', id: 12 },
      { name: this.translate.instant('TRIAL.NC'), column: 'nc', completed: false, color: 'primary', id: 13 },
      { name: this.translate.instant('TRIAL.LOH'), column: 'loh', completed: false, color: 'primary', id: 14 },
      { name: this.translate.instant('TRIAL.CAN'), column: 'can', completed: false, color: 'primary', id: 15 },
      { name: this.translate.instant('TRIAL.OST'), column: 'ost', completed: false, color: 'primary', id: 16 },
      { name: this.translate.instant('TRIAL.DEM'), column: 'dem', completed: false, color: 'primary', id: 17 },
      { name: this.translate.instant('TRIAL.STR'), column: 'str', completed: false, color: 'primary', id: 18 },
      { name: this.translate.instant('TRIAL.HEM'), column: 'hem', completed: false, color: 'primary', id: 19 },
      { name: this.translate.instant('TRIAL.PD'), column: 'pd', completed: false, color: 'primary', id: 20 },
      { name: this.translate.instant('TRIAL.TUB'), column: 'tub', completed: false, color: 'primary', id: 21 },
      { name: this.translate.instant('TRIAL.ORD'), column: 'ord', completed: false, color: 'primary', id: 22 }
    ]
  };

  taskWeight: Task = {
    name: this.translate.instant('TRIAL.WEIGHT'),
    id: 8,
    column: 'weight',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: this.translate.instant('TRIAL.YES'), column: 'yes', completed: false, color: 'primary', id: 1 },
      { name: this.translate.instant('TRIAL.NO'), column: 'no', completed: false, color: 'primary', id: 2 }
    ]
  };

  taskTimeOnTreatment: Task = {
    name: this.translate.instant('TRIAL.TIME_ON_TREATMENT'),
    id: 9,
    column: 'time_on_treatment',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: this.translate.instant('TRIAL.MONTH_0_3'), column: 'month03', completed: false, color: 'primary', id: 1 },
      { name: this.translate.instant('TRIAL.MONTH_3_6'), column: 'month36', completed: false, color: 'primary', id: 2 },
      { name: this.translate.instant('TRIAL.MONTH_6_12'), column: 'month612', completed: false, color: 'primary', id: 3 },
      { name: this.translate.instant('TRIAL.MONTH_GT_12'), column: 'monthGt12', completed: false, color: 'primary', id: 4 }
    ]
  };

  taskVaccination: Task = {
    name: this.translate.instant('TRIAL.VACCINATION'),
    id: 10,
    column: 'vaccination',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: this.translate.instant('TRIAL.YES'), column: 'yes', completed: false, color: 'primary', id: 1 },
      { name: this.translate.instant('TRIAL.NO'), column: 'no', completed: false, color: 'primary', id: 2 }
    ]
  };

  taskCurrentlySuppressed: Task = {
    name: this.translate.instant('TRIAL.CURRENTLY_SUPRESSED'),
    id: 11,
    column: 'currently_suppressed',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: this.translate.instant('TRIAL.YES'), column: 'yes', completed: false, color: 'primary', id: 1 },
      { name: this.translate.instant('TRIAL.NO'), column: 'no', completed: false, color: 'primary', id: 2 },
      { name: this.translate.instant('TRIAL.UNKNOWN'), column: 'unknown', completed: false, color: 'primary', id: 3 }
    ]
  };

  allSelected = false;
  allCompleteAge = false;
  allCompleteGender = false;
  allCompleteRace = false;
  allCompleteTreatmentStatus = false;
  allCompleteTimeFromDiagnosis = false;
  allCompleteOpportunisticInfections = false;
  allCompleteWeight = false;
  allCompleteTimeOnTreatment = false;
  allCompleteVaccination = false;
  allCompleteCurrentlySuppressed = false;
  allCompleteContraceptivesUse = false;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {
    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };
    this.dropdownList = this.taskOpportunisticInfections.subtasks;
  }

  ngOnInit(): void {
    const filterListDataExtended: FilterListDataExtended = JSON.parse(JSON.stringify(this.storageService.getTrialFilter()));
    const filterListData: FilterListData = filterListDataExtended.filterListData;

    if (filterListData) {
      this.filterDataList = filterListData.filterList;

      this.allCompleteAge = true;
      this.taskAge.subtasks.forEach(task => {
        task.completed = this.isSelected(this.taskAge.column, task.column);
        this.allCompleteAge = this.allCompleteAge && task.completed;
      });

      this.allCompleteGender = true;
      this.taskGender.subtasks.forEach(task => {
        task.completed = this.isSelected(this.taskGender.column, task.column);
        this.allCompleteGender = this.allCompleteGender && task.completed;
      });

      this.allCompleteRace = true;
      this.taskRace.subtasks.forEach(task => {
        task.completed = this.isSelected(this.taskRace.column, task.column);
        this.allCompleteRace = this.allCompleteRace && task.completed;
      });

      this.allCompleteContraceptivesUse = true;
      this.taskContraceptivesUse.subtasks.forEach(task => {
        task.completed = this.isSelected(this.taskContraceptivesUse.column, task.column);
        this.allCompleteContraceptivesUse = this.allCompleteContraceptivesUse && task.completed;
      });

      this.allCompleteTreatmentStatus = true;
      this.taskTreatmentStatus.subtasks.forEach(task => {
        task.completed = this.isSelected(this.taskTreatmentStatus.column, task.column);
        this.allCompleteTreatmentStatus = this.allCompleteTreatmentStatus && task.completed;
      });

      this.allCompleteTimeFromDiagnosis = true;
      this.taskTimeFromDiagnosis.subtasks.forEach(task => {
        task.completed = this.isSelected(this.taskTimeFromDiagnosis.column, task.column);
        this.allCompleteTimeFromDiagnosis = this.allCompleteTimeFromDiagnosis && task.completed;
      });

      this.allCompleteWeight = true;
      this.taskWeight.subtasks.forEach(task => {
        task.completed = this.isSelected(this.taskWeight.column, task.column);
        this.allCompleteWeight = this.allCompleteWeight && task.completed;
      });

      this.allCompleteTimeOnTreatment = true;
      this.taskTimeOnTreatment.subtasks.forEach(task => {
        task.completed = this.isSelected(this.taskTimeOnTreatment.column, task.column);
        this.allCompleteTimeOnTreatment = this.allCompleteTimeOnTreatment && task.completed;
      });

      this.allCompleteVaccination = true;
      this.taskVaccination.subtasks.forEach(task => {
        task.completed = this.isSelected(this.taskVaccination.column, task.column);
        this.allCompleteVaccination = this.allCompleteVaccination && task.completed;
      });

      this.allCompleteCurrentlySuppressed = true;
      this.taskCurrentlySuppressed.subtasks.forEach(task => {
        task.completed = this.isSelected(this.taskCurrentlySuppressed.column, task.column);
        this.allCompleteCurrentlySuppressed = this.allCompleteCurrentlySuppressed && task.completed;
      });

      this.allCompleteOpportunisticInfections = true;
      this.taskOpportunisticInfections.subtasks.forEach(task => {
        task.completed = this.isSelected(this.taskOpportunisticInfections.column, task.column);
        this.allCompleteOpportunisticInfections = this.allCompleteOpportunisticInfections && task.completed;
        if (task.completed) {
          this.selectedItems.push(task);
        }
      });

      this.allSelected = this.isAllSelected();
      if (this.allSelected) {
        this.selectAll(this.allSelected);
      }

    }
  }

  onItemSelect(item: any) {
    item.completed = true;
    this.taskOpportunisticInfections.subtasks.forEach(task => {
      if (task.id === item.id) {
        task.completed = true;
      }
    });
    this.updateAllComplete('opportunisticInfections');
    console.log(item);
    console.log(this.taskOpportunisticInfections.subtasks);
  }

  onDeSelect(item: any) {
    item.completed = false;
    this.taskOpportunisticInfections.subtasks.forEach(task => {
      if (task.id === item.id) {
        task.completed = false;
      }
    });
    this.updateAllComplete('opportunisticInfections');
    console.log(item);
    console.log(this.taskOpportunisticInfections.subtasks);
  }

  isSelected(name: string, field: string) {
    let selected = false;
    this.filterDataList.forEach(filter => {
      if (filter.name === name && filter.field === field) {
        selected = filter.selected;
      }
    });
    return selected;
  }

  saveFilter() {
    this.filterDataList = [];
    this.taskAge.subtasks.forEach(task => {
      this.filterDataList.push({
        field: task.column,
        name: this.taskAge.column,
        selected: task.completed
      });
    });

    this.taskGender.subtasks.forEach(task => {
      this.filterDataList.push({
        field: task.column,
        name: this.taskGender.column,
        selected: task.completed
      });
    });

    this.taskRace.subtasks.forEach(task => {
      this.filterDataList.push({
        field: task.column,
        name: this.taskRace.column,
        selected: task.completed
      });
    });

    this.taskContraceptivesUse.subtasks.forEach(task => {
      this.filterDataList.push({
        field: task.column,
        name: this.taskContraceptivesUse.column,
        selected: task.completed
      });
    });

    this.taskTreatmentStatus.subtasks.forEach(task => {
      this.filterDataList.push({
        field: task.column,
        name: this.taskTreatmentStatus.column,
        selected: task.completed
      });
    });

    this.taskTimeFromDiagnosis.subtasks.forEach(task => {
      this.filterDataList.push({
        field: task.column,
        name: this.taskTimeFromDiagnosis.column,
        selected: task.completed
      });
    });

    this.taskOpportunisticInfections.subtasks.forEach(task => {
      this.filterDataList.push({
        field: task.column,
        name: this.taskOpportunisticInfections.column,
        selected: task.completed
      });
    });

    this.taskWeight.subtasks.forEach(task => {
      this.filterDataList.push({
        field: task.column,
        name: this.taskWeight.column,
        selected: task.completed
      });
    });

    this.taskTimeOnTreatment.subtasks.forEach(task => {
      this.filterDataList.push({
        field: task.column,
        name: this.taskTimeOnTreatment.column,
        selected: task.completed
      });
    });

    this.taskVaccination.subtasks.forEach(task => {
      this.filterDataList.push({
        field: task.column,
        name: this.taskVaccination.column,
        selected: task.completed
      });
    });

    this.taskCurrentlySuppressed.subtasks.forEach(task => {
      this.filterDataList.push({
        field: task.column,
        name: this.taskCurrentlySuppressed.column,
        selected: task.completed
      });
    });
    const filterListDataExtended: FilterListDataExtended = {
      allCountries: null,
      selectedCountries: null,
      allStates: null,
      selectedStates: null,
      allCities: null,
      selectedCities: null,
      allOrganizations: null,
      selectedOrganizations: null,
      allCenters: null,
      selectedCenters: null,
      filterListData: {
        level: null,
        countries: null,
        states: null,
        centers: null,
        cities: null,
        organizations: null,
        filterList: this.filterDataList
      }
    };
    this.storageService.setTrialFilter(JSON.stringify(filterListDataExtended));
  }

  updateAllComplete(type) {
    if (type === 'age') {
      this.allCompleteAge = this.taskAge.subtasks != null && this.taskAge.subtasks.every(t => t.completed);
      if ((this.taskAge.subtasks.filter(t => t.completed).length > 0)) {
        this.ageControl = true;
      }
    } else if (type === 'gender') {
      this.allCompleteGender = this.taskGender.subtasks != null && this.taskGender.subtasks.every(t => t.completed);
      if ((this.taskGender.subtasks.filter(t => t.completed).length > 0)) {
        this.genderControl = true;
      }
    } else if (type === 'race') {
      this.allCompleteRace = this.taskRace.subtasks != null && this.taskRace.subtasks.every(t => t.completed);
      if ((this.taskRace.subtasks.filter(t => t.completed).length > 0)) {
        this.raceControl = true;
      }
    } else if (type === 'contraceptivesUse') {
      this.allCompleteContraceptivesUse = this.taskContraceptivesUse.subtasks != null
        && this.taskContraceptivesUse.subtasks.every(t => t.completed);
      if ((this.taskContraceptivesUse.subtasks.filter(t => t.completed).length > 0)) {
        this.contraceptivesUseControl = true;
      }
    } else if (type === 'treatmentStatus') {
      this.allCompleteTreatmentStatus = this.taskTreatmentStatus.subtasks != null
        && this.taskTreatmentStatus.subtasks.every(t => t.completed);
      if ((this.taskTreatmentStatus.subtasks.filter(t => t.completed).length > 0)) {
        this.treatmentStatusControl = true;
      }
    } else if (type === 'timeFromDiagnosis') {
      this.allCompleteTimeFromDiagnosis = this.taskTimeFromDiagnosis.subtasks != null
        && this.taskTimeFromDiagnosis.subtasks.every(t => t.completed);
      if ((this.taskTimeFromDiagnosis.subtasks.filter(t => t.completed).length > 0)) {
        this.timeFromDiagnosisControl = true;
      }
    } else if (type === 'opportunisticInfections') {
      this.allCompleteOpportunisticInfections = this.taskOpportunisticInfections.subtasks != null
        && this.taskOpportunisticInfections.subtasks.every(t => t.completed);
      if ((this.taskOpportunisticInfections.subtasks.filter(t => t.completed).length > 0)) {
        this.opportunisticInfectionsControl = true;
      }
    } else if (type === 'weight') {
      this.allCompleteWeight = this.taskWeight.subtasks != null && this.taskWeight.subtasks.every(t => t.completed);
      if ((this.taskWeight.subtasks.filter(t => t.completed).length > 0)) {
        this.weightControl = true;
      }
    } else if (type === 'timeOnTreatment') {
      this.allCompleteTimeOnTreatment = this.taskTimeOnTreatment.subtasks != null
        && this.taskTimeOnTreatment.subtasks.every(t => t.completed);
      if ((this.taskTimeOnTreatment.subtasks.filter(t => t.completed).length > 0)) {
        this.timeOnTreatmentControl = true;
      }
    } else if (type === 'vaccination') {
      this.allCompleteVaccination = this.taskVaccination.subtasks != null && this.taskVaccination.subtasks.every(t => t.completed);
      if ((this.taskVaccination.subtasks.filter(t => t.completed).length > 0)) {
        this.vaccinationControl = true;
      }
    } else if (type === 'currentlySuppressed') {
      this.allCompleteCurrentlySuppressed = this.taskCurrentlySuppressed.subtasks != null
        && this.taskCurrentlySuppressed.subtasks.every(t => t.completed);
      if ((this.taskCurrentlySuppressed.subtasks.filter(t => t.completed).length > 0)) {
        this.currentlySuppressedControl = true;
      }
    }
    this.allSelected = this.isAllSelected();
  }

  someComplete(type): boolean {
    if (type === 'age') {
      if (this.taskAge.subtasks == null) {
        return false;
      }
      return this.taskAge.subtasks.filter(t => t.completed).length > 0 && !this.allCompleteAge;
    } else if (type === 'gender') {
      if (this.taskGender.subtasks == null) {
        return false;
      }
      return this.taskGender.subtasks.filter(t => t.completed).length > 0 && !this.allCompleteGender;
    } else if (type === 'race') {
      if (this.taskRace.subtasks == null) {
        return false;
      }
      return this.taskRace.subtasks.filter(t => t.completed).length > 0 && !this.allCompleteRace;
    } else if (type === 'contraceptivesUse') {
      if (this.taskContraceptivesUse.subtasks == null) {
        return false;
      }
      return this.taskContraceptivesUse.subtasks.filter(t => t.completed).length > 0 && !this.allCompleteContraceptivesUse;
    } else if (type === 'treatmentStatus') {
      if (this.taskTreatmentStatus.subtasks == null) {
        return false;
      }
      return this.taskTreatmentStatus.subtasks.filter(t => t.completed).length > 0 && !this.allCompleteTreatmentStatus;
    } else if (type === 'timeFromDiagnosis') {
      if (this.taskTimeFromDiagnosis.subtasks == null) {
        return false;
      }
      return this.taskTimeFromDiagnosis.subtasks.filter(t => t.completed).length > 0 && !this.allCompleteTimeFromDiagnosis;
    } else if (type === 'opportunisticInfections') {
      if (this.taskOpportunisticInfections.subtasks == null) {
        return false;
      }
      return this.taskOpportunisticInfections.subtasks.filter(t => t.completed).length > 0 && !this.allCompleteOpportunisticInfections;
    } else if (type === 'weight') {
      if (this.taskWeight.subtasks == null) {
        return false;
      }
      return this.taskWeight.subtasks.filter(t => t.completed).length > 0 && !this.allCompleteWeight;
    } else if (type === 'timeOnTreatment') {
      if (this.taskTimeOnTreatment.subtasks == null) {
        return false;
      }
      return this.taskTimeOnTreatment.subtasks.filter(t => t.completed).length > 0 && !this.allCompleteTimeOnTreatment;
    } else if (type === 'vaccination') {
      if (this.taskVaccination.subtasks == null) {
        return false;
      }
      return this.taskVaccination.subtasks.filter(t => t.completed).length > 0 && !this.allCompleteVaccination;
    } else if (type === 'currentlySuppressed') {
      if (this.taskCurrentlySuppressed.subtasks == null) {
        return false;
      }
      return this.taskCurrentlySuppressed.subtasks.filter(t => t.completed).length > 0 && !this.allCompleteCurrentlySuppressed;
    }
    return false;
  }

  setAll(type, completed: boolean) {
    if (type === 'age') {
      this.allCompleteAge = completed;
      if (completed) {
        this.ageControl = true;
      }
      if (this.taskAge.subtasks == null) {
        return;
      }
      this.taskAge.subtasks.forEach(t => t.completed = completed);
    } else if (type === 'gender') {
      this.allCompleteGender = completed;
      if (completed) {
        this.genderControl = true;
      }
      if (this.taskGender.subtasks == null) {
        return;
      }
      this.taskGender.subtasks.forEach(t => t.completed = completed);
    } else if (type === 'race') {
      this.allCompleteRace = completed;
      if (completed) {
        this.raceControl = true;
      }
      if (this.taskRace.subtasks == null) {
        return;
      }
      this.taskRace.subtasks.forEach(t => t.completed = completed);
    } else if (type === 'contraceptivesUse') {
      this.allCompleteContraceptivesUse = completed;
      if (completed) {
        this.contraceptivesUseControl = true;
      }
      if (this.taskContraceptivesUse.subtasks == null) {
        return;
      }
      this.taskContraceptivesUse.subtasks.forEach(t => t.completed = completed);
    } else if (type === 'treatmentStatus') {
      this.allCompleteTreatmentStatus = completed;
      if (completed) {
        this.treatmentStatusControl = true;
      }
      if (this.taskTreatmentStatus.subtasks == null) {
        return;
      }
      this.taskTreatmentStatus.subtasks.forEach(t => t.completed = completed);
    } else if (type === 'timeFromDiagnosis') {
      this.allCompleteTimeFromDiagnosis = completed;
      if (completed) {
        this.timeFromDiagnosisControl = true;
      }
      if (this.taskTimeFromDiagnosis.subtasks == null) {
        return;
      }
      this.taskTimeFromDiagnosis.subtasks.forEach(t => t.completed = completed);
    } else if (type === 'opportunisticInfections') {
      this.allCompleteOpportunisticInfections = completed;
      if (completed) {
        this.opportunisticInfectionsControl = true;
        this.selectedItems = this.taskOpportunisticInfections.subtasks;
      } else {
        this.selectedItems = [];
      }
      if (this.taskOpportunisticInfections.subtasks == null) {
        return;
      }
      this.taskOpportunisticInfections.subtasks.forEach(t => {
        t.completed = completed;
      });
    } else if (type === 'weight') {
      this.allCompleteWeight = completed;
      if (completed) {
        this.weightControl = true;
      }
      if (this.taskWeight.subtasks == null) {
        return;
      }
      this.taskWeight.subtasks.forEach(t => t.completed = completed);
    } else if (type === 'timeOnTreatment') {
      this.allCompleteTimeOnTreatment = completed;
      if (completed) {
        this.timeOnTreatmentControl = true;
      }
      if (this.taskTimeOnTreatment.subtasks == null) {
        return;
      }
      this.taskTimeOnTreatment.subtasks.forEach(t => t.completed = completed);
    } else if (type === 'vaccination') {
      this.allCompleteVaccination = completed;
      if (completed) {
        this.vaccinationControl = true;
      }
      if (this.taskVaccination.subtasks == null) {
        return;
      }
      this.taskVaccination.subtasks.forEach(t => t.completed = completed);
    } else if (type === 'currentlySuppressed') {
      this.allCompleteCurrentlySuppressed = completed;
      if (completed) {
        this.currentlySuppressedControl = true;
      }
      if (this.taskCurrentlySuppressed.subtasks == null) {
        return;
      }
      this.taskCurrentlySuppressed.subtasks.forEach(t => t.completed = completed);
    }
    this.allSelected = this.isAllSelected();
  }

  onOutcomes() {
    let someQuestionGroupsInvalid = false;

    if (!(this.taskAge.subtasks.filter(t => t.completed).length > 0)) {
      this.ageControl = false;
      someQuestionGroupsInvalid = true;
    }

    if (!(this.taskGender.subtasks.filter(t => t.completed).length > 0)) {
      this.genderControl = false;
      someQuestionGroupsInvalid = true;
    }

    if (!(this.taskRace.subtasks.filter(t => t.completed).length > 0)) {
      this.raceControl = false;
      someQuestionGroupsInvalid = true;
    }

    if (!(this.taskContraceptivesUse.subtasks.filter(t => t.completed).length > 0)) {
      this.contraceptivesUseControl = false;
      someQuestionGroupsInvalid = true;
    }

    if (!(this.taskTreatmentStatus.subtasks.filter(t => t.completed).length > 0)) {
      this.treatmentStatusControl = false;
      someQuestionGroupsInvalid = true;
    }

    if (!(this.taskTimeFromDiagnosis.subtasks.filter(t => t.completed).length > 0)) {
      this.timeFromDiagnosisControl = false;
      someQuestionGroupsInvalid = true;
    }

    if (!(this.taskOpportunisticInfections.subtasks.filter(t => t.completed).length > 0)) {
      this.opportunisticInfectionsControl = false;
      someQuestionGroupsInvalid = true;
    }

    if (!(this.taskWeight.subtasks.filter(t => t.completed).length > 0)) {
      this.weightControl = false;
      someQuestionGroupsInvalid = true;
    }

    if (!(this.taskTimeOnTreatment.subtasks.filter(t => t.completed).length > 0)) {
      this.timeOnTreatmentControl = false;
      someQuestionGroupsInvalid = true;
    }

    if (!(this.taskVaccination.subtasks.filter(t => t.completed).length > 0)) {
      this.vaccinationControl = false;
      someQuestionGroupsInvalid = true;
    }

    if (!(this.taskCurrentlySuppressed.subtasks.filter(t => t.completed).length > 0)) {
      this.currentlySuppressedControl = false;
      someQuestionGroupsInvalid = true;
    }

    if (someQuestionGroupsInvalid) {
      this.notificationService.error(this.translate.instant('CLINICAL_OUTCOME.NOTIFICATION.QUESTIONS_NOT_VALID'));
      return;
    }

    this.saveFilter();
    this.router.navigate(['smarthiv-trialist/trial-country']);

  }

  isAllSelected() {
    return this.allCompleteAge
      && this.allCompleteGender
      && this.allCompleteRace
      && this.allCompleteContraceptivesUse
      && this.allCompleteTreatmentStatus
      && this.allCompleteTimeFromDiagnosis
      && this.allCompleteOpportunisticInfections
      && this.allCompleteWeight
      && this.allCompleteTimeOnTreatment
      && this.allCompleteVaccination
      && this.allCompleteCurrentlySuppressed;
  }


  selectAll(completed: boolean) {
    if (completed) {
      this.ageControl = true;
      this.genderControl = true;
      this.raceControl = true;
      this.contraceptivesUseControl = true;
      this.treatmentStatusControl = true;
      this.timeFromDiagnosisControl = true;
      this.opportunisticInfectionsControl = true;
      this.weightControl = true;
      this.timeOnTreatmentControl = true;
      this.vaccinationControl = true;
      this.currentlySuppressedControl = true;
      this.allSelected = true;
    }

    this.allCompleteAge = completed;
    this.taskAge.subtasks.forEach(t => t.completed = completed);

    this.allCompleteGender = completed;
    this.taskGender.subtasks.forEach(t => t.completed = completed);

    this.allCompleteRace = completed;
    this.taskRace.subtasks.forEach(t => t.completed = completed);

    this.allCompleteContraceptivesUse = completed;
    this.taskContraceptivesUse.subtasks.forEach(t => t.completed = completed);

    this.allCompleteTreatmentStatus = completed;
    this.taskTreatmentStatus.subtasks.forEach(t => t.completed = completed);

    this.allCompleteTimeFromDiagnosis = completed;
    this.taskTimeFromDiagnosis.subtasks.forEach(t => t.completed = completed);

    this.allCompleteOpportunisticInfections = completed;
    this.taskOpportunisticInfections.subtasks.forEach(t => t.completed = completed);
    if (completed) {
      this.selectedItems = this.taskOpportunisticInfections.subtasks;
    } else {
      this.selectedItems = [];
    }

    this.allCompleteWeight = completed;
    this.taskWeight.subtasks.forEach(t => t.completed = completed);

    this.allCompleteTimeOnTreatment = completed;
    this.taskTimeOnTreatment.subtasks.forEach(t => t.completed = completed);

    this.allCompleteVaccination = completed;
    this.taskVaccination.subtasks.forEach(t => t.completed = completed);

    this.allCompleteCurrentlySuppressed = completed;
    this.taskCurrentlySuppressed.subtasks.forEach(t => t.completed = completed);
  }

}
