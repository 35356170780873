import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserProfileRoutingModule } from './user-profile-routing.module';
import { AccountComponent } from './pages/account/account.component';
import { UserProfileMainComponent } from './pages/user-profile-main/user-profile-main.component';
import { IncomingInvitationsComponent } from './pages/incoming-invitations/incoming-invitations.component';
import { OutgoingInvitationsComponent } from './pages/outgoing-invitations/outgoing-invitations.component';
import { IndividualClinicianListComponent } from './pages/individual-clinician-list/individual-clinician-list.component';
import { IndividualPatientListComponent } from './pages/individual-patient-list/individual-patient-list.component';



@NgModule({
  declarations: [
    AccountComponent,
    UserProfileMainComponent,
    IncomingInvitationsComponent,
    OutgoingInvitationsComponent,
    IndividualClinicianListComponent,
    IndividualPatientListComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    UserProfileRoutingModule
  ]
})
export class UserProfileModule { }
