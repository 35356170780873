<div class="content-box chart">
  <h3 class="content-box__title">{{ 'FEEDBACK_LIST.TITLE' | translate }}</h3>

  <app-datatable
    [allColumnList]="allColumnList"
    [columns]="columns"
    [rows]="rows"
    [page]="page"
    (pageChanged)="onPageChanged($event)"
    (sortChanged)="onSortChanged($event)">
  </app-datatable>
</div>

<ng-template #idTemplate let-row="row" let-value="value">
  <a *ngIf="value" href="{{ '/feedbacks/' + row.id }}" target="_blank">{{value}}</a>
</ng-template>
