/**
 * An object used to get page information from the server
 */
export class DatatablePage {
    // The number of elements in the page
    size = 10;
    // The total number of elements
    totalElements = 0;
    // The current page number
    pageNumber = 0;
}
