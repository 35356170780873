/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/shared/component/base.component';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PageMode } from 'src/app/shared/model/enum/page-mode';
import { UserAdminService } from 'src/app/shared/services/user-admin.service';
import { UserSummaryAdmin } from 'src/app/shared/model/user-account';
import { DEFAULT_USER_PHOTO } from 'src/app/shared/constants';
@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent extends BaseComponent implements OnInit {

  photoUrl = DEFAULT_USER_PHOTO;
  PageMode: typeof PageMode = PageMode;
  roleComponentMode='edit';

  formGroup: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.minLength(4), Validators.maxLength(200), Validators.email]),
    phoneNumber: new FormControl('', [Validators.minLength(4), Validators.maxLength(200)]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    userUniqueId: new FormControl('', [Validators.required]),

    patientOrganisationName: new FormControl({value: '', disabled: true}),
    clinicianOrganisationName: new FormControl({value: '', disabled: true}),
    emailConfirmed: new FormControl(''),
    phoneNumberConfirmed: new FormControl(''),
    otpEnabled: new FormControl(''),
    enabled: new FormControl(''),
    active: new FormControl({value: '', disabled: true})
  });

  constructor(
    public dialogRef: MatDialogRef<UserDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private userAdminService: UserAdminService
  ) {
    super();
  }

  ngOnInit(): void {
    this.patchForms(this.data.row);

    this.photoUrl = this.data.row.photoUrl ? this.data.row.photoUrl : DEFAULT_USER_PHOTO;

    if (this.data.pageMode && !(this.data.pageMode === PageMode.CREATE || this.data.pageMode === PageMode.EDIT)) {
      this.formGroup.disable();
      this.roleComponentMode = 'view';
    }
  }

  submitForm() {
    console.log('Form submitted : ' + JSON.stringify(this.data.row));

  }


  onEdit(): void {
    if (this.formGroup.valid) {
      const rawVal = this.formGroup.getRawValue();
      const item = Object.assign(this.data.row, rawVal);

      this.userAdminService.updateUserByAdmin(this.data.row.id, item).subscribe(plan => {
        this.notificationService.success(this.translate.instant('USER_ADMIN.DETAIL.NOTIFICATION.USER_EDIT.SUCCESS'));
        this.closeDialog(true);
      }, error => {
        console.log('Error on updating user : ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('USER_ADMIN.DETAIL.NOTIFICATION.USER_EDIT.FAIL'));
      });
    }
  }

  onDelete(): void {

    this.userAdminService.deleteUserByAdmin(this.data.row.id).subscribe(() => {
      this.notificationService.success(this.translate.instant('USER_ADMIN.DETAIL.NOTIFICATION.USER_DELETE.SUCCESS'));
      this.closeDialog(true);
    }, error => {
      console.log('Error on deleting user : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('SUBSCRIPTION.PLAN.DETAIL.NOTIFICATION.USER_DELETE.FAIL'));
    });
  }

  onRoleChanged(roleValue) {
    this.data.row.roles = roleValue;
  }

  closeDialog(isChanged: boolean) {
    this.dialogRef.close({status: 'dialog closed', refreshTable: isChanged});
  }

  patchForms(user: UserSummaryAdmin) {
    if (user) {
      this.formGroup.patchValue(user);
      this.data.row = user;
    }
  }

}
