import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Suppression } from 'src/app/shared/model/suppression';
import { ApexChartOptions, CHART_COLORS_SCHEME5 } from 'src/app/shared/model/chart-options';
import { ChartComponent } from 'ng-apexcharts';
import { ClinicalOutcome } from 'src/app/shared/model/clinical-outcome';

@Component({
  selector: 'app-expected-overall-suppression',
  templateUrl: './expected-overall-suppression.component.html',
  styleUrls: ['./expected-overall-suppression.component.scss']
})
export class ExpectedOverallSuppressionComponent implements OnInit {
  @Input() suppressionList: Suppression[];
  @Input() clinicalOutcome: ClinicalOutcome;

  @ViewChild('chartView') chart: ChartComponent = Object.create(null);

  public chartOptions: Partial<ApexChartOptions> = {
    series: [],
    chart: {
      type: 'bar',
      height: 370,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      },
    },
    responsive: [{
      breakpoint: 600,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '80%',
        dataLabels: {
          position: 'top' // top, center, bottom
        }
      }
    },
    dataLabels: {
      enabled: false,
      formatter: (val) => val ? val + '%' : '',
      offsetY: -20,
      style: {
        fontSize: '8px',
        colors: ['#67757c']
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      title: {
        style: {
          color: '#304758'
        }
      }
    },
    yaxis: {
      title: {
        text: 'Percentage(%)'
      },
      labels: {
        formatter: (val) => val ? val.toFixed(1) : '',
      },
      min: 0,
      max: 100
    },
    legend: {
      show: true,
      position: 'bottom',
    },
    fill: {
      colors: CHART_COLORS_SCHEME5,
      opacity: 1
    },
    tooltip: {
      theme: 'light',
      fillSeriesColor: false,
      marker: {
        show: true,
      },
    }
  };

  constructor() { }

  ngOnInit(): void {
    const chartLabels: string[] = [];

    const labelOverallPs = 'OverAll';
    const labelMsmPs = 'MSM';

    const dataOverallPs = [];
    const dataMsmPs = [];


    for (const sup of this.suppressionList) {
      chartLabels.push(sup.artCodeNames);

      dataOverallPs.push(sup.psOverall);
      dataMsmPs.push(sup.msmPs);
    }

    const ageClassification = this.getAgeClassificationLabelAndList();
    const gender = this.getGenderLabelAndList();
    const race = this.getRaceLabelAndList();

    this.chartOptions.xaxis.categories = chartLabels;
    this.chartOptions.series.push({ data: dataOverallPs, name: labelOverallPs, color: CHART_COLORS_SCHEME5[0] });

    if(this.isValueExistInList(dataMsmPs)) {
      this.chartOptions.series.push({ data: dataMsmPs, name: labelMsmPs, color: CHART_COLORS_SCHEME5[1] });
    }

    if(this.isValueExistInList(ageClassification.list)) {
      this.chartOptions.series.push({ data: ageClassification.list, name: ageClassification.label, color: CHART_COLORS_SCHEME5[2] });
    }

    if(this.isValueExistInList(gender.list)) {
      this.chartOptions.series.push({ data: gender.list, name: gender.label, color: CHART_COLORS_SCHEME5[3] });
    }

    if(this.isValueExistInList(race.list)) {
      this.chartOptions.series.push({ data: race.list, name: race.label, color: CHART_COLORS_SCHEME5[4] });
    }

  }

  getAgeClassificationLabelAndList() {
    let label = '';
    let list = [];
    switch (this.clinicalOutcome.ageClassification) {
      case 'toddlers':
        label = 'Toddlers';
        list = this.suppressionList.map(s => s.childrenPs);
        break;
      case 'child':
        label = 'Child';
        list = this.suppressionList.map(s => s.childrenPs);
        break;
      case 'adolescent':
        label = 'Adolescent';
        list = this.suppressionList.map(s => s.adolescentPs);
        break;
      case 'adult':
        label = 'Adult';
        list = this.suppressionList.map(s => s.adultPs);
        break;
      case 'older-adult':
        label = 'Older Adult';
        list = this.suppressionList.map(s => s.olderAdultPs);
        break;
      default:
        label = 'Child';
        list = this.suppressionList.map(s => s.childrenPs);
        break;
    }

    return {label, list};
  }

  getGenderLabelAndList() {
    let label = '';
    let list = [];
    switch (this.clinicalOutcome.gender) {
      case 'male':
        label = 'Male';
        list = this.suppressionList.map(s => s.malePs);
        break;
      case 'female':
        label = 'Female';
        list = this.suppressionList.map(s => s.femalePs);
        break;
      case 'non-binary':
        label = 'Non-binary';
        list = this.suppressionList.map(s => s.nonBinaryPs);
        break;
      default:
        label = 'Male';
        list = this.suppressionList.map(s => s.malePs);
        break;
    }

    return {label, list};
  }

  getRaceLabelAndList() {
    let label = '';
    let list = [];
    switch (this.clinicalOutcome.race) {
      case 'white':
        label = 'White';
        list = this.suppressionList.map(s => s.whitePs);
        break;
      case 'non-white':
        label = 'Non-white';
        list = this.suppressionList.map(s => s.blackPs);
        break;
      default:
        label = 'White';
        list = this.suppressionList.map(s => s.whitePs);
        break;
    }

    return {label, list};
  }

  isValueExistInList(list) {
    for (const val of list) {
      if(val) {
        return true;
      }
    }
    return false;
  }

}
