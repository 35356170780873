import { OrganisationBranch } from './organisation';
import { UserSummary } from './user-account';

export class AuditlyticsPermission {
  id: number;
  permissionLevel: string;

  continent: string;
  country: string;
  state: string;
  city: string;
  organisationId: number;
  organisationBranchIds: number[];

  user: UserSummary;

  // ui paramters
  organisationBranches: OrganisationBranch[]; 

}

