<h1 mat-dialog-title>{{ 'SIMULATION.COST.HEADER' | translate }}</h1>
<div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
        <div fxFlex="50">
            <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                <h4>
                    {{ 'SIMULATION.CURRENT_PRACTICE' | translate }}
                </h4>
                <mat-card class="blue-background">
                    <mat-card-header>
                        <mat-card-title class="text-white">
                            {{ 'SIMULATION.COST.NAME' | translate }}
                        </mat-card-title>
                        <div fxFlex></div>
                        <div fxLayoutAlign="center center">
                            <mat-icon matTooltip="{{ 'SIMULATION.COST.INFO_TOOLTIP' | translate }}">info</mat-icon>
                        </div>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.artFirstCost" 
                                    [formControl]="artFirstCostControl"
                                    maxlength="9"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false }"
                                    matNativeControl placeholder="{{ 'SIMULATION.COST.FIRST_LINE' | translate }}"
                                    matTooltip="{{ 'SIMULATION.COST.FIRST_LINE' | translate }}" 
                                    required/>
                                <mat-error *ngIf="artFirstCostControl.invalid">{{ 'SIMULATION.COST.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.artSecondCost"
                                    [formControl]="artSecondCostControl" 
                                    maxlength="9"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false }"
                                    matNativeControl placeholder="{{ 'SIMULATION.COST.SECOND_LINE' | translate }}"
                                    matTooltip="{{ 'SIMULATION.COST.SECOND_LINE' | translate }}" 
                                    required/>
                                <mat-error *ngIf="artSecondCostControl.invalid">{{ 'SIMULATION.COST.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.laboratoryCost"
                                    [formControl]="laboratoryCostControl" 
                                    maxlength="9"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false }"
                                    matNativeControl placeholder="{{ 'SIMULATION.COST.LABORATORY' | translate }}"
                                    matTooltip="{{ 'SIMULATION.COST.LABORATORY' | translate }}" 
                                    required/>
                                <mat-error *ngIf="laboratoryCostControl.invalid">{{ 'SIMULATION.COST.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.overheadCost" 
                                    [formControl]="overheadCostControl"
                                    maxlength="9"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false }"
                                    matNativeControl placeholder="{{ 'SIMULATION.COST.STAFF' | translate }}"
                                    matTooltip="{{ 'SIMULATION.COST.STAFF' | translate }}" 
                                    required/>
                                <mat-error *ngIf="overheadCostControl.invalid">{{ 'SIMULATION.COST.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.preventionCost"
                                    [formControl]="preventionCostControl" 
                                    maxlength="9"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false }"
                                    matNativeControl placeholder="{{ 'SIMULATION.COST.PREVENTION' | translate }}"
                                    matTooltip="{{ 'SIMULATION.COST.PREVENTION' | translate }}" 
                                    required/>
                                <mat-error *ngIf="preventionCostControl.invalid">{{ 'SIMULATION.COST.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                            <div fxFlex="40"></div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <div fxFlex="50">
            <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                <h4>
                    {{ 'SIMULATION.SCENARIO' | translate }}
                </h4>
                <mat-card class="pink-background">
                    <mat-card-header>
                        <mat-card-title class="text-white">
                            {{ 'SIMULATION.COST.NAME' | translate }}
                        </mat-card-title>
                        <div fxFlex></div>
                        <div fxLayoutAlign="center center">
                            <mat-icon matTooltip="{{ 'SIMULATION.COST.INFO_TOOLTIP' | translate }}">info</mat-icon>
                        </div>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.scArtFirstCost"
                                    [formControl]="scArtFirstCostControl" 
                                    maxlength="9"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false }"
                                    matNativeControl placeholder="{{ 'SIMULATION.COST.FIRST_LINE' | translate }}"
                                    matTooltip="{{ 'SIMULATION.COST.FIRST_LINE' | translate }}" 
                                    required/>
                                <mat-error *ngIf="scArtFirstCostControl.invalid">{{ 'SIMULATION.COST.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.scArtSecondCost"
                                    [formControl]="scArtSecondCostControl" 
                                    maxlength="9"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false }"
                                    matNativeControl placeholder="{{ 'SIMULATION.COST.SECOND_LINE' | translate }}"
                                    matTooltip="{{ 'SIMULATION.COST.SECOND_LINE' | translate }}" 
                                    required/>
                                <mat-error *ngIf="scArtSecondCostControl.invalid">{{ 'SIMULATION.COST.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.scLaboratoryCost"
                                    [formControl]="scLaboratoryCostControl" 
                                    maxlength="9"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false }"
                                    matNativeControl placeholder="{{ 'SIMULATION.COST.LABORATORY' | translate }}"
                                    matTooltip="{{ 'SIMULATION.COST.LABORATORY' | translate }}" 
                                    required/>
                                <mat-error *ngIf="scLaboratoryCostControl.invalid">{{ 'SIMULATION.COST.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.scOverheadCost"
                                    [formControl]="scOverheadCostControl" 
                                    maxlength="9"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false }"
                                    matNativeControl placeholder="{{ 'SIMULATION.COST.STAFF' | translate }}"
                                    matTooltip="{{ 'SIMULATION.COST.STAFF' | translate }}" 
                                    required/>
                                <mat-error *ngIf="scOverheadCostControl.invalid">{{ 'SIMULATION.COST.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="40">
                                <mat-label>{{ 'SIMULATION.COST.PREVENTION' | translate }}</mat-label>
                                <input currencyMask [(ngModel)]="data.scPreventionCost"
                                    [formControl]="scPreventionCostControl" 
                                    maxlength="9"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false }"
                                    matNativeControl 
                                    placeholder="{{ 'SIMULATION.COST.PREVENTION' | translate }}"
                                    matTooltip="{{ 'SIMULATION.COST.PREVENTION' | translate }}" 
                                    required/>
                                <mat-error *ngIf="scPreventionCostControl.invalid">{{ 'SIMULATION.COST.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                            <div fxFlex="40"></div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
    <span>{{ 'SIMULATION.REQUIRED' | translate }}</span>
</div>
<div mat-dialog-actions>
    <button mat-button color="primary" (click)="onNoClick()">{{ 'SIMULATION.CANCEL' | translate }}</button>
    <button mat-button color="primary" (click)="onDefaultClick()">{{ 'SIMULATION.DEFAULT' | translate }}</button>
    <button mat-button [mat-dialog-close]="data" [disabled]="!isInfoValid()" cdkFocusInitial color="accent">{{
        'SIMULATION.OK' | translate }}</button>
