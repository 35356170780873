/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import { DEFAULT_USER_PHOTO } from 'src/app/shared/constants';
import { ClinicianPatient } from 'src/app/shared/model/clinician-patient';
import { DatatablePage } from 'src/app/shared/model/datatable-page';
import { PageMode } from 'src/app/shared/model/enum/page-mode';
import { Role } from 'src/app/shared/model/enum/role';
import { PageableSort } from 'src/app/shared/model/pagination';
import { UserSummary } from 'src/app/shared/model/user-account';
import { LoginResponse } from 'src/app/shared/model/user-profile';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { ClinicianPatientService } from 'src/app/shared/services/clinician-patient.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';
import { rolesToStringArray } from 'src/app/shared/util';

@Component({
  selector: 'app-individual-patient-list',
  templateUrl: './individual-patient-list.component.html',
  styleUrls: ['./individual-patient-list.component.scss']
})
export class IndividualPatientListComponent extends BaseListComponent implements OnInit {
  PageMode: typeof PageMode = PageMode;
  Role: typeof Role = Role;

  @ViewChild('rowNumberTemplate', { static: true }) rowNumberTemplate: TemplateRef<any>;
  @ViewChild('nameInfoTemplate', { static: true }) nameInfoTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate', { static: true }) actionTemplate: TemplateRef<any>;

  pageMode: PageMode;
  loginInfo: LoginResponse;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private authenticationStateService: AuthenticationStateService,
    private clinicianPatientService: ClinicianPatientService
  ) {
    super();
   }

  ngOnInit(): void {
    this.allColumnList = [
      { prop: 'id', name: '#', maxWidth: 50, cellClass: '', cellTemplate: this.rowNumberTemplate, sortable: false, draggable: false },
      { prop: 'xss', name: this.translate.instant('USER_ADMIN.LIST.COL_LBL.NAME'), cellTemplate: this.nameInfoTemplate, sortable: false, draggable: false},
      { prop: 'patient.email', name: this.translate.instant('USER_ADMIN.LIST.COL_LBL.EMAIL'), cellClass: '', draggable: false},
      { prop: 'patient.phoneNumber', name: this.translate.instant('USER_ADMIN.LIST.COL_LBL.PHONE_NUMBER'), cellClass: '', draggable: false},
      { prop: 'patient.hospitalId', name: this.translate.instant('USER_ADMIN.LIST.COL_LBL.USER_UNIQUE_ID'), cellClass: '', draggable: false},
      { prop: 'action', name: '', maxWidth: 50, cellTemplate: this.actionTemplate, sortable: false, draggable: false}
    ];
    this.columns = [...this.allColumnList];

    this.loginInfo = this.authenticationStateService.getLoginInfo();

    this.sort = new PageableSort({ property: 'startTime', direction: 'DESC' });
    this.loadData();
  }

  loadData() {
    this.clinicianPatientService.getPatientsOfClinician(this.page.pageNumber, this.page.size, this.sort, this.searchTerm).subscribe(page => {
      this.page.pageNumber = page.number;
      this.page.totalElements = page.totalElements;
      this.page.size = page.size;
      this.rows = page.content;
    });
  }

  getProfilePhotoUrl(userSummary: UserSummary): string {
    return userSummary && userSummary.photoUrl ? userSummary.photoUrl : DEFAULT_USER_PHOTO;
  }

  getNameStr(user: UserSummary): string {
    return user && user.firstName ? (user.firstName + ' ' + user.lastName) : 'No Name';
  }

  getRolesAsString(roleValue): string {
    if(roleValue) {
      const roles = rolesToStringArray(roleValue);
      return roles.map(r => this.translate.instant('ENUM.ROLE.' + r)).join(', ');
    }
    return 'No Role';
  }

  getUserUniqueId(userSummary: UserSummary): string {
    return userSummary.userUniqueId ? userSummary.userUniqueId : 'No Folio Number';
  }

  isMe(user: UserSummary): boolean {
    return (user.phoneNumber && user.phoneNumber === this.loginInfo.phoneNumber) || (user.email && user.email === this.loginInfo.email);
  }

  removeFromPatientList(row: ClinicianPatient) {
    this.clinicianPatientService.disconnectFromMyPatient(row.patient.id).subscribe(() => {
      this.notificationService.info(this.translate.instant('USER_PROFILE.INDIVIDUAL_PATIENT_LIST.NOTIFICATION.DISCONNECT_FROM_PATIENT_SUCCESS'));
      this.loadData();
    }, error => {
      console.log('Error on get disconnect patient of me : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('USER_PROFILE.INDIVIDUAL_PATIENT_LIST.NOTIFICATION.DISCONNECT_FROM_PATIENT_FAIL'));
    });
  }

}
