export class Condition {
  questionGroupId: string;
  questionId: string;
  optionId: string;
}

export class QuestionOption {
  id: string;
  name: string;
  selected: boolean;
}

export class Question {
  id: string;
  name: string;
  type: string; // QuestionOptionType;
  optionsType = 'STATIC';
  options: QuestionOption[];
  conditions: Condition[];
  required: boolean;
  maxSelectCount: number;
}

export class QuestionGroup {
  id: string;
  name: string;
  questions: Question[];
  conditions: Condition[];
  width: string;
}





