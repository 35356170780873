/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FileUploadModel } from '../../model/file-upload-model';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  fileUploadModels: Array<FileUploadModel> = [];

  @Input() public fileExtensions = '.xls, .xlsx';
  @Input() public uploadAllButtonActive = true;
  @Input() public removeAllButtonActive = true;
  @Input() multiple = true;

  @Output() public uploadFile = new EventEmitter<FileUploadModel>();

  constructor() { }

  ngOnInit() {
  }

  onSelectFiles($event) {
    const files = $event.target.files;
    this.selectFiles(files);
  }

  selectFiles(files) {
    for (const sFile of files) {
      const model = new FileUploadModel();
      model.file = sFile;
      model.totalSize = sFile.size;
      model.loadedSize = 0;
      model.progressPercentage = 0;
      model.state = 'in';
      model.isCompleted = false;
      model.inProgress = false;
      model.canRetry = false;
      model.canCancel = true;

      this.fileUploadModels.push(model);
    }
  }

  uploadAll() {
    for (const m of this.fileUploadModels) {
      this.upload(m);
    }
  }

  removeAll() {
    for (const m of this.fileUploadModels) {
      this.remove(m);
    }
    this.fileUploadModels = [];
  }

  remove(uploadModel: FileUploadModel) {
    if (uploadModel.sub) {
      uploadModel.sub.unsubscribe();
    }
    const index = this.fileUploadModels.indexOf(uploadModel);
    if (index > -1) {
          this.fileUploadModels.splice(index, 1);
    }
  }

  upload(uploadModel: FileUploadModel): void {
    const isUploadSuccess = uploadModel.isCompleted === true && !uploadModel.errorMessage;
    if (uploadModel.inProgress === false && !isUploadSuccess) {
      this.uploadFile.emit(uploadModel);
    }
  }

}



