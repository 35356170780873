<div class="form-box" *ngIf="showQuestion">
    <div class="form-box__header">
        <h3 class="title" [ngClass]="hasError===true ? 'cust-error-color':''">{{question.name }}</h3>
        <pan class="required" [ngClass]="hasError===true ? 'cust-error-color':''">{{ (question.required === true ? ' - Required' : ' - Optional') }}</pan>
    </div>

    <div class="form-box__body">
        <ng-container *ngIf = "question.type === OptionType[OptionType.SINGLE_SELECT]">
            <mat-radio-group [(ngModel)]="selectedOptionId">
                <div class="option-radio-container" *ngFor="let opt of question.options">
                    <mat-radio-button color="warn" class="option-radio" [value]="opt.id" (change)="onRadioSelectionChange(opt.id)">
                      {{opt.name}}
                    </mat-radio-button>
                </div>
            </mat-radio-group>
        </ng-container>
<!--
        <ng-container *ngIf = "question.type === OptionType[OptionType.DE_SELECTABLE_SINGLE_SELECT]">
            <mat-radio-group [(ngModel)]="selectedOptionId">
                <div class="option-radio-container" *ngFor="let opt of question.options">
                    <mat-radio-button #radioInput color="warn" class="option-radio" [value]="opt.id" (click)="onDeSelectableRadioSelectionChange(opt.id)">
                      {{opt.name}}
                    </mat-radio-button>
                </div>
            </mat-radio-group>
        </ng-container>-->

        <ng-container *ngIf = "question.type === OptionType[OptionType.DE_SELECTABLE_SINGLE_SELECT]">
            <div *ngFor="let opt of question.options" class="option-checkbox-container">
                <mat-checkbox color="warn" [(ngModel)]="opt.selected" class="option-checkbox" (change)='onCheckboxSingleSelectionChange($event)'>
                {{opt.name}}
                </mat-checkbox>
            </div>
        </ng-container>

        <ng-container *ngIf = "question.type === OptionType[OptionType.MULTI_SELECT]">
            <div *ngFor="let opt of question.options" class="option-checkbox-container">
                <mat-checkbox color="warn" [(ngModel)]="opt.selected" class="option-checkbox" (change)='onCheckboxSelectionChange($event)'>
                {{opt.name}}
                </mat-checkbox>
            </div>
        </ng-container>

        <ng-container *ngIf = "question.type === OptionType[OptionType.COMBO_SINGLE_SELECT]">
            <div class="option-combo-container">
                <mat-form-field appearance="outline" class="option-combo">
                    <mat-select (selectionChange)="onComboChange($event.value)" [(ngModel)]="selectedOptionId" placeholder="{{'GENERAL.SELECT_AN_OPTION' | translate}}">
                        <mat-option disabled>{{'GENERAL.SELECT_AN_OPTION' | translate}}</mat-option>
                        <mat-option *ngFor= "let opt of question.options" [value]="opt.id">
                            {{opt.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>

        <ng-container *ngIf = "question.type === OptionType[OptionType.COMBO_MULTI_SELECT]">
            <div class="option-combo-container">
                <mat-form-field appearance="outline" class="option-combo">
                    <mat-select #comboMultiSelect [(ngModel)]="selectedOptionIds" (selectionChange)="onComboMultipleChange($event.value)" placeholder="{{'GENERAL.SELECT_AN_OPTION' | translate}}" multiple>
                        <mat-option disabled>{{'GENERAL.SELECT_AN_OPTION' | translate}}</mat-option>
                        <mat-option *ngFor= "let opt of question.options" [value]="opt.id">
                            {{opt.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>

    </div>

</div>
