<h1 mat-dialog-title>{{ 'SIMULATION.COUNTRY.HEADER' | translate }}</h1>
<div mat-dialog-content>
    <mat-card>
        <mat-card-header>
            <div fxFlex></div>
            <div fxLayoutAlign="center center">
                <mat-icon matTooltip="{{ 'SIMULATION.COUNTRY.UPDATE_TOOLTIP' | translate }}">info</mat-icon>
            </div>
        </mat-card-header>
        <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <mat-form-field fxFlex="30">
                    <mat-label>{{ 'SIMULATION.COUNTRY.NAME' | translate }}</mat-label>
                    <mat-select placeholder="Country" [(ngModel)]="selectedChoice"
                        (selectionChange)="onSelectCountry($event.value)">
                        <mat-option *ngFor="let opt of data.costs" [value]="opt.country">{{opt.country}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <mat-checkbox [checked]="data.updateDefault" (change)="onUpdateDefault($event.checked)">
                    {{ 'SIMULATION.COUNTRY.UPDATE_DEFAULT' | translate }}
                </mat-checkbox>
            </div>
        </mat-card-content>
    </mat-card>

</div>
<div mat-dialog-actions>
    <button mat-button color="primary" (click)="onNoClick()">{{ 'SIMULATION.CANCEL' | translate }}</button>
    <button mat-button [mat-dialog-close]="data" [disabled]="!isUpdatable()" cdkFocusInitial color="accent">{{ 'SIMULATION.OK' | translate }}</button>