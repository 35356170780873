<mat-toolbar color="primary" class="no-shadow">
    <div fxLayout="row" fxFlex="100" style="justify-content: space-between;">
        <span class="m-t-0 font-14">
            <img src="{{getOrDefaultPhoto(from.photoUrl)}}" class="img-circle w-40 h-40 vm m-r-10" width="40">
        </span>
        <button [matMenuTriggerFor]="toolbarMenu" mat-icon-button class="ml-1">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #toolbarMenu="matMenu" x-position="before">
            <button mat-menu-item (click)="openUserSelectionDialog(true, 'chat')">Chat with other HCWs</button>
            <button mat-menu-item (click)="openUserSelectionDialog(false, 'chat')" *ngIf="isUserClinician()">Chat with patients within your facility</button>
            <button mat-menu-item (click)="openUserSelectionDialog(false, 'notification')" *ngIf="isUserClinician()">Send bulk messages within your facility</button>
            <button mat-menu-item (click)="openIndividualPatientDialog(false, 'chat')" *ngIf="isUserClinician()">Chat with My Patient List</button>
        </mat-menu>
    </div>
</mat-toolbar>

<mat-nav-list>
    <ng-container *ngFor="let chatRoom of chatRoomList">
        <mat-list-item (click)="onSelectChatRoomUser(chatRoom)" [class.active]="selectedChatRoomUser?.id === chatRoom.user.id">
            <img mat-list-avatar src="{{getOrDefaultPhoto(chatRoom?.user?.photoUrl)}}" alt="">
            <h3 matLine> {{chatRoom.user.firstName + ' ' + chatRoom.user.lastName}} </h3>
            <p matLine class="{{isLastMessageRead(chatRoom) ? 'text-muted' : ''}}"> {{chatRoom.lastMessage.content | slice:0:20}} </p>
        </mat-list-item>
    </ng-container>
    
</mat-nav-list>