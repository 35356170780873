<div class="login-register otp-confirm-container">
  <div class="login-register-box">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="formGroup" autocomplete="off" novalidate (ngSubmit)="confirmToken()">
          <div class="text-center">
            <img class="company-logo" alt="homepage" src="assets/images/logo-wide.svg">
            <h4 class="m-t-0 title-text">{{ 'OTP_CONFIRM.TITLE' | translate }}</h4>
          </div>
          <p class="text-center font-14 m-t-20">{{'OTP_CONFIRM.DESCRIPTION' | translate}}</p>
          <p class="text-center font-14 font-bold m-t-20">{{emailOrPhoneNumber}}</p>
          <p class="text-center font-14 font-bold m-t-20">{{('ENUM.OTP_TYPE.' + otpType) | translate}}</p>
          <p class="text-center font-20 font-bold m-t-20">
            <countdown #cd [config]="countDownConfig"></countdown>
          </p>

          <div class="otp-form-container">
            <div class="pb-1 cust-form-field-container">
              <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'OTP_CONFIRM.TOKEN' | translate }}</mat-label>
                <input matInput type="token" formControlName="token">
              </mat-form-field>
              <app-validation-message [control]="formGroup.get('token')"></app-validation-message>
            </div>

           <!--Password reset form-->
           <div *ngIf="passwordResetFormEnabled">

              <app-password-form-field 
              [passwordLabel]="'OTP_CONFIRM.PASSWORD_RESET_FORM.PASSWORD'"
              [passwordFormGroupName]="'passwordFormGroup'"
              [verifyPasswordLabel]="'OTP_CONFIRM.PASSWORD_RESET_FORM.PASSWORD_VERIFY'"
              [enableVerifyPassword]="true">
            </app-password-form-field>
           </div>
           <!--End of password reset form-->

           <div class="pb-1 cust-form-field-container">
            <re-captcha
            (resolved)="captchaResolved($event)"
            siteKey="{{recaptchaSiteKey}}"
            formControlName="captcha"></re-captcha>
          </div>
          
            <button mat-raised-button color="warn" type="submit" class="btn-block btn-lg m-t-20"
              [disabled]="!formGroup.valid || !emailOrPhoneNumber">{{ 'OTP_CONFIRM.CONFIRM_BUTTON' | translate }}</button>

            <div class="top-otp-confirm-container" *ngIf="emailOrPhoneNumber">
              <a (click)="regenerateOtp()">{{ 'OTP_CONFIRM.RESEND_OTP' | translate }}</a>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
