import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { faUsers, faUserDoctor, faInfoCircle, faCity, faUserLock } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-organisation-main',
  templateUrl: './organisation-main.component.html',
  styleUrls: ['./organisation-main.component.scss'],
})
export class OrganisationMainComponent implements OnInit {
  faUsers = faUsers; faUserDoctor = faUserDoctor; faInfoCircle = faInfoCircle; faCity = faCity; faUserLock = faUserLock;

  public config: PerfectScrollbarConfigInterface = {};

  sidePanelOpened = true;
  displayMode = 'default';

  private mediaMatcher: MediaQueryList = matchMedia(`(max-width: 960px)`);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
  }

  isOver(): boolean {
    return this.mediaMatcher.matches;
  }

  menuChanged(item) {
    this.router.navigate([item]);
  }
}
