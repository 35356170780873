import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  ClinicalOutcome,
  ClinicalOutcomeDashboardResponse,
  MutationRequest, MutationResponse,
  SuppressionData
} from 'src/app/shared/model/clinical-outcome';
import { Suppression } from 'src/app/shared/model/suppression';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { ClinicalOutcomeService } from 'src/app/shared/services/clinical-outcome.service';
import { QuestionsService } from 'src/app/shared/services/questions.service';
import { SuppressionService } from 'src/app/shared/services/suppression.service';
import {MutationService} from '../../../../shared/services/mutation.service';

@Component({
  selector: 'app-clinical-outcomes',
  templateUrl: './clinical-outcomes.component.html',
  styleUrls: ['./clinical-outcomes.component.scss']
})
export class ClinicalOutcomesComponent implements OnInit {

  clinicalOutcome: ClinicalOutcome;
  mutationRequest: MutationRequest;
  mutationResponse: MutationResponse[];
  suppressionList: Suppression[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private suppressionService: SuppressionService,
    private questionsService: QuestionsService,
    private clinicalOutcomeService: ClinicalOutcomeService,
    private mutationService: MutationService
  ) { }

  ngOnInit(): void {
    this.clinicalOutcomeService.getLatestClicalOutcomeResult().subscribe(result => {
      this.suppressionList = result.suppressionList;
      this.clinicalOutcome = result.clinicalOutcome;

      this.mutationRequest = this.initializeMutationRequest(result);

      this.mutationService.getMutationsResults(this.mutationRequest).subscribe(response => {
        this.mutationResponse = response;
      }, error => {
        if (error.status !== 404) {
          console.log('Error on getting drug interaction result: ' + JSON.stringify(error));
          this.notificationService.error(this.translate.instant('CLINICAL_OUTCOME.NOTIFICATION.GET_FAILED'));
        }
      });
    }, error => {
      if (error.status !== 404) {
        console.log('Error on create clinical outcome create: ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('CLINICAL_OUTCOME.NOTIFICATION.GET_FAILED'));
      }
    });
  }

  goToFormPage() {
    this.router.navigateByUrl('smarthiv-clinician/clinician-form');
  }

  isValueExistInList(propertyName: string) {
    for (const sup of this.suppressionList) {
      if(sup[propertyName]) {
        return true;
      }
    }
    return false;
  }

  initializeMutationRequest(result: ClinicalOutcomeDashboardResponse): MutationRequest {
    const request: MutationRequest = {
      mutation: this.clinicalOutcome.mutationName ? this.clinicalOutcome.mutationName : [],
      suppressionData: this.getSuppressionData(result)
    };
    return request;
  }

  getSuppressionData(result: ClinicalOutcomeDashboardResponse): SuppressionData[] {
    const suppressionData: SuppressionData[] = [];
    for (const r in result.suppressionList) {
      if (result.suppressionList.hasOwnProperty(r)) {
        const newSuppressionData = {
          art: result.suppressionList[r].artCodeNames,
          name: result.suppressionList[r].artCodeNames
        };
        suppressionData.push(newSuppressionData);
      }
    }
    return suppressionData;
  }

}
