import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { MainComponent } from './pages/main/main.component';
import { MainRoutingModule } from './main-routing.module';
import { AppBreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { VerticalAppHeaderComponent } from './component/vertical-header/vertical-header.component';
import { VerticalAppSidebarComponent } from './component/vertical-sidebar/vertical-sidebar.component';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [
    MainComponent,
    AppBreadcrumbComponent,
    VerticalAppHeaderComponent,
    VerticalAppSidebarComponent
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    SharedModule,
    DashboardModule
  ]
})
export class MainModule { }
