import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubscriptionPlanComponent } from './pages/subscription-plan/subscription-plan.component';

const routes: Routes = [
  {
    path: 'plan',
    // canActivate: [AuthenticationGuardService, UserEnabledGuardService],
    component: SubscriptionPlanComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubscriptionRoutingModule { }
