<div class="p-24 pb-0">
    <mat-card-header>
        <mat-card-title>{{'ORGANISATION.PATIENT_LIST_TITLE' | translate}}</mat-card-title>
    </mat-card-header>
    <!-- <mat-card-content> -->

        <div fxLayout="row wrap" class="align-items-center">
            <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100">
              <app-search-field [label]="'USER_ADMIN.LIST.SEARCH_FIELD.LABEL'" 
                [hint]="'USER_ADMIN.LIST.SEARCH_FIELD.HINT'" 
                (searchTermChanged)="onSearchTermChanged($event)">
              </app-search-field>
            </div>
            <div fxFlex.gt-md="75" class="text-right" *ngIf="loginInfo.clinicianOrganisationId">
                <div class="add-new-container m-b-20">
                    <button mat-raised-button color="primary" class="m-r-5 m-t-5" (click)="openSendInvitationToUnregisteredUserDialog()" >{{'ORGANISATION.INVITE_UNREGISTERED_PATIENT' | translate}}</button>
                    <button mat-raised-button color="primary" class="m-r-5 m-t-5" (click)="openUploadUserDialog()" >{{'ORGANISATION.UPLOAD_PATIENT_FILE' | translate}}</button>
                </div>
            </div>
          </div>

        <div class="flex-grow-1 overflow-auto w-100p">
            <app-datatable
                [styleClass]="'cust-no-border-datatable'"
                [allColumnList]="allColumnList"
                [columns]="columns"
                [rows]="rows"
                [page]="page"
                (pageChanged)="onPageChanged($event)"
                (sortChanged)="onSortChanged($event)">
            </app-datatable>
        </div>
    <!-- </mat-card-content> -->
</div>

<ng-template #rowNumberTemplate let-row="row" let-value="value" let-rowIndex="rowIndex">
    <span class="ng-star-inserted">{{(page.pageNumber * page.size) + rowIndex + 1}}</span>
</ng-template>

<ng-template #nameInfoTemplate let-row="row" let-value="value">
      <div class="d-flex">
        <img class="mat-card-avatar" [src]='getProfilePhotoUrl(row)'>
        <div class="m-l-15">
          <p class="font-bold m-b-0 m-t-0">{{ getNameStr(row) }}</p>
          <small class="text-muted" matTooltip="{{getRolesAsString(row.roles)}}">{{getRolesAsString(row.roles)}}</small>
        </div>
      </div>
</ng-template>

<ng-template #actionTemplate let-row="row" let-value="value">
    <div class="d-flex">
        <!--a (click)="openDetailDialog(PageMode.VIEW, row)" class="m-r-10 cursor-pointer">
            <i class="fa fa-eye fa-lg text-info"></i>
        </a-->
        <a [matMenuTriggerFor]="overflowRowMenu" [matMenuTriggerData]="{rowUserSummary: row}" class="m-r-10 cursor-pointer">
            <i class="fa fa-ellipsis-v fa-lg"></i>
        </a>
    </div>

</ng-template>


<!-- ============================================================== -->
<!-- Row overflow menu-->
<!-- ============================================================== -->
<mat-menu #overflowRowMenu="matMenu">
    <ng-template matMenuContent let-selectedRow="rowUserSummary" >
        <button mat-menu-item *ngIf="!isMe(selectedRow)" (click)="openDialogRemoveFromOrganisation(selectedRow)">Disable Organisation Connection</button>
        <button mat-menu-item *ngIf="!isMe(selectedRow)" (click)="onConnectPatientToMe(selectedRow)">Connect Patient To Me</button>
    </ng-template>
</mat-menu>