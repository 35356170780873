/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Page, Pageable, PageableSort } from '../model/pagination';
import {
  DrugInteraction, DrugInteractionMedicationModelResponse,
  DrugInteractionNewResponse,
  DrugInteractionRequest,
  DrugInteractionResponse
} from '../model/drug-interaction';
import { map } from 'rxjs/operators';
import { createPageParams } from '../util';

@Injectable({
  providedIn: 'root'
})
export class DrugInteractionService {

  private baseUrl = '/drug-interaction';

  constructor(private http: HttpClient) {
  }

  uploadFile(file: File): Observable<HttpEvent<{}>> {
    const fileAlias = 'file';
    const formData = new FormData();
    formData.set(fileAlias, file, file.name);

    let myheaders = new HttpHeaders();
    myheaders = myheaders.append('Accept', 'application/json');
    myheaders = myheaders.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(environment.url + `${this.baseUrl}/upload`, formData, {
      observe: 'events',
      reportProgress: true,
      responseType: 'json'
    });
  }

  getDrugInteractions(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<DrugInteraction>> {
    const pageParams = createPageParams(page, pageSize, sort, searchTerm);
    return this.http.get<Page<DrugInteraction>>(environment.url + `${this.baseUrl}`, { params: pageParams })
      .pipe(map((res: any) => (res || {})
      ));
  }


  getCategoriesOfMedications(): Observable<string[]> {
    return this.http.get<string[]>(environment.url + `${this.baseUrl}/unique-categories`)
      .pipe(map((res: any) => (res || [])
      ));
  }
  getMedications(request: string[]): Observable<string[]> {
    return this.http.post<string[]>(environment.url + `${this.baseUrl}/get-medications/`,request)
      .pipe(map((res: any) => (res || [])
      ));
  }
  getMedicationsModels(request: string[]): Observable<DrugInteractionMedicationModelResponse[]> {
    return this.http.post<DrugInteractionMedicationModelResponse[]>(environment.url + `${this.baseUrl}/get-medications-models/`,request)
      .pipe(map((res: any) => (res || [])
      ));
  }
  getDrugInteractionResults(request: DrugInteractionRequest): Observable<DrugInteractionResponse[]> {
    return this.http.post<DrugInteractionResponse[]>(environment.url + `${this.baseUrl}/get-results/`,request)
      .pipe(map((res: any) => (res || [])
      ));
  }
  getNewDrugInteractionResults(request: DrugInteractionRequest): Observable<DrugInteractionNewResponse[]> {
    return this.http.post<DrugInteractionNewResponse[]>(environment.url + `${this.baseUrl}/get-results-new/`,request)
      .pipe(map((res: any) => (res || [])
      ));
  }

}
