<div class="p-24 overflow-auto h-100p" *ngIf="userAccount">
  <div class="mb-40">
    <div class="d-flex flex-column mb-16">
      <mat-card-title class="font-size-2 mb-4">{{ 'ACCOUNT.TITLE' | translate }}</mat-card-title>
      <mat-card-subtitle>{{ 'ACCOUNT.SUBTITLE' | translate }}</mat-card-subtitle>
    </div>
    <!-- <mat-card-content> -->
      <!-- ============================================================== -->
      <!-- Profile Photo Update-->
      <!-- ============================================================== -->
      <div class="m-b-40" fxLayout="row wrap">
        <img class="m-r-15 shrink-none img-circle" [src]='photoUrl' width="60">
        <div class="media-body">
          <h5 class="m-t-0 m-b-10 font-medium">{{userAccount.email? userAccount.email : userAccount.phoneNumber}}</h5>
          <button mat-raised-button color="primary" class="m-l-10 input-file-button" (click)="onUploadProfilePhoto()">
            {{'ACCOUNT.BUTTON.UPLOAD_PHOTO' | translate}}
          </button>
        </div>
      </div>

      <!-- ============================================================== -->
      <!-- User Account Info Update-->
      <!-- ============================================================== -->
      <div class="m-b-15">

        <form [formGroup]="formGroupUserAccount" autocomplete="off" novalidate>
          <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
              <mat-label>{{ 'ACCOUNT.FIRST_NAME' | translate }}</mat-label>
              <input matInput formControlName="firstName">
            </mat-form-field>
            <app-validation-message [control]="formGroupUserAccount.get('firstName')"></app-validation-message>
          </div>

          <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
              <mat-label>{{ 'ACCOUNT.LAST_NAME' | translate }}</mat-label>
              <input matInput formControlName="lastName">
            </mat-form-field>
            <app-validation-message [control]="formGroupUserAccount.get('lastName')"></app-validation-message>
          </div>

          <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
              <mat-label>{{ 'ACCOUNT.GENDER' | translate }}</mat-label>
              <mat-select placeholder="{{ 'ACCOUNT.GENDER' | translate }}" formControlName="gender">
                <mat-option *ngFor="let gen of genderOptions" [value]="gen">
                  {{ 'ENUM.GENDER.' + gen | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-validation-message [control]="formGroupUserAccount.get('gender')"></app-validation-message>
          </div>

        </form>
        <button mat-raised-button color="primary" type="submit" class="btn-lg m-t-15" (click)="onUpdateAccount()"
          [disabled]="!formGroupUserAccount.valid">{{ 'ACCOUNT.BUTTON.UPDATE_ACCOUNT' | translate }}</button>
      </div>
    <!-- </mat-card-content> -->
  </div>

<!-- ============================================================== -->
<!-- User Account Detail-->
<!-- ============================================================== -->
<div class="mb-40">
  <div class="mb-16">
    <mat-card-title  class="font-size-2">{{ 'ACCOUNT.ACCOUNT_DETAIL_TITLE' | translate }}</mat-card-title>
  </div>
  <!-- <mat-card-content> -->
    <div class="m-b-15">
      <form [formGroup]="formGroupUserAccountDetail" autocomplete="off" novalidate>
        <div class="pb-1 cust-form-field-container">
          <mat-form-field appearance="outline" class="cust-form-field">
            <mat-label>{{ 'ACCOUNT.HOSPITAL_ID' | translate }}</mat-label>
            <input matInput formControlName="hospitalId">
          </mat-form-field>
          <app-validation-message [control]="formGroupUserAccountDetail.get('hospitalId')"></app-validation-message>
        </div>
        <div class="pb-1 cust-form-field-container">
          <mat-form-field appearance="outline" class="cust-form-field">
            <mat-label>{{ 'ACCOUNT.POSTAL_CODE' | translate }}</mat-label>
            <input matInput formControlName="postalCode">
          </mat-form-field>
          <app-validation-message [control]="formGroupUserAccountDetail.get('postalCode')"></app-validation-message>
        </div>
        <div class="pb-1 cust-form-field-container">
          <mat-form-field appearance="outline" class="cust-form-field">
            <mat-label>{{ 'ACCOUNT.BIRTHDAY' | translate }}</mat-label>
            <input matInput [matDatepicker]="birthdayPicker" [min]="minBirthday" [max]="maxBirthday" formControlName="birthday" (click)="birthdayPicker.open()">
            <mat-datepicker-toggle matSuffix [for]="birthdayPicker"></mat-datepicker-toggle>
            <mat-datepicker #birthdayPicker></mat-datepicker>
          </mat-form-field>
          <app-validation-message [control]="formGroupUserAccountDetail.get('birthday')"></app-validation-message>
        </div>

      </form>
      <button mat-raised-button color="primary" type="submit" class="btn-lg m-t-15" (click)="onUpdateDetail()"
        [disabled]="!formGroupUserAccountDetail.valid">{{ 'ACCOUNT.BUTTON.UPDATE_DETAIL' | translate }}</button>
    </div>
  <!-- </mat-card-content> -->
</div>

<!-- ============================================================== -->
<!-- Password Change-->
<!-- ============================================================== -->
  <div class="mb-40">
    <div class="mb-16">
      <mat-card-title class="font-size-2">{{ 'ACCOUNT.CHANGE_PASSWORD_TITLE' | translate }}</mat-card-title>
    </div>
    <!-- <mat-card-content> -->
      <div class="m-b-15">
        <form [formGroup]="formGroupChangePassword" autocomplete="off" novalidate>

          <app-password-form-field 
          [passwordLabel]="'ACCOUNT.CURRENT_PASSWORD'"
          [passwordFormGroupName]="'currentPasswordFormGroup'"
          [fieldAppearance]="'outline'">
         </app-password-form-field>

          <app-password-form-field 
          [passwordLabel]="'ACCOUNT.NEW_PASSWORD'"
          [passwordFormGroupName]="'newPasswordFormGroup'"
          [verifyPasswordLabel]="'ACCOUNT.NEW_PASSWORD_VERIFY'"
          [enableVerifyPassword]="true"
          [fieldAppearance]="'outline'">
         </app-password-form-field>

        </form>
        <button mat-raised-button color="primary" type="submit" class="btn-lg m-t-15" (click)="onChangePassword()"
          [disabled]="!formGroupChangePassword.valid">{{ 'ACCOUNT.BUTTON.CHANGE_PASSWORD' | translate }}</button>
      </div>
    <!-- </mat-card-content> -->
  </div>


  <!-- ============================================================== -->
  <!-- User Consents-->
  <!-- ============================================================== -->
  <div class="" *ngIf="showConsentSection">
    <div class="mb-16">
      <mat-card-title class="font-size-2">{{ 'ACCOUNT.CONSENT_TITLE' | translate }}</mat-card-title>
    </div>
    <!-- <mat-card-content> -->
      <div class="m-b-15">
        <form [formGroup]="formGroupUserConsent" autocomplete="off" novalidate>
          <div class="pb-1 cust-form-field-container">
            <mat-checkbox color="primary" formControlName="newsletterSubscribed">{{ 'ACCOUNT.NEWSLETTER_SUBSCRIBED' | translate }}</mat-checkbox>
            <app-validation-message [control]="formGroupUserConsent.get('newsletterSubscribed')"></app-validation-message>
          </div>

          <div class="pb-1 cust-form-field-container">
            <mat-checkbox color="primary" formControlName="notificationsSubscribed">{{ 'ACCOUNT.NOTIFICATION_SUBSCRIBED' | translate }}</mat-checkbox>
            <app-validation-message [control]="formGroupUserConsent.get('notificationsSubscribed')"></app-validation-message>
          </div>

          <div class="pb-1 cust-form-field-container">
            <mat-checkbox color="primary" formControlName="medicalDataAllowed">{{ 'ACCOUNT.MEDICAL_DATA_ALLOWED' | translate }}</mat-checkbox>
            <app-validation-message [control]="formGroupUserConsent.get('medicalDataAllowed')"></app-validation-message>
          </div>

          <div class="pb-1 cust-form-field-container">
            <mat-checkbox color="primary" formControlName="accessYourDataAllowed">{{ 'ACCOUNT.ACCESS_YOUR_DATA_ALLOWED' | translate }}</mat-checkbox>
            <app-validation-message [control]="formGroupUserConsent.get('accessYourDataAllowed')"></app-validation-message>
          </div>

          <div class="pb-1 cust-form-field-container">
            <mat-checkbox color="primary" formControlName="clinicalTrialsAllowed">{{ 'ACCOUNT.CLINICAL_TRIALS_ALLOWED' | translate }}</mat-checkbox>
            <app-validation-message [control]="formGroupUserConsent.get('clinicalTrialsAllowed')"></app-validation-message>
          </div>

        </form>
        <button mat-raised-button color="primary" type="submit" class="btn-lg m-t-15" (click)="onUpdateConsent()"
          [disabled]="!formGroupUserConsent.valid">{{ 'ACCOUNT.BUTTON.UPDATE_CONSENT' | translate }}</button>
      </div>
    <!-- </mat-card-content> -->
  </div>
</div>
