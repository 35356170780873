<div fxLayout="row wrap" *ngIf="suppressionList">

  <div fxFlex="100">
    <app-clinical-outcome-summary [clinicalOutcome]="clinicalOutcome"
                                  [suppressionList]="suppressionList"></app-clinical-outcome-summary>
  </div>
  <div fxFlex="100">
    <app-suppression-list-summary [clinicalOutcome]="clinicalOutcome"
                                  [suppressionList]="suppressionList"></app-suppression-list-summary>
  </div>

  <div class="outcomes-charts mb-24" fxFlex="100">
    <div class="content-box chart">
      <app-expected-overall-suppression [clinicalOutcome]="clinicalOutcome"
                                        [suppressionList]="suppressionList"></app-expected-overall-suppression>
    </div>
    <div class="content-box chart">
      <app-expected-patient-suppression [suppressionList]="suppressionList"></app-expected-patient-suppression>
    </div>

    <div *ngIf="isValueExistInList('percentDrae')" class="content-box chart">
      <app-expectancy-chart [suppressionList]="suppressionList" [suppressionProperty]="'percentDrae'"
                            [title]="'CLINICAL_OUTCOME.TITLE.EXPECTED_CHANCE_OF_DRAR' | translate"></app-expectancy-chart>
    </div>
    <div *ngIf="isValueExistInList('percentAdherent')" class="content-box chart">
      <app-expectancy-chart [suppressionList]="suppressionList" [suppressionProperty]="'percentAdherent'"
                            [title]="'CLINICAL_OUTCOME.TITLE.EXPECTED_PATIENT_ADHERENCE' | translate"></app-expectancy-chart>
    </div>
    <div *ngIf="(isValueExistInList('tbciPs')  && clinicalOutcome.opportunisticInfections.includes('tb-co-infection'))"
        class="content-box chart">
      <app-expectancy-chart [suppressionList]="suppressionList" [suppressionProperty]="'tbciPs'"
                            [title]="'CLINICAL_OUTCOME.TITLE.TB_COINFECTED_PATIENTS' | translate"></app-expectancy-chart>
    </div>
    <div *ngIf="(isValueExistInList('hbvciPs') && clinicalOutcome.opportunisticInfections.includes('hbv'))"
        class="content-box chart">
      <app-expectancy-chart [suppressionList]="suppressionList" [suppressionProperty]="'hbvciPs'"
                            [title]="'CLINICAL_OUTCOME.TITLE.HBV_COINFECTED_PATIENTS' | translate"></app-expectancy-chart>
    </div>
    <div *ngIf="(isValueExistInList('hcvciPs') && clinicalOutcome.opportunisticInfections.includes('hcv'))"
        class="content-box chart">
      <app-expectancy-chart [suppressionList]="suppressionList" [suppressionProperty]="'hcvciPs'"
                            [title]="'CLINICAL_OUTCOME.TITLE.HCV_COINFECTED_PATIENTS' | translate"></app-expectancy-chart>
    </div>
    <div *ngIf="isValueExistInList('timeToDeathDead')" class="content-box chart">
      <app-expectancy-chart [suppressionList]="suppressionList" [suppressionProperty]="'timeToDeathDead'"
                            [title]="'CLINICAL_OUTCOME.TITLE.LIFE_EXPECTANCY' | translate"></app-expectancy-chart>
    </div>
    <div class="content-box chart"
        *ngIf="(clinicalOutcome.anyMutation && clinicalOutcome.anyMutation === 'yes' && isValueExistInList('mutationScore')) || (clinicalOutcome.treatmentStrategySwitch && clinicalOutcome.treatmentStrategySwitch === 'yes' && isValueExistInList('mutationScore'))">
      <app-mutation-score [suppressionList]="suppressionList"></app-mutation-score>
    </div>
  </div>


  <div fxLayout="row wrap" *ngIf="mutationResponse">
    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <strong>Mutation Interpretation</strong>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="responsive-table" *ngIf="mutationResponse">
            <table class="table-rasponsive striped">
              <tr *ngFor="let response of mutationResponse | slice:0:1; let i=index">
                <!--              <tr>-->
                <td class="font-medium m-b-0 m-t-0"><strong>ARTs</strong></td>
                <td class="table_title" *ngFor="let r1 of response.mutation">
                  <span class="font-medium m-b-0 m-t-0"
                  ><strong>{{r1}}</strong></span>
                </td>
                <!--              </tr>-->
              </tr>

              <tbody>
              <tr *ngFor="let r2 of mutationResponse">
                <td class="art_title">{{r2.art}}</td>
                <td class="{{r2.mutationCSSClass[i]}}" *ngFor="let m of r2.mutationResultInNumber;let i=index">
                  <span
                    class="{{r2.mutationCSSClass[i]}} align-center mat-cell cdk-cell cdk-column-label mat-column-label ng-star-inserted"
                  >{{m}}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="mat-chip-list-wrapper mt-20">
            <mat-chip class="mat-chip mat-standard-chip potential_low_level_resistance_chip">Potential Low-Level Resistance</mat-chip>
            <mat-chip class="mat-chip mat-standard-chip susceptible-chip">Susceptible</mat-chip>
            <mat-chip class="mat-chip mat-standard-chip low_level_resistance_chip">Low-Level Resistance</mat-chip>
            <mat-chip class="mat-chip mat-standard-chip intermediate_resistance_chip">Intermediate Resistance</mat-chip>
            <mat-chip class="mat-chip mat-standard-chip high_level_resistance_chip">High-Level Resistance</mat-chip>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>


</div>


<div fxLayout="row wrap" fxLayoutAlign="center start" *ngIf="!suppressionList">
  <mat-card fxFlex.gt-sm="50%" fxFlex="50">

    <mat-card-content>
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <img fxFlexAlign="center" src="assets/images/smarthiv-clinician_800x800.png" width="300" height="300"
             alt="Smarthic Clinician">
      </div>
      <p>You haven't got any filled form, please fill a form before to see your outcomes.</p>
      <button mat-raised-button color="warn" (click)="goToFormPage()">Go to form page</button>
    </mat-card-content>
  </mat-card>
</div>
