import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { InvitationDetailComponent } from './invitation-detail/invitation-detail.component';
import { CustomMaterialModule } from '../material/material.module';
import { QuillModule } from 'ngx-quill';
import { FormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxPaginationModule } from 'ngx-pagination';
import { SendInvitationDialogComponent } from './send-invitation-dialog/send-invitation-dialog.component';
import { InvitationListComponent } from './invitation-list/invitation-list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedComponentsModule } from '../shared-components.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        CustomMaterialModule,
        QuillModule.forRoot(),
        PerfectScrollbarModule,
        NgxPaginationModule,
        FlexLayoutModule,
        SharedComponentsModule
    ],
    declarations: [
        InvitationListComponent,
        InvitationDetailComponent,
        SendInvitationDialogComponent
    ],
    exports: [
        InvitationListComponent,
        InvitationDetailComponent,
        SendInvitationDialogComponent
    ]
})
export class InvitationModule {

}
