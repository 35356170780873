/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

export class DocumentStateModel {
  currentState: 'visible' | 'invisible';
}

@Injectable({
  providedIn: 'root'
})
export class DocumentStateService {
  documentStateSubject: Subject<DocumentStateModel>;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private http: HttpClient,
    private router: Router) {
    this.documentStateSubject = new Subject<DocumentStateModel>();
  }

  public addVisibilityChangeListener(): void {
    this.document.addEventListener('visibilitychange', () => {
      if (!this.document.hidden) {
        console.log("tab activated " + new Date());
        this.documentStateSubject.next({currentState: 'visible'});
      } else {
        console.log("tab deactivated " + new Date());
        this.documentStateSubject.next({currentState: 'invisible'});
      }
    });
  }

  getDocumentVisibilityChange(): Observable<DocumentStateModel> {
    return this.documentStateSubject.asObservable();
  }

}
