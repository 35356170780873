/* eslint-disable max-len */
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective } from './directive/accordion';
import { FileUploadModule } from './module/file-upload/file-upload.module';
import { CustomMaterialModule } from './module/material/material.module';
import { DatatableComponent } from './component/datatable/datatable.component';
import { DemandDialogComponent } from './component/demand-dialog/demand-dialog.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImageUploadComponent } from './component/image-upload/image-upload.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ServiceDialogComponent } from './component/service-dialog/service-dialog.component';
import { NgChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CountryDialogComponent } from './component/country-dialog/country-dialog.component';
import { CostDialogComponent } from './component/cost-dialog/cost-dialog.component';
import { StaffDialogComponent } from './component/staff-dialog/staff-dialog.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustDialogComponent } from './component/cust-dialog/cust-dialog.component';
import { ResourceDialogComponent } from './component/resource-dialog/resource-dialog.component';
import { StringFilterByPipe } from './pipe/string-filter-by.pipe';
import { UnaidsDialogComponent } from './component/unaids-dialog/unaids-dialog.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { AwesomeTooltipDirective } from './directive/tooltip/tooltip.directive';
import { AwesomeTooltipComponent } from './directive/tooltip/tooltip.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { QuillModule } from 'ngx-quill';
import { InvitationModule } from './module/invitation/invitation.module';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { SearchFieldComponent } from './component/search-field/search-field.component';
import { RoleComponent } from './component/role/role.component';
import { UserSelectionDialogComponent } from './component/user-selection-dialog/user-selection-dialog.component';
import { OrganisationBranchSelectionDialogComponent } from './component/organisation-branch-selection-dialog/organisation-branch-selection-dialog.component';
import { SortFieldComponent } from './component/sort-field/sort-field.component';
import { OrganisationSelectionDialogComponent } from './component/organisation-selection-dialog/organisation-selection-dialog.component';
import { SharedComponentsModule } from './module/shared-components.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FusionChartsModule } from '../angular-fusioncharts/fusioncharts.module';
import { ClinicianPatientSelectionDialogComponent } from './component/clinician-patient-selection-dialog/clinician-patient-selection-dialog.component';

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    AwesomeTooltipComponent,
    AwesomeTooltipDirective,
    DatatableComponent,
    DemandDialogComponent,
    ImageUploadComponent,
    CustDialogComponent,
    ServiceDialogComponent,
    CountryDialogComponent,
    CostDialogComponent,
    StaffDialogComponent,
    ResourceDialogComponent,
    StringFilterByPipe,
    SafeHtmlPipe,
    UnaidsDialogComponent,
    SearchFieldComponent,
    SortFieldComponent,
    RoleComponent,
    UserSelectionDialogComponent,
    OrganisationBranchSelectionDialogComponent,
    OrganisationSelectionDialogComponent,
    ClinicianPatientSelectionDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    NgxDatatableModule,
    PerfectScrollbarModule,
    CustomMaterialModule,
    FileUploadModule,
    FontAwesomeModule,
    ImageCropperModule,
    SharedComponentsModule,
    InvitationModule,
    NgChartsModule,
    NgxChartsModule,
    NgApexchartsModule,
    NgxPaginationModule,
    CurrencyMaskModule,
    OverlayModule,
    QuillModule.forRoot(),
    FusionChartsModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    NgxDatatableModule,
    PerfectScrollbarModule,
    CustomMaterialModule,
    FileUploadModule,
    SharedComponentsModule,
    DatatableComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    AwesomeTooltipComponent,
    AwesomeTooltipDirective,
    FontAwesomeModule,
    NgxPermissionsModule,
    ImageUploadComponent,
    InvitationModule,
    NgChartsModule,
    NgxChartsModule,
    NgApexchartsModule,
    NgxPaginationModule,
    StringFilterByPipe,
    SafeHtmlPipe,
    QuillModule,
    SearchFieldComponent,
    SortFieldComponent,
    RoleComponent,
    UserSelectionDialogComponent,
    OrganisationBranchSelectionDialogComponent,
    FusionChartsModule,
    NgMultiSelectDropDownModule,
    ClinicianPatientSelectionDialogComponent
  ],
  providers: [
    DatePipe,
  ],
  entryComponents: [AwesomeTooltipComponent]
})
export class SharedModule { }
