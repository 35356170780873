export class ExcelFileUploadResponse {
  name: string;
  rowCount: number;
}

export class UserUploadResponse {
  email: string;
  phoneNumber: string;
  errorReason: string;
}

export class UserFileUploadResponse {
  name: string;
  rowCount: number;
  errorList: UserUploadResponse[];

}

