/* eslint-disable @typescript-eslint/naming-convention */
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {QuestionOptionType} from 'src/app/shared/model/enum/question-option-type';
import {Condition, Question, QuestionGroup, QuestionOption} from 'src/app/shared/model/question';
import {NotificationService} from 'src/app/shared/module/notification/service/notification.service';
import {QuestionsService} from 'src/app/shared/services/questions.service';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit, OnDestroy {

  @Input() question: Question;
  @Input() questionGroups: QuestionGroup[];
  @Output() questionRemoved = new EventEmitter<Question>();
  @ViewChild('comboMultiSelect') comboMultiSelect: MatSelect;

  OptionType = QuestionOptionType;
  selectedOptionId: string;
  selectedOptionIds: string[] = [];
  hasError = false;
  showQuestion = true;
  comboSearchText: string;

  constructor(
    private notificationService: NotificationService,
    private questionService: QuestionsService
  ) {
  }

  ngOnInit(): void {
    if (this.question.conditions) {
      const conditionSelected = this.isConditionOptionSelected(this.question.conditions);
      if (conditionSelected === false) {
        this.showQuestion = false;
      }

    }

    this.updateConditionsSelection();
    this.subscribeToOtherQuestionsSelectionChange();

    this.fillSelectedOptions();
  }

  ngOnDestroy(): void {
  }


  subscribeToOtherQuestionsSelectionChange() {
    this.questionService.getQuestionSelectionChange().subscribe(changedQuestion => {
      if (this.question.id !== changedQuestion.id && this.question.conditions) {
        this.updateConditionsSelection();
      }
    });
  }

  fillSelectedOptions() {
    const isSingleSelect = this.question.type === QuestionOptionType[QuestionOptionType.SINGLE_SELECT]
      || this.question.type === QuestionOptionType[QuestionOptionType.COMBO_SINGLE_SELECT];

    for (const option of this.question.options) {
      if (option.selected === true) {
        if (isSingleSelect) {
          this.selectedOptionId = option.id;
        } else {
          this.selectedOptionIds.push(option.id);
        }
      }
    }
  }

  isConditionOptionSelected(conditions: Condition[]) {
    for (const condition of conditions) {
      const conditionGroup = this.questionGroups.find(group => group.id === condition.questionGroupId);
      const conditionQuestion = conditionGroup.questions.find(question => question.id === condition.questionId);
      if (condition.optionId === '**ANY**') {
        const selectedOpt = conditionQuestion.options.find(option => option.selected === true);
        const anySelection = selectedOpt ? true : false;
        return anySelection;
      }
      const conditionOption = conditionQuestion.options.find(option => option.id === condition.optionId);
      if (conditionOption.selected === true) {
        return true;
      }
    }
    return false;
  }

  onRadioSelectionChange(optionId: string) {
    const options = this.question.options;
    for (const o of options) {
      o.selected = o.id === optionId;
    }
    this.questionService.notifyQuestionChange(this.question);
  }

  onDeSelectableRadioSelectionChange(optionId: string) {
    const options = this.question.options;
    for (const o of options) {
      if (o.id === optionId) {
        o.selected = false;
      }
      // o.selected = o.id === optionId;
    }
    this.questionService.notifyQuestionChange(this.question);
  }

  onCheckboxSingleSelectionChange(event: MatCheckboxChange) {
    if (event.source.checked === true) {
      this.questionService.notifyQuestionChange(this.question);
    }
  }
  onCheckboxSelectionChange(event: MatCheckboxChange) {
    this.questionService.notifyQuestionChange(this.question);
  }

  onComboChange(optionId: string) {
    const options = this.question.options;
    for (const o of options) {
      o.selected = o.id === optionId;
    }
    this.questionService.notifyQuestionChange(this.question);
  }

  onComboMultipleChange(optionIds: string[]) {
    if (this.question.maxSelectCount && this.question.maxSelectCount < optionIds.length) {
      this.notificationService.error('Max selection count ' + this.question.maxSelectCount + ' exceeded');
      const selectedOpts = this.comboMultiSelect.options.filter(o => o.selected);
      selectedOpts.forEach((item: MatOption, index) => {
        if (index === selectedOpts.length - 1) {
          item.deselect();
        }
      });
    }

    const options = this.question.options;
    for (const o of options) {
      const found = optionIds.find(i => i === o.id);
      o.selected = found ? true : false;
    }
    this.questionService.notifyQuestionChange(this.question);
  }

  updateConditionsSelection() {
    if (!this.question.conditions) {
      // this control required for validation method
      this.showQuestion = true;
      return;
    }
    const conditionSelected = this.isConditionOptionSelected(this.question.conditions);
    if (conditionSelected === true) {
      this.showQuestion = true;
      return;
    }

    this.destroyQuestion();
    return;
  }

  destroyQuestion() {
    this.showQuestion = false;
    const options = this.question.options;
    let fireChanges = false;
    for (const o of options) {
      if (o.selected === true) {
        o.selected = false;
        fireChanges = true;
      }
    }
    if (fireChanges) {
      this.questionService.notifyQuestionChange(this.question);
    }
  }

  validate(): boolean {
    if (this.question.required === true) {
      if (this.showQuestion === true) {
        const options = this.question.options;
        for (const o of options) {
          if (o.selected === true) {
            this.hasError = false;
            return true;
          }
        }
        this.hasError = true;
        return false;
      }
    }
    this.hasError = false;
    return true;
  }

}
