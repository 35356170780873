/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import { DEFAULT_USER_PHOTO } from 'src/app/shared/constants';
import { UserSummary, UserFilterRequest } from 'src/app/shared/model/user-account';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { convertToRoleStringArray, getClinicianRelatedRoles, rolesToStringArray } from 'src/app/shared/util';
import { Role } from '../../model/enum/role';
import { UserAdminService } from '../../services/user-admin.service';
import { UserProfileService } from '../../services/user-profile.service';

@Component({
  selector: 'app-user-selection-dialog',
  templateUrl: './user-selection-dialog.component.html',
  styleUrls: ['./user-selection-dialog.component.scss']
})
export class UserSelectionDialogComponent extends BaseListComponent implements OnInit {

  @ViewChild('rowNumberTemplate', { static: true }) rowNumberTemplate: TemplateRef<any>;
  @ViewChild('nameInfoTemplate', { static: true }) nameInfoTemplate: TemplateRef<any>;

  constructor(
    public dialogRef: MatDialogRef<UserSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private userAdminService: UserAdminService,
    private userProfileService: UserProfileService
  ) {
    super();
   }

  ngOnInit(): void {
    const availableColumns = [
      { prop: 'id', name: '#', maxWidth: 80, cellClass: '', cellTemplate: this.rowNumberTemplate, sortable: false, draggable: false },
      { prop: 'name', name: this.translate.instant('Name'), cellTemplate: this.nameInfoTemplate, sortable: false, draggable: false},
      { prop: 'email', name: this.translate.instant('Email'), cellClass: '', draggable: false},
      { prop: 'phoneNumber', name: this.translate.instant('PhoneNumber'), cellClass: '', draggable: false},
      { prop: 'userUniqueId', name: this.translate.instant('FolioNumber'), cellClass: '', draggable: false},
      { prop: 'hospitalId', name: this.translate.instant('FolioNumber'), cellClass: '', draggable: false},
    ];
    
    const availableColumnNames = this.data?.availableColumnNames ?? ['id', 'name', 'email', 'phoneNumber', 'userUniqueId'];

    this.allColumnList = availableColumnNames.map(colName => this.findColumn(availableColumns, colName));
    this.columns = [...this.allColumnList];

    this.rowSelectionType = this.data.rowSelectionType ?  this.data.rowSelectionType : 'single';
    this.selectedRows = this.data.defaultRowSelections ?  this.data.defaultRowSelections : [];
    this.selectAllRowsVisible = this.data.selectAllRowsVisible ? this.data.selectAllRowsVisible : false;

    this.loadData();
  }

  loadData() {
    let filterRequest = this.prepareFilter();

    if (this.data.searchInAllUsers === true) {
      this.userAdminService.getUsersByAdmin(this.page.pageNumber, this.page.size, this.sort, this.searchTerm, filterRequest).subscribe(page => {
        this.page.pageNumber = page.number;
        this.page.totalElements = page.totalElements;
        this.page.size = page.size;
        this.rows = page.content;
      });
    }

    if (this.data.searchInOrganisation === true) {
      this.userProfileService.listUsersByOrganisation(this.data.organisationId, filterRequest.roles, this.page.pageNumber, this.page.size, this.sort, this.searchTerm).subscribe(page => {
        this.page.pageNumber = page.number;
        this.page.totalElements = page.totalElements;
        this.page.size = page.size;
        this.rows = page.content;
      });
    }

    if (this.data.searchInOrganisationBranch === true) {
      this.userProfileService.listUsersByOrganisationBranch(this.data.organisationBranchId, filterRequest.roles, this.page.pageNumber, this.page.size, this.sort, this.searchTerm).subscribe(page => {
        this.page.pageNumber = page.number;
        this.page.totalElements = page.totalElements;
        this.page.size = page.size;
        this.rows = page.content;
      });
    }

  }
  
  findColumn(availableColumns, findColumnName) {
    return availableColumns.find(col => col.prop === findColumnName);
  }

  onSelectAllRows() {
    let filterRequest = this.prepareFilter();
    
    if (this.data.searchInOrganisation === true) {
      this.userProfileService.listAllUserIdsByOrganisation(this.data.organisationId, filterRequest.roles, this.searchTerm).subscribe(response => {
        this.selectedRows = response.ids.map(i => {
          const found = this.rows.find(row => row.id === i);
          return found || {id: i};
        });
      });
    }

    if (this.data.searchInOrganisationBranch === true) {
      this.userProfileService.listAllUserIdsByOrganisationBranch(this.data.organisationBranchId, filterRequest.roles, this.searchTerm).subscribe(response => {
        this.selectedRows = response.ids.map(i => {
          const found = this.rows.find(row => row.id === i);
          return found || {id: i};
        });
      });
    }

  }

  private prepareFilter() {
    let filterRequest = new UserFilterRequest();

    if (this.data.role) {
      let roles = [this.data.role];
      if (Role.CLINICIAN === this.data.role) {
        roles = getClinicianRelatedRoles();
      }
      filterRequest.roles = convertToRoleStringArray(roles);
    }

    if (this.data.defaultFilter) {
      filterRequest = { ...this.data.defaultFilter, ...filterRequest };
    }
    return filterRequest;
  }

  getProfilePhotoUrl(userSummary: UserSummary): string {
    return userSummary && userSummary.photoUrl ? userSummary.photoUrl : DEFAULT_USER_PHOTO;
  }

  getRolesAsString(roleValue): string {
    if(roleValue) {
      const roles = rolesToStringArray(roleValue);
      return roles.map(r => this.translate.instant('ENUM.ROLE.' + r)).join(', ');
    }
    return 'No Role';
  }

  onSelect() {
    this.dialogRef.close({selectedRows: this.selectedRows});
  }

}
