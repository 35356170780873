import { Component, Input, OnInit } from '@angular/core';
import { DEFAULT_USER_PHOTO } from 'src/app/shared/constants';
import { ChatRoomUserResponse, PushNotification } from 'src/app/shared/model/push-notification';
import { LoginResponse } from 'src/app/shared/model/user-profile';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {

  @Input() from: LoginResponse;
  @Input() chatRoomUser: ChatRoomUserResponse;
  @Input() message: PushNotification;

  constructor() { }

  ngOnInit(): void {
  }

  getOrDefaultPhoto(photoUrl: string) {
    return photoUrl ? photoUrl : DEFAULT_USER_PHOTO;
  }

}
