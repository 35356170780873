import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ChartComponent } from 'ng-apexcharts';
import { Pair, SimulationResultPreventionListResponse, SimulationResultPreventionResponse } from 'src/app/shared/model/simulation-result';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { SimulationService } from 'src/app/shared/services/simulation.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-prevention',
  templateUrl: './prevention.component.html',
  styleUrls: ['./prevention.component.scss']
})
export class PreventionComponent implements OnInit {

  @ViewChild('chartPatientPrep')
  chartPatientPrep: ChartComponent = Object.create(null);
  @ViewChild('chartPatientPrepSc')
  chartPatientPrepSc: ChartComponent = Object.create(null);
  @ViewChild('chartPrepRisk')
  chartPrepRisk: ChartComponent = Object.create(null);
  @ViewChild('chartPrepRiskSc')
  chartPrepRiskSc: ChartComponent = Object.create(null);
  @ViewChild('chartPrepAverted')
  chartPrepAverted: ChartComponent = Object.create(null);
  @ViewChild('chartPrepAvertedSc')
  chartPrepAvertedSc: ChartComponent = Object.create(null);
  @ViewChild('chartPrepVisit')
  chartPrepVisit: ChartComponent = Object.create(null);
  @ViewChild('chartPrepVisitSc')
  chartPrepVisitSc: ChartComponent = Object.create(null);
  @ViewChild('chartTotalPrevention')
  chartTotalPrevention: ChartComponent = Object.create(null);
  @ViewChild('chartTotalPreventionProgramme')
  chartTotalPreventionProgramme: ChartComponent = Object.create(null);

  @ViewChild('chartPieCurrent')
  chartPieCurrent: ChartComponent = Object.create(null);
  @ViewChild('chartPieCurrentActivity')
  chartPieCurrentActivity: ChartComponent = Object.create(null);
  @ViewChild('chartPieType')
  chartPieType: ChartComponent = Object.create(null);

  @ViewChild('chartPieScenario')
  chartPieScenario: ChartComponent = Object.create(null);
  @ViewChild('chartPieScenarioActivity')
  chartPieScenarioActivity: ChartComponent = Object.create(null);
  @ViewChild('chartPieTypeSc')
  chartPieTypeSc: ChartComponent = Object.create(null);

  @ViewChild('chartHivPrevention')
  chartHivPrevention: ChartComponent = Object.create(null);
  @ViewChild('chartTotalAverted')
  chartTotalAverted: ChartComponent = Object.create(null);

  @ViewChild('chartPatient')
  chartPatient: ChartComponent = Object.create(null);

  public chartPatientOptions;

  public chartHivPreventionOptions;
  public chartTotalAvertedOptions;

  public chartPieCurrentOptions;
  public chartPieCurrentActivityOptions;
  public chartPieTypeOptions;

  public chartPieScenarioOptions;
  public chartPieScenarioActivityOptions;
  public chartPieTypeScOptions;

  public chartPatientPrepOptions;
  public chartPatientPrepScOptions;
  public chartPrepRiskOptions;
  public chartPrepRiskScOptions;
  public chartPrepAvertedOptions;
  public chartPrepAvertedScOptions;
  public chartPrepVisitOptions;
  public chartPrepVisitScOptions;
  public chartTotalPreventionOptions;
  public chartTotalPreventionProgrammeOptions;

  numberOfPrep = 0;
  numberOfPep = 0;

  numberOfPrepSc = 0;
  numberOfPepSc = 0;

  activityPrep = 0;
  activityPep = 0;

  activityPrepSc = 0;
  activityPepSc = 0;

  menToMen = 0;
  pregnant = 0;
  injection = 0;
  sexWorkers = 0;
  serodiscordant = 0;
  heterosexual = 0;
  other = 0;

  menToMenSc = 0;
  pregnantSc = 0;
  injectionSc = 0;
  sexWorkersSc = 0;
  serodiscordantSc = 0;
  heterosexualSc = 0;
  otherSc = 0;

  layout = 'raw';
  layoutSize = '50';

  years;

  totalHivCost = 0;
  totalHivCostSc = 0;
  preventionCost = 0;
  preventionCostSc = 0;
  totalCost = 0;
  totalCostSc = 0;
  totalAverted = 0;
  totalAvertedSc = 0;
  costAverted = 0;
  differenceInTotalCost = 0;
  differenceInAvertedCase = 0;

  totalHivCostStr = '0';
  totalHivCostScStr = '0';
  preventionCostStr = '0';
  preventionCostScStr = '0';
  totalCostStr = '0';
  totalCostScStr = '0';
  totalAvertedStr = '0';
  totalAvertedScStr = '0';
  costAvertedStr = '0';

  differenceInTotalCostStr = '0';
  differenceInAvertedCaseStr = '0';
  costEffectivenessPlane = '';
  costResult = '';
  costColor = '';

  constructor(
    private router: Router,
    private storageService: StorageService,
    private notificationService: NotificationService,
    public simulationService: SimulationService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    const result = JSON.parse(this.storageService.getSimulationResult());

    if (!result) {
      this.router.navigate(['smarthiv-manager/manager-form']);
      this.notificationService.error(this.translate.instant('SIMULATION.RUN_MODEL_EXCEPTION'));
    }

    this.simulationService.getSimulationResultPreventions(result).subscribe(data => {
      this.years = data.preventions[0].years;

      if (this.years > 5) {
        this.layout = 'column';
        this.layoutSize = '100';
      }
      const total = data.preventions[0].demandPrevention;
      const percentagePrep = data.preventions[0].percentagePrep;
      const prepNumber = ((data.preventions[0].demandPrevention * percentagePrep) / 100);
      const pepNumber = total - prepNumber;

      this.numberOfPrep = Math.round(prepNumber);
      this.numberOfPep = Math.round(pepNumber);

      const simSize = Math.floor(data.preventions.length / 2);

      const totalSc = data.preventions[simSize].demandPrevention;
      const percentagePrepSc = data.preventions[simSize].percentagePrep;
      const prepNumberSc = ((data.preventions[simSize].demandPrevention * percentagePrepSc) / 100);
      const pepNumberSc = totalSc - prepNumberSc;

      this.numberOfPrepSc = Math.round(prepNumberSc);
      this.numberOfPepSc = Math.round(pepNumberSc);

      let totalForRate = 0;
      let totalForRateSc = 0;

      const menToMenRate = data.preventions[0].percentageMenHavingSexWithMen;
      const menToMenCount = (menToMenRate * total) / 100;
      this.menToMen = Math.round(menToMenCount);
      totalForRate = totalForRate + parseInt(menToMenCount.toFixed(0), 10);

      const menToMenRateSc = data.preventions[simSize].percentageMenHavingSexWithMen;
      const menToMenCountSc = (menToMenRateSc * totalSc) / 100;
      this.menToMenSc = Math.round(menToMenCountSc);
      totalForRateSc = totalForRateSc + parseInt(menToMenCountSc.toFixed(0), 10);

      const pregnantRate = data.preventions[0].percentagePregnantForPrevention;
      const pregnantCount = (pregnantRate * total) / 100;
      this.pregnant = Math.round(pregnantCount);
      totalForRate = totalForRate + parseInt(pregnantCount.toFixed(0), 10);

      const pregnantRateSc = data.preventions[simSize].percentagePregnantForPrevention;
      const pregnantCountSc = (pregnantRateSc * totalSc) / 100;
      this.pregnantSc = Math.round(pregnantCountSc);
      totalForRateSc = totalForRateSc + parseInt(pregnantCountSc.toFixed(0), 10);

      const injectionRate = data.preventions[0].percentageInjectionDrugUsers;
      const injectionCount = (injectionRate * total) / 100;
      this.injection = Math.round(injectionCount);
      totalForRate = totalForRate + parseInt(injectionCount.toFixed(0), 10);

      const injectionRateSc = data.preventions[simSize].percentageInjectionDrugUsers;
      const injectionCountSc = (injectionRateSc * totalSc) / 100;
      this.injectionSc = Math.round(injectionCountSc);
      totalForRateSc = totalForRateSc + parseInt(injectionCountSc.toFixed(0), 10);

      const sexWorkersRate = data.preventions[0].percentageSexWorkersUnsafeDailyWork;
      const sexWorkersCount = (sexWorkersRate * total) / 100;
      this.sexWorkers = Math.round(sexWorkersCount);
      totalForRate = totalForRate + parseInt(sexWorkersCount.toFixed(0), 10);

      const sexWorkersRateSc = data.preventions[simSize].percentageSexWorkersUnsafeDailyWork;
      const sexWorkersCountSc = (sexWorkersRateSc * totalSc) / 100;
      this.sexWorkersSc = Math.round(sexWorkersCountSc);
      totalForRateSc = totalForRateSc + parseInt(sexWorkersCountSc.toFixed(0), 10);

      const serodiscordantRate = data.preventions[0].percentageSerodiscordant;
      const serodiscordantCount = (serodiscordantRate * total) / 100;
      this.serodiscordant = Math.round(serodiscordantCount);
      totalForRate = totalForRate + parseInt(serodiscordantCount.toFixed(0), 10);

      const serodiscordantRateSc = data.preventions[simSize].percentageSerodiscordant;
      const serodiscordantCountSc = (serodiscordantRateSc * totalSc) / 100;
      this.serodiscordantSc = Math.round(serodiscordantCountSc);
      totalForRateSc = totalForRateSc + parseInt(serodiscordantCountSc.toFixed(0), 10);

      const otherRate = data.preventions[0].percentageOther;
      const otherCount = (otherRate * total) / 100;
      this.other = Math.round(otherCount);
      totalForRate = totalForRate + parseInt(otherCount.toFixed(0), 10);

      const otherRateSc = data.preventions[simSize].percentageOther;
      const otherCountSc = (otherRateSc * totalSc) / 100;
      this.otherSc = Math.round(otherCountSc);
      totalForRateSc = totalForRateSc + parseInt(otherCountSc.toFixed(0), 10);

      const heterosexualRate = data.preventions[0].percentageHeterosexualContact;
      const heterosexualCount = total - totalForRate;
      this.heterosexual = Math.round(heterosexualCount);

      const heterosexualRateSc = data.preventions[simSize].percentageHeterosexualContact;
      const heterosexualCountSc = totalSc - totalForRateSc;
      this.heterosexualSc = Math.round(heterosexualCountSc);

      this.setNewPatients(this.getAverage(this.getArray(data.preventions, 'demand', true)),
        this.getAverage(this.getArray(data.preventions, 'demand', false)),
        percentagePrep, percentagePrepSc);

      this.setRiskPatients(this.getAverage(this.getArray(data.preventions, 'demand', true)),
        this.getAverage(this.getArray(data.preventions, 'demand', false)),
        data.preventions[0],
        data.preventions[simSize]);

      this.setAvertedPatients(this.getAverage(this.getArray(data.preventions, 'avert', true)),
        this.getAverage(this.getArray(data.preventions, 'avert', false)));

      this.setVisitPatients(this.getAverage(this.getArray(data.preventions, 'prevention', true)),
        this.getAverage(this.getArray(data.preventions, 'prevention', false)));

      this.setNetPatients(this.getAverage(this.getArray(data.preventions, 'net', true)),
        this.getAverage(this.getArray(data.preventions, 'net', false)));

      const scStartIndex = simSize;
      this.setActivities(new Map(Object.entries(data.preventions[0].visitMapByPreventionType)),
        new Map(Object.entries(data.preventions[scStartIndex].visitMapByPreventionType)));

      this.setTotalCost(data,
        this.getAverage(this.getArray(data.preventions, 'cost', true)),
        this.getAverage(this.getArray(data.preventions, 'cost', false)),
        this.getAverage(this.getArray(data.preventions, 'net', true)),
        this.getAverage(this.getArray(data.preventions, 'net', false)),
        this.getAverage(this.getArray(data.preventions, 'discontinued', true)),
        this.getAverage(this.getArray(data.preventions, 'discontinued', false)),
        this.getAverage(this.getArray(data.preventions, 'demand', true)),
        this.getAverage(this.getArray(data.preventions, 'demand', false)),
        percentagePrep, percentagePrepSc);

      this.chartPieCurrentOptions = {
        series: [
          this.numberOfPrep,
          this.numberOfPep,
        ],
        chart: {
          width: 300,
          type: 'pie'
        },
        colors: ['#008efb', '#00e397'],
        labels: [
          this.translate.instant('SIMULATION.PREP_PATIENTS'),
          this.translate.instant('SIMULATION.PEP_PATIENTS')
        ],
        legend: {
          position: 'bottom',
          labels: {
            colors: ['#008efb', '#00e397'],
            useSeriesColors: false
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      };

      this.chartPieCurrentActivityOptions = {
        series: [
          this.activityPrep,
          this.activityPep,
        ],
        chart: {
          width: 300,
          type: 'pie'
        },
        colors: ['#008efb', '#00e397'],
        labels: [
          this.translate.instant('SIMULATION.PREP'),
          this.translate.instant('SIMULATION.PEP')
        ],
        legend: {
          position: 'bottom',
          labels: {
            colors: ['#008efb', '#00e397'],
            useSeriesColors: false
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      };

      this.chartPieTypeOptions = {
        series: [
          this.menToMen,
          this.pregnant,
          this.injection,
          this.sexWorkers,
          this.serodiscordant,
          this.heterosexual,
          this.other,
        ],
        chart: {
          width: 400,
          type: 'pie'
        },
        colors: ['#785dd0', '#008efb', '#00e397', '#feb219', '#6b0914', '#785dd0', '#008efb'],
        labels: [
          this.translate.instant('SIMULATION.PREVENTION.MEN_TO_MEN'),
          this.translate.instant('SIMULATION.PREVENTION.PREGNANT'),
          this.translate.instant('SIMULATION.PREVENTION.INJECTION'),
          this.translate.instant('SIMULATION.PREVENTION.SEX_WORKERS'),
          this.translate.instant('SIMULATION.PREVENTION.SERODISCORDANT'),
          this.translate.instant('SIMULATION.PREVENTION.HETEROSEXUAL'),
          this.translate.instant('SIMULATION.PREVENTION.OTHER'),
        ],
        legend: {
          position: 'bottom',
          labels: {
            colors: ['#785dd0', '#008efb', '#00e397', '#feb219', '#6b0914', '#785dd0', '#008efb'],
            useSeriesColors: false
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 400
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      };

      this.chartPieScenarioOptions = {
        series: [
          this.numberOfPrepSc,
          this.numberOfPepSc,
        ],
        chart: {
          width: 300,
          type: 'pie'
        },
        colors: ['#008efb', '#00e397'],
        labels: [
          this.translate.instant('SIMULATION.PREP_PATIENTS'),
          this.translate.instant('SIMULATION.PEP_PATIENTS')
        ],
        legend: {
          position: 'bottom',
          labels: {
            colors: ['#008efb', '#00e397'],
            useSeriesColors: false
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      };

      this.chartPieScenarioActivityOptions = {
        series: [
          this.activityPrepSc,
          this.activityPepSc,
        ],
        chart: {
          width: 300,
          type: 'pie'
        },
        colors: ['#008efb', '#00e397'],
        labels: [
          this.translate.instant('SIMULATION.PREP'),
          this.translate.instant('SIMULATION.PEP')
        ],
        legend: {
          position: 'bottom',
          labels: {
            colors: ['#008efb', '#00e397'],
            useSeriesColors: false
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      };

      this.chartPieTypeScOptions = {
        series: [
          this.menToMenSc,
          this.pregnantSc,
          this.injectionSc,
          this.sexWorkersSc,
          this.serodiscordantSc,
          this.heterosexualSc,
          this.otherSc,
        ],
        chart: {
          width: 400,
          type: 'pie'
        },
        colors: ['#785dd0', '#008efb', '#00e397', '#feb219', '#6b0914', '#785dd0', '#008efb'],
        labels: [
          this.translate.instant('SIMULATION.PREVENTION.MEN_TO_MEN'),
          this.translate.instant('SIMULATION.PREVENTION.PREGNANT'),
          this.translate.instant('SIMULATION.PREVENTION.INJECTION'),
          this.translate.instant('SIMULATION.PREVENTION.SEX_WORKERS'),
          this.translate.instant('SIMULATION.PREVENTION.SERODISCORDANT'),
          this.translate.instant('SIMULATION.PREVENTION.HETEROSEXUAL'),
          this.translate.instant('SIMULATION.PREVENTION.OTHER'),
        ],
        legend: {
          position: 'bottom',
          labels: {
            colors: ['#785dd0', '#008efb', '#00e397', '#feb219', '#6b0914', '#785dd0', '#008efb'],
            useSeriesColors: false
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 400
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      };

    }, (err) => {
      console.log('Error on get simulation. err : ' + err);
      this.notificationService.error('Error on get simulation');
    });
  }

  getArray(mapArray: Array<SimulationResultPreventionResponse>, type: string, baseline: boolean): Map<string, Array<Pair>>[] {
    const returnArrayMap: Map<string, Array<Pair>>[] = [];
    const arraySize = Math.floor(mapArray.length / 2);
    let startIndex = 0;
    let finishIndex = arraySize;
    if (!baseline) {
      startIndex = startIndex + arraySize;
      finishIndex = finishIndex + arraySize;
    }
    for (let i = startIndex; i < finishIndex; i++) {
      if (type === 'prevention') {
        returnArrayMap.push(mapArray[i].visitMapByPreventionType);
      } else if (type === 'demand') {
        returnArrayMap.push(mapArray[i].visitsMapByDemand);
      } else if (type === 'avert') {
        returnArrayMap.push(mapArray[i].yearlyAvertedMap);
      } else if (type === 'cost') {
        returnArrayMap.push(mapArray[i].yearlyTherapyLineMap);
      } else if (type === 'discontinued') {
        returnArrayMap.push(mapArray[i].yearlyDiscontinuedMap);
      } else if (type === 'net') {
        returnArrayMap.push(mapArray[i].yearlyNetPatientMap);
      }
    }
    return returnArrayMap;
  }

  getAverage(mapArray: Map<string, Array<Pair>>[]): Map<string, Array<Pair>> {
    const averageMap: Map<string, Array<Pair>> = new Map<string, Array<Pair>>();
    const mapSize = mapArray.length;
    mapArray.forEach(map => {
      const ttt = new Map(Object.entries(map));
      for (const key of ttt.keys()) {
        if (averageMap.get(key)) {
          const averagePairs: Pair[] = averageMap.get(key);
          const newPairs: Pair[] = [];
          const pairs: Pair[] = ttt.get(key);
          for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i];
            const averagePair = averagePairs[i];
            const second = averagePair.second + pair.second / mapSize;
            newPairs.push({ first: pair.first, second: parseFloat(second.toFixed(2)) });
          }
          averageMap.set(key, newPairs);
        } else {
          const newPairs: Pair[] = [];
          const pairs: Pair[] = ttt.get(key);
          pairs.forEach(pair => {
            newPairs.push({ first: pair.first, second: parseFloat((pair.second / mapSize).toFixed(2)) });
          });
          averageMap.set(key, newPairs);
        }
      }
    });
    return averageMap;
  }

  getCurrency(cost) {
    return new Intl.NumberFormat('en-US',
      { minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency', currency: 'USD' }).format(cost);
  }

  setTotalCost(
    data: SimulationResultPreventionListResponse,
    costMap: Map<string, Array<Pair>>,
    costMapSc: Map<string, Array<Pair>>,
    netPatientMap: Map<string, Array<Pair>>,
    netPatientMapSc: Map<string, Array<Pair>>,
    discontinuedMap: Map<string, Array<Pair>>,
    discontinuedMapSc: Map<string, Array<Pair>>,
    demandMap: Map<string, Array<Pair>>,
    demandMapSc: Map<string, Array<Pair>>,
    percentagePrep,
    percentagePrepSc) {

    const years: string[][] = [];

    const prepDrugCostData: number[] = [];
    const pepDrugCostData: number[] = [];

    const prepProgrammeData: number[] = [];
    const pepProgrammeData: number[] = [];

    const totalData: number[] = [];
    const totalDataSc: number[] = [];

    for (const key of costMap.keys()) {

      years.push([key, this.translate.instant('SIMULATION.CP')]);
      years.push([key, this.translate.instant('SIMULATION.SC')]);

      const costPairs: Pair[] = costMap.get(key);
      const costPairsSc: Pair[] = costMapSc.get(key);
      const demandPairs: Pair[] = demandMap.get(key);
      const demandPairsSc: Pair[] = demandMapSc.get(key);

      const netPatientPairs: Pair[] = netPatientMap.get(key);
      const netPatientPairsSc: Pair[] = netPatientMapSc.get(key);

      const discontinuedPairs: Pair[] = discontinuedMap.get(key);
      const discontinuedPairsSc: Pair[] = discontinuedMapSc.get(key);

      let experiencedFirst = 0;
      let experiencedSecond = 0;
      let naiveFirst = 0;
      let naiveSecond = 0;

      let experiencedFirstSc = 0;
      let experiencedSecondSc = 0;
      let naiveFirstSc = 0;
      let naiveSecondSc = 0;

      let prepAll = 0;
      let prepDemand = 0;
      let pepDemand = 0;
      let prepDiscontinued = 0;

      let prepAllSc = 0;
      let prepDemandSc = 0;
      let pepDemandSc = 0;
      let prepDiscontinuedSc = 0;

      costPairs.forEach(element => {
        if (element.first === 'THERAPY_LINE_ONE_NAIVE') {
          naiveFirst = naiveFirst + element.second;
        } else if (element.first === 'THERAPY_LINE_TWO_NAIVE') {
          naiveSecond = naiveSecond + element.second;
        } else if (element.first === 'THERAPY_LINE_ONE_EXP') {
          experiencedFirst = experiencedFirst + element.second;
        } else if (element.first === 'THERAPY_LINE_TWO_EXP') {
          experiencedSecond = experiencedSecond + element.second;
        }
      });

      costPairsSc.forEach(element => {
        if (element.first === 'THERAPY_LINE_ONE_NAIVE') {
          naiveFirstSc = naiveFirstSc + element.second;
        } else if (element.first === 'THERAPY_LINE_TWO_NAIVE') {
          naiveSecondSc = naiveSecondSc + element.second;
        } else if (element.first === 'THERAPY_LINE_ONE_EXP') {
          experiencedFirstSc = experiencedFirstSc + element.second;
        } else if (element.first === 'THERAPY_LINE_TWO_EXP') {
          experiencedSecondSc = experiencedSecondSc + element.second;
        }
      });

      netPatientPairs.forEach(element => {
        if (element.first === 'PREP') {
          prepAll = prepAll + element.second;
        }
      });

      netPatientPairsSc.forEach(element => {
        if (element.first === 'PREP') {
          prepAllSc = prepAllSc + element.second;
        }
      });

      let preventionNumber = 0;
      let preventionNumberSc = 0;

      demandPairs.forEach(element => {
        if (element.first === 'PREVENTION') {
          prepDemand = Math.round((element.second * percentagePrep) / 100);
          preventionNumber = element.second;
          pepDemand = preventionNumber - prepDemand;
        }
      });

      demandPairsSc.forEach(element => {
        if (element.first === 'PREVENTION') {
          prepDemandSc = Math.round((element.second * percentagePrepSc) / 100);
          preventionNumberSc = element.second;
          pepDemandSc = preventionNumberSc - prepDemandSc;
        }
      });

      discontinuedPairs.forEach(element => {
        if (element.first === 'PREP') {
          prepDiscontinued = prepDiscontinued + element.second;
        }
      });

      discontinuedPairsSc.forEach(element => {
        if (element.first === 'PREP') {
          prepDiscontinuedSc = prepDiscontinuedSc + element.second;
        }
      });


      const preventionDrugCost = data.preventions[0].preventionCost;
      const preventionDrugCostSc = data.preventions[data.preventions.length - 1].preventionCost;

      const laboratoryCost = data.preventions[0].laboratoryCost;
      const laboratoryCostSc = data.preventions[data.preventions.length - 1].laboratoryCost;

      const staffCost = data.preventions[0].overheadCost;
      const staffCostSc = data.preventions[data.preventions.length - 1].overheadCost;

      const pepLabCost = Math.round(pepDemand * laboratoryCost / 2);
      const pepStaffCost = Math.round(pepDemand * staffCost / 2);
      const pepDrugCost = Math.round(pepDemand * preventionDrugCost / 2);
      const pepCost = pepLabCost + pepStaffCost + pepDrugCost;

      const pepLabCostSc = Math.round(pepDemandSc * laboratoryCostSc / 2);
      const pepStaffCostSc = Math.round(pepDemandSc * staffCostSc / 2);
      const pepDrugCostSc = Math.round(pepDemandSc * preventionDrugCostSc / 2);
      const pepCostSc = pepLabCostSc + pepStaffCostSc + pepDrugCostSc;

      const prepDemandLabCost = Math.round(prepDemand * laboratoryCost / 2);
      const prepDemandStaffCost = Math.round(prepDemand * staffCost / 2);
      const prepDemandDrugCost = Math.round(prepDemand * preventionDrugCost / 2);
      const prepDemandCost = prepDemandLabCost + prepDemandStaffCost + prepDemandDrugCost;

      const prepDemandLabCostSc = Math.round(prepDemandSc * laboratoryCostSc / 2);
      const prepDemandStaffCostSc = Math.round(prepDemandSc * staffCostSc / 2);
      const prepDemandDrugCostSc = Math.round(prepDemandSc * preventionDrugCostSc / 2);
      const prepDemandCostSc = prepDemandLabCostSc + prepDemandStaffCostSc + prepDemandDrugCostSc;

      const prepExLabCost = Math.round((prepAll - prepDemand) * laboratoryCost);
      const prepExStaffCost = Math.round((prepAll - prepDemand) * staffCost);
      const prepExDrugCost = Math.round((prepAll - prepDemand) * preventionDrugCost);
      const prepExCost = prepExLabCost + prepExStaffCost + prepExDrugCost;

      const prepExLabCostSc = Math.round((prepAllSc - prepDemandSc) * laboratoryCostSc);
      const prepExStaffCostSc = Math.round((prepAllSc - prepDemandSc) * staffCostSc);
      const prepExDrugCostSc = Math.round((prepAllSc - prepDemandSc) * preventionDrugCostSc);
      const prepExCostSc = prepExLabCostSc + prepExStaffCostSc + prepExDrugCostSc;

      const prepDiscontinuedLabCost = Math.round(prepDiscontinued * laboratoryCost / 2);
      const prepDiscontinuedStaffCost = Math.round(prepDiscontinued * staffCost / 2);
      const prepDiscontinuedDrugCost = Math.round(prepDiscontinued * preventionDrugCost / 2);
      const prepDiscontinuedCost = prepDiscontinuedLabCost + prepDiscontinuedStaffCost + prepDiscontinuedDrugCost;

      const prepDiscontinuedLabCostSc = Math.round(prepDiscontinuedSc * laboratoryCostSc / 2);
      const prepDiscontinuedStaffCostSc = Math.round(prepDiscontinuedSc * staffCostSc / 2);
      const prepDiscontinuedDrugCostSc = Math.round(prepDiscontinuedSc * preventionDrugCostSc / 2);
      const prepDiscontinuedCostSc = prepDiscontinuedLabCostSc + prepDiscontinuedStaffCostSc + prepDiscontinuedDrugCostSc;

      const prepAllDrugCost = prepDemandDrugCost + prepExDrugCost + prepDiscontinuedDrugCost;
      const preventionAllDrugCost = pepDrugCost + prepDemandDrugCost + prepExDrugCost + prepDiscontinuedDrugCost;

      const prepAllDrugCostSc = prepDemandDrugCostSc + prepExDrugCostSc + prepDiscontinuedDrugCostSc;
      const preventionAllDrugCostSc = pepDrugCostSc + prepDemandDrugCostSc + prepExDrugCostSc + prepDiscontinuedDrugCostSc;

      const prepCost = prepDemandCost + prepExCost + prepDiscontinuedCost;
      const preventionAllCost = pepCost + prepCost;

      const prepCostSc = prepDemandCostSc + prepExCostSc + prepDiscontinuedCostSc;
      const preventionAllCostSc = pepCostSc + prepCostSc;

      const simSize = Math.floor(data.preventions.length / 2);

      const naiveFirstCost = naiveFirst * data.preventions[0].artFirstCost;
      const naiveSecondCost = naiveSecond * data.preventions[0].artSecondCost;
      const naiveCost = naiveFirstCost + naiveSecondCost;
      const naiveFirstCostSc = naiveFirstSc * data.preventions[simSize].artFirstCost;
      const naiveSecondCostSc = naiveSecondSc * data.preventions[simSize].artSecondCost;
      const naiveCostSc = naiveFirstCostSc + naiveSecondCostSc;

      const experiencedFirstCost = experiencedFirst * data.preventions[0].artFirstCost;
      const experiencedSecondCost = experiencedSecond * data.preventions[0].artSecondCost;
      const experiencedCost = experiencedFirstCost + experiencedSecondCost;
      const experiencedFirstCostSc = experiencedFirstSc * data.preventions[simSize].artFirstCost;
      const experiencedSecondCostSc = experiencedSecondSc * data.preventions[simSize].artSecondCost;
      const experiencedCostSc = experiencedFirstCostSc + experiencedSecondCostSc;

      const totalPatients = naiveFirst + naiveSecond + experiencedFirst + experiencedSecond + preventionNumber;
      const totalPatientsSc = naiveFirstSc + naiveSecondSc + experiencedFirstSc + experiencedSecondSc + preventionNumberSc;

      const laboratoryAllCost = totalPatients * data.preventions[0].laboratoryCost;
      const laboratoryAllCostSc = totalPatientsSc * data.preventions[simSize].laboratoryCost;

      const overheadCost = totalPatients * data.preventions[0].overheadCost;
      const overheadCostSc = totalPatientsSc * data.preventions[simSize].overheadCost;

      const totalCost = naiveCost + experiencedCost + preventionAllDrugCost + laboratoryAllCost + overheadCost;
      const totalCostSc = naiveCostSc + experiencedCostSc + preventionAllDrugCostSc + laboratoryAllCostSc + overheadCostSc;

      this.preventionCost = this.preventionCost + Math.round(preventionAllCost);
      this.totalHivCost = this.totalHivCost + Math.round(naiveCost + experiencedCost + laboratoryAllCost + overheadCost);
      this.totalCost = this.preventionCost + this.totalHivCost;

      this.preventionCostSc = this.preventionCostSc + Math.round(preventionAllCostSc);
      this.totalHivCostSc = this.totalHivCostSc + Math.round(naiveCostSc + experiencedCostSc + laboratoryAllCostSc + overheadCostSc);
      this.totalCostSc = this.preventionCostSc + this.totalHivCostSc;

      totalData.push(parseFloat(totalCost.toFixed(0)));
      totalDataSc.push(parseFloat(totalCostSc.toFixed(0)));

      prepDrugCostData.push(prepAllDrugCost);
      prepDrugCostData.push(prepAllDrugCostSc);

      prepProgrammeData.push(prepCost);
      prepProgrammeData.push(prepCostSc);

      pepProgrammeData.push(pepCost);
      pepProgrammeData.push(pepCostSc);

      pepDrugCostData.push(pepDrugCost);
      pepDrugCostData.push(pepDrugCostSc);
    }

    const avertedDiff = (this.totalAvertedSc - this.totalAverted);
    this.costAverted = (this.totalCostSc - this.totalCost) / avertedDiff;

    this.totalHivCostStr = this.getCurrency(this.totalHivCost);
    this.totalHivCostScStr = this.getCurrency(this.totalHivCostSc);
    this.preventionCostStr = this.getCurrency(this.preventionCost);
    this.preventionCostScStr = this.getCurrency(this.preventionCostSc);
    this.totalCostStr = this.getCurrency(this.totalCost);
    this.totalCostScStr = this.getCurrency(this.totalCostSc);
    this.totalAvertedStr = this.totalAverted.toFixed(2);
    this.totalAvertedScStr = this.totalAvertedSc.toFixed(2);
    this.costAvertedStr = this.getCurrency(this.costAverted);

    this.differenceInTotalCost = this.totalHivCostSc + this.preventionCostSc - this.totalHivCost - this.preventionCostSc;
    this.differenceInTotalCostStr = this.getCurrency(this.differenceInTotalCost);
    this.differenceInAvertedCase = this.totalAvertedSc - this.totalAverted;
    this.differenceInAvertedCaseStr = this.differenceInAvertedCase.toFixed(2);

    if (this.differenceInTotalCost >= 0 && this.differenceInAvertedCase >= 0 && this.costAverted <= 3000) {
      this.costEffectivenessPlane = this.translate.instant('SIMULATION.PREVENTION.QUADRANT_1');
      this.costResult = this.translate.instant('SIMULATION.PREVENTION.ACCEPT');
      this.costColor = 'green';
    } else if (this.differenceInTotalCost >= 0 && this.differenceInAvertedCase >= 0 && this.costAverted > 3000) {
      this.costEffectivenessPlane = this.translate.instant('SIMULATION.PREVENTION.QUADRANT_1');
      this.costResult = this.translate.instant('SIMULATION.PREVENTION.REJECT');
      this.costColor = 'red';
    } else if (this.differenceInTotalCost < 0 && this.differenceInAvertedCase >= 0) {
      this.costEffectivenessPlane = this.translate.instant('SIMULATION.PREVENTION.QUADRANT_2');
      this.costResult = this.translate.instant('SIMULATION.PREVENTION.ACCEPT');
      this.costColor = 'green';
    } else if (this.differenceInTotalCost < 0 && this.differenceInAvertedCase < 0 && this.costAverted <= 3000) {
      this.costEffectivenessPlane = this.translate.instant('SIMULATION.PREVENTION.QUADRANT_3');
      this.costResult = this.translate.instant('SIMULATION.PREVENTION.REJECT');
      this.costColor = 'red';
    } else if (this.differenceInTotalCost < 0 && this.differenceInAvertedCase < 0 && this.costAverted > 3000) {
      this.costEffectivenessPlane = this.translate.instant('SIMULATION.PREVENTION.QUADRANT_3');
      this.costResult = this.translate.instant('SIMULATION.PREVENTION.ACCEPT');
      this.costColor = 'green';
    } else if (this.differenceInTotalCost >= 0 && this.differenceInAvertedCase < 0) {
      this.costEffectivenessPlane = this.translate.instant('SIMULATION.PREVENTION.QUADRANT_4');
      this.costResult = this.translate.instant('SIMULATION.PREVENTION.REJECT');
      this.costColor = 'red';
    }

    const simulations: string[] = [];
    simulations.push(this.translate.instant('SIMULATION.CURRENT_PRACTICE'));
    simulations.push(this.translate.instant('SIMULATION.SCENARIO'));

    const hivPrevention: number[] = [];
    hivPrevention.push(this.totalHivCost);
    hivPrevention.push(this.totalHivCostSc);

    const hivPreventionSc: number[] = [];
    hivPreventionSc.push(this.preventionCost);
    hivPreventionSc.push(this.preventionCostSc);

    const totalAverted: number[] = [];
    totalAverted.push(parseFloat(this.totalAverted.toFixed(2)));
    totalAverted.push(parseFloat(this.totalAvertedSc.toFixed(2)));

    this.chartTotalPreventionOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.PREP'),
          type: 'column',
          data: prepDrugCostData
        },
        {
          name: this.translate.instant('SIMULATION.PEP'),
          type: 'column',
          data: pepDrugCostData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.COST.NAME')
        },
        labels: {
          formatter: (value) => new Intl.NumberFormat('en-US',
            { minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
            .format(value)
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartTotalPreventionProgrammeOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.PREP'),
          type: 'column',
          data: prepProgrammeData
        },
        {
          name: this.translate.instant('SIMULATION.PEP'),
          type: 'column',
          data: pepProgrammeData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.COST.NAME')
        },
        labels: {
          formatter: (value) => new Intl.NumberFormat('en-US',
            { minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
            .format(value)
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartTotalAvertedOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.PREVENTION.TOTAL_AVERTED'),
          type: 'column',
          data: totalAverted
        }
      ],
      xaxis: {
        categories: simulations
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.CASE')
        },
        decimalsInFloat: 0
      },
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: false,
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      }
    };

    this.chartHivPreventionOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.PREVENTION.TOTAL_HIV'),
          type: 'column',
          data: hivPrevention
        },
        {
          name: this.translate.instant('SIMULATION.PREVENTION.PREVENTION_COST'),
          type: 'column',
          data: hivPreventionSc
        }
      ],
      xaxis: {
        categories: simulations
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.COST.NAME')
        },
        labels: {
          formatter: (value) => new Intl.NumberFormat('en-US',
            { minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
            .format(value)
        }
      },
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: false,
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      }
    };

  }

  setNewPatients(demandMap: Map<string, Array<Pair>>, demandMapSc: Map<string, Array<Pair>>,
    percentagePrep: number, percentagePrepSc: number) {
    const years: string[][] = [];
    const prepData: number[] = [];
    const pepData: number[] = [];

    const prepDataSc: number[] = [];
    const pepDataSc: number[] = [];

    for (const key of demandMap.keys()) {
      years.push([key]);

      const patientPairs: Pair[] = demandMap.get(key);
      const patientPairsSc: Pair[] = demandMapSc.get(key);

      patientPairs.forEach(element => {
        if (element.first === 'PREVENTION') {
          const total = element.second;
          const prepNumber = ((total * percentagePrep) / 100).toFixed(0);
          const pepNumber = total - parseInt(prepNumber, 10);
          prepData.push(parseInt(prepNumber, 10));
          pepData.push(Math.round(pepNumber));
        }
      });
      patientPairsSc.forEach(element => {
        if (element.first === 'PREVENTION') {
          const total = element.second;
          const prepNumber = ((total * percentagePrepSc) / 100).toFixed(0);
          const pepNumber = total - parseInt(prepNumber, 10);
          prepDataSc.push(parseInt(prepNumber, 10));
          pepDataSc.push(Math.round(pepNumber));
        }
      });
    }

    this.chartPatientPrepOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          data: prepData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          data: prepDataSc
        }
      ],
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: true
        }
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PREVENTION.NO_PATIENTS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartPatientPrepScOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          data: pepData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          data: pepDataSc
        }
      ],
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: true
        }
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PREVENTION.NO_PATIENTS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

  }

  setRiskPatients(demandMap: Map<string, Array<Pair>>, demandMapSc: Map<string, Array<Pair>>,
    data, dataSc) {
    const years: string[][] = [];

    const menToMenDataPrep: number[] = [];
    const pregnantDataPrep: number[] = [];
    const injectionDataPrep: number[] = [];
    const sexWorkersDataPrep: number[] = [];
    const serodiscordantDataPrep: number[] = [];
    const heterosexualDataPrep: number[] = [];
    const otherDataPrep: number[] = [];

    const menToMenDataPep: number[] = [];
    const pregnantDataPep: number[] = [];
    const injectionDataPep: number[] = [];
    const sexWorkersDataPep: number[] = [];
    const serodiscordantDataPep: number[] = [];
    const heterosexualDataPep: number[] = [];
    const otherDataPep: number[] = [];

    for (const key of demandMap.keys()) {
      years.push([key, this.translate.instant('SIMULATION.CP')]);
      years.push([key, this.translate.instant('SIMULATION.SC')]);

      const patientPairs: Pair[] = demandMap.get(key);
      const patientPairsSc: Pair[] = demandMapSc.get(key);

      patientPairs.forEach(element => {
        if (element.first === 'PREVENTION') {
          const total = element.second;
          let totalForRatePrep = 0;
          let totalForRatePep = 0;
          const percentagePrep = data.percentagePrep;
          const prepNumber = ((total * percentagePrep) / 100).toFixed(0);
          const pepNumber = total - parseInt(prepNumber, 10);

          const menToMenRate = data.percentageMenHavingSexWithMen;

          let menToMenCountPrep = (menToMenRate * parseInt(prepNumber, 10)) / 100;
          menToMenCountPrep = parseInt(menToMenCountPrep.toFixed(0), 10);
          if (menToMenCountPrep < 0) {
            menToMenCountPrep = 0;
          }
          totalForRatePrep = totalForRatePrep + menToMenCountPrep;
          menToMenDataPrep.push(menToMenCountPrep);

          let menToMenCountPep = (menToMenRate * pepNumber) / 100;
          menToMenCountPep = parseInt(menToMenCountPep.toFixed(0), 10);
          if (menToMenCountPep < 0) {
            menToMenCountPep = 0;
          }
          totalForRatePep = totalForRatePep + menToMenCountPep;
          menToMenDataPep.push(menToMenCountPep);

          const pregnantRate = data.percentagePregnantForPrevention;

          let pregnantCountPrep = (pregnantRate * parseInt(prepNumber, 10)) / 100;
          pregnantCountPrep = parseInt(pregnantCountPrep.toFixed(0), 10);
          if (pregnantCountPrep < 0) {
            pregnantCountPrep = 0;
          }
          totalForRatePrep = totalForRatePrep + pregnantCountPrep;
          pregnantDataPrep.push(pregnantCountPrep);

          let pregnantCountPep = (pregnantRate * pepNumber) / 100;
          pregnantCountPep = parseInt(pregnantCountPep.toFixed(0), 10);
          if (pregnantCountPep < 0) {
            pregnantCountPep = 0;
          }
          totalForRatePep = totalForRatePrep + pregnantCountPep;
          pregnantDataPep.push(pregnantCountPep);

          const injectionRate = data.percentageInjectionDrugUsers;

          let injectionCountPrep = (injectionRate * parseInt(prepNumber, 10)) / 100;
          injectionCountPrep = parseInt(injectionCountPrep.toFixed(0), 10);
          if (injectionCountPrep < 0) {
            injectionCountPrep = 0;
          }
          totalForRatePrep = totalForRatePrep + injectionCountPrep;
          injectionDataPrep.push(injectionCountPrep);

          let injectionCountPep = (injectionRate * pepNumber) / 100;
          injectionCountPep = parseInt(injectionCountPep.toFixed(0), 10);
          if (injectionCountPep < 0) {
            injectionCountPep = 0;
          }
          totalForRatePep = totalForRatePrep + injectionCountPep;
          injectionDataPep.push(injectionCountPep);

          const sexWorkersRate = data.percentageSexWorkersUnsafeDailyWork;

          let sexWorkersCountPrep = (sexWorkersRate * parseInt(prepNumber, 10)) / 100;
          sexWorkersCountPrep = parseInt(sexWorkersCountPrep.toFixed(0), 10);
          if (sexWorkersCountPrep < 0) {
            sexWorkersCountPrep = 0;
          }
          totalForRatePrep = totalForRatePrep + sexWorkersCountPrep;
          sexWorkersDataPrep.push(sexWorkersCountPrep);

          let sexWorkersCountPep = (sexWorkersRate * pepNumber) / 100;
          sexWorkersCountPep = parseInt(sexWorkersCountPep.toFixed(0), 10);
          if (sexWorkersCountPep < 0) {
            sexWorkersCountPep = 0;
          }
          totalForRatePep = totalForRatePrep + sexWorkersCountPep;
          sexWorkersDataPep.push(sexWorkersCountPep);

          const serodiscordantRate = data.percentageSerodiscordant;

          let serodiscordantCountPrep = (serodiscordantRate * parseInt(prepNumber, 10)) / 100;
          serodiscordantCountPrep = parseInt(serodiscordantCountPrep.toFixed(0), 10);
          if (serodiscordantCountPrep < 0) {
            serodiscordantCountPrep = 0;
          }
          totalForRatePrep = totalForRatePrep + serodiscordantCountPrep;
          serodiscordantDataPrep.push(serodiscordantCountPrep);

          let serodiscordantCountPep = (serodiscordantRate * pepNumber) / 100;
          serodiscordantCountPep = parseInt(serodiscordantCountPep.toFixed(0), 10);
          if (serodiscordantCountPep < 0) {
            serodiscordantCountPep = 0;
          }
          totalForRatePep = totalForRatePrep + serodiscordantCountPep;
          serodiscordantDataPep.push(serodiscordantCountPep);

          const otherRate = data.percentageOther;

          let otherCountPrep = (otherRate * parseInt(prepNumber, 10)) / 100;
          otherCountPrep = parseInt(otherCountPrep.toFixed(0), 10);
          if (otherCountPrep < 0) {
            otherCountPrep = 0;
          }
          totalForRatePrep = totalForRatePrep + otherCountPrep;
          otherDataPrep.push(otherCountPrep);

          let otherCountPep = (otherRate * pepNumber) / 100;
          otherCountPep = parseInt(otherCountPep.toFixed(0), 10);
          if (otherCountPep < 0) {
            otherCountPep = 0;
          }
          totalForRatePep = totalForRatePrep + otherCountPep;
          otherDataPep.push(otherCountPep);

          let heterosexualCountPrep = parseInt(prepNumber, 10) - totalForRatePrep;
          if (heterosexualCountPrep < 0) {
            heterosexualCountPrep = 0;
          }
          heterosexualDataPrep.push(heterosexualCountPrep);

          let heterosexualCountPep = pepNumber - totalForRatePep;
          if (heterosexualCountPep < 0) {
            heterosexualCountPep = 0;
          }
          heterosexualDataPep.push(heterosexualCountPep);
        }
      });

      patientPairsSc.forEach(element => {
        if (element.first === 'PREVENTION') {
          const total = element.second;
          let totalForRate = 0;
          let totalForRatePep = 0;
          const percentagePrep = dataSc.percentagePrep;
          const prepNumber = ((total * percentagePrep) / 100).toFixed(0);
          const pepNumber = total - parseInt(prepNumber, 10);

          const menToMenRate = data.percentageMenHavingSexWithMen;

          let menToMenCountPrep = (menToMenRate * parseInt(prepNumber, 10)) / 100;
          menToMenCountPrep = parseInt(menToMenCountPrep.toFixed(0), 10);
          if (menToMenCountPrep < 0) {
            menToMenCountPrep = 0;
          }
          totalForRate = totalForRate + menToMenCountPrep;
          menToMenDataPrep.push(menToMenCountPrep);

          let menToMenCountPep = (menToMenRate * pepNumber) / 100;
          menToMenCountPep = parseInt(menToMenCountPep.toFixed(0), 10);
          if (menToMenCountPep < 0) {
            menToMenCountPep = 0;
          }
          totalForRatePep = totalForRatePep + menToMenCountPep;
          menToMenDataPep.push(menToMenCountPep);

          const pregnantRate = data.percentagePregnantForPrevention;

          let pregnantCountPrep = (pregnantRate * parseInt(prepNumber, 10)) / 100;
          pregnantCountPrep = parseInt(pregnantCountPrep.toFixed(0), 10);
          if (pregnantCountPrep < 0) {
            pregnantCountPrep = 0;
          }
          totalForRate = totalForRate + pregnantCountPrep;
          pregnantDataPrep.push(pregnantCountPrep);

          let pregnantCountPep = (pregnantRate * pepNumber) / 100;
          pregnantCountPep = parseInt(pregnantCountPep.toFixed(0), 10);
          if (pregnantCountPep < 0) {
            pregnantCountPep = 0;
          }
          totalForRatePep = totalForRate + pregnantCountPep;
          pregnantDataPep.push(pregnantCountPep);

          const injectionRate = data.percentageInjectionDrugUsers;

          let injectionCountPrep = (injectionRate * parseInt(prepNumber, 10)) / 100;
          injectionCountPrep = parseInt(injectionCountPrep.toFixed(0), 10);
          if (injectionCountPrep < 0) {
            injectionCountPrep = 0;
          }
          totalForRate = totalForRate + injectionCountPrep;
          injectionDataPrep.push(injectionCountPrep);

          let injectionCountPep = (injectionRate * pepNumber) / 100;
          injectionCountPep = parseInt(injectionCountPep.toFixed(0), 10);
          if (injectionCountPep < 0) {
            injectionCountPep = 0;
          }
          totalForRatePep = totalForRate + injectionCountPep;
          injectionDataPep.push(injectionCountPep);

          const sexWorkersRate = data.percentageSexWorkersUnsafeDailyWork;

          let sexWorkersCountPrep = (sexWorkersRate * parseInt(prepNumber, 10)) / 100;
          sexWorkersCountPrep = parseInt(sexWorkersCountPrep.toFixed(0), 10);
          if (sexWorkersCountPrep < 0) {
            sexWorkersCountPrep = 0;
          }
          totalForRate = totalForRate + sexWorkersCountPrep;
          sexWorkersDataPrep.push(sexWorkersCountPrep);

          let sexWorkersCountPep = (sexWorkersRate * pepNumber) / 100;
          sexWorkersCountPep = parseInt(sexWorkersCountPep.toFixed(0), 10);
          if (sexWorkersCountPep < 0) {
            sexWorkersCountPep = 0;
          }
          totalForRatePep = totalForRate + sexWorkersCountPep;
          sexWorkersDataPep.push(sexWorkersCountPep);

          const serodiscordantRate = data.percentageSerodiscordant;

          let serodiscordantCountPrep = (serodiscordantRate * parseInt(prepNumber, 10)) / 100;
          serodiscordantCountPrep = parseInt(serodiscordantCountPrep.toFixed(0), 10);
          if (serodiscordantCountPrep < 0) {
            serodiscordantCountPrep = 0;
          }
          totalForRate = totalForRate + serodiscordantCountPrep;
          serodiscordantDataPrep.push(serodiscordantCountPrep);

          let serodiscordantCountPep = (serodiscordantRate * pepNumber) / 100;
          serodiscordantCountPep = parseInt(serodiscordantCountPep.toFixed(0), 10);
          if (serodiscordantCountPep < 0) {
            serodiscordantCountPep = 0;
          }
          totalForRatePep = totalForRate + serodiscordantCountPep;
          serodiscordantDataPep.push(serodiscordantCountPep);

          const otherRate = data.percentageOther;

          let otherCountPrep = (otherRate * parseInt(prepNumber, 10)) / 100;
          otherCountPrep = parseInt(otherCountPrep.toFixed(0), 10);
          if (otherCountPrep < 0) {
            otherCountPrep = 0;
          }
          totalForRate = totalForRate + otherCountPrep;
          otherDataPrep.push(otherCountPrep);

          let otherCountPep = (otherRate * pepNumber) / 100;
          otherCountPep = parseInt(otherCountPep.toFixed(0), 10);
          if (otherCountPep < 0) {
            otherCountPep = 0;
          }
          totalForRatePep = totalForRate + otherCountPep;
          otherDataPep.push(otherCountPep);

          let heterosexualCountPrep = parseInt(prepNumber, 10) - totalForRate;
          if (heterosexualCountPrep < 0) {
            heterosexualCountPrep = 0;
          }
          heterosexualDataPrep.push(heterosexualCountPrep);

          let heterosexualCountPep = pepNumber - totalForRatePep;
          if (heterosexualCountPep < 0) {
            heterosexualCountPep = 0;
          }
          heterosexualDataPep.push(heterosexualCountPep);

        }
      });

    }

    this.chartPrepRiskOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.PREVENTION.MEN_TO_MEN'),
          type: 'column',
          data: menToMenDataPrep
        },
        {
          name: this.translate.instant('SIMULATION.PREVENTION.PREGNANT'),
          type: 'column',
          data: pregnantDataPrep
        },
        {
          name: this.translate.instant('SIMULATION.PREVENTION.INJECTION'),
          type: 'column',
          data: injectionDataPrep
        },
        {
          name: this.translate.instant('SIMULATION.PREVENTION.SEX_WORKERS'),
          type: 'column',
          data: sexWorkersDataPrep
        },
        {
          name: this.translate.instant('SIMULATION.PREVENTION.SERODISCORDANT'),
          type: 'column',
          data: serodiscordantDataPrep
        },
        {
          name: this.translate.instant('SIMULATION.PREVENTION.HETEROSEXUAL'),
          type: 'column',
          data: heterosexualDataPrep
        },
        {
          name: this.translate.instant('SIMULATION.PREVENTION.OTHER'),
          type: 'column',
          data: otherDataPrep
        }
      ],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      colors: ['#008efb', '#00e397', '#feb219', '#ff4561', '#785dd0', '#6b0914', '#ffff45'],
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: false,
        },
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PREVENTION.NO_PATIENTS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      }
    };

    this.chartPrepRiskScOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.PREVENTION.MEN_TO_MEN'),
          type: 'column',
          data: menToMenDataPep
        },
        {
          name: this.translate.instant('SIMULATION.PREVENTION.PREGNANT'),
          type: 'column',
          data: pregnantDataPep
        },
        {
          name: this.translate.instant('SIMULATION.PREVENTION.INJECTION'),
          type: 'column',
          data: injectionDataPep
        },
        {
          name: this.translate.instant('SIMULATION.PREVENTION.SEX_WORKERS'),
          type: 'column',
          data: sexWorkersDataPep
        },
        {
          name: this.translate.instant('SIMULATION.PREVENTION.SERODISCORDANT'),
          type: 'column',
          data: serodiscordantDataPep
        },
        {
          name: this.translate.instant('SIMULATION.PREVENTION.HETEROSEXUAL'),
          type: 'column',
          data: heterosexualDataPep
        },
        {
          name: this.translate.instant('SIMULATION.PREVENTION.OTHER'),
          type: 'column',
          data: otherDataPep
        }
      ],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      colors: ['#008efb', '#00e397', '#feb219', '#ff4561', '#785dd0', '#6b0914', '#ffff45'],
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: false,
        },
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PREVENTION.NO_PATIENTS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      }
    };
  }

  setAvertedPatients(demandMap: Map<string, Array<Pair>>, demandMapSc: Map<string, Array<Pair>>) {
    const years: string[][] = [];
    const prepData: number[] = [];
    const pepData: number[] = [];

    const prepDataSc: number[] = [];
    const pepDataSc: number[] = [];

    for (const key of demandMap.keys()) {
      years.push([key]);

      const patientPairs: Pair[] = demandMap.get(key);
      const patientPairsSc: Pair[] = demandMapSc.get(key);

      patientPairs.forEach(element => {
        if (element.first === 'PREP') {
          prepData.push(element.second);
        } else if (element.first === 'PEP') {
          pepData.push(element.second);
        }
        this.totalAverted = this.totalAverted + element.second;
      });
      patientPairsSc.forEach(element => {
        if (element.first === 'PREP') {
          prepDataSc.push(element.second);
        } else if (element.first === 'PEP') {
          pepDataSc.push(element.second);
        }
        this.totalAvertedSc = this.totalAvertedSc + element.second;
      });
    }

    this.chartPrepAvertedOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          data: prepData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          data: prepDataSc
        }
      ],
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: true
        }
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PREVENTION.NO_CASES')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartPrepAvertedScOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          data: pepData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          data: pepDataSc
        }
      ],
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: true
        }
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PREVENTION.NO_CASES')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };
  }

  setVisitPatients(demandMap: Map<string, Array<Pair>>, demandMapSc: Map<string, Array<Pair>>) {
    const years: string[][] = [];
    const prepData: number[] = [];
    const pepData: number[] = [];

    const prepDataSc: number[] = [];
    const pepDataSc: number[] = [];

    for (const key of demandMap.keys()) {
      years.push([key]);

      const patientPairs: Pair[] = demandMap.get(key);
      const patientPairsSc: Pair[] = demandMapSc.get(key);

      patientPairs.forEach(element => {
        if (element.first === 'PREP') {
          prepData.push(Math.round(element.second));
        } else if (element.first === 'PEP') {
          pepData.push(Math.round(element.second));
        }
      });
      patientPairsSc.forEach(element => {
        if (element.first === 'PREP') {
          prepDataSc.push(Math.round(element.second));
        } else if (element.first === 'PEP') {
          pepDataSc.push(Math.round(element.second));
        }
      });

    }

    this.chartPrepVisitOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: prepData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: prepDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PREVENTION.NO_VISITS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartPrepVisitScOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: pepData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: pepDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PREVENTION.NO_VISITS')
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };
  }

  setNetPatients(patientsMap: Map<string, Array<Pair>>, patientsScMap: Map<string, Array<Pair>>) {

    const years: string[][] = [];

    const prepData: number[] = [];
    const pepData: number[] = [];

    for (const key of patientsMap.keys()) {
      years.push([key, this.translate.instant('SIMULATION.CP')]);
      years.push([key, this.translate.instant('SIMULATION.SC')]);

      const patientPairs: Pair[] = patientsMap.get(key);

      const patientScPairs: Pair[] = patientsScMap.get(key);

      let prep = 0;
      let prepSc = 0;
      let pep = 0;
      let pepSc = 0;

      patientPairs.forEach(element => {
        if (element.first === 'PREP') {
          prep = prep + element.second;
        } else if (element.first === 'PEP') {
          pep = pep + element.second;
        }
      });

      patientScPairs.forEach(element => {
        if (element.first === 'PREP') {
          prepSc = prepSc + element.second;
        } else if (element.first === 'PEP') {
          pepSc = pepSc + element.second;
        }
      });

      prepData.push(parseFloat(prep.toFixed(0)));
      prepData.push(parseFloat(prepSc.toFixed(0)));
      pepData.push(parseFloat(pep.toFixed(0)));
      pepData.push(parseFloat(pepSc.toFixed(0)));

    }

    this.chartPatientOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.PREP'),
          type: 'column',
          data: prepData
        },
        {
          name: this.translate.instant('SIMULATION.PEP'),
          type: 'column',
          data: pepData
        }
      ],
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.PREVENTION.NO_PATIENTS')
        }
      },
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        stacked: true,
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

  }

  setActivities(demandMap: Map<string, Array<Pair>>, demandMapSc: Map<string, Array<Pair>>) {
    let activityPrepNumber = 0;
    let activityPepNumber = 0;
    let activityPrepNumberSc = 0;
    let activityPepNumberSc = 0;

    const key = demandMap.keys().next().value;
    const patientPairs: Pair[] = demandMap.get(key);
    const patientPairsSc: Pair[] = demandMapSc.get(key);

    patientPairs.forEach(element => {
      if (element.first === 'PREP') {
        activityPrepNumber = element.second;
      } else if (element.first === 'PEP') {
        activityPepNumber = element.second;
      }
    });
    patientPairsSc.forEach(element => {
      if (element.first === 'PREP') {
        activityPrepNumberSc = element.second;
      } else if (element.first === 'PEP') {
        activityPepNumberSc = element.second;
      }
    });

    this.activityPrep = Math.round(activityPrepNumber);
    this.activityPep = Math.round(activityPepNumber);

    this.activityPrepSc = Math.round(activityPrepNumberSc);
    this.activityPepSc = Math.round(activityPepNumberSc);

  }

}
