import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  FilterData,
  FilterListDataExtended,
  TrialistSummaryResult,
  SummaryData,
} from 'src/app/shared/model/trial-list';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { SurveyResultsService } from 'src/app/shared/services/survey-results.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ChartComponent } from '@swimlane/ngx-charts';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { OrganisationService } from 'src/app/shared/services/organisation.service';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';

export interface Centre {
  action: string;
  centre: string;
  centreId: number;
  doctors?: Doctor[] | MatTableDataSource<Doctor>;
}

export interface Doctor {
  name: string;
  phone: string;
  email: string;
}

export interface CountryElement {
  id: number;
  name: string;
  count: number;
}

@Component({
  selector: 'app-trial-demographics',
  templateUrl: './trial-demographics.component.html',
  styleUrls: ['./trial-demographics.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class TrialDemographicsComponent implements OnInit {
  @ViewChild('chartRace')
  chartRace: ChartComponent = Object.create(null);
  @ViewChild('chartSex')
  chartSex: ChartComponent = Object.create(null);
  @ViewChild('chartType')
  chartType: ChartComponent = Object.create(null);
  @ViewChild('outerSort', { static: true }) sort: MatSort;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<Doctor>>;
  public chartRaceOptions;
  public chartSexOptions;
  public chartTypeOptions;

  filterListDataExtended: FilterListDataExtended;

  countryList: SummaryData[] = [];
  selectedRowIndex: any;
  displayedColumnsC: string[] = ['name', 'count'];
  dataSourceC = new MatTableDataSource<SummaryData>(this.countryList);
  selectionC = new SelectionModel<SummaryData>(true, []);

  data: TrialistSummaryResult = {
    total: 0,
    countries: null,
    states: null,
    cities: null,
    organizations: null,
    centers: null,
    summaryDataList: [],
    suppressedDataList: [],
    enrolledDataList: [],
    antiretroviralDataList: [],
    comedicationDataList: [],
    comorbiditiesDataList: [],
    mapSuppressedStatus: null,
    mapTreatmentStatus: null,
    mapGender: null,
    mapRace: null,
    mapType: null,
    mapAdherence: null,
    dataListMap: null,
    dataMapper: null
  };

  dropdownListCountry = [];
  selectedItemsCountry = [];
  dropdownSettingsCountry: IDropdownSettings = {};
  dropDownSelectCountry = false;

  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState: IDropdownSettings = {};
  dropDownSelectState = false;

  dropdownListCentre = [];
  selectedItemsCentre = [];
  dropdownSettingsCentre: IDropdownSettings = {};
  dropDownSelectCentre = false;

  filterDataList: Array<FilterData>;

  patientCount = 0;

  dataUser: Centre[] = [];

  dataSource: MatTableDataSource<Centre>;
  usersData: Centre[] = [];
  columnsToDisplay = ['action', 'centre'];
  innerDisplayedColumns = ['name', 'phone', 'email'];
  expandedElement: Centre | null;
  expandedElements: any[] = [];

  constructor(
    private router: Router,
    private storageService: StorageService,
    public surveyResultsService: SurveyResultsService,
    private notificationService: NotificationService,
    private organisationService: OrganisationService,
    private userProfileService: UserProfileService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef
  ) { }

  selectRow(row) {
    this.selectedRowIndex = row.id;
    this.usersData = [];
    this.dataSource = new MatTableDataSource(this.usersData);
    this.organisationService.getOrganisationsByCountryName(row.name)
      .subscribe((data) => {
        data.forEach(org => {
          this.usersData.push({
            action: 'expand_more',
            centre: org.name,
            centreId: org.id,
            doctors: []
          });
          this.dataSource = new MatTableDataSource(this.usersData);
        });
      });
  }

  selectExpandedRow2(element) {
    element.expanded = !element?.expanded;
    this.selectExpandedRow(element);
  }

  selectExpandedRow(element) {
    if (this.expandedElement === element) {
      element.action = 'expand_more';
      return this.expandedElement = this.expandedElement === element ? null : element;
    }
    const orgDoctors: Doctor[] = [];
    this.userProfileService.getUsersByOrganisation(element.centreId)
      .subscribe(usrData => {
        usrData.content.forEach(usr => {
          orgDoctors.push({
            name: usr.firstName + ' ' + usr.lastName,
            email: usr.email,
            phone: usr.phoneNumber
          });
        });
        element.doctors = orgDoctors;
        element.action = 'expand_less';
        return this.expandedElement = this.expandedElement === element ? null : element;
      });
  }

  applyFilter(filterValue: string) {
    this.innerTables.forEach(
      (table, index) =>
      ((table.dataSource as MatTableDataSource<Doctor>).filter = filterValue
        .trim()
        .toLowerCase())
    );
    console.log(filterValue);
  }

  ngOnInit(): void {
    this.dropdownSettingsCountry = {
      singleSelection: false,
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      limitSelection: 10,
      allowSearchFilter: false,
    };

    this.dropdownSettingsCentre = {
      singleSelection: false,
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      limitSelection: 10,
      allowSearchFilter: false,
    };

    this.dropdownSettingsState = {
      singleSelection: false,
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      limitSelection: 10,
      allowSearchFilter: false,
    };

    this.filterListDataExtended = JSON.parse(
      JSON.stringify(this.storageService.getTrialFilter())
    );

    if (!this.filterListDataExtended) {
      this.router.navigate(['smarthiv-trialist/trial-filter']);
      this.notificationService.error(
        this.translate.instant('TRIAL.SELECT_FILTERS')
      );
    }

    this.surveyResultsService
      .getTrialistDemographicResults(this.filterListDataExtended.filterListData)
      .subscribe((data) => {
        if (this.filterListDataExtended.filterListData.countries) {
          this.dropdownListCountry = this.filterListDataExtended.allCountries;
          this.selectedItemsCountry =
            this.filterListDataExtended.selectedCountries;
          this.dropdownListState = this.filterListDataExtended.allStates;
          this.selectedItemsState = this.filterListDataExtended.selectedStates;
        } else {
          this.dropdownListCountry = data.summaryDataList;
        }

        this.patientCount = data.total;
        this.data = data;
        this.setCharts();
      });

    this.dataSource = new MatTableDataSource(this.usersData);
    this.dataSource.sort = this.sort;
  }

  setCharts() {
    const years: string[] = [];

    const txExp: number[] = [];
    const naive: number[] = [];

    const mapType = new Map(Object.entries(this.data.mapType));

    for (const key of mapType.keys()) {
      const mapTypeTmp: Map<string, number> = new Map(
        Object.entries(mapType.get(key))
      );
      years.push(key);
      txExp.push(mapTypeTmp.get('Tx Exp'));
      naive.push(mapTypeTmp.get('Naive'));
    }

    const sex: string[] = [];

    const sexMale: number[] = [];
    const sexFemale: number[] = [];
    const sexMaleToFemale: number[] = [];
    const sexFemaleToMale: number[] = [];
    const sexNonBinary: number[] = [];

    sex.push(this.translate.instant('TRIAL.SEX_FIRST'));
    sex.push(this.translate.instant('TRIAL.SEX_SECOND'));
    sex.push(this.translate.instant('TRIAL.SEX_THIRD'));
    sex.push(this.translate.instant('TRIAL.SEX_FOURTH'));
    sex.push(this.translate.instant('TRIAL.SEX_ALL'));

    const mapSex = new Map(Object.entries(this.data.mapGender));
    const mapSexOne: Map<string, number> = new Map(
      Object.entries(mapSex.get('one'))
    );
    const mapSexTwo: Map<string, number> = new Map(
      Object.entries(mapSex.get('two'))
    );
    const mapSexThree: Map<string, number> = new Map(
      Object.entries(mapSex.get('three'))
    );
    const mapSexFour: Map<string, number> = new Map(
      Object.entries(mapSex.get('four'))
    );

    sexMale.push(mapSexOne.get('Male'));
    sexMale.push(mapSexTwo.get('Male'));
    sexMale.push(mapSexThree.get('Male'));
    sexMale.push(mapSexFour.get('Male'));
    sexMale.push(
      mapSexOne.get('Male') +
      mapSexTwo.get('Male') +
      mapSexThree.get('Male') +
      mapSexFour.get('Male')
    );

    sexFemale.push(mapSexOne.get('Female'));
    sexFemale.push(mapSexTwo.get('Female'));
    sexFemale.push(mapSexThree.get('Female'));
    sexFemale.push(mapSexFour.get('Female'));
    sexFemale.push(
      mapSexOne.get('Female') +
      mapSexTwo.get('Female') +
      mapSexThree.get('Female') +
      mapSexFour.get('Female')
    );

    sexMaleToFemale.push(mapSexOne.get('Male -> Female'));
    sexMaleToFemale.push(mapSexTwo.get('Male -> Female'));
    sexMaleToFemale.push(mapSexThree.get('Male -> Female'));
    sexMaleToFemale.push(mapSexFour.get('Male -> Female'));
    sexMaleToFemale.push(
      mapSexOne.get('Male -> Female') +
      mapSexTwo.get('Male -> Female') +
      mapSexThree.get('Male -> Female') +
      mapSexFour.get('Male -> Female')
    );

    sexFemaleToMale.push(mapSexOne.get('Female -> Male'));
    sexFemaleToMale.push(mapSexTwo.get('Female -> Male'));
    sexFemaleToMale.push(mapSexThree.get('Female -> Male'));
    sexFemaleToMale.push(mapSexFour.get('Female -> Male'));
    sexFemaleToMale.push(
      mapSexOne.get('Female -> Male') +
      mapSexTwo.get('Female -> Male') +
      mapSexThree.get('Female -> Male') +
      mapSexFour.get('Female -> Male')
    );

    sexNonBinary.push(mapSexOne.get('Non-Binary'));
    sexNonBinary.push(mapSexTwo.get('Non-Binary'));
    sexNonBinary.push(mapSexThree.get('Non-Binary'));
    sexNonBinary.push(mapSexFour.get('Non-Binary'));
    sexNonBinary.push(
      mapSexOne.get('Non-Binary') +
      mapSexTwo.get('Non-Binary') +
      mapSexThree.get('Non-Binary') +
      mapSexFour.get('Non-Binary')
    );

    const race: string[] = [];

    const raceAmerican: number[] = [];
    const raceAsian: number[] = [];
    const raceAfrican: number[] = [];
    const raceHispanic: number[] = [];
    const racePacific: number[] = [];
    const raceWhite: number[] = [];
    const raceOther: number[] = [];

    race.push(this.translate.instant('TRIAL.SEX_FIRST'));
    race.push(this.translate.instant('TRIAL.SEX_SECOND'));
    race.push(this.translate.instant('TRIAL.SEX_THIRD'));
    race.push(this.translate.instant('TRIAL.SEX_FOURTH'));
    race.push(this.translate.instant('TRIAL.SEX_ALL'));

    const mapRace = new Map(Object.entries(this.data.mapRace));
    const mapRaceOne: Map<string, number> = new Map(
      Object.entries(mapRace.get('one'))
    );
    const mapRaceTwo: Map<string, number> = new Map(
      Object.entries(mapRace.get('two'))
    );
    const mapRaceThree: Map<string, number> = new Map(
      Object.entries(mapRace.get('three'))
    );
    const mapRaceFour: Map<string, number> = new Map(
      Object.entries(mapRace.get('four'))
    );

    raceAmerican.push(mapRaceOne.get('American Indian or Alaska Native'));
    raceAmerican.push(mapRaceTwo.get('American Indian or Alaska Native'));
    raceAmerican.push(mapRaceThree.get('American Indian or Alaska Native'));
    raceAmerican.push(mapRaceFour.get('American Indian or Alaska Native'));
    raceAmerican.push(
      mapRaceOne.get('American Indian or Alaska Native')
      + mapRaceTwo.get('American Indian or Alaska Native')
      + mapRaceThree.get('American Indian or Alaska Native')
      + mapRaceFour.get('American Indian or Alaska Native')
    );

    raceAsian.push(mapRaceOne.get('Asian'));
    raceAsian.push(mapRaceTwo.get('Asian'));
    raceAsian.push(mapRaceThree.get('Asian'));
    raceAsian.push(mapRaceFour.get('Asian'));
    raceAsian.push(
      mapRaceOne.get('Asian')
      + mapRaceTwo.get('Asian')
      + mapRaceThree.get('Asian')
      + mapRaceFour.get('Asian')
    );

    raceAfrican.push(mapRaceOne.get('Black or African American'));
    raceAfrican.push(mapRaceTwo.get('Black or African American'));
    raceAfrican.push(mapRaceThree.get('Black or African American'));
    raceAfrican.push(mapRaceFour.get('Black or African American'));
    raceAfrican.push(
      mapRaceOne.get('Black or African American')
      + mapRaceTwo.get('Black or African American')
      + mapRaceThree.get('Black or African American')
      + mapRaceFour.get('Black or African American')
    );

    raceHispanic.push(mapRaceOne.get('Hispanic or Latino'));
    raceHispanic.push(mapRaceTwo.get('Hispanic or Latino'));
    raceHispanic.push(mapRaceThree.get('Hispanic or Latino'));
    raceHispanic.push(mapRaceFour.get('Hispanic or Latino'));
    raceHispanic.push(
      mapRaceOne.get('Hispanic or Latino')
      + mapRaceTwo.get('Hispanic or Latino')
      + mapRaceThree.get('Hispanic or Latino')
      + mapRaceFour.get('Hispanic or Latino')
    );

    racePacific.push(
      mapRaceOne.get('Native Hawaiian or Other Pacific Islander')
    );
    racePacific.push(
      mapRaceTwo.get('Native Hawaiian or Other Pacific Islander')
    );
    racePacific.push(
      mapRaceThree.get('Native Hawaiian or Other Pacific Islander')
    );
    racePacific.push(
      mapRaceFour.get('Native Hawaiian or Other Pacific Islander')
    );
    racePacific.push(
      mapRaceOne.get('Native Hawaiian or Other Pacific Islander')
      + mapRaceTwo.get('Native Hawaiian or Other Pacific Islander')
      + mapRaceThree.get('Native Hawaiian or Other Pacific Islander')
      + mapRaceFour.get('Native Hawaiian or Other Pacific Islander')
    );

    raceWhite.push(mapRaceOne.get('White'));
    raceWhite.push(mapRaceTwo.get('White'));
    raceWhite.push(mapRaceThree.get('White'));
    raceWhite.push(mapRaceFour.get('White'));
    raceWhite.push(
      mapRaceOne.get('White')
      + mapRaceTwo.get('White')
      + mapRaceThree.get('White')
      + mapRaceFour.get('White')
    );

    raceOther.push(mapRaceOne.get('Other'));
    raceOther.push(mapRaceTwo.get('Other'));
    raceOther.push(mapRaceThree.get('Other'));
    raceOther.push(mapRaceFour.get('Other'));
    raceOther.push(
      mapRaceOne.get('Other')
      + mapRaceTwo.get('Other')
      + mapRaceThree.get('Other')
      + mapRaceOne.get('Other')
    );

    this.chartTypeOptions = {
      series: [
        {
          name: this.translate.instant('TRIAL.TX_EXP'),
          data: txExp,
        },
        {
          name: this.translate.instant('TRIAL.NAIVE'),
          data: naive,
        },
      ],
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ['#008efb', '#00e397'],
      dataLabels: {
        enabled: true,
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: years,
        title: {
          text: this.translate.instant('TRIAL.YEARS'),
        },
      },
      yaxis: {
        title: {
          text: this.translate.instant('TRIAL.PATIENTS'),
        },
      },
      legend: {
        position: 'bottom',
        offsetX: 0,
      },
    };

    this.chartRaceOptions = {
      series: [
        {
          name: this.translate.instant('TRIAL.RACE_AMERICAN'),
          type: 'column',
          data: raceAmerican,
        },
        {
          name: this.translate.instant('TRIAL.RACE_ASIAN'),
          type: 'column',
          data: raceAsian,
        },
        {
          name: this.translate.instant('TRIAL.RACE_AFRICAN'),
          type: 'column',
          data: raceAfrican,
        },
        {
          name: this.translate.instant('TRIAL.RACE_HISPANIC'),
          type: 'column',
          data: raceHispanic,
        },
        {
          name: this.translate.instant('TRIAL.RACE_PACIFIC'),
          type: 'column',
          data: racePacific,
        },
        {
          name: this.translate.instant('TRIAL.RACE_WHITE'),
          type: 'column',
          data: raceWhite,
        },
        {
          name: this.translate.instant('TRIAL.RACE_OTHER'),
          type: 'column',
          data: raceOther,
        },
      ],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: false,
        },
      },
      xaxis: {
        categories: race,
      },
      yaxis: {
        title: {
          text: this.translate.instant('TRIAL.PATIENTS'),
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: 'bottom',
        offsetX: 0,
      },
      colors: [
        '#008efb',
        '#00e397',
        '#feb219',
        '#ff4561',
        '#785dd0',
        '#6b0914',
        '#f9a3a4',
        '#421243',
        '#7F94B0',
        '#EF6537',
        '#C0ADDB',
      ],
      fill: {
        opacity: 1,
      },
    };

    this.chartSexOptions = {
      series: [
        {
          name: this.translate.instant('TRIAL.SEX_MALE'),
          type: 'column',
          data: sexMale,
        },
        {
          name: this.translate.instant('TRIAL.SEX_FEMALE'),
          type: 'column',
          data: sexFemale,
        },
        {
          name: this.translate.instant('TRIAL.SEX_FEMALE_TO_MALE'),
          type: 'column',
          data: sexMaleToFemale,
        },
        {
          name: this.translate.instant('TRIAL.SEX_MALE_TO_FEMALE'),
          type: 'column',
          data: sexFemaleToMale,
        },
        {
          name: this.translate.instant('TRIAL.SEX_NON_BINARY'),
          type: 'column',
          data: sexNonBinary,
        },
      ],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: false,
        },
      },
      xaxis: {
        categories: sex,
      },
      yaxis: {
        title: {
          text: this.translate.instant('TRIAL.PATIENTS'),
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: 'bottom',
        offsetX: 0,
      },
      colors: [
        '#008efb',
        '#00e397',
        '#feb219',
        '#ff4561',
        '#785dd0',
        '#6b0914',
        '#f9a3a4',
        '#421243',
        '#7F94B0',
        '#EF6537',
        '#C0ADDB',
      ],
      fill: {
        opacity: 1,
      },
    };

    this.countryList = [];
    let countSummary = 0;
    this.dropdownListCountry.forEach((item) => {
      if (countSummary < 5) {
        const dataSummary = new SummaryData();
        dataSummary.name = item.name;
        dataSummary.id = item.id;
        dataSummary.count = item.count;
        this.countryList.push(dataSummary);
      }
      countSummary++;
    });

    this.dataSourceC = new MatTableDataSource<SummaryData>(this.countryList);
  }

  onDeSelectCountry(item: any) { }

  onDeSelectState(item: any) { }

  onDeSelectCentre(item: any) { }

  onItemSelect(item: any) { }

  onItemSelectCountry(item: any) { }

  onItemSelectState(item: any) { }

  onItemSelectCentre(item: any) { }

  onDropDownCloseCountry() {
    if (this.dropDownSelectCountry) {
      const countries = [];
      this.selectedItemsCountry.forEach((country) => {
        countries.push(country.name);
      });
      this.filterListDataExtended.filterListData.countries = countries;
      this.filterListDataExtended.filterListData.states = [];
      this.dropdownListState = [];
      this.selectedItemsState = [];

      this.filterListDataExtended.allCountries = this.dropdownListCountry;
      this.filterListDataExtended.selectedCountries = this.selectedItemsCountry;

      this.filterListDataExtended.allStates = [];
      this.filterListDataExtended.selectedStates = [];

      this.storageService.setTrialFilter(
        JSON.stringify(this.filterListDataExtended)
      );
      this.surveyResultsService
        .getTrialistDemographicResults(
          this.filterListDataExtended.filterListData
        )
        .subscribe((data) => {
          if (this.selectedItemsCountry.length > 0) {
            this.dropdownListState = data.summaryDataList;
          } else {
            this.dropdownListState = [];
            this.selectedItemsState = [];
          }
          this.patientCount = data.total;
          this.data = data;
          if (countries.length > 0) {
            this.setCharts();
          } else {
            this.setCharts();
          }
        });
    }
    this.dropDownSelectCountry = false;
  }

  onDropDownCloseState() {
    if (this.dropDownSelectState) {
      const states = [];
      this.selectedItemsState.forEach((selectedState) => {
        states.push(selectedState.name);
      });
      this.filterListDataExtended.filterListData.states = states;

      this.filterListDataExtended.allCountries = this.dropdownListCountry;
      this.filterListDataExtended.selectedCountries = this.selectedItemsCountry;

      this.filterListDataExtended.allStates = this.dropdownListState;
      this.filterListDataExtended.selectedStates = this.selectedItemsState;

      this.storageService.setTrialFilter(
        JSON.stringify(this.filterListDataExtended)
      );
      this.surveyResultsService
        .getTrialistDemographicResults(
          this.filterListDataExtended.filterListData
        )
        .subscribe((data) => {
          this.patientCount = data.total;
          this.data = data;
          this.setCharts();
        });
    }
    this.dropDownSelectState = false;
  }

  onDropDownCloseCentre() {
    this.dropDownSelectCentre = false;
  }

  getColumnName(name: string) {
    if (name === 'name') {
      return 'Name';
    } else if (name === 'email') {
      return 'Email';
    } else if (name === 'phone') {
      return 'Phone';
    } else {
      return name;
    }
  }
}
