<div class="title-container">
    <span>{{'ORGANISATION_USER_UPLOAD.TITLE' | translate}}</span>
    <button class="button-close" mat-button (click)="closeDialog()">X</button>
</div>

<div class="m-b-20 m-l-10" *ngIf="subTitle">
    <small>{{subTitle}}</small>
</div>

<div fxLayout="row" fxLayoutAlign="end center" >
    <a (click)="downloadExcelTemplate()" class="m-r-10 cursor-pointer">
        <i class="fa fa-file-excel-o fa-lg" title="{{'ORGANISATION_USER_UPLOAD.DOWNLOAD_TEMPLATE' | translate}}"></i>
        {{'ORGANISATION_USER_UPLOAD.DOWNLOAD_TEMPLATE' | translate}}
    </a>
</div>

<div>
    <div >
        <mat-form-field appearance="outline" class="cust-form-field">
            <mat-label>{{ 'ORGANISATION_USER_UPLOAD.LABEL.REGISTRATION_TYPE_SELECTION' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedInvitationType">
                <mat-option *ngFor="let bot of invitationTypeOptions" [value]="bot">
                    {{ baseInvitationTypeTranslationKey + '.' + bot | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="data.organisationId">
        <app-searchable-dropdown 
        [items]="organisationBranchList" 
        [label]="'ORGANISATION_USER_UPLOAD.LABEL.ORGANISATION_BRANCH'"
        [itemDisplayProperty]="'name'"
        [itemValueProperty]="'id'"
        [formFieldAppearance]="'outline'"
        (selectionChange)="onSelectOrganisationBranch($event)"
        ></app-searchable-dropdown>
    </div>

    <div class="m-b-20" *ngIf="isPatientUpload() && selectedOrganisationBranchId">
        <mat-form-field appearance="outline" class="cust-form-field m-b-20">
            <mat-label>{{ 'ORGANISATION_USER_UPLOAD.LABEL.CLINICIAN_LIST' | translate }}</mat-label>
            <input matInput [(ngModel)]="selectedCliniciansStr" [ngModelOptions]="{standalone: true}" [disabled]="true">
            <button matSuffix mat-icon-button (click)="openClinicianSelectionDialog()">
                <mat-icon>person_search</mat-icon>
            </button>
        </mat-form-field>
    </div>  
    
    <app-file-upload [multiple]="false" (uploadFile)="upload($event)" *ngIf="visibleUpload()"></app-file-upload>
    
    <ng-container *ngIf="fileUploadResponse?.errorList?.length > 0">
        <mat-list role="list">
            <mat-list-item class="text-danger" *ngFor="let userResponse of fileUploadResponse.errorList">{{ getErrorMessage(userResponse) }}</mat-list-item>
          </mat-list>
    </ng-container>
</div>
