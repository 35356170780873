<div fxLayout="row" fxLayoutAlign="space-between start">
    <div fxFlex="67">
        <div class="img-container" #blockImg>
            <div *ngIf="showWalkingMan" [ngClass]="[bntStyle]"></div>
            <div *ngIf="showWalkingMan2" [ngClass]="[bntStyle2]"></div>
            <div *ngIf="showWalkingMan3" [ngClass]="[bntStyle3]"></div>
            <div *ngIf="showWalkingMan4" [ngClass]="[bntStyle4]"></div>
            <div *ngIf="showWalkingMan5" [ngClass]="[bntStyle5]"></div>

            <div *ngIf="showMiddleWalkingMan" [ngClass]="[bntMiddleStyle]"></div>
            <div *ngIf="showMiddleWalkingMan2" [ngClass]="[bntMiddleStyle2]"></div>
            <div *ngIf="showMiddleWalkingMan3" [ngClass]="[bntMiddleStyle3]"></div>
            <div *ngIf="showMiddleWalkingMan4" [ngClass]="[bntMiddleStyle4]"></div>
            <div *ngIf="showMiddleWalkingMan5" [ngClass]="[bntMiddleStyle5]"></div>
            <div id="point2"></div>
        </div>
    </div>
    <div fxLayout="row" fxFlex="33">
        <div fxLayout="column" style="width: 100%;">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-card class="organisation">
                    <mat-card-header class="grad-pink text-white">
                        <mat-card-title>{{ 'SIMULATION.ORGANISATION_NAME' | translate }} : {{organizationName}}
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="question-list-container">
                        <mat-form-field>
                            <mat-label>{{ 'SIMULATION.INTERVAL' | translate }}</mat-label>
                            <input matInput [(ngModel)]="simulationDate" readonly="true"
                                matTooltip="{{ 'SIMULATION.INTERVAL' | translate }}">
                            <div *ngIf="isAnimationExist()">
                                <ngx-spinner name="simulator" bdColor="rgba(255, 255, 255, 1)" size="medium"
                                    color="#0b0b0b" type="timer" [fullScreen]="false"></ngx-spinner>
                            </div>
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>
            </div>
            <div fxLayout="column" class="dashed-border">
                <p fxFlex="100" class="input-title">HIGH LEVEL RESULTS</p>
                <div fxLayout="row" fxLayoutAlign="space-between center">

                    <div fxFlex="50">
                        <mat-card style="margin: 0px 5px 0px 0px !important;">
                            <mat-card-header class="grad-dark-blue text-white">
                                <mat-card-title>{{ 'SIMULATION.CURRENT_PRACTICE' | translate }}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content class="question-list-container">
                                <mat-form-field>
                                    <mat-label>{{ 'SIMULATION.NO_PATIENTS' | translate }}</mat-label>
                                    <input matInput [hidden]="isAnimationExist()" [(ngModel)]="patients" readonly="true"
                                        matTooltip="{{ 'SIMULATION.NO_PATIENTS' | translate }}">
                                    <div *ngIf="isAnimationExist()" class="example-section">
                                        <mat-progress-bar color="primary" mode="query"></mat-progress-bar>
                                        <div style="position: absolute; color: white;" matNativeControl>{{
                                            'SIMULATION.SIMULATING' | translate }}</div>
                                    </div>
                                    <mat-icon matSuffix>person_add_alt_1</mat-icon>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>{{ 'SIMULATION.VISITS' | translate }}</mat-label>
                                    <input matInput [hidden]="isAnimationExist()" [(ngModel)]="visits" readonly="true"
                                        matTooltip="{{ 'SIMULATION.VISITS' | translate }}">
                                    <div *ngIf="isAnimationExist()" class="example-section">
                                        <mat-progress-bar color="primary" mode="query"></mat-progress-bar>
                                        <div style="position: absolute; color: white;" matNativeControl>{{
                                            'SIMULATION.SIMULATING' | translate }}</div>
                                    </div>
                                    <mat-icon matSuffix>home</mat-icon>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>{{ 'SIMULATION.NO_HOURS' | translate }}</mat-label>
                                    <input matInput [hidden]="isAnimationExist()" [(ngModel)]="hours" readonly="true"
                                        matTooltip="{{ 'SIMULATION.NO_HOURS' | translate }}">
                                    <div *ngIf="isAnimationExist()" class="example-section">
                                        <mat-progress-bar color="primary" mode="query"></mat-progress-bar>
                                        <div style="position: absolute; color: white;" matNativeControl>{{
                                            'SIMULATION.SIMULATING' | translate }}</div>
                                    </div>
                                    <mat-icon matSuffix>access_time</mat-icon>
                                </mat-form-field>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <div fxFlex="50">
                        <mat-card style="margin: 0px 0px 0px 5px !important;">
                            <mat-card-header class="grad-pink text-white">
                                <mat-card-title>{{ 'SIMULATION.SCENARIO' | translate }}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content class="question-list-container">
                                <mat-form-field>
                                    <mat-label>{{ 'SIMULATION.NO_PATIENTS' | translate }}</mat-label>
                                    <input matInput [hidden]="isAnimationExist()" [(ngModel)]="patientsSc"
                                        readonly="true" matTooltip="{{ 'SIMULATION.NO_PATIENTS' | translate }}">
                                    <div *ngIf="isAnimationExist()" class="example-section">
                                        <mat-progress-bar color="warn" mode="query"></mat-progress-bar>
                                        <div style="position: absolute; color: white;" matNativeControl>{{
                                            'SIMULATION.SIMULATING' | translate }}</div>
                                    </div>
                                    <mat-icon matSuffix>person_add_alt_1</mat-icon>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>{{ 'SIMULATION.VISITS' | translate }}</mat-label>
                                    <input matInput [hidden]="isAnimationExist()" [(ngModel)]="visitsSc" readonly="true"
                                        matTooltip="{{ 'SIMULATION.VISITS' | translate }}">
                                    <div *ngIf="isAnimationExist()" class="example-section">
                                        <mat-progress-bar color="warn" mode="query"></mat-progress-bar>
                                        <div style="position: absolute; color: white;" matNativeControl>{{
                                            'SIMULATION.SIMULATING' | translate }}</div>
                                    </div>
                                    <mat-icon matSuffix>home</mat-icon>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>{{ 'SIMULATION.NO_HOURS' | translate }}</mat-label>
                                    <input matInput [hidden]="isAnimationExist()" [(ngModel)]="hoursSc" readonly="true"
                                        matTooltip="{{ 'SIMULATION.NO_HOURS' | translate }}">
                                    <div *ngIf="isAnimationExist()" class="example-section">
                                        <mat-progress-bar color="warn" mode="query"></mat-progress-bar>
                                        <div style="position: absolute; color: white;" matNativeControl>{{
                                            'SIMULATION.SIMULATING' | translate }}</div>
                                    </div>
                                    <mat-icon matSuffix>access_time</mat-icon>
                                </mat-form-field>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
            <div fxLayout="column" class="dashed-border">
                <p fxFlex="100" class="output-title">INPUT PANEL</p>
                <div fxLayout="row">
                    <button [disabled]="isAnimationExist()" fxFlex="50" mat-flat-button
                        class="grad-blue text-white btn-manager-left" style="text-align: right;"
                        (click)="openCountryDialog()"><span class="flag-code">{{code}}</span> <img src="{{flag}}">
                    </button>
                    <button [disabled]="isAnimationExist()" fxFlex="50" mat-flat-button
                        class="grad-blue text-white btn-manager-right" (click)="openCostDialog()">{{ 'SIMULATION.COST.NAME' |
                        translate }}</button>
                </div>
                <div fxLayout="row">
                    <button [disabled]="isAnimationExist()" fxFlex="50" mat-flat-button
                        class="grad-blue text-white btn-manager-left" (click)="openDialog()">{{
                        'SIMULATION.DEMAND.NAME' | translate }}</button>
                    <button [disabled]="isAnimationExist()" fxFlex="50" mat-flat-button
                        class="grad-green text-white btn-manager-right" (click)="openServiceDialog()">{{
                        'SIMULATION.SERVICE.NAME'
                        | translate }}</button>
                </div>
                <div fxLayout="row">
                    <button [disabled]="isAnimationExist()" fxFlex="50" mat-flat-button
                        class="grad-blue text-white btn-manager-left" (click)="openUnaidsDialog()">{{
                        'SIMULATION.UNAIDS.NAME' |
                        translate }}</button>
                    <button [disabled]="isAnimationExist()" fxFlex="50" mat-flat-button
                        class="grad-blue text-white btn-manager-right" (click)="openStaffDialog()">{{
                        'SIMULATION.HUMAN_RESOURCE'
                        | translate }}</button>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">

                </div>
                <div fxLayout="row">
                    <button [disabled]="isAnimationExist()" fxFlex="50" mat-flat-button
                        class="grad-dark-blue text-white btn-manager-left" (click)="runSimulations()">{{
                        'SIMULATION.RUN_MODEL' |
                        translate }}</button>
                    <button [disabled]="isAnimationExist()" fxFlex="50" mat-flat-button
                        class="grad-pink text-white btn-manager-right" (click)="downloadResultFile()">{{ 'SIMULATION.RESULT' |
                        translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>