import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { ArtCodeService } from 'src/app/shared/services/art-code.service';
import { ArtCodeUploadComponent } from '../../component/art-code-upload/art-code-upload.component';

@Component({
  selector: 'app-art-code-list',
  templateUrl: './art-code-list.component.html',
  styleUrls: ['./art-code-list.component.scss']
})
export class ArtCodeListComponent extends BaseListComponent implements OnInit {
  @ViewChild('idTemplate', { static: true }) idTemplate: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private artCodeService: ArtCodeService
  ) {
    super();
   }

  ngOnInit(): void {
    this.allColumnList = [
      {
        prop: 'id',
        name: this.translate.instant('ART_CODE_LIST.COL_LBL.ID'),
        cellTemplate: this.idTemplate,
        width: 100,
        maxWidth: 100
      },
      { prop: 'art', name: this.translate.instant('ART_CODE_LIST.COL_LBL.ART'), cellClass: '' },
      { prop: 'code', name: this.translate.instant('ART_CODE_LIST.COL_LBL.CODE'), cellClass: '' }

    ];

    this.columns = [
      this.allColumnList[0],
      this.allColumnList[1],
      this.allColumnList[2]
    ];

    this.loadData();
  }

  openUploadDialog(): void {
    const dialogConfig = this.createDialogConfig(null);
    const dialogRef = this.dialog.open(ArtCodeUploadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.loadData();
    });
  }

  loadData() {
    this.artCodeService.getArtCodes(this.page.pageNumber, this.page.size, this.sort).subscribe(page => {
      this.page.pageNumber = page.number;
      this.page.totalElements = page.totalElements;
      this.page.size = page.size;
      this.rows = page.content;
    });
  }

}
