<div class="content-box p-0" style="height: calc(100vh - 11.5rem);">
  <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start none" fxFlex="100">
    <div class="cust-sidebar-navigation" fxShow="false" fxShow.gt-sm>
      <ng-container  [ngTemplateOutlet]="menuContent"></ng-container>
    </div>
    <div fxHide="false" fxHide.gt-sm>
      <ng-container  [ngTemplateOutlet]="menuContent"></ng-container>
    </div>

    <div fxFlex>
        <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-template #menuContent>
  <mat-nav-list class="menu-list p-0">
    <mat-list-item [routerLink]="['detail']" routerLinkActive="active-list-item" class="m-b-20">
      <div class="list-item">
        <fa-icon class="list-item-img" [icon]="faInfoCircle" size="lg" ></fa-icon>
        <a matLine class="list-item-link"> {{ 'ORGANISATION.MENU.ORGANISATION_DETAIL' | translate }}</a>
      </div>
    </mat-list-item>
    <mat-list-item [routerLink]="['organisation-branches']" routerLinkActive="active-list-item" class="m-b-20">
      <div class="list-item">
        <fa-icon class="list-item-img" [icon]="faCity" size="lg" ></fa-icon>
        <a matLine class="list-item-link"> {{ 'ORGANISATION.MENU.ORGANISATION_BRANCHES' | translate }}</a>
      </div>
    </mat-list-item>
    <mat-list-item [routerLink]="['clinician-list']" routerLinkActive="active-list-item" class="m-b-20">
      <div class="list-item">
        <fa-icon  class="list-item-img" [icon]="faUserDoctor" size="lg"></fa-icon>
        <a matLine class="list-item-link">{{ 'ORGANISATION.MENU.ORGANISATION_CLINICIAN_LIST' | translate }}</a>
      </div>
    </mat-list-item>
    <mat-list-item [routerLink]="['patient-list']" routerLinkActive="active-list-item" class="m-b-20">
      <div class="list-item">
        <fa-icon  class="list-item-img" [icon]="faUsers" size="lg"></fa-icon>
        <a matLine class="list-item-link">{{ 'ORGANISATION.MENU.ORGANISATION_PATIENT_LIST' | translate }}</a>
      </div>
    </mat-list-item>
    <mat-list-item [routerLink]="['auditlytics-permissions']" routerLinkActive="active-list-item" class="m-b-20">
      <div class="list-item">
        <fa-icon  class="list-item-img" [icon]="faUserLock" size="lg"></fa-icon>
        <a matLine class="list-item-link">{{ 'ORGANISATION.MENU.ORGANISATION_AUDITLYTICS_PERMISSIONS' | translate }}</a>
      </div>
    </mat-list-item>

  </mat-nav-list>

</ng-template>