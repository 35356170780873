/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Page, Pageable, PageableSort } from '../model/pagination';
import {AdverseEvent, AdverseEventRequest, AdverseEventResponse} from '../model/adverse-event';
import { map } from 'rxjs/operators';
import { createPageParams } from '../util';

@Injectable({
  providedIn: 'root'
})
export class AdverseEventService {

  private baseUrl = '/adverse-event';

  constructor(private http: HttpClient) {
  }

  uploadFile(file: File): Observable<HttpEvent<{}>> {
    const fileAlias = 'file';
    const formData = new FormData();
    formData.set(fileAlias, file, file.name);

    let myheaders = new HttpHeaders();
    myheaders = myheaders.append('Accept', 'application/json');
    myheaders = myheaders.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(environment.url + `${this.baseUrl}/upload`, formData, {
      observe: 'events',
      reportProgress: true,
      responseType: 'json'
    });
  }

  getAdverseEvents(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<AdverseEvent>> {
    const pageParams = createPageParams(page, pageSize, sort, searchTerm);

    return this.http.get<Page<AdverseEvent>>(environment.url + `${this.baseUrl}`, { params: pageParams })
      .pipe(map((res: any) => (res || {})
      ));
  }

  getEventCategories(): Observable<string[]> {
    return this.http.get<string[]>(environment.url + `${this.baseUrl}/unique-categories`)
      .pipe(map((res: any) => (res || [])
      ));
  }
  getAdverseEventsFromCategories(request: string[]): Observable<string[]> {
    return this.http.post<string[]>(environment.url + `${this.baseUrl}/get-adverse-events/`,request)
      .pipe(map((res: any) => (res || [])
      ));
  }


  getResults(request: AdverseEventRequest): Observable<AdverseEventResponse[]> {
    return this.http.post<AdverseEventResponse[]>(environment.url + `${this.baseUrl}/get-results/`,request)
      .pipe(map((res: any) => (res || [])
      ));
  }

}
