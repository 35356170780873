<div class="login-register otp-generate-container">
  <div class="login-register-box">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="formGroup" autocomplete="off" novalidate (ngSubmit)="generateOtp()">
          <div class="text-center">
            <img class="company-logo" alt="homepage" src="assets/images/logo-wide.svg">
            <h4 class="m-t-0 title-text">{{ 'OTP_GENERATE.TITLE' | translate }}</h4>
          </div>
          <p class="text-center font-14">{{ 'OTP_GENERATE.DESCRIPTION' | translate }}</p>

          <div class="otp-generate-form-container">
   
            <app-email-or-phone-number></app-email-or-phone-number>

            <div class="pb-1 cust-form-field-container">
              <re-captcha
              (resolved)="captchaResolved($event)"
              siteKey="{{recaptchaSiteKey}}"
              formControlName="captcha"></re-captcha>
            </div>

            <button mat-raised-button color="warn" type="submit" class="btn-block btn-lg m-t-20"
              [disabled]="!formGroup.valid">{{ 'OTP_GENERATE.GENERATE_OTP_BUTTON' | translate }}</button>
          </div>

        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>