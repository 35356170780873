<mat-card>
    <span class="file-info" [ngStyle]="getErrorStyle()">{{fileUploadModel.file.name}}({{fileUploadModel.file.size | bytes}})</span>
    <section class="button-container">
        <mat-progress-bar [value]="fileUploadModel.progressPercentage"></mat-progress-bar>
        <a class="action-button" (click)="retryFile()" *ngIf="fileUploadModel.canRetry"><mat-icon>refresh</mat-icon></a>
        <a class="action-button" (click)="removeFile()" *ngIf="fileUploadModel.canCancel"><mat-icon>cancel</mat-icon></a>
    </section>
    <span class="file-info" [ngStyle]="getErrorStyle()">{{fileUploadModel.progressPercentage}}%</span>
    <span class="file-info" [ngStyle]="getErrorStyle()"> {{fileUploadModel.loadedSize | bytes}} of {{fileUploadModel.totalSize | bytes}}</span>
    <br>
    <span class="file-info" [ngStyle]="getErrorStyle()" *ngIf="fileUploadModel.errorMessage"  >{{fileUploadModel.errorMessage}}</span>
</mat-card>