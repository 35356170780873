import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmarthivTrialistRoutingModule } from './smarthiv-trialist-routing.module';
import { TrialFilterComponent } from './pages/trial-filter/trial-filter.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TrialCountryComponent } from './pages/trial-country/trial-country.component';

import { TrialSummaryComponent } from './pages/trial-summary/trial-summary.component';

import { TrialComorbidityComponent } from './pages/trial-comorbidity/trial-comorbidity.component';
import { TrialDemographicsComponent } from './pages/trial-demographics/trial-demographics.component';
import { TrialBudgetCalculatorComponent } from './pages/trial-budget-calculator/trial-budget-calculator.component';

@NgModule({
  declarations: [
    TrialFilterComponent,
    TrialCountryComponent,
    TrialSummaryComponent,
    TrialComorbidityComponent,
    TrialDemographicsComponent,
    TrialBudgetCalculatorComponent
  ],
  imports: [
    CommonModule,
    SmarthivTrialistRoutingModule,
    SharedModule
  ]
})
export class SmarthivTrialistModule { }
