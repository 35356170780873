import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatTable } from '@angular/material/table';

interface Month {
  value: number;
  viewValue: string;
}

interface Personnel {
  value: string;
  viewValue: string;
}

interface Budget {
  personnel: string;
  needed: number;
  annualSalary: number;
  months: number;
  time: number;
  totalSalary: number;
  fringeRate: number;
  fringeTotalSalary: number;
}

@Component({
  selector: 'app-trial-budget-calculator',
  templateUrl: './trial-budget-calculator.component.html',
  styleUrls: ['./trial-budget-calculator.component.scss'],
})
export class TrialBudgetCalculatorComponent implements OnInit {
  items: Array<any> = [];
  displayedColumns = [
    'budget-personnel',
    'budget-needed',
    'budget-annual-salary',
    'budget-months',
    'budget-time',
    'budget-total-salary',
    'budget-fringe-rate',
    'budget-fringe-total-salary',
    'budget-action',
  ];
  dataSource = new MatTableDataSource(this.items);
  newItem: any;
  needed: number;
  annualSalary: number;
  time: number;
  totalSalary: number;
  fringeRate: number;
  fringeTotalSalary: number;
  indexTmp = 0;
  lastElement: any;
  subTotal: number;
  fringeTotalSalaryBefore: number;

  value: number;
  form: FormGroup;
  months: Month[] = [
    { value: 0.25, viewValue: '3' },
    { value: 0.5, viewValue: '6' },
    { value: 0.75, viewValue: '9' },
    { value: 1, viewValue: '12' },
  ];
  personnels: Personnel[] = [
    { value: 'Principal investigator', viewValue: 'Principal investigator' },
    { value: 'Project coordinator', viewValue: 'Project coordinator' },
    {
      value: 'Clinical research manager',
      viewValue: 'Clinical research manager',
    },
    { value: 'Enumarator', viewValue: 'Enumarator' },
    { value: 'Counselor', viewValue: 'Counselor' },
  ];
  monthsControl = new FormControl(this.months[2].value);
  personnelControl = new FormControl(this.personnels[1].value);

  constructor(private formBuilder: FormBuilder) {}

  createForm() {
    this.form = this.formBuilder.group({
      month: [],
      personnel: [],
      needed: [],
      annualSalary: [],
      time: [],
      fringeRate: [],
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.subTotal = 0;
  }

  addItems() {
    const annualSalaryTmp = this.form.get('annualSalary').value;
    const monthsTmp = this.form.get('month').value;
    let monthsViewValue = '';
    const fringeRateTmp = this.form.get('fringeRate').value;
    const neededTmp = this.form.get('needed').value;
    const timeTmp = this.form.get('time').value;
    const totalSalaryTmp =
      annualSalaryTmp * monthsTmp * neededTmp * (timeTmp / 100);
    const fringeTotalSalaryTmp =
      totalSalaryTmp + totalSalaryTmp * (fringeRateTmp / 100);
    this.months.forEach((currentValue, index) => {
      if (currentValue.value === monthsTmp) {
        monthsViewValue = currentValue.viewValue;
      }
    });

    this.newItem = {
      personnel: this.form.get('personnel').value,
      personnelLast: this.form.get('personnel').value,
      annualSalary: annualSalaryTmp,
      annualSalaryLast: annualSalaryTmp,
      months: monthsViewValue,
      monthsLast: monthsViewValue,
      fringeRate: fringeRateTmp,
      fringeRateLast: fringeRateTmp,
      needed: neededTmp,
      neededLast: neededTmp,
      time: timeTmp,
      timeLast: timeTmp,
      totalSalary: totalSalaryTmp,
      fringeTotalSalary: fringeTotalSalaryTmp,
      id: this.items.length,
      edit: false,
    };
    console.log(this.newItem.personnel);
    this.items.push(this.newItem);
    //this.items.push(this.newItem);
    console.log(this.form.get('annualSalary').value);
    this.subTotal = this.subTotal + fringeTotalSalaryTmp;
    console.log(this.subTotal);
    this.newItem = null;
    this.dataSource = new MatTableDataSource(this.items);
  }

  removeItem(index) {
    this.items.splice(index, 1); // remove 1 item at ith place
  }

  deleteItem(element) {
    //this.items.splice(index, 1); // remove 1 item at ith place
    console.log(element);
    this.items.forEach((currentValue, index) => {
      if (currentValue.id === element.id) {
        this.indexTmp = index;
        this.subTotal = this.subTotal - currentValue.fringeTotalSalary;
      }
    });
    this.items.splice(this.indexTmp, 1);
    this.dataSource = new MatTableDataSource(this.items);
  }

  updateItem(element) {
    this.items.forEach((currentValue, index) => {
      if (currentValue.id === element.id) {
        this.indexTmp = index;
        currentValue.edit = false;
        this.months.forEach((monthValue, indexM) => {
          if (monthValue.value === currentValue.months) {
            currentValue.totalSalary =
              currentValue.annualSalary *
              currentValue.months *
              currentValue.needed *
              (currentValue.time / 100);
            currentValue.fringeTotalSalary =
              currentValue.totalSalary +
              currentValue.totalSalary * (currentValue.fringeRate / 100);
            currentValue.months = monthValue.viewValue;

            currentValue.personnelLast = currentValue.personnel;
            currentValue.annualSalaryLast = currentValue.annualSalary;
            currentValue.monthsLast = currentValue.months;
            currentValue.fringeRateLast = currentValue.fringeRate;
            currentValue.neededLast = currentValue.needed;
            currentValue.timeLast = currentValue.time;
            this.subTotal = this.subTotal - this.fringeTotalSalaryBefore;
            this.subTotal = this.subTotal + currentValue.fringeTotalSalary;
          }
        });
      }
    });
    this.dataSource = new MatTableDataSource(this.items);
  }

  editItem(element) {
    //this.items.splice(index, 1); // remove 1 item at ith place
    this.lastElement = element;
    console.log(element + ' EEE');
    console.log('1 - ' + this.lastElement.annualSalary);
    this.items.forEach((currentValue, index) => {
      if (currentValue.id === element.id) {
        this.indexTmp = index;
        currentValue.edit = true;
        this.fringeTotalSalaryBefore = currentValue.fringeTotalSalary;
        this.months.forEach((monthValue, indexM) => {
          if (monthValue.viewValue === currentValue.months) {
            currentValue.months = monthValue.value;
          }
        });
      }
    });
    this.dataSource = new MatTableDataSource(this.items);
    console.log('2 - ' + this.lastElement.annualSalary);
  }

  cancelEdit(element) {
    console.log(element + ' CCC');
    console.log('3 - ' + this.lastElement.annualSalary);
    this.items.forEach((currentValue, index) => {
      if (currentValue.id === element.id) {
        this.indexTmp = index;
        currentValue.edit = false;
        currentValue.personnel = currentValue.personnelLast;
        currentValue.annualSalary = currentValue.annualSalaryLast;
        currentValue.months = currentValue.monthsLast;
        currentValue.fringeRate = currentValue.fringeRateLast;
        currentValue.needed = currentValue.neededLast;
        currentValue.time = currentValue.timeLast;
        console.log('4 - ' + currentValue.neededLast);
        /*this.months.forEach((monthValue, indexM) => {
          if (monthValue.viewValue === currentValue.months) {
            currentValue.months = monthValue.value;
          }
        });*/
      }
    });
    //this.dataSource = new MatTableDataSource(this.items);
  }
}
