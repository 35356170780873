<div class="p-24 pb-0">
    <!-- <mat-card-header> -->
        <mat-card-title class="font-size-2">{{'USER_PROFILE.INDIVIDUAL_CLINICIAN_LIST.TITLE' | translate}}</mat-card-title>
    <!-- </mat-card-header>
    <mat-card-content> -->

        <div fxLayout="row wrap" class="align-items-center">
            <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100">
              <app-search-field [label]="'USER_PROFILE.INDIVIDUAL_CLINICIAN_LIST.SEARCH_FIELD.LABEL'" 
                [hint]="'USER_PROFILE.INDIVIDUAL_CLINICIAN_LIST.SEARCH_FIELD.HINT'" 
                (searchTermChanged)="onSearchTermChanged($event)">
              </app-search-field>
            </div>
            <div fxFlex.gt-md="75" class="text-right">
                <!--div class="add-new-container m-b-20" *ngIf="pageMode !== PageMode.VIEW">
                    <button mat-raised-button color="primary" class="m-r-5 m-t-5" (click)="openSendInvitationToUnregisteredUserDialog()" >{{'ORGANISATION.INVITE_UNREGISTERED_CLINICIAN' | translate}}</button>
                    <button mat-raised-button color="primary" class="m-r-5 m-t-5" (click)="openUploadUserDialog()" >{{'ORGANISATION.UPLOAD_CLINICIAN_FILE' | translate}}</button>
                </div-->
            </div>
          </div>

        <div>
            <app-datatable
                [styleClass]="'cust-no-border-datatable'"
                [allColumnList]="allColumnList"
                [columns]="columns"
                [rows]="rows"
                [page]="page"
                (pageChanged)="onPageChanged($event)"
                (sortChanged)="onSortChanged($event)">
            </app-datatable>
        </div>
    <!-- </mat-card-content> -->
</div>

<ng-template #rowNumberTemplate let-row="row" let-value="value" let-rowIndex="rowIndex">
    <span class="ng-star-inserted">{{(page.pageNumber * page.size) + rowIndex + 1}}</span>
</ng-template>

<ng-template #nameInfoTemplate let-row="row" let-value="value">
      <div class="d-flex">
        <img class="mat-card-avatar" [src]='getProfilePhotoUrl(row.clinician)'>
        <div class="m-l-15">
          <p class="font-bold m-b-0 m-t-0">{{ getNameStr(row.clinician) }}</p>
          <small class="text-muted" matTooltip="{{getRolesAsString(row.clinician.roles)}}">{{getRolesAsString(row.clinician.roles)}}</small>
        </div>
      </div>
</ng-template>

<ng-template #actionTemplate let-row="row" let-value="value">
    <div class="d-flex">
        <!--a (click)="openDetailDialog(PageMode.VIEW, row)" class="m-r-10 cursor-pointer">
            <i class="fa fa-eye fa-lg text-info"></i>
        </a-->
        <a [matMenuTriggerFor]="overflowRowMenu" [matMenuTriggerData]="{rowClinicianPatientSummary: row}" class="m-r-10 cursor-pointer">
            <i class="fa fa-ellipsis-v fa-lg"></i>
        </a>
    </div>

</ng-template>


<!-- ============================================================== -->
<!-- Row overflow menu-->
<!-- ============================================================== -->
<mat-menu #overflowRowMenu="matMenu">
    <ng-template matMenuContent let-selectedRow="rowClinicianPatientSummary" >
        <button mat-menu-item *ngIf="!isMe(selectedRow.clinician)" (click)="removeFromClinicianList(selectedRow)">Disable HCW Connection</button>
    </ng-template>
</mat-menu>
