import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import { ClinicianPatientSelectionDialogComponent } from 'src/app/shared/component/clinician-patient-selection-dialog/clinician-patient-selection-dialog.component';
import { UserSelectionDialogComponent } from 'src/app/shared/component/user-selection-dialog/user-selection-dialog.component';
import { DEFAULT_USER_PHOTO } from 'src/app/shared/constants';
import { PushNotificationType } from 'src/app/shared/model/enum/push-notification-type';
import { Role } from 'src/app/shared/model/enum/role';
import { ChatRoomResponse, ChatRoomUserResponse, NewChatRoom, PushNotification } from 'src/app/shared/model/push-notification';
import { UserFilterRequest, UserSummary } from 'src/app/shared/model/user-account';
import { LoginResponse } from 'src/app/shared/model/user-profile';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { PushNotificationService } from 'src/app/shared/services/push-notification.service';
import { DocumentStateService } from 'src/app/shared/state/document-state.service';
import { hasUserAnyClinicianRelatedRole, convertToRoleStringArray, getClinicianRelatedRoles } from 'src/app/shared/util';


@Component({
  selector: 'app-chat-contact-list',
  templateUrl: './chat-contact-list.component.html',
  styleUrls: ['./chat-contact-list.component.scss']
})
export class ChatContactListComponent extends BaseListComponent implements OnInit, OnDestroy {

  @Input() from: LoginResponse;
  @Input() fromId;
  @Output() changeSelectedRoom = new EventEmitter<ChatRoomUserResponse>();
  @Output() newChatRoom = new EventEmitter<NewChatRoom>();

  chatRoomList: ChatRoomResponse[];
  selectedChatRoomUser: ChatRoomUserResponse;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private pushNotificationService: PushNotificationService,
    private documentStateService: DocumentStateService
  ) { 
    super();
  }

  ngOnInit(): void {
    this.loadData();

    const subsNotificationChange = this.pushNotificationService.getPushNotificationChangeObservable().subscribe(notification => {
      if (this.fromId == notification.fromId) {
        this.updateLastMessageOrLoadData(notification.toId, notification);
      }

      if (this.fromId == notification.toId) {
        this.updateLastMessageOrLoadData(notification.fromId, notification);
      }
    });

    const subsDocumentStateChange = this.documentStateService.getDocumentVisibilityChange().subscribe(data => {
      if (data.currentState === 'visible') {
        this.loadData();  
      }
    });

    this.subscription(subsNotificationChange);
    this.subscription(subsDocumentStateChange);
  }

  public loadData() {
    this.pushNotificationService.listChatRoomsOfMe().subscribe(response => {
      this.sortList(response);
      
      this.selectedChatRoomUser = this.selectedChatRoomUser ?? this.chatRoomList?.[0].user;

      if(this.selectedChatRoomUser) {
        this.changeSelectedRoom.emit(this.selectedChatRoomUser);
      } 
    }, error => {
      console.log('Error on while loading chat rooms : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('Conversation list could not load'));
    } );
  }

  updateLastMessageOrLoadData(userId, notification: PushNotification) {
    const found = this.chatRoomList.find(room => room?.user?.id === userId);
    if (found) {
      found.lastMessage = notification;
      this.sortList(this.chatRoomList);
    } else {
      this.loadData();
    }
  }

  sortList(roomList: ChatRoomResponse[]) {
    this.chatRoomList = roomList.sort((m1,m2) => {
      const d1 = new Date(m1.lastMessage.createdAt);
      const d2 = new Date(m2.lastMessage.createdAt);
      return d2.getTime() - d1.getTime()
    });
  }

  getOrDefaultPhoto(photoUrl: string) {
    return photoUrl || DEFAULT_USER_PHOTO;
  }

  onSelectChatRoomUser(chatRoom: ChatRoomResponse) {
    this.selectedChatRoomUser = chatRoom.user;
    chatRoom.lastMessage.readAt = new Date().toISOString();
    this.changeSelectedRoom.emit(this.selectedChatRoomUser);
  }

  isUserClinician() {
    return hasUserAnyClinicianRelatedRole(this.from.roles);
  }

  isLastMessageRead(chatRoom: ChatRoomResponse) {
    return (chatRoom.lastMessage.readAt) || (chatRoom.lastMessage.fromId===this.fromId);
  }

  openUserSelectionDialog(filterClinician: boolean, notificationType) {

    const filterRequest = new UserFilterRequest(); 
        
    let roles = [];

    if (filterClinician) {
      roles = getClinicianRelatedRoles();
    }
    
    if (!filterClinician) {
      roles.push(Role.PATIENT);
    }

    filterRequest.roles = convertToRoleStringArray(roles);

    let organisationBranchId = this.from.patientOrganisationBranchId;
    if (this.isUserClinician()) {
      organisationBranchId = this.from.clinicianOrganisationBranchId;
    }

    const dialogConfig = this.createDialogConfig({
      searchInOrganisationBranch: true,
      organisationBranchId: organisationBranchId,
      defaultFilter: filterRequest,
      title: notificationType !== 'chat' ? 'Select user for notification' : 'Select user for chat',
      rowSelectionType: 'multiple',
      selectAllRowsVisible: notificationType !== 'chat'
    });
    const userSelectionDialogRef = this.dialog.open(UserSelectionDialogComponent, dialogConfig);

    userSelectionDialogRef.afterClosed().subscribe(data => {
      if (data?.selectedRows?.length > 0) {
        this.selectRoomOrCreateNewRoom(data.selectedRows, notificationType);
      }
    });
  }

  openIndividualPatientDialog(filterClinician: boolean, notificationType) {
    const dialogConfig = this.createDialogConfig({
      searchInOrganisationBranch: true,
      title: notificationType !== 'chat' ? 'Select user for notification' : 'Select user for chat',
      rowSelectionType: 'multiple',
      searchPatients: !filterClinician,
      searchClinicians: filterClinician
    });

    const userSelectionDialogRef = this.dialog.open(ClinicianPatientSelectionDialogComponent, dialogConfig);

    userSelectionDialogRef.afterClosed().subscribe(data => {
      if (data?.selectedRows?.length > 0) {
        let userList = [];
        
        if (filterClinician) {
          userList = data?.selectedRows?.map(row => row.clinician);
        } else {
          userList = data?.selectedRows?.map(row => row.patient);
        }
        this.selectRoomOrCreateNewRoom(userList, notificationType);
      }
    });
  }


  selectRoomOrCreateNewRoom(userList: UserSummary[], notificationType) {
    if (this.chatRoomList?.length > 0 && userList?.length === 1) {
      const found = this.chatRoomList.find(cr => cr?.user?.id === userList[0].id);
      if (found) {
        this.onSelectChatRoomUser(found);
        return;
      }
    }
    this.selectedChatRoomUser = null;
    const newChatRoom = new NewChatRoom();
    newChatRoom.type = notificationType === 'chat' ? PushNotificationType.CHAT : PushNotificationType.NOTIFICATION;
    newChatRoom.userList = userList;
    this.newChatRoom.emit(newChatRoom);
  }
  

}
