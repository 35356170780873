/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import { OrganisationBranchSelectionDialogComponent } from 'src/app/shared/component/organisation-branch-selection-dialog/organisation-branch-selection-dialog.component';
import { UserSelectionDialogComponent } from 'src/app/shared/component/user-selection-dialog/user-selection-dialog.component';
import { DEFAULT_USER_PHOTO } from 'src/app/shared/constants';
import { PageMode } from 'src/app/shared/model/enum/page-mode';
import { Role } from 'src/app/shared/model/enum/role';
import { UserSummaryAdmin } from 'src/app/shared/model/user-account';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { UserAdminService } from 'src/app/shared/services/user-admin.service';
import { hasUserAnyClinicianRelatedRole, rolesToStringArray, userHasRole } from 'src/app/shared/util';
import { UserDetailComponent } from '../../component/user-detail/user-detail.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent extends BaseListComponent implements OnInit {
  @ViewChild('rowNumberTemplate', { static: true }) rowNumberTemplate: TemplateRef<any>;
  @ViewChild('nameInfoTemplate', { static: true }) nameInfoTemplate: TemplateRef<any>;
  @ViewChild('activeTemplate', { static: true }) activeTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate', { static: true }) actionTemplate: TemplateRef<any>;
  @ViewChild('clinicianOrganistionTemplate', { static: true }) clinicianOrganistionTemplate: TemplateRef<any>;
  @ViewChild('patientOrganistionTemplate', { static: true }) patientOrganistionTemplate: TemplateRef<any>;

  

  PageMode: typeof PageMode = PageMode;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private userAdminService: UserAdminService
  ) {
    super();
   }

  ngOnInit(): void {
    this.allColumnList = [
      { prop: 'id', name: '#', maxWidth: 50, cellClass: '', cellTemplate: this.rowNumberTemplate, sortable: false, draggable: false },
      { prop: 'xss', name: this.translate.instant('USER_ADMIN.LIST.COL_LBL.NAME'), cellTemplate: this.nameInfoTemplate, sortable: false, draggable: false},
      { prop: 'email', name: this.translate.instant('USER_ADMIN.LIST.COL_LBL.EMAIL'), cellClass: '', draggable: false},
      { prop: 'phoneNumber', name: this.translate.instant('USER_ADMIN.LIST.COL_LBL.PHONE_NUMBER'), cellClass: '', draggable: false},
      { prop: 'patientOrganisationName', name: this.translate.instant('USER_ADMIN.LIST.COL_LBL.PATIENT_ORGANISATION'), cellTemplate: this.patientOrganistionTemplate, draggable: false},
      { prop: 'clinicianOrganisationName', name: this.translate.instant('USER_ADMIN.LIST.COL_LBL.CLINICIAN_ORGANISATION'), cellTemplate: this.clinicianOrganistionTemplate, draggable: false},
      { prop: 'active', name: this.translate.instant('USER_ADMIN.LIST.COL_LBL.ACTIVE'), cellClass: '', cellTemplate: this.activeTemplate, draggable: false},
      { prop: 'action', name: '', maxWidth: 160, cellTemplate: this.actionTemplate, sortable: false, draggable: false}
    ];

    this.columns = [...this.allColumnList];

    this.loadData();
  }

  loadData() {
    this.userAdminService.getUsersByAdmin(this.page.pageNumber, this.page.size, this.sort, this.searchTerm).subscribe(page => {
      this.page.pageNumber = page.number;
      this.page.totalElements = page.totalElements;
      this.page.size = page.size;
      this.rows = page.content;
    });
  }

  getProfilePhotoUrl(userSummary: UserSummaryAdmin): string {
    return userSummary && userSummary.photoUrl ? userSummary.photoUrl : DEFAULT_USER_PHOTO;
  }

  getNameStr(user: UserSummaryAdmin): string {
    return user && user.firstName ? (user.firstName + ' ' + user.lastName) : 'No Name';
  }

  getRolesAsString(roleValue): string {
    if(roleValue) {
      const roles = rolesToStringArray(roleValue);
      return roles.map(r => this.translate.instant('ENUM.ROLE.' + r)).join(', ');
    }
    return 'No Role';
  }

  getStr(str: string) {
    return str ?? '-';
  }

  openDetailDialog(mode: PageMode, user: UserSummaryAdmin): void {
    const dialogConfig = this.createDialogConfig({pageMode: mode, row: user});
    const dialogRef = this.dialog.open(UserDetailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data && data.refreshTable && data.refreshTable === true) {
        this.loadData();
      }
    });
  }

  hasUserPatientRole(user: UserSummaryAdmin): boolean {
    return user.active && userHasRole(Role.PATIENT, user.roles);
  }

  hasUserClinicianRole(user: UserSummaryAdmin): boolean {
    return user.active && hasUserAnyClinicianRelatedRole(user.roles);
  }

  openConnectToClinicianDialog(patient: UserSummaryAdmin) {
    const dialogConfig = this.createDialogConfig({searchInAllUsers: true, role: Role.CLINICIAN, title: 'Select Clician To Connect Patient(' + this.getNameStr(patient) + ')' });
    const dialogRef = this.dialog.open(UserSelectionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data && data.selectedRows) {
        this.userAdminService.connectClinicianPatientByAdmin(data.selectedRows[0].id, patient.id).subscribe(plan => {
          this.notificationService.success(this.translate.instant('USER_ADMIN.DETAIL.NOTIFICATION.USER_EDIT.SUCCESS'));
          this.loadData();
        }, error => {
          console.log('Error on updating user : ' + JSON.stringify(error));
          this.notificationService.error(this.translate.instant('USER_ADMIN.DETAIL.NOTIFICATION.USER_EDIT.FAIL'));
        });
      }
    });
  }


  openConnectToPatientDialog(clinician: UserSummaryAdmin) {
    const dialogConfig = this.createDialogConfig({searchInAllUsers: true, role: Role.PATIENT, title: 'Select Patient To Connect Clinician (' + this.getNameStr(clinician) + ')' });
    const dialogRef = this.dialog.open(UserSelectionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data && data.selectedRows) {
        this.userAdminService.connectClinicianPatientByAdmin(clinician.id, data.selectedRows[0].id).subscribe(plan => {
          this.notificationService.success(this.translate.instant('USER_ADMIN.DETAIL.NOTIFICATION.USER_EDIT.SUCCESS'));
          this.loadData();
        }, error => {
          console.log('Error on updating user : ' + JSON.stringify(error));
          this.notificationService.error(this.translate.instant('USER_ADMIN.DETAIL.NOTIFICATION.USER_EDIT.FAIL'));
        });
      }
    });
  }

  openConnectPatientToOrganisationBranchDialog(user: UserSummaryAdmin) {
    const dialogConfig = this.createDialogConfig({selectedRows: 'single', title: 'Select Organisation Branch To Connect Patient (' + this.getNameStr(user) + ')' });
    const dialogRef = this.dialog.open(OrganisationBranchSelectionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data && data.selectedRows) {
        this.userAdminService.connectPatientToOrganisationBranchByAdmin(user.id, data.selectedRows[0].id).subscribe(userSummary => {
          this.notificationService.success(this.translate.instant('USER_ADMIN.DETAIL.NOTIFICATION.USER_EDIT.SUCCESS'));
          this.loadData();
        }, error => {
          console.log('Error on updating user : ' + JSON.stringify(error));
          this.notificationService.error(this.translate.instant('USER_ADMIN.DETAIL.NOTIFICATION.USER_EDIT.FAIL'));
        });
      }
    });
  }

  openConnectClinicianToOrganisationBranchDialog(user: UserSummaryAdmin) {
    const dialogConfig = this.createDialogConfig({selectedRows: 'single', title: 'Select Organisation Branch To Connect Clinician (' + this.getNameStr(user) + ')' });
    const dialogRef = this.dialog.open(OrganisationBranchSelectionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data && data.selection) {
        this.userAdminService.connectClinicianToOrganisationBranchByAdmin(user.id, data.selection.id).subscribe(userSummary => {
          this.notificationService.success(this.translate.instant('USER_ADMIN.DETAIL.NOTIFICATION.USER_EDIT.SUCCESS'));
          this.loadData();
        }, error => {
          console.log('Error on updating user : ' + JSON.stringify(error));
          this.notificationService.error(this.translate.instant('USER_ADMIN.DETAIL.NOTIFICATION.USER_EDIT.FAIL'));
        });
      }
    });
  }

}
