<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->


<mat-nav-list appAccordion>
    <ng-container *ngFor="let menuitem of menuItems; let i=index;">
        <mat-list-item appAccordionLink routerLinkActive="selected" group="{{menuitem.state}}" (click)="scrollToTop()" >
            <ng-container *ngxPermissionsOnly="menuitem.permissions">
                <a class="" appAccordionToggle [routerLink]="['/', menuitem.state]" *ngIf="menuitem.type === 'link'" (click) = "selectMenuItem(menuitem, i, -1, -1)">
                    <!-- <mat-icon>{{ menuitem.icon }}</mat-icon> -->
                    <i class="bs-home font-size-1-9 w-46"></i>
                    <span>{{ menuitem.name | translate }}</span>
                    <span fxFlex></span>
                    <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
                </a>

                <a class="" appAccordionToggle href="{{menuitem.state}}" *ngIf="menuitem.type === 'extLink'">
                    <mat-icon>{{ menuitem.icon }}</mat-icon>
                    <span>{{ menuitem.name | translate }}</span>
                    <span fxFlex></span>
                    <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
                </a>
                <a class="" appAccordionToggle href="{{menuitem.state}}" target="_blank" *ngIf="menuitem.type === 'extTabLink'">
                    <mat-icon>{{ menuitem.icon }}</mat-icon>
                    <span>{{ menuitem.name | translate}}</span>
                    <span fxFlex></span>
                    <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
                </a>

                <a class="" appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub'">
                    <!-- <mat-icon>{{ menuitem.icon }}</mat-icon> -->
                    <i class="{{getIconName(menuitem.name)}}"></i>
                    <span>{{ menuitem.name | translate }}</span>
                    <span fxFlex></span>
                    <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
                    <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
                </a>
                <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'" >
                    <ng-container *ngFor="let childitem of menuitem.children; let j=index;">
                        <ng-container *ngxPermissionsOnly="childitem.permissions">
                            <mat-list-item  routerLinkActive="selected">
                                <a [routerLink]="['/' +  menuitem.state + '/' + childitem.state ]"  *ngIf="childitem.type === 'link'" class="relative"
                                    routerLinkActive="selected" (click)="selectMenuItem(menuitem, i, j, -1)">{{ childitem.name | translate}}</a>
                            </mat-list-item>
                            <mat-list-item>
                                <a class="" href="javascript: void(0);" *ngIf="childitem.type === 'subchild'" (click)="selectMenuItem(menuitem, i, j, -1)"
                                    [ngClass]="j===childIndex? 'selected' : ''">
                                    <span>{{ childitem.name | translate }}</span>
                                    <span fxFlex></span>
                                    <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
                                </a>
                                <mat-nav-list class="child-sub-item" *ngIf="childitem.type === 'subchild'" >
                                    <mat-list-item *ngFor="let child of childitem.subchildren; let k=index;" routerLinkActive="selected">
                                        <a [routerLink]="['/' + menuitem.state + '/' + childitem.state + '/' + child.state ]"
                                        routerLinkActive="selected" (click) = "selectMenuItem(menuitem, i, j, k)">{{child.name | translate}}</a>
                                    </mat-list-item>
                                </mat-nav-list>
                            </mat-list-item>
                        </ng-container>
                    </ng-container>



                </mat-nav-list>

                <div class="separator text-muted" *ngIf="menuitem.type === 'separator'">
                    <span>{{ menuitem.name | translate }}</span>
                    <span fxFlex></span>
                    <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
                </div>
            </ng-container>


        </mat-list-item>
    </ng-container>


</mat-nav-list>