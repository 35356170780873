import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagerFormComponent } from './pages/manager-form/manager-form.component';
import { ServiceDemandComponent } from './pages/service-demand/service-demand.component';
import { HrPlanningManagementComponent } from './pages/hr-planning-management/hr-planning-management.component';
import { UnaidsComponent } from './pages/unaids/unaids.component';
import { PreventionComponent } from './pages/prevention/prevention.component';
import { FinancalPlanningComponent } from './pages/financal-planning/financal-planning.component';
import { SmarthivManagerRoutingModule } from './smarthiv-manager-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    ManagerFormComponent,
    ServiceDemandComponent,
    HrPlanningManagementComponent,
    UnaidsComponent,
    PreventionComponent,
    FinancalPlanningComponent
  ],
  imports: [
    CommonModule,
    SmarthivManagerRoutingModule,
    SharedModule,
    NgxSpinnerModule
  ]
})
export class SmarthivManagerModule { }
