/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Menu } from '../model/menu-items';

@Injectable({
    providedIn: 'root'
})
export class MenuItemService {

    MENUITEMS = [
        {
            state: 'dashboard',
            name: 'Home',
            type: 'link',
            icon: 'av_timer',
            custImage: 'assets/images/dashboard-page.png'
        },
        {
            state: 'smarthiv-clinician',
            name: 'SmartHIV Clinician',
            type: 'sub',
            icon: 'apps',
            permissions: ['SYSTEM_ADMINISTRATOR', 'SMART_HIV_CLINICIAN'],
            children: [
                { state: 'clinician-form', name: 'Clinician Form ', type: 'link' },
                { state: 'clinical-outcomes', name: 'Clinical Outcomes ', type: 'link' },
                // { state: 'comobidity-management', name: 'Co-mobidity Management ', type: 'link' },
                // { state: 'risk-predection', name: 'Risk Predicition ', type: 'link' },
                { state: 'drug-interaction', name: 'Drug Interaction ', type: 'link' },
                { state: 'adverse-event', name: 'Serious Adverse Events ', type: 'link' }

            ],
            custImage: 'assets/images/smarthiv-clinician.png'
        },
        {
            state: 'smarthiv-manager',
            name: 'SmartHIV Manager',
            type: 'sub',
            icon: 'bubble_chart',
            permissions: ['SYSTEM_ADMINISTRATOR', 'SMART_HIV_MANAGER'],
            children: [
                { state: 'manager-form', name: 'HIV Pathway ', type: 'link' },
                { state: 'service-demand', name: 'Service Demand', type: 'link' },
                { state: 'hr-planning-management', name: 'Human Resource', type: 'link' },
                { state: 'unaids', name: 'UNAIDS 90-90-90 ', type: 'link' },
                { state: 'prevention', name: 'Prevention ', type: 'link' },
                { state: 'financial-planning', name: 'Budgeting', type: 'link' }
            ],
            custImage: 'assets/images/smarthiv-manager.png'
        },
        {
            state: 'smarthiv-trialist',
            name: 'SmartHIV Trialist',
            type: 'sub',
            icon: 'perm_contact_calendar',
            permissions: ['SYSTEM_ADMINISTRATOR', 'SMART_HIV_TRIALIST'],
            children: [
                { state: 'trial-filter', name: 'Trialist Form', type: 'link' },
                { state: 'trial-country', name: 'Country', type: 'link' },
                { state: 'trial-summary', name: 'Summary', type: 'link' },
                { state: 'trial-demographics', name: 'Demographics', type: 'link' },
                { state: 'trial-comorbidity', name: 'Comorbidity', type: 'link' },
                { state: 'trial-budget-calculator', name: 'Budget Calculator', type: 'link' }
            ],
            custImage: 'assets/images/smarthiv-trialist.png'
        },
        {
            state: 'smarthiv-auditlytics',
            name: 'SmartHIV Auditlytics',
            type: 'sub',
            icon: 'insert_drive_file',
            permissions: ['SYSTEM_ADMINISTRATOR', 'SMART_HIV_AUDITLYTICS'],
            children: [
                { state: 'audit-filter', name: 'Auditlytics Form', type: 'link' },
                { state: 'audit-patient', name: 'My Patients', type: 'link' },
                { state: 'audit-comorbidity', name: 'Comorbidities', type: 'link' },
                { state: 'audit-adherence', name: 'Adherence', type: 'link' },
                { state: 'audit-retention', name: 'Retention in Care', type: 'link' },
                { state: 'audit-suppression', name: 'Suppression', type: 'link' },
                { state: 'audit-stigma', name: 'Stigma', type: 'link' },
                { state: 'audit-mental', name: 'Mental Health', type: 'link' },
                { state: 'audit-physical', name: 'Physical Health', type: 'link' },
                { state: 'audit-cognitive', name: 'Cognitive Health', type: 'link' },
                { state: 'audit-sexual', name: 'Sexual Health', type: 'link' }
            ],
            custImage: 'assets/images/hiv-smartlytics.png'
        },
        {
            state: 'subscription',
            name: 'Subscription',
            type: 'sub',
            icon: 'subscriptions',
            children: [
                { state: 'plan', name: 'Plan', type: 'link' }
            ]
        },
        {
            state: 'third-party',
            name: 'Third Party',
            type: 'sub',
            icon: 'backup_table',
            badge: [{ type: 'warning', value: 'new' }],
            permissions: ['SYSTEM_ADMINISTRATOR'],
            children: [
                { state: 'dhis2-main', name: 'DHIS2 Main', type: 'link' },
                { state: 'openmrs-main', name: 'OpenMRS Main', type: 'link' }
            ]
        },
        {
            state: '',
            name: 'Administrator',
            type: 'separator',
            icon: '',
            badge: [{ type: 'red', value: 'new' }],
            permissions: ['SYSTEM_ADMINISTRATOR'],
        },
        {
            state: 'administrator/suppression',
            name: 'Suppression Admin',
            type: 'sub',
            icon: 'local_library',
            permissions: ['SYSTEM_ADMINISTRATOR'],
            children: [
                { state: 'suppression-list', name: 'Suppression List', type: 'link' },
                { state: 'art-code-list', name: 'Art Code List', type: 'link' },
                { state: 'mutation-list', name: 'Mutation List', type: 'link' },
                { state: 'drug-interaction-list', name: 'Drug Interaction List', type: 'link' },
                { state: 'adverse-event-list', name: 'Adverse Event List', type: 'link' }
            ],
        },
        {
            state: 'administrator/survey',
            name: 'Survey Admin',
            type: 'sub',
            icon: 'local_library',
            permissions: ['SYSTEM_ADMINISTRATOR'],
            children: [
                { state: 'feedback-list', name: 'Feedback List', type: 'link' },
                { state: 'survey-result-list', name: 'Survey Result List', type: 'link' },
                { state: 'at-risk-list', name: 'At Risk List', type: 'link' }
            ]
        },
        {
            state: 'administrator/user',
            name: 'User Admin',
            type: 'sub',
            icon: 'local_library',
            permissions: ['SYSTEM_ADMINISTRATOR'],
            children: [
                { state: 'user-list', name: 'User List', type: 'link' },
                { state: 'clinician-patient-list', name: 'ClinicianPatient List', type: 'link' },
                { state: 'kin-patient-list', name: 'Buddy Connections', type: 'link' },
                { state: 'invitation-list', name: 'Invitation List', type: 'link' },
                { state: 'auditlytics-permission-list', name: 'Auditlytics Permissions', type: 'link' }
            ]
        }
        ,
        {
            state: 'administrator/subscription',
            name: 'Subscription Admin',
            type: 'sub',
            icon: 'local_library',
            permissions: ['SYSTEM_ADMINISTRATOR'],
            children: [
                { state: 'plan-list', name: 'Plan List', type: 'link' }
            ]
        },
        {
            state: 'administrator/general',
            name: 'General Admin',
            type: 'sub',
            icon: 'local_library',
            permissions: ['SYSTEM_ADMINISTRATOR'],
            children: [
                { state: 'custom-city-list', name: 'Custom City List', type: 'link' }
            ]
        }
    ];

    constructor(
        private http: HttpClient) {
    }

    public getMenuitems(): Menu[] {
        return this.MENUITEMS;
    }
}
