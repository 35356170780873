import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ChartComponent } from 'ng-apexcharts';
import { ChartOptions, Pair, SimulationResultDemandResponse } from 'src/app/shared/model/simulation-result';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { SimulationService } from 'src/app/shared/services/simulation.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
    selector: 'app-service-demand',
    templateUrl: './service-demand.component.html',
    styleUrls: ['./service-demand.component.scss']
})
export class ServiceDemandComponent implements OnInit {

    @ViewChild('chartPatient')
    chartPatient: ChartComponent = Object.create(null);
    @ViewChild('chartPatientDead')
    chartPatientDead: ChartComponent = Object.create(null);
    @ViewChild('chartPatientDiscontinued')
    chartPatientDiscontinued: ChartComponent = Object.create(null);
    @ViewChild('chartAdherence')
    chartAdherence: ChartComponent = Object.create(null);
    @ViewChild('chartVisitPatient')
    chartVisitPatient: ChartComponent = Object.create(null);
    @ViewChild('chartVisitAge')
    chartVisitAge: ChartComponent = Object.create(null);
    @ViewChild('chartVisitCounselling')
    chartVisitCounselling: ChartComponent = Object.create(null);
    @ViewChild('chartReason')
    chartReason: ChartComponent = Object.create(null);
    @ViewChild('chartArt')
    chartArt: ChartComponent = Object.create(null);
    @ViewChild('chartArtSc')
    chartArtSc: ChartComponent = Object.create(null);

    @ViewChild('chartPiePatientType')
    chartPiePatientType: ChartComponent = Object.create(null);
    @ViewChild('chartPieAdherence')
    chartPieAdherence: ChartComponent = Object.create(null);
    @ViewChild('chartPieAge')
    chartPieAge: ChartComponent = Object.create(null);
    @ViewChild('chartPieInfect')
    chartPieInfect: ChartComponent = Object.create(null);
    @ViewChild('chartPieArt')
    chartPieArt: ChartComponent = Object.create(null);
    @ViewChild('chartPieVisit')
    chartPieVisit: ChartComponent = Object.create(null);

    public chartPatientOptions: Partial<ChartOptions>;
    public chartPatientDeadOptions: Partial<ChartOptions>;
    public chartPatientDiscontinuedOptions: Partial<ChartOptions>;
    public chartAdherenceOptions;
    public chartVisitPatientOptions;
    public chartVisitAgeOptions;
    public chartVisitCounsellingOptions;
    public chartReasonOptions: Partial<ChartOptions>;
    public chartArtOptions;
    public chartArtScOptions;

    public chartPiePatientTypeOptions;
    public chartPieAdherenceOptions;
    public chartPieAgeOptions;
    public chartPieInfectOptions;
    public chartPieArtOptions;
    public chartPieVisitOptions;

    demandNaive = 0;
    demandExperienced = 0;

    demandDead = 0;
    demandExperiencedWODead = 0;

    hiv1 = 0;
    hiv2 = 0;
    hiv3 = 0;
    hiv4 = 0;
    hiv5 = 0;

    art1 = 0;
    art2 = 0;
    art3 = 0;
    art4 = 0;
    art5 = 0;

    children = 0;
    adolescents = 0;
    adults = 0;
    olderAdults = 0;

    visitNaive = 0;
    visitExperienced = 0;

    layout = 'raw';
    layoutSize = '50';

    years;

    constructor(
        private router: Router,
        private storageService: StorageService,
        private notificationService: NotificationService,
        public simulationService: SimulationService,
        private translate: TranslateService
    ) {
    }

    ngOnInit(): void {
        const result = JSON.parse(this.storageService.getSimulationResult());

        if (!result) {
            this.router.navigate(['smarthiv-manager/manager-form']);
            this.notificationService.error(this.translate.instant('SIMULATION.RUN_MODEL_EXCEPTION'));
        }

        this.simulationService.getSimulationResultDemands(result).subscribe(data => {
            this.years = data.demands[0].years;

            if (this.years > 5) {
                this.layout = 'column';
                this.layoutSize = '100';
            }

            const total = data.demands[0].demandExperienced + data.demands[0].demandNaive;
            this.demandExperienced = data.demands[0].demandExperienced;
            this.demandNaive = data.demands[0].demandNaive;

            this.hiv1 = Math.round(total * (data.demands[0].percentageTb / 100));
            this.hiv2 = Math.round(total * (data.demands[0].percentageHepB / 100));
            this.hiv3 = Math.round(total * (data.demands[0].percentageHepC / 100));
            this.hiv4 = Math.round(total * (data.demands[0].percentageSyphillis / 100));
            this.hiv5 = Math.round(total * (data.demands[0].percentageNoInfection / 100));

            this.children = Math.round(total * (data.demands[0].percentageChildren / 100));
            this.adolescents = Math.round(total * (data.demands[0].percentageAdolescent / 100));
            this.adults = Math.round(total * (data.demands[0].percentageAdults / 100));
            this.olderAdults = Math.round(total * (data.demands[0].percentageOlderAdults / 100));

            this.art1 = Math.round(total * (data.demands[0].percentageNnrti / 100));
            this.art2 = Math.round(total * (data.demands[0].percentageNrtiPi2 / 100));
            this.art3 = Math.round(total * (data.demands[0].percentageNrtiIn2 / 100));
            this.art4 = Math.round(total * (data.demands[0].percentageNrtiEi2 / 100));
            this.art5 = Math.round(total * (data.demands[0].percentageNrti2Dr2 / 100));

            this.chartPiePatientTypeOptions = {
                series: [
                    this.demandNaive,
                    this.demandExperienced,
                ],
                chart: {
                    width: 300,
                    type: 'pie'
                },
                colors: ['#008efb', '#00e397'],
                labels: [
                    this.translate.instant('SIMULATION.DEMAND.TREATMENT_NAIVE'),
                    this.translate.instant('SIMULATION.DEMAND.TREATMENT_EXPERIENCED')
                ],
                legend: {
                    position: 'bottom',
                    labels: {
                        colors: ['#008efb', '#00e397'],
                        useSeriesColors: false
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 300
                            },
                            dataLabels: {
                                enabled: false
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }
                ]
            };

            this.chartPieAgeOptions = {
                series: [
                    this.children,
                    this.adolescents,
                    this.adults,
                    this.olderAdults,
                ],
                chart: {
                    width: 300,
                    type: 'pie'
                },
                colors: ['#785dd0', '#008efb', '#00e397', '#feb219'],
                labels: [
                    this.translate.instant('SIMULATION.DEMAND.CHILDREN'),
                    this.translate.instant('SIMULATION.DEMAND.ADOLESCENTS'),
                    this.translate.instant('SIMULATION.DEMAND.ADULT'),
                    this.translate.instant('SIMULATION.DEMAND.OLDER_ADULT')
                ],
                legend: {
                    position: 'bottom',
                    labels: {
                        colors: ['#785dd0', '#008efb', '#00e397', '#feb219'],
                        useSeriesColors: false
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 300
                            },
                            dataLabels: {
                                enabled: false
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }
                ]
            };

            this.chartPieInfectOptions = {
                series: [
                    this.hiv1,
                    this.hiv2,
                    this.hiv3,
                    this.hiv4,
                    this.hiv5
                ],
                chart: {
                    width: 300,
                    type: 'pie'
                },
                colors: ['#785dd0', '#008efb', '#00e397', '#feb219', '#6b0914'],
                labels: [
                    this.translate.instant('SIMULATION.DEMAND.TB'),
                    this.translate.instant('SIMULATION.DEMAND.HBV'),
                    this.translate.instant('SIMULATION.DEMAND.HCV'),
                    this.translate.instant('SIMULATION.DEMAND.SYP'),
                    this.translate.instant('SIMULATION.DEMAND.NO_CO_INFECT')
                ],
                legend: {
                    position: 'bottom',
                    labels: {
                        colors: ['#785dd0', '#008efb', '#00e397', '#feb219', '#6b0914'],
                        useSeriesColors: false
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 300
                            },
                            dataLabels: {
                                enabled: false
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }
                ]
            };

            this.chartPieArtOptions = {
                series: [
                    this.art1,
                    this.art2,
                    this.art3,
                    this.art4,
                    this.art5
                ],
                chart: {
                    width: 300,
                    type: 'pie'
                },
                colors: ['#785dd0', '#008efb', '#00e397', '#feb219', '#6b0914'],
                labels: [
                    this.translate.instant('SIMULATION.SERVICE.NNRTI_2_NRTI'),
                    this.translate.instant('SIMULATION.SERVICE.PI_2_NRTI'),
                    this.translate.instant('SIMULATION.SERVICE.IN_2_NRTI'),
                    this.translate.instant('SIMULATION.SERVICE.EI_2_NRTI'),
                    this.translate.instant('SIMULATION.SERVICE.2DR')
                ],
                legend: {
                    position: 'bottom',
                    labels: {
                        colors: ['#785dd0', '#008efb', '#00e397', '#feb219', '#6b0914'],
                        useSeriesColors: false
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 300
                            },
                            dataLabels: {
                                enabled: false
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }
                ]
            };

            this.setPatientType(this.getAverage(this.getArray(data.demands, 'patient', true)),
                this.getAverage(this.getArray(data.demands, 'dead', true)),
                this.getAverage(this.getArray(data.demands, 'discontinued', true)),
                this.getAverage(this.getArray(data.demands, 'patient', false)),
                this.getAverage(this.getArray(data.demands, 'dead', false)),
                this.getAverage(this.getArray(data.demands, 'discontinued', false)));

            this.setMonitoring(this.getAverage(this.getArray(data.demands, 'patient_type', true)),
                this.getAverage(this.getArray(data.demands, 'age', true)),
                this.getAverage(this.getArray(data.demands, 'patient_type', false)),
                this.getAverage(this.getArray(data.demands, 'age', false)));

            this.setCounselling(this.getAverage(this.getArray(data.demands, 'counsell', true)),
                this.getAverage(this.getArray(data.demands, 'counsell', false)));

            this.setArt(this.getAverage(this.getArray(data.demands, 'art', true)),
                this.getAverage(this.getArray(data.demands, 'art', false)));

            this.chartPieAdherenceOptions = {
                series: [
                    this.demandExperiencedWODead,
                    this.demandDead,
                ],
                chart: {
                    width: 300,
                    type: 'pie'
                },
                colors: ['#008efb', '#00e397'],
                labels: [
                    this.translate.instant('SIMULATION.DEMAND.ADHERENCE'),
                    this.translate.instant('SIMULATION.DEMAND.NON_ADHERENCE')
                ],
                legend: {
                    position: 'bottom',
                    labels: {
                        colors: ['#008efb', '#00e397'],
                        useSeriesColors: false
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 300
                            },
                            dataLabels: {
                                enabled: false
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }
                ]
            };

            this.chartPieVisitOptions = {
                series: [
                    this.visitNaive,
                    this.visitExperienced
                ],
                chart: {
                    width: 300,
                    type: 'pie'
                },
                colors: ['#008efb', '#00e397'],
                labels: [
                    this.translate.instant('SIMULATION.NAIVE'),
                    this.translate.instant('SIMULATION.TX_EXP')
                ],
                legend: {
                    position: 'bottom',
                    labels: {
                        colors: ['#008efb', '#00e397'],
                        useSeriesColors: false
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 300
                            },
                            dataLabels: {
                                enabled: false
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }
                ]
            };

        }, (err) => {
            console.log('Error on get simulation. err : ' + err);
            this.notificationService.error('Error on get simulation');
        });
    }

    getArray(mapArray: Array<SimulationResultDemandResponse>, type: string, baseline: boolean): Map<string, Array<Pair>>[] {
        const returnArrayMap: Map<string, Array<Pair>>[] = [];
        const arraySize = Math.floor(mapArray.length / 2);
        let startIndex = 0;
        let finishIndex = arraySize;
        if (!baseline) {
            startIndex = startIndex + arraySize;
            finishIndex = finishIndex + arraySize;
        }
        for (let i = startIndex; i < finishIndex; i++) {
            if (type === 'patient_type') {
                returnArrayMap.push(mapArray[i].visitMapByPatientType);
            } else if (type === 'art_ex') {
                returnArrayMap.push(mapArray[i].visitsMapByArt);
            } else if (type === 'art') {
                returnArrayMap.push(mapArray[i].yearlyNetArtMap);
            } else if (type === 'counsell') {
                returnArrayMap.push(mapArray[i].visitsCounsellingMapByType);
            } else if (type === 'age') {
                returnArrayMap.push(mapArray[i].visitMapByAgeGroup);
            } else if (type === 'dead') {
                returnArrayMap.push(mapArray[i].yearlyDeadMap);
            } else if (type === 'patient_ex') {
                returnArrayMap.push(mapArray[i].yearlyPatientMap);
            } else if (type === 'patient') {
                returnArrayMap.push(mapArray[i].yearlyNetPatientMap);
            } else if (type === 'discontinued') {
                returnArrayMap.push(mapArray[i].yearlyDiscontinuedMap);
            }
        }
        return returnArrayMap;
    }

    getAverage(mapArray: Map<string, Array<Pair>>[]): Map<string, Array<Pair>> {
        const averageMap: Map<string, Array<Pair>> = new Map<string, Array<Pair>>();
        const mapSize = mapArray.length;
        mapArray.forEach(map => {
            const ttt = new Map(Object.entries(map));
            for (const key of ttt.keys()) {
                if (averageMap.get(key)) {
                    const averagePairs: Pair[] = averageMap.get(key);
                    const newPairs: Pair[] = [];
                    const pairs: Pair[] = ttt.get(key);
                    for (let i = 0; i < pairs.length; i++) {
                        const pair = pairs[i];
                        const averagePair = averagePairs[i];
                        const second = averagePair.second + pair.second / mapSize;
                        newPairs.push({ first: pair.first, second: parseFloat(second.toFixed(2)) });
                    }
                    averageMap.set(key, newPairs);
                } else {
                    const newPairs: Pair[] = [];
                    const pairs: Pair[] = ttt.get(key);
                    pairs.forEach(pair => {
                        newPairs.push({ first: pair.first, second: parseFloat((pair.second / mapSize).toFixed(2)) });
                    });
                    averageMap.set(key, newPairs);
                }
            }
        });
        return averageMap;
    }

    setPatientType(patientsMap: Map<string, Array<Pair>>,
        yearlyDeadMap: Map<string, Array<Pair>>,
        yearlyDiscontinuedMap: Map<string, Array<Pair>>,
        patientsScMap: Map<string, Array<Pair>>,
        yearlyScDeadMap: Map<string, Array<Pair>>,
        yearlyScDiscontinuedMap: Map<string, Array<Pair>>) {
        const years: string[][] = [];
        const yearsOnly: string[] = [];
        const naiveData: number[] = [];
        const preventionData: number[] = [];
        const experiencedData: number[] = [];
        const deadData: number[] = [];
        const deadDataSc: number[] = [];
        const discontinuedData: number[] = [];
        const discontinuedDataSc: number[] = [];
        const nonAdherenceData: number[] = [];
        const nonAdherenceDataSc: number[] = [];

        for (const key of patientsMap.keys()) {
            years.push([key, this.translate.instant('SIMULATION.CP')]);
            years.push([key, this.translate.instant('SIMULATION.SC')]);
            yearsOnly.push(key);

            const patientPairs: Pair[] = patientsMap.get(key);
            const deadPairs: Pair[] = yearlyDeadMap.get(key);
            const discontinuedPairs: Pair[] = yearlyDiscontinuedMap.get(key);

            const patientScPairs: Pair[] = patientsScMap.get(key);
            const deadScPairs: Pair[] = yearlyScDeadMap.get(key);
            const discontinuedScPairs: Pair[] = yearlyScDiscontinuedMap.get(key);

            let naive = 0;
            let prevention = 0;
            let experienced = 0;
            let naiveSc = 0;
            let preventionSc = 0;
            let experiencedSc = 0;

            let nonAdherence = 0;
            let nonAdherenceSc = 0;

            patientPairs.forEach(element => {
                if (element.first === 'NAIVE'
                    || element.first === 'NAIVE_IAC'
                    || element.first === 'NAIVE_TL'
                    || element.first === 'NAIVE_PREGNANT') {
                    naive = naive + element.second;
                } else if (element.first === 'PREP'
                    || element.first === 'PEP') {
                    prevention = prevention + element.second;
                } else if (element.first === 'TX_EXPERIENCED'
                    || (element.first === 'TX_EXPERIENCED_TL')
                    || (element.first === 'TX_EXPERIENCED_PREGNANT')
                    || (element.first === 'TX_EXPERIENCED_IAC')) {
                    experienced = experienced + element.second;
                }

                if ((element.first === 'TX_EXPERIENCED_IAC')
                    || (element.first === 'NAIVE_IAC')) {
                        nonAdherence = nonAdherence + element.second;
                }
            });
            nonAdherenceData.push(parseFloat(nonAdherence.toFixed(0)));

            patientScPairs.forEach(element => {
                if (element.first === 'NAIVE'
                    || element.first === 'NAIVE_IAC'
                    || element.first === 'NAIVE_TL'
                    || element.first === 'NAIVE_PREGNANT') {
                    naiveSc = naiveSc + element.second;
                } else if (element.first === 'PREP'
                    || element.first === 'PEP') {
                    preventionSc = preventionSc + element.second;
                } else if (element.first === 'TX_EXPERIENCED'
                    || (element.first === 'TX_EXPERIENCED_TL')
                    || (element.first === 'TX_EXPERIENCED_PREGNANT')
                    || (element.first === 'TX_EXPERIENCED_IAC')) {
                    experiencedSc = experiencedSc + element.second;
                }

                if ((element.first === 'TX_EXPERIENCED_IAC')
                    || (element.first === 'NAIVE_IAC')) {
                        nonAdherenceSc = nonAdherenceSc + element.second;
                }
            });
            nonAdherenceDataSc.push(parseFloat(nonAdherenceSc.toFixed(0)));

            naiveData.push(parseFloat(naive.toFixed(0)));
            naiveData.push(parseFloat(naiveSc.toFixed(0)));
            preventionData.push(parseFloat(prevention.toFixed(0)));
            preventionData.push(parseFloat(preventionSc.toFixed(0)));
            experiencedData.push(parseFloat(experienced.toFixed(0)));
            experiencedData.push(parseFloat(experiencedSc.toFixed(0)));

            let dead = 0;
            deadPairs.forEach(element => {
                if (element.first === 'NAIVE'
                    || element.first === 'NAIVE_IAC'
                    || element.first === 'NAIVE_TL'
                    || element.first === 'NAIVE_PREGNANT'
                    || element.first === 'TX_EXPERIENCED'
                    || (element.first === 'TX_EXPERIENCED_TL')
                    || (element.first === 'TX_EXPERIENCED_PREGNANT')
                    || (element.first === 'TX_EXPERIENCED_IAC')) {
                        dead = dead + element.second;
                    }
            });
            deadData.push(parseFloat(dead.toFixed(0)));

            let deadSc = 0;
            deadScPairs.forEach(element => {
                if (element.first === 'NAIVE'
                    || element.first === 'NAIVE_IAC'
                    || element.first === 'NAIVE_TL'
                    || element.first === 'NAIVE_PREGNANT'
                    || element.first === 'TX_EXPERIENCED'
                    || (element.first === 'TX_EXPERIENCED_TL')
                    || (element.first === 'TX_EXPERIENCED_PREGNANT')
                    || (element.first === 'TX_EXPERIENCED_IAC')) {
                        deadSc = deadSc + element.second;
                    }
            });
            deadDataSc.push(parseFloat(deadSc.toFixed(0)));

            let discontinued = 0;
            discontinuedPairs.forEach(element => {
                if (element.first === 'NAIVE'
                    || element.first === 'NAIVE_IAC'
                    || element.first === 'NAIVE_TL'
                    || element.first === 'NAIVE_PREGNANT'
                    || element.first === 'TX_EXPERIENCED'
                    || (element.first === 'TX_EXPERIENCED_TL')
                    || (element.first === 'TX_EXPERIENCED_PREGNANT')
                    || (element.first === 'TX_EXPERIENCED_IAC')) {
                        discontinued = discontinued + element.second;
                    }
            });
            discontinuedData.push(parseFloat(discontinued.toFixed(0)));

            let discontinuedSc = 0;
            discontinuedScPairs.forEach(element => {
                if (element.first === 'NAIVE'
                    || element.first === 'NAIVE_IAC'
                    || element.first === 'NAIVE_TL'
                    || element.first === 'NAIVE_PREGNANT'
                    || element.first === 'TX_EXPERIENCED'
                    || (element.first === 'TX_EXPERIENCED_TL')
                    || (element.first === 'TX_EXPERIENCED_PREGNANT')
                    || (element.first === 'TX_EXPERIENCED_IAC')) {
                        discontinuedSc = discontinuedSc + element.second;
                    }
            });
            discontinuedDataSc.push(parseFloat(discontinuedSc.toFixed(0)));

        }

        this.chartPatientOptions = {
            series: [
                {
                    name: this.translate.instant('SIMULATION.NAIVE'),
                    type: 'column',
                    data: naiveData
                },
                {
                    name: this.translate.instant('SIMULATION.TX_EXP'),
                    type: 'column',
                    data: experiencedData
                }
            ],
            xaxis: {
                categories: years
            },
            yaxis: {
                title: {
                    text: this.translate.instant('SIMULATION.PREVENTION.NO_PATIENTS')
                }
            },
            chart: {
                type: 'bar',
                fontFamily: 'Poppins,sans-serif',
                stacked: true,
                height: 347
            },
            grid: {
                borderColor: 'rgba(0,0,0,.2)',
                strokeDashArray: 3,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            legend: {
                position: 'bottom',
                offsetX: 0
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                theme: 'light',
                fillSeriesColor: false,
                marker: {
                    show: false,
                },
            }
        };

        this.chartPatientDeadOptions = {
            series: [
                {
                    name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
                    data: deadData
                },
                {
                    name: this.translate.instant('SIMULATION.SCENARIO'),
                    data: deadDataSc
                }
            ],
            chart: {
                height: 350,
                type: 'line',
                dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                },
                toolbar: {
                    show: true
                }
            },
            grid: {
                borderColor: 'rgba(0,0,0,.2)',
                strokeDashArray: 3,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            dataLabels: {
                enabled: true
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: yearsOnly
            },
            yaxis: {
                title: {
                    text: this.translate.instant('SIMULATION.DEMAND.NO_DEAD')
                }
            },
            legend: {
                position: 'bottom',
                offsetX: 0
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                theme: 'light',
                fillSeriesColor: false,
                marker: {
                    show: false,
                },
            }
        };

        this.chartPatientDiscontinuedOptions = {
            series: [
                {
                    name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
                    data: discontinuedData
                },
                {
                    name: this.translate.instant('SIMULATION.SCENARIO'),
                    data: discontinuedDataSc
                }
            ],
            chart: {
                height: 350,
                type: 'line',
                dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                },
                toolbar: {
                    show: true
                }
            },
            grid: {
                borderColor: 'rgba(0,0,0,.2)',
                strokeDashArray: 3,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            dataLabels: {
                enabled: true
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: yearsOnly
            },
            yaxis: {
                title: {
                    text: this.translate.instant('SIMULATION.DEMAND.NO_DISCONTINUED')
                }
            },
            legend: {
                position: 'bottom',
                offsetX: 0
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                theme: 'light',
                fillSeriesColor: false,
                marker: {
                    show: false,
                },
            }
        };

        this.demandExperiencedWODead = experiencedData[0];
        this.demandDead = nonAdherenceData[0];

        this.chartAdherenceOptions = {
            series: [
                {
                    name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
                    data: nonAdherenceData
                },
                {
                    name: this.translate.instant('SIMULATION.SCENARIO'),
                    data: nonAdherenceDataSc
                }
            ],
            chart: {
                height: 350,
                type: 'line',
                dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                },
                toolbar: {
                    show: true
                }
            },
            grid: {
                borderColor: 'rgba(0,0,0,.2)',
                strokeDashArray: 3,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            dataLabels: {
                enabled: true
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: yearsOnly
            },
            yaxis: {
                title: {
                    text: this.translate.instant('SIMULATION.PREVENTION.NO_PATIENTS')
                }
            },
            legend: {
                position: 'bottom',
                offsetX: 0
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                theme: 'light',
                fillSeriesColor: false,
                marker: {
                    show: false,
                },
            }
        };
    }

    setMonitoring(patientsMap: Map<string, Array<Pair>>,
        agesMap: Map<string, Array<Pair>>,
        patientsScMap: Map<string, Array<Pair>>,
        agesScMap: Map<string, Array<Pair>>) {
        const years: string[][] = [];

        const naiveData: number[] = [];
        const preventionData: number[] = [];
        const experiencedData: number[] = [];

        const childrenData: number[] = [];
        const adolescentsData: number[] = [];
        const adultData: number[] = [];
        const olderAdultsData: number[] = [];

        for (const key of patientsMap.keys()) {
            years.push([key, this.translate.instant('SIMULATION.CP')]);
            years.push([key, this.translate.instant('SIMULATION.SC')]);
            const patientPairs: Pair[] = patientsMap.get(key);
            const patientScPairs: Pair[] = patientsScMap.get(key);

            let naive = 0;
            let prevention = 0;
            let experienced = 0;

            let naiveSc = 0;
            let preventionSc = 0;
            let experiencedSc = 0;

            patientPairs.forEach(element => {
                if (element.first === 'NAIVE') {
                    naive = naive + element.second;
                } else if (element.first === 'PREVENTION') {
                    prevention = prevention + element.second;
                } else if (element.first === 'TX_EXPERIENCED') {
                    experienced = experienced + element.second;
                }
            });
            patientScPairs.forEach(element => {
                if (element.first === 'NAIVE') {
                    naiveSc = naiveSc + element.second;
                } else if (element.first === 'PREVENTION') {
                    preventionSc = preventionSc + element.second;
                } else if (element.first === 'TX_EXPERIENCED') {
                    experiencedSc = experiencedSc + element.second;
                }
            });
            naiveData.push(Math.round(naive));
            naiveData.push(Math.round(naiveSc));
            preventionData.push(Math.round(prevention));
            preventionData.push(Math.round(preventionSc));
            experiencedData.push(Math.round(experienced));
            experiencedData.push(Math.round(experiencedSc));

            const agePairs: Pair[] = agesMap.get(key);
            const ageScPairs: Pair[] = agesScMap.get(key);
            agePairs.forEach(element => {
                if (element.first === 'CHILDREN') {
                    childrenData.push(Math.round(element.second));
                } else if (element.first === 'ADOLESCENT') {
                    adolescentsData.push(Math.round(element.second));
                } else if (element.first === 'ADULT') {
                    adultData.push(Math.round(element.second));
                } else if (element.first === 'OLDER_ADULTS') {
                    olderAdultsData.push(Math.round(element.second));
                }
            });
            ageScPairs.forEach(element => {
                if (element.first === 'CHILDREN') {
                    childrenData.push(Math.round(element.second));
                } else if (element.first === 'ADOLESCENT') {
                    adolescentsData.push(Math.round(element.second));
                } else if (element.first === 'ADULT') {
                    adultData.push(Math.round(element.second));
                } else if (element.first === 'OLDER_ADULTS') {
                    olderAdultsData.push(Math.round(element.second));
                }
            });
        }

        this.visitNaive = naiveData[0];
        this.visitExperienced = experiencedData[0];

        this.chartVisitPatientOptions = {
            series: [
                {
                    name: this.translate.instant('SIMULATION.NAIVE'),
                    type: 'column',
                    data: naiveData
                },
                {
                    name: this.translate.instant('SIMULATION.PREP_PEP'),
                    type: 'column',
                    data: preventionData
                },
                {
                    name: this.translate.instant('SIMULATION.TX_EXP'),
                    type: 'column',
                    data: experiencedData
                }
            ],
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    borderRadius: 8,
                    horizontal: false,
                },
            },
            xaxis: {
                categories: years
            },
            yaxis: {
                title: {
                    text: this.translate.instant('SIMULATION.DEMAND.NO_VISITS')
                }
            },
            legend: {
                position: 'bottom',
                offsetX: 0
            },
            fill: {
                opacity: 1
            }
        };

        this.chartVisitAgeOptions = {
            series: [
                {
                    name: this.translate.instant('SIMULATION.DEMAND.CHILDREN'),
                    type: 'column',
                    data: childrenData
                },
                {
                    name: this.translate.instant('SIMULATION.DEMAND.ADOLESCENTS'),
                    type: 'column',
                    data: adolescentsData
                },
                {
                    name: this.translate.instant('SIMULATION.DEMAND.ADULT'),
                    type: 'column',
                    data: adultData
                },
                {
                    name: this.translate.instant('SIMULATION.DEMAND.OLDER_ADULT'),
                    type: 'column',
                    data: olderAdultsData
                }
            ],
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    borderRadius: 8,
                    horizontal: false,
                },
            },
            xaxis: {
                categories: years
            },
            yaxis: {
                title: {
                    text: this.translate.instant('SIMULATION.DEMAND.NO_VISITS')
                }
            },
            legend: {
                position: 'bottom',
                offsetX: 0
            },
            fill: {
                opacity: 1
            }
        };

    }

    setCounselling(patientsMap: Map<string, Array<Pair>>,
        patientsScMap: Map<string, Array<Pair>>) {
        const years: string[][] = [];

        const firstData: number[] = [];
        const iacData: number[] = [];
        const groupData: number[] = [];

        for (const key of patientsMap.keys()) {
            years.push([key, this.translate.instant('SIMULATION.CP')]);
            years.push([key, this.translate.instant('SIMULATION.SC')]);
            const patientPairs: Pair[] = patientsMap.get(key);
            const patientScPairs: Pair[] = patientsScMap.get(key);

            patientPairs.forEach(element => {
                if (element.first === 'FIRST_COUNSELLING') {
                    firstData.push(Math.round(element.second));
                } else if (element.first === 'GROUP') {
                    groupData.push(Math.round(element.second));
                } else if (element.first === 'IAC') {
                    iacData.push(Math.round(element.second));
                }
            });
            patientScPairs.forEach(element => {
                if (element.first === 'FIRST_COUNSELLING') {
                    firstData.push(Math.round(element.second));
                } else if (element.first === 'GROUP') {
                    groupData.push(Math.round(element.second));
                } else if (element.first === 'IAC') {
                    iacData.push(Math.round(element.second));
                }
            });
        }

        this.chartVisitCounsellingOptions = {
            series: [
                {
                    name: this.translate.instant('SIMULATION.FIRST'),
                    type: 'column',
                    data: firstData
                },
                {
                    name: this.translate.instant('SIMULATION.IAC'),
                    type: 'column',
                    data: iacData
                },
                {
                    name: this.translate.instant('SIMULATION.GROUP'),
                    type: 'column',
                    data: groupData
                }
            ],
            chart: {
                type: 'bar',
                fontFamily: 'Poppins,sans-serif',
                stacked: true,
                height: 347
            },
            grid: {
                borderColor: 'rgba(0,0,0,.2)',
                strokeDashArray: 3,
            },
            plotOptions: {
                bar: {
                    borderRadius: 8,
                    horizontal: false,
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: years
            },
            yaxis: {
                title: {
                    text: this.translate.instant('SIMULATION.DEMAND.NO_SESSIONS')
                }
            },
            legend: {
                position: 'bottom',
                offsetX: 0
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                theme: 'light',
                fillSeriesColor: false,
                marker: {
                    show: false,
                },
            }
        };

    }

    setArt(patientsMap: Map<string, Array<Pair>>,
        patientsScMap: Map<string, Array<Pair>>) {

        const years: string[] = [];

        const dr2Data: number[] = [];
        const dr2DataSc: number[] = [];
        const ei2Data: number[] = [];
        const ei2DataSc: number[] = [];
        const in2Data: number[] = [];
        const in2DataSc: number[] = [];
        const nrti2Data: number[] = [];
        const nrti2DataSc: number[] = [];
        const pi2Data: number[] = [];
        const pi2DataSc: number[] = [];

        for (const key of patientsMap.keys()) {
            years.push(key);

            const patientPairs: Pair[] = patientsMap.get(key);
            const patientScPairs: Pair[] = patientsScMap.get(key);

            patientPairs.forEach(element => {
                if (element.first === 'DR2') {
                    dr2Data.push(Math.round(element.second));
                } else if (element.first === 'EI2_NRTI') {
                    ei2Data.push(Math.round(element.second));
                } else if (element.first === 'IN2_NRTI') {
                    in2Data.push(Math.round(element.second));
                } else if (element.first === 'NRTI2_NRTI') {
                    nrti2Data.push(Math.round(element.second));
                } else if (element.first === 'PI2_NRTI') {
                    pi2Data.push(Math.round(element.second));
                }
            });
            patientScPairs.forEach(element => {
                if (element.first === 'DR2') {
                    dr2DataSc.push(Math.round(element.second));
                } else if (element.first === 'EI2_NRTI') {
                    ei2DataSc.push(Math.round(element.second));
                } else if (element.first === 'IN2_NRTI') {
                    in2DataSc.push(Math.round(element.second));
                } else if (element.first === 'NRTI2_NRTI') {
                    nrti2DataSc.push(Math.round(element.second));
                } else if (element.first === 'PI2_NRTI') {
                    pi2DataSc.push(Math.round(element.second));
                }
            });
        }

        this.chartArtOptions = {
            series: [
                {
                    name: this.translate.instant('SIMULATION.SERVICE.NNRTI_2_NRTI'),
                    type: 'column',
                    data: nrti2Data
                },
                {
                    name: this.translate.instant('SIMULATION.SERVICE.PI_2_NRTI'),
                    type: 'column',
                    data: pi2Data
                },
                {
                    name: this.translate.instant('SIMULATION.SERVICE.IN_2_NRTI'),
                    type: 'column',
                    data: in2Data
                },
                {
                    name: this.translate.instant('SIMULATION.SERVICE.EI_2_NRTI'),
                    type: 'column',
                    data: ei2Data
                },
                {
                    name: this.translate.instant('SIMULATION.SERVICE.2DR'),
                    type: 'column',
                    data: dr2Data
                }
            ],
            xaxis: {
                categories: years
            },
            yaxis: {
                title: {
                    text: this.translate.instant('SIMULATION.DEMAND.NO_CLASSES')
                }
            },
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    borderRadius: 8,
                    horizontal: false,
                },
            },
            legend: {
                position: 'bottom',
                offsetX: 0
            },
            fill: {
                opacity: 1
            }
        };

        this.chartArtScOptions = {
            series: [
                {
                    name: this.translate.instant('SIMULATION.SERVICE.NNRTI_2_NRTI'),
                    type: 'column',
                    data: nrti2DataSc
                },
                {
                    name: this.translate.instant('SIMULATION.SERVICE.PI_2_NRTI'),
                    type: 'column',
                    data: pi2DataSc
                },
                {
                    name: this.translate.instant('SIMULATION.SERVICE.IN_2_NRTI'),
                    type: 'column',
                    data: in2DataSc
                },
                {
                    name: this.translate.instant('SIMULATION.SERVICE.EI_2_NRTI'),
                    type: 'column',
                    data: ei2DataSc
                },
                {
                    name: this.translate.instant('SIMULATION.SERVICE.2DR'),
                    type: 'column',
                    data: dr2DataSc
                }
            ],
            xaxis: {
                categories: years
            },
            yaxis: {
                title: {
                    text: this.translate.instant('SIMULATION.DEMAND.NO_CLASSES')
                }
            },
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    borderRadius: 8,
                    horizontal: false,
                },
            },
            legend: {
                position: 'bottom',
                offsetX: 0
            },
            fill: {
                opacity: 1
            }
        };
    }

    /** Whether the button toggle group contains the id as an active value. */
    isSticky(buttonToggleGroup: MatButtonToggleGroup, id: string) {
        return (buttonToggleGroup.value || []).indexOf(id) !== -1;
    }

}
