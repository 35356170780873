import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexGrid,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexMarkers,
  ApexTitleSubtitle,
  ApexAnnotations
} from 'ng-apexcharts';

export class ApexChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  grid: ApexGrid;

  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  colors: string[];
}

export class ApexTimeLineChartOptions extends ApexChartOptions {
  annotations: ApexAnnotations;
  labels: string[];
}

export type ApexNonAxisChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  stroke: ApexStroke;
};

export const CHART_COLORS_SCHEME1 = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)'
];

export const CHART_COLORS_SCHEME2 = [
  '#008FFB',
  '#00E396',
  '#FEB019',
  '#FF4560',
  '#775DD0',
  '#546E7A',
  '#26a69a',
  '#D10CE8'
];

export const CHART_COLORS_SCHEME3 = [
  '#6633CC',
  '#CC33CC',
  '#CC99FF',
  '#FFCCFF',
  '#3333FF',
  '#6699CC',
];

export const CHART_COLORS_SCHEME4 = [
  '#28E0CE',
  '#5234E0',
  '#3AE01D',
  '#E06832',
  '#E0CD38'
];

export const CHART_COLORS_SCHEME5 = [
  '#ff69b4',
  '#ff00ff',
  '#708090',
  '#00ffff',
  '#c0c0c0',
];




