import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comobidity-management',
  templateUrl: './comobidity-management.component.html',
  styleUrls: ['./comobidity-management.component.scss']
})
export class ComobidityManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
