import { UserSummary } from './user-account';

export class Invitation {
  id: number;
  invitorNote: string;
  inviteeNote: string;
  type: string;
  status: string;

  invitationTime: string;
  responseTime: string;

  unregisteredInviteeEmail: string;
  unregisteredInviteePhone: string;

  invitor: UserSummary;
  invitee: UserSummary;

  organisationBranchId: number;
  clinicianIdList: number[];
}

export class InvitationRequest {
  invitorNote: string;
  type: string;
  organisationBranchId: number;
  clinicianIdList: number[];
}

export class InvitationConfirmRequest {
  inviteeNote: string;
  status: string;
}
