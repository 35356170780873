/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import { DEFAULT_USER_PHOTO } from 'src/app/shared/constants';
import { AuditlyticsPermission } from 'src/app/shared/model/auditlytics-permission';
import { PageMode } from 'src/app/shared/model/enum/page-mode';
import { UserSummaryAdmin } from 'src/app/shared/model/user-account';
import { LoginResponse } from 'src/app/shared/model/user-profile';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { AuditlyticsPermissionService } from 'src/app/shared/services/auditlytics-permission.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';
import { OrganisationAuditlyticsPermissionDetailComponent } from '../../component/organisation-auditlytics-permission-detail/organisation-auditlytics-permission-detail.component';

@Component({
  selector: 'app-organisation-auditlytics-permission-list',
  templateUrl: './organisation-auditlytics-permission-list.component.html',
  styleUrls: ['./organisation-auditlytics-permission-list.component.scss']
})
export class OrganisationAuditlyticsPermissionListComponent extends BaseListComponent implements OnInit {
  @ViewChild('rowNumberTemplate', { static: true }) rowNumberTemplate: TemplateRef<any>;
  @ViewChild('nameInfoTemplate', { static: true }) nameInfoTemplate: TemplateRef<any>;
  @ViewChild('permissionLevelTemplate', { static: true }) permissionLevelTemplate: TemplateRef<any>;
  @ViewChild('countryStateCityTemplate', { static: true }) countryStateCityTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate', { static: true }) actionTemplate: TemplateRef<any>;

  PageMode: typeof PageMode = PageMode;

  pageMode: PageMode;
  loginInfo: LoginResponse;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private auditlyticsPermissionService: AuditlyticsPermissionService,
    private authenticationStateService: AuthenticationStateService
  ) {
    super();
  }

  ngOnInit(): void {

    this.allColumnList = [
      { prop: 'id', name: '#', maxWidth: 50, cellClass: '', cellTemplate: this.rowNumberTemplate, sortable: false, draggable: false },
      { prop: 'xss', name: this.translate.instant('AUDITLYTICS_PERMISSION.LIST.COL_LBL.USER'), cellTemplate: this.nameInfoTemplate, sortable: false, draggable: false },
      { prop: 'permissionLevel', name: this.translate.instant('AUDITLYTICS_PERMISSION.LIST.COL_LBL.PERMISSION_LEVEL'), cellClass: 'w-250', headerClass: 'w-250', cellTemplate: this.permissionLevelTemplate, draggable: false },
      { prop: 'continent', name: this.translate.instant('AUDITLYTICS_PERMISSION.LIST.COL_LBL.CONTINENT'), cellClass: '', draggable: false },
      { prop: 'countryStateCity', name: this.translate.instant('AUDITLYTICS_PERMISSION.LIST.COL_LBL.COUNTRY_STATE_CITY'), cellClass: '', cellTemplate: this.countryStateCityTemplate, draggable: false },
      { prop: 'organisationBranchIds', name: this.translate.instant('AUDITLYTICS_PERMISSION.LIST.COL_LBL.ORGANISATION_BRANCH_ID'), cellClass: '', draggable: false },
      { prop: 'action', name: '', maxWidth: 160, cellTemplate: this.actionTemplate, sortable: false, draggable: false }
    ];
    this.columns = [...this.allColumnList];

    this.loginInfo = this.authenticationStateService.getLoginInfo();

    if (this.loginInfo.clinicianOrganisationId && this.loginInfo.clinicianOrganisationId > 0) {
      this.pageMode = this.loginInfo.organisationOwner === true ? PageMode.EDIT : PageMode.VIEW;
      if (this.loginInfo.organisationOwner === true) {
        this.loadData();
      }
    }
  }

  loadData() {
    this.auditlyticsPermissionService.listAuditlyticsPermissionsByOrgManager(this.page.pageNumber, this.page.size, this.sort, this.searchTerm).subscribe(page => {
      this.page.pageNumber = page.number;
      this.page.totalElements = page.totalElements;
      this.page.size = page.size;
      this.rows = page.content;
    });
  }

  getProfilePhotoUrl(userSummary: UserSummaryAdmin): string {
    return userSummary && userSummary.photoUrl ? userSummary.photoUrl : DEFAULT_USER_PHOTO;
  }

  getNameStr(user: UserSummaryAdmin): string {
    return user && user.firstName ? (user.firstName + ' ' + user.lastName) : 'No Name';
  }

  openDetailDialog(mode: PageMode, auditlyticsPermission: AuditlyticsPermission): void {
    const dialogConfig = this.createDialogConfig({ pageMode: mode, row: auditlyticsPermission });
    const dialogRef = this.dialog.open(OrganisationAuditlyticsPermissionDetailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data && data.refreshTable && data.refreshTable === true) {
        this.loadData();
      }
    });
  }

}
