<div class="p-24">
    <div>
        <div class="mb-16">
          <mat-card-title>{{ 'ORGANISATION.DETAIL.DETAIL_TITLE' | translate }}</mat-card-title>
        </div>

        <!-- <mat-card-content> -->
          <div class="m-b-15">

            <form [formGroup]="formGroupOrganisation" autocomplete="off" novalidate>
              <div class="pb-1 cust-form-field-container">
                <mat-form-field appearance="outline" class="cust-form-field">
                  <mat-label>{{ 'ORGANISATION.DETAIL.NAME' | translate }}</mat-label>
                  <input matInput formControlName="name">
                </mat-form-field>
                <app-validation-message [control]="formGroupOrganisation.get('name')"></app-validation-message>
              </div>

              <!--<div class="pb-1 cust-form-field-container">
                <mat-form-field appearance="outline" class="cust-form-field">
                  <mat-label>{{ 'ORGANISATION.DETAIL.TITLE' | translate }}</mat-label>
                  <input matInput formControlName="title">
                </mat-form-field>
                <app-validation-message [control]="formGroupOrganisation.get('title')"></app-validation-message>
              </div>-->

              <div class="pb-1 cust-form-field-container" fxLayout="row" fxLayoutWrap="wrap">
                <!-- column -->
                <div fxFlex.gt-sm="50" fxFlex="50" class="mr-24">
                    <mat-form-field appearance="outline" class="cust-form-field">
                        <mat-label>{{ 'ORGANISATION.DETAIL.SURVEY_REMINDER_INTERVAL_COUNT' | translate }}</mat-label>
                        <input matInput formControlName="surveyReminderIntervalCount" type="number">
                    </mat-form-field>
                    <app-validation-message [control]="formGroupOrganisation.get('surveyReminderIntervalCount')"></app-validation-message>
                </div>
                <!-- column -->
                <div fxFlex.gt-sm="50" fxFlex="50">
                    <mat-form-field appearance="outline" class="cust-form-field">
                        <mat-label>{{ 'ORGANISATION.DETAIL.SURVEY_REMINDER_INTERVAL' | translate }}</mat-label>
                        <mat-select placeholder="{{ 'ORGANISATION.DETAIL.SURVEY_REMINDER_INTERVAL' | translate }}"
                            formControlName="surveyReminderInterval">
                            <mat-option *ngFor="let interval of intervalOptions" [value]="interval">
                                {{ 'ENUM.INTERVAL.' + interval | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <app-validation-message [control]="formGroupOrganisation.get('surveyReminderInterval')"></app-validation-message>
                </div>
              </div>

            </form>
          </div>

        <div>
          <button mat-raised-button color="primary"
            type="submit" class="btn-lg"
            *ngIf="pageMode === PageMode.CREATE && isUserClinician === true"
            (click)="onCreateOrganisation()"
            [disabled]="!formGroupOrganisation.valid">
            {{ 'ORGANISATION.DETAIL.BUTTON.CREATE_ORGANISATION' | translate }}</button>

          <button mat-raised-button color="warn"
            type="submit" class="btn-lg"
            *ngIf="pageMode === PageMode.EDIT && isUserClinician === true"
            (click)="onEditOrganisation()"
            [disabled]="!formGroupOrganisation.valid">
            {{ 'ORGANISATION.DETAIL.BUTTON.UPDATE_ORGANISATION' | translate }}</button>
        </div>
        <!-- </mat-card-content> -->
    </div>

</div>
