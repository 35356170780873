import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cust-dialog',
  templateUrl: './cust-dialog.component.html',
  styleUrls: ['./cust-dialog.component.scss']
})
export class CustDialogComponent implements OnInit {
  custDialogModel: CustDialogModel;

  constructor(public dialogRef: MatDialogRef<CustDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustDialogModel) {
    this.custDialogModel = data;
  }

  ngOnInit(): void {
  }

  onOk(): void {
    this.dialogRef.close({status: 'ok'});
  }

  onCancel(): void {
    this.dialogRef.close({status: 'cancel'});
  }

}


export class CustDialogModel {
  title: string;
  message: string;
  type?: 'alert' | 'confirm';

  public constructor(data: any = {}) {
    this.title = data.title || '';
    this.message = data.message || '';
    this.type = data.type || 'alert';
  }
}
