import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthenticationGuardService } from 'src/app/shared/services/guard/authentication-guard.service';
import { UserEnabledGuardService } from 'src/app/shared/services/guard/user-enabled-guard.service';
import { FinancalPlanningComponent } from './pages/financal-planning/financal-planning.component';
import { HrPlanningManagementComponent } from './pages/hr-planning-management/hr-planning-management.component';
import { ManagerFormComponent } from './pages/manager-form/manager-form.component';
import { PreventionComponent } from './pages/prevention/prevention.component';
import { ServiceDemandComponent } from './pages/service-demand/service-demand.component';
import { UnaidsComponent } from './pages/unaids/unaids.component';

const routes: Routes = [
  {
    path: 'manager-form',
    // canActivate: [AuthenticationGuardService, UserEnabledGuardService],
    component: ManagerFormComponent
  },
  {
    path: 'service-demand',
    component: ServiceDemandComponent,
    canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard],
  },
  {
    path: 'hr-planning-management',
    component: HrPlanningManagementComponent,
    canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard],
  },
  {
    path: 'unaids',
    component: UnaidsComponent,
    canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard],
  },
  {
    path: 'prevention',
    component: PreventionComponent,
    canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard],
  },
  {
    path: 'financial-planning',
    component: FinancalPlanningComponent,
    canActivate: [AuthenticationGuardService, UserEnabledGuardService, NgxPermissionsGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SmarthivManagerRoutingModule { }
