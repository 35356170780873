/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Role } from '../../model/enum/role';
import { rolesToArray, rolesToStringArray, stringArrayToRoleValue } from '../../util';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {
  Role: typeof Role = Role;

  @Input() roleValue: number;
  @Input() componentMode = 'edit';
  @Output() roleChanged = new EventEmitter<any>();

  roleOptions: string[];
  selectedRoles: string[];
  selectedRolesString: string;

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    const rOptions: string[] = Object.keys(Role);
    this.roleOptions = rOptions.slice(rOptions.length / 2);
    this.selectedRoles = rolesToStringArray(this.roleValue);
    this.selectedRolesString = this.getRolesAsString();
    console.log('Role value : ' + this.roleValue + '   ' + this.selectedRolesString);
  }

  onRoleChanged(event: Event) {
    const val = stringArrayToRoleValue(this.selectedRoles);
    this.roleValue = val;
    this.roleChanged.emit(val);
  }

  getRolesAsString(): string {
    if(this.roleValue) {
      const roles = rolesToArray(this.roleValue);
      return roles.map(r => this.translate.instant('ENUM.ROLE.' + Role[r])).join(', ');
    }
    return 'No Role';
  }


}
