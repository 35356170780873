export const environment = {
  production: true,
  url: 'https://test-api.bsmartlytics.com/api',
  recaptchaSiteKey: '6LfcV44lAAAAALJAQQGcERazklTZhXXTWHFyKDjM',
  firebaseConfig: {
    apiKey: "AIzaSyDfE9KQqR5DK2SfgwNwX28ANluFbHvi1fY",
    authDomain: "bsmartlytics-e1c97.firebaseapp.com",
    projectId: "bsmartlytics-e1c97",
    storageBucket: "bsmartlytics-e1c97.appspot.com",
    messagingSenderId: "559008199018",
    appId: "1:559008199018:web:07a9befcfce26a9410b513"
  }
};
