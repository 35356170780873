<div class="top-nav__user">
<!-- ============================================================== -->
<!-- Push Notifications - style you can find in header.scss -->
<!-- ============================================================== -->
<button matTooltip="Push Notifications" [matMenuTriggerFor]="pushNotification" mat-icon-button class="top-nav__user--btn mt-4" (click)="listMyUnreadPushNotifications()">
    <i class="bs-message font-size-2-5"></i>
    <span class="count">{{unreadPushNotificationCount}}</span>
    <!-- <mat-icon matBadge="{{unreadPushNotificationCount}}" matBadgeColor="warn">message</mat-icon> -->
</button>
<mat-menu #pushNotification="matMenu" class="mymegamenu">
    <div class="mailbox">
        <ul>
            <li>
                <div class="drop-title">Push Notifications</div>
            </li>
            <li>
                <div class="message-center">
                    <perfect-scrollbar [config]="config">
                        <ng-container *ngIf="chatRoomResponseList">
                            <!-- Message -->
                            <a *ngFor="let cr of chatRoomResponseList" (click)="goToChatPage()">
                                <div class="mail-content mail-content-cursor">
                                    <h5>{{cr.user.firstName + ' ' + cr.user.lastName }}</h5>
                                    <span class="mail-desc">{{cr.lastMessage.content | slice:0:20}}</span>
                                    <span class="time">{{cr.lastMessage.createdAt}}</span>
                                </div>
                            </a>
                        </ng-container>

                        <ng-container *ngIf="!chatRoomResponseList">
                            <div>
                                There is no waiting notifications
                            </div>
                        </ng-container>

                    </perfect-scrollbar>
                </div>
            </li>

        </ul>
    </div>
</mat-menu>


<!-- ============================================================== -->
<!-- Invitation - style you can find in header.scss -->
<!-- ============================================================== -->
<button matTooltip="Invitations" [matMenuTriggerFor]="invitation" mat-icon-button class="top-nav__user--btn mt-4" (click)="loadWaitingInvitations()">
    <i class="bs-subscription font-size-2-5"></i>
    <span class="count">{{waitingInvitationCount}}</span>
    <!-- <mat-icon matBadge="{{waitingInvitationCount}}" matBadgeColor="warn">message</mat-icon> -->
</button>
<mat-menu #invitation="matMenu" class="mymegamenu">
    <div class="mailbox">
        <ul>
            <li>
                <div class="drop-title">Invitations</div>
            </li>
            <li>
                <div class="message-center">
                    <perfect-scrollbar [config]="config">
                        <ng-container *ngIf="invitations">
                            <!-- Message -->
                            <a *ngFor="let invitation of invitations" (click)="goToIncomingInvitations()">
                                <div class="mail-content mail-content-cursor">
                                    <h5>{{ 'ENUM.INVITATION_TYPE.' + invitation.type  | translate }}</h5>
                                    <span class="mail-desc">{{invitation.invitorNote}}</span>
                                    <span class="time">{{invitation.invitationTime}}</span>
                                </div>
                            </a>
                        </ng-container>

                        <ng-container *ngIf="!invitations">
                            <div>
                                There is no waiting invitations
                            </div>
                        </ng-container>

                    </perfect-scrollbar>
                </div>
            </li>

        </ul>
    </div>
</mat-menu>

<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button [matMenuTriggerFor]="profile" class="top-nav__user--avatar">
    <img src="{{photoUrl}}" alt="user" class="profile-pic"> </button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <!--button mat-menu-item>
        <mat-icon>settings</mat-icon> Settings
    </button-->
    <button mat-menu-item (click)="goToAccountUpdatePage()">
        <mat-icon>account_box</mat-icon> Profile Settings
    </button>
    <button *ngxPermissionsOnly="['SYSTEM_ADMINISTRATOR','CLINICIAN','STANDALONE_CLINICIAN','SMART_HIV_MANAGER','SMART_HIV_TRIALIST','SMART_HIV_AUDITLYTICS', 'SMART_HIV_CLINICIAN']" mat-menu-item (click)="goToOrganisationDetailPage()">
        <fa-icon [icon]="faBuilding" size="lg" style="margin-right: 22px; color: rgba(0, 0, 0, 0.54);"></fa-icon> Manage
        Organisation
    </button>
    <button mat-menu-item (click)="goToChatPage()">
        <fa-icon [icon]="faComments" size="lg" style="margin-right: 22px; color: rgba(0, 0, 0, 0.54);"></fa-icon> Chat
    </button>
    <button mat-menu-item (click)="openUserManual()">
        <mat-icon>insert_drive_file</mat-icon> User Manual
    </button>
    <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon> Sign Out
    </button>
</mat-menu>
</div>
