/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DEFAULT_USER_PHOTO } from 'src/app/shared/constants';
import { InvitationStatus } from 'src/app/shared/model/enum/invitation-status';
import { InvitationType } from 'src/app/shared/model/enum/invitation-type';
import { Invitation, InvitationConfirmRequest } from 'src/app/shared/model/invitation';
import { InvitationService } from 'src/app/shared/services/invitation.service';
import { NotificationService } from '../../notification/service/notification.service';
import { InboxType } from '../model/invitation-models';

@Component({
    selector: 'app-invitation-detail',
    templateUrl: './invitation-detail.component.html',
    styleUrls: ['./invitation-detail.component.scss']
})
export class InvitationDetailComponent implements OnInit {

    @Input() invitation: Invitation;
    @Input() inboxType: InboxType;

    InvitationType: typeof InvitationType = InvitationType;
    InboxType: typeof InboxType = InboxType;

    invitationTypeOptions: string[];
    invitationTypeLabelColors = ['label-info', 'label-success', 'label-danger', 'label-primary', 'label-warning'];
    invitationStatusLabelColors = ['label-success', 'label-danger', 'label-warning', 'label-info', 'label-primary', ];

    type: InvitationType;

    showReply = false;
    showActionsPanel = false;

    public config: PerfectScrollbarConfigInterface = {};


    constructor(
        private router: Router,
        private translate: TranslateService,
        private notificationService: NotificationService,
        private invitationService: InvitationService
    ) { }


    ngOnInit() {
        const itOptions: string[] = Object.keys(InvitationType);
        this.invitationTypeOptions = itOptions.slice(itOptions.length / 2);
        this.type = InvitationType[this.invitation.type];
        this.showActionsPanel = this.invitation.responseTime ? false : true;
    }

    onSendConfirm() {
        this.sendConfirmation(InvitationStatus.ACCEPTED);
    }

    onSendReject() {
        this.sendConfirmation(InvitationStatus.REJECTED);
    }

    sendConfirmation(status: InvitationStatus) {
        const confirmRequest = new InvitationConfirmRequest();
        confirmRequest.inviteeNote = this.invitation.inviteeNote;
        confirmRequest.status = InvitationStatus[status];

        this.invitationService.confirmInvitation(this.invitation.id, confirmRequest).subscribe(invitation => {
            this.invitation = invitation;
            this.notificationService.success(this.translate.instant('SEND_INVITATION.NOTIFICATION.INVITATION_CONFIRM.SUCCESS'));
        }, error => {
            console.log('Error on confirming invitation : ' + JSON.stringify(error));
            this.notificationService.error(this.translate.instant('SEND_INVITATION.NOTIFICATION.INVITATION_CONFIRM.FAILED'));
        });
    }


    getInviteeUsername(row: Invitation) {
        if (row.invitee) {
            return row.invitee.firstName + ' ' + row.invitee.lastName;
        }
        return row.unregisteredInviteeEmail ? row.unregisteredInviteeEmail : row.unregisteredInviteePhone;
    }

    getRowPhotoUrl(row: Invitation) {
        return row.invitor.photoUrl ? row.invitor.photoUrl : DEFAULT_USER_PHOTO;
    }

    getTypeColorClass(row: Invitation) {
        return this.invitationTypeLabelColors[(InvitationType[row.type] % this.invitationTypeLabelColors.length)];
    }

    getStatusColorClass(row: Invitation) {
        return this.invitationStatusLabelColors[(InvitationStatus[row.status] % this.invitationStatusLabelColors.length)];
    }

}


