<div class="section-title-bar bg-primary justify-content-between border-radius-bottom-0">
    <h2 class="content-box__title font-size-2 mb-0">{{ 'SIMULATION.PREVENTION.CURRENT_SUMMARY' | translate }}</h2>
    <mat-icon matTooltip="{{ 'SIMULATION.PREVENTION.CURRENT_SUMMARY' | translate }}">info</mat-icon>
</div>

<div class="content-box blue-background service-demand-grid border-radius-top-0 mb-24">
    <div class="content-box chart px-0 pink-background">
        <h3 class="content-box__title sub">{{ 'SIMULATION.CURRENT_PRACTICE' | translate }}</h3>
        <div id="chartPieCurrent" class="d-flex flex-column" *ngIf="chartPieCurrentOptions">
            <apx-chart 
                [series]="chartPieCurrentOptions.series"
                [chart]="chartPieCurrentOptions.chart"
                [labels]="chartPieCurrentOptions.labels"
                [legend]="chartPieCurrentOptions.legend"
                [responsive]="chartPieCurrentOptions.responsive"
                [colors]="chartPieCurrentOptions.colors"
                [tooltip]="chartPieCurrentOptions.tooltip">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart px-0 pink-background">
        <h3 class="content-box__title sub">{{ 'SIMULATION.CURRENT_PRACTICE_ACTIVITY' | translate }}</h3>
        <div id="chartPieCurrentActivity" class="d-flex flex-column" *ngIf="chartPieCurrentActivityOptions">
            <apx-chart 
                [series]="chartPieCurrentActivityOptions.series"
                [chart]="chartPieCurrentActivityOptions.chart"
                [labels]="chartPieCurrentActivityOptions.labels"
                [legend]="chartPieCurrentActivityOptions.legend"
                [responsive]="chartPieCurrentActivityOptions.responsive"
                [colors]="chartPieCurrentActivityOptions.colors"
                [tooltip]="chartPieCurrentActivityOptions.tooltip">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart px-0 pink-background">
        <h3 class="content-box__title sub">{{ 'SIMULATION.CURRENT_PRACTICE' | translate }}</h3>
        <div id="chartPieType" class="d-flex flex-column" *ngIf="chartPieTypeOptions">
            <apx-chart 
                [series]="chartPieTypeOptions.series"
                [chart]="chartPieTypeOptions.chart"
                [labels]="chartPieTypeOptions.labels"
                [legend]="chartPieTypeOptions.legend"
                [responsive]="chartPieTypeOptions.responsive"
                [colors]="chartPieTypeOptions.colors"
                [tooltip]="chartPieTypeOptions.tooltip">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart px-0 pink-background">
        <h3 class="content-box__title sub">{{ 'SIMULATION.SCENARIO' | translate }}</h3>
        <div id="chartPieScenario" class="d-flex flex-column" *ngIf="chartPieScenarioOptions">
            <apx-chart 
                [series]="chartPieScenarioOptions.series"
                [chart]="chartPieScenarioOptions.chart"
                [labels]="chartPieScenarioOptions.labels"
                [legend]="chartPieScenarioOptions.legend"
                [responsive]="chartPieScenarioOptions.responsive"
                [colors]="chartPieScenarioOptions.colors"
                [tooltip]="chartPieScenarioOptions.tooltip">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart px-0 pink-background">
        <h3 class="content-box__title sub">{{ 'SIMULATION.SCENARIO_ACTIVITY' | translate }}</h3>
        <div id="chartPieScenarioActivity" class="d-flex flex-column" *ngIf="chartPieScenarioActivityOptions">
            <apx-chart 
                [series]="chartPieScenarioActivityOptions.series"
                [chart]="chartPieScenarioActivityOptions.chart"
                [labels]="chartPieScenarioActivityOptions.labels"
                [legend]="chartPieScenarioActivityOptions.legend"
                [responsive]="chartPieScenarioActivityOptions.responsive"
                [colors]="chartPieScenarioActivityOptions.colors"
                [tooltip]="chartPieScenarioActivityOptions.tooltip">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart px-0 pink-background">
        <h3 class="content-box__title sub">{{ 'SIMULATION.SCENARIO' | translate }}</h3>
        <div id="chartPieTypeSc" class="d-flex flex-column" *ngIf="chartPieTypeScOptions">
            <apx-chart 
                [series]="chartPieTypeScOptions.series"
                [chart]="chartPieTypeScOptions.chart"
                [labels]="chartPieTypeScOptions.labels"
                [legend]="chartPieTypeScOptions.legend"
                [responsive]="chartPieTypeScOptions.responsive"
                [colors]="chartPieTypeScOptions.colors"
                [tooltip]="chartPieTypeScOptions.tooltip">
            </apx-chart>
        </div>
    </div>
</div>

<div class="outcomes-charts mb-12">
    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.PREVENTION.NO_PATIENTS_PREV' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.PREVENTION.NO_PATIENTS_PREV' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.PREVENTION.PREVENTION_TYPE' | translate }}</p>

        <div id="chartPatient" class="d-flex flex-column" *ngIf="chartPatientOptions">
            <apx-chart 
                [series]="chartPatientOptions.series"
                [xaxis]="chartPatientOptions.xaxis"
                [yaxis]="chartPatientOptions.yaxis" 
                [chart]="chartPatientOptions.chart"
                [dataLabels]="chartPatientOptions.dataLabels" 
                [plotOptions]="chartPatientOptions.plotOptions"
                [legend]="chartPatientOptions.legend" 
                [fill]="chartPatientOptions.fill"
                [stroke]="chartPatientOptions.stroke"
                [tooltip]="chartPatientOptions.tooltip"
                [grid]="chartPatientOptions.grid">
            </apx-chart>
        </div>
    </div>
</div>
<p class="subtitle-text mb-12">{{ 'SIMULATION.CP_SC' | translate }}</p>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.PREVENTION.PREP_ACTIVITY' | translate }}</p>

<div class="outcomes-charts mb-12">
    <div>
        <div class="content-box chart">
            <div class="d-flex justify-content-between">
                <h3 class="content-box__title mb-0">{{ 'SIMULATION.PREVENTION.PREP_PATIENTS' | translate }}</h3>
                <mat-icon matTooltip="{{ 'SIMULATION.PREVENTION.PREP_PATIENTS' | translate }}">info</mat-icon>
            </div>
            <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.PREVENTION.NEW_PATIENTS' | translate }}</p>

            <div id="chartPatientPrep" class="d-flex flex-column" *ngIf="chartPatientPrepOptions">
                <apx-chart 
                    [series]="chartPatientPrepOptions.series"
                    [xaxis]="chartPatientPrepOptions.xaxis"
                    [yaxis]="chartPatientPrepOptions.yaxis" 
                    [chart]="chartPatientPrepOptions.chart"
                    [dataLabels]="chartPatientPrepOptions.dataLabels" 
                    [plotOptions]="chartPatientPrepOptions.plotOptions"
                    [legend]="chartPatientPrepOptions.legend" 
                    [fill]="chartPatientPrepOptions.fill"
                    [stroke]="chartPatientPrepOptions.stroke"
                    [tooltip]="chartPatientPrepOptions.tooltip"
                    [grid]="chartPatientPrepOptions.grid">
                </apx-chart>
            </div>
        </div>
        <div class="h-35"></div>
    </div>

    <div>
        <div class="content-box chart">
            <div class="d-flex justify-content-between">
                <h3 class="content-box__title mb-0">{{ 'SIMULATION.PREVENTION.PREP_RISK' | translate }}</h3>
                <mat-icon matTooltip="{{ 'SIMULATION.PREVENTION.PREP_RISK' | translate }}">info</mat-icon>
            </div>
            <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.PREVENTION.RISK_CATEGORY' | translate }}</p>

            <div id="chartPrepRisk" class="d-flex flex-column" *ngIf="chartPrepRiskOptions">
                <apx-chart 
                    [series]="chartPrepRiskOptions.series" 
                    [colors]="chartPrepRiskOptions.colors"
                    [chart]="chartPrepRiskOptions.chart"
                    [dataLabels]="chartPrepRiskOptions.dataLabels" 
                    [plotOptions]="chartPrepRiskOptions.plotOptions"
                    [yaxis]="chartPrepRiskOptions.yaxis" 
                    [legend]="chartPrepRiskOptions.legend" 
                    [fill]="chartPrepRiskOptions.fill"
                    [stroke]="chartPrepRiskOptions.stroke" 
                    [tooltip]="chartPrepRiskOptions.tooltip" 
                    [xaxis]="chartPrepRiskOptions.xaxis"
                    [grid]="chartPrepRiskOptions.grid">
                </apx-chart>
            </div>
        </div>
        <p class="subtitle-text mb-0">{{ 'SIMULATION.CP_SC' | translate }}</p>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.PREVENTION.PREP_AVERTED' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.PREVENTION.PREP_AVERTED' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.PREVENTION.AVERTED' | translate }}</p>

        <div id="chartPrepAverted" class="d-flex flex-column" *ngIf="chartPrepAvertedOptions">
            <apx-chart [series]="chartPrepAvertedOptions.series" 
                [chart]="chartPrepAvertedOptions.chart"
                [dataLabels]="chartPrepAvertedOptions.dataLabels" 
                [plotOptions]="chartPrepAvertedOptions.plotOptions"
                [yaxis]="chartPrepAvertedOptions.yaxis" 
                [legend]="chartPrepAvertedOptions.legend" 
                [fill]="chartPrepAvertedOptions.fill"
                [stroke]="chartPrepAvertedOptions.stroke" 
                [tooltip]="chartPrepAvertedOptions.tooltip" 
                [xaxis]="chartPrepAvertedOptions.xaxis"
                [grid]="chartPrepAvertedOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.PREVENTION.PREP_VISITS' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.PREVENTION.PREP_VISITS' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.PREVENTION.VISITS' | translate }}</p>

        <div id="chartPrepVisit" class="d-flex flex-column" *ngIf="chartPrepVisitOptions">
            <apx-chart [series]="chartPrepVisitOptions.series" 
                [chart]="chartPrepVisitOptions.chart"
                [dataLabels]="chartPrepVisitOptions.dataLabels" 
                [plotOptions]="chartPrepVisitOptions.plotOptions"
                [yaxis]="chartPrepVisitOptions.yaxis" 
                [legend]="chartPrepVisitOptions.legend" 
                [fill]="chartPrepVisitOptions.fill"
                [stroke]="chartPrepVisitOptions.stroke" 
                [tooltip]="chartPrepVisitOptions.tooltip" 
                [xaxis]="chartPrepVisitOptions.xaxis"
                [grid]="chartPrepVisitOptions.grid">
            </apx-chart>
        </div>
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.PREVENTION.PEP_ACTIVITY' | translate }}</p>

<div class="outcomes-charts mb-12">
    <div>
        <div class="content-box chart">
            <div class="d-flex justify-content-between">
                <h3 class="content-box__title mb-0">{{ 'SIMULATION.PREVENTION.PEP_PATIENTS' | translate }}</h3>
                <mat-icon matTooltip="{{ 'SIMULATION.PREVENTION.PEP_PATIENTS' | translate }}">info</mat-icon>
            </div>
            <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.PREVENTION.NEW_PATIENTS' | translate }}</p>

            <div id="chartPatientPrepSc" class="d-flex flex-column" *ngIf="chartPatientPrepScOptions">
                <apx-chart 
                    [series]="chartPatientPrepScOptions.series"
                    [xaxis]="chartPatientPrepScOptions.xaxis"
                    [yaxis]="chartPatientPrepScOptions.yaxis" 
                    [chart]="chartPatientPrepScOptions.chart"
                    [dataLabels]="chartPatientPrepScOptions.dataLabels" 
                    [plotOptions]="chartPatientPrepScOptions.plotOptions"
                    [legend]="chartPatientPrepScOptions.legend" 
                    [fill]="chartPatientPrepScOptions.fill"
                    [stroke]="chartPatientPrepScOptions.stroke"
                    [tooltip]="chartPatientPrepScOptions.tooltip"
                    [grid]="chartPatientPrepScOptions.grid">
                </apx-chart>
            </div>
        </div>
        <div class="h-35"></div>
    </div>

    <div>
        <div class="content-box chart">
            <div class="d-flex justify-content-between">
                <h3 class="content-box__title mb-0">{{ 'SIMULATION.PREVENTION.PEP_RISK' | translate }}</h3>
                <mat-icon matTooltip="{{ 'SIMULATION.PREVENTION.PEP_RISK' | translate }}">info</mat-icon>
            </div>
            <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.PREVENTION.RISK_CATEGORY' | translate }}</p>

            <div id="chartPrepRiskSc" class="d-flex flex-column" *ngIf="chartPrepRiskScOptions">
                <apx-chart 
                    [series]="chartPrepRiskScOptions.series" 
                    [colors]="chartPrepRiskScOptions.colors"
                    [chart]="chartPrepRiskScOptions.chart"
                    [dataLabels]="chartPrepRiskScOptions.dataLabels" 
                    [plotOptions]="chartPrepRiskScOptions.plotOptions"
                    [yaxis]="chartPrepRiskScOptions.yaxis" 
                    [legend]="chartPrepRiskScOptions.legend" 
                    [fill]="chartPrepRiskScOptions.fill"
                    [stroke]="chartPrepRiskScOptions.stroke" 
                    [tooltip]="chartPrepRiskScOptions.tooltip" 
                    [xaxis]="chartPrepRiskScOptions.xaxis"
                    [grid]="chartPrepRiskScOptions.grid">
                </apx-chart>
            </div>
        </div>
        <p class="subtitle-text mb-0">{{ 'SIMULATION.CP_SC' | translate }}</p>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.PREVENTION.PEP_AVERTED' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.PREVENTION.PEP_AVERTED' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.PREVENTION.AVERTED' | translate }}</p>

        <div id="chartPrepAvertedSc" class="d-flex flex-column" *ngIf="chartPrepAvertedScOptions">
            <apx-chart [series]="chartPrepAvertedScOptions.series" 
                [chart]="chartPrepAvertedScOptions.chart"
                [dataLabels]="chartPrepAvertedScOptions.dataLabels" 
                [plotOptions]="chartPrepAvertedScOptions.plotOptions"
                [yaxis]="chartPrepAvertedScOptions.yaxis" 
                [legend]="chartPrepAvertedScOptions.legend" 
                [fill]="chartPrepAvertedScOptions.fill"
                [stroke]="chartPrepAvertedScOptions.stroke" 
                [tooltip]="chartPrepAvertedScOptions.tooltip" 
                [xaxis]="chartPrepAvertedScOptions.xaxis"
                [grid]="chartPrepAvertedScOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.PREVENTION.PEP_VISITS' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.PREVENTION.PEP_VISITS' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.PREVENTION.VISITS' | translate }}</p>

        <div id="chartPrepVisitSc" class="d-flex flex-column" *ngIf="chartPrepVisitScOptions">
            <apx-chart [series]="chartPrepVisitScOptions.series" 
                [chart]="chartPrepVisitScOptions.chart"
                [dataLabels]="chartPrepVisitScOptions.dataLabels" 
                [plotOptions]="chartPrepVisitScOptions.plotOptions"
                [yaxis]="chartPrepVisitScOptions.yaxis" 
                [legend]="chartPrepVisitScOptions.legend" 
                [fill]="chartPrepVisitScOptions.fill"
                [stroke]="chartPrepVisitScOptions.stroke" 
                [tooltip]="chartPrepVisitScOptions.tooltip" 
                [xaxis]="chartPrepVisitScOptions.xaxis"
                [grid]="chartPrepVisitScOptions.grid">
            </apx-chart>
        </div>
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.PREVENTION.DRUG_COSTS' | translate }}</p>

<div class="outcomes-charts">
    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.PREVENTION.YEAR_RESULT' | translate:{year: years} }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.PREVENTION.YEAR_RESULT' | translate:{year: years} }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.PREVENTION.HIV_VS_PREVENTION' | translate }}</p>

        <div id="chartHivPrevention" class="d-flex flex-column" *ngIf="chartHivPreventionOptions">
            <apx-chart 
                [series]="chartHivPreventionOptions.series" 
                [chart]="chartHivPreventionOptions.chart"
                [dataLabels]="chartHivPreventionOptions.dataLabels" 
                [plotOptions]="chartHivPreventionOptions.plotOptions"
                [yaxis]="chartHivPreventionOptions.yaxis" 
                [legend]="chartHivPreventionOptions.legend" 
                [fill]="chartHivPreventionOptions.fill"
                [stroke]="chartHivPreventionOptions.stroke" 
                [tooltip]="chartHivPreventionOptions.tooltip" 
                [xaxis]="chartHivPreventionOptions.xaxis"
                [grid]="chartHivPreventionOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.PREVENTION.TOTAL_AVERTED' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.PREVENTION.TOTAL_AVERTED' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.CASE' | translate }}</p>

        <div id="chartTotalAverted" class="d-flex flex-column" *ngIf="chartTotalAvertedOptions">
            <apx-chart 
                [series]="chartTotalAvertedOptions.series" 
                [chart]="chartTotalAvertedOptions.chart"
                [dataLabels]="chartTotalAvertedOptions.dataLabels" 
                [plotOptions]="chartTotalAvertedOptions.plotOptions"
                [yaxis]="chartTotalAvertedOptions.yaxis" 
                [legend]="chartTotalAvertedOptions.legend" 
                [fill]="chartTotalAvertedOptions.fill"
                [stroke]="chartTotalAvertedOptions.stroke" 
                [tooltip]="chartTotalAvertedOptions.tooltip" 
                [xaxis]="chartTotalAvertedOptions.xaxis"
                [grid]="chartTotalAvertedOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div>
        <div class="content-box chart">
            <div class="d-flex justify-content-between">
                <h3 class="content-box__title mb-0">{{ 'SIMULATION.PREVENTION.ANNUAL_DRUG_COSTS' | translate }}</h3>
                <mat-icon matTooltip="{{ 'SIMULATION.PREVENTION.ANNUAL_DRUG_COSTS' | translate }}">info</mat-icon>
            </div>
            <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.COST.EXPENSE' | translate }}</p>

            <div id="chartTotalPrevention" class="d-flex flex-column" *ngIf="chartTotalPreventionOptions">
                <apx-chart [series]="chartTotalPreventionOptions.series" 
                    [chart]="chartTotalPreventionOptions.chart"
                    [dataLabels]="chartTotalPreventionOptions.dataLabels"
                    [plotOptions]="chartTotalPreventionOptions.plotOptions" 
                    [yaxis]="chartTotalPreventionOptions.yaxis"
                    [legend]="chartTotalPreventionOptions.legend" 
                    [fill]="chartTotalPreventionOptions.fill"
                    [stroke]="chartTotalPreventionOptions.stroke" 
                    [tooltip]="chartTotalPreventionOptions.tooltip"
                    [xaxis]="chartTotalPreventionOptions.xaxis" 
                    [grid]="chartTotalPreventionOptions.grid">
                </apx-chart>
            </div>
        </div>
        <p class="subtitle-text mb-0">{{ 'SIMULATION.CP_SC' | translate }}</p>
    </div>

    <div>
        <div class="content-box chart">
            <div class="d-flex justify-content-between">
                <h3 class="content-box__title mb-0">{{ 'SIMULATION.PREVENTION.ANNUAL_DRUG_COSTS_PROGRAMME' | translate }}</h3>
                <mat-icon matTooltip="{{ 'SIMULATION.PREVENTION.ANNUAL_DRUG_COSTS_PROGRAMME' | translate }}">info</mat-icon>
            </div>
            <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.COST.EXPENSE' | translate }}</p>

            <div id="chartTotalPreventionProgramme" class="d-flex flex-column" *ngIf="chartTotalPreventionProgrammeOptions">
                <apx-chart [series]="chartTotalPreventionProgrammeOptions.series" 
                    [chart]="chartTotalPreventionProgrammeOptions.chart"
                    [dataLabels]="chartTotalPreventionProgrammeOptions.dataLabels"
                    [plotOptions]="chartTotalPreventionProgrammeOptions.plotOptions" 
                    [yaxis]="chartTotalPreventionProgrammeOptions.yaxis"
                    [legend]="chartTotalPreventionProgrammeOptions.legend" 
                    [fill]="chartTotalPreventionProgrammeOptions.fill"
                    [stroke]="chartTotalPreventionProgrammeOptions.stroke" 
                    [tooltip]="chartTotalPreventionProgrammeOptions.tooltip"
                    [xaxis]="chartTotalPreventionProgrammeOptions.xaxis" 
                    [grid]="chartTotalPreventionProgrammeOptions.grid">
                </apx-chart>
            </div>
        </div>
        <p class="subtitle-text mb-0">{{ 'SIMULATION.CP_SC' | translate }}</p>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between mb-24">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.PREVENTION.COST_EFFECTIVENESS' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.PREVENTION.COST_EFFECTIVENESS' | translate }}">info</mat-icon>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-around center">
            <div fxLayout="column" class="cost-panel-blue">
                <span class="cost-panel-title">{{ 'SIMULATION.PREVENTION.DIFFERENCE_IN_TOTAL_COST' | translate }}</span>
                <span class="cost-panel-text">
                    {{differenceInTotalCostStr}}
                </span>
            </div>
            <div fxLayout="column" class="cost-panel-pink">
                <span class="cost-panel-title">{{ 'SIMULATION.PREVENTION.DIFFERENCE_IN_AVERTED' | translate }}</span>
                <span class="cost-panel-text">{{differenceInAvertedCaseStr}}</span>
            </div>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between mb-24">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.CURRENT_PRACTICE_VS_SCENARIO' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.CURRENT_PRACTICE_VS_SCENARIO' | translate }}">info</mat-icon>
        </div>

        <div fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <div fxLayout="column" class="cost-panel-blue">
                    <span class="cost-panel-title">{{ 'SIMULATION.PREVENTION.COST_AVERTED' | translate }}</span>
                    <span class="cost-panel-text">{{costAvertedStr}}</span>
                </div>
                <div fxLayout="column" class="cost-panel-pink">
                    <span class="cost-panel-title">{{ 'SIMULATION.PREVENTION.COST_EFFECTIVENESS_PLANE' | translate }}</span>
                    <span class="cost-panel-text" appAwesomeTooltip="Hello World!"  image="assets/images/quadrant.jpeg">{{costEffectivenessPlane}}</span>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-around center" class="cost-result-panel" [ngStyle]="{'background-color': costColor}">
                <span>{{costResult}}</span>
            </div>
        </div>
    </div>
</div>