<div class="file-selection-container">
    <div class="dropzone" appDragDropFile (fileDropped)="selectFiles($event)">
        <div class="text-wrapper">
            <div class="centered">{{ 'GENERAL.DROP_FILE' | translate }}</div>
        </div>
    </div>
    <div class="or-container">OR</div>
    <button mat-raised-button (click)="fileInput.click()">{{ 'GENERAL.SELECT_FILE' | translate }}</button>
    <input hidden type="file" (change)="onSelectFiles($event)" (click)="fileInput.value = null" accept="{{fileExtensions}}" [multiple]="multiple" #fileInput>
</div>

<div *ngFor="let fileUploadModel of fileUploadModels">
    <app-upload-info [fileUploadModel] = "fileUploadModel" (uploadEvent)="upload($event)" (removeEvent)="remove($event)"></app-upload-info>
</div>

<button mat-raised-button color="primary" *ngIf="uploadAllButtonActive && fileUploadModels.length > 0" (click)="uploadAll()">{{multiple ? ('GENERAL.UPLOAD_ALL' | translate) : ('GENERAL.UPLOAD' | translate) }}</button>
<button mat-raised-button color="warn" *ngIf="removeAllButtonActive && fileUploadModels.length > 0" (click)="removeAll()">{{ multiple ? ('GENERAL.REMOVE_ALL' | translate) : ('GENERAL.REMOVE' | translate) }}</button>


