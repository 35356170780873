import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuardService } from 'src/app/shared/services/guard/authentication-guard.service';
import { UserEnabledGuardService } from 'src/app/shared/services/guard/user-enabled-guard.service';
import { TrialCountryComponent } from './pages/trial-country/trial-country.component';
import { TrialFilterComponent } from './pages/trial-filter/trial-filter.component';
import { TrialSummaryComponent } from './pages/trial-summary/trial-summary.component';
import { TrialComorbidityComponent } from './pages/trial-comorbidity/trial-comorbidity.component';
import { TrialDemographicsComponent } from './pages/trial-demographics/trial-demographics.component';
import { TrialBudgetCalculatorComponent } from './pages/trial-budget-calculator/trial-budget-calculator.component';

const routes: Routes = [
  {
    path: 'trial-filter',
    canActivate: [AuthenticationGuardService, UserEnabledGuardService],
    component: TrialFilterComponent
  },
  {
    path: 'trial-country',
    canActivate: [AuthenticationGuardService, UserEnabledGuardService],
    component: TrialCountryComponent
  },
  {
    path: 'trial-summary',
    canActivate: [AuthenticationGuardService, UserEnabledGuardService],
    component: TrialSummaryComponent
  },
  {
    path: 'trial-comorbidity',
    canActivate: [AuthenticationGuardService, UserEnabledGuardService],
    component: TrialComorbidityComponent
  },
  {
    path: 'trial-demographics',
    canActivate: [AuthenticationGuardService, UserEnabledGuardService],
    component: TrialDemographicsComponent
  },
  {
    path: 'trial-budget-calculator',
    canActivate: [AuthenticationGuardService, UserEnabledGuardService],
    component: TrialBudgetCalculatorComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SmarthivTrialistRoutingModule { }
