import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { LoginResponse } from '../model/user-profile';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';
import { UserAccount } from '../model/user-account';
import { parseJwt } from '../util';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationStateService {

  loginChangeSubject: BehaviorSubject<LoginResponse>;

  animationStarted: boolean;
  animationStartedUpdate: Observable<boolean>;
  animationStartedObserver: Subscriber<boolean>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService) {
    const loginInfo = this.storageService.getLoginInfo();
    const loginResponse = loginInfo ? loginInfo : null;
    this.loginChangeSubject = new BehaviorSubject<LoginResponse>(loginResponse);

    this.animationStartedUpdate = new Observable(observer => {
      this.animationStartedObserver = observer;
    });
  }

  getLoginChangeObservable(): Observable<LoginResponse> {
    return this.loginChangeSubject.asObservable();
  }

  getLoginInfo(): LoginResponse {
    return this.storageService.getLoginInfo();
  }

  isAuthenticated(): boolean {
    const token = this.getAccessToken();
    return token != null;
  }

  getAccessToken(): string {
    const loginInfo = this.getLoginInfo();
    return loginInfo ? loginInfo.accessToken : null;
  }

  getRefreshToken(): string {
    const loginInfo = this.getLoginInfo();
    return loginInfo ? loginInfo.refreshToken : null;
  }

  notifyLoginInfo(loginInfo: LoginResponse) {
    this.storageService.setLoginInfo(loginInfo);
    if (!loginInfo) {
      this.storageService.removeLoginInfo();
      this.storageService.removeAuditlyticsPermission();
      this.storageService.removeScenario();
      this.storageService.removeSimulation();
      this.storageService.removeSimulationResult();
      this.storageService.removePatients();
      this.storageService.removePatientsSc();
      this.storageService.removeVisits();
      this.storageService.removeVisitsSc();
      this.storageService.removeHours();
      this.storageService.removeHoursSc();
      this.storageService.removeTrialFilter();
      this.storageService.removeAuditFilter();
      this.storageService.removeCountry();
      this.storageService.removeLanguage();
    }
    this.loginChangeSubject.next(loginInfo);
  }

  notifyLoginInfoWithAccount(account: UserAccount) {
    const info = this.getLoginInfo();
    info.email = account.email;
    info.roles = account.roles;
    info.photoUrl = account.photoUrl;
    info.permissionLevel = account.permissionLevel;

    info.patientOrganisationId = account.patientOrganisationId;
    info.patientOrganisationName = account.patientOrganisationName;

    info.patientOrganisationBranchId = account.patientOrganisationBranchId;
    info.patientOrganisationBranchName = account.patientOrganisationBranchName;

    info.clinicianOrganisationId = account.clinicianOrganisationId;
    info.clinicianOrganisationName = account.clinicianOrganisationName;

    info.clinicianOrganisationBranchId = account.clinicianOrganisationBranchId;
    info.clinicianOrganisationBranchName = account.clinicianOrganisationBranchName;

    info.organisationOwner = account.organisationOwner;
    this.notifyLoginInfo(info);
  }

  notifyAccessTokenChange(accessToken: string) {
    const info = this.getLoginInfo();
    info.accessToken = accessToken;
    this.notifyLoginInfo(info);
  }

  notifyOrganisationInfoChanges(clinicianOrganisationId: number, clinicianOrganisationName: string, clinicianOrganisationBranchId: number, clinicianOrganisationBranchName: string) {
    const info = this.getLoginInfo();
    info.clinicianOrganisationId = clinicianOrganisationId;
    info.clinicianOrganisationName = clinicianOrganisationName;
    info.clinicianOrganisationBranchId = clinicianOrganisationBranchId;
    info.clinicianOrganisationBranchName = clinicianOrganisationBranchName;
    this.notifyLoginInfo(info);
  }

  logout() {
    this.notifyLoginInfo(null);
    this.router.navigateByUrl('/login');
  }

  isAnimationStarted(): boolean {
    return this.animationStarted;
  }

  updateGlobalVar(started: boolean) {
    this.animationStarted = started;
    if (!this.animationStartedObserver) {return;}
    this.animationStartedObserver.next(this.animationStarted);
  }

  getDecodedToken() {
    const token = this.storageService.getLoginInfo().accessToken;
    if (token) {
      return parseJwt(token);
    }
    return null;
  }

}
