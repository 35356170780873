import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../module/notification/service/notification.service';
import { OrganisationBranchFilterRequest } from '../../model/organisation';
import { AuditlyticsPermission } from '../../model/auditlytics-permission';
import { AuditlyticsPermissionService } from '../auditlytics-permission.service';
import { OrganisationBranchService } from '../organisation-branch.service';
import { StorageService } from '../storage.service';
import { MAX_PAGE_SIZE_FOR_UNLIMITED_LIST } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class AuditlyticsPermissionGuardService implements CanActivate {

    constructor(
        private translate: TranslateService,
        private notificationService: NotificationService,
        private storageService: StorageService,
        private auditlyticsPermissionService: AuditlyticsPermissionService,
        private organsationBranchService: OrganisationBranchService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.auditlyticsPermissionService.getAuditlyticsPermissionByMe().pipe(map(permission => {
            if (permission && permission.id) {
                delete permission.id;
                delete permission.user;
                this.storageService.setAuditlyticsPermission(permission);
                if (permission.organisationBranchIds && permission.organisationBranchIds.length > 0) {
                    this.updateOrgBranchesForAuditlyticsPermission(permission);
                }
            } else {
                this.storageService.removeAuditlyticsPermission();
            }
            return true;
        }), catchError(error => {
            console.log('There is an error getAuditlyticsPermissionByMe' + JSON.stringify(error));
            return of(true);
        }));
    }


    updateOrgBranchesForAuditlyticsPermission(auditlyticsPermission: AuditlyticsPermission) {
        const filterRequest = new OrganisationBranchFilterRequest();
        filterRequest.branchIds = auditlyticsPermission.organisationBranchIds;
        this.organsationBranchService.getOrganisationBranches(null, MAX_PAGE_SIZE_FOR_UNLIMITED_LIST, null, null, filterRequest).subscribe(page => {
            auditlyticsPermission.organisationBranches = page.content;
            this.storageService.setAuditlyticsPermission(auditlyticsPermission);
        });
    }
}
