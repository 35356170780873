import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../module/notification/service/notification.service';
import { AuthenticationStateService } from '../../state/authentication-state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuardService implements CanActivate {

 constructor(
    public router: Router,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private authenticationStateService: AuthenticationStateService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLoggedIn = this.authenticationStateService.isAuthenticated();
    if (!isLoggedIn) {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
    const isAnimationStarted = this.authenticationStateService.isAnimationStarted();
    if (isAnimationStarted) {
      this.notificationService.error(this.translate.instant('Please wait until simulation finished.'));
      return false;
    }
    return true;
  }

}
