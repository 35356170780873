import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-email-or-phone-number',
  templateUrl: './email-or-phone-number.component.html',
  styleUrls: ['./email-or-phone-number.component.scss']
})
export class EmailOrPhoneNumberComponent implements OnInit, OnDestroy {

  @Input() defaultSelection = "EMAIL";
  @Input() selectionList = ["EMAIL", "PHONE"];

  formGroupEmailOrPhone: FormGroup = new FormGroup({
    emailOrPhoneType: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.minLength(5), Validators.maxLength(250), Validators.email, Validators.required]),
    phone: new FormControl('', [Validators.required]),
  });
  constructor(private parent: FormGroupDirective) { }

  ngOnInit(): void {
    this.parent.form.addControl('emailOrPhoneNumber', this.formGroupEmailOrPhone);

    this.formGroupEmailOrPhone.get('emailOrPhoneType').valueChanges.subscribe((val) => {
      this.updateEmailOrPhoneTypeValidators(val);
    });

    this.formGroupEmailOrPhone.patchValue({emailOrPhoneType: this.defaultSelection});
  }

  ngOnDestroy(): void {
  }

  updateEmailOrPhoneTypeValidators(val) {
    if(val === 'EMAIL') {
      this.formGroupEmailOrPhone.get('email').enable();
      this.formGroupEmailOrPhone.get('phone').disable();
    } else {
      this.formGroupEmailOrPhone.get('phone').enable();
      this.formGroupEmailOrPhone.get('email').disable();
    }
  }
}
