/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { StorageService } from '../storage.service';
import { NotificationService } from '../../module/notification/service/notification.service';
import { UserAuthService } from '../user-auth.service';
import { AuthenticationStateService } from '../../state/authentication-state.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private isRefreshing: boolean;
    private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    excludedMessages = [
        'Subscription is not found',
        'ClinicalOutcome not found for this user'
    ];

    constructor(
        private router: Router,
        private storageService: StorageService,
        private notificationService: NotificationService,
        private authService: AuthenticationStateService,
        private userAuthService: UserAuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request)
            .pipe(catchError(res => {
                if (res.error instanceof Error) {
                } else if (res instanceof HttpErrorResponse) {
                    if (res.status === 401) {
                        if (res.error.message === 'TOKEN_EXPIRED') {
                            return this.refreshToken(request, next);
                        }
                        this.notificationService.error(res.error.message + '(' + res.error.code + ')');
                        return this.logout(res);
                    } else if (res.status === 403) {
                        if (res.error.message === 'INVALID_REFRESH_TOKEN') {
                            this.notificationService.error('Your session is expired');
                            return this.logout(res);
                        }
                        this.router.navigateByUrl('/forbidden');
                    }
                    console.log('err ==> ' + res.error.message + ' status = ' + res.status);
                }
                if (this.excludedMessages.some(x => res.error.message.includes(x))) {
                    console.log(res.error.message);
                } else {
                    this.notificationService.error(res.error.message + '(' + res.error.code + ')');
                }
                return throwError(res);
            }));
    }


    private logout(res): Observable<HttpEvent<any>> {
        this.isRefreshing = false;
        this.authService.logout();
        return throwError(res);
    }

    private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            const refresh = this.authService.getRefreshToken();
            return this.userAuthService.refreshToken(refresh).pipe(
                switchMap((res) => {
                    this.authService.notifyAccessTokenChange(res.newAccessToken);
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(res.newAccessToken);
                    // repeat failed request with new token
                    const tempReq = this.addToken(request, res.newAccessToken);
                    return next.handle(tempReq);
                })
            );
        } else {
            // wait while getting new token
            return this.refreshTokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(token => {
                    // repeat failed request with new token
                    const tempReq = this.addToken(request, token);
                    return next.handle(tempReq);
                })
            );
        }
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        if (token) {
            return request.clone({
                setHeaders: {
                    authorization: `${token}`
                }
            });
        }

        return request;
    }

}

