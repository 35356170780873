import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ClinicalOutcome } from 'src/app/shared/model/clinical-outcome';
import { ColumnDef } from 'src/app/shared/model/column-def';
import { Suppression } from 'src/app/shared/model/suppression';

@Component({
  selector: 'app-suppression-list-summary',
  templateUrl: './suppression-list-summary.component.html',
  styleUrls: ['./suppression-list-summary.component.scss']
})
export class SuppressionListSummaryComponent implements OnInit {

  @Input() clinicalOutcome: ClinicalOutcome;
  @Input() suppressionList: Suppression[];
  dataSource;
  public columnDefs: ColumnDef[] = [];
  public displayedColumns: string[] = [];


  constructor() { }

  ngOnInit(): void {
    const rowArtCodeNames = {label: 'ART Name'};
    const rowPsOverall = {label: '% viral suppression'};
    const rowOverallHivDeadRelated = {label: 'HIV related deaths (%)'};
    const rowOverallAllCausesDead = {label: 'All cause deaths (%)'};

    const colLabel = new ColumnDef({ name: 'label', displayName: '', class: 'cust-label-column'});
    this.columnDefs.push(colLabel);
    this.displayedColumns.push('label');

    let index = 0;
    for (const sup of this.suppressionList) {
      const propName = 'art' + index;
      const colArt = new ColumnDef({ name: propName, displayName: 'ART ' + (index + 1), class: ''});
      colArt.formatCellValue = (rowObj: any, path: any) => {
        const val = colArt.getProperty(rowObj, path);
        return  val ? val : 'Data unavailable';
      };

      this.columnDefs.push(colArt);
      this.displayedColumns.push(propName);

      rowArtCodeNames[propName] = sup.artCodeNames;
      rowPsOverall[propName] = sup.psOverall;
      rowOverallHivDeadRelated[propName] = sup.overallHivDead;
      rowOverallAllCausesDead[propName] = sup.overallAllCausesDead;

      index++;
    }


    const rows = [rowArtCodeNames, rowPsOverall, rowOverallHivDeadRelated, rowOverallAllCausesDead];
    this.dataSource = new MatTableDataSource(rows);

  }

}
