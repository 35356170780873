<div class="section-title-bar justify-content-between mb-16">
    <h2 class="content-box__title mb-0">{{ 'TRIAL.COMORBITY_DASHBOARD' | translate }}</h2>
    <mat-icon matTooltip="{{ 'TRIAL.COMORBITY_DASHBOARD' | translate }}">info</mat-icon>
</div>

<div class="content-box mb-24">
    <h2 class="content-box__title mb-16">{{ 'TRIAL.FILTERS' | translate }}</h2>

    <div class="form-box summary-filter-box mb-0 pt-20">
        <div fxFlex="20" fxLayout="column" fxLayoutAlign="space-around center">
            <label class="filter-label">{{ 'TRIAL.TOTAL_PATIENTS' | translate }}</label>
            <label class="patient-count-label">{{patientCount}}</label>
        </div>
        <div fxFlex="25">
            <label class="filter-label">{{ 'TRIAL.COUNTRY' | translate }}</label>
            <ng-multiselect-dropdown 
                [placeholder]="'Country'"
                [settings]="dropdownSettingsCountry" 
                [data]="dropdownListCountry"
                [(ngModel)]="selectedItemsCountry"
                (onSelect)="onItemSelectCountry($event)"
                (onDeSelect)="onDeSelectCountry($event)" 
                (onDropDownClose)="onDropDownCloseCountry()"
                (click)="dropDownSelectCountry = true">
            </ng-multiselect-dropdown>
        </div>
        <div fxFlex="25">
            <label class="filter-label">{{ 'TRIAL.STATE' | translate }}</label>
            <ng-multiselect-dropdown [placeholder]="'State'"
                [settings]="dropdownSettingsState" 
                [data]="dropdownListState"
                [(ngModel)]="selectedItemsState" 
                (onSelect)="onItemSelectState($event)"
                (onDeSelect)="onDeSelectState($event)"
                (onDropDownClose)="onDropDownCloseState()"
                (click)="dropDownSelectState = true">
            </ng-multiselect-dropdown>
        </div>
        <div fxFlex="25">
            <label class="filter-label">{{ 'TRIAL.CENTRE' | translate }}</label>
            <ng-multiselect-dropdown [placeholder]="'Centre'"
                [settings]="dropdownSettingsCentre" 
                [data]="dropdownListCentre"
                [(ngModel)]="selectedItemsCentre" 
                (onSelect)="onItemSelectCentre($event)"
                (onDeSelect)="onDeSelectCentre($event)"
                (onDropDownClose)="onDropDownCloseCentre()"
                (click)="dropDownSelectCentre = true">
            </ng-multiselect-dropdown>
        </div>
    </div>
</div>
                                    
<div class="content-box chart mb-24">
    <div class="d-flex justify-content-between">
        <h3 class="content-box__title mb-0">{{ 'TRIAL.COMORBITIES' | translate }}</h3>
        <mat-icon matTooltip="{{ 'TRIAL.COMORBITIES' | translate }}">info</mat-icon>
    </div>
    <p class="font-size-1-4 font-weight-300 mb-16">{{ 'TRIAL.PATIENTS_PERCENT' | translate }}</p>

    <div id="chartComorbidities" class="d-flex flex-column" *ngIf="chartComorbiditiesOptions">
        <apx-chart [series]="chartComorbiditiesOptions.series" 
            [chart]="chartComorbiditiesOptions.chart"
            [colors]="chartComorbiditiesOptions.colors"
            [dataLabels]="chartComorbiditiesOptions.dataLabels" 
            [plotOptions]="chartComorbiditiesOptions.plotOptions"
            [yaxis]="chartComorbiditiesOptions.yaxis" 
            [legend]="chartComorbiditiesOptions.legend" 
            [fill]="chartComorbiditiesOptions.fill"
            [stroke]="chartComorbiditiesOptions.stroke" 
            [tooltip]="chartComorbiditiesOptions.tooltip" 
            [xaxis]="chartComorbiditiesOptions.xaxis"
            [grid]="chartComorbiditiesOptions.grid">
        </apx-chart>
    </div>
</div>

<div class="content-box chart mb-24">
    <div class="d-flex justify-content-between">
        <h3 class="content-box__title mb-0">{{ 'TRIAL.COMEDICATION' | translate }}</h3>
        <mat-icon matTooltip="{{ 'TRIAL.COMEDICATION' | translate }}">info</mat-icon>
    </div>
    <p class="font-size-1-4 font-weight-300 mb-16">{{ 'TRIAL.PATIENTS_PERCENT' | translate }}</p>

    <div id="chartComedication" class="d-flex flex-column" *ngIf="chartComedicationOptions">
        <apx-chart [series]="chartComedicationOptions.series" 
            [chart]="chartComedicationOptions.chart"
            [colors]="chartComedicationOptions.colors"
            [dataLabels]="chartComedicationOptions.dataLabels" 
            [plotOptions]="chartComedicationOptions.plotOptions"
            [yaxis]="chartComedicationOptions.yaxis" 
            [legend]="chartComedicationOptions.legend" 
            [fill]="chartComedicationOptions.fill"
            [stroke]="chartComedicationOptions.stroke" 
            [tooltip]="chartComedicationOptions.tooltip" 
            [xaxis]="chartComedicationOptions.xaxis"
            [grid]="chartComedicationOptions.grid">
        </apx-chart>
    </div>
</div>

<div class="content-box chart">
    <div class="d-flex justify-content-between">
        <h3 class="content-box__title mb-0">{{ 'TRIAL.CURRENT_ANTIRETROVIRAL' | translate }}</h3>
        <mat-icon matTooltip="{{ 'TRIAL.CURRENT_ANTIRETROVIRAL' | translate }}">info</mat-icon>
    </div>
    <p class="font-size-1-4 font-weight-300 mb-16">{{ 'TRIAL.ANTIRETROVIRAL' | translate }}</p>

    <div id="chartAntiretroviral" class="d-flex flex-column" *ngIf="chartAntiretroviralOptions">
        <apx-chart [series]="chartAntiretroviralOptions.series" 
            [chart]="chartAntiretroviralOptions.chart"
            [colors]="chartAntiretroviralOptions.colors"
            [dataLabels]="chartAntiretroviralOptions.dataLabels" 
            [plotOptions]="chartAntiretroviralOptions.plotOptions"
            [yaxis]="chartAntiretroviralOptions.yaxis" 
            [legend]="chartAntiretroviralOptions.legend" 
            [fill]="chartAntiretroviralOptions.fill"
            [stroke]="chartAntiretroviralOptions.stroke" 
            [tooltip]="chartAntiretroviralOptions.tooltip" 
            [xaxis]="chartAntiretroviralOptions.xaxis"
            [grid]="chartAntiretroviralOptions.grid">
        </apx-chart>
    </div>
</div>
