import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sort-field',
  templateUrl: './sort-field.component.html',
  styleUrls: ['./sort-field.component.scss']
})
export class SortFieldComponent implements OnInit, OnDestroy {

  @Input() label = 'Sort By';
  @Input() fields = [];

  @Output() sortChanged = new EventEmitter<SortFieldModel | undefined>();

  convertedFields = [];

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    if (!this.fields || this.fields.length === 0) {
      this.fields = ['id'];
    }

    for(const field of this.fields) {
      const descField = this.createSortFieldModel(field, 'DESC');
      descField.label = this.capitalizeFirstChar(field) + ': ' + this.translate.instant('High to Low');
      this.convertedFields.push(descField);

      const ascField = this.createSortFieldModel(field, 'ASC');
      ascField.label = this.capitalizeFirstChar(field) + ': ' + this.translate.instant('Low to High');
      this.convertedFields.push(ascField);
    }

  }

  createSortFieldModel(field, direction): SortFieldModel {
    const temp = new SortFieldModel();
    temp.prop = field;
    temp.dir = direction;
    return temp;
  }

  capitalizeFirstChar(str: string) {
    const index = str.lastIndexOf('.');
    const subs = str.substring(index + 1);
    return subs.charAt(0).toUpperCase() + subs.slice(1);
  }

  ngOnDestroy(): void {
  }

  onSortSelectionChanged(sortData: SortFieldModel) {
    this.sortChanged.emit(sortData);
  }

}

export class SortFieldModel {
  prop: string;
  dir: string;
  label: string;
}
