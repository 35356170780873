/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { OrganisationBranch, OrganisationBranchFilterRequest } from '../model/organisation';
import { Page, PageableSort } from '../model/pagination';
import { createPageParams } from '../util';
import { MAX_PAGE_SIZE_FOR_UNLIMITED_LIST } from '../constants';

@Injectable({
    providedIn: 'root'
})
export class OrganisationBranchService {

    private baseUrl = '/organisation-svc/v1/organisation-branches';

    constructor(private http: HttpClient) {
    }

    createOrganisationBranch(organisationId: number, organisationBranch: OrganisationBranch): Observable<OrganisationBranch> {
        return this.http.post<OrganisationBranch>(`${environment.url}/${this.baseUrl}/organisations/${organisationId}`, organisationBranch)
        .pipe(map((res: any) => res || {}));
    }

    updateOrganisationBranch(id: number, organisationBranch: OrganisationBranch): Observable<OrganisationBranch> {
        return this.http.put<OrganisationBranch>(environment.url + `${this.baseUrl}/${id}`, organisationBranch)
            .pipe(map((res: any) => res || {}));
    }

    deleteOrgansationBranch(id: number): Observable<void> {
        return this.http.delete<any>(environment.url + `${this.baseUrl}/${id}`);
    }

    getOrganisationBranch(id: number): Observable<OrganisationBranch> {
        return this.http.get<OrganisationBranch>(environment.url + `${this.baseUrl}/${id}`)
            .pipe(map((res: any) => res || {}));
    }


    getOrganisationBranches(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string, filterRequest?: OrganisationBranchFilterRequest): Observable<Page<OrganisationBranch>> {
        let queryParams = createPageParams(page, pageSize, sort, searchTerm);
        if (filterRequest) {
            queryParams = {...queryParams, ...filterRequest};
        }
        return this.http.get<Page<OrganisationBranch>>(environment.url + `${this.baseUrl}`, { params: queryParams })
            .pipe(map((res: any) => res || {}));
    }

    getAllOrganisationBranches(organisationId: number): Observable<Page<OrganisationBranch>> {
        const filterRequest = new OrganisationBranchFilterRequest();
        filterRequest.organisationId = organisationId;
        return this.getOrganisationBranches(null, MAX_PAGE_SIZE_FOR_UNLIMITED_LIST, null, null, filterRequest);
    }

}
