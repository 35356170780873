import { PushNotificationType } from "./enum/push-notification-type";

export class PushNotification {
  id: number;
  type: string;
  content: string;
  createdAt: string;
  readAt: string;
  fromId: number;
  toId: number;
}

export class PushNotificationRequest {
  content: string;
  toId: number;
  type: string;
}

export class PushNotificationMulticastRequest {
  content: string;
  toIds: number[];
  type: string;
}

export class PushNotificationMulticastResponse {
  failedUserIds: number[];
}

export class PushNotificationTokenRequest {
  token: string;
}

export class PushNotificationReadResponse {
  unReadNotificationCount: number;
  lastReadPushNotification: PushNotification;
}

export class ChatRoomUserResponse {

  id: number;

  email: string;
  phoneNumber: string;

  firstName: string;
  lastName: string;
  photoUrl: string;
}

export class ChatRoomResponse {

  user: ChatRoomUserResponse;
  lastMessage: PushNotification;

}

export class NewChatRoom {

  userList: any[];
  type: PushNotificationType;

}

