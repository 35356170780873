import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimulationServiceUpdate, VisitTime } from '../../model/simulation';

@Component({
  selector: 'app-service-dialog',
  templateUrl: './service-dialog.component.html',
  styleUrls: ['./service-dialog.component.scss']
})
export class ServiceDialogComponent implements OnInit {

  selectedNaiveChoice: number;
  selectedExperiencedChoice: number;
  selectedPregnantChoice: number;
  selectedIacChoice: number;
  selectedTlSwitchedChoice: number;
  selectedPrepChoice: number;
  selectedPepChoice: number;

  selectedNaiveChoiceSc: number;
  selectedExperiencedChoiceSc: number;
  selectedPregnantChoiceSc: number;
  selectedIacChoiceSc: number;
  selectedTlSwitchedChoiceSc: number;
  selectedPrepChoiceSc: number;
  selectedPepChoiceSc: number;

  naiveVisitTimes: Array<VisitTime> = [];
  experiencedVisitTimes: Array<VisitTime> = [];
  pregnantVisitTimes: Array<VisitTime> = [];
  iacVisitTimes: Array<VisitTime> = [];
  tlSwitchedVisitTimes: Array<VisitTime> = [];
  prepVisitTimes: Array<VisitTime> = [];
  pepVisitTimes: Array<VisitTime> = [];

  percentageMaleControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  scPercentageMaleControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  percentageFemaleControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  scPercentageFemaleControl = new FormControl('', [Validators.min(0), Validators.max(100)]);

  percentageChildrenControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  scPercentageChildrenControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  percentageAdolescentControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  scPercentageAdolescentControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  percentageAdultsControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  scPercentageAdultsControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  percentageOlderAdultsControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  scPercentageOlderAdultsControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  percentagePregnantControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  scPercentagePregnantControl = new FormControl('', [Validators.min(0), Validators.max(100)]);

  percentagePrepControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  scPercentagePrepControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  percentagePepControl = new FormControl('', [Validators.min(0), Validators.max(100)]);
  scPercentagePepControl = new FormControl('', [Validators.min(0), Validators.max(100)]);

  diagnoseDurationNaiveControl = new FormControl('', [Validators.min(5), Validators.max(100)]);
  diagnoseDurationExperiencedControl = new FormControl('', [Validators.min(5), Validators.max(100)]);
  diagnoseDurationPreventionControl = new FormControl('', [Validators.min(5), Validators.max(100)]);
  counsellingDurationNaiveControl = new FormControl('', [Validators.min(5), Validators.max(100)]);
  counsellingDurationExperiencedControl = new FormControl('', [Validators.min(5), Validators.max(100)]);
  counsellingDurationPreventionControl = new FormControl('', [Validators.min(5), Validators.max(100)]);
  monitoringDurationNaiveControl = new FormControl('', [Validators.min(5), Validators.max(100)]);
  monitoringDurationExperiencedControl = new FormControl('', [Validators.min(5), Validators.max(100)]);
  monitoringDurationPreventionControl = new FormControl('', [Validators.min(5), Validators.max(100)]);

  scDiagnoseDurationNaiveControl = new FormControl('', [Validators.min(5), Validators.max(100)]);
  scDiagnoseDurationExperiencedControl = new FormControl('', [Validators.min(5), Validators.max(100)]);
  scDiagnoseDurationPreventionControl = new FormControl('', [Validators.min(5), Validators.max(100)]);
  scCounsellingDurationNaiveControl = new FormControl('', [Validators.min(5), Validators.max(100)]);
  scCounsellingDurationExperiencedControl = new FormControl('', [Validators.min(5), Validators.max(100)]);
  scCounsellingDurationPreventionControl = new FormControl('', [Validators.min(5), Validators.max(100)]);
  scMonitoringDurationNaiveControl = new FormControl('', [Validators.min(5), Validators.max(100)]);
  scMonitoringDurationExperiencedControl = new FormControl('', [Validators.min(5), Validators.max(100)]);
  scMonitoringDurationPreventionControl = new FormControl('', [Validators.min(5), Validators.max(100)]);

  diagnoseDurationNaive;
  diagnoseDurationExperienced;
  diagnoseDurationPrevention;

  counsellingDurationNaive;
  counsellingDurationExperienced;
  counsellingDurationPrevention;

  monitoringDurationNaive;
  monitoringDurationExperienced;
  monitoringDurationPrevention;

  scDiagnoseDurationNaive;
  scDiagnoseDurationExperienced;
  scDiagnoseDurationPrevention;

  scCounsellingDurationNaive;
  scCounsellingDurationExperienced;
  scCounsellingDurationPrevention;

  scMonitoringDurationNaive;
  scMonitoringDurationExperienced;
  scMonitoringDurationPrevention;

  serviceForm: FormGroup = new FormGroup({
    percentageMale: this.percentageMaleControl,
    scPercentageMale: this.scPercentageMaleControl,
    percentageFemale: this.percentageFemaleControl,
    scPercentageFemale: this.scPercentageFemaleControl,

    percentageChildren: this.percentageChildrenControl,
    scPercentageChildren: this.scPercentageChildrenControl,
    percentageAdolescent: this.percentageAdolescentControl,
    scPercentageAdolescent: this.scPercentageAdolescentControl,
    percentageAdults: this.percentageAdultsControl,
    scPercentageAdults: this.scPercentageAdultsControl,
    percentageOlderAdults: this.percentageOlderAdultsControl,
    scPercentageOlderAdults: this.scPercentageOlderAdultsControl,
    percentagePregnant: this.percentagePregnantControl,
    scPercentagePregnant: this.scPercentagePregnantControl,

    percentagePrep: this.percentagePrepControl,
    scPercentagePrep: this.scPercentagePrepControl,
    percentagePep: this.percentagePepControl,
    scPercentagePep: this.scPercentagePepControl,

    diagnoseDurationNaive: this.diagnoseDurationNaiveControl,
    diagnoseDurationExperienced: this.diagnoseDurationExperiencedControl,
    diagnoseDurationPrevention: this.diagnoseDurationPreventionControl,

    counsellingDurationNaive: this.counsellingDurationNaiveControl,
    counsellingDurationExperienced: this.counsellingDurationExperiencedControl,
    counsellingDurationPrevention: this.counsellingDurationPreventionControl,

    monitoringDurationNaive: this.monitoringDurationNaiveControl,
    monitoringDurationExperienced: this.monitoringDurationExperiencedControl,
    monitoringDurationPrevention: this.monitoringDurationPreventionControl,

    scDiagnoseDurationNaive: this.scDiagnoseDurationNaiveControl,
    scDiagnoseDurationExperienced: this.scDiagnoseDurationExperiencedControl,
    scDiagnoseDurationPrevention: this.scDiagnoseDurationPreventionControl,

    scCounsellingDurationNaive: this.scCounsellingDurationNaiveControl,
    scCounsellingDurationExperienced: this.scCounsellingDurationExperiencedControl,
    scCounsellingDurationPrevention: this.scCounsellingDurationPreventionControl,

    scMonitoringDurationNaive: this.scMonitoringDurationNaiveControl,
    scMonitoringDurationExperienced: this.scMonitoringDurationExperiencedControl,
    scMonitoringDurationPrevention: this.scMonitoringDurationPreventionControl,
  });

  constructor(
    public dialogRef: MatDialogRef<ServiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SimulationServiceUpdate) {
    data.allVisitTimes.forEach(visit => {
      if (visit.timeType === 'NAIVE') {
        this.naiveVisitTimes.push(visit);
      } else if (visit.timeType === 'EXPERIENCED') {
        this.experiencedVisitTimes.push(visit);
      } else if (visit.timeType === 'PREGNANT') {
        this.pregnantVisitTimes.push(visit);
      } else if (visit.timeType === 'IAC') {
        this.iacVisitTimes.push(visit);
      } else if (visit.timeType === 'TL_SWITCH') {
        this.tlSwitchedVisitTimes.push(visit);
      } else if (visit.timeType === 'PREP') {
        this.prepVisitTimes.push(visit);
      } else if (visit.timeType === 'PEP') {
        this.pepVisitTimes.push(visit);
      }
    });

    data.visitTimes.forEach(visit => {
      if (visit.timeType === 'NAIVE') {
        this.selectedNaiveChoice = visit.numberOfVisit;
      } else if (visit.timeType === 'EXPERIENCED') {
        this.selectedExperiencedChoice = visit.numberOfVisit;
      } else if (visit.timeType === 'PREGNANT') {
        this.selectedPregnantChoice = visit.numberOfVisit;
      } else if (visit.timeType === 'IAC') {
        this.selectedIacChoice = visit.numberOfVisit;
      } else if (visit.timeType === 'TL_SWITCH') {
        this.selectedTlSwitchedChoice = visit.numberOfVisit;
      } else if (visit.timeType === 'PREP') {
        this.selectedPrepChoice = visit.numberOfVisit;
      } else if (visit.timeType === 'PEP') {
        this.selectedPepChoice = visit.numberOfVisit;
      }
    });

    data.scVisitTimes.forEach(visit => {
      if (visit.timeType === 'NAIVE') {
        this.selectedNaiveChoiceSc = visit.numberOfVisit;
      } else if (visit.timeType === 'EXPERIENCED') {
        this.selectedExperiencedChoiceSc = visit.numberOfVisit;
      } else if (visit.timeType === 'PREGNANT') {
        this.selectedPregnantChoiceSc = visit.numberOfVisit;
      } else if (visit.timeType === 'IAC') {
        this.selectedIacChoiceSc = visit.numberOfVisit;
      } else if (visit.timeType === 'TL_SWITCH') {
        this.selectedTlSwitchedChoiceSc = visit.numberOfVisit;
      } else if (visit.timeType === 'PREP') {
        this.selectedPrepChoiceSc = visit.numberOfVisit;
      } else if (visit.timeType === 'PEP') {
        this.selectedPepChoiceSc = visit.numberOfVisit;
      }
    });

  }

  setVisitTime(selected: VisitTime, type: string) {
    this.data.visitTimes.forEach(visit => {
      if (visit.timeType === type) {
        visit.id = selected.id;
        visit.continuous = selected.continuous;
        visit.controlVisits = selected.controlVisits;
        visit.numberOfVisit = selected.numberOfVisit;
        visit.timeType = selected.timeType;
        visit.waitingTime = selected.waitingTime;
      }
    });
  }

  setVisitTimeSc(selected: VisitTime, type: string) {
    this.data.scVisitTimes.forEach(visit => {
      if (visit.timeType === type) {
        visit.id = selected.id;
        visit.continuous = selected.continuous;
        visit.controlVisits = selected.controlVisits;
        visit.numberOfVisit = selected.numberOfVisit;
        visit.timeType = selected.timeType;
        visit.waitingTime = selected.waitingTime;
      }
    });
  }

  onSelectNaive(value) {
    this.naiveVisitTimes.forEach(naive => {
      if (naive.numberOfVisit === value) {
        this.setVisitTime(naive, 'NAIVE');
      }
    });
  }

  onSelectExperienced(value) {
    this.experiencedVisitTimes.forEach(experienced => {
      if (experienced.numberOfVisit === value) {
        this.setVisitTime(experienced, 'EXPERIENCED');
      }
    });
  }

  onSelectPregnant(value) {
    this.pregnantVisitTimes.forEach(pregnant => {
      if (pregnant.numberOfVisit === value) {
        this.setVisitTime(pregnant, 'PREGNANT');
      }
    });
  }

  onSelectIac(value) {
    this.iacVisitTimes.forEach(iac => {
      if (iac.numberOfVisit === value) {
        this.setVisitTime(iac, 'IAC');
      }
    });
  }

  onSelectTlSwitch(value) {
    this.tlSwitchedVisitTimes.forEach(tlSwitched => {
      if (tlSwitched.numberOfVisit === value) {
        this.setVisitTime(tlSwitched, 'TL_SWITCH');
      }
    });
  }

  onSelectPrep(value) {
    this.prepVisitTimes.forEach(prep => {
      if (prep.numberOfVisit === value) {
        this.setVisitTime(prep, 'PREP');
      }
    });
  }

  onSelectPep(value) {
    this.pepVisitTimes.forEach(pep => {
      if (pep.numberOfVisit === value) {
        this.setVisitTime(pep, 'PEP');
      }
    });
  }

  onSelectNaiveSc(value) {
    this.naiveVisitTimes.forEach(naive => {
      if (naive.numberOfVisit === value) {
        this.setVisitTimeSc(naive, 'NAIVE');
      }
    });
  }

  onSelectExperiencedSc(value) {
    this.experiencedVisitTimes.forEach(experienced => {
      if (experienced.numberOfVisit === value) {
        this.setVisitTimeSc(experienced, 'EXPERIENCED');
      }
    });
  }

  onSelectPregnantSc(value) {
    this.pregnantVisitTimes.forEach(pregnant => {
      if (pregnant.numberOfVisit === value) {
        this.setVisitTimeSc(pregnant, 'PREGNANT');
      }
    });
  }

  onSelectIacSc(value) {
    this.iacVisitTimes.forEach(iac => {
      if (iac.numberOfVisit === value) {
        this.setVisitTimeSc(iac, 'IAC');
      }
    });
  }

  onSelectTlSwitchSc(value) {
    this.tlSwitchedVisitTimes.forEach(tlSwitched => {
      if (tlSwitched.numberOfVisit === value) {
        this.setVisitTimeSc(tlSwitched, 'TL_SWITCH');
      }
    });
  }

  onSelectPrepSc(value) {
    this.prepVisitTimes.forEach(prep => {
      if (prep.numberOfVisit === value) {
        this.setVisitTimeSc(prep, 'PREP');
      }
    });
  }

  onSelectPepSc(value) {
    this.pepVisitTimes.forEach(pep => {
      if (pep.numberOfVisit === value) {
        this.setVisitTimeSc(pep, 'PEP');
      }
    });
  }

  ngOnInit(): void {
    this.diagnoseDurationNaive = (this.data.minDiagnoseDurationNaive + this.data.maxDiagnoseDurationNaive) / 2;
    this.diagnoseDurationExperienced = (this.data.minDiagnoseDurationExperienced + this.data.maxDiagnoseDurationExperienced) / 2;
    this.diagnoseDurationPrevention = (this.data.minDiagnoseDurationPrevention + this.data.maxDiagnoseDurationPrevention) / 2;

    this.counsellingDurationNaive = (this.data.minCounsellingDurationNaive + this.data.maxCounsellingDurationNaive) / 2;
    this.counsellingDurationExperienced = (this.data.minCounsellingDurationExperienced + this.data.maxCounsellingDurationExperienced) / 2;
    this.counsellingDurationPrevention = (this.data.minCounsellingDurationPrevention + this.data.maxCounsellingDurationPrevention) / 2;

    this.monitoringDurationNaive = (this.data.minMonitoringDurationNaive + this.data.maxMonitoringDurationNaive) / 2;
    this.monitoringDurationExperienced = (this.data.minMonitoringDurationExperienced + this.data.maxMonitoringDurationExperienced) / 2;
    this.monitoringDurationPrevention = (this.data.minMonitoringDurationPrevention + this.data.maxMonitoringDurationPrevention) / 2;

    this.scDiagnoseDurationNaive = (this.data.scMinDiagnoseDurationNaive + this.data.scMaxDiagnoseDurationNaive) / 2;
    this.scDiagnoseDurationExperienced = (this.data.scMinDiagnoseDurationExperienced + this.data.scMaxDiagnoseDurationExperienced) / 2;
    this.scDiagnoseDurationPrevention = (this.data.scMinDiagnoseDurationPrevention + this.data.scMaxDiagnoseDurationPrevention) / 2;

    this.scCounsellingDurationNaive = (this.data.scMinCounsellingDurationNaive + this.data.scMaxCounsellingDurationNaive) / 2;
    this.scCounsellingDurationExperienced = (this.data.scMinCounsellingDurationExperienced
      + this.data.scMaxCounsellingDurationExperienced) / 2;
    this.scCounsellingDurationPrevention = (this.data.scMinCounsellingDurationPrevention
      + this.data.scMaxCounsellingDurationPrevention) / 2;

    this.scMonitoringDurationNaive = (this.data.scMinMonitoringDurationNaive + this.data.scMaxMonitoringDurationNaive) / 2;
    this.scMonitoringDurationExperienced = (this.data.scMinMonitoringDurationExperienced
      + this.data.scMaxMonitoringDurationExperienced) / 2;
    this.scMonitoringDurationPrevention = (this.data.scMinMonitoringDurationPrevention + this.data.scMaxMonitoringDurationPrevention) / 2;
  }

  onDurationChange(event): void {
    const diagnoseDurationNaiveDiff = parseInt((this.diagnoseDurationNaive * 0.2).toFixed(0), 10);
    this.data.minDiagnoseDurationNaive = this.diagnoseDurationNaive - diagnoseDurationNaiveDiff;
    this.data.maxDiagnoseDurationNaive = this.diagnoseDurationNaive + diagnoseDurationNaiveDiff;

    const diagnoseDurationExperiencedDiff = parseInt((this.diagnoseDurationExperienced * 0.2).toFixed(0), 10);
    this.data.minDiagnoseDurationExperienced = this.diagnoseDurationExperienced - diagnoseDurationExperiencedDiff;
    this.data.maxDiagnoseDurationExperienced = this.diagnoseDurationExperienced + diagnoseDurationExperiencedDiff;

    const diagnoseDurationPreventionDiff = parseInt((this.diagnoseDurationPrevention * 0.2).toFixed(0), 10);
    this.data.minDiagnoseDurationPrevention = this.diagnoseDurationPrevention - diagnoseDurationPreventionDiff;
    this.data.maxDiagnoseDurationPrevention = this.diagnoseDurationPrevention + diagnoseDurationPreventionDiff;

    const counsellingDurationNaiveDiff = parseInt((this.counsellingDurationNaive * 0.2).toFixed(0), 10);
    this.data.minCounsellingDurationNaive = this.counsellingDurationNaive - counsellingDurationNaiveDiff;
    this.data.maxCounsellingDurationNaive = this.counsellingDurationNaive + counsellingDurationNaiveDiff;

    const counsellingDurationExperiencedDiff = parseInt((this.counsellingDurationExperienced * 0.2).toFixed(0), 10);
    this.data.minCounsellingDurationExperienced = this.counsellingDurationExperienced - counsellingDurationExperiencedDiff;
    this.data.maxCounsellingDurationExperienced = this.counsellingDurationExperienced + counsellingDurationExperiencedDiff;

    const counsellingDurationPreventionDiff = parseInt((this.counsellingDurationPrevention * 0.2).toFixed(0), 10);
    this.data.minCounsellingDurationPrevention = this.counsellingDurationPrevention - counsellingDurationPreventionDiff;
    this.data.maxCounsellingDurationPrevention = this.counsellingDurationPrevention + counsellingDurationPreventionDiff;

    const monitoringDurationNaiveDiff = parseInt((this.monitoringDurationNaive * 0.2).toFixed(0), 10);
    this.data.minMonitoringDurationNaive = this.monitoringDurationNaive - monitoringDurationNaiveDiff;
    this.data.maxMonitoringDurationNaive = this.monitoringDurationNaive + monitoringDurationNaiveDiff;

    const monitoringDurationExperiencedDiff = parseInt((this.monitoringDurationExperienced * 0.2).toFixed(0), 10);
    this.data.minMonitoringDurationExperienced = this.monitoringDurationExperienced - monitoringDurationExperiencedDiff;
    this.data.maxMonitoringDurationExperienced = this.monitoringDurationExperienced + monitoringDurationExperiencedDiff;

    const monitoringDurationPreventionDiff = parseInt((this.monitoringDurationPrevention * 0.2).toFixed(0), 10);
    this.data.minMonitoringDurationPrevention = this.monitoringDurationPrevention - monitoringDurationPreventionDiff;
    this.data.maxMonitoringDurationPrevention = this.monitoringDurationPrevention + monitoringDurationPreventionDiff;


    const scDiagnoseDurationNaiveDiff = parseInt((this.scDiagnoseDurationNaive * 0.2).toFixed(0), 10);
    this.data.scMinDiagnoseDurationNaive = this.scDiagnoseDurationNaive - scDiagnoseDurationNaiveDiff;
    this.data.scMaxDiagnoseDurationNaive = this.scDiagnoseDurationNaive + scDiagnoseDurationNaiveDiff;

    const scDiagnoseDurationExperiencedDiff = parseInt((this.scDiagnoseDurationExperienced * 0.2).toFixed(0), 10);
    this.data.scMinDiagnoseDurationExperienced = this.scDiagnoseDurationExperienced - scDiagnoseDurationExperiencedDiff;
    this.data.scMaxDiagnoseDurationExperienced = this.scDiagnoseDurationExperienced + scDiagnoseDurationExperiencedDiff;

    const scDiagnoseDurationPreventionDiff = parseInt((this.scDiagnoseDurationPrevention * 0.2).toFixed(0), 10);
    this.data.scMinDiagnoseDurationPrevention = this.scDiagnoseDurationPrevention - scDiagnoseDurationPreventionDiff;
    this.data.scMaxDiagnoseDurationPrevention = this.scDiagnoseDurationPrevention + scDiagnoseDurationPreventionDiff;

    const scCounsellingDurationNaiveDiff = parseInt((this.scCounsellingDurationNaive * 0.2).toFixed(0), 10);
    this.data.scMinCounsellingDurationNaive = this.scCounsellingDurationNaive - scCounsellingDurationNaiveDiff;
    this.data.scMaxCounsellingDurationNaive = this.scCounsellingDurationNaive + scCounsellingDurationNaiveDiff;

    const scCounsellingDurationExperiencedDiff = parseInt((this.scCounsellingDurationExperienced * 0.2).toFixed(0), 10);
    this.data.scMinCounsellingDurationExperienced = this.scCounsellingDurationExperienced - scCounsellingDurationExperiencedDiff;
    this.data.scMaxCounsellingDurationExperienced = this.scCounsellingDurationExperienced + scCounsellingDurationExperiencedDiff;

    const scCounsellingDurationPreventionDiff = parseInt((this.scCounsellingDurationPrevention * 0.2).toFixed(0), 10);
    this.data.scMinCounsellingDurationPrevention = this.scCounsellingDurationPrevention - scCounsellingDurationPreventionDiff;
    this.data.scMaxCounsellingDurationPrevention = this.scCounsellingDurationPrevention + scCounsellingDurationPreventionDiff;

    const scMonitoringDurationNaiveDiff = parseInt((this.scMonitoringDurationNaive * 0.2).toFixed(0), 10);
    this.data.scMinMonitoringDurationNaive = this.scMonitoringDurationNaive - scMonitoringDurationNaiveDiff;
    this.data.scMaxMonitoringDurationNaive = this.scMonitoringDurationNaive + scMonitoringDurationNaiveDiff;

    const scMonitoringDurationExperiencedDiff = parseInt((this.scMonitoringDurationExperienced * 0.2).toFixed(0), 10);
    this.data.scMinMonitoringDurationExperienced = this.scMonitoringDurationExperienced - scMonitoringDurationExperiencedDiff;
    this.data.scMaxMonitoringDurationExperienced = this.scMonitoringDurationExperienced + scMonitoringDurationExperiencedDiff;

    const scMonitoringDurationPreventionDiff = parseInt((this.scMonitoringDurationPrevention * 0.2).toFixed(0), 10);
    this.data.scMinMonitoringDurationPrevention = this.scMonitoringDurationPrevention - scMonitoringDurationPreventionDiff;
    this.data.scMaxMonitoringDurationPrevention = this.scMonitoringDurationPrevention + scMonitoringDurationPreventionDiff;

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isSimArtTotalTrue() {
    return (this.data.percentageNrti2Nrti
      + this.data.percentagePi2Nrti
      + this.data.percentageIn2Nrti
      + this.data.percentageEi2Nrti
      + this.data.percentage2dr === 100);
  }

  isScenArtTotalTrue() {
    return (this.data.scPercentageNrti2Nrti
      + this.data.scPercentagePi2Nrti
      + this.data.scPercentageIn2Nrti
      + this.data.scPercentageEi2Nrti
      + this.data.scPercentage2dr === 100);
  }

  isSimPreventionTotalTrue() {
    return (this.data.percentagePep + this.data.percentagePrep === 100);
  }

  isScenPreventionTotalTrue() {
    return (this.data.scPercentagePep + this.data.scPercentagePrep === 100);
  }

  isSimGenderTotalTrue() {
    return (this.data.percentageMale + this.data.percentageFemale === 100);
  }

  isScenGenderTotalTrue() {
    return (this.data.scPercentageMale + this.data.scPercentageFemale === 100);
  }

  isSimAgeTotalTrue() {
    return (this.data.percentageChildren + this.data.percentageAdolescent + this.data.percentageAdults
      + this.data.percentageOlderAdults === 100);
  }

  isScenAgeTotalTrue() {
    return (this.data.scPercentageChildren + this.data.scPercentageAdolescent + this.data.scPercentageAdults
      + this.data.scPercentageOlderAdults === 100);
  }

  public isInfoValid() {
    return this.serviceForm.valid
      && this.isSimArtTotalTrue()
      && this.isScenArtTotalTrue()
      && this.isSimPreventionTotalTrue()
      && this.isScenPreventionTotalTrue()
      && this.isSimAgeTotalTrue()
      && this.isScenAgeTotalTrue()
      && this.isSimGenderTotalTrue()
      && this.isScenGenderTotalTrue();
  }

}
