import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimulationStaffUpdate } from '../../model/simulation';

@Component({
  selector: 'app-staff-dialog',
  templateUrl: './staff-dialog.component.html',
  styleUrls: ['./staff-dialog.component.scss']
})
export class StaffDialogComponent implements OnInit {

  doctorNumberControl = new FormControl('', [Validators.min(0), Validators.max(1000)]);
  scDoctorNumberControl = new FormControl('', [Validators.min(0), Validators.max(1000)]);
  generalNurseNumberControl = new FormControl('', [Validators.min(0), Validators.max(1000)]);
  scGeneralNurseNumberControl = new FormControl('', [Validators.min(0), Validators.max(1000)]);
  hivSpecialistNurseNumberControl = new FormControl('', [Validators.min(0), Validators.max(1000)]);
  scHivSpecialistNurseNumberControl = new FormControl('', [Validators.min(0), Validators.max(1000)]);
  counselorNumberControl = new FormControl('', [Validators.min(0), Validators.max(1000)]);
  scCounselorNumberControl = new FormControl('', [Validators.min(0), Validators.max(1000)]);
  dieticianNumberControl = new FormControl('', [Validators.min(0), Validators.max(1000)]);
  scDieticianNumberControl = new FormControl('', [Validators.min(0), Validators.max(1000)]);
  medicalLabScientistNumberControl = new FormControl('', [Validators.min(0), Validators.max(1000)]);
  scMedicalLabScientistNumberControl = new FormControl('', [Validators.min(0), Validators.max(1000)]);
  pharmacistNumberControl = new FormControl('', [Validators.min(0), Validators.max(1000)]);
  scPharmacistNumberControl = new FormControl('', [Validators.min(0), Validators.max(1000)]);
  allocationControl = new FormControl('', [Validators.min(0), Validators.max(1000)]);
  scAllocationControl = new FormControl('', [Validators.min(0), Validators.max(1000)]);

  staffForm: FormGroup = new FormGroup({
    doctorNumber: this.doctorNumberControl,
    scDoctorNumber: this.scDoctorNumberControl,
    generalNurseNumber: this.generalNurseNumberControl,
    scGeneralNurseNumber: this.scGeneralNurseNumberControl,
    hivSpecialistNurseNumber: this.hivSpecialistNurseNumberControl,
    scHivSpecialistNurseNumber: this.scHivSpecialistNurseNumberControl,
    counselorNumber: this.counselorNumberControl,
    scCounselorNumber: this.scCounselorNumberControl,
    dieticianNumber: this.dieticianNumberControl,
    scDieticianNumber: this.scDieticianNumberControl,
    medicalLabScientistNumber: this.medicalLabScientistNumberControl,
    scMedicalLabScientistNumber: this.scMedicalLabScientistNumberControl,
    pharmacistNumber: this.pharmacistNumberControl,
    scPharmacistNumber: this.scPharmacistNumberControl,
    allocation: this.allocationControl,
    scAllocationNumber: this.scAllocationControl
  });

  constructor(
    public dialogRef: MatDialogRef<StaffDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SimulationStaffUpdate) {}

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public isInfoValid() {
    return this.staffForm.valid;
  }

}
