<div fxLayout="row" fxLayoutAlign="center start">
    <div fxFlex.gt-md="100" fxFlex.gt-sm="100" fxFlex="100">
      <mat-card class="mat-card-top chat-app">
        <mat-sidenav-container
          [ngClass]="{ 'side-panel-opened': sidePanelOpened, 'side-panel-closed': !sidePanelOpened }">
          
          <mat-sidenav #chatnav [mode]="isOver() ? 'over' : 'side'" [opened]="!isOver()" (open)="sidePanelOpened = true"
            (close)="sidePanelOpened = false">
            <app-chat-contact-list [from]="from" [fromId]="fromId"
              (changeSelectedRoom)="onSelectedRoomChange($event)"
              (newChatRoom)="onNewChatRoom($event)"
              >
            </app-chat-contact-list>
          </mat-sidenav>
          
          <div class="chat-drawer-content-container">

            <mat-toolbar class="bg-success no-shadow chat-right-panel chat-to-user-toolbar" color="warn">
              <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center" *ngIf="selectedChatRoomUser || newChatRoom">
                <button (click)="chatnav.toggle()" class="mr-1" mat-icon-button>
                  <mat-icon>short_text</mat-icon>
                </button>
                <span class="top-avatar m-r-10">
                  <img src="{{getOrDefaultPhoto(selectedChatRoomUser?.photoUrl)}}" class="img-circle">
                </span>
                <div fxFlex>
                  <div class="font-14">{{getChatRoomUserName() | slice:0:50}}</div>
                </div>
                <!-- <button [matMenuTriggerFor]="them" mat-icon-button class="ml-1" *ngIf="selectedChatRoomUser">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #them="matMenu" x-position="before">
                  <button mat-menu-item>Contact info</button>
                  <button mat-menu-item>Delete chat</button>
                </mat-menu> -->
              </div>
            </mat-toolbar>
  
            
            <mat-card-content class="chat-middle-box" #messageListContainer>
              <div>
                <ng-container *ngFor="let message of pushNotificationList">
                  <app-chat-message [from]="from" 
                    [chatRoomUser]="selectedChatRoomUser" 
                    [message]="message" #chatMessageElement>
                  </app-chat-message>
                </ng-container>
              </div>
            </mat-card-content>
            
            
            <mat-card-actions class="chat-footer">
              <div fxLayout="row" layout-align="start center">
                <mat-form-field class="mr-1 ml-1" fxFlex>
                  <input matInput [(ngModel)]="messageText" [disabled]="!selectedChatRoomUser && !newChatRoom" 
                  placeholder="Send message" (keyup)="onTyping($event)" (keyup.enter)="onEnter($event)">
                </mat-form-field>
                <button mat-fab color="warn" (click)="onSendMessage()" [disabled]="!messageText">
                  <mat-icon>send</mat-icon>
                </button>
              </div>
            </mat-card-actions>

          </div>
          
        </mat-sidenav-container>
      </mat-card>
    </div>
  </div>