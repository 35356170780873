<!-- ============================================================== -->
<!-- Simple four boxes Row -->
<!-- ============================================================== -->
<!--<app-top-card></app-top-card>-->
<!-- ============================================================== -->
<!-- Charts -->
<!-- ============================================================== -->
<!-- <div fxLayout="row wrap"> -->
  <!-- Column-->
  <!--<div fxFlex.gt-lg="75" fxFlex.gt-md="60" fxFlex.gt-xs="100" fxFlex="100">
      <app-sales-overview></app-sales-overview>
  </div>-->
  <!-- Column-->
  <!--<div fxFlex.gt-lg="25" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
      <app-visitor></app-visitor>
  </div>-->
  <!-- Column-->
<!-- </div> -->
<!-- ============================================================== -->
<!-- Chart boxes -->
<!-- ============================================================== -->
<!--<app-income-expenss></app-income-expenss>-->
<!-- ============================================================== -->
<!-- Image and chart -->
<!-- ============================================================== -->
<div class="dashboard-cont">


  <!-- Column-->
  <div class="content-box" *ngxPermissionsOnly="['SYSTEM_ADMINISTRATOR','SMART_HIV_CLINICIAN']">
    <!--<app-posts></app-posts>-->

    <h2 class="content-box__title mb-32">SmartHIV Clinician</h2>

    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="40" fxFlex.gt-xs="100" fxFlex="100">
        <a href="/smarthiv-clinician/clinician-form"><img class="" style="width: 100%;padding-right: 10px;padding-left: 10px;" src="assets/images/smarthiv-clinician_800x800.png" alt=""></a>
      </div>
      <div fxFlex.gt-sm="60" fxFlex.gt-xs="100" fxFlex="100">
        <iframe src="https://www.youtube.com/embed/3NubnEnvsPM" frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                style="width: 100%;height: 100%;"></iframe>
      </div>
    </div>
  </div>

  <div class="content-box" *ngxPermissionsOnly="['SYSTEM_ADMINISTRATOR','SMART_HIV_MANAGER']" >
    <!--<app-posts></app-posts>-->
    <h2 class="content-box__title mb-32">SmartHIV Manager</h2>

    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="40" fxFlex.gt-xs="100" fxFlex="100">
        <a href="/smarthiv-manager/manager-form"><img class="" style="width: 100%;padding-right: 10px;padding-left: 10px;" src="assets/images/smarthiv-manager_800x800.png" alt=""></a>
      </div>
      <div fxFlex.gt-sm="60" fxFlex.gt-xs="100" fxFlex="100">
        <iframe src="https://www.youtube.com/embed/dVqLUJskbh0" frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                style="width: 100%;height: 100%;"></iframe>
      </div>
    </div>
  </div>

  <!-- Column-->
  <div class="content-box" *ngxPermissionsOnly="['SYSTEM_ADMINISTRATOR','SMART_HIV_AUDITLYTICS']" >
    <!--<app-posts></app-posts>-->
    <h2 class="content-box__title mb-32">SmartHIV Auditlytics</h2>

    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="25" fxFlex.gt-xs="100" fxFlex="100">
      </div>
      <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
        <a href="/smarthiv-auditlytics/audit-filter"> <img class="" style="width: 100%;padding-right: 10px;padding-left: 10px;" src="assets/images/icon_smart_hiv_auditlytics.png" alt=""></a>
      </div>
      <div fxFlex.gt-sm="25" fxFlex.gt-xs="100" fxFlex="100">
      </div>
      <!--<div fxFlex.gt-sm="60" fxFlex.gt-xs="100" fxFlex="100">
        <div>
          <div class="title">Under Development</div>
          <div class="info">Ready for Q3 2021</div>
        </div>
    </div>-->
    </div>
  </div>

  <div class="content-box" *ngxPermissionsOnly="['SYSTEM_ADMINISTRATOR','SMART_HIV_TRIALIST']" >
    <!--<app-posts></app-posts>-->
    <h2 class="content-box__title mb-32">SmartHIV Trialist</h2>

    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="25" fxFlex.gt-xs="100" fxFlex="100">
      </div>
      <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
        <a href="/smarthiv-trialist/trial-filter"><img class="" style="width: 100%;padding-right: 10px;padding-left: 10px;" src="assets/images/smarthiv-trialist_800x800.png" alt=""></a>
      </div>
      <div fxFlex.gt-sm="25" fxFlex.gt-xs="100" fxFlex="100">
      </div>
      <!--<div fxFlex.gt-sm="60" fxFlex.gt-xs="100" fxFlex="100">
          <div>
            <div class="title">Under Development</div>
            <div class="info">Ready for Q3 2021</div>
          </div>
      </div>-->
    </div>
  </div>

  <!-- Column-->
  <!--
      <div fxFlex.gt-lg="75" fxFlex.gt-md="60" fxFlex.gt-xs="100" fxFlex="100">
          <app-newsletter></app-newsletter>
      </div>
  -->

</div>
