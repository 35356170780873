/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OtpType } from 'src/app/shared/model/enum/otp-type';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-otp-generate',
  templateUrl: './otp-generate.component.html',
  styleUrls: ['./otp-generate.component.scss']
})
export class OtpGenerateComponent implements OnInit {

  captcha: string;
  recaptchaSiteKey = environment.recaptchaSiteKey;

  private returnUrl: string;
  private otpType: string;

  formGroup: FormGroup = new FormGroup({
    captcha:  new FormControl('', [Validators.required])
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private authenticationStateService: AuthenticationStateService,
    private userAuthService: UserAuthService
    ) { }

  ngOnInit() {
    const urlParam = 'returnUrl';
    const otpType = 'otpType';
    this.returnUrl = this.route.snapshot.queryParams[urlParam] || '/';
    const tempType = this.route.snapshot.queryParams[otpType] || OtpType.LOGIN;
    this.otpType = OtpType[tempType];
    const isAuthenticated = this.authenticationStateService.isAuthenticated();
    if (isAuthenticated) {
      this.router.navigateByUrl(this.returnUrl);
    }
  }

  generateOtp() {
    if (this.formGroup.valid) {
      const emailOrPhoneNumberControl = this.formGroup.get('emailOrPhoneNumber');
      const emailOrPhoneNumber = emailOrPhoneNumberControl.get('emailOrPhoneType').value === 'EMAIL' ? emailOrPhoneNumberControl.get('email').value : emailOrPhoneNumberControl.get('phone').value;
      this.userAuthService.regenerateOtp(emailOrPhoneNumber, this.otpType, this.captcha).subscribe(data => {
        if (data) {
          this.notificationService.success(this.translate.instant('OTP_GENERATE.GENERATION_MESSAGE.SUCCESS'));
          this.goToOtpConfirm(data.email ? data.email : data.phoneNumber);
        }
      }, (error) => {
        this.formGroup.get('captcha').reset();
        console.log('Error on generate otp confirmation ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('OTP_GENERATE.GENERATION_MESSAGE.ERROR'));
      });
    }
  }

  goToOtpConfirm(emailOrPhoneNumber) {
    this.router.navigate(['user/otp-confirm', this.otpType, emailOrPhoneNumber], { queryParams: { returnUrl: this.returnUrl }});
  }

  captchaResolved(capthcaResult) {
    this.captcha = capthcaResult;
  }

}
