export class ColumnDef {
    name: string;
    displayName: string;
    class: string;
    checkbox: boolean;
    public constructor(data: any = {}) {
        this.name = data.name || '';
        this.displayName = data.displayName || '';
        this.class = data.class || '';
        this.checkbox = data.checkbox || false;
    }
    public formatCellValue(rowObj: any, path: any) {
        return this.getProperty(rowObj, path);
    }

    public getProperty(rowObj: any, path: any) {
        return path.split('.').reduce((o, p) => o && o[p], rowObj);
    }
}
