import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { Role } from './shared/model/enum/role';
import { LoginResponse } from './shared/model/user-profile';
import { NotificationService } from './shared/module/notification/service/notification.service';
import { StorageService } from './shared/services/storage.service';
import { AuthenticationStateService } from './shared/state/authentication-state.service';
import { RouteStateService } from './shared/state/route-state.services';
import { rolesToArray } from './shared/util';
import { DocumentStateService } from './shared/state/document-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'BSmartlytics';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private permissionsService: NgxPermissionsService,
    private rolesService: NgxRolesService,
    private storageService: StorageService,
    private authenticationStateService: AuthenticationStateService,
    private routeStateService: RouteStateService,
    private notificationService: NotificationService,
    private documentStateService: DocumentStateService
  ) { }

  ngOnInit() {
    const language = this.storageService.getLanguage() ? this.storageService.getLanguage() : 'en';
    this.storageService.setLanguage(language);
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    this.routeStateService.addNavigationListener();
    this.documentStateService.addVisibilityChangeListener();
    this.registerForLoginChanges();
  }

  registerForLoginChanges() {
    this.authenticationStateService.getLoginChangeObservable().subscribe(info => {
      this.handleLoginChangesForRole(info);
    });
  }

  handleLoginChangesForRole(info: LoginResponse) {
    if (info?.roles) {
      const roleNumber = info.roles;
      const roles: Role[] = rolesToArray(roleNumber);

      this.rolesService.flushRolesAndPermissions();
      this.permissionsService.flushPermissions();

      for (const role of roles) {
        const roleStr = Role[role];
        this.rolesService.addRole(roleStr, () => true);
      }
    } else {
      this.rolesService.flushRolesAndPermissions();
      this.permissionsService.flushPermissions();
    }
  }

}
