/* eslint-disable max-len */
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { AdverseEventService } from 'src/app/shared/services/adverse-event.service';
import { AdverseEventUploadComponent } from '../../component/adverse-event-upload/adverse-event-upload.component';

@Component({
  selector: 'app-adverse-event-list',
  templateUrl: './adverse-event-list.component.html',
  styleUrls: ['./adverse-event-list.component.scss']
})
export class AdverseEventListComponent extends BaseListComponent implements OnInit {
  @ViewChild('idTemplate', { static: true }) idTemplate: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private adverseEventService: AdverseEventService
  ) {
    super();
  }

  ngOnInit(): void {
    this.allColumnList = [
      {
        prop: 'id',
        name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.ID'),
        cellTemplate: this.idTemplate,
        width: 100,
        maxWidth: 100
      },
      { prop: 'eventCategory', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.EVENT_CATEGORY'), cellClass: '' },
      { prop: 'adverseEvents', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.ADVERSE_EVENTS'), cellClass: '' },
      { prop: 'code0002', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0002'), cellClass: '' },
      { prop: 'code0002Code0010', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0002CODE0010'), cellClass: '' },
      { prop: 'code0002Code0012', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0002CODE0012'), cellClass: '' },
      { prop: 'code0002Code0004Code0010', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0002CODE0004CODE0010'), cellClass: '' },
      { prop: 'code0002Code0004Code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0002CODE0004CODE0008'), cellClass: '' },
      { prop: 'code0002Code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0002CODE0008'), cellClass: '' },
      { prop: 'code0001', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0001'), cellClass: '' },
      { prop: 'code0001Code0002', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0001CODE0002'), cellClass: '' },
      { prop: 'code0001Code0002Code0010', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0001CODE0002CODE0010'), cellClass: '' },
      { prop: 'code0001Code0002Code0019', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0001CODE0002CODE0019'), cellClass: '' },
      { prop: 'code0001Code0002Code0011', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0001CODE0002CODE0011'), cellClass: '' },
      { prop: 'code0025', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0025'), cellClass: '' },
      { prop: 'code0006', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0006'), cellClass: '' },
      { prop: 'code0009', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0009'), cellClass: '' },
      { prop: 'code0009Code0005Code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0009CODE0005CODE0008'), cellClass: '' },
      { prop: 'code0010', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0010'), cellClass: '' },
      { prop: 'code0020', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0020'), cellClass: '' },
      { prop: 'code0020Code0005Code0007', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0020CODE0005CODE0007'), cellClass: '' },
      { prop: 'code0020Code0005Code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0020CODE0005CODE0008'), cellClass: '' },
      { prop: 'code0027', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0027'), cellClass: '' },
      { prop: 'code0039', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0039'), cellClass: '' },
      { prop: 'code0040', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0040'), cellClass: '' },
      { prop: 'code0012', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0012'), cellClass: '' },
      { prop: 'code0012Code0010', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0012CODE0010'), cellClass: '' },
      { prop: 'code0028', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0028'), cellClass: '' },
      { prop: 'code0028Code0002Code0011', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0028CODE0002CODE0011'), cellClass: '' },
      { prop: 'code0042', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0042'), cellClass: '' },
      { prop: 'code0021', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0021'), cellClass: '' },
      { prop: 'code0021Code0002Code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0021CODE0002CODE0008'), cellClass: '' },
      { prop: 'code0041', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0041'), cellClass: '' },
      { prop: 'code0013', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0013'), cellClass: '' },
      { prop: 'code0013Code0005Code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0013CODE0005CODE0008'), cellClass: '' },
      { prop: 'code0015', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0015'), cellClass: '' },
      { prop: 'code0015Code0016', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0015CODE0016'), cellClass: '' },
      { prop: 'code0019', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0019'), cellClass: '' },
      { prop: 'code0019Code0002', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0019CODE0002'), cellClass: '' },
      { prop: 'code0019Code0002Code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0019CODE0002CODE0008'), cellClass: '' },
      { prop: 'code0019Code0001Code0002', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0019CODE0001CODE0002'), cellClass: '' },
      { prop: 'code0019Code0005Code0007', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0019CODE0005CODE0007'), cellClass: '' },
      { prop: 'code0019Code0014', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0019CODE0014'), cellClass: '' },
      { prop: 'code0011', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0011'), cellClass: '' },
      { prop: 'code0011Code0002Code0010', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0011CODE0002CODE0010'), cellClass: '' },
      { prop: 'code0011Code0002Code0012', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0011CODE0002CODE0012'), cellClass: '' },
      { prop: 'code0011Code0002Code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0011CODE0002CODE0008'), cellClass: '' },
      { prop: 'code0011Code0010', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0011CODE0010'), cellClass: '' },
      { prop: 'code0011Code0005Code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0011CODE0005CODE0008'), cellClass: '' },
      { prop: 'code0011Code0004', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0011CODE0004'), cellClass: '' },
      { prop: 'code0032', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0032'), cellClass: '' },
      { prop: 'code0017Code0002Code0007', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0017CODE0002CODE0007'), cellClass: '' },
      { prop: 'code0017Code0002Code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0017CODE0002CODE0008'), cellClass: '' },
      { prop: 'code0017Code0005Code0007', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0017CODE0005CODE0007'), cellClass: '' },
      { prop: 'code0005', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0005'), cellClass: '' },
      { prop: 'code0005Code0010Code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0005CODE0010CODE0008'), cellClass: '' },
      { prop: 'code0005Code0014Code0007', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0005CODE0014CODE0007'), cellClass: '' },
      { prop: 'code0005Code0014Code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0005CODE0014CODE0008'), cellClass: '' },
      { prop: 'code0005Code0007', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0005CODE0007'), cellClass: '' },
      { prop: 'code0005Code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0005CODE0008'), cellClass: '' },
      { prop: 'code0043', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0043'), cellClass: '' },
      { prop: 'code0043Code0037', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0043CODE0037'), cellClass: '' },
      { prop: 'code0044', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0044'), cellClass: '' },
      { prop: 'code0045', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0045'), cellClass: '' },
      { prop: 'code0046', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0046'), cellClass: '' },
      { prop: 'code0003', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0003'), cellClass: '' },
      { prop: 'code0003Code0002Code0010', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0003CODE0002CODE0010'), cellClass: '' },
      { prop: 'code0003Code0002Code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0003CODE0002CODE0008'), cellClass: '' },
      { prop: 'code0003Code0005Code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0003CODE0005CODE0008'), cellClass: '' },
      { prop: 'code0035', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0035'), cellClass: '' },
      { prop: 'code0047', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0047'), cellClass: '' },
      { prop: 'code0004', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0004'), cellClass: '' },
      { prop: 'code0016', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0016'), cellClass: '' },
      { prop: 'code0016Code0005Code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0016CODE0005CODE0008'), cellClass: '' },
      { prop: 'code0014', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0014'), cellClass: '' },
      { prop: 'code0037', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0037'), cellClass: '' },
      { prop: 'code0048', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0048'), cellClass: '' },
      { prop: 'code0049', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0049'), cellClass: '' },
      { prop: 'code0007', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0007'), cellClass: '' },
      { prop: 'code0008', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0008'), cellClass: '' },
      { prop: 'code0038', name: this.translate.instant('ADVERSE_EVENTS_LIST.COL_LBL.CODE0038'), cellClass: '' },
    ];

    this.columns = [
      this.allColumnList[0],
      this.allColumnList[1],
      this.allColumnList[2],
      this.allColumnList[3],
      this.allColumnList[4],
      this.allColumnList[5],
      this.allColumnList[6],
      this.allColumnList[7],
      this.allColumnList[8],
      this.allColumnList[9],

    ];
    this.loadData();
  }

  openUploadDialog(): void {
    const dialogConfig = this.createDialogConfig(null);
    const dialogRef = this.dialog.open(AdverseEventUploadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.loadData();
    });
  }

  loadData() {
    this.adverseEventService.getAdverseEvents(this.page.pageNumber, this.page.size, this.sort).subscribe(page => {
      this.page.pageNumber = page.number;
      this.page.totalElements = page.totalElements;
      this.page.size = page.size;
      this.rows = page.content;
    });
  }
}



