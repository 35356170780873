<div class="datatable-container" style="height: 100%;">
    <div class="action-container">
        
        <div class="search-section">
            <button *ngIf ="showToggleColumnButton" mat-icon-button [matMenuTriggerFor]="visibleColumnSettingsMenu" aria-label="Select visible columns">
                <mat-icon>settings</mat-icon>
            </button>

        </div>
    </div>
    <div class="table-responsive">
        <ngx-datatable 
            class="material" 
            [ngClass]="styleClass"
            [columns]="columns" 
            [rows]="rows"
            [columnMode]="columnMode.force"
            [externalPaging]="true"
            [externalSorting]="true"
            [count]="page.totalElements"
            [offset]="page.pageNumber"
            [limit]="page.size"
            [rowHeight]="'auto'"
            [scrollbarH]="true"
            [headerHeight]="50" 
            [footerHeight]="50"
            (sort)="onSortChanged($event)">
            <ngx-datatable-footer>
                <ng-template 
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible">
                  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="flex-end center" fxFlex="100"
                  *ngIf = "showPageLimit || showPageSummary || showPagination">

                        <div class="page-limit-container" *ngIf= "showPageLimit">
                            <div class="m-r-5">Show </div>
                            <mat-form-field class="limit-select">
                                <mat-select [(value)]="page.size" (selectionChange)="onPageLimitChanged($event.value)">
                                    <mat-option *ngFor="let plo of pageLimitOptions" [value]="plo">
                                    {{plo}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        
                        <div class="page-summary" *ngIf= "showPageSummary">
                            {{rowCount.toLocaleString()}} {{'total'}}
                        </div>

                          <datatable-pager *ngIf= "showPagination"
                              [pagerLeftArrowIcon]="'datatable-icon-left'"
                              [pagerRightArrowIcon]="'datatable-icon-right'"
                              [pagerPreviousIcon]="'datatable-icon-prev'"
                              [pagerNextIcon]="'datatable-icon-skip'"
                              [page]="curPage"
                              [size]="pageSize"
                              [count]="rowCount"
                              [hidden]="!((rowCount / pageSize) > 1)"
                              (change)="onPageEvent($event)">
                          </datatable-pager>        
 
                  </div>

                </ng-template>
              </ngx-datatable-footer>
        </ngx-datatable>

    </div>
    
</div>


<mat-menu #visibleColumnSettingsMenu="matMenu">
    <div class="toggle-column-menu-container">
        <div class="toggle-all-container">
            <mat-checkbox color="primary"
                class="toggle-all-checkbox" 
                [checked]="allColumnToggled"
                (change)="onToggleAll($event.checked)"
                (click)="$event.stopPropagation();">
                {{'DATATABLE.SHOW_ALL_COLUMNS' | translate}}
            </mat-checkbox>
            <span class="toggle-all-warning" >{{'DATATABLE.COLUMN_TOGGLE.UNSELECT_WARNING' | translate}}</span>
        </div>
        <div mat-menu-item *ngFor="let col of allColumnList">
            <div>
                <button mat-icon-button color="primary"
                    class="settings-icon-button"
                    (click)="onFreeze(col); $event.stopPropagation();" 
                    [disabled]="!isColumnShowing(col)" 
                    title="{{ (col.frozenLeft ? ('DATATABLE.FROZEN_ON' | translate) : ('DATATABLE.FROZEN_OFF' | translate) ) }}">
                    <fa-icon *ngIf = "col.frozenLeft" [icon]="faLock"></fa-icon>
                    <fa-icon *ngIf = "!col.frozenLeft" [icon]="faUnlock"></fa-icon>
                </button>
                <button mat-icon-button color="warn"
                    class="settings-icon-button"
                    (click)="onToggle(col); $event.stopPropagation();" 
                    [disabled]="col.frozenLeft" 
                    title="{{ !isColumnShowing(col) ? ('DATATABLE.COLUMN_SHOW' | translate)  : ('DATATABLE.COLUMN_HIDE' | translate)  }}">
                    <fa-icon *ngIf = "!isColumnShowing(col)" [icon]="faEyeSlash"></fa-icon>
                    <fa-icon *ngIf = "isColumnShowing(col)" [icon]="faEye"></fa-icon>
                </button>
                <span class="settings-icon-column-name">{{ col.name }}</span>
            </div>
        </div>
    </div>
</mat-menu>
