<div fxLayout="row wrap" *ngIf="suppressionList">
  <div fxFlex="100">
    <app-clinical-outcome-summary [clinicalOutcome]="clinicalOutcome"
                                  [suppressionList]="suppressionList"></app-clinical-outcome-summary>
  </div>
  <div fxFlex="100">
    <app-suppression-list-summary [clinicalOutcome]="clinicalOutcome"
                                  [suppressionList]="suppressionList"></app-suppression-list-summary>
  </div>
</div>

<div class="content-box d-flex flex-column" *ngIf="adverseEventResponse">
  <h2 class="content-box__title">Chance of Serious Adverse Events (%)</h2>

  <div class="flex-grow-1 overflow-auto" *ngIf="adverseEventResponse">
    <table class="clinician-summary-table">
      <thead>
        <tr>
          <td class="font-medium m-b-0 m-t-0 pl-16"><strong>Measure</strong></td>
          <td class="table_title pb-8" *ngFor="let r1 of adverseEventResponse">
              <span class="font-medium m-b-0 m-t-0"
              ><strong>{{r1.art}}</strong></span>
          </td>
        </tr>
      </thead>

      <tbody>
      <tr>
        <td class="art_title">Mean</td>
        <td  [ngClass]="calculateClasses(m.mean)" *ngFor="let m of adverseEventResponse">
            <span  [ngClass]="calculateClasses(m.mean)"  class="align-center mat-cell cdk-cell cdk-column-label mat-column-label ng-star-inserted"
            >{{m.mean}} %</span>
        </td>
      </tr>
      <tr>
        <td class="art_title">Median</td>
        <td   [ngClass]="calculateClasses(m.median)"  *ngFor="let m of adverseEventResponse">
            <span   [ngClass]="calculateClasses(m.median)"  class="align-center mat-cell cdk-cell cdk-column-label mat-column-label ng-star-inserted"
            >{{m.median}} %</span>
        </td>
      </tr>
      <!--<tr>
        <td class="art_title">Minimum</td>
        <td  [ngClass]="calculateClasses(m.minimum)" *ngFor="let m of adverseEventResponse">
            <span  [ngClass]="calculateClasses(m.minimum)"   class="align-center mat-cell cdk-cell cdk-column-label mat-column-label ng-star-inserted"
            >{{m.minimum}} %</span>
        </td>
      </tr>
      <tr>
        <td class="art_title">Maximum</td>
        <td [ngClass]="calculateClasses(m.maximum)"  *ngFor="let m of adverseEventResponse">
            <span [ngClass]="calculateClasses(m.maximum)"  class="align-center mat-cell cdk-cell cdk-column-label mat-column-label ng-star-inserted"
            >{{m.maximum}} %</span>
        </td>
      </tr>-->
      </tbody>
    </table>
  </div>
</div>

<div fxLayout="row wrap" fxLayoutAlign="center start" *ngIf="!suppressionList">
  <mat-card fxFlex.gt-sm="50%" fxFlex="50">

    <mat-card-content>
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <img fxFlexAlign="center" src="assets/images/smarthiv-clinician_800x800.png" width="300" height="300"
             alt="Smarthic Clinician">
      </div>
      <p>You haven't got any filled form, please fill a form before to see your outcomes.</p>
      <button mat-raised-button color="warn" (click)="goToFormPage()">Go to form page</button>
    </mat-card-content>
  </mat-card>
</div>
