<mat-dialog-content>
    <div>
        <mat-card-header>
          <mat-card-title>{{ 'ORGANISATION_BRANCH.DETAIL.DETAIL_TITLE' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>

          <!-- ============================================================== -->
          <!-- Organisation Branch Info-->
          <!-- ============================================================== -->
          <div class="m-b-15">

            <form [formGroup]="formGroupDetail" autocomplete="off" novalidate>
              <div class="pb-1 cust-form-field-container">
                <mat-form-field appearance="outline" class="cust-form-field">
                  <mat-label>{{ 'ORGANISATION_BRANCH.DETAIL.NAME' | translate }}</mat-label>
                  <input matInput formControlName="name">
                </mat-form-field>
                <app-validation-message [control]="formGroupDetail.get('name')"></app-validation-message>
              </div>

              <!--<div class="pb-1 cust-form-field-container">
                <mat-form-field appearance="outline" class="cust-form-field">
                  <mat-label>{{ 'ORGANISATION_BRANCH.DETAIL.TITLE' | translate }}</mat-label>
                  <input matInput formControlName="title">
                </mat-form-field>
                <app-validation-message [control]="formGroupDetail.get('title')"></app-validation-message>
              </div>-->

              <div class="pb-1 cust-form-field-container">
                <mat-form-field appearance="outline" class="cust-form-field">
                  <mat-label>{{ 'ORGANISATION_BRANCH.DETAIL.SECTOR' | translate }}</mat-label>
                  <input matInput formControlName="sector">
                </mat-form-field>
                <app-validation-message [control]="formGroupDetail.get('sector')"></app-validation-message>
              </div>

              <div class="pb-1 cust-form-field-container">
                <mat-form-field appearance="outline" class="cust-form-field">
                  <mat-label>{{ 'ORGANISATION_BRANCH.DETAIL.PHONE' | translate }}</mat-label>
                  <ngx-mat-intl-tel-input
                    [enablePlaceholder]="true"
                    [enableSearch]="true"
                    name="phone"
                    formControlName="phone">
                  </ngx-mat-intl-tel-input>
                </mat-form-field>

                <app-validation-message [control]="formGroupDetail.get('phone')"></app-validation-message>
              </div>
            </form>
          </div>
        </mat-card-content>
    </div>

    <!-- ============================================================== -->
    <!-- Organisation Branch Address Info-->
    <!-- ============================================================== -->
    <div>
      <mat-card-header>
        <mat-card-title>{{ 'ORGANISATION_BRANCH.DETAIL.ADDRESS_TITLE' | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="m-b-15">
          <form [formGroup]="formGroupAddress" autocomplete="off" novalidate>

            <div class="pb-1 cust-form-field-container">
              <app-searchable-dropdown 
              [items]="countryList" 
              [label]="'ORGANISATION_BRANCH.DETAIL.COUNTRY'"
              [itemDisplayProperty]="'name'"
              [itemValueProperty]="'iso2'"
              [formFieldAppearance]="'outline'"
              (selectionChange)="loadStates()"
              [control]="getFormControl(formGroupAddress,'country')"
              >
              <ng-template #itemTemplate let-country="item">
                <span>{{ country.emoji + '   ' + country.name}}</span>
              </ng-template>
              
              </app-searchable-dropdown>

              <app-validation-message [control]="formGroupAddress.get('country')"></app-validation-message>
            </div>

            <div class="pb-1 cust-form-field-container">
              <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'ORGANISATION_BRANCH.DETAIL.STATE' | translate }}</mat-label>
                <mat-select placeholder="{{ 'ORGANISATION_BRANCH.DETAIL.STATE' | translate }}" formControlName="state" (selectionChange)="loadCities()">
                  <mat-option *ngFor="let state of stateList" [value]="state.iso2">
                    {{ state.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <app-validation-message [control]="formGroupAddress.get('state')"></app-validation-message>
            </div>

            <div class="pb-1 cust-form-field-container">
              <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'ORGANISATION_BRANCH.DETAIL.CITY' | translate }}</mat-label>
                <mat-select placeholder="{{ 'ORGANISATION_BRANCH.DETAIL.CITY' | translate }}" formControlName="city">
                  <mat-option *ngFor="let city of cityList" [value]="city.name">
                    {{ city.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <app-validation-message [control]="formGroupAddress.get('city')"></app-validation-message>
            </div>

            <div class="pb-1 cust-form-field-container">
                <mat-form-field appearance="outline" class="cust-form-field">
                  <mat-label>{{ 'ORGANISATION_BRANCH.DETAIL.POSTAL_CODE' | translate }}</mat-label>
                  <input matInput formControlName="postalCode">
                </mat-form-field>
                <app-validation-message [control]="formGroupAddress.get('postalCode')"></app-validation-message>
            </div>

            <div class="pb-1 cust-form-field-container">
                <mat-form-field appearance="outline" class="cust-form-field">
                  <mat-label>{{ 'ORGANISATION_BRANCH.DETAIL.TOWN' | translate }}</mat-label>
                  <input matInput formControlName="town">
                </mat-form-field>
                <app-validation-message [control]="formGroupAddress.get('town')"></app-validation-message>
            </div>

            <div class="pb-1 cust-form-field-container">
                <mat-form-field appearance="outline" class="cust-form-field">
                  <mat-label>{{ 'ORGANISATION_BRANCH.DETAIL.ADDRESS' | translate }}</mat-label>
                  <input matInput formControlName="address">
                </mat-form-field>
                <app-validation-message [control]="formGroupAddress.get('address')"></app-validation-message>
            </div>

          </form>
        </div>
      </mat-card-content>
    </div>

</mat-dialog-content>
<mat-dialog-actions>
  <div>
    <button mat-raised-button color="primary"
      type="submit" class="btn-lg"
      *ngIf="pageMode === PageMode.CREATE"
      (click)="onCreateOrganisationBranch()"
      [disabled]="!formGroupDetail.valid || !formGroupAddress.valid">
      {{ 'ORGANISATION_BRANCH.DETAIL.BUTTON.CREATE_ORGANISATION_BRANCH' | translate }}</button>

    <button mat-raised-button color="accent"
      type="submit" class="btn-lg"
      *ngIf="pageMode === PageMode.EDIT"
      (click)="onEditOrganisationBranch()"
      [disabled]="!formGroupDetail.valid || !formGroupAddress.valid">
      {{ 'ORGANISATION_BRANCH.DETAIL.BUTTON.UPDATE_ORGANISATION_BRANCH' | translate }}</button>

    <button mat-raised-button color="warn"
    type="submit" class="btn-lg"
    *ngIf="pageMode === PageMode.DELETE"
    (click)="onDeleteOrganisationBranch()">
    {{ 'ORGANISATION_BRANCH.DETAIL.BUTTON.DELETE_ORGANISATION_BRANCH' | translate }}</button>
  </div>
</mat-dialog-actions>
