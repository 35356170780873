/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import { CustDialogComponent, CustDialogModel } from 'src/app/shared/component/cust-dialog/cust-dialog.component';
import { DEFAULT_USER_PHOTO } from 'src/app/shared/constants';
import { InvitationType } from 'src/app/shared/model/enum/invitation-type';
import { PageMode } from 'src/app/shared/model/enum/page-mode';
import { Role } from 'src/app/shared/model/enum/role';
import { UserSummary } from 'src/app/shared/model/user-account';
import { LoginResponse } from 'src/app/shared/model/user-profile';
import { SendInvitationDialogComponent } from 'src/app/shared/module/invitation/send-invitation-dialog/send-invitation-dialog.component';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { ClinicianPatientService } from 'src/app/shared/services/clinician-patient.service';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';
import { convertToRoleStringArray, getClinicianRelatedRoles, rolesToStringArray } from 'src/app/shared/util';
import { UserUploadComponent } from '../../component/user-upload/user-upload.component';

@Component({
  selector: 'app-organisation-patient-list',
  templateUrl: './organisation-patient-list.component.html',
  styleUrls: ['./organisation-patient-list.component.scss']
})
export class OrganisationPatientListComponent extends BaseListComponent implements OnInit {
  PageMode: typeof PageMode = PageMode;
  Role: typeof Role = Role;

  @ViewChild('rowNumberTemplate', { static: true }) rowNumberTemplate: TemplateRef<any>;
  @ViewChild('nameInfoTemplate', { static: true }) nameInfoTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate', { static: true }) actionTemplate: TemplateRef<any>;

  pageMode: PageMode;
  loginInfo: LoginResponse;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private userProfileService: UserProfileService,
    private authenticationStateService: AuthenticationStateService,
    private clinicianPatientService: ClinicianPatientService
  ) { super(); }

  ngOnInit(): void {
    this.allColumnList = [
      { prop: 'id', name: '#', maxWidth: 50, cellClass: '', cellTemplate: this.rowNumberTemplate, sortable: false, draggable: false },
      { prop: 'xss', name: this.translate.instant('USER_ADMIN.LIST.COL_LBL.NAME'), cellClass: 'w-250', headerClass: 'w-250', cellTemplate: this.nameInfoTemplate, sortable: false, draggable: false },
      { prop: 'email', name: this.translate.instant('USER_ADMIN.LIST.COL_LBL.EMAIL'), cellClass: '', draggable: false },
      { prop: 'phoneNumber', name: this.translate.instant('USER_ADMIN.LIST.COL_LBL.PHONE_NUMBER'), cellClass: '', draggable: false },
      { prop: 'userUniqueId', name: this.translate.instant('USER_ADMIN.LIST.COL_LBL.USER_UNIQUE_ID'), cellClass: '', draggable: false },
      { prop: 'patientOrganisationBranchName', name: this.translate.instant('USER_ADMIN.LIST.COL_LBL.PATIENT_ORGANISATION_BRANCH_NAME'), cellClass: '', draggable: false },
      { prop: 'action', name: '', maxWidth: 50, cellTemplate: this.actionTemplate, sortable: false, draggable: false }
    ];

    this.columns = [...this.allColumnList];

    this.loginInfo = this.authenticationStateService.getLoginInfo();

    if (this.loginInfo.clinicianOrganisationId && this.loginInfo.clinicianOrganisationId > 0) {
      this.pageMode = this.loginInfo.organisationOwner === true ? PageMode.EDIT : PageMode.VIEW;
      this.loadData();
    }
  }

  loadData() {
    const roles = [Role.PATIENT];
    const roleStrList = convertToRoleStringArray(roles);

    if (this.loginInfo?.organisationOwner === true) {
      this.userProfileService.listUsersByOrganisation(this.loginInfo.clinicianOrganisationId, roleStrList, this.page.pageNumber, this.page.size, this.sort, this.searchTerm).subscribe(page => {
        this.page.pageNumber = page.number;
        this.page.totalElements = page.totalElements;
        this.page.size = page.size;
        this.rows = page.content;
      });
    } else {
      this.userProfileService.listUsersByOrganisationBranch(this.loginInfo.clinicianOrganisationBranchId, roleStrList, this.page.pageNumber, this.page.size, this.sort, this.searchTerm).subscribe(page => {
        this.page.pageNumber = page.number;
        this.page.totalElements = page.totalElements;
        this.page.size = page.size;
        this.rows = page.content;
      });

    }
  }

  getProfilePhotoUrl(user: UserSummary): string {
    return user?.photoUrl ? user.photoUrl : DEFAULT_USER_PHOTO;
  }

  getNameStr(user: UserSummary): string {
    return user?.firstName ? (user.firstName + ' ' + user.lastName) : 'No Name';
  }

  getRolesAsString(roleValue): string {
    if (roleValue) {
      const roles = rolesToStringArray(roleValue);
      return roles.map(r => this.translate.instant('ENUM.ROLE.' + r)).join(', ');
    }
    return 'No Role';
  }

  getUserUniqueId(userSummary: UserSummary): string {
    return userSummary.userUniqueId ? userSummary.userUniqueId : 'No Folio Number';
  }

  isMe(selectedRow: UserSummary): boolean {
    return (selectedRow.phoneNumber && selectedRow.phoneNumber === this.loginInfo.phoneNumber) || (selectedRow.email && selectedRow.email === this.loginInfo.email);
  }

  openSendInvitationToUnregisteredUserDialog() {
    const dialogData = {
      emailOrPhone: '',
      inviteeId: null,
      invitationType: InvitationType.BECOME_PATIENT_OF_MY_ORGANISATION_AND_ME,
      editInvitationType: true,
      selectableInvitationTypes: [InvitationType.BECOME_PATIENT_OF_MY_ORGANISATION_AND_ME],
      baseInvitationTypeTranslationKey: 'ORGANISATION.SEND_INVITATION.PATIENT_PAGE.ENUM.INVITATION_TYPE',
      subTitle: this.translate.instant('ORGANISATION.SEND_INVITATION.PATIENT_PAGE.SUBTITLE'),
      organisationId: this.loginInfo.clinicianOrganisationId,
      organisationBranchId: this.loginInfo.clinicianOrganisationBranchId,
      organisationOwner: this.loginInfo.organisationOwner
    };

    const dialogConfig = this.createDialogConfig(dialogData);
    const dialogRef = this.dialog.open(SendInvitationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data?.status && data.status === 'send') {
        // do nothing
      }
    });
  }

  openUploadUserDialog() {
    const dialogConfig = this.createDialogConfig({ role: Role.PATIENT, organisationId: this.loginInfo.clinicianOrganisationId, organisationBranchId: this.loginInfo.clinicianOrganisationBranchId, organisationOwner: this.loginInfo.organisationOwner });
    const dialogRef = this.dialog.open(UserUploadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      // do nothing
    });
  }

  openDialogRemoveFromOrganisation(item: UserSummary) {
    if (this.pageMode !== PageMode.EDIT || item.email === this.loginInfo.email) {
      return;
    }
    const dialogData = new CustDialogModel();
    dialogData.message = 'Are you sure to remove user from organisation?';
    dialogData.title = 'Remove User From Organisation';
    dialogData.type = 'confirm';

    const dialogConfig = this.createDialogConfig(dialogData);
    const dialogRef = this.dialog.open(CustDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data === 'ok') {
        this.removePatientFromOrganisation(item);
      }
    });
  }

  removePatientFromOrganisation(item: UserSummary) {
    this.userProfileService.removePatientFromOrganisation(item.id).subscribe(() => {
      this.notificationService.info(this.translate.instant('ORGANISATION.NOTIFICATION.USER_REMOVE.SUCCESS'));
      this.loadData();
    }, error => {
      console.log('Error on get remove user from organisation : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('ORGANISATION.NOTIFICATION.USER_REMOVE.FAIL'));
    });
  }

  onConnectPatientToMe(item: UserSummary) {
    this.clinicianPatientService.connectClinicianPatientByOrganisationClinician(item.id).subscribe(() => {
      this.notificationService.info(this.translate.instant('ORGANISATION.NOTIFICATION.CONNECT_PATIENT_TO_ME.SUCCESS'));
      this.loadData();
    }, error => {
      console.log('Error on get remove user from organisation : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('ORGANISATION.NOTIFICATION.CONNECT_PATIENT_TO_ME.FAIL'));
    });
  }

}
