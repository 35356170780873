/* eslint-disable max-len */
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { SuppressionService } from 'src/app/shared/services/suppression.service';
import { SuppressionUploadComponent } from '../../component/suppression-upload/suppression-upload.component';

@Component({
  selector: 'app-suppression-list',
  templateUrl: './suppression-list.component.html',
  styleUrls: ['./suppression-list.component.scss']
})
export class SuppressionListComponent extends BaseListComponent implements OnInit {
  @ViewChild('idTemplate', { static: true }) idTemplate: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private suppressionService: SuppressionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.allColumnList = [
      {
        prop: 'id',
        name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.ID'),
        cellTemplate: this.idTemplate,
        width: 100,
        maxWidth: 100
      },
      { prop: 'artCodeNames', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.ART_CODE_NAMES'), cellClass: '' },
      { prop: 'artCodeNamesTokens', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.ART_CODE_NAMES_TOKENS'), cellClass: '' },
      { prop: 'artCodes', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.ART_CODES'), cellClass: '' },
      { prop: 'artCodeCombination', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.ART_CODE_COMBINATION'), cellClass: '' },

      { prop: 'pswk24', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.PSWK24'), cellClass: '' },
      { prop: 'pswk48', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.PSWK48'), cellClass: '' },
      { prop: 'pswk96', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.PSWK96'), cellClass: '' },
      { prop: 'pswk144', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.PSWK144'), cellClass: '' },
      { prop: 'psOverall', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.PS_OVERALL'), cellClass: '' },
      { prop: 'percentAdherent', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.PERCENT_ADHERENT'), cellClass: '' },

      { prop: 'populationPercentDiscont', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.POPULATION_PERCENT_DISCONT'), cellClass: '' },
      { prop: 'populationNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.POPULATION_NOP'), cellClass: '' },
      { prop: 'tfdDays', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.TFD_DAYS'), cellClass: '' },
      { prop: 'tbciPs', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.TBCI_PS'), cellClass: '' },
      { prop: 'tbciNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.TBCI_NOP'), cellClass: '' },
      { prop: 'hbvciPs', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.HBVCI_PS'), cellClass: '' },
      { prop: 'hbvciNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.HBVCI_NOP'), cellClass: '' },
      { prop: 'hcvciPs', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.HCVCI_PS'), cellClass: '' },
      { prop: 'hcvciNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.HCVCI_NOP'), cellClass: '' },
      { prop: 'childrenPs', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.CHILDREN_PS'), cellClass: '' },
      { prop: 'childrenNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.CHILDREN_NOP'), cellClass: '' },
      { prop: 'pregnantPs', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.PREGNANT_PS'), cellClass: '' },
      { prop: 'pregnantNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.PREGNANT_NOP'), cellClass: '' },
      { prop: 'adultPs', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.ADULT_PS'), cellClass: '' },
      { prop: 'adultNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.ADULT_NOP'), cellClass: '' },
      { prop: 'olderAdultPs', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.OLDER_ADULT_PS'), cellClass: '' },
      { prop: 'olderAdultNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.OLDER_ADULT_NOP'), cellClass: '' },

      { prop: 'cd4Ps', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.CD4PS'), cellClass: '' },
      { prop: 'cd4Nop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.CD4NOP'), cellClass: '' },
      { prop: 'gt100000cmlPs', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.GT100000CML_PS'), cellClass: '' },
      { prop: 'gt100000cmlNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.GT100000CML_NOP'), cellClass: '' },
      { prop: 'percentAe', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.PERCENT_AE'), cellClass: '' },
      { prop: 'anyAeNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.ANY_AE_NOP'), cellClass: '' },
      { prop: 'percentSae', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.PERCENT_SAE'), cellClass: '' },
      { prop: 'anySaeNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.ANY_SAE_NOP'), cellClass: '' },
      { prop: 'percentDrae', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.PERCENT_DRAE'), cellClass: '' },
      { prop: 'draeNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.DRAE_NOP'), cellClass: '' },
      { prop: 'percentAnyMutation', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.PERCENT_ANY_MUTATION'), cellClass: '' },
      { prop: 'frequentMutations', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.FREQUENT_MUTATIONS'), cellClass: '' },
      { prop: 'msmPs', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.MSM_PS'), cellClass: '' },
      { prop: 'msmNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.MSM_NOP'), cellClass: '' },
      { prop: 'blackPs', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.BLACK_PS'), cellClass: '' },
      { prop: 'blackNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.BLACK_NOP'), cellClass: '' },
      { prop: 'whitePs', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.WHITE_PS'), cellClass: '' },
      { prop: 'whiteNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.WHITE_NOP'), cellClass: '' },
      { prop: 'femalePs', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.FEMALE_PS'), cellClass: '' },
      { prop: 'femaleNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.FEMALE_NOP'), cellClass: '' },
      { prop: 'malePs', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.MALE_PS'), cellClass: '' },
      { prop: 'maleNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.MALE_NOP'), cellClass: '' },
      { prop: 'nonBinaryPs', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.NON_BINARY_PS'), cellClass: '' },
      { prop: 'nonBinaryNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.NON_BINARY_NOP'), cellClass: '' },
      { prop: 'adolescentPs', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.ADOLESCENT_PS'), cellClass: '' },
      { prop: 'adolescentNop', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.ADOLESCENT_NOP'), cellClass: '' },
      { prop: 'overallHivDead', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.OVERALL_HIV_DEAD'), cellClass: '' },
      { prop: 'overallAllCausesDead', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.OVERALL_ALL_CAUSES_DEAD'), cellClass: '' },
      { prop: 'nonAdherenceDead', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.NON_ADHERENCE_DEAD'), cellClass: '' },
      { prop: 'tbCoinfectedDead', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.TB_COINFECTED_DEAD'), cellClass: '' },
      { prop: 'childrenDead', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.CHILDREN_DEAD'), cellClass: '' },
      { prop: 'adolescentDead', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.ADOLESCENT_DEAD'), cellClass: '' },
      { prop: 'pregnantDead', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.PREGNANT_DEAD'), cellClass: '' },
      { prop: 'adultDead', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.ADULT_DEAD'), cellClass: '' },
      { prop: 'adultOlderDead', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.ADULT_OLDER_DEAD'), cellClass: '' },
      { prop: 'injectionDrugUseDead', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.INJECTION_DRUG_USE_DEAD'), cellClass: '' },
      { prop: 'timeToDeathDead', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.TIME_TO_DEATH_DEAD'), cellClass: '' },

      { prop: 'naiveTreatment', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.NAIVE_TREATMENT'), cellClass: '' },
      { prop: 'switchSuppressed', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.SWITCH_SUPPRESSED'), cellClass: '' },
      { prop: 'switchFailed', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.SWITCH_FAILED'), cellClass: '' },
      { prop: 'pregnantFirstTrim', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.PREGNANT_FIRST_TRIM'), cellClass: '' },
      { prop: 'pregnantSecondTrim', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.PREGNANT_SECOND_TRIM'), cellClass: '' },
      { prop: 'pregnantThirdTrim', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.PREGNANT_THIRD_TRIM'), cellClass: '' },
      { prop: 'totalPregnant', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.TOTAL_PREGNANT'), cellClass: '' },
      { prop: 'expectPregnant', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.EXPECT_PREGNANT'), cellClass: '' },
      { prop: 'tbhivCoinfection', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.TBHIV_COINFECTION'), cellClass: '' },
      { prop: 'hbvhivCoinfection', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.HBVHIV_COINFECTION'), cellClass: '' },
      { prop: 'hcvhivCoinfG14', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.HCVHIV_COINFG14'), cellClass: '' },
      { prop: 'hcvhivCoinfG2', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.HCVHIV_COINFG2'), cellClass: '' },
      { prop: 'hcvhivCoinfG3', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.HCVHIV_COINFG3'), cellClass: '' },
      { prop: 'hcvhivCoinfG56', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.HCVHIV_COINFG56'), cellClass: '' },
      { prop: 'adolescentFlag', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.ADOLESCENT_FLAG'), cellClass: '' },
      { prop: 'childrenFlag', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.CHILDREN_FLAG'), cellClass: '' },
      { prop: 'toddler03Flag', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.TODDLER03FLAG'), cellClass: '' },
      { prop: 'adultFlag', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.ADULTFLAG'), cellClass: '' },

      { prop: 'manufacturerName', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.MANUFACTURER_NAME'), cellClass: '' },
      { prop: 'drugName', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.DRUG_NAME'), cellClass: '' },
      { prop: 'expectedMutation', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.EXPECTED_MUTATION'), cellClass: '' },
      { prop: 'mutationScore', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.MUTATION_SCORE'), cellClass: '' },
      { prop: 'mutationCategory', name: this.translate.instant('SUPPRESSION_LIST.COL_LBL.MUTATION_CATEGORY'), cellClass: '' }

    ];

    this.columns = [
      this.allColumnList[0],
      this.allColumnList[1],
      this.allColumnList[2],
      this.allColumnList[3],
      this.allColumnList[4],
    ];
    this.loadData();
  }

  openUploadDialog(): void {
    const dialogConfig = this.createDialogConfig(null);
    const dialogRef = this.dialog.open(SuppressionUploadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.loadData();
    });
  }

  loadData() {
    this.suppressionService.getSuppressions(this.page.pageNumber, this.page.size, this.sort).subscribe(page => {
      this.page.pageNumber = page.number;
      this.page.totalElements = page.totalElements;
      this.page.size = page.size;
      this.rows = page.content;
    });
  }

}
