<div class="content-box chart pb-0">
  <h3 class="content-box__title">{{'KIN_PATIENT_ADMIN.LIST.TITLE' | translate}}</h3>


  <div fxLayout="row wrap" class="align-items-center">
    <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100">
      <app-search-field [label]="'KIN_PATIENT_ADMIN.LIST.SEARCH_FIELD.LABEL'"
        [hint]="'KIN_PATIENT_ADMIN.LIST.SEARCH_FIELD.HINT'"
        (searchTermChanged)="onSearchTermChanged($event)">
      </app-search-field>
    </div>
    <div fxFlex.gt-md="75" class="text-right">
        <!--div class="add-new-container m-b-20" *ngIf="pageMode !== PageMode.VIEW">
            <button mat-raised-button color="primary" class="m-r-5 m-t-5" (click)="openSendInvitationToUnregisteredUserDialog()" >{{'ORGANISATION.INVITE_UNREGISTERED_CLINICIAN' | translate}}</button>
        </div-->
    </div>
  </div>

  <div>
    <app-datatable
      [styleClass]="'cust-no-border-datatable'"
      [allColumnList]="allColumnList"
      [columns]="columns"
      [rows]="rows"
      [page]="page"
      (pageChanged)="onPageChanged($event)"
      (sortChanged)="onSortChanged($event)">
    </app-datatable>
  </div>
</div>

<ng-template #rowNumberTemplate let-row="row" let-value="value" let-rowIndex="rowIndex">
  <span class="ng-star-inserted">{{(page.pageNumber * page.size) + rowIndex + 1}}</span>
</ng-template>

<ng-template #kinInfoTemplate let-row="row" let-value="value">
    <div class="d-flex">
      <img class="mat-card-avatar" [src]='getProfilePhotoUrl(row.kin)'>
      <div class="m-l-15">
        <p class="font-bold m-b-0 m-t-0">{{ getNameStr(row.kin) }}</p>
        <small class="text-muted" matTooltip="{{getRolesAsString(row.kin.roles)}}">{{getRolesAsString(row.kin.roles)}}</small>
      </div>
    </div>
</ng-template>

<ng-template #patientInfoTemplate let-row="row" let-value="value">
  <div class="d-flex">
    <img class="mat-card-avatar" [src]='getProfilePhotoUrl(row.patient)'>
    <div class="m-l-15">
      <p class="font-bold m-b-0 m-t-0">{{ getNameStr(row.patient) }}</p>
      <small class="text-muted" matTooltip="{{getRolesAsString(row.patient.roles)}}">{{getRolesAsString(row.patient.roles)}}</small>
    </div>
  </div>
</ng-template>

<ng-template #kinEmailOrPhoneTemplate let-row="row" let-value="value">
  <div class="d-flex">
    <div class="m-l-0">
      <p class="font-bold m-b-0 m-t-0">{{ getEmailOrPhone(row.kin) }}</p>
      <small class="text-muted">{{getUserUniqueId(row.kin)}}</small>
    </div>
  </div>
</ng-template>

<ng-template #patientEmailOrPhoneTemplate let-row="row" let-value="value">
<div class="d-flex">
  <div class="m-l-0">
    <p class="font-bold m-b-0 m-t-0">{{ getEmailOrPhone(row.patient) }}</p>
    <small class="text-muted">{{getUserUniqueId(row.patient)}}</small>
  </div>
</div>
</ng-template>

<ng-template #actionTemplate let-row="row" let-value="value">
  <div class="d-flex">
      <!--a (click)="openDetailDialog(PageMode.VIEW, row)" class="m-r-10 cursor-pointer">
          <i class="fa fa-eye fa-lg text-info"></i>
      </a-->
      <a [matMenuTriggerFor]="overflowRowMenu" [matMenuTriggerData]="{rowKinPatientSummary: row}" class="m-r-10 cursor-pointer">
          <i class="fa fa-ellipsis-v fa-lg"></i>
      </a>
  </div>

</ng-template>


<!-- ============================================================== -->
<!-- Row overflow menu-->
<!-- ============================================================== -->
<mat-menu #overflowRowMenu="matMenu">
  <ng-template matMenuContent let-selectedRow="rowKinPatientSummary" >
      <button mat-menu-item *ngIf="!isMe(selectedRow.kin)" (click)="removeFromKinPatientList(selectedRow)">Remove From List</button>
  </ng-template>
</mat-menu>
