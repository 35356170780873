/* eslint-disable max-len */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Page, PageableSort } from '../model/pagination';
import { PushNotification, PushNotificationRequest, PushNotificationTokenRequest, ChatRoomResponse, PushNotificationMulticastRequest, PushNotificationMulticastResponse, PushNotificationReadResponse } from '../model/push-notification';
import { NotificationService } from '../module/notification/service/notification.service';
import { createPageParams } from '../util';


@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  private baseUrl = '/notification-svc/v1/push-notifications';

  pushNotificationSubject = new Subject<PushNotification>();
  unreadPushNotificationCountSubject = new Subject<number>();

  token: string = '';
  subscriptions: Subscription[] = [];

  constructor(
    private http: HttpClient,
    private angularFireMessaging: AngularFireMessaging,
    private translate: TranslateService,
    private notificationService: NotificationService
  ) {
     //navigator.serviceWorker.register('assets/firabase/firebase-messaging-sw.js');
  }

  requestPermission() {
    const subsRequestToken = this.angularFireMessaging.requestToken.subscribe((token) => {
      if (token) {
        this.onRegisterToken(token);
        this.startReceiveMessage();
      } else {
        this.notificationService.error(this.translate.instant('Registration token could not retrieve'));
      }
    }, (error) => {
      console.log('Error on request push notification request permission : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('We could not get the required permission from user'));
    });
    this.subscriptions.push(subsRequestToken);
  }

  destroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  startReceiveMessage() {
    const subsFirebaseMessages = this.angularFireMessaging.messages.subscribe((payload) => {
        let unreadMessageCount = -1;
        const notification = new PushNotification();
        notification.id = +payload.data?.id;
        notification.type = payload.data?.type;
        notification.content = payload.data?.content;
        notification.createdAt = payload.data?.createdAt;
        notification.fromId = +payload.data?.fromId;
        notification.toId = +payload.data?.toId;
        unreadMessageCount = +payload.data?.unreadMessageCount;

        this.publishPushNotification(notification);
        this.publishUnreadPushNotificationCount(unreadMessageCount);
    });

    this.subscriptions.push(subsFirebaseMessages);

  }

  publishPushNotification(pushNotification: PushNotification) {
    this.pushNotificationSubject.next(pushNotification);
  }

  publishUnreadPushNotificationCount(unreadMessageCount: number) {
    this.unreadPushNotificationCountSubject.next(unreadMessageCount);
  }

  getPushNotificationChangeObservable(): Observable<PushNotification> {
    return this.pushNotificationSubject.asObservable();
  }

  getUnreadPushNotificationCountObservable(): Observable<number> {
    return this.unreadPushNotificationCountSubject.asObservable();
  }

  onDeleteToken(token) {
    const request = new PushNotificationTokenRequest();
    request.token = token;
    this.deletePushNotificationToken(request).subscribe(res => {
        this.notificationService.success(this.translate.instant('Push notification disabled successfully'));
    }, (error) => {
      console.log('Error on registration of push notification token : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('We could not register push notification token'));
    });
  }

  onRegisterToken(token) {
    this.token = token;
    const request = new PushNotificationTokenRequest();
    request.token = token;
    this.registerPushNotificationToken(request).subscribe(res => {
      this.notificationService.success(this.translate.instant('Push notification enabled successfully'));
    }, (error) => {
      console.log('Error on registration of push notification token : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('We could not register push notification token'));
    });
  }

  registerPushNotificationToken(request: PushNotificationTokenRequest):  Observable<any> {
    return this.http.post<any>(`${environment.url}${this.baseUrl}/register-token`, request)
    .pipe(map((res: any) => res || {}));
  }

  deletePushNotificationToken(request: PushNotificationTokenRequest):  Observable<any> {
    return this.http.post<any>(`${environment.url}${this.baseUrl}/delete-token`, request)
    .pipe(map((res: any) => res || {}));
  }

  sendChatMessage(request: PushNotificationRequest):  Observable<PushNotification> {
    return this.http.post<PushNotification>(`${environment.url}${this.baseUrl}/send`, request)
    .pipe(map((res: any) => res || {}));
  }

  sendMulticastChatMessage(request: PushNotificationMulticastRequest):  Observable<PushNotificationMulticastResponse> {
    return this.http.post<PushNotificationMulticastResponse>(`${environment.url}${this.baseUrl}/send-multicast`, request)
    .pipe(map((res: any) => res || {}));
  }

  readChatMessage(pushNotificationId):  Observable<PushNotificationReadResponse> {
    return this.http.patch<PushNotification>(`${environment.url}${this.baseUrl}/read-message/message/${pushNotificationId}`, {})
    .pipe(map((res: any) => res || {}));
  }

  readPushNotificationsOfMyRoom(otherUserId):  Observable<PushNotificationReadResponse> {
    return this.http.patch<PushNotificationReadResponse>(`${environment.url}${this.baseUrl}/read-message/me/otherUser/${otherUserId}`, {})
    .pipe(map((res: any) => res || {}));
  }

  listChatRoomsOfMe():  Observable<ChatRoomResponse[]> {
    return this.http.get<ChatRoomResponse[]>(`${environment.url}${this.baseUrl}/me/chat-message/rooms`)
    .pipe(map((res: any) => res || []));
  }

  listPushNotificationsBetweenUsers(otherUserId, page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<PushNotification>> {
    const pageParams = createPageParams(page, pageSize, sort, searchTerm);

    return this.http.get<Page<PushNotification>>(`${environment.url}${this.baseUrl}/me/otherUser/${otherUserId}`, { params: pageParams })
        .pipe(map((res: any) => (res || {})
        ));
  }


  listMyUnReadPushNotifications(page: number, pageSize: number, sort?: PageableSort): Observable<Page<ChatRoomResponse>> {
    const pageParams = createPageParams(page, pageSize, sort, null);

    return this.http.get<Page<ChatRoomResponse>>(`${environment.url}${this.baseUrl}/me/unread-notifications`, { params: pageParams })
        .pipe(map((res: any) => (res || {})
        ));
  }

  getMyUnReadPushNotificationCount(): Observable<PushNotificationReadResponse> {

    return this.http.get<PushNotificationReadResponse>(`${environment.url}${this.baseUrl}/me/unread-notification-count`)
        .pipe(map((res: any) => (res || {})
        ));
  }

}
