import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {

    constructor(private translateService: TranslateService) {
    }

    getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
        /* eslint-disable max-len */
        let config = {
            alpha: this.translateService.instant('VALIDATION-ERROR.ALPHA'),
            notEmpty: this.translateService.instant('VALIDATION-ERROR.REQUIRED'),
            required: this.translateService.instant('VALIDATION-ERROR.REQUIRED'),
            email: this.translateService.instant('VALIDATION-ERROR.EMAIL'),
            invalidPassword: this.translateService.instant('VALIDATION-ERROR.INVALID-PASSWORD'),
            invalidPhoneNumber: this.translateService.instant('VALIDATION-ERROR.INVALID-PHONE-NUMBER'),
            invalidFaxNumber: this.translateService.instant('VALIDATION-ERROR.INVALID-FAX-NUMBER'),
            compare: this.translateService.instant('VALIDATION-ERROR.COMPARE'),
            url: this.translateService.instant('VALIDATION-ERROR.URL'),
            numeric: this.translateService.instant('VALIDATION-ERROR.NUMERIC'),
            validatePhoneNumber: this.translateService.instant('VALIDATION-ERROR.INVALID-PHONE-NUMBER'),
            error: validatorValue
        };
        if (validatorValue.refValues !== undefined) {
            const config2 = {
                minLength: this.translateService.instant('VALIDATION-ERROR.MIN-LENGTH').replace('{{ limit }}', `${validatorValue.refValues[1]}`),
                maxLength: this.translateService.instant('VALIDATION-ERROR.MAX-LENGTH').replace('{{ limit }}', `${validatorValue.refValues[1]}`),
                range: this.translateService.instant('VALIDATION-ERROR.RANGE').replace('{{ min }}', `${validatorValue.refValues[1]}`).replace('{{ max }}', `${validatorValue.refValues[2]}`)
            };
            config = {...config, ...config2};
        }

        if (validatorValue.requiredLength !== undefined) {
            const config3 = {
                minlength: this.translateService.instant('VALIDATION-ERROR.MIN-LENGTH').replace('{{ limit }}', `${validatorValue.requiredLength}`),
                maxlength: this.translateService.instant('VALIDATION-ERROR.MAX-LENGTH').replace('{{ limit }}', `${validatorValue.requiredLength}`),
            };
            config = {...config, ...config3};
        }

        return config[validatorName];
    }
}
