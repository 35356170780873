/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { InvitationService } from 'src/app/shared/services/invitation.service';
import { Invitation } from 'src/app/shared/model/invitation';
import { DatatablePage } from 'src/app/shared/model/datatable-page';
import { PageableSort } from 'src/app/shared/model/pagination';
import { InboxType } from '../model/invitation-models';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../notification/service/notification.service';
import { DEFAULT_USER_PHOTO } from 'src/app/shared/constants';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';


@Component({
    selector: 'app-invitation-list',
    templateUrl: './invitation-list.component.html',
    styleUrls: ['./invitation-list.component.scss']
})
export class InvitationListComponent extends BaseListComponent implements OnInit {
    InboxType: typeof InboxType = InboxType;

    private mediaMatcher: MediaQueryList = matchMedia(`(max-width: 960px)`);

    public config: PerfectScrollbarConfigInterface = {};
    selectedInvitation: Invitation;

    title;
    pageLimitOptions = [6, 12, 24, 48, 96];

    @Input() inboxType: InboxType;

    constructor(
        private router: Router,
        private sanitized: DomSanitizer,
        private translate: TranslateService,
        private notificationService: NotificationService,
        private invitationService: InvitationService,
        private dialog: MatDialog
    ) {
        super();
    }

    isOver(): boolean {
        return this.mediaMatcher.matches;
    }

    ngOnInit(): void {
        this.page.size = this.pageLimitOptions[0];
        this.sort = new PageableSort({property: 'createdAt', direction: 'DESC'});

        this.loadData();
    }

    loadData() {
        if (this.inboxType === InboxType.INCOMING_INVITATIONS) {
            this.title = this.translate.instant('USER_PROFILE.MENU.INCOMING_INVITATION');
            this.invitationService.getInviteeInvitations(this.page.pageNumber, this.page.size, this.sort).subscribe(page => {
                this.page.pageNumber = page.number;
                this.page.totalElements = page.totalElements;
                this.page.size = page.size;
                this.rows = page.content;
                this.selectedInvitation = null;
            });
        }
        if (this.inboxType === InboxType.OUTGOING_INVITATIONS) {
            this.title = this.translate.instant('USER_PROFILE.MENU.OUTGOING_INVITATION');
            this.invitationService.getInvitorInvitations(this.page.pageNumber, this.page.size, this.sort).subscribe(page => {
                this.page.pageNumber = page.number;
                this.page.totalElements = page.totalElements;
                this.page.size = page.size;
                this.rows = page.content;
                this.selectedInvitation = null;
            });
        }

    }

    rowSelected(row: Invitation) {
        this.selectedInvitation = row;
    }

    sanitizeHtml(value) {
       const response = this.sanitized.bypassSecurityTrustHtml(value);
       return response;
    }

    openNewInvitationDialog() {
    }

    getRowUsername(row: Invitation) {
        if (InboxType.OUTGOING_INVITATIONS === this.inboxType) {
            if (row.invitee) {
                return row.invitee.firstName + ' ' + row.invitee.lastName;
            }
            return row.unregisteredInviteeEmail ? row.unregisteredInviteeEmail : row.unregisteredInviteePhone;
        }
        return row.invitor.firstName + ' ' + row.invitor.lastName;

    }

    getRowPhotoUrl(row: Invitation) {
        if (InboxType.OUTGOING_INVITATIONS === this.inboxType) {
            if (row.invitee) {
                return row.invitee.photoUrl ? row.invitee.photoUrl : DEFAULT_USER_PHOTO;
            }
            return DEFAULT_USER_PHOTO;
        }
        return row.invitor.photoUrl ? row.invitor.photoUrl : DEFAULT_USER_PHOTO;
    }

}
