import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ChartComponent } from 'ng-apexcharts';
import { Pair, SimulationResultCostListResponse, SimulationResultCostResponse } from 'src/app/shared/model/simulation-result';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { SimulationService } from 'src/app/shared/services/simulation.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-financal-planning',
  templateUrl: './financal-planning.component.html',
  styleUrls: ['./financal-planning.component.scss']
})
export class FinancalPlanningComponent implements OnInit {

  @ViewChild('chartPieCost')
  chartPieCost: ChartComponent = Object.create(null);
  @ViewChild('chartPieCostSc')
  chartPieCostSc: ChartComponent = Object.create(null);
  @ViewChild('chartTotal')
  chartTotal: ChartComponent = Object.create(null);
  @ViewChild('chartTotalPatient')
  chartTotalPatient: ChartComponent = Object.create(null);
  @ViewChild('chartTotalExpense')
  chartTotalExpense: ChartComponent = Object.create(null);
  @ViewChild('chartTotalAge')
  chartTotalAge: ChartComponent = Object.create(null);
  @ViewChild('chartTotalFirst')
  chartTotalFirst: ChartComponent = Object.create(null);
  @ViewChild('chartTotalSecond')
  chartTotalSecond: ChartComponent = Object.create(null);
  @ViewChild('chartTotalLaboratory')
  chartTotalLaboratory: ChartComponent = Object.create(null);
  @ViewChild('chartTotalOverhead')
  chartTotalOverhead: ChartComponent = Object.create(null);
  @ViewChild('chartTotalPrevention')
  chartTotalPrevention: ChartComponent = Object.create(null);

  public chartPieCostOptions;
  public chartPieCostScOptions;
  public chartTotalOptions;
  public chartTotalPatientOptions;
  public chartTotalExpenseOptions;
  public chartTotalAgeOptions;
  public chartTotalFirstOptions;
  public chartTotalSecondOptions;
  public chartTotalLaboratoryOptions;
  public chartTotalOverheadOptions;
  public chartTotalPreventionOptions;

  firstCost: string;
  secondCost: string;
  laboratoryCost: string;
  overheadCost: string;
  preventionCost: string;
  totalCost: string;

  firstCostSc: string;
  secondCostSc: string;
  laboratoryCostSc: string;
  overheadCostSc: string;
  preventionCostSc: string;
  totalCostSc: string;

  layout = 'raw';
  layoutSize = '50';

  years;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private notificationService: NotificationService,
    public simulationService: SimulationService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    const result = JSON.parse(this.storageService.getSimulationResult());

    if (!result) {
      this.router.navigate(['smarthiv-manager/manager-form']);
      this.notificationService.error(this.translate.instant('SIMULATION.RUN_MODEL_EXCEPTION'));
    }

    this.simulationService.getSimulationResultCosts(result).subscribe(data => {
      this.years = data.costs[0].years;

      if (this.years > 5) {
        this.layout = 'column';
        this.layoutSize = '100';
      }

      this.setTotalCost(data,
        this.getAverage(this.getArray(data.costs, 'therapyLine', true)),
        this.getAverage(this.getArray(data.costs, 'therapyLine', false)),
        this.getAverage(this.getArray(data.costs, 'netPatient', true)),
        this.getAverage(this.getArray(data.costs, 'netPatient', false)),
        this.getAverage(this.getArray(data.costs, 'discontinued', true)),
        this.getAverage(this.getArray(data.costs, 'discontinued', false)),
        this.getAverage(this.getArray(data.costs, 'demand', true)),
        this.getAverage(this.getArray(data.costs, 'demand', false)),
        this.getAverage(this.getArray(data.costs, 'viral', true)),
        this.getAverage(this.getArray(data.costs, 'viral', false)));
    }, (err) => {
      console.log('Error on get simulation. err : ' + err);
      this.notificationService.error('Error on get simulation result');
    });
  }

  setTotalCost(data: SimulationResultCostListResponse,
    therapyLineMap: Map<string, Array<Pair>>,
    therapyLineSc: Map<string, Array<Pair>>,
    netPatientMap: Map<string, Array<Pair>>,
    netPatientMapSc: Map<string, Array<Pair>>,
    discontinuedMap: Map<string, Array<Pair>>,
    discontinuedMapSc: Map<string, Array<Pair>>,
    demandMap: Map<string, Array<Pair>>,
    demandMapSc: Map<string, Array<Pair>>,
    viralLoadMap: Map<string, Array<Pair>>,
    viralLoadMapSc: Map<string, Array<Pair>>) {

    let firstCostNumber = 0;
    let secondCostNumber = 0;
    let laboratoryCostNumber = 0;
    let overheadCostNumber = 0;
    let preventionCostNumber = 0;

    let firstCostNumberSc = 0;
    let secondCostNumberSc = 0;
    let laboratoryCostNumberSc = 0;
    let overheadCostNumberSc = 0;
    let preventionCostNumberSc = 0;

    const years: string[][] = [];
    const yearsOnly: string[] = [];

    const experiencedData: number[] = [];
    const naiveData: number[] = [];

    const firstData: number[] = [];
    const firstDataSc: number[] = [];
    const firstDataAll: number[] = [];

    const secondData: number[] = [];
    const secondDataSc: number[] = [];
    const secondDataAll: number[] = [];

    const laboratoryData: number[] = [];
    const laboratoryDataSc: number[] = [];
    const laboratoryDataAll: number[] = [];

    const staffData: number[] = [];
    const staffDataSc: number[] = [];
    const staffDataAll: number[] = [];

    const preventionData: number[] = [];
    const preventionDataSc: number[] = [];
    const preventionDataAll: number[] = [];

    const totalData: number[] = [];
    const totalDataSc: number[] = [];

    const childrenData: number[] = [];
    const adolescentData: number[] = [];
    const adultData: number[] = [];
    const olderAdultData: number[] = [];

    let isFirst = true;

    for (const key of therapyLineMap.keys()) {

      const percentagePrep = data.costs[0].percentagePrep;
      const percentagePrepSc = data.costs[data.costs.length - 1].percentagePrep;

      years.push([key, this.translate.instant('SIMULATION.CP')]);
      years.push([key, this.translate.instant('SIMULATION.SC')]);

      yearsOnly.push(key);

      const therapyLinePairs: Pair[] = therapyLineMap.get(key);
      const therapyLinePairsSc: Pair[] = therapyLineSc.get(key);

      const netPatientPairs: Pair[] = netPatientMap.get(key);
      const netPatientPairsSc: Pair[] = netPatientMapSc.get(key);

      const discontinuedPairs: Pair[] = discontinuedMap.get(key);
      const discontinuedPairsSc: Pair[] = discontinuedMapSc.get(key);

      const demandPairs: Pair[] = demandMap.get(key);
      const demandPairsSc: Pair[] = demandMapSc.get(key);

      const viralLoadPairs: Pair[] = viralLoadMap.get(key);
      const viralLoadPairsSc: Pair[] = viralLoadMapSc.get(key);

      let experiencedFirst = 0;
      let experiencedSecond = 0;

      let naiveFirst = 0;
      let naiveSecond = 0;

      let removedFirst = 0;
      let removedSecond = 0;

      let prepAll = 0;
      let prepDemand = 0;
      let pep = 0;
      let prepDiscontinued = 0;

      let experiencedFirstSc = 0;
      let experiencedSecondSc = 0;

      let naiveFirstSc = 0;
      let naiveSecondSc = 0;

      let removedFirstSc = 0;
      let removedSecondSc = 0;

      let prepAllSc = 0;
      let prepDemandSc = 0;
      let pepSc = 0;
      let prepDiscontinuedSc = 0;

      therapyLinePairs.forEach(element => {
        if (element.first === 'THERAPY_LINE_ONE_NAIVE') {
          naiveFirst = naiveFirst + element.second;
        } else if (element.first === 'THERAPY_LINE_TWO_NAIVE') {
          naiveSecond = naiveSecond + element.second;
        } else if (element.first === 'THERAPY_LINE_ONE_EXP') {
          experiencedFirst = experiencedFirst + element.second;
        } else if (element.first === 'THERAPY_LINE_TWO_EXP') {
          experiencedSecond = experiencedSecond + element.second;
        } else if (element.first === 'THERAPY_LINE_ONE_REMOVED') {
          removedFirst = removedFirst + element.second;
        } else if (element.first === 'THERAPY_LINE_TWO_REMOVED') {
          removedSecond = removedSecond + element.second;
        }
      });

      therapyLinePairsSc.forEach(element => {
        if (element.first === 'THERAPY_LINE_ONE_NAIVE') {
          naiveFirstSc = naiveFirstSc + element.second;
        } else if (element.first === 'THERAPY_LINE_TWO_NAIVE') {
          naiveSecondSc = naiveSecondSc + element.second;
        } else if (element.first === 'THERAPY_LINE_ONE_EXP') {
          experiencedFirstSc = experiencedFirstSc + element.second;
        } else if (element.first === 'THERAPY_LINE_TWO_EXP') {
          experiencedSecondSc = experiencedSecondSc + element.second;
        } else if (element.first === 'THERAPY_LINE_ONE_REMOVED') {
          removedFirstSc = removedFirstSc + element.second;
        } else if (element.first === 'THERAPY_LINE_TWO_REMOVED') {
          removedSecondSc = removedSecondSc + element.second;
        }
      });

      netPatientPairs.forEach(element => {
        if (element.first === 'PREP') {
          prepAll = prepAll + element.second;
        } else if (element.first === 'PEP') {
          pep = pep + element.second;
        }
      });

      netPatientPairsSc.forEach(element => {
        if (element.first === 'PREP') {
          prepAllSc = prepAllSc + element.second;
        } else if (element.first === 'PEP') {
          pepSc = pepSc + element.second;
        }
      });

      demandPairs.forEach(element => {
        if (element.first === 'PREVENTION') {
          prepDemand = Math.round((element.second * percentagePrep) / 100);
        }
      });

      demandPairsSc.forEach(element => {
        if (element.first === 'PREVENTION') {
          prepDemandSc = Math.round((element.second * percentagePrepSc) / 100);
        }
      });

      discontinuedPairs.forEach(element => {
        if (element.first === 'PREP') {
          prepDiscontinued = prepDiscontinued + element.second;
        }
      });

      discontinuedPairsSc.forEach(element => {
        if (element.first === 'PREP') {
          prepDiscontinuedSc = prepDiscontinuedSc + element.second;
        }
      });

      viralLoadPairs.forEach(element => {
        if (element.first === 'INFECTED') {
          prepDiscontinued = prepDiscontinued + element.second;
        }
      });

      viralLoadPairsSc.forEach(element => {
        if (element.first === 'INFECTED') {
          prepDiscontinuedSc = prepDiscontinuedSc + element.second;
        }
      });

      const threapyLineOneCost = data.costs[0].artFirstCost;
      const threapyLineTwoCost = data.costs[0].artSecondCost;

      const threapyLineOneCostSc = data.costs[data.costs.length - 1].artFirstCost;
      const threapyLineTwoCostSc = data.costs[data.costs.length - 1].artSecondCost;

      const preventionDrugCost = data.costs[0].preventionCost;
      const preventionDrugCostSc = data.costs[data.costs.length - 1].preventionCost;

      const laboratoryCost = data.costs[0].laboratoryCost;
      const laboratoryCostSc = data.costs[data.costs.length - 1].laboratoryCost;

      const staffCost = data.costs[0].overheadCost;
      const staffCostSc = data.costs[data.costs.length - 1].overheadCost;

      const naiveFirstCost = Math.round((naiveFirst * threapyLineOneCost) / 2);
      const naiveSecondCost = Math.round((naiveSecond * threapyLineTwoCost) / 2);
      const naiveLabCost = Math.round((naiveFirst + naiveSecond) * laboratoryCost / 2);
      const naiveStaffCost = Math.round((naiveFirst + naiveSecond) * staffCost / 2);
      const naiveCost = naiveFirstCost + naiveSecondCost + naiveLabCost + naiveStaffCost;

      const naiveFirstCostSc = Math.round((naiveFirstSc * threapyLineOneCostSc) / 2);
      const naiveSecondCostSc = Math.round((naiveSecondSc * threapyLineTwoCostSc) / 2);
      const naiveLabCostSc = Math.round((naiveFirstSc + naiveSecondSc) * laboratoryCostSc / 2);
      const naiveStaffCostSc = Math.round((naiveFirstSc + naiveSecondSc) * staffCostSc / 2);
      const naiveCostSc = naiveFirstCostSc + naiveSecondCostSc + naiveLabCostSc + naiveStaffCostSc;

      const experiencedFirstCost = Math.round((experiencedFirst * threapyLineOneCost));
      const experiencedSecondCost = Math.round((experiencedSecond * threapyLineTwoCost));
      const experiencedLabCost = Math.round((experiencedFirst + experiencedSecond) * laboratoryCost);
      const experiencedStaffCost = Math.round((experiencedFirst + experiencedSecond) * staffCost);
      const experiencedCost = experiencedFirstCost + experiencedSecondCost + experiencedLabCost + experiencedStaffCost;

      const experiencedFirstCostSc = Math.round((experiencedFirstSc * threapyLineOneCostSc));
      const experiencedSecondCostSc = Math.round((experiencedSecondSc * threapyLineTwoCostSc));
      const experiencedLabCostSc = Math.round((experiencedFirstSc + experiencedSecondSc) * laboratoryCostSc);
      const experiencedStaffCostSc = Math.round((experiencedFirstSc + experiencedSecondSc) * staffCostSc);
      const experiencedCostSc = experiencedFirstCostSc + experiencedSecondCostSc + experiencedLabCostSc + experiencedStaffCostSc;

      const removedFirstCost = Math.round((removedFirst * threapyLineOneCost) / 2);
      const removedSecondCost = Math.round((removedSecond * threapyLineTwoCost) / 2);
      const removedLabCost = Math.round((removedFirst + removedSecond) * laboratoryCost / 2);
      const removedStaffCost = Math.round((removedFirst + removedSecond) * staffCost / 2);
      const removedCost = removedFirstCost + removedSecondCost + removedLabCost + removedStaffCost;

      const removedFirstCostSc = Math.round((removedFirstSc * threapyLineOneCostSc) / 2);
      const removedSecondCostSc = Math.round((removedSecondSc * threapyLineTwoCostSc) / 2);
      const removedLabCostSc = Math.round((removedFirstSc + removedSecondSc) * laboratoryCostSc / 2);
      const removedStaffCostSc = Math.round((removedFirstSc + removedSecondSc) * staffCostSc / 2);
      const removedCostSc = removedFirstCostSc + removedSecondCostSc + removedLabCostSc + removedStaffCostSc;

      const pepLabCost = Math.round(pep * laboratoryCost / 2);
      const pepStaffCost = Math.round(pep * staffCost / 2);
      const pepDrugCost = Math.round(pep * preventionDrugCost / 2);
      const pepCost = pepLabCost + pepStaffCost + pepDrugCost;

      const pepLabCostSc = Math.round(pepSc * laboratoryCostSc / 2);
      const pepStaffCostSc = Math.round(pepSc * staffCostSc / 2);
      const pepDrugCostSc = Math.round(pepSc * preventionDrugCostSc / 2);
      const pepCostSc = pepLabCostSc + pepStaffCostSc + pepDrugCostSc;

      const prepDemandLabCost = Math.round(prepDemand * laboratoryCost / 2);
      const prepDemandStaffCost = Math.round(prepDemand * staffCost / 2);
      const prepDemandDrugCost = Math.round(prepDemand * preventionDrugCost / 2);
      const prepDemandCost = prepDemandLabCost + prepDemandStaffCost + prepDemandDrugCost;

      const prepDemandLabCostSc = Math.round(prepDemandSc * laboratoryCostSc / 2);
      const prepDemandStaffCostSc = Math.round(prepDemandSc * staffCostSc / 2);
      const prepDemandDrugCostSc = Math.round(prepDemandSc * preventionDrugCostSc / 2);
      const prepDemandCostSc = prepDemandLabCostSc + prepDemandStaffCostSc + prepDemandDrugCostSc;

      const prepExLabCost = Math.round((prepAll - prepDemand) * laboratoryCost);
      const prepExStaffCost = Math.round((prepAll - prepDemand) * staffCost);
      const prepExDrugCost = Math.round((prepAll - prepDemand) * preventionDrugCost);
      const prepExCost = prepExLabCost + prepExStaffCost + prepExDrugCost;

      const prepExLabCostSc = Math.round((prepAllSc - prepDemandSc) * laboratoryCostSc);
      const prepExStaffCostSc = Math.round((prepAllSc - prepDemandSc) * staffCostSc);
      const prepExDrugCostSc = Math.round((prepAllSc - prepDemandSc) * preventionDrugCostSc);
      const prepExCostSc = prepExLabCostSc + prepExStaffCostSc + prepExDrugCostSc;

      const prepDiscontinuedLabCost = Math.round(prepDiscontinued * laboratoryCost / 2);
      const prepDiscontinuedStaffCost = Math.round(prepDiscontinued * staffCost / 2);
      const prepDiscontinuedDrugCost = Math.round(prepDiscontinued * preventionDrugCost / 2);
      const prepDiscontinuedCost = prepDiscontinuedLabCost + prepDiscontinuedStaffCost + prepDiscontinuedDrugCost;

      const prepDiscontinuedLabCostSc = Math.round(prepDiscontinuedSc * laboratoryCostSc / 2);
      const prepDiscontinuedStaffCostSc = Math.round(prepDiscontinuedSc * staffCostSc / 2);
      const prepDiscontinuedDrugCostSc = Math.round(prepDiscontinuedSc * preventionDrugCostSc / 2);
      const prepDiscontinuedCostSc = prepDiscontinuedLabCostSc + prepDiscontinuedStaffCostSc + prepDiscontinuedDrugCostSc;

      const laboratoryAllCost = naiveLabCost + experiencedLabCost + removedLabCost + pepLabCost
        + prepDemandLabCost + prepExLabCost + prepDiscontinuedLabCost;
      const laboratoryAllCostSc = naiveLabCostSc + experiencedLabCostSc + removedLabCostSc + pepLabCostSc
        + prepDemandLabCostSc + prepExLabCostSc + prepDiscontinuedLabCostSc;

      const staffAllCost = naiveStaffCost + experiencedStaffCost + removedStaffCost + pepStaffCost
        + prepDemandStaffCost + prepExStaffCost + prepDiscontinuedStaffCost;
      const staffAllCostSc = naiveStaffCostSc + experiencedStaffCostSc + removedStaffCostSc + pepStaffCostSc
        + prepDemandStaffCostSc + prepExStaffCostSc + prepDiscontinuedStaffCostSc;

      const totalCost = naiveCost + experiencedCost + removedCost + pepCost + prepDemandCost + prepExCost + prepDiscontinuedCost;
      const totalCostSc = naiveCostSc + experiencedCostSc + removedCostSc + pepCostSc + prepDemandCostSc + prepExCostSc
        + prepDiscontinuedCostSc;

      naiveData.push(naiveCost);
      naiveData.push(naiveCostSc);
      experiencedData.push(experiencedCost);
      experiencedData.push(experiencedCostSc);
      totalData.push(totalCost);
      totalDataSc.push(totalCostSc);

      childrenData.push(parseFloat((totalCost * (data.costs[0].percentageChildren / 100)).toFixed(0)));
      childrenData.push(parseFloat((totalCostSc * (data.costs[data.costs.length - 1].percentageChildren / 100)).toFixed(0)));

      adolescentData.push(parseFloat((totalCost * (data.costs[0].percentageAdolescent / 100)).toFixed(0)));
      adolescentData.push(parseFloat((totalCostSc * (data.costs[data.costs.length - 1].percentageAdolescent / 100)).toFixed(0)));

      adultData.push(parseFloat((totalCost * (data.costs[0].percentageAdults / 100)).toFixed(0)));
      adultData.push(parseFloat((totalCostSc * (data.costs[data.costs.length - 1].percentageAdults / 100)).toFixed(0)));

      olderAdultData.push(parseFloat((totalCost * (data.costs[0].percentageOlderAdults / 100)).toFixed(0)));
      olderAdultData.push(parseFloat((totalCostSc * (data.costs[data.costs.length - 1].percentageOlderAdults / 100)).toFixed(0)));

      preventionData.push(pepDrugCost + prepDemandDrugCost + prepExDrugCost + prepDiscontinuedDrugCost);
      preventionDataAll.push(pepDrugCost + prepDemandDrugCost + prepExDrugCost + prepDiscontinuedDrugCost);

      preventionDataSc.push(pepDrugCostSc + prepDemandDrugCostSc + prepExDrugCostSc + prepDiscontinuedDrugCostSc);
      preventionDataAll.push(pepDrugCostSc + prepDemandDrugCostSc + prepExDrugCostSc + prepDiscontinuedDrugCostSc);

      laboratoryData.push(laboratoryAllCost);
      laboratoryDataAll.push(laboratoryAllCost);

      laboratoryDataSc.push(laboratoryAllCostSc);
      laboratoryDataAll.push(laboratoryAllCostSc);

      staffData.push(staffAllCost);
      staffDataAll.push(staffAllCost);

      staffDataSc.push(staffAllCostSc);
      staffDataAll.push(staffAllCostSc);

      firstData.push(naiveFirstCost + experiencedFirstCost + removedFirstCost);
      firstDataAll.push(naiveFirstCost + experiencedFirstCost + removedFirstCost);

      firstDataSc.push(naiveFirstCostSc + experiencedFirstCostSc + removedFirstCostSc);
      firstDataAll.push(naiveFirstCostSc + experiencedFirstCostSc + removedFirstCostSc);

      secondData.push(naiveSecondCost + experiencedSecondCost + removedSecondCost);
      secondDataAll.push(naiveSecondCost + experiencedSecondCost + removedSecondCost);

      secondDataSc.push(naiveSecondSc + experiencedSecondCostSc + removedSecondCostSc);
      secondDataAll.push(naiveSecondSc + experiencedSecondCostSc + removedSecondCostSc);

      if (isFirst) {
        isFirst = false;

        firstCostNumber = naiveFirstCost + experiencedFirstCost + removedFirstCost;
        secondCostNumber = naiveSecondCost + experiencedSecondCost + removedSecondCost;
        laboratoryCostNumber = laboratoryAllCost;
        overheadCostNumber = staffAllCost;
        preventionCostNumber = pepDrugCost + prepDemandDrugCost + prepExDrugCost + prepDiscontinuedDrugCost;

        firstCostNumberSc = naiveFirstCostSc + experiencedFirstCostSc + removedFirstCostSc;
        secondCostNumberSc = naiveSecondCostSc + experiencedSecondCostSc + removedSecondCostSc;
        laboratoryCostNumberSc = laboratoryAllCostSc;
        overheadCostNumberSc = staffAllCostSc;
        preventionCostNumberSc = pepDrugCostSc + prepDemandDrugCostSc + prepExDrugCostSc + prepDiscontinuedDrugCostSc;

        this.firstCost = this.getCurrency(naiveFirstCost + experiencedFirstCost + removedFirstCost);
        this.secondCost = this.getCurrency(naiveSecondCost + experiencedSecondCost + removedSecondCost);
        this.laboratoryCost = this.getCurrency(laboratoryAllCost);
        this.overheadCost = this.getCurrency(staffAllCost);
        this.preventionCost = this.getCurrency(pepDrugCost + prepDemandDrugCost + prepExDrugCost + prepDiscontinuedDrugCost);
        this.totalCost = this.getCurrency(totalCost);

        this.firstCostSc = this.getCurrency(naiveFirstCostSc + experiencedFirstCostSc + removedFirstCostSc);
        this.secondCostSc = this.getCurrency(naiveSecondCostSc + experiencedSecondCostSc + removedSecondCostSc);
        this.laboratoryCostSc = this.getCurrency(laboratoryAllCostSc);
        this.overheadCostSc = this.getCurrency(staffAllCostSc);
        this.preventionCostSc = this.getCurrency(pepDrugCostSc + prepDemandDrugCostSc + prepExDrugCostSc + prepDiscontinuedDrugCostSc);
        this.totalCostSc = this.getCurrency(totalCostSc);
      }


      console.log('year : ' + key);
      console.log('naiveFirst CP: ' + naiveFirst);
      console.log('naiveSecond CP: ' + naiveSecond);
      console.log('experiencedFirst CP: ' + experiencedFirst);
      console.log('experiencedSecond CP: ' + experiencedSecond);
      console.log('naiveFirst Sc: ' + naiveFirstSc);
      console.log('naiveSecond Sc: ' + naiveSecondSc);
      console.log('experiencedFirst Sc: ' + experiencedFirstSc);
      console.log('experiencedSecond Sc: ' + experiencedSecondSc);
    }

    this.chartPieCostOptions = {
      series: [
        firstCostNumber,
        secondCostNumber,
        laboratoryCostNumber,
        overheadCostNumber,
        preventionCostNumber],
      chart: {
        width: 380,
        type: 'pie'
      },
      colors: ['#008efb', '#00e397', '#feb219', '#6b0914', '#785dd0'],
      labels: [
        this.translate.instant('SIMULATION.COST.FIRST_LINE'),
        this.translate.instant('SIMULATION.COST.SECOND_LINE'),
        this.translate.instant('SIMULATION.COST.LABORATORY'),
        this.translate.instant('SIMULATION.COST.STAFF'),
        this.translate.instant('SIMULATION.COST.PREVENTION')],
      tooltip: {
        y: {
          formatter: (value) => new Intl.NumberFormat('en-US',
            { minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
            .format(value)
        }
      },
      legend: {
        position: 'bottom',
        labels: {
          colors: ['#785dd0', '#008efb', '#00e397', '#feb219', '#6b0914'],
          useSeriesColors: false
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    };

    this.chartPieCostScOptions = {
      series: [
        firstCostNumberSc,
        secondCostNumberSc,
        laboratoryCostNumberSc,
        overheadCostNumberSc,
        preventionCostNumberSc],
      chart: {
        width: 380,
        type: 'pie'
      },
      colors: ['#008efb', '#00e397', '#feb219', '#6b0914', '#785dd0'],
      dataLabels: {
        formatter: (val: any,
          opts: { w: { config: { series: { [x: string]: any } } }; seriesIndex: string | number }) =>
          new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(opts.w.config.series[opts.seriesIndex])
      },
      labels: [
        this.translate.instant('SIMULATION.COST.FIRST_LINE'),
        this.translate.instant('SIMULATION.COST.SECOND_LINE'),
        this.translate.instant('SIMULATION.COST.LABORATORY'),
        this.translate.instant('SIMULATION.COST.STAFF'),
        this.translate.instant('SIMULATION.COST.PREVENTION')],
      tooltip: {
        y: {
          formatter: (value) => new Intl.NumberFormat('en-US',
            { minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
            .format(value)
        }
      },
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          options: {
            chart: {
              width: 1000
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    };

    this.chartTotalOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: totalData,
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: totalDataSc,
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yearsOnly
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.COST.NAME')
        },
        labels: {
          formatter: (value) => new Intl.NumberFormat('en-US',
            { minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
            .format(value)
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
        formatter: (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
      }
    };

    this.chartTotalPatientOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.NAIVE'),
          type: 'column',
          data: naiveData
        },
        {
          name: this.translate.instant('SIMULATION.TX_EXP'),
          type: 'column',
          data: experiencedData
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.COST.NAME')
        },
        labels: {
          formatter: (value) => new Intl.NumberFormat('en-US',
            { minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
            .format(value)
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartTotalAgeOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.DEMAND.CHILDREN'),
          type: 'column',
          data: childrenData
        },
        {
          name: this.translate.instant('SIMULATION.DEMAND.ADOLESCENTS'),
          type: 'column',
          data: adolescentData
        },
        {
          name: this.translate.instant('SIMULATION.DEMAND.ADULT'),
          type: 'column',
          data: adultData
        },
        {
          name: this.translate.instant('SIMULATION.DEMAND.OLDER_ADULT'),
          type: 'column',
          data: olderAdultData
        }
      ],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: false,
        },
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.COST.NAME')
        },
        labels: {
          formatter: (value) => new Intl.NumberFormat('en-US',
            { minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
            .format(value)
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      }
    };

    this.chartTotalExpenseOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.COST.FIRST_LINE'),
          type: 'column',
          data: firstDataAll
        },
        {
          name: this.translate.instant('SIMULATION.COST.SECOND_LINE'),
          type: 'column',
          data: secondDataAll
        },
        {
          name: this.translate.instant('SIMULATION.COST.LABORATORY'),
          type: 'column',
          data: laboratoryDataAll
        },
        {
          name: this.translate.instant('SIMULATION.COST.STAFF'),
          type: 'column',
          data: staffDataAll
        },
        {
          name: this.translate.instant('SIMULATION.COST.PREVENTION'),
          type: 'column',
          data: preventionDataAll
        }
      ],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: false,
        },
      },
      xaxis: {
        categories: years
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.COST.NAME')
        },
        labels: {
          formatter: (value) => new Intl.NumberFormat('en-US',
            { minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
            .format(value)
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      }
    };

    this.chartTotalFirstOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: firstData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: firstDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yearsOnly
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.COST.NAME')
        },
        labels: {
          formatter: (value) => new Intl.NumberFormat('en-US',
            { minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
            .format(value)
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartTotalSecondOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: secondData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: secondDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yearsOnly
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.COST.NAME')
        },
        labels: {
          formatter: (value) => new Intl.NumberFormat('en-US',
            { minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
            .format(value)
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartTotalLaboratoryOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: laboratoryData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: laboratoryDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yearsOnly
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.COST.NAME')
        },
        labels: {
          formatter: (value) => new Intl.NumberFormat('en-US',
            { minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
            .format(value)
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartTotalOverheadOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: staffData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: staffDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yearsOnly
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.COST.NAME')
        },
        labels: {
          formatter: (value) => new Intl.NumberFormat('en-US',
            { minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
            .format(value)
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };

    this.chartTotalPreventionOptions = {
      series: [
        {
          name: this.translate.instant('SIMULATION.CURRENT_PRACTICE'),
          type: 'column',
          data: preventionData
        },
        {
          name: this.translate.instant('SIMULATION.SCENARIO'),
          type: 'column',
          data: preventionDataSc
        }
      ],
      chart: {
        type: 'bar',
        fontFamily: 'Poppins,sans-serif',
        height: 347
      },
      grid: {
        borderColor: 'rgba(0,0,0,.2)',
        strokeDashArray: 3,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: yearsOnly
      },
      yaxis: {
        title: {
          text: this.translate.instant('SIMULATION.COST.NAME')
        },
        labels: {
          formatter: (value) => new Intl.NumberFormat('en-US',
            { minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
            .format(value)
        }
      },
      legend: {
        position: 'bottom',
        offsetX: 0
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        theme: 'light',
        fillSeriesColor: false,
        marker: {
          show: false,
        },
      }
    };
  }

  getCurrency(cost) {
    return new Intl.NumberFormat('en-US',
      { minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency', currency: 'USD' }).format(cost);
  }

  getArray(mapArray: Array<SimulationResultCostResponse>, type: string, baseline: boolean): Map<string, Array<Pair>>[] {
    const returnArrayMap: Map<string, Array<Pair>>[] = [];
    const arraySize = Math.floor(mapArray.length / 2);
    let startIndex = 0;
    let finishIndex = arraySize;
    if (!baseline) {
      startIndex = startIndex + arraySize;
      finishIndex = finishIndex + arraySize;
    }
    for (let i = startIndex; i < finishIndex; i++) {
      if (type === 'therapyLine') {
        returnArrayMap.push(mapArray[i].yearlyTherapyLineMap);
      } else if (type === 'demand') {
        returnArrayMap.push(mapArray[i].visitsMapByDemand);
      } else if (type === 'viral') {
        returnArrayMap.push(mapArray[i].viralLoadPatientMap);
      } else if (type === 'netPatient') {
        returnArrayMap.push(mapArray[i].yearlyNetPatientMap);
      } else if (type === 'discontinued') {
        returnArrayMap.push(mapArray[i].yearlyDiscontinuedMap);
      }
    }
    return returnArrayMap;
  }

  getAverage(mapArray: Map<string, Array<Pair>>[]): Map<string, Array<Pair>> {
    const averageMap: Map<string, Array<Pair>> = new Map<string, Array<Pair>>();
    const mapSize = mapArray.length;
    mapArray.forEach(map => {
      const ttt = new Map(Object.entries(map));
      for (const key of ttt.keys()) {
        if (averageMap.get(key)) {
          const averagePairs: Pair[] = averageMap.get(key);
          const newPairs: Pair[] = [];
          const pairs: Pair[] = ttt.get(key);
          for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i];
            const averagePair = averagePairs[i];
            const second = averagePair.second + pair.second / mapSize;
            newPairs.push({ first: pair.first, second: parseFloat(second.toFixed(2)) });
          }
          averageMap.set(key, newPairs);
        } else {
          const newPairs: Pair[] = [];
          const pairs: Pair[] = ttt.get(key);
          pairs.forEach(pair => {
            newPairs.push({ first: pair.first, second: parseFloat((pair.second / mapSize).toFixed(2)) });
          });
          averageMap.set(key, newPairs);
        }
      }
    });
    return averageMap;
  }

}
