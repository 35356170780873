<h1 mat-dialog-title>Resource</h1>
<div mat-dialog-content>
    <mat-tab-group>
        <mat-tab label="Doctor">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <mat-card fxFlex="50">
                    <mat-card-header>
                        <mat-card-title>
                            Simulation
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Diagnose (Naive)</mat-label>
                                    <input matInput [(ngModel)]="data.doctor.diagnoseNaive" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                                <mat-form-field fxFlex="45">
                                    <mat-label>Monitoring (Naive)</mat-label>
                                    <input matInput [(ngModel)]="data.doctor.monitoringNaive" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Monitoring (Experienced)</mat-label>
                                    <input matInput [(ngModel)]="data.doctor.monitoringExperienced" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                                <mat-form-field fxFlex="45">
                                    <mat-label>Counselling</mat-label>
                                    <input matInput [(ngModel)]="data.doctor.counselling" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Prevention</mat-label>
                                    <input matInput [(ngModel)]="data.doctor.prevention" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card fxFlex="50">
                    <mat-card-header>
                        <mat-card-title>
                            Scenario
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Diagnose (Naive)</mat-label>
                                <input matInput [(ngModel)]="data.doctorSc.diagnoseNaive" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                            <mat-form-field fxFlex="45">
                                <mat-label>Monitoring (Naive)</mat-label>
                                <input matInput [(ngModel)]="data.doctorSc.monitoringNaive" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Monitoring (Experienced)</mat-label>
                                <input matInput [(ngModel)]="data.doctorSc.monitoringExperienced" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                            <mat-form-field fxFlex="45">
                                <mat-label>Counselling</mat-label>
                                <input matInput [(ngModel)]="data.doctorSc.counselling" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Prevention</mat-label>
                                <input matInput [(ngModel)]="data.doctorSc.prevention" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            </div>
        </mat-tab>

        <mat-tab label="Pharmacist">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <mat-card fxFlex="50">
                    <mat-card-header>
                        <mat-card-title>
                            Simulation
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Diagnose (Naive)</mat-label>
                                    <input matInput [(ngModel)]="data.pharmacist.diagnoseNaive" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                                <mat-form-field fxFlex="45">
                                    <mat-label>Monitoring (Naive)</mat-label>
                                    <input matInput [(ngModel)]="data.pharmacist.monitoringNaive" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Monitoring (Experienced)</mat-label>
                                    <input matInput [(ngModel)]="data.pharmacist.monitoringExperienced" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                                <mat-form-field fxFlex="45">
                                    <mat-label>Counselling</mat-label>
                                    <input matInput [(ngModel)]="data.pharmacist.counselling" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Prevention</mat-label>
                                    <input matInput [(ngModel)]="data.pharmacist.prevention" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card fxFlex="50">
                    <mat-card-header>
                        <mat-card-title>
                            Scenario
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Diagnose (Naive)</mat-label>
                                <input matInput [(ngModel)]="data.pharmacistSc.diagnoseNaive" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                            <mat-form-field fxFlex="45">
                                <mat-label>Monitoring (Naive)</mat-label>
                                <input matInput [(ngModel)]="data.pharmacistSc.monitoringNaive" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Monitoring (Experienced)</mat-label>
                                <input matInput [(ngModel)]="data.pharmacistSc.monitoringExperienced" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                            <mat-form-field fxFlex="45">
                                <mat-label>Counselling</mat-label>
                                <input matInput [(ngModel)]="data.pharmacistSc.counselling" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Prevention</mat-label>
                                <input matInput [(ngModel)]="data.pharmacistSc.prevention" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            </div>
        </mat-tab>

        <mat-tab label="Medical Lab Scientist">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <mat-card fxFlex="50">
                    <mat-card-header>
                        <mat-card-title>
                            Simulation
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Diagnose (Naive)</mat-label>
                                    <input matInput [(ngModel)]="data.medicalLabScientist.diagnoseNaive" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                                <mat-form-field fxFlex="45">
                                    <mat-label>Monitoring (Naive)</mat-label>
                                    <input matInput [(ngModel)]="data.medicalLabScientist.monitoringNaive" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Monitoring (Experienced)</mat-label>
                                    <input matInput [(ngModel)]="data.medicalLabScientist.monitoringExperienced" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                                <mat-form-field fxFlex="45">
                                    <mat-label>Counselling</mat-label>
                                    <input matInput [(ngModel)]="data.medicalLabScientist.counselling" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Prevention</mat-label>
                                    <input matInput [(ngModel)]="data.medicalLabScientist.prevention" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card fxFlex="50">
                    <mat-card-header>
                        <mat-card-title>
                            Scenario
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Diagnose (Naive)</mat-label>
                                <input matInput [(ngModel)]="data.medicalLabScientistSc.diagnoseNaive" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                            <mat-form-field fxFlex="45">
                                <mat-label>Monitoring (Naive)</mat-label>
                                <input matInput [(ngModel)]="data.medicalLabScientistSc.monitoringNaive" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Monitoring (Experienced)</mat-label>
                                <input matInput [(ngModel)]="data.medicalLabScientistSc.monitoringExperienced" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                            <mat-form-field fxFlex="45">
                                <mat-label>Counselling</mat-label>
                                <input matInput [(ngModel)]="data.medicalLabScientistSc.counselling" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Prevention</mat-label>
                                <input matInput [(ngModel)]="data.medicalLabScientistSc.prevention" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            </div>
        </mat-tab>

        <mat-tab label="Hiv Specialist Nurse">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <mat-card fxFlex="50">
                    <mat-card-header>
                        <mat-card-title>
                            Simulation
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Diagnose (Naive)</mat-label>
                                    <input matInput [(ngModel)]="data.hivSpecialistNurse.diagnoseNaive" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                                <mat-form-field fxFlex="45">
                                    <mat-label>Monitoring (Naive)</mat-label>
                                    <input matInput [(ngModel)]="data.hivSpecialistNurse.monitoringNaive" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Monitoring (Experienced)</mat-label>
                                    <input matInput [(ngModel)]="data.hivSpecialistNurse.monitoringExperienced" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                                <mat-form-field fxFlex="45">
                                    <mat-label>Counselling</mat-label>
                                    <input matInput [(ngModel)]="data.hivSpecialistNurse.counselling" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Prevention</mat-label>
                                    <input matInput [(ngModel)]="data.hivSpecialistNurse.prevention" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card fxFlex="50">
                    <mat-card-header>
                        <mat-card-title>
                            Scenario
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Diagnose (Naive)</mat-label>
                                <input matInput [(ngModel)]="data.hivSpecialistNurseSc.diagnoseNaive" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                            <mat-form-field fxFlex="45">
                                <mat-label>Monitoring (Naive)</mat-label>
                                <input matInput [(ngModel)]="data.hivSpecialistNurseSc.monitoringNaive" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Monitoring (Experienced)</mat-label>
                                <input matInput [(ngModel)]="data.hivSpecialistNurseSc.monitoringExperienced" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                            <mat-form-field fxFlex="45">
                                <mat-label>Counselling</mat-label>
                                <input matInput [(ngModel)]="data.hivSpecialistNurseSc.counselling" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Prevention</mat-label>
                                <input matInput [(ngModel)]="data.hivSpecialistNurseSc.prevention" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            </div>
        </mat-tab>

        <mat-tab label="Dietician">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <mat-card fxFlex="50">
                    <mat-card-header>
                        <mat-card-title>
                            Simulation
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Diagnose (Naive)</mat-label>
                                    <input matInput [(ngModel)]="data.dietician.diagnoseNaive" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                                <mat-form-field fxFlex="45">
                                    <mat-label>Monitoring (Naive)</mat-label>
                                    <input matInput [(ngModel)]="data.dietician.monitoringNaive" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Monitoring (Experienced)</mat-label>
                                    <input matInput [(ngModel)]="data.dietician.monitoringExperienced" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                                <mat-form-field fxFlex="45">
                                    <mat-label>Counselling</mat-label>
                                    <input matInput [(ngModel)]="data.dietician.counselling" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Prevention</mat-label>
                                    <input matInput [(ngModel)]="data.dietician.prevention" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card fxFlex="50">
                    <mat-card-header>
                        <mat-card-title>
                            Scenario
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Diagnose (Naive)</mat-label>
                                <input matInput [(ngModel)]="data.dieticianSc.diagnoseNaive" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                            <mat-form-field fxFlex="45">
                                <mat-label>Monitoring (Naive)</mat-label>
                                <input matInput [(ngModel)]="data.dieticianSc.monitoringNaive" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Monitoring (Experienced)</mat-label>
                                <input matInput [(ngModel)]="data.dieticianSc.monitoringExperienced" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                            <mat-form-field fxFlex="45">
                                <mat-label>Counselling</mat-label>
                                <input matInput [(ngModel)]="data.dieticianSc.counselling" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Prevention</mat-label>
                                <input matInput [(ngModel)]="data.dieticianSc.prevention" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            </div>
        </mat-tab>

        <mat-tab label="General Nurse">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <mat-card fxFlex="50">
                    <mat-card-header>
                        <mat-card-title>
                            Simulation
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Diagnose (Naive)</mat-label>
                                    <input matInput [(ngModel)]="data.generalNurse.diagnoseNaive" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                                <mat-form-field fxFlex="45">
                                    <mat-label>Monitoring (Naive)</mat-label>
                                    <input matInput [(ngModel)]="data.generalNurse.monitoringNaive" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Monitoring (Experienced)</mat-label>
                                    <input matInput [(ngModel)]="data.generalNurse.monitoringExperienced" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                                <mat-form-field fxFlex="45">
                                    <mat-label>Counselling</mat-label>
                                    <input matInput [(ngModel)]="data.generalNurse.counselling" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Prevention</mat-label>
                                    <input matInput [(ngModel)]="data.generalNurse.prevention" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card fxFlex="50">
                    <mat-card-header>
                        <mat-card-title>
                            Scenario
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Diagnose (Naive)</mat-label>
                                <input matInput [(ngModel)]="data.generalNurseSc.diagnoseNaive" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                            <mat-form-field fxFlex="45">
                                <mat-label>Monitoring (Naive)</mat-label>
                                <input matInput [(ngModel)]="data.generalNurseSc.monitoringNaive" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Monitoring (Experienced)</mat-label>
                                <input matInput [(ngModel)]="data.generalNurseSc.monitoringExperienced" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                            <mat-form-field fxFlex="45">
                                <mat-label>Counselling</mat-label>
                                <input matInput [(ngModel)]="data.generalNurseSc.counselling" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Prevention</mat-label>
                                <input matInput [(ngModel)]="data.generalNurseSc.prevention" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            </div>
        </mat-tab>

        <mat-tab label="Counselor">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <mat-card fxFlex="50">
                    <mat-card-header>
                        <mat-card-title>
                            Simulation
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Diagnose (Naive)</mat-label>
                                    <input matInput [(ngModel)]="data.counselor.diagnoseNaive" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                                <mat-form-field fxFlex="45">
                                    <mat-label>Monitoring (Naive)</mat-label>
                                    <input matInput [(ngModel)]="data.counselor.monitoringNaive" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Monitoring (Experienced)</mat-label>
                                    <input matInput [(ngModel)]="data.counselor.monitoringExperienced" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                                <mat-form-field fxFlex="45">
                                    <mat-label>Counselling</mat-label>
                                    <input matInput [(ngModel)]="data.counselor.counselling" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                <mat-form-field fxFlex="45">
                                    <mat-label>Prevention</mat-label>
                                    <input matInput [(ngModel)]="data.counselor.prevention" type="number">
                                    <span matSuffix>min</span>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card fxFlex="50">
                    <mat-card-header>
                        <mat-card-title>
                            Scenario
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutAlign="space-around center">
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Diagnose (Naive)</mat-label>
                                <input matInput [(ngModel)]="data.counselorSc.diagnoseNaive" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                            <mat-form-field fxFlex="45">
                                <mat-label>Monitoring (Naive)</mat-label>
                                <input matInput [(ngModel)]="data.counselorSc.monitoringNaive" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Monitoring (Experienced)</mat-label>
                                <input matInput [(ngModel)]="data.counselorSc.monitoringExperienced" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                            <mat-form-field fxFlex="45">
                                <mat-label>Counselling</mat-label>
                                <input matInput [(ngModel)]="data.counselorSc.counselling" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="45">
                                <mat-label>Prevention</mat-label>
                                <input matInput [(ngModel)]="data.counselorSc.prevention" type="number">
                                <span matSuffix>min</span>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

<div mat-dialog-actions>
    <div fxLayout="row" fxLayoutAlign="end center" class="full-width">
        <button mat-raised-button (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="secondary" [mat-dialog-close]="data" cdkFocusInitial color="accent">Ok</button>
    </div>
</div>