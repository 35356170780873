import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimulationCountrySelection } from '../../model/simulation';

@Component({
  selector: 'app-country-dialog',
  templateUrl: './country-dialog.component.html',
  styleUrls: ['./country-dialog.component.scss']
})
export class CountryDialogComponent implements OnInit {

  selectedChoice: string;
  existingChoice: string;

  constructor(
    public dialogRef: MatDialogRef<CountryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SimulationCountrySelection) {
      this.selectedChoice = data.country;
      this.existingChoice = data.country;
     }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSelectCountry(value) {
    this.data.costs.forEach(cost => {
      if (cost.country === value) {
        this.data.selectedCost = cost;
        this.data.country = cost.country;
        this.data.code = cost.code;
        this.data.flag = cost.flag;
      }
    });
  }

  onUpdateDefault(value) {
    this.data.updateDefault = value;
  }

  public isUpdatable() {
    return this.selectedChoice !== this.existingChoice;
  }

}
