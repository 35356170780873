import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ThirdPartyRoutingModule } from './third-party-routing.module';
import { Dhis2MainComponent } from './pages/dhis2-main/dhis2-main.component';
import { OpenmrsMainComponent } from './pages/openmrs-main/openmrs-main.component';



@NgModule({
  declarations: [
    Dhis2MainComponent,
    OpenmrsMainComponent
  ],
  imports: [
    CommonModule,
    ThirdPartyRoutingModule,
    SharedModule
  ]
})
export class ThirdPartyModule { }
