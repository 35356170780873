<ng-container *ngIf="showQuestionGroup">
<!-- <mat-card class="question-group-card cust-darker-shadow" *ngIf="showQuestionGroup"> -->
    <!-- <mat-card-header class="bg-danger text-white"> -->
        <h2 class="content-box__title mb-16">{{questionGroup.name}}</h2>
    <!-- </mat-card-header> -->
    <!-- <mat-card-content class="question-list-container"> -->
        <ng-container *ngFor="let q of questionGroup.questions">
            <app-question [question]="q" [questionGroups]="questionGroups"></app-question>
        </ng-container>
    <!-- </mat-card-content> -->
<!-- </mat-card> -->
</ng-container>