<div class="title-container">
    <span>{{'USER_ADMIN.DETAIL.TITLE' | translate}}</span>
    <small>{{'ENUM.PAGE_MODE.' + PageMode[this.data.pageMode] | translate}}</small>
</div>

<!-- ============================================================== -->
<!-- User Summary-->
<!-- ============================================================== -->
<div class="m-b-40" fxLayout="row wrap">
    <img class="m-r-15 shrink-none img-circle" [src]='photoUrl' width="60">
    <div class="media-body">
        <h5 class="m-t-0 m-b-10 font-medium">{{data.row.email? data.row.email : data.row.phoneNumber}}</h5>
        <div class="role-select-container">
            <app-role [roleValue]="data.row.roles" (roleChanged)="onRoleChanged($event)" [componentMode]="roleComponentMode"></app-role>
        </div>
        <small *ngIf="data.row.lastLoginAt">{{('USER_ADMIN.DETAIL.FORM.LAST_LOGIN_AT' | translate) + data.row.lastLoginAt}}</small>
    </div>
</div>

<mat-dialog-content>

    <form [formGroup]="formGroup" autocomplete="on" novalidate>
        <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'USER_ADMIN.DETAIL.FORM.EMAIL' | translate }}</mat-label>
                <input matInput formControlName="email">
            </mat-form-field>
            <app-validation-message [control]="formGroup.get('email')"></app-validation-message>
        </div>
        <div class="pb-1 cust-form-field-container m-b-15">
            <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'USER_ADMIN.DETAIL.FORM.PHONE_NUMBER' | translate }}</mat-label>
                <ngx-mat-intl-tel-input
                  [enablePlaceholder]="true"
                  [enableSearch]="true"
                  name="phoneNumber"
                  formControlName="phoneNumber" 
                  #phoneComp>
                </ngx-mat-intl-tel-input>
            </mat-form-field>
            <mat-hint>e.g. {{phoneComp.selectedCountry.placeHolder}}</mat-hint>
            <app-validation-message [control]="formGroup.get('phoneNumber')"></app-validation-message>
        </div>

        <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'USER_ADMIN.DETAIL.FORM.FIRST_NAME' | translate }}</mat-label>
                <input matInput formControlName="firstName">
            </mat-form-field>
            <app-validation-message [control]="formGroup.get('firstName')"></app-validation-message>
        </div>
        <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'USER_ADMIN.DETAIL.FORM.LAST_NAME' | translate }}</mat-label>
                <input matInput formControlName="lastName">
            </mat-form-field>
            <app-validation-message [control]="formGroup.get('lastName')"></app-validation-message>
        </div>
        <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'USER_ADMIN.DETAIL.FORM.USER_UNIQUE_ID' | translate }}</mat-label>
                <input matInput formControlName="userUniqueId">
            </mat-form-field>
            <app-validation-message [control]="formGroup.get('userUniqueId')"></app-validation-message>
        </div>
        <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'USER_ADMIN.DETAIL.FORM.PATIENT_ORGANISATION_NAME' | translate }}</mat-label>
                <input matInput formControlName="patientOrganisationName">
            </mat-form-field>
            <app-validation-message [control]="formGroup.get('patientOrganisationName')"></app-validation-message>
        </div>
        <div class="pb-1 cust-form-field-container">
            <mat-form-field appearance="outline" class="cust-form-field">
                <mat-label>{{ 'USER_ADMIN.DETAIL.FORM.CLINICIAN_ORGANISATION_NAME' | translate }}</mat-label>
                <input matInput formControlName="clinicianOrganisationName">
            </mat-form-field>
            <app-validation-message [control]="formGroup.get('clinicianOrganisationName')"></app-validation-message>
        </div>
        
        <div class="pb-1 cust-form-field-container" fxLayout="row" fxLayoutWrap="wrap">
            <div fxFlex.gt-sm="50" fxFlex="50">
                <mat-checkbox color="primary" formControlName="emailConfirmed">{{ 'USER_ADMIN.DETAIL.FORM.EMAIL_CONFIRMED' | translate }}</mat-checkbox>
                <app-validation-message [control]="formGroup.get('emailConfirmed')"></app-validation-message>
            </div>
            <div fxFlex.gt-sm="50" fxFlex="50">
                <mat-checkbox color="primary" formControlName="phoneNumberConfirmed">{{ 'USER_ADMIN.DETAIL.FORM.PHONE_NUMBER_CONFIRMED' | translate }}</mat-checkbox>
                <app-validation-message [control]="formGroup.get('phoneNumberConfirmed')"></app-validation-message>
            </div>
        </div>
    
        <div class="pb-1 cust-form-field-container" fxLayout="row" fxLayoutWrap="wrap">

            <div fxFlex.gt-sm="50" fxFlex="50">
                <mat-checkbox color="primary" formControlName="enabled">{{ 'USER_ADMIN.DETAIL.FORM.ENABLED' | translate }}</mat-checkbox>
                <app-validation-message [control]="formGroup.get('enabled')"></app-validation-message>
            </div>
            <div fxFlex.gt-sm="50" fxFlex="50">
                <mat-checkbox color="primary" formControlName="active">{{ 'USER_ADMIN.DETAIL.FORM.ACTIVE' | translate }}</mat-checkbox>
                <app-validation-message [control]="formGroup.get('active')"></app-validation-message>
            </div>
        </div>
        
        <div class="pb-1 cust-form-field-container" fxLayout="row" fxLayoutWrap="wrap">
            <div fxFlex.gt-sm="100" fxFlex="100">
                <mat-checkbox color="primary" formControlName="otpEnabled">{{ 'USER_ADMIN.DETAIL.FORM.OTP_ENABLED' | translate }}</mat-checkbox>
                <app-validation-message [control]="formGroup.get('otpEnabled')"></app-validation-message>
            </div>
        </div>

    </form>
    
</mat-dialog-content>


<mat-dialog-actions>

    <button mat-raised-button color="warn" type="submit" class="btn-lg" *ngIf="this.data.pageMode === PageMode.EDIT"
        (click)="onEdit()" [disabled]="!formGroup.valid">
        {{ 'GENERAL.EDIT' | translate }}</button>
    <button mat-raised-button color="warn" type="submit" class="btn-lg" *ngIf="this.data.pageMode === PageMode.DELETE"
        (click)="onDelete()">
        {{ 'GENERAL.DELETE' | translate }}</button>
    <button mat-raised-button color="basic" class="btn-lg m-l-10" (click)="closeDialog(false)">
        {{ 'GENERAL.CANCEL' | translate }}</button>
</mat-dialog-actions>