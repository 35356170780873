/* eslint-disable max-len */
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../../shared/module/notification/service/notification.service';
import {FeedbackService} from '../../../../shared/services/feedback.service';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';

@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.scss']
})
export class FeedbackListComponent extends BaseListComponent implements OnInit {

  @ViewChild('idTemplate', {static: true}) idTemplate: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private feedbackService: FeedbackService
  ) {
    super();
  }

  ngOnInit(): void {
    this.allColumnList = [
      {
        prop: 'id',
        name: this.translate.instant('FEEDBACK_LIST.COL_LBL.ID'),
        cellTemplate: this.idTemplate,
        width: 100,
        maxWidth: 100
      },
      {
        prop: 'userId',
        name: this.translate.instant('FEEDBACK_LIST.COL_LBL.USER_ID'),
        cellTemplate: this.idTemplate,
        width: 100,
        maxWidth: 100
      },

      {
        prop: 'ques0001',
        name: this.translate.instant('FEEDBACK_LIST.COL_LBL.QUES_0001'),
        cellClass: ''
      },

      {
        prop: 'ques0002',
        name: this.translate.instant('FEEDBACK_LIST.COL_LBL.QUES_0002'),
        cellClass: ''
      },
      {
        prop: 'ques0003',
        name: this.translate.instant('FEEDBACK_LIST.COL_LBL.QUES_0003'),
        cellClass: ''
      },
      {
        prop: 'ques0004',
        name: this.translate.instant('FEEDBACK_LIST.COL_LBL.QUES_0004'),
        cellClass: ''
      },
      {
        prop: 'ques0005',
        name: this.translate.instant('FEEDBACK_LIST.COL_LBL.QUES_0005'),
        cellClass: ''
      },
      {
        prop: 'ques0006',
        name: this.translate.instant('FEEDBACK_LIST.COL_LBL.QUES_0006'),
        cellClass: ''
      },
      {
        prop: 'ques0007',
        name: this.translate.instant('FEEDBACK_LIST.COL_LBL.QUES_0007'),
        cellClass: ''
      },
      {
        prop: 'ques0008',
        name: this.translate.instant('FEEDBACK_LIST.COL_LBL.QUES_0008'),
        cellClass: ''
      },
      {
        prop: 'ques0009',
        name: this.translate.instant('FEEDBACK_LIST.COL_LBL.QUES_0009'),
        cellClass: ''
      },
      {
        prop: 'ques0010',
        name: this.translate.instant('FEEDBACK_LIST.COL_LBL.QUES_0010'),
        cellClass: ''
      },
      {
        prop: 'ques0011',
        name: this.translate.instant('FEEDBACK_LIST.COL_LBL.QUES_0011'),
        cellClass: ''
      },
      {
        prop: 'ques0012',
        name: this.translate.instant('FEEDBACK_LIST.COL_LBL.QUES_0012'),
        cellClass: ''
      },
      {
        prop: 'dateCreated',
        name: this.translate.instant('FEEDBACK_LIST.COL_LBL.DATE_CREATED'),
        cellClass: ''
      }
    ];

    this.columns = [
      this.allColumnList[0],
      this.allColumnList[1],
      this.allColumnList[2],
      this.allColumnList[3],
      this.allColumnList[4],
      this.allColumnList[5],
      this.allColumnList[6],
      this.allColumnList[7]
    ];
    this.loadData();
  }

  loadData() {
    this.feedbackService.getAllFeedbacks(this.page.pageNumber, this.page.size, this.sort).subscribe(page=> {
      this.page.pageNumber = page.number;
      this.page.totalElements = page.totalElements;
      this.page.size = page.size;
      this.rows = page.content;
    });
  }

}
