<div class="photo-upload">
    <div class="popup-title">{{ 'IMAGE_UPLOAD.TITLE' | translate }}</div>
    <div class="photo-popup-content">
        <div class="photo-action">
            <div class="photo-upload-btn">
                {{ 'IMAGE_UPLOAD.CHOOSE_PHOTO' | translate }}
                <input name="file" id="photo-upload-submit" type="file" class="hidden-file popup-cancel" accept="image/gif, image/jpg, image/jpeg, image/png" (change)="onFileChangeEvent($event)">
            </div>
            <div class="photo-delete-block">
                <span class="or">{{ 'IMAGE_UPLOAD.OR' | translate }}</span>
                <span class="photo-remove" (click)="onRemoveImage()">{{ 'IMAGE_UPLOAD.DELETE_PHOTO' | translate }}</span>
            </div>
            <span class="rotate-photo-container" *ngIf="imageLoaded">
                <button mat-button id="rotateLeft" class="rotate-photo-button" (click)="onRotateLeft()">
                    <fa-icon [icon]="faUndoAlt" size="lg"></fa-icon>
                </button>
                <button mat-button id="rotateRight" class="rotate-photo-button" (click)="onRotateRight()">
                    <fa-icon [icon]="faRedoAlt" size="lg"></fa-icon>
                </button>
            </span>
        </div>

        <div class="image-crop-container" ngClass="{{ (imageLoaded) ? 'active' : '' }}">
                <div id="cropperContainer" class="cropper-container cropper-bg">
                    <div class="cropper-wrap-box">
                        <div class="cropper-canvas" #imageCropperCanvas>
                            <image-cropper
                            [imageURL]="imageUrl"
                            [imageChangedEvent]="imageChangedEvent"
                            [maintainAspectRatio]="false"
                            [roundCropper]="false"
                            [cropper]="cropper"
                            [transform]="transform"
                            format="png"
                            (imageCropped)="onImageCropped($event)"
                            (imageLoaded)="onImageLoaded()"
                            (cropperReady)="onCropperReady($event)"
                            (loadImageFailed)="onLoadImageFailed()"
                            [style.display]="imageLoaded ? null : 'none'"
                            class="imageCropperStyle">
                        </image-cropper>
                        </div>
            
                    </div>
                </div>

        </div>
    </div>
    <div class="popup-action">
        <button mat-raised-button color="primary" class="popup-button m-r-20" (click)="onAccept()" [disabled]="!croppedImage">{{ 'IMAGE_UPLOAD.APPLY' | translate }}</button>
        <button mat-raised-button color="warn" class="popup-button" (click)="onCancel()">{{ 'IMAGE_UPLOAD.CANCEL' | translate }}</button>
    </div>
</div>

