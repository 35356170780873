import { Component, EventEmitter, Input, OnDestroy, Output, OnInit } from '@angular/core';
import { FileUploadModel } from '../../model/file-upload-model';

@Component({
  selector: 'app-upload-info',
  templateUrl: './upload-info.component.html',
  styleUrls: ['./upload-info.component.scss']
})
export class UploadInfoComponent implements OnDestroy, OnInit {

  @Input() public fileUploadModel: FileUploadModel;

  @Output() public removeEvent = new EventEmitter<FileUploadModel>();
  @Output() public uploadEvent = new EventEmitter<FileUploadModel>();

  constructor() { }

  ngOnInit() {}

  ngOnDestroy() {
    console.log('file ' + this.fileUploadModel.file.name + ' destroyed...');
  }

  removeFile(): void {
    if (this.fileUploadModel.sub) {
      this.fileUploadModel.sub.unsubscribe();
    }
    this.removeEvent.emit(this.fileUploadModel);
  }

  retryFile() {
    this.uploadEvent.emit(this.fileUploadModel);
    this.fileUploadModel.canRetry = false;
  }


  getErrorStyle() {
    let tempColor = 'black';

    if (this.fileUploadModel.errorMessage) {
      tempColor = 'red';
    } else if (this.fileUploadModel.isCompleted === true && this.fileUploadModel.canRetry === false){
      tempColor = 'green';
    }
    const myStyles = {
      color: tempColor
    };
    return myStyles;
  }

}
