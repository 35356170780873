/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InboxType } from 'src/app/shared/module/invitation/model/invitation-models';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { OrganisationService } from 'src/app/shared/services/organisation.service';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';

@Component({
  selector: 'app-incoming-invitations',
  templateUrl: './incoming-invitations.component.html',
  styleUrls: ['./incoming-invitations.component.scss']
})
export class IncomingInvitationsComponent implements OnInit {
  InboxType: typeof InboxType = InboxType;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private userProfileService: UserProfileService,
    private organisationService: OrganisationService,
    private authenticationStateService: AuthenticationStateService
  ) { }

  ngOnInit(): void {
  }

}
