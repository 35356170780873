/* eslint-disable @typescript-eslint/semi */
import { Component, OnDestroy } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
    template: ''
})
export abstract class BaseComponent implements OnDestroy {

    private subscriptions: Array<Subscription> = [];

    constructor() {}

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    public subscription(subscription: Subscription) {
        this.subscriptions.push(subscription);
    }

    public addAllSubscriptions(subscriptionList: Subscription[]) {
        subscriptionList.forEach(subs => {
            this.subscriptions.push(subs);
        });
    }

    getProperty = (obj, path) => (
        path.split('.').reduce((o, p) => o && o[p], obj)
    )

    createDialogConfig(data): MatDialogConfig {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = false;
        dialogConfig.minWidth = '60vw';
        dialogConfig.maxHeight = '95vh';
        dialogConfig.data = data;
        return dialogConfig;
      }

}
