/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { ApexChartOptions, CHART_COLORS_SCHEME1 } from 'src/app/shared/model/chart-options';
import { Suppression } from 'src/app/shared/model/suppression';

@Component({
  selector: 'app-expectancy-chart',
  templateUrl: './expectancy-chart.component.html',
  styleUrls: ['./expectancy-chart.component.scss']
})
export class ExpectancyChartComponent implements OnInit {
  @Input() suppressionList: Suppression[];
  @Input() suppressionProperty: string;
  @Input() title: string;

  @ViewChild('chartView') chart: ChartComponent = Object.create(null);

  public chartOptions: Partial<ApexChartOptions> = {
    series: [],
    chart: {
      type: 'bar',
      height: 370,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      }
    },
    colors: [],
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        columnWidth: '80%',
        distributed: true,
        dataLabels: {
          position: 'top' // top, center, bottom
        }
      },
    },
    dataLabels: {
      enabled: false,
      formatter: (val) => val ? val + '%' : '',
      offsetY: -20,
      style: {
        fontSize: '10px',
        colors: ['#67757c']
      }
    },
    xaxis: {
      labels: {
        style: {
          colors: [],
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      title: {
        text: 'Percentage(%)'
      },
      labels: {
        formatter: (val) => val ? val.toFixed(1) : '',
      },
      min: 0,
      max: 100
    },
    legend: {
      show: false,
    },
    tooltip: {
      theme: 'light',
      fillSeriesColor: false,
      marker: {
        show: true,
      },
    }
  };
  constructor() { }

  ngOnInit(): void {
    const labels = [];
    const dataSup = [];
    const colors = [];

    let i = 0;
    for (const sup of this.suppressionList) {
      const propValue = sup[this.suppressionProperty];
      if (propValue) {
        labels.push(sup.artCodeNames);
        dataSup.push(propValue);
        colors.push(CHART_COLORS_SCHEME1[i]);
      }
      i++;
    }

    if (dataSup.length > 0) {
      this.chartOptions.xaxis.categories = labels;
      this.chartOptions.series.push({ data: dataSup });
      this.chartOptions.colors = colors;
      this.chartOptions.xaxis.labels.style.colors = colors;
    }
  }

}
