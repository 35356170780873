import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Question, QuestionGroup, QuestionOption } from '../model/question';
import { ClinicalOutcome } from '../model/clinical-outcome';
import { isArray } from '../util';

@Injectable({
    providedIn: 'root'
})
export class QuestionsService {
    questionSelectionChange: Subject<Question> = new Subject();

    constructor(private http: HttpClient) { }

    loadClinicianFormQuestions(): Observable<QuestionGroup[]> {
        return this.http.get('assets/json/clinician-form-questions.json').pipe(map(result => {
            const allQuestionGroups = result as QuestionGroup[];
            return allQuestionGroups;
        }));
    }

    getQuestionSelectionChange(): Observable<Question> {
        return this.questionSelectionChange.asObservable();
    }

    notifyQuestionChange(question: Question) {
        this.questionSelectionChange.next(question);
    }

    fillSelectedOptionsOfQuestions(clinicalOutcome: ClinicalOutcome, questionGroups: QuestionGroup[]) {
        for (const group of questionGroups) {
            for (const question of group.questions) {
                const answer = clinicalOutcome[question.id];
                const isAnswerExist = (isArray(answer) && answer.length > 0 && answer[0]) || (!isArray(answer) && answer);
                if (isAnswerExist) {
                    this.fillSelectedOptions(clinicalOutcome[question.id], question.options);
                }
            }
        }
    }

    fillSelectedOptions(selectedAnswers, options: QuestionOption[]) {
        let answer = selectedAnswers;
        const isAnswerExist = (isArray(answer) && answer.length > 0 && answer[0]) || (!isArray(answer) && answer);

        if (isAnswerExist) {
            if (!isArray(selectedAnswers)) {
                answer = [];
                answer.push(selectedAnswers);
            }
            for (const a of answer) {
                if (options && options.length > 0) { // check DYNAMIC options
                    const foundOption = options.find(option => option.id === a);
                    if (foundOption) {
                        foundOption.selected = true;
                    }
                }
            }
        }
    }

}
