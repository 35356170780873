import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatMainComponent } from './chat-main/chat-main.component';
import { ChatRoutingModule } from './chat-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChatContactListComponent } from './component/chat-contact-list/chat-contact-list.component';
import { ChatMessageComponent } from './component/chat-message/chat-message.component';


@NgModule({
  declarations: [
    ChatMainComponent,
    ChatContactListComponent,
    ChatMessageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ChatRoutingModule
  ]
})
export class ChatModule { }
