/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export enum Role {
  SYSTEM_ADMINISTRATOR = 1,
  CLINICIAN = 2,
  PATIENT = 4,
  STANDALONE_CLINICIAN = 8,
  KIN = 16,
  AT_RISK = 32,
  SMART_HIV_MANAGER = 64,
  SMART_HIV_TRIALIST = 128,
  SMART_HIV_AUDITLYTICS = 256,
  SMART_HIV_CLINICIAN = 512
}
