<div class="login-main">
  <div class="login-content">
    <div class="login-content__bg"></div>
    <div class="login-content__form">
      <!-- <mat-card-content> -->
        <form [formGroup]="formGroup" autocomplete="off" novalidate (ngSubmit)="login()">
          <div class="text-center">
            <img class="company-logo" width="200" height="100" alt="homepage" src="assets/images/logo-wide.svg">
            <!--h4 class="m-t-0 title-text"></h4-->
          </div>
          <!--p class="text-center font-14"></p-->

          <div class="login-form-container" style="margin-top: 30px">
      
            <app-email-or-phone-number></app-email-or-phone-number>

            <app-password-form-field 
            [passwordLabel]="'LOGIN_FORM.PASSWORD'"
            [passwordFormGroupName]="'passwordFormGroup'">
           </app-password-form-field>

            <div class="pb-1 cust-form-field-container">
              <re-captcha
              (resolved)="captchaResolved($event)"
              siteKey="{{recaptchaSiteKey}}"
              formControlName="captcha"></re-captcha>
            </div>

            <div class="login-forgot-password-container">
              <span class="material-icons">lock</span>
              <a (click)="goToPasswordResetOtpGeneration()">{{ 'LOGIN_FORM.FORGOT_PASSWORD' | translate }}</a>
            </div>

            <button mat-raised-button color="warn" type="submit" class="btn-block btn-lg m-t-20 login-button">{{ 'LOGIN_FORM.LOGIN_LABEL' | translate }}</button>
<!--
            <div class="login-registration-otp-container" style="margin-top: 20px">
              <a (click)="goToRegistrationOtpGeneration()">{{ 'LOGIN_FORM.RESEND_REGISTRATION_OTP' | translate }}</a>
            </div>-->

            <!--<div class="login-signup-container">
              <span>{{ 'LOGIN_FORM.SIGN_UP_DESC' | translate }}</span>
              <a (click)="goToRegisterPage()">{{ 'GENERAL.SIGN_UP' | translate }}</a>
            </div>-->
          </div>

        </form>
      <!-- </mat-card-content> -->
    </div>
  </div>
</div>
