<div class="section-title-bar justify-content-between mb-16">
    <h2 class="content-box__title mb-0">{{ 'TRIAL.DEMOGRAPHICS_DASHBOARD' | translate }}</h2>
    <mat-icon matTooltip="{{ 'TRIAL.DEMOGRAPHICS_DASHBOARD' | translate }}">info</mat-icon>
</div>

<div class="content-box mb-24">
    <h2 class="content-box__title mb-16">{{ 'TRIAL.FILTERS' | translate }}</h2>
    
    <div div class="form-box summary-filter-box mb-0 pt-20">
        <div fxFlex="20" fxLayout="column" fxLayoutAlign="space-around center">
            <label class="filter-label">{{ 'TRIAL.TOTAL_PATIENTS' | translate
                }}</label>
            <label class="patient-count-label">{{patientCount}}</label>
        </div>
        <div fxFlex="25">
            <label class="filter-label">{{ 'TRIAL.COUNTRY' | translate }}</label>
            <ng-multiselect-dropdown [placeholder]="'Country'"
                [settings]="dropdownSettingsCountry" [data]="dropdownListCountry"
                [(ngModel)]="selectedItemsCountry"
                (onSelect)="onItemSelectCountry($event)"
                (onDeSelect)="onDeSelectCountry($event)"
                (onDropDownClose)="onDropDownCloseCountry()"
                (click)="dropDownSelectCountry = true">
            </ng-multiselect-dropdown>
        </div>
        <div fxFlex="25">
            <label class="filter-label">{{ 'TRIAL.STATE' | translate }}</label>
            <ng-multiselect-dropdown [placeholder]="'State'"
                [settings]="dropdownSettingsState" [data]="dropdownListState"
                [(ngModel)]="selectedItemsState"
                (onSelect)="onItemSelectState($event)"
                (onDeSelect)="onDeSelectState($event)"
                (onDropDownClose)="onDropDownCloseState()"
                (click)="dropDownSelectState = true">
            </ng-multiselect-dropdown>
        </div>
        <div fxFlex="25">
            <label class="filter-label">{{ 'TRIAL.CENTRE' | translate }}</label>
            <ng-multiselect-dropdown [placeholder]="'Centre'"
                [settings]="dropdownSettingsCentre" [data]="dropdownListCentre"
                [(ngModel)]="selectedItemsCentre"
                (onSelect)="onItemSelectCentre($event)"
                (onDeSelect)="onDeSelectCentre($event)"
                (onDropDownClose)="onDropDownCloseCentre()"
                (click)="dropDownSelectCentre = true">
            </ng-multiselect-dropdown>
        </div>
    </div>
</div>
                                    
<div class="content-box chart mb-24">
    <div class="d-flex justify-content-between">
        <h3 class="content-box__title mb-0">{{ 'TRIAL.RACE_AGE' | translate }}</h3>
        <mat-icon matTooltip="{{ 'TRIAL.RACE_DEMO' | translate }}">info</mat-icon>
    </div>
    <p class="font-size-1-4 font-weight-300 mb-16">{{ 'TRIAL.PATIENTS' | translate }}</p>

    <div id="chartRace" class="d-flex flex-column" *ngIf="chartRaceOptions">
        <apx-chart [series]="chartRaceOptions.series" [chart]="chartRaceOptions.chart"
            [colors]="chartRaceOptions.colors" [dataLabels]="chartRaceOptions.dataLabels"
            [plotOptions]="chartRaceOptions.plotOptions" [yaxis]="chartRaceOptions.yaxis"
            [legend]="chartRaceOptions.legend" [fill]="chartRaceOptions.fill"
            [stroke]="chartRaceOptions.stroke" [tooltip]="chartRaceOptions.tooltip"
            [xaxis]="chartRaceOptions.xaxis" [grid]="chartRaceOptions.grid">
        </apx-chart>
    </div>
</div>

<div class="content-box chart mb-24">
    <div class="d-flex justify-content-between">
        <h3 class="content-box__title mb-0">{{ 'TRIAL.SEX_ADHERENCE' | translate }}</h3>
        <mat-icon matTooltip="{{ 'TRIAL.SEX_ADHERENCE' | translate }}">info</mat-icon>
    </div>
    <p class="font-size-1-4 font-weight-300 mb-16">{{ 'TRIAL.PATIENTS' | translate }}</p>

    <div id="chartSex" class="d-flex flex-column" *ngIf="chartSexOptions">
        <apx-chart [series]="chartSexOptions.series" [chart]="chartSexOptions.chart"
            [colors]="chartSexOptions.colors" [dataLabels]="chartSexOptions.dataLabels"
            [plotOptions]="chartSexOptions.plotOptions" [yaxis]="chartSexOptions.yaxis"
            [legend]="chartSexOptions.legend" [fill]="chartSexOptions.fill"
            [stroke]="chartSexOptions.stroke" [tooltip]="chartSexOptions.tooltip"
            [xaxis]="chartSexOptions.xaxis" [grid]="chartSexOptions.grid">
        </apx-chart>
    </div>
</div>

<div class="content-box chart mb-24">
    <div class="d-flex justify-content-between">
        <h3 class="content-box__title mb-0">{{ 'TRIAL.TYPE_VS' | translate }}</h3>
        <mat-icon matTooltip="{{ 'TRIAL.TYPE_VS' | translate }}">info</mat-icon>
    </div>
    <p class="font-size-1-4 font-weight-300 mb-16">{{ 'TRIAL.PATIENTS' | translate }}</p>

    <div id="chartType" class="d-flex flex-column" *ngIf="chartTypeOptions">
        <apx-chart 
            [series]="chartTypeOptions.series"
            [chart]="chartTypeOptions.chart" 
            [xaxis]="chartTypeOptions.xaxis"
            [colors]="chartTypeOptions.colors" 
            [dataLabels]="chartTypeOptions.dataLabels"
            [legend]="chartTypeOptions.legend" 
            [markers]="chartTypeOptions.markers"
            [grid]="chartTypeOptions.grid" 
            [yaxis]="chartTypeOptions.yaxis"
            [title]="chartTypeOptions.title" 
            [tooltip]="chartTypeOptions.tooltip">
        </apx-chart>
    </div>
</div>

<div class="content-box chart">
    <div class="d-flex justify-content-between">
        <h3 class="content-box__title mb-24">{{ 'TRIAL.DOCTORS' | translate }}</h3>
        <mat-icon matTooltip="{{ 'TRIAL.DOCTORS' | translate }}">info</mat-icon>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-around stretch" class="full-width">
        <div fxFlex="30" fxLayout="column" class="full-width">
            <table mat-table [dataSource]="dataSourceC" class="mat-elevation-z8">

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="example-header"> {{ 'TRIAL.NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <ng-container matColumnDef="count">
                    <th mat-header-cell *matHeaderCellDef class="example-header"> {{ 'TRIAL.COUNT' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{element.count}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsC"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsC;"
                    [ngClass]="{highlighted: selectedRowIndex === row.id}" (click)="selectRow(row)">
                </tr>
            </table>
        </div>
        <div fxFlex="65" fxLayout="column" class="full-width">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows
                class="mat-elevation-z8">
                <ng-container [matColumnDef]="column" *ngFor="let column of columnsToDisplay">
                    <ng-container *ngIf="column !== 'action'; else action">
                        <th mat-header-cell *matHeaderCellDef class="example-header"> {{ 'TRIAL.CENTRE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div>
                                {{element[column]}}
                            </div>
                        </td>
                    </ng-container>
                    <ng-template #action>
                        <th mat-header-cell *matHeaderCellDef class="example-header"> {{ 'TRIAL.ACTIONS' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon (click)="selectExpandedRow(element)" class="icon-color">
                                {{element.expanded ? 'expand_less' : 'expand_more'}}
                            </mat-icon>
                        </td>
                    </ng-template>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                        <div class="example-element-detail" [@detailExpand]="element?.expanded"
                            *ngIf="element?.expanded">
                            <div style="width: 100%;">

                                <table mat-table [dataSource]="element.doctors"
                                    multiTemplateDataRows matSort>
                                    <ng-container matColumnDef="{{innerColumn}}"
                                        *ngFor="let innerColumn of innerDisplayedColumns">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="example-header">
                                            {{getColumnName(innerColumn)}} </th>
                                        <td mat-cell *matCellDef="let doctor">

                                            <div class="icon-color-pink"
                                                *ngIf="innerColumn === 'name'">
                                                {{doctor[innerColumn]}} </div>

                                            <a mat-icon-button class="icon-color-pink"
                                                *ngIf="innerColumn === 'phone'">
                                                <mat-icon *ngIf="innerColumn === 'phone'"
                                                    matTooltip="{{doctor[innerColumn]}}">
                                                    {{doctor[innerColumn] === null ? 'phone_locked' : 'phone'}}
                                                </mat-icon>
                                            </a>
                                            <a mat-icon-button class="icon-color-pink"
                                                *ngIf="innerColumn === 'email'"
                                                href="mailto:{{doctor[innerColumn]}}">
                                                <mat-icon *ngIf="innerColumn === 'email'"
                                                    matTooltip="{{doctor[innerColumn]}}">email
                                                </mat-icon>
                                            </a>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns">
                                    </tr>
                                    <tr mat-row
                                        *matRowDef="let doctor; columns: innerDisplayedColumns;">
                                    </tr>

                                </table>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                    class="example-element-row"
                    [class.example-element-row]="element.doctors?.length"
                    [class.example-expanded-row]="element?.expanded"
                    (click)="selectExpandedRow2(element)">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
                    class="example-detail-row"></tr>

            </table>
        </div>
    </div>
</div>

