/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export enum AuditlyticsPermissionLevel {
  GLOBAL = 0,
  CONTINENT = 1,
  COUNTRY = 2,
  STATE = 3,
  CITY = 4,
  ORGANISATION = 5,
  ORGANISATION_BRANCH = 6
}
