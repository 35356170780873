import { Suppression } from './suppression';

export class ClinicalOutcome {
  id: number;
  userId: number;
  //artCodeNameChoice
  artCodeNameChoiceMain: string[];
  // demographics
  ageClassification: string;
  gender: string;
  pregnancy: string;
  trimester: string;
  race: string;
  // clinicalInformation
  patientType: string;
  timeFromDiagnosis: string;
  opportunisticInfections: string[];
  genoType: string;
  viralLoadCount: string;
  viralLoad: string;
  intensiveAdherenceCounsellingDone: string;
  // txExperienced
  anyMutation: string;
  timeOnTreatment: string;
  treatmentStrategySwitch: string;
  mutationClass: string[];
  mutationName: string[];
  currentlySuppressed: string;
  // treatmentPurpose
  treatmentPurposeMain: string[];
  // drugDrugInteractions
  categoryOfMedication: string[];
  medication: string[];
  // resistanceMutationManagement
  resistanceMutationManagementMain: string;

}

export class ClinicalOutcomeDashboardResponse {
  clinicalOutcome: ClinicalOutcome;
  suppressionList: Suppression[];
}



export class MutationRequest{
  suppressionData: SuppressionData[];
  mutation: string[];
}


export class SuppressionData{
  art: string;
  name: string;
  constructor(options?: {art: string; name: string}) {
    this.art = options.art;
    this.name = options.name;
  }
}


export class MutationResponse{
  art: string;
  mutation: string[];
  mutationResultInNumber: number[];
  mutationCSSClass: string[];
}
