/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { DatePipe } from '@angular/common';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/shared/component/base.component';
import { UserSelectionDialogComponent } from 'src/app/shared/component/user-selection-dialog/user-selection-dialog.component';
import { InvitationType } from 'src/app/shared/model/enum/invitation-type';
import { Role } from 'src/app/shared/model/enum/role';
import { ExcelFileUploadResponse, UserFileUploadResponse, UserUploadResponse } from 'src/app/shared/model/file-upload-response';
import { OrganisationBranch } from 'src/app/shared/model/organisation';
import { FileUploadModel } from 'src/app/shared/module/file-upload/model/file-upload-model';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { OrganisationBranchService } from 'src/app/shared/services/organisation-branch.service';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { downloadFile } from 'src/app/shared/util';

@Component({
  selector: 'app-user-upload',
  templateUrl: './user-upload.component.html',
  styleUrls: ['./user-upload.component.scss']
})
export class UserUploadComponent extends BaseComponent implements OnInit {

  InvitationType: typeof InvitationType = InvitationType;

  uploadedFileCount = 0;
  selectedInvitationType = InvitationType[InvitationType.BECOME_PATIENT_OF_MY_ORGANISATION_AND_ME];

  invitationTypeOptions: string[];
  fileUploadResponse: UserFileUploadResponse;
  baseInvitationTypeTranslationKey = 'ENUM.INVITATION_TYPE.USER_UPLOAD.PATIENT_PAGE';
  subTitle = '';

  organisationBranchList: OrganisationBranch[] = [];
  selectedOrganisationBranchId: number;

  selectedClinicians: any[];
  selectedCliniciansStr = [];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UserUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private userAuthService: UserAuthService,
    private organisationBranchService: OrganisationBranchService
  ) { super(); }

  ngOnInit(): void {
    this.invitationTypeOptions = [InvitationType[InvitationType.BECOME_PATIENT_OF_MY_ORGANISATION_AND_ME]];

    this.subTitle = this.translate.instant('ORGANISATION_USER_UPLOAD.PATIENT_PAGE.SUBTITLE');

    if (Role.CLINICIAN === this.data.role ) {
      this.selectedInvitationType = InvitationType[InvitationType.BECOME_CLINICIAN_OF_MY_ORGANISATION];
      this.invitationTypeOptions = [this.selectedInvitationType];
      this.baseInvitationTypeTranslationKey = 'ENUM.INVITATION_TYPE.USER_UPLOAD.CLINICIAN_PAGE';
      this.subTitle = this.translate.instant('ORGANISATION_USER_UPLOAD.CLINICIAN_PAGE.SUBTITLE');
    }

    if (this.data.organisationId) {
      this.organisationBranchService.getAllOrganisationBranches(this.data.organisationId).subscribe(page => {
        this.organisationBranchList = page.content?.filter(branch => branch.id === this.data.organisationBranchId);
        if (this.data.organisationOwner === true) {
          this.organisationBranchList = page.content;
        }
      });
    }
  }

  isPatientUpload() {
    return (this.selectedInvitationType === InvitationType[InvitationType.BECOME_PATIENT_OF_MY_ORGANISATION]
      || this.selectedInvitationType === InvitationType[InvitationType.BECOME_PATIENT_OF_MY_ORGANISATION_AND_ME]);
  }

  visibleUpload() {
    if (!this.selectedOrganisationBranchId) {
      return false;
    }

    if (this.isPatientUpload() && (!this.selectedClinicians || this.selectedClinicians.length <= 0)) {
      return false;
    }

    return true;
  }

  onSelectOrganisationBranch(branchId) {
    this.selectedOrganisationBranchId = branchId;
    this.selectedClinicians = null;
    this.selectedCliniciansStr = null;
  }

  upload(uploadModel: FileUploadModel): void {
    const selectedRole = Role[this.data.role];
    const selectedIt = this.selectedInvitationType;

    uploadModel.inProgress = true;
    uploadModel.isCompleted = false;
    uploadModel.canRetry = false;
    this.fileUploadResponse = null;

    let clinicianIdList = null;
    if (this.selectedClinicians) {
      clinicianIdList = this.selectedClinicians.map(c => c.id);
    }

    uploadModel.sub = this.userAuthService.uploadBulkUsersFile(uploadModel.file, selectedRole, selectedIt, this.selectedOrganisationBranchId, clinicianIdList).subscribe((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
            uploadModel.progressPercentage = Math.floor(event.loaded * 100 / event.total);
            uploadModel.loadedSize = event.loaded;
            uploadModel.totalSize = event.total;
        } else if (event instanceof HttpResponse) {
            this.fileUploadResponse = event.body as UserFileUploadResponse;
            uploadModel.inProgress = false;
            uploadModel.isCompleted = true;
            uploadModel.canRetry = false;
            this.uploadedFileCount ++;
            if (this.fileUploadResponse?.errorList?.length > 0) {
              this.notificationService.error(this.translate.instant('ORGANISATION_USER_UPLOAD.NOTIFICATION.FILE_UPLOAD_USER_FAIL'));
            } else {
              this.notificationService.success(this.translate.instant('ORGANISATION_USER_UPLOAD.NOTIFICATION.FILE_UPLOAD_SUCCESS'));
              this.closeDialog();
            }
        }
    }, (error: any) => {
        if (uploadModel.sub) {
            uploadModel.sub.unsubscribe();
        }
        uploadModel.errorMessage = error?.error?.message ?? 'File could not upload';
        uploadModel.inProgress = false;
        uploadModel.isCompleted = true;
        uploadModel.canRetry = true;
        this.uploadedFileCount ++;
        this.notificationService.error(this.translate.instant('ORGANISATION_USER_UPLOAD.NOTIFICATION.FILE_UPLOAD_FAIL'));
    });
  }


  downloadExcelTemplate() {
    const selectedRole = Role[this.data.role];
    this.userAuthService.downloadOrgUserUploadTemplate(selectedRole).subscribe(dataResult => {
      const fileName = selectedRole + '-template-' + this.datePipe.transform(new Date(), 'yyyy-MM-dd_HH-mm-ss') + '.xlsx';
      const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        downloadFile(fileName, contentType, dataResult);
      });

  }

  openClinicianSelectionDialog() {
    const dialogConfig = this.createDialogConfig({
      searchInOrganisationBranch: true,
      organisationBranchId: this.selectedOrganisationBranchId,
      role: Role.CLINICIAN, title: 'Select Clinicians to Connect Patient',
      rowSelectionType: 'multiple',
      defaultRowSelections: this.selectedClinicians
    });
    const userSelectionDialogRef = this.dialog.open(UserSelectionDialogComponent, dialogConfig);

    userSelectionDialogRef.afterClosed().subscribe(data => {
      if (data?.selectedRows) {
        this.selectedClinicians = data.selectedRows;
        this.selectedCliniciansStr = this.selectedClinicians.map(c => c.firstName + ' ' + c.lastName);
      }
    });
  }


  closeDialog() {
    this.dialogRef.close({status: 'dialog closed', uploadedFileCount: this.uploadedFileCount});
  }

  getErrorMessage(userResponse: UserUploadResponse) {
    const emailOrPhone = userResponse.email ? userResponse.email : userResponse.phoneNumber;
    return '(' + emailOrPhone + ') ' + userResponse.errorReason;
  }

}
