import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { PasswordErrorStateMatcher } from '../password-error-state-matcher';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-password-form-field',
  templateUrl: './password-form-field.component.html',
  styleUrls: ['./password-form-field.component.scss']
})
export class PasswordFormFieldComponent implements OnInit, OnDestroy {

  public showPassword: boolean = false;
  public showPasswordVerify: boolean = false;

  @Input() fieldAppearance: MatFormFieldAppearance='standard';

  @Input() passwordFormGroupName: string = 'passwordFormGroup';
  @Input() passwordLabel: string = 'REGISTER_FORM.PASSWORD';

  @Input() enableVerifyPassword = false;
  @Input() verifyPasswordLabel: string = 'REGISTER_FORM.PASSWORD_VERIFY';

  passwordMatcher = new PasswordErrorStateMatcher('password', 'passwordVerify');

  formGroupPassword: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.minLength(6), Validators.maxLength(50), Validators.required]),
    passwordVerify: new FormControl('')
  });
  constructor(private parent: FormGroupDirective) { }

  ngOnInit(): void {
    this.parent.form.addControl(this.passwordFormGroupName, this.formGroupPassword);

    if(this.enableVerifyPassword) {
      this.formGroupPassword.validator = this.checkPasswords;
    }
  }

  ngOnDestroy(): void {
  }

  checkPasswords(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('passwordVerify').value;

    return pass === confirmPass ? null : { notSame: true };
  }

}
