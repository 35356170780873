<div class="section-title-bar bg-primary justify-content-between border-radius-bottom-0">
    <h2 class="content-box__title font-size-2 mb-0">{{ 'SIMULATION.DEMAND.CURRENT_SUMMARY' | translate }}</h2>
    <mat-icon matTooltip="{{ 'SIMULATION.DEMAND.CURRENT_SUMMARY' | translate }}">info</mat-icon>
</div>

<div class="content-box blue-background service-demand-grid border-radius-top-0">
    <div class="content-box chart px-0 pink-background">
        <h3 class="content-box__title sub">{{ 'SIMULATION.DEMAND.PATIENT_TYPE' | translate }}</h3>
        <div id="chartPiePatientType" class="d-flex flex-column" *ngIf="chartPiePatientTypeOptions">
            <apx-chart 
                [series]="chartPiePatientTypeOptions.series"
                [chart]="chartPiePatientTypeOptions.chart"
                [labels]="chartPiePatientTypeOptions.labels"
                [legend]="chartPiePatientTypeOptions.legend"
                [responsive]="chartPiePatientTypeOptions.responsive"
                [colors]="chartPiePatientTypeOptions.colors"
                [tooltip]="chartPiePatientTypeOptions.tooltip">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart px-0 pink-background">
        <h3 class="content-box__title sub">{{ 'SIMULATION.DEMAND.ADHERENCE' | translate }}</h3>
        <div id="chartPieAdherence" class="d-flex flex-column" *ngIf="chartPieAdherenceOptions">
            <apx-chart 
                [series]="chartPieAdherenceOptions.series"
                [chart]="chartPieAdherenceOptions.chart"
                [labels]="chartPieAdherenceOptions.labels"
                [legend]="chartPieAdherenceOptions.legend"
                [responsive]="chartPieAdherenceOptions.responsive"
                [colors]="chartPieAdherenceOptions.colors"
                [tooltip]="chartPieAdherenceOptions.tooltip">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart px-0 pink-background">
        <h3 class="content-box__title sub">{{ 'SIMULATION.DEMAND.AGE' | translate }}</h3>
        <div id="chartPieAge" class="d-flex flex-column" *ngIf="chartPieAgeOptions">
            <apx-chart 
                [series]="chartPieAgeOptions.series"
                [chart]="chartPieAgeOptions.chart"
                [labels]="chartPieAgeOptions.labels"
                [legend]="chartPieAgeOptions.legend"
                [responsive]="chartPieAgeOptions.responsive"
                [colors]="chartPieAgeOptions.colors"
                [tooltip]="chartPieAgeOptions.tooltip">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart px-0 pink-background">
        <h3 class="content-box__title sub">{{ 'SIMULATION.DEMAND.CO_INFECT' | translate }}</h3>

        <div id="chartPieInfect" class="d-flex flex-column" *ngIf="chartPieInfectOptions">
            <apx-chart 
                [series]="chartPieInfectOptions.series"
                [chart]="chartPieInfectOptions.chart"
                [labels]="chartPieInfectOptions.labels"
                [legend]="chartPieInfectOptions.legend"
                [responsive]="chartPieInfectOptions.responsive"
                [colors]="chartPieInfectOptions.colors"
                [tooltip]="chartPieInfectOptions.tooltip">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart px-0 pink-background">
        <h3 class="content-box__title sub">{{ 'SIMULATION.SERVICE.ART' | translate }}</h3>

        <div id="chartPieArt" class="d-flex flex-column" *ngIf="chartPieArtOptions">
            <apx-chart 
                [series]="chartPieArtOptions.series"
                [chart]="chartPieArtOptions.chart"
                [labels]="chartPieArtOptions.labels"
                [legend]="chartPieArtOptions.legend"
                [responsive]="chartPieArtOptions.responsive"
                [colors]="chartPieArtOptions.colors"
                [tooltip]="chartPieArtOptions.tooltip">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart px-0 pink-background">
        <h3 class="content-box__title sub">{{ 'SIMULATION.SERVICE.NO_VISIT' | translate }}</h3>

        <div id="chartPieVisit" class="d-flex flex-column" *ngIf="chartPieVisitOptions">
            <apx-chart 
                [series]="chartPieVisitOptions.series"
                [chart]="chartPieVisitOptions.chart"
                [labels]="chartPieVisitOptions.labels"
                [legend]="chartPieVisitOptions.legend"
                [responsive]="chartPieVisitOptions.responsive"
                [colors]="chartPieVisitOptions.colors"
                [tooltip]="chartPieVisitOptions.tooltip">
            </apx-chart>
        </div>
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.DEMAND.HEADER' | translate }}</p>

<div class="outcomes-charts mb-12">
    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.DEMAND.NO_PATIENTS' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.DEMAND.NO_PATIENTS' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.DEMAND.PATIENT_TYPE' | translate }}</p>

        <div id="chartPatient" class="d-flex flex-column" *ngIf="chartPatientOptions">
            <apx-chart 
                [series]="chartPatientOptions.series"
                [xaxis]="chartPatientOptions.xaxis"
                [yaxis]="chartPatientOptions.yaxis" 
                [chart]="chartPatientOptions.chart"
                [dataLabels]="chartPatientOptions.dataLabels" 
                [plotOptions]="chartPatientOptions.plotOptions"
                [legend]="chartPatientOptions.legend" 
                [fill]="chartPatientOptions.fill"
                [stroke]="chartPatientOptions.stroke"
                [tooltip]="chartPatientOptions.tooltip"
                [grid]="chartPatientOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.DEMAND.NON_ADHERENCE_PATIENTS' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.DEMAND.NON_ADHERENCE_PATIENTS' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.DEMAND.ADHERENCE_TYPE' | translate }}</p>

        <div id="chartAdherence" class="d-flex flex-column" *ngIf="chartAdherenceOptions">
            <apx-chart 
                [series]="chartAdherenceOptions.series" 
                [chart]="chartAdherenceOptions.chart"
                [dataLabels]="chartAdherenceOptions.dataLabels" 
                [plotOptions]="chartAdherenceOptions.plotOptions"
                [yaxis]="chartAdherenceOptions.yaxis" 
                [legend]="chartAdherenceOptions.legend" 
                [fill]="chartAdherenceOptions.fill"
                [stroke]="chartAdherenceOptions.stroke" 
                [tooltip]="chartAdherenceOptions.tooltip" 
                [xaxis]="chartAdherenceOptions.xaxis"
                [grid]="chartAdherenceOptions.grid">
            </apx-chart>
        </div>
    </div>
</div>

<div class="subtitle-text">{{ 'SIMULATION.CP_SC' | translate }}</div>

<div class="outcomes-charts mb-24">
    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.DEMAND.NO_DEAD' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.DEMAND.NO_DEAD' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.DEMAND.DEAD' | translate }}</p>

        <div id="chartPatientDead" class="d-flex flex-column" *ngIf="chartPatientDeadOptions">
            <apx-chart [series]="chartPatientDeadOptions.series" 
                [chart]="chartPatientDeadOptions.chart"
                [dataLabels]="chartPatientDeadOptions.dataLabels"
                [plotOptions]="chartPatientDeadOptions.plotOptions" 
                [yaxis]="chartPatientDeadOptions.yaxis"
                [legend]="chartPatientDeadOptions.legend" 
                [fill]="chartPatientDeadOptions.fill"
                [stroke]="chartPatientDeadOptions.stroke" 
                [tooltip]="chartPatientDeadOptions.tooltip"
                [xaxis]="chartPatientDeadOptions.xaxis" 
                [grid]="chartPatientDeadOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.DEMAND.NO_DISCONTINUED' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.DEMAND.NO_DISCONTINUED' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.DEMAND.DISCONTINUED' | translate }}</p>

        <div id="chartPatientDiscontinued" class="d-flex flex-column" *ngIf="chartPatientDiscontinuedOptions">
            <apx-chart 
                [series]="chartPatientDiscontinuedOptions.series" 
                [chart]="chartPatientDiscontinuedOptions.chart"
                [dataLabels]="chartPatientDiscontinuedOptions.dataLabels" 
                [plotOptions]="chartPatientDiscontinuedOptions.plotOptions"
                [yaxis]="chartPatientDiscontinuedOptions.yaxis" 
                [legend]="chartPatientDiscontinuedOptions.legend" 
                [fill]="chartPatientDiscontinuedOptions.fill"
                [stroke]="chartPatientDiscontinuedOptions.stroke" 
                [tooltip]="chartPatientDiscontinuedOptions.tooltip" 
                [xaxis]="chartPatientDiscontinuedOptions.xaxis"
                [grid]="chartPatientDiscontinuedOptions.grid">
            </apx-chart>
        </div>
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.DEMAND.MONITORING' | translate }}</p>

<div class="outcomes-charts mb-24">
    <div>
        <div class="content-box chart">
            <div class="d-flex justify-content-between">
                <h3 class="content-box__title mb-0">{{ 'SIMULATION.DEMAND.NO_PATIENTS_TYPE' | translate }}</h3>
                <mat-icon matTooltip="{{ 'SIMULATION.DEMAND.NO_PATIENTS_TYPE' | translate }}">info</mat-icon>
            </div>
            <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.DEMAND.PATIENT_TYPE' | translate }}</p>

            <div id="chartVisitPatient" class="d-flex flex-column" *ngIf="chartVisitPatientOptions">
                <apx-chart [series]="chartVisitPatientOptions.series" 
                    [chart]="chartVisitPatientOptions.chart"
                    [dataLabels]="chartVisitPatientOptions.dataLabels" 
                    [plotOptions]="chartVisitPatientOptions.plotOptions"
                    [yaxis]="chartVisitPatientOptions.yaxis" 
                    [legend]="chartVisitPatientOptions.legend" 
                    [fill]="chartVisitPatientOptions.fill"
                    [stroke]="chartVisitPatientOptions.stroke" 
                    [tooltip]="chartVisitPatientOptions.tooltip" 
                    [xaxis]="chartVisitPatientOptions.xaxis"
                    [grid]="chartVisitPatientOptions.grid">
                </apx-chart>
            </div>
        </div>
        <div class="subtitle-text">{{ 'SIMULATION.CP_SC' | translate }}</div>
    </div>

    <div>
        <div class="content-box chart">
            <div class="d-flex justify-content-between">
                <h3 class="content-box__title mb-0">{{ 'SIMULATION.DEMAND.NO_AGE_GROUP' | translate }}</h3>
                <mat-icon matTooltip="{{ 'SIMULATION.DEMAND.NO_AGE_GROUP' | translate }}">info</mat-icon>
            </div>
            <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.DEMAND.AGE_GROUP' | translate }}</p>

            <div id="chartVisitAge" class="d-flex flex-column" *ngIf="chartVisitAgeOptions">
                <apx-chart [series]="chartVisitAgeOptions.series" 
                    [chart]="chartVisitAgeOptions.chart"
                    [dataLabels]="chartVisitAgeOptions.dataLabels" 
                    [plotOptions]="chartVisitAgeOptions.plotOptions"
                    [yaxis]="chartVisitAgeOptions.yaxis" 
                    [legend]="chartVisitAgeOptions.legend" 
                    [fill]="chartVisitAgeOptions.fill"
                    [stroke]="chartVisitAgeOptions.stroke" 
                    [tooltip]="chartVisitAgeOptions.tooltip" 
                    [xaxis]="chartVisitAgeOptions.xaxis"
                    [grid]="chartVisitAgeOptions.grid">
                </apx-chart>
            </div>
        </div>
        <div class="subtitle-text">{{ 'SIMULATION.CP_SC' | translate }}</div>
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.DEMAND.COUNSELLING' | translate }}</p>

<div class="outcomes-charts mb-24">
    <div>
        <div class="content-box chart">
            <div class="d-flex justify-content-between">
                <h3 class="content-box__title mb-0">{{ 'SIMULATION.DEMAND.NO_COUNSELLING' | translate }}</h3>
                <mat-icon matTooltip="{{ 'SIMULATION.DEMAND.NO_COUNSELLING' | translate }}">info</mat-icon>
            </div>
            <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.DEMAND.COUNSELLING_SESSIONS' | translate }}</p>

            <div id="chartVisitCounselling" class="d-flex flex-column" *ngIf="chartVisitCounsellingOptions">
                <apx-chart [series]="chartVisitCounsellingOptions.series" 
                    [chart]="chartVisitCounsellingOptions.chart"
                    [dataLabels]="chartVisitCounsellingOptions.dataLabels" 
                    [plotOptions]="chartVisitCounsellingOptions.plotOptions"
                    [yaxis]="chartVisitCounsellingOptions.yaxis" 
                    [legend]="chartVisitCounsellingOptions.legend" 
                    [fill]="chartVisitCounsellingOptions.fill"
                    [stroke]="chartVisitCounsellingOptions.stroke" 
                    [tooltip]="chartVisitCounsellingOptions.tooltip" 
                    [xaxis]="chartVisitCounsellingOptions.xaxis"
                    [grid]="chartVisitCounsellingOptions.grid">
                </apx-chart>
            </div>
        </div>
        <div class="subtitle-text">{{ 'SIMULATION.CP_SC' | translate }}</div>
    </div>

    <!-- <div>
        <div class="content-box chart">
            <div class="d-flex justify-content-between">
                <h3 class="content-box__title mb-0">{{ 'SIMULATION.DEMAND.REASON_NON_ADHERENCE' | translate }}</h3>
                <mat-icon matTooltip="{{ 'SIMULATION.DEMAND.REASON_NON_ADHERENCE' | translate }}">info</mat-icon>
            </div>
            <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.DEMAND.NON_ADHERENCE' | translate }}</p>

            <div id="chartReason" class="d-flex flex-column" *ngIf="chartReasonOptions">
                <apx-chart [series]="chartReasonOptions.series" 
                    [chart]="chartReasonOptions.chart"
                    [dataLabels]="chartReasonOptions.dataLabels" 
                    [plotOptions]="chartReasonOptions.plotOptions"
                    [yaxis]="chartReasonOptions.yaxis" 
                    [legend]="chartReasonOptions.legend" 
                    [fill]="chartReasonOptions.fill"
                    [stroke]="chartReasonOptions.stroke" 
                    [tooltip]="chartReasonOptions.tooltip" 
                    [xaxis]="chartReasonOptions.xaxis"
                    [grid]="chartReasonOptions.grid">
                </apx-chart>
            </div>
        </div>
        <div class="subtitle-text">{{ 'SIMULATION.CP_SC' | translate }}</div>
    </div> -->
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.DEMAND.ART' | translate }}</p>

<div class="outcomes-charts">
    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.CURRENT_PRACTICE' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.DEMAND.NO_COUNSELLING' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.CURRENT_PRACTICE' | translate }}</p>

        <div id="chartArt" class="d-flex flex-column" *ngIf="chartArtOptions">
            <apx-chart 
                [series]="chartArtOptions.series" 
                [chart]="chartArtOptions.chart"
                [dataLabels]="chartArtOptions.dataLabels" 
                [plotOptions]="chartArtOptions.plotOptions"
                [yaxis]="chartArtOptions.yaxis" 
                [legend]="chartArtOptions.legend" 
                [fill]="chartArtOptions.fill"
                [stroke]="chartArtOptions.stroke" 
                [tooltip]="chartArtOptions.tooltip" 
                [xaxis]="chartArtOptions.xaxis"
                [grid]="chartArtOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.SCENARIO' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.SCENARIO' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.SERVICE.ART' | translate }}</p>

        <div id="chartArtSc" class="d-flex flex-column" *ngIf="chartArtScOptions">
            <apx-chart 
                [series]="chartArtScOptions.series" 
                [chart]="chartArtScOptions.chart"
                [dataLabels]="chartArtScOptions.dataLabels" 
                [plotOptions]="chartArtScOptions.plotOptions"
                [yaxis]="chartArtScOptions.yaxis" 
                [legend]="chartArtScOptions.legend" 
                [fill]="chartArtScOptions.fill"
                [stroke]="chartArtScOptions.stroke" 
                [tooltip]="chartArtScOptions.tooltip" 
                [xaxis]="chartArtScOptions.xaxis"
                [grid]="chartArtScOptions.grid">
            </apx-chart>
        </div>
    </div>
</div>