/* eslint-disable */

import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FilterData, FilterListData, FilterListDataExtended, TrialistCountryResult } from 'src/app/shared/model/trial-list';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { SurveyResultsService } from 'src/app/shared/services/survey-results.service';

@Component({
  selector: 'app-trial-country',
  templateUrl: './trial-country.component.html',
  styleUrls: ['./trial-country.component.scss']
})
export class TrialCountryComponent implements OnInit {

  data: TrialistCountryResult = {
    continentalDataList: [],
    africaDataList: [],
    asiaDataList: [],
    europeDataList: [],
    northAmericaDataList: [],
    oceaniaDataList: [],
    southAmericaDataList: []
  };

  dataSource;
  width = '900';
  height = '600';
  type = 'world';
  dataFormat = 'json';
  chartInstance: any = {};

  chartObj: any;
  handler: any;
  attached: boolean;

  colorContinental = [
    {
      'minvalue': '0',
      'maxvalue': '50',
      'code': '#396200'
    },
    {
      'minvalue': '50',
      'maxvalue': '100',
      'code': '#63AB00'
    },
    {
      'minvalue': '100',
      'maxvalue': '200',
      'code': '#93FF00'
    },
    {
      'minvalue': '200',
      'maxvalue': '500',
      'code': '#FFDE00'
    },
    {
      'minvalue': '500',
      'maxvalue': '1000',
      'code': '#D2B700'
    },
    {
      'minvalue': '1000',
      'maxvalue': '2000',
      'code': '#AA9400'
    },
    {
      'minvalue': '2000',
      'maxvalue': '5000',
      'code': '#FF0000'
    },
    {
      'minvalue': '5000',
      'maxvalue': '10000',
      'code': '#B40000'
    },
    {
      'minvalue': '10000',
      'maxvalue': '100000',
      'code': '#4E0000'
    }
  ];

  colorCountry = [
    {
      'minvalue': '0',
      'maxvalue': '10',
      'code': '#396200'
    },
    {
      'minvalue': '10',
      'maxvalue': '20',
      'code': '#63AB00'
    },
    {
      'minvalue': '20',
      'maxvalue': '50',
      'code': '#93FF00'
    },
    {
      'minvalue': '50',
      'maxvalue': '100',
      'code': '#FFDE00'
    },
    {
      'minvalue': '100',
      'maxvalue': '200',
      'code': '#D2B700'
    },
    {
      'minvalue': '200',
      'maxvalue': '500',
      'code': '#AA9400'
    },
    {
      'minvalue': '500',
      'maxvalue': '1000',
      'code': '#FF0000'
    },
    {
      'minvalue': '1000',
      'maxvalue': '2000',
      'code': '#B40000'
    },
    {
      'minvalue': '2000',
      'maxvalue': '50000',
      'code': '#4E0000'
    }
  ];

  constructor(
    private zone: NgZone,
    private router: Router,
    private storageService: StorageService,
    public surveyResultsService: SurveyResultsService,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {
    this.setMap();
  }

  ngOnInit(): void {
    const filterListDataExtended: FilterListDataExtended = JSON.parse(JSON.stringify(this.storageService.getTrialFilter()));
    const filterListData: FilterListData = {
      level: null,
      countries: null,
      states: null,
      centers: null,
      cities: null,
      organizations: null,
      filterList: filterListDataExtended.filterListData.filterList
    };

    if (!filterListData) {
      this.router.navigate(['smarthiv-trialist/trial-filter']);
      this.notificationService.error(this.translate.instant('TRIAL.SELECT_FILTERS'));
    }

    this.surveyResultsService.getTrialistCountryResults(filterListData).subscribe(data => {
      this.data = data;
      this.setMap();
    });

  }

  setMap() {
    this.dataSource = {
      chart: {
        caption: "Worldwide Patients location, regions, country",
        subcaption: "Patients Number",
        numbersuffix: " patients",
        captionalignment: "left",
        theme: "fusion",
        formatnumberscale: "0",
        entityfillhovercolor: "#E5E5E9",
        entitytooltext:
          "<div style='font-size:14px; text-align:center; padding: 2px 4px 2px 4px; color:black;'>$lName</div><div style='font-size:12px; color:black'>$value patients</div>"
      },
      colorrange: {
        gradient: '0',
        color: this.colorContinental
      },
      data: this.data.continentalDataList,
      linkeddata: [
        {
          id: "NA",
          linkedchart: {
            chart: {
              caption: "North America Patients location, regions, country",
              subcaption: "Patients Number",
              numbersuffix: " patients",
              captionalignment: "left",
              theme: "fusion",
              formatnumberscale: "0",
              entityfillhovercolor: "#E5E5E9",
              entitytooltext:
                "<div style='font-size:14px; text-align:center; padding: 2px 4px 2px 4px; color:black;'>$lName</div><div style='font-size:12px; color:black'>$value patients</div>"
            },
            colorrange: {
              gradient: '0',
              color: this.colorCountry
            },
            data: this.data.northAmericaDataList
          }
        },
        {
          id: "SA",
          linkedchart: {
            chart: {
              caption: "South America Patients location, regions, country",
              subcaption: "Patients Number",
              numbersuffix: " patients",
              captionalignment: "left",
              theme: "fusion",
              formatnumberscale: "0",
              showlabels: "0",
              entityfillhovercolor: "#E5E5E9",
              entitytooltext:
                "<div style='font-size:14px; text-align:center; padding: 2px 4px 2px 4px; color:black;'>$lName</div><div style='font-size:12px; color:black'>$value patients</div>"
            },
            colorrange: {
              gradient: '0',
              color: this.colorCountry
            },
            data: this.data.southAmericaDataList
          }
        },
        {
          id: "AS",
          linkedchart: {
            chart: {
              caption: "Asia Patients location, regions, country",
              subcaption: "Patients Number",
              numbersuffix: " patients",
              captionalignment: "left",
              theme: "fusion",
              formatnumberscale: "0",
              showlabels: "0",
              entityfillhovercolor: "#E5E5E9",
              entitytooltext:
                "<div style='font-size:14px; text-align:center; padding: 2px 4px 2px 4px; color:black;'>$lName</div><div style='font-size:12px; color:black'>$value patients</div>"
            },
            colorrange: {
              gradient: '0',
              color: this.colorCountry
            },
            data: this.data.asiaDataList
          }
        },
        {
          id: "EU",
          linkedchart: {
            chart: {
              caption: "Europe Patients location, regions, country",
              subcaption: "Patients Number",
              numbersuffix: " patients",
              captionalignment: "left",
              theme: "fusion",
              formatnumberscale: "0",
              showlabels: "0",
              entityfillhovercolor: "#E5E5E9",
              entitytooltext:
                "<div style='font-size:14px; text-align:center; padding: 2px 4px 2px 4px; color:black;'>$lName</div><div style='font-size:12px; color:black'>$value patients</div>"
            },
            colorrange: {
              gradient: '0',
              color: this.colorCountry
            },
            data: this.data.europeDataList
          }
        },
        {
          id: "AU",
          linkedchart: {
            chart: {
              caption: "Australia Patients location, regions, country",
              subcaption: "Patients Number",
              numbersuffix: " patients",
              captionalignment: "left",
              theme: "fusion",
              formatnumberscale: "0",
              showlabels: "0",
              entityfillhovercolor: "#E5E5E9",
              entitytooltext:
                "<div style='font-size:14px; text-align:center; padding: 2px 4px 2px 4px; color:black;'>$lName</div><div style='font-size:12px; color:black'>$value patients</div>"
            },
            colorrange: {
              gradient: '0',
              color: this.colorCountry
            },
            data: this.data.oceaniaDataList
          }
        },
        {
          id: "AF",
          linkedchart: {
            chart: {
              caption: "Africa Patients location, regions, country",
              subcaption: "Patients Number",
              numbersuffix: " patients",
              captionalignment: "left",
              theme: "fusion",
              formatnumberscale: "0",
              showlabels: "0",
              entityfillhovercolor: "#E5E5E9",
              entitytooltext:
                "<div style='font-size:14px; text-align:center; padding: 2px 4px 2px 4px; color:black;'>$lName</div><div style='font-size:12px; color:black'>$value patients</div>"
            },
            colorrange: {
              gradient: '0',
              color: this.colorCountry
            },
            data: this.data.africaDataList
          }
        }
      ]
    };
  }

  initialized($event) {
    this.chartObj = $event.chart;
    this.handler = this.dataplotClickHandler.bind(this);
    this.chartObj.addEventListener('entityClick', this.handler);
    this.attached = true;
  }

  dataplotClickHandler(eventObj, dataObj) {
    this.zone.run(() => {
      this.entityClick(dataObj.label.toLowerCase().split(' ').join(''));
    });
  }

  entityClick(continent) {
    this.chartObj.configureLink({
      type: continent,
      overlayButton: {
        message: "Back",
        fontColor: "880000",
        bgColor: "FFEEEE",
        borderColor: "660000"
      }
    });
  }

}
