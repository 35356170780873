/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClinicianFormComponent } from './pages/clinician-form/clinician-form.component';
import { ClinicalOutcomesComponent } from './pages/clinical-outcomes/clinical-outcomes.component';
import { ComobidityManagementComponent } from './pages/comobidity-management/comobidity-management.component';
import { RiskPredectionComponent } from './pages/risk-predection/risk-predection.component';
import { SmarthivClinicianRoutingModule } from './smarthiv-clinician-routing.module';
import { QuestionComponent } from './component/question/question.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ExpectedOverallSuppressionComponent } from './component/expected-overall-suppression/expected-overall-suppression.component';
import { ExpectedPatientSuppressionComponent } from './component/expected-patient-suppression/expected-patient-suppression.component';
import { ClinicalOutcomeSummaryComponent } from './component/clinical-outcome-summary/clinical-outcome-summary.component';
import { SuppressionListSummaryComponent } from './component/suppression-list-summary/suppression-list-summary.component';
import { MutationScoreComponent } from './component/mutation-score/mutation-score.component';
import { QuestionGroupComponent } from './component/question-group/question-group.component';
import { ExpectancyChartComponent } from './component/expectancy-chart/expectancy-chart.component';
import { DrugInteractionComponent } from './pages/drug-interaction/drug-interaction.component';
import { AdverseEventComponent } from './pages/adverse-event/adverse-event.component';



@NgModule({
  declarations: [
    ClinicianFormComponent,
    ClinicalOutcomesComponent,
    ComobidityManagementComponent,
    RiskPredectionComponent,
    QuestionComponent,
    ExpectedOverallSuppressionComponent,
    ExpectedPatientSuppressionComponent,
    ClinicalOutcomeSummaryComponent,
    SuppressionListSummaryComponent,
    MutationScoreComponent,
    QuestionGroupComponent,
    ExpectancyChartComponent,
    DrugInteractionComponent,
    AdverseEventComponent
  ],
  imports: [
    CommonModule,
    SmarthivClinicianRoutingModule,
    SharedModule
  ]
})
export class SmarthivClinicianModule { }
