/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { Notification, NotificationType } from './../model/notification-model';
import { NotificationService } from './../service/notification.service';

@Component({
    selector: 'app-notification',
    styleUrls: ['notification.component.scss'],
    templateUrl: 'notification.component.html'
})
export class NotificationComponent implements OnInit, OnDestroy {
    @Input() id: string;

    notifications: Notification[] = [];
    subscription: Subscription;
    NOTIFICATION_TIMEOUT = 3500;

    constructor(private notificationService: NotificationService) { }

    ngOnInit() {
        this.subscription = this.notificationService.onNotification(this.id)
            .subscribe(notification => {
                if (!notification.message) {
                    // clear notifications when an empty notification is received
                    this.notifications = [];
                    return;
                }

                this.showNotification(notification);
            });
    }

    ngOnDestroy() {
        // unsubscribe to avoid memory leaks
        this.subscription.unsubscribe();
    }

    showNotification(notification: Notification) {
        // add notification to array
        this.notifications.push(notification);
        setTimeout(() => {
            this.removeNotification(notification);
        }, this.NOTIFICATION_TIMEOUT);
    }

    removeNotification(notification: Notification) {
        // remove specified notification from array
        this.notifications = this.notifications.filter(x => x !== notification);
    }

    cssClass(notification: Notification) {
        if (!notification) {
            return '';
        }
        switch (notification.type) {
            case NotificationType.Success:
                return 'toast-success';
            case NotificationType.Error:
                return 'toast-error';
            case NotificationType.Info:
                return 'toast-info';
            case NotificationType.Warning:
                return 'toast-warning';
        }
    }
}
