import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { Menu } from 'src/app/shared/model/menu-items';
import { MenuItemService } from 'src/app/shared/services/menu-item.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { AuthenticationStateService } from 'src/app/shared/state/authentication-state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { DEFAULT_USER_PHOTO, DEFAULT_USER_BACKGROUND_PHOTO, DEFAULT_EMAIL } from 'src/app/shared/constants';

@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html',
  styleUrls: ['./vertical-sidebar.component.scss']
})
export class VerticalAppSidebarComponent implements OnInit, OnDestroy {
  faBuilding = faBuilding;

  public config: PerfectScrollbarConfigInterface = {};
  photoUrl = DEFAULT_USER_PHOTO;
  userProfileBackgroundPhoto = DEFAULT_USER_BACKGROUND_PHOTO;
  username = DEFAULT_EMAIL;

  mobileQuery: MediaQueryList;

  parentIndex = 0;
  childIndex = 0;
  subChildIndex = 0;
  menuItems: Menu[];

  icons = {
    smarthivclinician: 'bs-clinician font-size-2-5 w-46',
    smarthivmanager: 'bs-manager font-size-2-5 w-46',
    smarthivauditlytics: 'bs-smartlytics font-size-2-5 w-46',
    hivsmartlytics: 'bs-smartlytics font-size-2-5 w-46',
    smarthivtrialist: 'bs-trialist font-size-2-2 w-46',
    subscription: 'bs-subscription font-size-2-5 w-46',
    dhis2: 'bs-dhis-2 font-size-2-5 w-46',
    thirdparty: 'bs-third-party font-size-2-6 w-46',
    suppressionadmin: 'bs-suppression-admin font-size-2-6 w-46',
    surveyadmin: 'bs-survey-admin font-size-2-6 w-46',
    useradmin: 'bs-user-admin font-size-2-6 w-46',
    subscriptionadmin: 'bs-subscription-admin font-size-2-6 w-46',
    generaladmin: 'bs-general-admin font-size-2-2 w-46',
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private authenticationStateService: AuthenticationStateService,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private menuItemService: MenuItemService
  ) { }


  ngOnInit() {
    this.menuItems = this.menuItemService.getMenuitems();
    this.mobileQuery = this.media.matchMedia('(min-width: 768px)');
    this.mobileQuery.addListener(this.mobileQueryListener);

    this.authenticationStateService.getLoginChangeObservable().subscribe(info => {
      if (info) {
        const emailOrPhoneNumber = info.email ? info.email : info.phoneNumber;
        this.photoUrl = info.photoUrl ? info.photoUrl : DEFAULT_USER_PHOTO;
        this.username = emailOrPhoneNumber ? emailOrPhoneNumber : DEFAULT_EMAIL;
      }
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  private mobileQueryListener(): void {
    if (this.changeDetectorRef) {
      this.changeDetectorRef.detectChanges();
    }

  }

  scrollToTop() {
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0
    });
  }

  selectMenuItem(selectedParentMenu: Menu, index1, index2, index3) {
    this.parentIndex = index1;
    this.childIndex = index2;
    this.subChildIndex = index3;
    let userBackgroundImage = DEFAULT_USER_BACKGROUND_PHOTO;
    if (selectedParentMenu.custImage) {
      userBackgroundImage = selectedParentMenu.custImage;
    }
    this.userProfileBackgroundPhoto = userBackgroundImage;
  }

  logout() {
    this.authenticationStateService.logout();
  }

  goToAccountUpdatePage() {
    this.router.navigateByUrl('user-profile/update-account');
  }

  goToOrganisationDetailPage() {
    this.router.navigateByUrl('organisation/detail');
  }

  getIconName(name: string) {
    const icon = name.toLowerCase().replace(/\s/g, '').replace('-', '');
    return this.icons[icon];
  }
}
