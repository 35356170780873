<div class="section-title-bar bg-primary justify-content-between border-radius-bottom-0">
    <h2 class="content-box__title font-size-2 mb-0">{{ 'SIMULATION.STAFF.CURRENT_SUMMARY' | translate }}</h2>
</div>

<div class="content-box blue-background hr-planning-management-grid border-radius-top-0 mb-24">
    <div class="content-box chart pink-background">
        <div fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <span fxFlex="20" fxFlexOffset="60" fxLayoutAlign="space-around center">{{ 'SIMULATION.CP' | translate }}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{ 'SIMULATION.SC' | translate }}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <span fxFlex="60" fxLayoutAlign="space-around center">{{ 'SIMULATION.STAFF.DOCTOR_SERVICE_HOURS' | translate }}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{doctorHours}}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{doctorHoursSc}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <span fxFlex="60" fxLayoutAlign="space-around center">{{ 'SIMULATION.STAFF.NO_AVAILABLE_DOCTOR' | translate }}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{doctorAvailable}}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{doctorAvailableSc}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <span fxFlex="60" fxLayoutAlign="space-around center">{{ 'SIMULATION.STAFF.NO_REQUIRED_DOCTOR' | translate }}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{doctorRequired}}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{doctorRequiredSc}}</span>
            </div>
        </div>
    </div>

    <div class="content-box chart pink-background">
        <div fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <span fxFlex="20" fxFlexOffset="60" fxLayoutAlign="space-around center">{{ 'SIMULATION.CP' | translate }}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{ 'SIMULATION.SC' | translate }}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <span fxFlex="60" fxLayoutAlign="space-around center">{{ 'SIMULATION.STAFF.HIV_NURSE_SERVICE_HOURS' | translate }}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{hivSpecialistNurseHours}}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{hivSpecialistNurseHoursSc}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <span fxFlex="60" fxLayoutAlign="space-around center">{{ 'SIMULATION.STAFF.NO_AVAILABLE_HIV_NURSE' | translate }}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{hivNurseAvailable}}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{hivNurseAvailableSc}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <span fxFlex="60" fxLayoutAlign="space-around center">{{ 'SIMULATION.STAFF.NO_REQUIRED_HIV_NURSE' | translate }}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{hivNurseRequired}}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{hivNurseRequiredSc}}</span>
            </div>
        </div>
    </div>

    <div class="content-box chart pink-background">
        <div fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <span fxFlex="20" fxFlexOffset="60" fxLayoutAlign="space-around center">{{ 'SIMULATION.CP' | translate }}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{ 'SIMULATION.SC' | translate }}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <span fxFlex="60" fxLayoutAlign="space-around center">{{ 'SIMULATION.STAFF.COUNSELLOR_SERVICE_HOURS' | translate }}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{counselorHours}}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{counselorHoursSc}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <span fxFlex="60" fxLayoutAlign="space-around center">{{ 'SIMULATION.STAFF.NO_AVAILABLE_COUNSELLOR' | translate }}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{counsellorAvailable}}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{counsellorAvailableSc}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <span fxFlex="60" fxLayoutAlign="space-around center">{{ 'SIMULATION.STAFF.NO_REQUIRED_COUNSELLOR' | translate }}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{counsellorRequired}}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{counsellorRequiredSc}}</span>
            </div>
        </div>
    </div>

    <div class="content-box chart pink-background">
        <div fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <span fxFlex="20" fxFlexOffset="60" fxLayoutAlign="space-around center">{{ 'SIMULATION.CP' | translate }}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{ 'SIMULATION.SC' | translate }}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <span fxFlex="60" fxLayoutAlign="space-around center">{{ 'SIMULATION.STAFF.TOTAL_SERVICE_HOURS' | translate }}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{totalHours}}</span>
                <span fxFlex="20" fxLayoutAlign="space-around center">{{totalHoursSc}}</span>
            </div>
        </div>
    </div>

    <div class="content-box chart px-0 pink-background">
        <h3 class="content-box__title sub">{{ 'SIMULATION.STAFF.ANNUAL_WORKLOAD' | translate }}</h3>

        <div id="chartPieAnnual" class="d-flex flex-column align-items-center" *ngIf="chartPieAnnualOptions">
            <apx-chart 
                [series]="chartPieAnnualOptions.series"
                [chart]="chartPieAnnualOptions.chart"
                [labels]="chartPieAnnualOptions.labels"
                [legend]="chartPieAnnualOptions.legend"
                [responsive]="chartPieAnnualOptions.responsive"
                [colors]="chartPieAnnualOptions.colors"
                [yaxis]="chartPieAnnualOptions.yaxis"
                [tooltip]="chartPieAnnualOptions.tooltip">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart px-0 pink-background">
        <h3 class="content-box__title sub">{{ 'SIMULATION.STAFF.ANNUAL_WORKLOADBY_SERVICE' | translate }}</h3>
        <div id="chartPieAnnualService" class="d-flex flex-column align-items-center" *ngIf="chartPieAnnualServiceOptions">
            <apx-chart 
                [series]="chartPieAnnualServiceOptions.series"
                [chart]="chartPieAnnualServiceOptions.chart"
                [labels]="chartPieAnnualServiceOptions.labels"
                [legend]="chartPieAnnualServiceOptions.legend"
                [responsive]="chartPieAnnualServiceOptions.responsive"
                [colors]="chartPieAnnualServiceOptions.colors"
                [yaxis]="chartPieAnnualServiceOptions.yaxis"
                [tooltip]="chartPieAnnualServiceOptions.tooltip">
            </apx-chart>
        </div>
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.STAFF.YEAR_PLAN' | translate }}</p>

<div class="outcomes-charts mb-24">
    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.CURRENT_PRACTICE' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.CURRENT_PRACTICE' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartYearStaff" class="d-flex flex-column" *ngIf="chartYearStaffOptions">
            <apx-chart [series]="chartYearStaffOptions.series" 
                [chart]="chartYearStaffOptions.chart"
                [colors]="chartYearStaffOptions.colors" 
                [dataLabels]="chartYearStaffOptions.dataLabels"
                [plotOptions]="chartYearStaffOptions.plotOptions" 
                [yaxis]="chartYearStaffOptions.yaxis"
                [legend]="chartYearStaffOptions.legend" 
                [fill]="chartYearStaffOptions.fill"
                [stroke]="chartYearStaffOptions.stroke" 
                [tooltip]="chartYearStaffOptions.tooltip"
                [xaxis]="chartYearStaffOptions.xaxis" 
                [grid]="chartYearStaffOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.SCENARIO' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.SCENARIO' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartYearStaffSc" class="d-flex flex-column" *ngIf="chartYearStaffScOptions">
            <apx-chart [series]="chartYearStaffScOptions.series" 
                [chart]="chartYearStaffScOptions.chart"
                [colors]="chartYearStaffScOptions.colors" 
                [dataLabels]="chartYearStaffScOptions.dataLabels"
                [plotOptions]="chartYearStaffScOptions.plotOptions" 
                [yaxis]="chartYearStaffScOptions.yaxis"
                [legend]="chartYearStaffScOptions.legend" 
                [fill]="chartYearStaffScOptions.fill"
                [stroke]="chartYearStaffScOptions.stroke" 
                [tooltip]="chartYearStaffScOptions.tooltip"
                [xaxis]="chartYearStaffScOptions.xaxis" 
                [grid]="chartYearStaffScOptions.grid">
            </apx-chart>
        </div>
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.STAFF.FIVE_YEAR_PLAN' | translate:{years: years} }}</p>

<div class="outcomes-charts mb-24">
    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.CURRENT_PRACTICE' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.CURRENT_PRACTICE' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartStaff" class="d-flex flex-column" *ngIf="chartStaffOptions">
            <apx-chart [series]="chartStaffOptions.series" 
                [chart]="chartStaffOptions.chart"
                [colors]="chartStaffOptions.colors" 
                [dataLabels]="chartStaffOptions.dataLabels"
                [plotOptions]="chartStaffOptions.plotOptions" 
                [yaxis]="chartStaffOptions.yaxis"
                [legend]="chartStaffOptions.legend" 
                [fill]="chartStaffOptions.fill"
                [stroke]="chartStaffOptions.stroke" 
                [tooltip]="chartStaffOptions.tooltip"
                [xaxis]="chartStaffOptions.xaxis" 
                [grid]="chartStaffOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.SCENARIO' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.SCENARIO' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartStaffSc" class="d-flex flex-column" *ngIf="chartStaffScOptions">
            <apx-chart [series]="chartStaffScOptions.series" 
                [chart]="chartStaffScOptions.chart"
                [colors]="chartStaffOptions.colors" 
                [dataLabels]="chartStaffScOptions.dataLabels"
                [plotOptions]="chartStaffScOptions.plotOptions" 
                [yaxis]="chartStaffScOptions.yaxis"
                [legend]="chartStaffScOptions.legend" 
                [fill]="chartStaffScOptions.fill"
                [stroke]="chartStaffScOptions.stroke" 
                [tooltip]="chartStaffScOptions.tooltip"
                [xaxis]="chartStaffScOptions.xaxis" 
                [grid]="chartStaffScOptions.grid">
            </apx-chart>
        </div>
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.STAFF.YEAR_STAFF_PLAN' | translate }}</p>

<div class="outcomes-charts mb-24">
    <div class="content-box chart">
        <div class="d-flex justify-content-between mb-16">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.CURRENT_PRACTICE' | translate }} {{ 'SIMULATION.STAFF.REQUIRED' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.CURRENT_PRACTICE' | translate }} {{ 'SIMULATION.STAFF.REQUIRED' | translate }}">info</mat-icon>
        </div>

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container *ngFor="let colDef of colDefinitions">
                <ng-container *ngIf="colDef.sticky" [matColumnDef]="colDef.label" sticky>
                    <th mat-header-cell *matHeaderCellDef>{{colDef.label}}</th>
                    <td mat-cell *matCellDef="let element"  [ngStyle]="{'background-color': chooseStickyColor(element, colDef)}">{{element[colDef.position]}}</td>
                </ng-container>
                <ng-container *ngIf="!colDef.sticky" [matColumnDef]="colDef.label">
                    <th mat-header-cell *matHeaderCellDef>{{colDef.label}}</th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': chooseColor(element, colDef)}">{{element[colDef.position]}}</td>
                </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        {{ 'SIMULATION.STAFF.UTILISATION_100' | translate }}
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between mb-16">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.SCENARIO' | translate }} {{ 'SIMULATION.STAFF.REQUIRED' | translate}}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.SCENARIO' | translate }} {{ 'SIMULATION.STAFF.REQUIRED' | translate}}">info</mat-icon>
        </div>

        <table mat-table [dataSource]="dataSourceSc" class="mat-elevation-z8">
            <ng-container *ngFor="let colDef of colDefinitions">
                <ng-container *ngIf="colDef.sticky" [matColumnDef]="colDef.label" sticky>
                    <th mat-header-cell *matHeaderCellDef>{{colDef.label}}</th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': chooseStickyColor(element, colDef)}">{{element[colDef.position]}}</td>
                </ng-container>
                <ng-container *ngIf="!colDef.sticky" [matColumnDef]="colDef.label">
                    <th mat-header-cell *matHeaderCellDef>{{colDef.label}}</th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': chooseColor(element, colDef)}">{{element[colDef.position]}}</td>
                </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        {{ 'SIMULATION.STAFF.UTILISATION_100' | translate }}
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between mb-16">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.CURRENT_PRACTICE' | translate }} (Optimum)</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.CURRENT_PRACTICE' | translate }} (Optimum)">info</mat-icon>
        </div>
        
        <table mat-table [dataSource]="dataSourceOptimum" class="mat-elevation-z8">
            <ng-container *ngFor="let colDef of colDefinitions">
                <ng-container *ngIf="colDef.sticky" [matColumnDef]="colDef.label" sticky>
                    <th mat-header-cell *matHeaderCellDef>{{colDef.label}}</th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': chooseStickyColor(element, colDef)}">{{element[colDef.position]}}</td>
                </ng-container>
                <ng-container *ngIf="!colDef.sticky" [matColumnDef]="colDef.label">
                    <th mat-header-cell *matHeaderCellDef>{{colDef.label}}</th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': chooseColor(element, colDef)}">{{element[colDef.position]}}</td>
                </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        {{ 'SIMULATION.STAFF.UTILISATION_80' | translate }}
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between mb-16">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.SCENARIO' | translate }} (Optimum)</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.SCENARIO' | translate }} (Optimum)">info</mat-icon>
        </div>
        
        <table mat-table [dataSource]="dataSourceOptimumSc" class="mat-elevation-z8">
            <ng-container *ngFor="let colDef of colDefinitions">
                <ng-container *ngIf="colDef.sticky" [matColumnDef]="colDef.label" sticky>
                    <th mat-header-cell *matHeaderCellDef>{{colDef.label}}</th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': chooseStickyColor(element, colDef)}">{{element[colDef.position]}}</td>
                </ng-container>
                <ng-container *ngIf="!colDef.sticky" [matColumnDef]="colDef.label">
                    <th mat-header-cell *matHeaderCellDef>{{colDef.label}}</th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': chooseColor(element, colDef)}">{{element[colDef.position]}}</td>
                </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        {{ 'SIMULATION.STAFF.UTILISATION_80' | translate }}
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.STAFF.FIVE_YEAR_STAFF_PLAN' | translate:{years: years} }}</p>

<div class="outcomes-charts mb-24">
    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.STAFF.DOCTOR_NAME' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.DOCTOR_NAME' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartDoctor" class="d-flex flex-column" *ngIf="chartDoctorOptions">
            <apx-chart [series]="chartDoctorOptions.series" [chart]="chartDoctorOptions.chart"
                [dataLabels]="chartDoctorOptions.dataLabels" [plotOptions]="chartDoctorOptions.plotOptions"
                [yaxis]="chartDoctorOptions.yaxis" [legend]="chartDoctorOptions.legend"
                [fill]="chartDoctorOptions.fill" [stroke]="chartDoctorOptions.stroke"
                [tooltip]="chartDoctorOptions.tooltip" [xaxis]="chartDoctorOptions.xaxis"
                [grid]="chartDoctorOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.STAFF.PHARMACIST_NAME' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.PHARMACIST_NAME' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartPharmacist" class="d-flex flex-column" *ngIf="chartPharmacistOptions">
            <apx-chart [series]="chartPharmacistOptions.series" [chart]="chartPharmacistOptions.chart"
                [dataLabels]="chartPharmacistOptions.dataLabels"
                [plotOptions]="chartPharmacistOptions.plotOptions" [yaxis]="chartPharmacistOptions.yaxis"
                [legend]="chartPharmacistOptions.legend" [fill]="chartPharmacistOptions.fill"
                [stroke]="chartPharmacistOptions.stroke" [tooltip]="chartPharmacistOptions.tooltip"
                [xaxis]="chartPharmacistOptions.xaxis" [grid]="chartPharmacistOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.STAFF.LAB_NAME' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.LAB_NAME' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartMedicalLabScientist" class="d-flex flex-column"
            *ngIf="chartMedicalLabScientistOptions">
            <apx-chart [series]="chartMedicalLabScientistOptions.series"
                [chart]="chartMedicalLabScientistOptions.chart"
                [dataLabels]="chartMedicalLabScientistOptions.dataLabels"
                [plotOptions]="chartMedicalLabScientistOptions.plotOptions"
                [yaxis]="chartMedicalLabScientistOptions.yaxis"
                [legend]="chartMedicalLabScientistOptions.legend"
                [fill]="chartMedicalLabScientistOptions.fill"
                [stroke]="chartMedicalLabScientistOptions.stroke"
                [tooltip]="chartMedicalLabScientistOptions.tooltip"
                [xaxis]="chartMedicalLabScientistOptions.xaxis"
                [grid]="chartMedicalLabScientistOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.STAFF.HIV_NURSE_NAME' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.HIV_NURSE_NAME' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartHivSpecialistNurse" class="d-flex flex-column" *ngIf="chartHivSpecialistNurseOptions">
            <apx-chart [series]="chartHivSpecialistNurseOptions.series"
                [chart]="chartHivSpecialistNurseOptions.chart"
                [dataLabels]="chartHivSpecialistNurseOptions.dataLabels"
                [plotOptions]="chartHivSpecialistNurseOptions.plotOptions"
                [yaxis]="chartHivSpecialistNurseOptions.yaxis"
                [legend]="chartHivSpecialistNurseOptions.legend"
                [fill]="chartHivSpecialistNurseOptions.fill"
                [stroke]="chartHivSpecialistNurseOptions.stroke"
                [tooltip]="chartHivSpecialistNurseOptions.tooltip"
                [xaxis]="chartHivSpecialistNurseOptions.xaxis" [grid]="chartHivSpecialistNurseOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.STAFF.DIETICIAN_NAME' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.DIETICIAN_NAME' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartDietician" class="d-flex flex-column" *ngIf="chartDieticianOptions">
            <apx-chart [series]="chartDieticianOptions.series" [chart]="chartDieticianOptions.chart"
                [dataLabels]="chartDieticianOptions.dataLabels"
                [plotOptions]="chartDieticianOptions.plotOptions" [yaxis]="chartDieticianOptions.yaxis"
                [legend]="chartDieticianOptions.legend" [fill]="chartDieticianOptions.fill"
                [stroke]="chartDieticianOptions.stroke" [tooltip]="chartDieticianOptions.tooltip"
                [xaxis]="chartDieticianOptions.xaxis" [grid]="chartDieticianOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.STAFF.GENERAL_NURSE_NAME' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.GENERAL_NURSE_NAME' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartGeneralNurse" class="d-flex flex-column" *ngIf="chartGeneralNurseOptions">
            <apx-chart [series]="chartGeneralNurseOptions.series" [chart]="chartGeneralNurseOptions.chart"
                [dataLabels]="chartGeneralNurseOptions.dataLabels"
                [plotOptions]="chartGeneralNurseOptions.plotOptions"
                [yaxis]="chartGeneralNurseOptions.yaxis" [legend]="chartGeneralNurseOptions.legend"
                [fill]="chartGeneralNurseOptions.fill" [stroke]="chartGeneralNurseOptions.stroke"
                [tooltip]="chartGeneralNurseOptions.tooltip" [xaxis]="chartGeneralNurseOptions.xaxis"
                [grid]="chartGeneralNurseOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.STAFF.COUNSELLOR_NAME' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.COUNSELLOR_NAME' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartCounselor" class="d-flex flex-column" *ngIf="chartCounselorOptions">
            <apx-chart [series]="chartCounselorOptions.series" [chart]="chartCounselorOptions.chart"
                [dataLabels]="chartCounselorOptions.dataLabels"
                [plotOptions]="chartCounselorOptions.plotOptions" [yaxis]="chartCounselorOptions.yaxis"
                [legend]="chartCounselorOptions.legend" [fill]="chartCounselorOptions.fill"
                [stroke]="chartCounselorOptions.stroke" [tooltip]="chartCounselorOptions.tooltip"
                [xaxis]="chartCounselorOptions.xaxis" [grid]="chartCounselorOptions.grid">
            </apx-chart>
        </div>
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.STAFF.FIVE_YEAR_STAFF_FORECAST_PLAN' | translate:{years: years} }}</p>

<div class="outcomes-charts">
    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.STAFF.DOCTOR_NAME' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.DOCTOR_NAME' | translate }} (Optimum)">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartDoctorTotal" class="d-flex flex-column" *ngIf="chartDoctorTotalOptions">
            <apx-chart [series]="chartDoctorTotalOptions.series" [chart]="chartDoctorTotalOptions.chart"
                [dataLabels]="chartDoctorTotalOptions.dataLabels"
                [plotOptions]="chartDoctorTotalOptions.plotOptions" [yaxis]="chartDoctorTotalOptions.yaxis"
                [legend]="chartDoctorTotalOptions.legend" [fill]="chartDoctorTotalOptions.fill"
                [stroke]="chartDoctorTotalOptions.stroke" [tooltip]="chartDoctorTotalOptions.tooltip"
                [xaxis]="chartDoctorTotalOptions.xaxis" [grid]="chartDoctorTotalOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.STAFF.PHARMACIST_NAME' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.PHARMACIST_NAME' | translate }} (Optimum)">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartPharmacistTotal" class="d-flex flex-column" *ngIf="chartPharmacistTotalOptions">
            <apx-chart [series]="chartPharmacistTotalOptions.series"
                [chart]="chartPharmacistTotalOptions.chart"
                [dataLabels]="chartPharmacistTotalOptions.dataLabels"
                [plotOptions]="chartPharmacistTotalOptions.plotOptions"
                [yaxis]="chartPharmacistTotalOptions.yaxis" [legend]="chartPharmacistTotalOptions.legend"
                [fill]="chartPharmacistTotalOptions.fill" [stroke]="chartPharmacistTotalOptions.stroke"
                [tooltip]="chartPharmacistTotalOptions.tooltip" [xaxis]="chartPharmacistTotalOptions.xaxis"
                [grid]="chartPharmacistTotalOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.STAFF.LAB_NAME' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.LAB_NAME' | translate }} (Optimum)">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartMedicalLabScientistTotal" class="d-flex flex-column"
            *ngIf="chartMedicalLabScientistTotalOptions">
            <apx-chart [series]="chartMedicalLabScientistTotalOptions.series"
                [chart]="chartMedicalLabScientistTotalOptions.chart"
                [dataLabels]="chartMedicalLabScientistTotalOptions.dataLabels"
                [plotOptions]="chartMedicalLabScientistTotalOptions.plotOptions"
                [yaxis]="chartMedicalLabScientistTotalOptions.yaxis"
                [legend]="chartMedicalLabScientistTotalOptions.legend"
                [fill]="chartMedicalLabScientistTotalOptions.fill"
                [stroke]="chartMedicalLabScientistTotalOptions.stroke"
                [tooltip]="chartMedicalLabScientistTotalOptions.tooltip"
                [xaxis]="chartMedicalLabScientistTotalOptions.xaxis"
                [grid]="chartMedicalLabScientistTotalOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.STAFF.HIV_NURSE_NAME' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.HIV_NURSE_NAME' | translate }} (Optimum)">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartHivSpecialistNurseTotal" class="d-flex flex-column"
            *ngIf="chartHivSpecialistNurseTotalOptions">
            <apx-chart [series]="chartHivSpecialistNurseTotalOptions.series"
                [chart]="chartHivSpecialistNurseTotalOptions.chart"
                [dataLabels]="chartHivSpecialistNurseTotalOptions.dataLabels"
                [plotOptions]="chartHivSpecialistNurseTotalOptions.plotOptions"
                [yaxis]="chartHivSpecialistNurseTotalOptions.yaxis"
                [legend]="chartHivSpecialistNurseTotalOptions.legend"
                [fill]="chartHivSpecialistNurseTotalOptions.fill"
                [stroke]="chartHivSpecialistNurseTotalOptions.stroke"
                [tooltip]="chartHivSpecialistNurseTotalOptions.tooltip"
                [xaxis]="chartHivSpecialistNurseTotalOptions.xaxis"
                [grid]="chartHivSpecialistNurseTotalOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.STAFF.DIETICIAN_NAME' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.DIETICIAN_NAME' | translate }} (Optimum)">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartDieticianTotal" class="d-flex flex-column" *ngIf="chartDieticianTotalOptions">
            <apx-chart [series]="chartDieticianTotalOptions.series"
                [chart]="chartDieticianTotalOptions.chart"
                [dataLabels]="chartDieticianTotalOptions.dataLabels"
                [plotOptions]="chartDieticianTotalOptions.plotOptions"
                [yaxis]="chartDieticianTotalOptions.yaxis" [legend]="chartDieticianTotalOptions.legend"
                [fill]="chartDieticianTotalOptions.fill" [stroke]="chartDieticianTotalOptions.stroke"
                [tooltip]="chartDieticianTotalOptions.tooltip" [xaxis]="chartDieticianTotalOptions.xaxis"
                [grid]="chartDieticianTotalOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.STAFF.GENERAL_NURSE_NAME' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.GENERAL_NURSE_NAME' | translate }} (Optimum)">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartGeneralNurseTotal" class="d-flex flex-column" *ngIf="chartGeneralNurseTotalOptions">
            <apx-chart [series]="chartGeneralNurseTotalOptions.series"
                [chart]="chartGeneralNurseTotalOptions.chart"
                [dataLabels]="chartGeneralNurseTotalOptions.dataLabels"
                [plotOptions]="chartGeneralNurseTotalOptions.plotOptions"
                [yaxis]="chartGeneralNurseTotalOptions.yaxis"
                [legend]="chartGeneralNurseTotalOptions.legend" [fill]="chartGeneralNurseTotalOptions.fill"
                [stroke]="chartGeneralNurseTotalOptions.stroke"
                [tooltip]="chartGeneralNurseTotalOptions.tooltip"
                [xaxis]="chartGeneralNurseTotalOptions.xaxis" [grid]="chartGeneralNurseTotalOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.STAFF.COUNSELLOR_NAME' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.COUNSELLOR_NAME' | translate }} (Optimum)">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.STAFF.STAFF' | translate }}</p>

        <div id="chartCounselorTotal" class="d-flex flex-column" *ngIf="chartCounselorTotalOptions">
            <apx-chart [series]="chartCounselorTotalOptions.series"
                [chart]="chartCounselorTotalOptions.chart"
                [dataLabels]="chartCounselorTotalOptions.dataLabels"
                [plotOptions]="chartCounselorTotalOptions.plotOptions"
                [yaxis]="chartCounselorTotalOptions.yaxis" [legend]="chartCounselorTotalOptions.legend"
                [fill]="chartCounselorTotalOptions.fill" [stroke]="chartCounselorTotalOptions.stroke"
                [tooltip]="chartCounselorTotalOptions.tooltip"
                [xaxis]="chartCounselorTotalOptions.xaxis" [grid]="chartCounselorTotalOptions.grid">
            </apx-chart>
        </div>
    </div>
</div>