/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Page, PageableSort } from '../model/pagination';
import { createPageParams } from '../util';
import { KinPatient } from '../model/kin-patient';

@Injectable({
    providedIn: 'root'
})
export class KinPatientService {

    private baseUrl = '/ums/v1/kin-patient';
    private baseAdminUrl = '/ums/v1/admin-ops/kin-patient';

    constructor(private http: HttpClient) {
    }

    getKinsOfPatient(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<KinPatient>> {
        const pageParams = createPageParams(page, pageSize, sort, searchTerm);
        return this.http.get<Page<KinPatient>>(environment.url + `${this.baseUrl}/kins-of-patient`, { params: pageParams })
            .pipe(map((res: any) => res || {}));
    }

    getPatientsOfKin(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<KinPatient>> {
        const pageParams = createPageParams(page, pageSize, sort, searchTerm);
        return this.http.get<Page<KinPatient>>(environment.url + `${this.baseUrl}/patients-of-kin`, { params: pageParams })
            .pipe(map((res: any) => res || {}));
    }

    listAllKinPatientByAdmin(page: number, pageSize: number, sort?: PageableSort, searchTerm?: string): Observable<Page<KinPatient>> {
        const pageParams = createPageParams(page, pageSize, sort, searchTerm);
        return this.http.get<Page<KinPatient>>(environment.url + `${this.baseAdminUrl}`, { params: pageParams })
            .pipe(map((res: any) => res || {}));
    }

}
