import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/* eslint-disable max-len */
export class PasswordErrorStateMatcher implements ErrorStateMatcher {
    constructor(private passwordFieldName: string, private passwordVerifyFieldName) {}

    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return (control && control.parent.get(this.passwordFieldName).value !== control.parent.get(this.passwordVerifyFieldName).value && control.dirty);
    }
}
