<div class="content-box mb-24">
    <!-- <mat-card-content> -->
        <div class="d-flex align-items-center justify-content-between mb-16">
            <h4 class="content-box__title mb-0">{{'CLINICAL_OUTCOME.TITLE.CLINICAL_OUTCOME_SUMMARY' | translate}}</h4>
            <button class="filled-btn tertiary md" (click)="onEdit()">Edit</button>
        </div>
        <div class="summary-box">
            <ng-container *ngFor="let questionInfo of convertedInfoList;;let i = index">
                <!-- <div class="cust-property-card" [ngClass]="questionInfo.styleClass" fxFlex.gt-sm="48" fxFlex.gt-md="24" fxFlex="100">
                    <div class="cust-property-card-title">{{questionInfo.questionText}}</div>
                    <span class="cust-property-card-decription" *ngFor="let answerText of questionInfo.answerTextList;">{{answerText}}</span>
                </div> -->
                <div [class]="'summary-box__item bg-color-'+(i+1)">
                    <div class="summary-box__item--head">
                        <div class="icon">
                            <!-- <i [class]="getIconName()' font-size-2-7 text-color-'+(i+1)"></i> -->
                            <i class="{{getIconName(questionInfo.questionText) +' text-color-'+(i+1)}}"></i>
                        </div>
                        <h3 class="title">{{questionInfo.questionText}}</h3>
                    </div>

                    <ul *ngIf="questionInfo.answerTextList.length > 1;else elseBlock" class="summary-box__item--body-list">
                        <li *ngFor="let answerText of questionInfo.answerTextList;">{{answerText}}</li>
                    </ul>

                    <ng-template #elseBlock>
                        <p class="summary-box__item--body-text" *ngFor="let answerText of questionInfo.answerTextList;">{{answerText}}</p>
                    </ng-template>
                </div>
            </ng-container>
        </div>
    <!-- </mat-card-content> -->
</div>