import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { MutationService } from 'src/app/shared/services/mutation.service';
import { MutationUploadComponent } from '../../component/mutation-upload/mutation-upload.component';

@Component({
  selector: 'app-mutation-list',
  templateUrl: './mutation-list.component.html',
  styleUrls: ['./mutation-list.component.scss']
})
export class MutationListComponent extends BaseListComponent implements OnInit {
  @ViewChild('idTemplate', { static: true }) idTemplate: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private mutationService: MutationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.allColumnList = [
      {
        prop: 'id',
        name: this.translate.instant('MUTATION_LIST.COL_LBL.ID'),
        cellTemplate: this.idTemplate,
        width: 100,
        maxWidth: 100
      },
      { prop: 'mutation', name: this.translate.instant('MUTATION_LIST.COL_LBL.MUTATION'), cellClass: '' },
      { prop: 'mutationClass', name: this.translate.instant('MUTATION_LIST.COL_LBL.MUTATION_CLASS'), cellClass: '' },
      { prop: 'code0001Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0001CODE0002'), cellClass: '' },
      { prop: 'code0001Code0002Code0024', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0001CODE0002CODE0024'), cellClass: '' },
      { prop: 'code0025', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0025'), cellClass: '' },
      { prop: 'code0025Code0001Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0025CODE0001CODE0002'), cellClass: '' },
      { prop: 'code0006', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0006'), cellClass: '' },
      { prop: 'code0006Code0001Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0006CODE0001CODE0002'), cellClass: '' },
      { prop: 'code0006Code0005Code0008', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0006CODE0005CODE0008'), cellClass: '' },
      { prop: 'code0006Code0008Code0005', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0006CODE0008CODE0005'), cellClass: '' },
      { prop: 'code0009', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0009'), cellClass: '' },
      { prop: 'code0026', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0026'), cellClass: '' },
      { prop: 'code0009Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0009CODE0002'), cellClass: '' },
      { prop: 'code0009Code0001Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0009CODE0001CODE0002'), cellClass: '' },
      { prop: 'code0009Code0005Code0008', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0009CODE0005CODE0008'), cellClass: '' },
      { prop: 'code0009Code0007Code0005', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0009CODE0007CODE0005'), cellClass: '' },
      { prop: 'code0009Code0008Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0009CODE0008CODE0002'), cellClass: '' },
      { prop: 'code0009Code0008Code0005', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0009CODE0008CODE0005'), cellClass: '' },
      { prop: 'code0010Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0010CODE0002'), cellClass: '' },
      { prop: 'code0027Code0014', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0027CODE0014'), cellClass: '' },
      { prop: 'code0012Code0002Code0011', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0012CODE0002CODE0011'), cellClass: '' },
      { prop: 'code0021Code0002Code0008', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0021CODE0002CODE0008'), cellClass: '' },
      { prop: 'code0013', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0013'), cellClass: '' },
      { prop: 'code0013Code0001Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0013CODE0001CODE0002'), cellClass: '' },
      { prop: 'code0013Code0007Code0005', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0013CODE0007CODE0005'), cellClass: '' },
      { prop: 'code0013Code0008Code0005', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0013CODE0008CODE0005'), cellClass: '' },
      { prop: 'code0015', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0015'), cellClass: '' },
      { prop: 'code0015Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0015CODE0002'), cellClass: '' },
      { prop: 'code0015Code0001Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0015CODE0001CODE0002'), cellClass: '' },
      { prop: 'code0015Code0019', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0015CODE0019'), cellClass: '' },
      { prop: 'code0015Code0050', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0015CODE0050'), cellClass: '' },
      { prop: 'code0015Code0007Code0005', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0015CODE0007CODE0005'), cellClass: '' },
      { prop: 'code0015Code0008Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0015CODE0008CODE0002'), cellClass: '' },
      { prop: 'code0015Code0008Code0005', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0015CODE0008CODE0005'), cellClass: '' },
      { prop: 'code0019', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0019'), cellClass: '' },
      { prop: 'code0019Code0023', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0019CODE0023'), cellClass: '' },
      { prop: 'code0019Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0019CODE0002'), cellClass: '' },
      { prop: 'code0019Code0002Code0001', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0019CODE0002CODE0001'), cellClass: '' },
      { prop: 'code0019Code0005Code0007', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0019CODE0005CODE0007'), cellClass: '' },
      { prop: 'code0019Code0005Code0008', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0019CODE0005CODE0008'), cellClass: '' },
      { prop: 'code0019Code0001Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0019CODE0001CODE0002'), cellClass: '' },
      { prop: 'code0019Code0014', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0019CODE0014'), cellClass: '' },
      { prop: 'code0011', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0011'), cellClass: '' },
      { prop: 'code0011Code0002Code0008', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0011CODE0002CODE0008'), cellClass: '' },
      { prop: 'code0011Code0005Code0007', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0011CODE0005CODE0007'), cellClass: '' },
      { prop: 'code0011Code0005Code0008', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0011CODE0005CODE0008'), cellClass: '' },
      { prop: 'code0032', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0032'), cellClass: '' },
      { prop: 'code0017Code0007Code0005', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0017CODE0007CODE0005'), cellClass: '' },
      { prop: 'code0017Code0008Code0005', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0017CODE0008CODE0005'), cellClass: '' },
      { prop: 'code0005Code0007', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0005CODE0007'), cellClass: '' },
      { prop: 'code0005Code0008', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0005CODE0008'), cellClass: '' },
      { prop: 'code0053', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0053'), cellClass: '' },
      { prop: 'code0003Code0037', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0003CODE0037'), cellClass: '' },
      { prop: 'code0003Code0037Code0023', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0003CODE0037CODE0023'), cellClass: '' },
      {
        prop: 'code0003Code0037Code0001Code0002',
        name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0003CODE0037CODE0001CODE0002'),
        cellClass: '' },
      { prop: 'code0003Code0037Code0001Code0028',
        name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0003CODE0037CODE0001CODE0028'),
        cellClass: '' },
      { prop: 'code0003Code0037Code0007Code0005',
        name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0003CODE0037CODE0007CODE0005'),
        cellClass: '' },
      { prop: 'code0003Code0037Code0008Code0005',
        name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0003CODE0037CODE0008CODE0005'),
        cellClass: '' },
      { prop: 'code0003Code0037Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0003CODE0037CODE0002'), cellClass: '' },
      { prop: 'code0035', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0035'), cellClass: '' },
      { prop: 'code0035Code0022', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0035CODE0022'), cellClass: '' },
      { prop: 'code0035Code0015', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0035CODE0015'), cellClass: '' },
      { prop: 'code0035Code0036', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0035CODE0036'), cellClass: '' },
      { prop: 'code0035Code0005Code0007', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0035CODE0005CODE0007'), cellClass: '' },
      { prop: 'code0035Code0005Code0008', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0035CODE0005CODE0008'), cellClass: '' },
      { prop: 'code0004', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0004'), cellClass: '' },
      { prop: 'code0016', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0016'), cellClass: '' },
      { prop: 'code0016Code0001Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0016CODE0001CODE0002'), cellClass: '' },
      { prop: 'code0016Code0007Code0005', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0016CODE0007CODE0005'), cellClass: '' },
      { prop: 'code0016Code0008Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0016CODE0008CODE0002'), cellClass: '' },
      { prop: 'code0016Code0008Code0005', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0016CODE0008CODE0005'), cellClass: '' },
      { prop: 'code0014Code0001Code0002', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0014CODE0001CODE0002'), cellClass: '' },
      { prop: 'code0014Code0007Code0005', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0014CODE0007CODE0005'), cellClass: '' },
      { prop: 'code0014Code0008Code0005', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0014CODE0008CODE0005'), cellClass: '' },
      { prop: 'code0037', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0037'), cellClass: '' },
      { prop: 'code0007', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0007'), cellClass: '' },
      { prop: 'code0007Code0005Code0020', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0007CODE0005CODE0020'), cellClass: '' },
      { prop: 'code0007Code0005Code0004', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0007CODE0005CODE0004'), cellClass: '' },
      { prop: 'code0008Code0005', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0008CODE0005'), cellClass: '' },
      { prop: 'code0008Code0005Code0004', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0008CODE0005CODE0004'), cellClass: '' },
      { prop: 'code0008Code0005Code0021', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0008CODE0005CODE0021'), cellClass: '' },
      { prop: 'code0038', name: this.translate.instant('MUTATION_LIST.COL_LBL.CODE0038'), cellClass: '' },

    ];

    this.columns = [
      this.allColumnList[0],
      this.allColumnList[1],
      this.allColumnList[2],
      this.allColumnList[3],
      this.allColumnList[4],
      this.allColumnList[5],
      this.allColumnList[6],
      this.allColumnList[7],
      this.allColumnList[8],
      this.allColumnList[9],
    ];

    this.loadData();
  }

  openUploadDialog(): void {
    const dialogConfig = this.createDialogConfig(null);
    const dialogRef = this.dialog.open(MutationUploadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.loadData();
    });
  }

  loadData() {
    this.mutationService.getMutations(this.page.pageNumber, this.page.size, this.sort).subscribe(page => {
      this.page.pageNumber = page.number;
      this.page.totalElements = page.totalElements;
      this.page.size = page.size;
      this.rows = page.content;
    });
  }

}
