/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoginResponse } from '../model/user-profile';
import { AuditlyticsPermission } from '../model/auditlytics-permission';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    private KEY_LOGIN_INFO = 'login-info';
    private KEY_LANGUAGE = 'language';
    private KEY_OTP_INFO = 'otp-info';
    private KEY_COUNTRY = 'country';
    private KEY_SIMULATION = 'simulation';
    private KEY_SCENARIO = 'scenario';
    private KEY_SIMULATION_RESULT = 'simulation-result';

    private KEY_PATIENTS = 'simulation-patients';
    private KEY_VISITS = 'simulation-visits';
    private KEY_HOURS = 'simulation-hours';

    private KEY_PATIENTS_SC = 'simulation-patients-sc';
    private KEY_VISITS_SC = 'simulation-visits-sc';
    private KEY_HOURS_SC = 'simulation-hours-sc';

    private KEY_TRIAL_FILTER = 'trial-filter';

    private KEY_AUDIT_FILTER = 'audit-filter';
    private KEY_AUDITLYTICS_PERMISSION = 'auditlytics-permission';

    private virtualLocalStorage: Map<string, string> = new Map<string, string>();

    constructor(@Inject(PLATFORM_ID) private platformId: any) {}

    public clearAllStorage(): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.clear();
        } else {
            this.virtualLocalStorage = new Map<string, string>();
        }
    }

    private getStorageItem(key: string): any {
        return isPlatformBrowser(this.platformId) ? localStorage.getItem(key) : this.virtualLocalStorage.get(key);
    }

    private setStorageItem(key: string, value: string): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(key, value);
        } else {
            this.virtualLocalStorage.set(key, value);
        }
    }

    private removeStorageItem(key: string): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem(key);
        } else {
            this.virtualLocalStorage.delete(key);
        }
    }

    public getLoginInfo(): LoginResponse {
        const loginResponse = JSON.parse(this.getStorageItem(this.KEY_LOGIN_INFO));
        const auditlyticsPermission = this.getAuditlyticsPermission();
        if (loginResponse && auditlyticsPermission) {
            loginResponse.auditlyticsPermission = auditlyticsPermission;
        }
        return loginResponse;
    }

    public setLoginInfo(loginInfo: LoginResponse): void {
        this.setStorageItem(this.KEY_LOGIN_INFO, JSON.stringify(loginInfo));
    }

    public removeLoginInfo(): void {
        this.removeStorageItem(this.KEY_LOGIN_INFO);
    }

    public getLanguage(): string {
        return this.getStorageItem(this.KEY_LANGUAGE);
    }

    public setLanguage(language: string): void {
        this.setStorageItem(this.KEY_LANGUAGE, language);
    }

    public removeLanguage(): void {
        this.removeStorageItem(this.KEY_LANGUAGE);
    }

    public getAuditlyticsPermission(): AuditlyticsPermission {
        return JSON.parse(this.getStorageItem(this.KEY_AUDITLYTICS_PERMISSION));
    }

    public setAuditlyticsPermission(auditlyticsPermission: AuditlyticsPermission): void {
        this.setStorageItem(this.KEY_AUDITLYTICS_PERMISSION, JSON.stringify(auditlyticsPermission));
    }

    public removeAuditlyticsPermission(): void {
        this.removeStorageItem(this.KEY_AUDITLYTICS_PERMISSION);
    }

    public getCountry(): string {
        return this.getStorageItem(this.KEY_COUNTRY);
    }

    public setCountry(value: string): void {
        this.setStorageItem(this.KEY_COUNTRY, value);
    }

    public removeCountry(): void {
        this.removeStorageItem(this.KEY_COUNTRY);
    }

    public getSimulation(): string {
        return this.getStorageItem(this.KEY_SIMULATION);
    }

    public setSimulation(simulation: string): void {
        this.setStorageItem(this.KEY_SIMULATION, simulation);
    }

    public removeSimulation(): void {
        this.removeStorageItem(this.KEY_SIMULATION);
    }

    public getScenario(): string {
        return this.getStorageItem(this.KEY_SCENARIO);
    }

    public setScenario(simulation: string): void {
        this.setStorageItem(this.KEY_SCENARIO, simulation);
    }

    public removeScenario(): void {
        this.removeStorageItem(this.KEY_SCENARIO);
    }

    public getSimulationResult(): string {
        return this.getStorageItem(this.KEY_SIMULATION_RESULT);
    }

    public setSimulationResult(result: string): void {
        this.setStorageItem(this.KEY_SIMULATION_RESULT, result);
    }

    public removeSimulationResult(): void {
        this.removeStorageItem(this.KEY_SIMULATION_RESULT);
    }

    public getPatients(): string {
        return JSON.parse(this.getStorageItem(this.KEY_PATIENTS));
    }

    public setPatients(patients: string): void {
        this.setStorageItem(this.KEY_PATIENTS, patients);
    }

    public removePatients(): void {
        this.removeStorageItem(this.KEY_PATIENTS);
    }

    public getPatientsSc(): string {
        return JSON.parse(this.getStorageItem(this.KEY_PATIENTS_SC));
    }

    public setPatientsSc(patients: string): void {
        this.setStorageItem(this.KEY_PATIENTS_SC, patients);
    }

    public removePatientsSc(): void {
        this.removeStorageItem(this.KEY_PATIENTS_SC);
    }

    public getVisits(): string {
        return JSON.parse(this.getStorageItem(this.KEY_VISITS));
    }

    public setVisits(visits: string): void {
        this.setStorageItem(this.KEY_VISITS, visits);
    }

    public removeVisits(): void {
        this.removeStorageItem(this.KEY_VISITS);
    }

    public getVisitsSc(): string {
        return JSON.parse(this.getStorageItem(this.KEY_VISITS_SC));
    }

    public setVisitsSc(visits: string): void {
        this.setStorageItem(this.KEY_VISITS_SC, visits);
    }

    public removeVisitsSc(): void {
        this.removeStorageItem(this.KEY_VISITS_SC);
    }

    public getHours(): string {
        return JSON.parse(this.getStorageItem(this.KEY_HOURS));
    }

    public setHours(hours: string): void {
        this.setStorageItem(this.KEY_HOURS, hours);
    }

    public removeHours(): void {
        this.removeStorageItem(this.KEY_HOURS);
    }

    public getHoursSc(): string {
        return JSON.parse(this.getStorageItem(this.KEY_HOURS_SC));
    }

    public setHoursSc(hours: string): void {
        this.setStorageItem(this.KEY_HOURS_SC, hours);
    }

    public removeHoursSc(): void {
        this.removeStorageItem(this.KEY_HOURS_SC);
    }

    public getTrialFilter(): string {
        return JSON.parse(this.getStorageItem(this.KEY_TRIAL_FILTER));
    }

    public setTrialFilter(filter: string): void {
        this.setStorageItem(this.KEY_TRIAL_FILTER, filter);
    }

    public removeTrialFilter(): void {
        this.removeStorageItem(this.KEY_TRIAL_FILTER);
    }

    public getAuditFilter(): string {
        return JSON.parse(this.getStorageItem(this.KEY_AUDIT_FILTER));
    }

    public setAuditFilter(filter: string): void {
        this.setStorageItem(this.KEY_AUDIT_FILTER, filter);
    }

    public removeAuditFilter(): void {
        this.removeStorageItem(this.KEY_AUDIT_FILTER);
    }

}
