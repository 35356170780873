import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadInfoComponent } from './component/upload-info/upload-info.component';
import { FileUploadComponent } from './component/file-upload/file-upload.component';
import { BytesPipe } from './pipe/bytes.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { CustomMaterialModule } from '../material/material.module';
import { DragDropFileDirective } from './directive/drag-drop-file.directive';


@NgModule({
  declarations: [
    UploadInfoComponent,
    FileUploadComponent,
    BytesPipe,
    DragDropFileDirective
  ],
  imports: [
    CommonModule,
    TranslateModule,
    CustomMaterialModule
  ],
  exports: [
    UploadInfoComponent,
    FileUploadComponent
  ]
})
export class FileUploadModule { }
