<h1 mat-dialog-title>{{ 'SIMULATION.SERVICE.HEADER' | translate }}</h1>
<div mat-dialog-content>
    <mat-tab-group>
        <mat-tab label="{{ 'SIMULATION.SERVICE.PATIENT_ATTRIBUTES' | translate }}">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <div fxFlex="50">
                    <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                        <h4>
                            {{ 'SIMULATION.CURRENT_PRACTICE' | translate }}
                        </h4>
                        <mat-card class="blue-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.SEX' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_SEX' | translate }}">info
                                    </mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="column" fxLayoutAlign="space-around center">
                                    <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                        <mat-form-field fxFlex="30">
                                            <input currencyMask [(ngModel)]="data.percentageMale"
                                                [formControl]="percentageMaleControl" maxlength="4"
                                                [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                                matNativeControl
                                                placeholder="{{ 'SIMULATION.SERVICE.MALE_PERCENTAGE' | translate }}"
                                                matTooltip="{{ 'SIMULATION.SERVICE.MALE_PERCENTAGE' | translate }}" required>
                                            <mat-error *ngIf="percentageMaleControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}</mat-error>
                                        </mat-form-field>
                                        <mat-form-field fxFlex="30">
                                            <input currencyMask [(ngModel)]="data.percentageFemale"
                                                [formControl]="percentageFemaleControl" maxlength="4"
                                                [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                                matNativeControl
                                                placeholder="{{ 'SIMULATION.SERVICE.FEMALE_PERCENTAGE' | translate }}"
                                                matTooltip="{{ 'SIMULATION.SERVICE.FEMALE_PERCENTAGE' | translate }}" required/>
                                            <mat-error *ngIf="percentageFemaleControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <mat-error *ngIf="!isSimGenderTotalTrue()">{{ 'SIMULATION.SERVICE.TOTAL_ERROR' | translate }}</mat-error>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>

                <div fxFlex="50">
                    <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                        <h4>
                            {{ 'SIMULATION.SCENARIO' | translate }}
                        </h4>
                        <mat-card class="pink-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.SEX' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_SEX' | translate }}">info</mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="column" fxLayoutAlign="space-around center">
                                    <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                        <mat-form-field fxFlex="30">
                                            <input currencyMask [(ngModel)]="data.scPercentageMale"
                                                [formControl]="scPercentageMaleControl" maxlength="4"
                                                [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                                matNativeControl
                                                placeholder="{{ 'SIMULATION.SERVICE.MALE_PERCENTAGE' | translate }}"
                                                matTooltip="{{ 'SIMULATION.SERVICE.MALE_PERCENTAGE' | translate }}" required/>
                                            <mat-error *ngIf="scPercentageMaleControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}</mat-error>
                                        </mat-form-field>
                                        <mat-form-field fxFlex="30">
                                            <input currencyMask [(ngModel)]="data.scPercentageFemale"
                                                [formControl]="scPercentageFemaleControl" maxlength="4"
                                                [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                                matNativeControl
                                                placeholder="{{ 'SIMULATION.SERVICE.FEMALE_PERCENTAGE' | translate }}"
                                                matTooltip="{{ 'SIMULATION.SERVICE.FEMALE_PERCENTAGE' | translate }}" required/>
                                            <mat-error *ngIf="scPercentageFemaleControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <mat-error *ngIf="!isScenGenderTotalTrue()">{{ 'SIMULATION.SERVICE.TOTAL_ERROR' | translate }}</mat-error>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <div fxFlex="50">
                    <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                        <mat-card class="blue-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.AGE_GROUPS' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_AGE' | translate }}">info</mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="column" fxLayoutAlign="space-around center">
                                    <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                        <mat-form-field fxFlex="40">
                                            <input currencyMask [(ngModel)]="data.percentageChildren"
                                                [formControl]="percentageChildrenControl" maxlength="5"
                                                [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                                matNativeControl
                                                placeholder="{{ 'SIMULATION.SERVICE.CHILDREN_PERCENTAGE' | translate }}"
                                                matTooltip="{{ 'SIMULATION.SERVICE.CHILDREN_PERCENTAGE' | translate }}" required/>
                                            <mat-error *ngIf="percentageChildrenControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}</mat-error>
                                        </mat-form-field>
                                        <mat-form-field fxFlex="40">
                                            <input currencyMask [(ngModel)]="data.percentageAdolescent"
                                                [formControl]="percentageAdolescentControl" maxlength="5"
                                                [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                                matNativeControl
                                                placeholder="{{ 'SIMULATION.SERVICE.ADOLESCENT_PERCENTAGE' | translate }}"
                                                matTooltip="{{ 'SIMULATION.SERVICE.ADOLESCENT_PERCENTAGE' | translate }}" required/>
                                            <mat-error *ngIf="percentageAdolescentControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                        <mat-form-field fxFlex="40">
                                            <input currencyMask [(ngModel)]="data.percentageAdults"
                                                [formControl]="percentageAdultsControl" maxlength="5"
                                                [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                                matNativeControl
                                                placeholder="{{ 'SIMULATION.SERVICE.ADULT_PERCENTAGE' | translate }}"
                                                matTooltip="{{ 'SIMULATION.SERVICE.ADULT_PERCENTAGE' | translate }}" required/>
                                            <mat-error *ngIf="percentageAdultsControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}</mat-error>
                                        </mat-form-field>
                                        <mat-form-field fxFlex="40">
                                            <input currencyMask [(ngModel)]="data.percentageOlderAdults"
                                                [formControl]="percentageOlderAdultsControl" maxlength="5"
                                                [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                                matNativeControl
                                                placeholder="{{ 'SIMULATION.SERVICE.OLDER_ADULT_PERCENTAGE' | translate }}"
                                                matTooltip="{{ 'SIMULATION.SERVICE.OLDER_ADULT_PERCENTAGE' | translate }}" required/>
                                            <mat-error *ngIf="percentageOlderAdultsControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <mat-error *ngIf="!isSimAgeTotalTrue()">{{ 'SIMULATION.SERVICE.TOTAL_ERROR' | translate }}</mat-error>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>

                <div fxFlex="50">
                    <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                        <mat-card class="pink-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.AGE_GROUPS' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_AGE' | translate }}">info</mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="column" fxLayoutAlign="space-around center">
                                    <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                        <mat-form-field fxFlex="40">
                                            <input currencyMask [(ngModel)]="data.scPercentageChildren"
                                                [formControl]="scPercentageChildrenControl" maxlength="5"
                                                [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                                matNativeControl
                                                placeholder="{{ 'SIMULATION.SERVICE.CHILDREN_PERCENTAGE' | translate }}"
                                                matTooltip="{{ 'SIMULATION.SERVICE.CHILDREN_PERCENTAGE' | translate }}" required/>
                                            <mat-error *ngIf="scPercentageChildrenControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}</mat-error>
                                        </mat-form-field>
                                        <mat-form-field fxFlex="40">
                                            <input currencyMask [(ngModel)]="data.scPercentageAdolescent"
                                                [formControl]="scPercentageAdolescentControl" maxlength="5"
                                                [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                                matNativeControl
                                                placeholder="{{ 'SIMULATION.SERVICE.ADOLESCENT_PERCENTAGE' | translate }}"
                                                matTooltip="{{ 'SIMULATION.SERVICE.ADOLESCENT_PERCENTAGE' | translate }}" required/>
                                            <mat-error *ngIf="scPercentageAdolescentControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                        <mat-form-field fxFlex="40">
                                            <input currencyMask [(ngModel)]="data.scPercentageAdults"
                                                [formControl]="scPercentageAdultsControl" maxlength="5"
                                                [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                                matNativeControl
                                                placeholder="{{ 'SIMULATION.SERVICE.ADULT_PERCENTAGE' | translate }}"
                                                matTooltip="{{ 'SIMULATION.SERVICE.ADULT_PERCENTAGE' | translate }}" required/>
                                            <mat-error *ngIf="scPercentageAdultsControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}</mat-error>
                                        </mat-form-field>
                                        <mat-form-field fxFlex="40">
                                            <input currencyMask [(ngModel)]="data.scPercentageOlderAdults"
                                                [formControl]="scPercentageOlderAdultsControl" maxlength="5"
                                                [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                                matNativeControl
                                                placeholder="{{ 'SIMULATION.SERVICE.OLDER_ADULT_PERCENTAGE' | translate }}"
                                                matTooltip="{{ 'SIMULATION.SERVICE.OLDER_ADULT_PERCENTAGE' | translate }}" required/>
                                            <mat-error *ngIf="scPercentageOlderAdultsControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <mat-error *ngIf="!isScenAgeTotalTrue()">{{ 'SIMULATION.SERVICE.TOTAL_ERROR' | translate }}</mat-error>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <div fxFlex="50">
                    <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                        <mat-card class="blue-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.PREGNANTS' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_PREGNANT' | translate }}">info</mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="column" fxLayoutAlign="space-around center">
                                    <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                        <mat-form-field fxFlex="90">
                                            <input currencyMask [(ngModel)]="data.percentagePregnant"
                                                [formControl]="percentagePregnantControl" maxlength="7"
                                                [options]="{ thousands: ',', precision: '2', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                                matNativeControl
                                                placeholder="{{ 'SIMULATION.SERVICE.PREGNANT_PERCENTAGE' | translate }}"
                                                matTooltip="{{ 'SIMULATION.SERVICE.PREGNANT_PERCENTAGE' | translate }}" required/>
                                            <mat-error *ngIf="percentagePregnantControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>

                <div fxFlex="50">
                    <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                        <mat-card class="pink-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.PREGNANTS' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_PREGNANT' | translate }}">info</mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="column" fxLayoutAlign="space-around center">
                                    <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                        <mat-form-field fxFlex="90">
                                            <input currencyMask [(ngModel)]="data.scPercentagePregnant"
                                                [formControl]="scPercentagePregnantControl" maxlength="7"
                                                [options]="{ thousands: ',', precision: '2', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                                matNativeControl
                                                placeholder="{{ 'SIMULATION.SERVICE.PREGNANT_PERCENTAGE' | translate }}"
                                                matTooltip="{{ 'SIMULATION.SERVICE.PREGNANT_PERCENTAGE' | translate }}" required/>
                                            <mat-error *ngIf="scPercentagePregnantControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="{{ 'SIMULATION.SERVICE.PREVENTION' | translate }}">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <div fxFlex="50">
                    <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                        <h4>
                            {{ 'SIMULATION.CURRENT_PRACTICE' | translate }}
                        </h4>
                        <mat-card class="blue-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.PREVENTION_PERCENTAGE' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_PREVENTION' | translate }}">info
                                    </mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="40">
                                        <input currencyMask [(ngModel)]="data.percentagePrep"
                                            [formControl]="percentagePrepControl" maxlength="4"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                            matNativeControl
                                            placeholder="{{ 'SIMULATION.SERVICE.PREP_PERCENTAGE' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.PREP_PERCENTAGE' | translate }}" required/>
                                        <mat-error *ngIf="percentagePrepControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="40">
                                        <input currencyMask [(ngModel)]="data.percentagePep"
                                            [formControl]="percentagePepControl" maxlength="4"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                            matNativeControl
                                            placeholder="{{ 'SIMULATION.SERVICE.PEP_PERCENTAGE' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.PEP_PERCENTAGE' | translate }}" required/>
                                        <mat-error *ngIf="percentagePepControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <mat-error *ngIf="!isSimPreventionTotalTrue()">{{ 'SIMULATION.SERVICE.TOTAL_ERROR' | translate }}</mat-error>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div fxFlex="50">
                    <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                        <h4>
                            {{ 'SIMULATION.SCENARIO' | translate }}
                        </h4>
                        <mat-card class="pink-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.PREVENTION_PERCENTAGE' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_PREVENTION' | translate }}">info
                                    </mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="40">
                                        <input currencyMask [(ngModel)]="data.scPercentagePrep"
                                            [formControl]="scPercentagePrepControl" maxlength="4"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                            matNativeControl
                                            placeholder="{{ 'SIMULATION.SERVICE.PREP_PERCENTAGE' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.PREP_PERCENTAGE' | translate }}" required/>
                                        <mat-error *ngIf="scPercentagePrepControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}</mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="40">
                                        <input currencyMask [(ngModel)]="data.scPercentagePep"
                                            [formControl]="scPercentagePepControl" maxlength="4"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                            matNativeControl
                                            placeholder="{{ 'SIMULATION.SERVICE.PEP_PERCENTAGE' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.PEP_PERCENTAGE' | translate }}" required/>
                                        <mat-error *ngIf="scPercentagePepControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR' | translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <mat-error *ngIf="!isScenPreventionTotalTrue()">{{ 'SIMULATION.SERVICE.TOTAL_ERROR' | translate }}</mat-error>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="{{ 'SIMULATION.SERVICE.ART' | translate }}">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <div fxFlex="50">
                    <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                        <h4>
                            {{ 'SIMULATION.CURRENT_PRACTICE' | translate }}
                        </h4>
                        <mat-card class="blue-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.THERAPY_LINE' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_ART' | translate }}">info
                                    </mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="40">
                                        <input currencyMask [(ngModel)]="data.percentageNrti2Nrti"
                                            maxlength="6"
                                            [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                            matNativeControl
                                            placeholder="{{ 'SIMULATION.SERVICE.NNRTI_2_NRTI' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.NNRTI_2_NRTI' | translate }}" required/>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="40">
                                        <input currencyMask [(ngModel)]="data.percentagePi2Nrti"
                                            maxlength="6"
                                            [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                            matNativeControl
                                            placeholder="{{ 'SIMULATION.SERVICE.PI_2_NRTI' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.PI_2_NRTI' | translate }}" required/>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="40">
                                        <input currencyMask [(ngModel)]="data.percentageIn2Nrti"
                                            maxlength="6"
                                            [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                            matNativeControl
                                            placeholder="{{ 'SIMULATION.SERVICE.IN_2_NRTI' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.IN_2_NRTI' | translate }}" required/>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="40">
                                        <input currencyMask [(ngModel)]="data.percentageEi2Nrti"
                                            maxlength="6"
                                            [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                            matNativeControl
                                            placeholder="{{ 'SIMULATION.SERVICE.EI_2_NRTI' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.EI_2_NRTI' | translate }}" required/>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="40">
                                        <input currencyMask [(ngModel)]="data.percentage2dr"
                                            maxlength="6"
                                            [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                            matNativeControl
                                            placeholder="{{ 'SIMULATION.SERVICE.2DR' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.2DR' | translate }}" required/>
                                    </mat-form-field>
                                </div>
                                <mat-error *ngIf="!isSimArtTotalTrue()">{{ 'SIMULATION.SERVICE.TOTAL_ERROR' | translate }}</mat-error>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>

                <div fxFlex="50">
                    <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                        <h4>
                            {{ 'SIMULATION.SCENARIO' | translate }}
                        </h4>
                        <mat-card class="pink-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.THERAPY_LINE' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_ART' | translate }}">info
                                    </mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="40">
                                        <input currencyMask [(ngModel)]="data.scPercentageNrti2Nrti"
                                            maxlength="6"
                                            [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                            matNativeControl
                                            placeholder="{{ 'SIMULATION.SERVICE.NNRTI_2_NRTI' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.NNRTI_2_NRTI' | translate }}" required/>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="40">
                                        <input currencyMask [(ngModel)]="data.scPercentagePi2Nrti"
                                            maxlength="6"
                                            [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                            matNativeControl
                                            placeholder="{{ 'SIMULATION.SERVICE.PI_2_NRTI' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.PI_2_NRTI' | translate }}" required/>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="40">
                                        <input currencyMask [(ngModel)]="data.scPercentageIn2Nrti"
                                            maxlength="6"
                                            [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                            matNativeControl
                                            placeholder="{{ 'SIMULATION.SERVICE.IN_2_NRTI' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.IN_2_NRTI' | translate }}" required/>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="40">
                                        <input currencyMask [(ngModel)]="data.scPercentageEi2Nrti"
                                            maxlength="6"
                                            [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                            matNativeControl
                                            placeholder="{{ 'SIMULATION.SERVICE.EI_2_NRTI' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.EI_2_NRTI' | translate }}" required/>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="40">
                                        <input currencyMask [(ngModel)]="data.scPercentage2dr"
                                            maxlength="6"
                                            [options]="{ thousands: ',', precision: '1', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                            matNativeControl
                                            placeholder="{{ 'SIMULATION.SERVICE.2DR' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.2DR' | translate }}" required/>
                                    </mat-form-field>
                                </div>
                                <mat-error *ngIf="!isScenArtTotalTrue()">{{ 'SIMULATION.SERVICE.TOTAL_ERROR' | translate }}</mat-error>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="{{ 'SIMULATION.SERVICE.TREATMENT_TIMES' | translate }}">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <div fxFlex="50">
                    <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                        <h4>
                            {{ 'SIMULATION.CURRENT_PRACTICE' | translate }}
                        </h4>
                        <mat-card class="blue-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.DIAGNOSE' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_DIAGNOSE' | translate }}">info
                                    </mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="diagnoseDurationNaive"
                                            [formControl]="diagnoseDurationNaiveControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.NAIVE_TREATMENT' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.NAIVE_TREATMENT' | translate }}" required/>
                                        <mat-error *ngIf="diagnoseDurationNaiveControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="diagnoseDurationExperienced"
                                            [formControl]="diagnoseDurationExperiencedControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.EXPERIENCED_TREATMENT' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.EXPERIENCED_TREATMENT' | translate }}" required/>
                                        <mat-error *ngIf="diagnoseDurationExperiencedControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="diagnoseDurationPrevention"
                                            [formControl]="diagnoseDurationPreventionControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.PREVENTION_TREATMENT' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.PREVENTION_TREATMENT' | translate }}" required/>
                                        <mat-error *ngIf="diagnoseDurationPreventionControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <mat-card class="blue-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.COUNSELLING' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_COUNSELLING' | translate }}">info
                                    </mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="counsellingDurationNaive"
                                            [formControl]="counsellingDurationNaiveControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.COUNSELLING_INITIAL' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.COUNSELLING_INITIAL' | translate }}" required/>
                                        <mat-error *ngIf="counsellingDurationNaiveControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}</mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="counsellingDurationExperienced"
                                            [formControl]="counsellingDurationExperiencedControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.COUNSELLING_GROUP' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.COUNSELLING_GROUP' | translate }}" required/>
                                        <mat-error *ngIf="counsellingDurationExperiencedControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="counsellingDurationPrevention"
                                            [formControl]="counsellingDurationPreventionControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.COUNSELLING_IAC' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.COUNSELLING_IAC' | translate }}" required/>
                                        <mat-error *ngIf="counsellingDurationPreventionControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <mat-card class="blue-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.MONITORING' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_MONITORING' | translate }}">info
                                    </mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="monitoringDurationNaive"
                                            [formControl]="monitoringDurationNaiveControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.NAIVE_TREATMENT' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.NAIVE_TREATMENT' | translate }}" required/>
                                        <mat-error *ngIf="monitoringDurationNaiveControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}</mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="monitoringDurationExperienced"
                                            [formControl]="monitoringDurationExperiencedControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.EXPERIENCED_TREATMENT' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.EXPERIENCED_TREATMENT' | translate }}" required/>
                                        <mat-error *ngIf="monitoringDurationExperiencedControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="monitoringDurationPrevention"
                                            [formControl]="monitoringDurationPreventionControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.PREVENTION_TREATMENT' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.PREVENTION_TREATMENT' | translate }}" required/>
                                        <mat-error *ngIf="monitoringDurationPreventionControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>

                <div fxFlex="50">
                    <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                        <h4>
                            {{ 'SIMULATION.SCENARIO' | translate }}
                        </h4>
                        <mat-card class="pink-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.DIAGNOSE' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_DIAGNOSE' | translate }}">info
                                    </mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="scDiagnoseDurationNaive"
                                            [formControl]="scDiagnoseDurationNaiveControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.NAIVE_TREATMENT' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.NAIVE_TREATMENT' | translate }}" required/>
                                        <mat-error *ngIf="scDiagnoseDurationNaiveControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}</mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="scDiagnoseDurationExperienced"
                                            [formControl]="scDiagnoseDurationExperiencedControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.EXPERIENCED_TREATMENT' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.EXPERIENCED_TREATMENT' | translate }}" required/>
                                        <mat-error *ngIf="scDiagnoseDurationExperiencedControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="scDiagnoseDurationPrevention"
                                            [formControl]="scDiagnoseDurationPreventionControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.PREVENTION_TREATMENT' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.PREVENTION_TREATMENT' | translate }}" required/>
                                        <mat-error *ngIf="scDiagnoseDurationPreventionControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <mat-card class="pink-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.COUNSELLING' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_COUNSELLING' | translate }}">info
                                    </mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="scCounsellingDurationNaive"
                                            [formControl]="scCounsellingDurationNaiveControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.COUNSELLING_INITIAL' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.COUNSELLING_INITIAL' | translate }}" required/>
                                        <mat-error *ngIf="scCounsellingDurationNaiveControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}</mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="scCounsellingDurationExperienced"
                                            [formControl]="scCounsellingDurationExperiencedControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.COUNSELLING_GROUP' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.COUNSELLING_GROUP' | translate }}" required/>
                                        <mat-error *ngIf="scCounsellingDurationExperiencedControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="scCounsellingDurationPrevention"
                                            [formControl]="scCounsellingDurationPreventionControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.COUNSELLING_IAC' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.COUNSELLING_IAC' | translate }}" required/>
                                        <mat-error *ngIf="scCounsellingDurationPreventionControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                            </mat-card-content>
                        </mat-card>

                        <mat-card class="pink-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.MONITORING' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_MONITORING' | translate }}">info
                                    </mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="scMonitoringDurationNaive"
                                            [formControl]="scMonitoringDurationNaiveControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.NAIVE_TREATMENT' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.NAIVE_TREATMENT' | translate }}" required/>
                                        <mat-error *ngIf="scMonitoringDurationNaiveControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}</mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="scMonitoringDurationExperienced"
                                            [formControl]="scMonitoringDurationExperiencedControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.EXPERIENCED_TREATMENT' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.EXPERIENCED_TREATMENT' | translate }}" required/>
                                        <mat-error *ngIf="scMonitoringDurationExperiencedControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="45">
                                        <input currencyMask [(ngModel)]="scMonitoringDurationPrevention"
                                            [formControl]="scMonitoringDurationPreventionControl"
                                            maxlength="8"
                                            [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: ' min' }"
                                            matNativeControl
                                            (ngModelChange)="onDurationChange($event)"
                                            placeholder="{{ 'SIMULATION.SERVICE.PREVENTION_TREATMENT' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.PREVENTION_TREATMENT' | translate }}" required/>
                                        <mat-error *ngIf="scMonitoringDurationPreventionControl.invalid">{{ 'SIMULATION.SERVICE.MIN_MAX_ERROR_DURATION' | translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="{{ 'SIMULATION.SERVICE.MONITORING_VISITS' | translate }}">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                <div fxFlex="50">
                    <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                        <h4>
                            {{ 'SIMULATION.CURRENT_PRACTICE' | translate }}
                        </h4>
                        <mat-card class="blue-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.NO_MONITORING_VISITS' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_MONITORING' | translate }}">info
                                    </mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="40">
                                        <mat-select [(ngModel)]="selectedNaiveChoice"
                                            (selectionChange)="onSelectNaive($event.value)"
                                            placeholder="{{ 'SIMULATION.SERVICE.NAIVE' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.NAIVE' | translate }}">
                                            <mat-option *ngFor="let opt of naiveVisitTimes" 
                                                [value]="opt.numberOfVisit">{{opt.numberOfVisit}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="40">
                                        <mat-select [(ngModel)]="selectedExperiencedChoice"
                                            (selectionChange)="onSelectExperienced($event.value)"
                                            placeholder="{{ 'SIMULATION.SERVICE.EXPERIENCED' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.EXPERIENCED' | translate }}">
                                            <mat-option *ngFor="let opt of experiencedVisitTimes" 
                                                [value]="opt.numberOfVisit">{{opt.numberOfVisit}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="40">
                                        <mat-select [(ngModel)]="selectedPregnantChoice"
                                            (selectionChange)="onSelectPregnant($event.value)"
                                            placeholder="{{ 'SIMULATION.SERVICE.PREGNANT' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.PREGNANT' | translate }}">
                                            <mat-option *ngFor="let opt of pregnantVisitTimes" 
                                                [value]="opt.numberOfVisit">{{opt.numberOfVisit}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="40">
                                        <mat-select [(ngModel)]="selectedIacChoice"
                                            (selectionChange)="onSelectIac($event.value)"
                                            placeholder="{{ 'SIMULATION.SERVICE.IAC' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.IAC' | translate }}">
                                            <mat-option *ngFor="let opt of iacVisitTimes" 
                                                [value]="opt.numberOfVisit">{{opt.numberOfVisit}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="40">
                                        <mat-select [(ngModel)]="selectedTlSwitchedChoice"
                                            (selectionChange)="onSelectTlSwitch($event.value)"
                                            placeholder="{{ 'SIMULATION.SERVICE.TL_SWITCHED' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.TL_SWITCHED' | translate }}">
                                            <mat-option *ngFor="let opt of tlSwitchedVisitTimes" 
                                                [value]="opt.numberOfVisit">{{opt.numberOfVisit}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="40">
                                        <mat-select [(ngModel)]="selectedPrepChoice"
                                            (selectionChange)="onSelectPrep($event.value)"
                                            placeholder="{{ 'SIMULATION.SERVICE.PREP' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.PREP' | translate }}">
                                            <mat-option *ngFor="let opt of prepVisitTimes" 
                                                [value]="opt.numberOfVisit">{{opt.numberOfVisit}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="40">
                                        <mat-select [(ngModel)]="selectedPepChoice"
                                            (selectionChange)="onSelectPep($event.value)"
                                            placeholder="{{ 'SIMULATION.SERVICE.PEP' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.PEP' | translate }}">
                                            <mat-option *ngFor="let opt of pepVisitTimes" 
                                                [value]="opt.numberOfVisit">{{opt.numberOfVisit}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>

                <div fxFlex="50">
                    <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                        <h4>
                            {{ 'SIMULATION.SCENARIO' | translate }}
                        </h4>
                        <mat-card class="pink-background">
                            <mat-card-header>
                                <mat-card-title class="text-white">
                                    {{ 'SIMULATION.SERVICE.NO_MONITORING_VISITS' | translate }}
                                </mat-card-title>
                                <div fxFlex></div>
                                <div fxLayoutAlign="center center">
                                    <mat-icon matTooltip="{{ 'SIMULATION.SERVICE.INFO_TOOLTIP_MONITORING' | translate }}">info
                                    </mat-icon>
                                </div>
                            </mat-card-header>
                            <mat-card-content>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="40">
                                        <mat-select [(ngModel)]="selectedNaiveChoiceSc"
                                            (selectionChange)="onSelectNaiveSc($event.value)"
                                            placeholder="{{ 'SIMULATION.SERVICE.NAIVE' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.NAIVE' | translate }}">
                                            <mat-option *ngFor="let opt of naiveVisitTimes" 
                                                [value]="opt.numberOfVisit">{{opt.numberOfVisit}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="40">
                                        <mat-select [(ngModel)]="selectedExperiencedChoiceSc"
                                            (selectionChange)="onSelectExperiencedSc($event.value)"
                                            placeholder="{{ 'SIMULATION.SERVICE.EXPERIENCED' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.EXPERIENCED' | translate }}">
                                            <mat-option *ngFor="let opt of experiencedVisitTimes" 
                                                [value]="opt.numberOfVisit">{{opt.numberOfVisit}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="40">
                                        <mat-select [(ngModel)]="selectedPregnantChoiceSc"
                                            (selectionChange)="onSelectPregnantSc($event.value)"
                                            placeholder="{{ 'SIMULATION.SERVICE.PREGNANT' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.PREGNANT' | translate }}">
                                            <mat-option *ngFor="let opt of pregnantVisitTimes" 
                                                [value]="opt.numberOfVisit">{{opt.numberOfVisit}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="40">
                                        <mat-select [(ngModel)]="selectedIacChoiceSc"
                                            (selectionChange)="onSelectIacSc($event.value)"
                                            placeholder="{{ 'SIMULATION.SERVICE.IAC' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.IAC' | translate }}">
                                            <mat-option *ngFor="let opt of iacVisitTimes" 
                                                [value]="opt.numberOfVisit">{{opt.numberOfVisit}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="40">
                                        <mat-select [(ngModel)]="selectedTlSwitchedChoiceSc"
                                            (selectionChange)="onSelectTlSwitchSc($event.value)"
                                            placeholder="{{ 'SIMULATION.SERVICE.TL_SWITCHED' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.TL_SWITCHED' | translate }}">
                                            <mat-option *ngFor="let opt of tlSwitchedVisitTimes" 
                                                [value]="opt.numberOfVisit">{{opt.numberOfVisit}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="40">
                                        <mat-select [(ngModel)]="selectedPrepChoiceSc"
                                            (selectionChange)="onSelectPrepSc($event.value)"
                                            placeholder="{{ 'SIMULATION.SERVICE.PREP' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.PREP' | translate }}">
                                            <mat-option *ngFor="let opt of prepVisitTimes" 
                                                [value]="opt.numberOfVisit">{{opt.numberOfVisit}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                                    <mat-form-field fxFlex="40">
                                        <mat-select [(ngModel)]="selectedPepChoiceSc"
                                            (selectionChange)="onSelectPepSc($event.value)"
                                            placeholder="{{ 'SIMULATION.SERVICE.PEP' | translate }}"
                                            matTooltip="{{ 'SIMULATION.SERVICE.PEP' | translate }}">
                                            <mat-option *ngFor="let opt of pepVisitTimes" 
                                                [value]="opt.numberOfVisit">{{opt.numberOfVisit}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
    <span>{{ 'SIMULATION.REQUIRED' | translate }}</span>
</div>

<div mat-dialog-actions>
    <button mat-button color="primary" (click)="onNoClick()">{{ 'SIMULATION.CANCEL' | translate }}</button>
    <button mat-button [mat-dialog-close]="data" [disabled]="!isInfoValid()"
        cdkFocusInitial color="accent">{{ 'SIMULATION.OK' | translate }}</button>
