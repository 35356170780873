import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { Dhis2Service } from 'src/app/shared/services/dhis2.service';
import {TableColumn} from '@swimlane/ngx-datatable';
import {TranslateService} from '@ngx-translate/core';
import {DatatablePage} from '../../../../shared/model/datatable-page';
import {PageableSort} from '../../../../shared/model/pagination';

@Component({
  selector: 'app-openmrs-main',
  templateUrl: './openmrs-main.component.html',
  styleUrls: ['./openmrs-main.component.scss']
})
export class OpenmrsMainComponent implements OnInit {

  @ViewChild('idTemplate', { static: true }) idTemplate: TemplateRef<any>;


  rows = [];
  columns: TableColumn[];
  allColumnList: TableColumn[];
  page: DatatablePage = new DatatablePage();
  sort: PageableSort;
  constructor(
    private translate: TranslateService,
    private dhis2Service: Dhis2Service
  ){
  }

  ngOnInit(): void {

    this.allColumnList = [
      {
        prop: 'id',
        name: this.translate.instant('ART_CODE_LIST.COL_LBL.ID'),
        cellTemplate: this.idTemplate,
        width: 100,
        maxWidth: 100
      },
      { prop: 'code', name: this.translate.instant('DHIS2.COL_LBL.CODE'), cellClass: '' },
      { prop: 'name', name: this.translate.instant('DHIS2.COL_LBL.NAME'), cellClass: '' },
      { prop: 'shortName', name: this.translate.instant('DHIS2.COL_LBL.SHORT_NAME'), cellClass: '' },
      { prop: 'level', name: this.translate.instant('DHIS2.COL_LBL.LEVEL'), cellClass: '' },
      { prop: 'path', name: this.translate.instant('DHIS2.COL_LBL.PATH'), cellClass: '' },
      { prop: 'contactPerson', name: this.translate.instant('DHIS2.COL_LBL.CONTACT_PERSON'), cellClass: '' },
      { prop: 'address', name: this.translate.instant('DHIS2.COL_LBL.ADDRESS'), cellClass: '' },
      { prop: 'email', name: this.translate.instant('DHIS2.COL_LBL.EMAIL'), cellClass: '' },
      { prop: 'phoneNumber', name: this.translate.instant('DHIS2.COL_LBL.PHONE_NUMBER'), cellClass: '' },
    ];

    this.columns = [
      this.allColumnList[0],
      this.allColumnList[1],
      this.allColumnList[2],
      this.allColumnList[3],
      this.allColumnList[4],
      this.allColumnList[5]
    ];
    this.loadData();
  }
  loadData() {
    this.dhis2Service.getOrgUnits().subscribe(data => {
      this.page.pageNumber = 0;
      this.page.totalElements = 1332;
      this.page.size = 1500;
      this.rows = data;
    });
  }
}
