import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { ApexChartOptions, CHART_COLORS_SCHEME1 } from 'src/app/shared/model/chart-options';
import { Suppression } from 'src/app/shared/model/suppression';

@Component({
  selector: 'app-mutation-score',
  templateUrl: './mutation-score.component.html',
  styleUrls: ['./mutation-score.component.scss']
})
export class MutationScoreComponent implements OnInit {
  @Input() suppressionList: Suppression[];
  @ViewChild('chartView') chart: ChartComponent = Object.create(null);

  public chartOptions: Partial<ApexChartOptions> = {
    series: [],
    chart: {
      type: 'bar',
      height: 370,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      }
    },
    colors: CHART_COLORS_SCHEME1,
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        columnWidth: '80%',
        distributed: true,
        dataLabels: {
          position: 'top' // top, center, bottom
        }
      },
    },
    dataLabels: {
      enabled: false,
      offsetY: -20,
      style: {
        fontSize: '10px',
        colors: ['#67757c']
      }
    },
    xaxis: {
      labels: {
        style: {
          fontSize: '12px',
          colors: ['#67757c']
        }
      }
    },
    yaxis: {
      title: {
        text: 'MutationScore'
      },
//      min: 0,
//      max: 100
    },
    legend: {
      show: false,
    },
    tooltip: {
      theme: 'light',
      fillSeriesColor: false,
      marker: {
        show: true,
      },
    }
  };
  constructor() { }

  ngOnInit(): void {
    const chartLabels = [];
    const dataMutationScore = [];
    const colorMutationScore = [];

    for (const sup of this.suppressionList) {
      chartLabels.push(sup.artCodeNames);
      dataMutationScore.push(sup.mutationScore ? sup.mutationScore : 0);
      const color = this.getColor(sup.mutationCategory);
      colorMutationScore.push(color);
    }

    this.chartOptions.colors = colorMutationScore;
    this.chartOptions.xaxis.categories = chartLabels;
    this.chartOptions.series.push({ data: dataMutationScore });
  }

  getColor(mutationCategory: string) {
    switch (mutationCategory) {
      case 'Susceptible':
        return '#309143';
        break;
      case 'Potential low-level resistance':
        return '#8ace7e';
      case 'Low-level resistance':
        return '#f0bd27';
      case 'Intermediate resistance':
        return '#ff684c';
      case 'High-level resistance':
        return '#b60a1c';
      default:
      return '#309143';
    }
  }
}
