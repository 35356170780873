<div [formGroup]="formGroupPassword">
  <div class="pb-1 cust-form-field-container">
    <mat-form-field class="cust-form-field"  [appearance]="fieldAppearance">
      <mat-label>{{ passwordLabel | translate }}</mat-label>
      <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password">
      <button type="button" mat-icon-button matSuffix (click)="showPassword = !showPassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="!showPassword">
        <mat-icon>{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>

    </mat-form-field>
    <app-validation-message [control]="formGroupPassword.get('password')"></app-validation-message>
  </div>


  <div class="pb-1 cust-form-field-container" *ngIf="enableVerifyPassword">
    <mat-form-field class="cust-form-field" [appearance]="fieldAppearance">
      <mat-label>{{ verifyPasswordLabel | translate }}</mat-label>
      <input matInput [errorStateMatcher]="passwordMatcher" [type]="showPasswordVerify ? 'text' : 'password'" formControlName="passwordVerify">

      <button type="button" mat-icon-button matSuffix (click)="showPasswordVerify = !showPasswordVerify" [attr.aria-label]="'Hide verify password'" [attr.aria-pressed]="!showPasswordVerify">
        <mat-icon>{{showPasswordVerify ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>

    </mat-form-field>
    <mat-error class="cust-error-text" *ngIf="formGroupPassword.hasError('notSame')">{{ 'GENERAL.PASSWORD_NOT_SAME' | translate }} </mat-error>
  </div>

</div>
