import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../../shared/module/notification/service/notification.service';
import { SurveyResultsService } from '../../../../shared/services/survey-results.service';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';

@Component({
  selector: 'app-survey-result-list',
  templateUrl: './survey-result-list.component.html',
  styleUrls: ['./survey-result-list.component.scss']
})
export class SurveyResultListComponent extends BaseListComponent implements OnInit {

  @ViewChild('idTemplate', {static: true}) idTemplate: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private surveyResultsService: SurveyResultsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.allColumnList = [
      {
        prop: 'id',
        name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.ID'),
        cellTemplate: this.idTemplate,
        width: 100,
        maxWidth: 100
      },
      {
        prop: 'userId',
        name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.USER_ID'),
        cellTemplate: this.idTemplate,
        width: 100,
        maxWidth: 100
      },
      {prop: 'ques0001', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0001'), cellClass: ''},
      {prop: 'ques0002', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0002'), cellClass: ''},
      {prop: 'ques0003', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0003'), cellClass: ''},
      {prop: 'ques0004', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0004'), cellClass: ''},
      {prop: 'ques0005', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0005'), cellClass: ''},
      {prop: 'ques0006', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0006'), cellClass: ''},
      {prop: 'ques0007', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0007'), cellClass: ''},
      {prop: 'ques0008', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0008'), cellClass: ''},
      {prop: 'ques0009', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0009'), cellClass: ''},
      {prop: 'ques0010', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0010'), cellClass: ''},
      {prop: 'ques0011', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0011'), cellClass: ''},
      {prop: 'ques0012', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0012'), cellClass: ''},
      {prop: 'ques0013', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0013'), cellClass: ''},
      {prop: 'ques0014', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0014'), cellClass: ''},
      {prop: 'ques0015', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0015'), cellClass: ''},
      {prop: 'ques0016', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0016'), cellClass: ''},
      {prop: 'ques0017', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0017'), cellClass: ''},
      {prop: 'ques0018', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0018'), cellClass: ''},
      {prop: 'ques0019', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0019'), cellClass: ''},
      {prop: 'ques0020', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0020'), cellClass: ''},
      {prop: 'ques0021', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0021'), cellClass: ''},
      {prop: 'ques0022', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0022'), cellClass: ''},
      {prop: 'ques0023', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0023'), cellClass: ''},
      {prop: 'ques0024', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0024'), cellClass: ''},
      {prop: 'ques0025', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0025'), cellClass: ''},
      {prop: 'ques0026', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0026'), cellClass: ''},
      {prop: 'ques0027', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0027'), cellClass: ''},
      {prop: 'ques0028', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0028'), cellClass: ''},
      {prop: 'ques0029', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0029'), cellClass: ''},
      {prop: 'ques0030', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0030'), cellClass: ''},
      {prop: 'ques0031', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0031'), cellClass: ''},
      {prop: 'ques0032', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0032'), cellClass: ''},
      {prop: 'ques0033', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0033'), cellClass: ''},
      {prop: 'ques0034', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0034'), cellClass: ''},
      {prop: 'ques0035', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0035'), cellClass: ''},
      {prop: 'ques0036', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0036'), cellClass: ''},
      {prop: 'ques0037', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0037'), cellClass: ''},
      {prop: 'ques0038', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0038'), cellClass: ''},
      {prop: 'ques0039', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0039'), cellClass: ''},
      {prop: 'ques0040', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0040'), cellClass: ''},
      {prop: 'ques0041', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0041'), cellClass: ''},
      {prop: 'ques0042', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0042'), cellClass: ''},
      {prop: 'ques0043', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0043'), cellClass: ''},
      {prop: 'ques0044', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0044'), cellClass: ''},
      {prop: 'ques0045', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0045'), cellClass: ''},
      {prop: 'ques0046', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0046'), cellClass: ''},
      {prop: 'ques0047', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0047'), cellClass: ''},
      {prop: 'ques0048', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0048'), cellClass: ''},
      {prop: 'ques0049', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0049'), cellClass: ''},
      {prop: 'ques0050', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0050'), cellClass: ''},
      {prop: 'ques0051', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0051'), cellClass: ''},
      {prop: 'ques0052', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0052'), cellClass: ''},
      {prop: 'ques0053', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0053'), cellClass: ''},
      {prop: 'ques0054', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0054'), cellClass: ''},
      {prop: 'ques0055', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0055'), cellClass: ''},
      {prop: 'ques0056', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0056'), cellClass: ''},
      {prop: 'ques0057', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0057'), cellClass: ''},
      {prop: 'ques0058', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0058'), cellClass: ''},
      {prop: 'ques0059', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0059'), cellClass: ''},
      {prop: 'ques0060', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0060'), cellClass: ''},
      {prop: 'ques0061', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0061'), cellClass: ''},
      {prop: 'ques0062', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0062'), cellClass: ''},
      {prop: 'ques0063', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0063'), cellClass: ''},
      {prop: 'ques0064', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0064'), cellClass: ''},
      {prop: 'ques0065', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0065'), cellClass: ''},
      {prop: 'ques0066', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0066'), cellClass: ''},
      {prop: 'ques0067', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0067'), cellClass: ''},
      {prop: 'ques0068', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0068'), cellClass: ''},
      {prop: 'ques0069', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0069'), cellClass: ''},
      {prop: 'ques0070', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0070'), cellClass: ''},
      {prop: 'ques0071', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0071'), cellClass: ''},
      {prop: 'ques0072', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0072'), cellClass: ''},
      {prop: 'ques0073', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0073'), cellClass: ''},
      {prop: 'ques0074', name: this.translate.instant('SURVEY_RESULTS_LIST.COL_LBL.QUES_0074'), cellClass: ''},
    ];

    this.columns = [
      this.allColumnList[0],
      this.allColumnList[1],
      this.allColumnList[2],
      this.allColumnList[3],
      this.allColumnList[4],
      this.allColumnList[5],
      this.allColumnList[6],
      this.allColumnList[7]
    ];
    this.loadData();
  }


  loadData() {
    this.surveyResultsService.getAllSurveyResults(this.page.pageNumber, this.page.size, this.sort).subscribe(page => {
      this.page.pageNumber = page.number;
      this.page.totalElements = page.totalElements;
      this.page.size = page.size;
      this.rows = page.content;
      console.log(this.rows);
    });
  }

}
