/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseListComponent } from 'src/app/shared/component/base-list.component';
import { DEFAULT_USER_PHOTO } from 'src/app/shared/constants';
import { UserSummary } from 'src/app/shared/model/user-account';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';
import { rolesToStringArray } from 'src/app/shared/util';
import { ClinicianPatientService } from '../../services/clinician-patient.service';

@Component({
  selector: 'app-clinician-patient-selection-dialog',
  templateUrl: './clinician-patient-selection-dialog.component.html',
  styleUrls: ['./clinician-patient-selection-dialog.component.scss']
})
export class ClinicianPatientSelectionDialogComponent extends BaseListComponent implements OnInit {

  @ViewChild('rowNumberTemplate', { static: true }) rowNumberTemplate: TemplateRef<any>;
  @ViewChild('clinicianInfoTemplate', { static: true }) clinicianInfoTemplate: TemplateRef<any>;
  @ViewChild('patientInfoTemplate', { static: true }) patientInfoTemplate: TemplateRef<any>;
  @ViewChild('clinicianEmailOrPhoneTemplate', { static: true }) clinicianEmailOrPhoneTemplate: TemplateRef<any>;
  @ViewChild('patientEmailOrPhoneTemplate', { static: true }) patientEmailOrPhoneTemplate: TemplateRef<any>;

  constructor(
    public dialogRef: MatDialogRef<ClinicianPatientSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private clinicianPatientService: ClinicianPatientService
  ) {
    super();
   }

  ngOnInit(): void {

    const availableColumns = [
      { prop: 'id', name: '#', maxWidth: 50, cellClass: '', cellTemplate: this.rowNumberTemplate, sortable: false, draggable: false },
      { prop: 'clinician.firstName', name: this.translate.instant('CLINICIAN_PATIENT_ADMIN.LIST.COL_LBL.CLINICIAN_NAME'), cellTemplate: this.clinicianInfoTemplate, sortable: false, draggable: false},
      { prop: 'clinician.emailOrPhone', name: this.translate.instant('CLINICIAN_PATIENT_ADMIN.LIST.COL_LBL.CLINICIAN_EMAIL_OR_PHONE'), cellTemplate: this.clinicianEmailOrPhoneTemplate, sortable: false, draggable: false},
      { prop: 'patient.firstName', name: this.translate.instant('CLINICIAN_PATIENT_ADMIN.LIST.COL_LBL.PATIENT_NAME'), cellTemplate: this.patientInfoTemplate, sortable: false, draggable: false},
      { prop: 'patient.emailOrPhone', name: this.translate.instant('CLINICIAN_PATIENT_ADMIN.LIST.COL_LBL.PATIENT_EMAIL_OR_PHONE'), cellTemplate: this.patientEmailOrPhoneTemplate, sortable: false, draggable: false},
      { prop: 'startTime', name: this.translate.instant('CLINICIAN_PATIENT_ADMIN.LIST.COL_LBL.START_TIME'), cellClass: '', sortable: true, draggable: false},
      { prop: 'endTime', name: this.translate.instant('CLINICIAN_PATIENT_ADMIN.LIST.COL_LBL.END_TIME'), cellClass: '', sortable: true, draggable: false}
      // { prop: 'action', name: '', maxWidth: 50, cellTemplate: this.actionTemplate, sortable: false, draggable: false}
    ];
    
    let availableColumnNames = ['id', 'clinician.firstName', 'clinician.emailOrPhone', 'patient.firstName', 'patient.emailOrPhone', 'startTime', 'endTime'];
    
    if (this.data.searchPatients === true) {
      availableColumnNames = ['id', 'patient.firstName', 'patient.emailOrPhone'];
    }

    if (this.data.searchClinicians === true) {
      availableColumnNames = ['id', 'clinician.firstName', 'clinician.emailOrPhone'];
    }

    this.allColumnList = availableColumnNames.map(colName => this.findColumn(availableColumns, colName));
    this.columns = [...this.allColumnList];

    this.rowSelectionType = this.data.rowSelectionType ?  this.data.rowSelectionType : 'single';
    this.selectedRows = this.data.defaultRowSelections ?  this.data.defaultRowSelections : [];
    this.selectAllRowsVisible = this.data.selectAllRowsVisible ? this.data.selectAllRowsVisible : false;

    this.loadData();
  }

  loadData() {

    if (this.data.searchPatients === true) {
      this.clinicianPatientService.getPatientsOfClinician(this.page.pageNumber, this.page.size, this.sort, this.searchTerm).subscribe(page => {
        this.page.pageNumber = page.number;
        this.page.totalElements = page.totalElements;
        this.page.size = page.size;
        this.rows = page.content;
      });
    }

    if (this.data.searchClinicians === true) {
      this.clinicianPatientService.getCliniciansOfPatient(this.page.pageNumber, this.page.size, this.sort, this.searchTerm).subscribe(page => {
        this.page.pageNumber = page.number;
        this.page.totalElements = page.totalElements;
        this.page.size = page.size;
        this.rows = page.content;
      });
    }
  }
  
  findColumn(availableColumns, findColumnName) {
    return availableColumns.find(col => col.prop === findColumnName);
  }

  getProfilePhotoUrl(userSummary: UserSummary): string {
    return userSummary && userSummary.photoUrl ? userSummary.photoUrl : DEFAULT_USER_PHOTO;
  }

  getNameStr(user: UserSummary): string {
    return user && user.firstName ? (user.firstName + ' ' + user.lastName) : 'No Name';
  }

  getRolesAsString(roleValue): string {
    if(roleValue) {
      const roles = rolesToStringArray(roleValue);
      return roles.map(r => this.translate.instant('ENUM.ROLE.' + r)).join(', ');
    }
    return 'No Role';
  }

  getUserUniqueId(userSummary: UserSummary): string {
    return userSummary.userUniqueId ? userSummary.userUniqueId : 'No Folio Number';
  }

  getEmailOrPhone(userSummary: UserSummary): string {
    const email = userSummary.email ? userSummary.email : 'No Email';
    const phone = userSummary.phoneNumber ? userSummary.phoneNumber : 'No Phone';
    return email + '/' + phone;
  }

  onSelect() {
    this.dialogRef.close({selectedRows: this.selectedRows});
  }

}
