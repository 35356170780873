/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Page, Pageable, PageableSort } from '../model/pagination';
import { map } from 'rxjs/operators';
import { createPageParams } from '../util';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    private baseUrl = '/subscription-svc/v1/subscriptions';

    constructor(private http: HttpClient) {
    }

    subscribeUser(planId: number): Observable<Subscription> {
        return this.http.post<Subscription>(environment.url + `${this.baseUrl}/plan/${planId}`, null)
        .pipe(map((res: any) => res || {}));
    }

    getActiveSubscriptionOfUser(): Observable<Subscription> {
        return this.http.get<Subscription>(environment.url + `${this.baseUrl}/user/active`)
            .pipe(map((res: any) => res || {}));
    }

    getSubscriptionById(subscriptionId: number): Observable<Subscription> {
        return this.http.get<Subscription>(environment.url + `${this.baseUrl}/${subscriptionId}`)
            .pipe(map((res: any) => res || {}));
    }

    cancelSubscription(): Observable<void> {
        return this.http.delete<void>(environment.url + `${this.baseUrl}/cancel-subscription`)
            .pipe(map((res: any) => res || {}));
    }

}
