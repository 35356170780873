<h1 mat-dialog-title>{{ 'SIMULATION.STAFF.HEADER' | translate }}</h1>
<div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
        <div fxFlex="50">
            <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                <h4>
                    {{ 'SIMULATION.CURRENT_PRACTICE' | translate }}
                </h4>
                <mat-card class="blue-background">
                    <mat-card-header>
                        <mat-card-title class="text-white">
                            {{ 'SIMULATION.STAFF.NAME' | translate }}
                        </mat-card-title>
                        <div fxFlex></div>
                        <div fxLayoutAlign="center center">
                            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.INFO_TOOLTIP' | translate }}">info</mat-icon>
                        </div>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.doctorNumber"
                                    [formControl]="doctorNumberControl" maxlength="4"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                                    matNativeControl placeholder="{{ 'SIMULATION.STAFF.DOCTOR' | translate }}"
                                    matTooltip="{{ 'SIMULATION.STAFF.DOCTOR' | translate }}" required/>
                                <mat-error *ngIf="doctorNumberControl.invalid">{{ 'SIMULATION.STAFF.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.generalNurseNumber"
                                    [formControl]="generalNurseNumberControl" maxlength="4"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                                    matNativeControl placeholder="{{ 'SIMULATION.STAFF.GENERAL_NURSES' | translate }}"
                                    matTooltip="{{ 'SIMULATION.STAFF.GENERAL_NURSES' | translate }}" required/>
                                <mat-error *ngIf="generalNurseNumberControl.invalid">{{ 'SIMULATION.STAFF.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.hivSpecialistNurseNumber"
                                    [formControl]="hivSpecialistNurseNumberControl" maxlength="4"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                                    matNativeControl placeholder="{{ 'SIMULATION.STAFF.HIV_NURSES' | translate }}"
                                    matTooltip="{{ 'SIMULATION.STAFF.HIV_NURSES' | translate }}" required/>
                                <mat-error *ngIf="hivSpecialistNurseNumberControl.invalid">{{ 'SIMULATION.STAFF.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.counselorNumber"
                                    [formControl]="counselorNumberControl" maxlength="4"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                                    matNativeControl placeholder="{{ 'SIMULATION.STAFF.COUNSELLOR' | translate }}"
                                    matTooltip="{{ 'SIMULATION.STAFF.COUNSELLOR' | translate }}" required/>
                                <mat-error *ngIf="counselorNumberControl.invalid">{{ 'SIMULATION.STAFF.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.dieticianNumber"
                                    [formControl]="dieticianNumberControl" maxlength="4"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                                    matNativeControl placeholder="{{ 'SIMULATION.STAFF.DIETICIAN' | translate }}"
                                    matTooltip="{{ 'SIMULATION.STAFF.DIETICIAN' | translate }}" required/>
                                <mat-error *ngIf="dieticianNumberControl.invalid">{{ 'SIMULATION.STAFF.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.medicalLabScientistNumber"
                                    [formControl]="medicalLabScientistNumberControl" maxlength="4"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                                    matNativeControl placeholder="{{ 'SIMULATION.STAFF.LAB' | translate }}"
                                    matTooltip="{{ 'SIMULATION.STAFF.LAB' | translate }}" required/>
                                <mat-error *ngIf="medicalLabScientistNumberControl.invalid">{{ 'SIMULATION.STAFF.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.pharmacistNumber"
                                    [formControl]="pharmacistNumberControl" maxlength="4"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                                    matNativeControl placeholder="{{ 'SIMULATION.STAFF.PHARMACIST' | translate }}"
                                    matTooltip="{{ 'SIMULATION.STAFF.PHARMACIST' | translate }}" required/>
                                <mat-error *ngIf="pharmacistNumberControl.invalid">{{ 'SIMULATION.STAFF.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                            <div fxFlex="40"></div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <div fxFlex="50">
            <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                <h4>
                    {{ 'SIMULATION.SCENARIO' | translate }}
                </h4>
                <mat-card class="pink-background">
                    <mat-card-header>
                        <mat-card-title class="text-white">
                            {{ 'SIMULATION.STAFF.NAME' | translate }}
                        </mat-card-title>
                        <div fxFlex></div>
                        <div fxLayoutAlign="center center">
                            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.INFO_TOOLTIP' | translate }}">info</mat-icon>
                        </div>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.scDoctorNumber"
                                    [formControl]="scDoctorNumberControl" maxlength="4"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                                    matNativeControl placeholder="{{ 'SIMULATION.STAFF.DOCTOR' | translate }}"
                                    matTooltip="{{ 'SIMULATION.STAFF.DOCTOR' | translate }}" required/>
                                <mat-error *ngIf="scDoctorNumberControl.invalid">{{ 'SIMULATION.STAFF.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.scGeneralNurseNumber"
                                    [formControl]="scGeneralNurseNumberControl" maxlength="4"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                                    matNativeControl placeholder="{{ 'SIMULATION.STAFF.GENERAL_NURSES' | translate }}"
                                    matTooltip="{{ 'SIMULATION.STAFF.GENERAL_NURSES' | translate }}" required/>
                                <mat-error *ngIf="scGeneralNurseNumberControl.invalid">{{ 'SIMULATION.STAFF.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.scHivSpecialistNurseNumber"
                                    [formControl]="scHivSpecialistNurseNumberControl" maxlength="4"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                                    matNativeControl placeholder="{{ 'SIMULATION.STAFF.HIV_NURSES' | translate }}"
                                    matTooltip="{{ 'SIMULATION.STAFF.HIV_NURSES' | translate }}" required/>
                                <mat-error *ngIf="scHivSpecialistNurseNumberControl.invalid">{{ 'SIMULATION.STAFF.MIN_MAX_ERROR' | translate }}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.scCounselorNumber"
                                    [formControl]="scCounselorNumberControl" maxlength="4"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                                    matNativeControl placeholder="{{ 'SIMULATION.STAFF.COUNSELLOR' | translate }}"
                                    matTooltip="{{ 'SIMULATION.STAFF.COUNSELLOR' | translate }}" required/>
                                <mat-error *ngIf="scCounselorNumberControl.invalid">{{ 'SIMULATION.STAFF.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.scDieticianNumber"
                                    [formControl]="scDieticianNumberControl" maxlength="4"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                                    matNativeControl placeholder="{{ 'SIMULATION.STAFF.DIETICIAN' | translate }}"
                                    matTooltip="{{ 'SIMULATION.STAFF.DIETICIAN' | translate }}" required/>
                                <mat-error *ngIf="scDieticianNumberControl.invalid">{{ 'SIMULATION.STAFF.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.scMedicalLabScientistNumber"
                                    [formControl]="scMedicalLabScientistNumberControl" maxlength="4"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                                    matNativeControl placeholder="{{ 'SIMULATION.STAFF.LAB' | translate }}"
                                    matTooltip="{{ 'SIMULATION.STAFF.LAB' | translate }}" required/>
                                <mat-error *ngIf="scMedicalLabScientistNumberControl.invalid">{{ 'SIMULATION.STAFF.MIN_MAX_ERROR' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="40">
                                <input currencyMask [(ngModel)]="data.scPharmacistNumber"
                                    [formControl]="scPharmacistNumberControl" maxlength="4"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '' }"
                                    matNativeControl placeholder="{{ 'SIMULATION.STAFF.PHARMACIST' | translate }}"
                                    matTooltip="{{ 'SIMULATION.STAFF.PHARMACIST' | translate }}" required/>
                                <mat-error *ngIf="scPharmacistNumberControl.invalid">{{ 'SIMULATION.STAFF.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                            <div fxFlex="40"></div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
        <div fxFlex="50">
            <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                <mat-card class="blue-background">
                    <mat-card-header>
                        <mat-card-title class="text-white">
                            {{ 'SIMULATION.STAFF.ALLOCATION' | translate }}
                        </mat-card-title>
                        <div fxFlex></div>
                        <div fxLayoutAlign="center center">
                            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.INFO_TOOLTIP_ALLOCATION' | translate }}">info
                            </mat-icon>
                        </div>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="60">
                                <input currencyMask [(ngModel)]="data.allocation"
                                    [formControl]="allocationControl" maxlength="4"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                    matNativeControl placeholder="{{ 'SIMULATION.STAFF.ALLOCATION_PERCENTAGE' | translate }}"
                                    matTooltip="{{ 'SIMULATION.STAFF.ALLOCATION_PERCENTAGE' | translate }}" required/>
                                <mat-error *ngIf="allocationControl.invalid">{{ 'SIMULATION.STAFF.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                            <div fxFlex="40"></div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <div fxFlex="50">
            <div fxLayout="column" fxLayoutAlign="space-around center" class="full-width">
                <mat-card class="pink-background">
                    <mat-card-header>
                        <mat-card-title class="text-white">
                            {{ 'SIMULATION.STAFF.ALLOCATION' | translate }}
                        </mat-card-title>
                        <div fxFlex></div>
                        <div fxLayoutAlign="center center">
                            <mat-icon matTooltip="{{ 'SIMULATION.STAFF.INFO_TOOLTIP_ALLOCATION' | translate }}">info
                            </mat-icon>
                        </div>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayout="row" fxLayoutAlign="space-around center" class="full-width">
                            <mat-form-field fxFlex="60">
                                <input currencyMask [(ngModel)]="data.scAllocation"
                                    [formControl]="scAllocationControl" maxlength="4"
                                    [options]="{ thousands: ',', precision: '0', align: 'left', allowNegative: false, prefix: '', suffix: '%' }"
                                    matNativeControl placeholder="{{ 'SIMULATION.STAFF.ALLOCATION_PERCENTAGE' | translate }}"
                                    matTooltip="{{ 'SIMULATION.STAFF.ALLOCATION_PERCENTAGE' | translate }}" required/>
                                <mat-error *ngIf="scAllocationControl.invalid">{{ 'SIMULATION.STAFF.MIN_MAX_ERROR' | translate }}</mat-error>
                            </mat-form-field>
                            <div fxFlex="40"></div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
    <span>{{ 'SIMULATION.REQUIRED' | translate }}</span>
</div>
<div mat-dialog-actions>
    <button mat-button color="primary" (click)="onNoClick()">{{ 'SIMULATION.CANCEL' | translate }}</button>
    <button mat-button [mat-dialog-close]="data" [disabled]="!isInfoValid()" cdkFocusInitial color="accent">{{ 'SIMULATION.OK' | translate }}</button>