<div class="content-box chart">
  <h3 class="content-box__title">{{ 'SUBSCRIPTION.PLAN.LIST.TITLE' | translate }}</h3>

    <div class="add-new-container">
        <button mat-raised-button color="primary" (click)="openDetailDialog(PageMode.CREATE, null)">{{'SUBSCRIPTION.PLAN.LIST.ADD_NEW' | translate}}</button>
    </div>
    <app-datatable
        [allColumnList]="allColumnList"  
        [columns]="columns" 
        [rows]="rows"
        [page]="page"
        (pageChanged)="onPageChanged($event)"
        (sortChanged)="onSortChanged($event)">
    </app-datatable>
</div>

<ng-template #activeTemplate let-row="row" let-value="value">
    <mat-checkbox [checked]="row.active" [disabled]="'true'"></mat-checkbox>
</ng-template>


<ng-template #rowActionButtonsTemplate let-row="row" let-value="value">
    <div class="d-flex">
        <a (click)="openDetailDialog(PageMode.VIEW, row)" class="m-r-10 m-l-5 cursor-pointer">
            <i class="fa fa-eye fa-lg text-info" title="{{'GENERAL.VIEW' | translate}}"></i>
        </a>
        <a (click)="openDetailDialog(PageMode.EDIT, row)" class="m-r-10 m-l-5 cursor-pointer">
            <i class="fa fa-pencil fa-lg text-warning" title="{{'GENERAL.EDIT' | translate}}"></i>
        </a>
        <a (click)="openDetailDialog(PageMode.DELETE, row)" class="m-r-10 m-l-5 cursor-pointer">
            <i class="fa fa-trash fa-lg text-danger" title="{{'GENERAL.DELETE' | translate}}"></i>
        </a>
    </div>

</ng-template>