/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/shared/component/base.component';
import { NotificationService } from 'src/app/shared/module/notification/service/notification.service';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PageMode } from 'src/app/shared/model/enum/page-mode';
import { AuditlyticsPermissionLevel } from 'src/app/shared/model/enum/auditlytics-permission-level';
import { City, Country, State } from 'src/app/shared/model/geo-location';
import { AuditlyticsPermission } from 'src/app/shared/model/auditlytics-permission';
import { GeoLocationService } from 'src/app/shared/services/geo-location.service';
import { AuditlyticsPermissionService } from 'src/app/shared/services/auditlytics-permission.service';
import { Role } from 'src/app/shared/model/enum/role';
import { UserSelectionDialogComponent } from 'src/app/shared/component/user-selection-dialog/user-selection-dialog.component';
import { OrganisationBranchSelectionDialogComponent } from 'src/app/shared/component/organisation-branch-selection-dialog/organisation-branch-selection-dialog.component';
@Component({
  selector: 'app-auditlytics-permission-detail',
  templateUrl: './auditlytics-permission-detail.component.html',
  styleUrls: ['./auditlytics-permission-detail.component.scss']
})
export class AuditlyticsPermissionDetailComponent extends BaseComponent implements OnInit {

  AuditlyticsPermissionLevel: typeof AuditlyticsPermissionLevel = AuditlyticsPermissionLevel;
  PageMode: typeof PageMode = PageMode;

  pageMode: PageMode;

  countryList: Country[];
  stateList: State[];
  cityList: City[];

  permissionLevelOptions: string[];

  auditlyticsPermission;
  orgasationBranchSelectionButtonEnable = true;

  formGroup: FormGroup = new FormGroup({
    userFullName: new FormControl({value: '', disabled: true}, [Validators.required, Validators.minLength(2)]),
    permissionLevel: new FormControl('', [Validators.required]),
    continent: new FormControl({value: '', disabled: true}),
    country: new FormControl({value: '', disabled: true}),
    state: new FormControl({value: '', disabled: true}),
    city: new FormControl({value: '', disabled: true}),
    organisationBranchIds: new FormControl({value: '', disabled: true})
  });

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AuditlyticsPermissionDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private geoLocationService: GeoLocationService,
    private auditlyticsPermissionService: AuditlyticsPermissionService
  ) {
    super();
  }

  ngOnInit(): void {
    const plOptions: string[] = Object.keys(AuditlyticsPermissionLevel);
    this.permissionLevelOptions = plOptions.slice(plOptions.length / 2);
    this.permissionLevelOptions = this.permissionLevelOptions.filter(p => p !== AuditlyticsPermissionLevel[AuditlyticsPermissionLevel.ORGANISATION]);

    if (this.data.row) {
      this.auditlyticsPermission = this.data.row;
    }
    this.pageMode = this.data.pageMode ? this.data.pageMode : PageMode.CREATE;

    if (this.pageMode === PageMode.CREATE) {
      this.auditlyticsPermission = new AuditlyticsPermission();
    }

    if (this.pageMode === PageMode.VIEW || this.pageMode === PageMode.DELETE) {
      this.formGroup.disable();
    }

    if (this.pageMode === PageMode.EDIT) {
      if (this.auditlyticsPermission.permissionLevel) {
        this.updateAllPermissionControlStatus(this.auditlyticsPermission.permissionLevel);
      }
    }

    this.patchForms(this.auditlyticsPermission);
    this.loadCountries();


    this.formGroup
    .get('permissionLevel')
    .valueChanges
    .subscribe((pl) => {
      this.updateAllPermissionControlStatus(pl);

    });

    this.formGroup
    .get('city')
    .valueChanges
    .subscribe((pl) => {
      this.formGroup.get('organisationBranchIds').setValue(null);

    });
  }

  updateAllPermissionControlStatus(pl) {
    const permissionLevels = [AuditlyticsPermissionLevel.GLOBAL,
        AuditlyticsPermissionLevel.CONTINENT,
        AuditlyticsPermissionLevel.COUNTRY,
        AuditlyticsPermissionLevel.STATE,
        AuditlyticsPermissionLevel.CITY,
        AuditlyticsPermissionLevel.ORGANISATION,
        AuditlyticsPermissionLevel.ORGANISATION_BRANCH];
    const activePermission = permissionLevels.find(l => AuditlyticsPermissionLevel[l] === pl);

    this.updateControlStatus('continent', AuditlyticsPermissionLevel.CONTINENT, activePermission);
    this.updateControlStatus('country', AuditlyticsPermissionLevel.COUNTRY, activePermission);
    this.updateControlStatus('state', AuditlyticsPermissionLevel.STATE, activePermission);
    this.updateControlStatus('city', AuditlyticsPermissionLevel.CITY, activePermission);
    this.updateControlStatus('organisationBranchIds', AuditlyticsPermissionLevel.ORGANISATION_BRANCH, activePermission, false);

    this.orgasationBranchSelectionButtonEnable = activePermission >= AuditlyticsPermissionLevel.ORGANISATION_BRANCH;

  }

  updateControlStatus(controlName, enumIndex, activePermission, canEnable = true) {
    if (activePermission >= enumIndex) {
      const control = this.formGroup.get(controlName);
      if (canEnable) {
        control.enable();
      }
      control.setValidators([Validators.required]);
      control.updateValueAndValidity();
    } else {
      const control = this.formGroup.get(controlName);
      control.setValue('');
      control.disable();
      control.clearValidators();
      control.updateValueAndValidity();
    }
  }

  onCreate(): void {
    if (this.formGroup.valid) {
     
      const rawVal = this.formGroup.getRawValue();

      const item = {...rawVal};

      const selectedUser = this.auditlyticsPermission.user;
      delete item.userFullName;
      item.organisationId = this.auditlyticsPermission.organisationId;

      if (!selectedUser?.id) {
        this.notificationService.error(this.translate.instant('AUDITLYTICS_PERMISSION.DETAIL.NOTIFICATION.VALIDATION.USER_REQUIRED'));
        return;
      }

      this.auditlyticsPermissionService.createAuditlyticsPermissionByAdmin(selectedUser.id, item).subscribe(data => {
        this.notificationService.success(this.translate.instant('AUDITLYTICS_PERMISSION.DETAIL.NOTIFICATION.CREATE.SUCCESS'));
        this.closeDialog(true);
      }, error => {
        console.log('Error on create auditlytics permission : ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('AUDITLYTICS_PERMISSION.DETAIL.NOTIFICATION.CREATE.FAIL'));
      });
    }
  }

  onEdit(): void {
    if (this.formGroup.valid) {
      const rawVal = this.formGroup.getRawValue();
      const item = {...rawVal};

      delete item.userFullName;
      item.organisationId = this.auditlyticsPermission.organisationId;

      if(!item.organisationBranchIds || item.organisationBranchIds === "") {
        item.organisationBranchIds = [];
      }
      this.auditlyticsPermissionService.updateAuditlyticsPermissionByAdmin(this.data.row.id, item).subscribe(data => {
        this.notificationService.success(this.translate.instant('AUDITLYTICS_PERMISSION.DETAIL.NOTIFICATION.EDIT.SUCCESS'));
        this.closeDialog(true);
      }, error => {
        console.log('Error on edit auditlytics permission : ' + JSON.stringify(error));
        this.notificationService.error(this.translate.instant('AUDITLYTICS_PERMISSION.DETAIL.NOTIFICATION.EDIT.FAIL'));
      });
    }
  }

  onDelete(): void {

    this.auditlyticsPermissionService.deleteAuditlyticsPermissionByAdmin(this.data.row.id).subscribe(() => {
      this.notificationService.success(this.translate.instant('AUDITLYTICS_PERMISSION.DETAIL.NOTIFICATION.DELETE.SUCCESS'));
      this.closeDialog(true);
    }, error => {
      console.log('Error on delete auditlytics permission : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('AUDITLYTICS_PERMISSION.DETAIL.NOTIFICATION.DELETE.FAIL'));
    });
  }

  closeDialog(isChanged: boolean) {
    this.dialogRef.close({status: 'dialog closed', refreshTable: isChanged});
  }

  patchForms(permission) {
    if (permission) {
      permission.userFullName = permission.user ? (permission.user.firstName + ' ' + permission.user.lastName) : '';

      if (permission.organisationBranches) {
        permission.organisationBranchIds = permission.organisationBranches.map(b => b.id);
        permission.organisationId = permission.organisationBranches[0].organisation?.id;
      }

      this.formGroup.patchValue(permission);
      this.auditlyticsPermission = permission;
    }
  }

  patchModel() {
    const rawVal = this.formGroup.getRawValue();
    const item = {...rawVal};
    this.auditlyticsPermission.permissionLevel = item.permissionLevel;
    this.auditlyticsPermission.continent = item.continent;
    this.auditlyticsPermission.country = item.country;
    this.auditlyticsPermission.state = item.state;
    this.auditlyticsPermission.city = item.city;

  }

  openUserSelectionDialog() {
    const dialogConfig = this.createDialogConfig({searchInAllUsers: true, role: Role.CLINICIAN, title: 'Select User' });
    const dialogRef = this.dialog.open(UserSelectionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data?.selectedRows) {
        this.patchModel();
        this.auditlyticsPermission.user = data.selectedRows[0];
        this.patchForms(this.auditlyticsPermission);
      }
    });
  }

  openOrganisationBranchSelectionDialog() {
    const rawVal = this.formGroup.getRawValue();
    const defaultFilter = {
      ciso: rawVal.country ? rawVal.country: '',
      siso: rawVal.state ? rawVal.state: '',
      city: rawVal.city? rawVal.city: ''
    };

    const dialogConfig = this.createDialogConfig({
      rowSelectionType: 'single',
      // defaultRowSelections: this.auditlyticsPermission.organisationBranchIds?.map(bid => ({id: bid})),
      defaultFilter
    });
    const dialogRef = this.dialog.open(OrganisationBranchSelectionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data?.selectedRows) {
        this.patchModel();
        this.auditlyticsPermission.organisationBranches = data.selectedRows;
        this.patchForms(this.auditlyticsPermission);
      }
    });
  }

  loadCountries() {
    const tempCountry = this.formGroup.get('country').value;

    this.geoLocationService.getCountries().subscribe(countryList => {
      this.countryList = countryList;
      if (tempCountry) {
        this.loadStates();
      }
    }, error => {
      console.log('Error on loading countries : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('GEO_LOCATION.NOTIFICATION.COUNTRY.LOAD_FAIL'));
    });
  }

  loadStates() {
    const tempCountry = this.formGroup.get('country').value;
    const tempState = this.formGroup.get('state').value;
    this.geoLocationService.getStatesOfCountry(tempCountry).subscribe(stateList => {
      this.stateList = stateList;
      const foundState = stateList.find(s => s.iso2 === tempState);
      if (!foundState) {
        this.formGroup.get('state').setValue(null);
        this.formGroup.get('city').setValue(null);
        this.formGroup.get('organisationBranchIds').setValue(null);
      } else {
        this.loadCities();
      }
    }, error => {
      console.log('Error on loading states : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('GEO_LOCATION.NOTIFICATION.STATE.LOAD_FAIL'));
    });
  }

  loadCities() {
    const tempCountry = this.formGroup.get('country').value;
    const tempState = this.formGroup.get('state').value;
    const tempCity = this.formGroup.get('city').value;

    this.geoLocationService.getCitiesOfState(tempCountry, tempState).subscribe(cityList => {
      this.cityList = cityList;
      const foundCity = cityList.find(c => c.name === tempCity);
      if (!foundCity) {
        this.formGroup.get('city').setValue(null);
        this.formGroup.get('organisationBranchIds').setValue(null);
      }
    }, error => {
      console.log('Error on loading cities : ' + JSON.stringify(error));
      this.notificationService.error(this.translate.instant('GEO_LOCATION.NOTIFICATION.CITY.LOAD_FAIL'));
    });
  }

}
