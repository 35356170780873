<mat-tab-group>
    <mat-tab label="Personnel Salaries with fringe benefits"> 
        <ng-container *ngIf="form">
            <form [formGroup]="form">
                <div fxLayout="row" fxLayoutAlign="space-around stretch" style="margin-top: 20px;">
                    <div fxFlex="20">
                        <mat-form-field appearance="fill">
                            <mat-label>Personnel Salaries</mat-label>
                            <mat-select formControlName="personnel" name="personnel">
                                <mat-option>None</mat-option>
                                <mat-option *ngFor="let personnel of personnels" [value]="personnel.value">
                                    {{personnel.viewValue}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="10">
                        <mat-form-field class="example-margin example-width" appearance="fill">
                            <mat-label>Needed</mat-label>
                            <input matInput type="number" formControlName="needed">
                        </mat-form-field>
                    </div>
                    <div fxFlex="12">
                        <mat-form-field class="example-margin example-width" appearance="fill">
                            <mat-label>Annual Salary</mat-label>
                            <input matInput type="number" formControlName="annualSalary">
                            <span matPrefix>£&nbsp;</span>
                        </mat-form-field>
                    </div>
                    <div fxFlex="10">
                        <mat-form-field appearance="fill">
                            <mat-label>Months</mat-label>
                            <mat-select formControlName="month" name="month">
                                <mat-option>None</mat-option>
                                <mat-option *ngFor="let month of months" [value]="month.value">
                                    {{month.viewValue}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="10">
                        <mat-form-field class="example-margin example-width" appearance="fill">
                            <mat-label>Time</mat-label>
                            <input matInput type="number" formControlName="time">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </div>
                    <div fxFlex="10">
                        <mat-form-field class="example-margin example-width" appearance="fill">
                            <mat-label>Fringe rate</mat-label>
                            <input matInput type="number" formControlName="fringeRate">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </div>
                    <div fxFlex="12">
                        <button mat-raised-button color="primary" (click)="addItems()">Add Item</button>
                    </div>
        
                </div>
            </form>
        
            <div fxLayout="row" fxLayoutAlign="space-around stretch">
                <table mat-table [dataSource]="dataSource" class="budget-table mat-elevation-z8">
        
                    <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
        
                    <!-- Position Column -->
                    <ng-container matColumnDef="budget-personnel">
                        <th mat-header-cell *matHeaderCellDef class="table-header"> Personnel </th>
                        <td mat-cell *matCellDef="let element" style="background-color: #FDEADA;"> 
                            <div style="width: 70px;" *ngIf="!element.edit">{{element.personnel}}</div>
                            <mat-select style="width: 70px;" *ngIf="element.edit" [(ngModel)]="element.personnel" name="personnel">
                                <mat-option>None</mat-option>
                                <mat-option *ngFor="let personnel of personnels" [value]="personnel.value">
                                    {{personnel.viewValue}}
                                </mat-option>
                            </mat-select>
                        </td>
                    </ng-container>
        
                    <!-- Name Column -->
                    <ng-container matColumnDef="budget-needed">
                        <th mat-header-cell *matHeaderCellDef class="table-header"> Number needed </th>
                        <td mat-cell *matCellDef="let element">
                            <div style="width: 70px;" *ngIf="!element.edit">{{element.needed}} </div>
                            <mat-form-field style="width: 70px;" *ngIf="element.edit" class="example-margin example-width" appearance="fill">
                                <input matInput type="number" [(ngModel)]="element.needed">
                            </mat-form-field>
                        </td>
                    </ng-container>
        
                    <!-- Weight Column -->
                    <ng-container matColumnDef="budget-annual-salary">
                        <th mat-header-cell *matHeaderCellDef class="table-header"> Annual salary </th>
                        <td mat-cell *matCellDef="let element"> 
                            <div style="width: 70px;" *ngIf="!element.edit">£ {{element.annualSalary}}</div>
                            <mat-form-field style="width: 70px;" *ngIf="element.edit" class="example-margin example-width" appearance="fill">
                                <input matInput type="number" [(ngModel)]="element.annualSalary">
                            </mat-form-field>
                        </td>
                    </ng-container>
        
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="budget-months">
                        <th mat-header-cell *matHeaderCellDef class="table-header"> Number of months </th>
                        <td mat-cell *matCellDef="let element"> 
                            <div style="width: 70px;" *ngIf="!element.edit">{{element.months}} </div>
                            <mat-select style="width: 70px;" *ngIf="element.edit" [(ngModel)]="element.months" name="month">
                                <mat-option>None</mat-option>
                                <mat-option *ngFor="let month of months" [value]="month.value">
                                    {{month.viewValue}}
                                </mat-option>
                            </mat-select>
                        </td>
                    </ng-container>
        
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="budget-time">
                        <th mat-header-cell *matHeaderCellDef class="table-header"> Time </th>
                        <td mat-cell *matCellDef="let element"> 
                            <div style="width: 70px;" *ngIf="!element.edit">{{element.time}} % </div>
                            <mat-form-field style="width: 70px;" *ngIf="element.edit" class="example-margin example-width" appearance="fill">
                                <input matInput type="number" [(ngModel)]="element.time">
                            </mat-form-field>
                        </td>
                    </ng-container>
        
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="budget-total-salary">
                        <th mat-header-cell *matHeaderCellDef class="table-header"> Salary Total </th>
                        <td mat-cell *matCellDef="let element"> £ {{element.totalSalary}} </td>
                    </ng-container>
        
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="budget-fringe-rate">
                        <th mat-header-cell *matHeaderCellDef class="table-header"> Fringe rate of salary </th>
                        <td mat-cell *matCellDef="let element"> 
                            <div style="width: 70px;" *ngIf="!element.edit">{{element.fringeRate}} % </div>
                            <mat-form-field style="width: 70px;" *ngIf="element.edit" class="example-margin example-width" appearance="fill">
                                <input matInput type="number" [(ngModel)]="element.fringeRate">
                            </mat-form-field>
                        </td>
                    </ng-container>
        
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="budget-fringe-total-salary">
                        <th mat-header-cell *matHeaderCellDef class="table-header"> Total salary and fringe benefits </th>
                        <td mat-cell *matCellDef="let element"> £ {{element.fringeTotalSalary}} </td>
                    </ng-container>
        
                    <ng-container matColumnDef="budget-action">
                        <th mat-header-cell *matHeaderCellDef class="table-header"> Action </th>
                        <td mat-cell *matCellDef="let element">
                            <button *ngIf="!element.edit" mat-icon-button matTooltip="Edit" color="primary" (click)="editItem(element)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button *ngIf="element.edit" mat-icon-button matTooltip="Update" color="primary" (click)="updateItem(element)">
                                <mat-icon>done</mat-icon>
                            </button>
                            <button *ngIf="!element.edit"mat-icon-button matTooltip="Delete"color="warn" (click)="deleteItem(element)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button *ngIf="element.edit" mat-icon-button matTooltip="Cancel" color="warn" (click)="cancelEdit(element)">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around stretch" class="subtotal-row">
                <div fxFlex="80">Subtotal: Personnel salaries and fringe benefits</div>
                <div fxFlex="20">£ {{subTotal}}</div>
            </div>
        </ng-container>    
    </mat-tab>
    <mat-tab label="Second"> Content 2 </mat-tab>
    <mat-tab label="Third"> Content 3 </mat-tab>
  </mat-tab-group>
