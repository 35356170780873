<div class="section-title-bar bg-primary justify-content-between border-radius-bottom-0">
    <h2 class="content-box__title font-size-2 mb-0">{{ 'SIMULATION.COST.CURRENT_SUMMARY' | translate }}</h2>
    <mat-icon matTooltip="{{ 'SIMULATION.COST.CURRENT_SUMMARY' | translate }}">info</mat-icon>
</div>

<div class="content-box blue-background service-demand-grid border-radius-top-0 mb-24">
    <div class="d-flex flex-column h-100p">
        <div class="content-box chart flex-grow-1 pink-background mb-24">
            <h3 class="content-box__title sub pl-0">{{ 'SIMULATION.CURRENT_PRACTICE' | translate }}</h3>
            <p class="font-size-2 font-weight-300 py-16">{{ 'SIMULATION.COST.TOTAL_COSTS' | translate }} = {{totalCost}}</p>
        </div>

        <div class="content-box chart flex-grow-1 pink-background">
            <h3 class="content-box__title sub pl-0">{{ 'SIMULATION.SCENARIO' | translate }}</h3>
            <p class="font-size-2 font-weight-300 py-16">{{ 'SIMULATION.COST.TOTAL_COSTS' | translate }} = {{totalCostSc}}</p>
        </div>
    </div>

    <div class="content-box chart px-0 pink-background">
        <h3 class="content-box__title sub">{{ 'SIMULATION.CURRENT_PRACTICE' | translate }}</h3>
        <div id="chartPieCost" class="d-flex flex-column" *ngIf="chartPieCostOptions">
            <apx-chart 
                [series]="chartPieCostOptions.series"
                [chart]="chartPieCostOptions.chart"
                [labels]="chartPieCostOptions.labels"
                [legend]="chartPieCostOptions.legend"
                [responsive]="chartPieCostOptions.responsive"
                [colors]="chartPieCostOptions.colors"
                [yaxis]="chartPieCostOptions.yaxis"
                [tooltip]="chartPieCostOptions.tooltip">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart px-0 pink-background">
        <h3 class="content-box__title sub">{{ 'SIMULATION.SCENARIO' | translate }}</h3>
        <div id="chartPieCostSc" class="d-flex flex-column" *ngIf="chartPieCostScOptions">
            <apx-chart 
                [series]="chartPieCostScOptions.series"
                [chart]="chartPieCostScOptions.chart"
                [labels]="chartPieCostScOptions.labels"
                [legend]="chartPieCostScOptions.legend"
                [responsive]="chartPieCostScOptions.responsive"
                [colors]="chartPieCostScOptions.colors"
                [yaxis]="chartPieCostScOptions.yaxis"
                [tooltip]="chartPieCostScOptions.tooltip">
            </apx-chart>
        </div>
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.COST.BY_PATIENT_TYPES' | translate }}</p>

<div class="outcomes-charts mb-24">
    <div>
        <div class="content-box chart">
            <div class="d-flex justify-content-between">
                <h3 class="content-box__title mb-0">{{ 'SIMULATION.COST.TOTAL_COSTS' | translate }}</h3>
                <mat-icon matTooltip="{{ 'SIMULATION.COST.TOTAL_COSTS' | translate }}">info</mat-icon>
            </div>
            <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.COST.ALL' | translate }}</p>

            <div id="chartTotal" class="d-flex flex-column" *ngIf="chartTotalOptions">
                <apx-chart [series]="chartTotalOptions.series" 
                    [chart]="chartTotalOptions.chart"
                    [dataLabels]="chartTotalOptions.dataLabels"
                    [plotOptions]="chartTotalOptions.plotOptions" 
                    [yaxis]="chartTotalOptions.yaxis"
                    [legend]="chartTotalOptions.legend" 
                    [fill]="chartTotalOptions.fill"
                    [stroke]="chartTotalOptions.stroke" 
                    [tooltip]="chartTotalOptions.tooltip"
                    [xaxis]="chartTotalOptions.xaxis" 
                    [grid]="chartTotalOptions.grid">
                </apx-chart>
            </div>
        </div>
        <div class="h-35"></div>
    </div>

    <div>
        <div class="content-box chart">
            <div class="d-flex justify-content-between">
                <h3 class="content-box__title mb-0">{{ 'SIMULATION.COST.TOTAL_COSTS' | translate }}</h3>
                <mat-icon matTooltip="{{ 'SIMULATION.COST.TOTAL_COSTS' | translate }}">info</mat-icon>
            </div>
            <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.COST.NAIVE_VS_EXP' | translate }}</p>

            <div id="chartTotalPatient" class="d-flex flex-column" *ngIf="chartTotalPatientOptions">
                <apx-chart [series]="chartTotalPatientOptions.series" 
                    [chart]="chartTotalPatientOptions.chart"
                    [dataLabels]="chartTotalPatientOptions.dataLabels"
                    [plotOptions]="chartTotalPatientOptions.plotOptions" 
                    [yaxis]="chartTotalPatientOptions.yaxis"
                    [legend]="chartTotalPatientOptions.legend" 
                    [fill]="chartTotalPatientOptions.fill"
                    [stroke]="chartTotalPatientOptions.stroke" 
                    [tooltip]="chartTotalPatientOptions.tooltip"
                    [xaxis]="chartTotalPatientOptions.xaxis" 
                    [grid]="chartTotalPatientOptions.grid">
                </apx-chart>
            </div>
        </div>
        <p class="subtitle-text mb-0">{{ 'SIMULATION.CP_SC' | translate }}</p>
    </div>

    <div>
        <div class="content-box chart">
            <div class="d-flex justify-content-between">
                <h3 class="content-box__title mb-0">{{ 'SIMULATION.COST.EXPENSE_TYPE_COSTS' | translate }}</h3>
                <mat-icon matTooltip="{{ 'SIMULATION.COST.EXPENSE_TYPE_COSTS' | translate }}">info</mat-icon>
            </div>
            <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.COST.EXPENSE_TYPE' | translate }}</p>

            <div id="chartTotalExpense" class="d-flex flex-column" *ngIf="chartTotalExpenseOptions">
                <apx-chart [series]="chartTotalExpenseOptions.series" 
                    [chart]="chartTotalExpenseOptions.chart"
                    [dataLabels]="chartTotalExpenseOptions.dataLabels"
                    [plotOptions]="chartTotalExpenseOptions.plotOptions" 
                    [yaxis]="chartTotalExpenseOptions.yaxis"
                    [legend]="chartTotalExpenseOptions.legend" 
                    [fill]="chartTotalExpenseOptions.fill"
                    [stroke]="chartTotalExpenseOptions.stroke" 
                    [tooltip]="chartTotalExpenseOptions.tooltip"
                    [xaxis]="chartTotalExpenseOptions.xaxis" 
                    [grid]="chartTotalExpenseOptions.grid">
                </apx-chart>
            </div>
        </div>
        <p class="subtitle-text mb-0">{{ 'SIMULATION.CP_SC' | translate }}</p>
    </div>

    <div>
        <div class="content-box chart">
            <div class="d-flex justify-content-between">
                <h3 class="content-box__title mb-0">{{ 'SIMULATION.COST.AGE_GROUP_COSTS' | translate }}</h3>
                <mat-icon matTooltip="{{ 'SIMULATION.COST.AGE_GROUP_COSTS' | translate }}">info</mat-icon>
            </div>
            <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.COST.AGE_GROUP' | translate }}</p>

            <div id="chartTotalAge" class="d-flex flex-column" *ngIf="chartTotalAgeOptions">
                <apx-chart [series]="chartTotalAgeOptions.series" 
                    [chart]="chartTotalAgeOptions.chart"
                    [dataLabels]="chartTotalAgeOptions.dataLabels"
                    [plotOptions]="chartTotalAgeOptions.plotOptions" 
                    [yaxis]="chartTotalAgeOptions.yaxis"
                    [legend]="chartTotalAgeOptions.legend" 
                    [fill]="chartTotalAgeOptions.fill"
                    [stroke]="chartTotalAgeOptions.stroke" 
                    [tooltip]="chartTotalAgeOptions.tooltip"
                    [xaxis]="chartTotalAgeOptions.xaxis" 
                    [grid]="chartTotalAgeOptions.grid">
                </apx-chart>
            </div>
        </div>
        <p class="subtitle-text mb-0">{{ 'SIMULATION.CP_SC' | translate }}</p>
    </div>
</div>

<p class="graph-header text-center mb-12">{{ 'SIMULATION.COST.BY_EXPENSE' | translate }}</p>

<div class="outcomes-charts">
    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.COST.FIRST_LINE' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.COST.FIRST_LINE' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.COST.EXPENSE' | translate }}</p>

        <div id="chartTotalFirst" class="d-flex flex-column" *ngIf="chartTotalFirstOptions">
            <apx-chart [series]="chartTotalFirstOptions.series" 
                [chart]="chartTotalFirstOptions.chart"
                [dataLabels]="chartTotalFirstOptions.dataLabels"
                [plotOptions]="chartTotalFirstOptions.plotOptions" 
                [yaxis]="chartTotalFirstOptions.yaxis"
                [legend]="chartTotalFirstOptions.legend" 
                [fill]="chartTotalFirstOptions.fill"
                [stroke]="chartTotalFirstOptions.stroke" 
                [tooltip]="chartTotalFirstOptions.tooltip"
                [xaxis]="chartTotalFirstOptions.xaxis" 
                [grid]="chartTotalFirstOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.COST.SECOND_LINE' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.COST.SECOND_LINE' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.COST.EXPENSE' | translate }}</p>

        <div id="chartTotalSecond" class="d-flex flex-column" *ngIf="chartTotalSecondOptions">
            <apx-chart [series]="chartTotalSecondOptions.series" 
                [chart]="chartTotalSecondOptions.chart"
                [dataLabels]="chartTotalSecondOptions.dataLabels"
                [plotOptions]="chartTotalSecondOptions.plotOptions" 
                [yaxis]="chartTotalSecondOptions.yaxis"
                [legend]="chartTotalSecondOptions.legend" 
                [fill]="chartTotalSecondOptions.fill"
                [stroke]="chartTotalSecondOptions.stroke" 
                [tooltip]="chartTotalSecondOptions.tooltip"
                [xaxis]="chartTotalSecondOptions.xaxis" 
                [grid]="chartTotalSecondOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.COST.LABORATORY' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.COST.LABORATORY' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.COST.EXPENSE' | translate }}</p>

        <div id="chartTotalLaboratory" class="d-flex flex-column" *ngIf="chartTotalLaboratoryOptions">
            <apx-chart [series]="chartTotalLaboratoryOptions.series" 
                [chart]="chartTotalLaboratoryOptions.chart"
                [dataLabels]="chartTotalLaboratoryOptions.dataLabels"
                [plotOptions]="chartTotalLaboratoryOptions.plotOptions" 
                [yaxis]="chartTotalLaboratoryOptions.yaxis"
                [legend]="chartTotalLaboratoryOptions.legend" 
                [fill]="chartTotalLaboratoryOptions.fill"
                [stroke]="chartTotalLaboratoryOptions.stroke" 
                [tooltip]="chartTotalLaboratoryOptions.tooltip"
                [xaxis]="chartTotalLaboratoryOptions.xaxis" 
                [grid]="chartTotalLaboratoryOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.COST.STAFF' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.COST.STAFF' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.COST.EXPENSE' | translate }}</p>

        <div id="chartTotalOverhead" class="d-flex flex-column" *ngIf="chartTotalOverheadOptions">
            <apx-chart [series]="chartTotalOverheadOptions.series" 
                [chart]="chartTotalOverheadOptions.chart"
                [dataLabels]="chartTotalOverheadOptions.dataLabels"
                [plotOptions]="chartTotalOverheadOptions.plotOptions" 
                [yaxis]="chartTotalOverheadOptions.yaxis"
                [legend]="chartTotalOverheadOptions.legend" 
                [fill]="chartTotalOverheadOptions.fill"
                [stroke]="chartTotalOverheadOptions.stroke" 
                [tooltip]="chartTotalOverheadOptions.tooltip"
                [xaxis]="chartTotalOverheadOptions.xaxis" 
                [grid]="chartTotalOverheadOptions.grid">
            </apx-chart>
        </div>
    </div>

    <div class="content-box chart">
        <div class="d-flex justify-content-between">
            <h3 class="content-box__title mb-0">{{ 'SIMULATION.COST.PREVENTION' | translate }}</h3>
            <mat-icon matTooltip="{{ 'SIMULATION.COST.PREVENTION' | translate }}">info</mat-icon>
        </div>
        <p class="font-size-1-4 font-weight-300 mb-16">{{ 'SIMULATION.COST.EXPENSE' | translate }}</p>

        <div id="chartTotalPrevention" class="d-flex flex-column" *ngIf="chartTotalPreventionOptions">
            <apx-chart [series]="chartTotalPreventionOptions.series" 
                [chart]="chartTotalPreventionOptions.chart"
                [dataLabels]="chartTotalPreventionOptions.dataLabels"
                [plotOptions]="chartTotalPreventionOptions.plotOptions" 
                [yaxis]="chartTotalPreventionOptions.yaxis"
                [legend]="chartTotalPreventionOptions.legend" 
                [fill]="chartTotalPreventionOptions.fill"
                [stroke]="chartTotalPreventionOptions.stroke" 
                [tooltip]="chartTotalPreventionOptions.tooltip"
                [xaxis]="chartTotalPreventionOptions.xaxis" 
                [grid]="chartTotalPreventionOptions.grid">
            </apx-chart>
        </div>
    </div>
</div>