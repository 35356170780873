import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExcelFileUploadResponse } from 'src/app/shared/model/file-upload-response';
import { FileUploadModel } from 'src/app/shared/module/file-upload/model/file-upload-model';
import { AdverseEventService } from 'src/app/shared/services/adverse-event.service';

@Component({
  selector: 'app-adverse-event-upload',
  templateUrl: './adverse-event-upload.component.html',
  styleUrls: ['./adverse-event-upload.component.scss']
})
export class AdverseEventUploadComponent implements OnInit {
  uploadedFileCount = 0;

  constructor(
    public dialogRef: MatDialogRef<AdverseEventUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adverseEventService: AdverseEventService
  ) { }

  ngOnInit(): void {
  }

  upload(uploadModel: FileUploadModel): void {
    uploadModel.inProgress = true;
    uploadModel.isCompleted = false;
    uploadModel.canRetry = false;

    uploadModel.sub = this.adverseEventService.uploadFile(uploadModel.file).subscribe((event: any) => {
      if (event.type === HttpEventType.UploadProgress) {
        uploadModel.progressPercentage = Math.floor(event.loaded * 100 / event.total);
        uploadModel.loadedSize = event.loaded;
        uploadModel.totalSize = event.total;
      } else if (event instanceof HttpResponse) {
        const res = event.body as ExcelFileUploadResponse;
        uploadModel.inProgress = false;
        uploadModel.isCompleted = true;
        uploadModel.canRetry = false;
        this.uploadedFileCount ++;
      }
    }, (error: any) => {
      if (uploadModel.sub) {
        uploadModel.sub.unsubscribe();
      }
      uploadModel.errorMessage = error.error.message ? error.error.message : 'File could not upload';
      uploadModel.inProgress = false;
      uploadModel.isCompleted = true;
      uploadModel.canRetry = true;
      this.uploadedFileCount ++;
    });
  }

  closeDialog() {
    this.dialogRef.close({status: 'dialog closed', uploadedFileCount: this.uploadedFileCount});
  }


}
