import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit, OnDestroy {

  @Input() label = 'Search';
  @Input() hint = 'Search';

  @Output() searchTermChanged = new EventEmitter<string | undefined>();

  private readonly searchSubject = new Subject<string | undefined>();


  constructor() { }

  ngOnInit(): void {
    this.searchSubject
    .pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((text) => {
      this.searchTermChanged.emit(text);
    });
  }

  ngOnDestroy(): void {
    this.searchSubject.unsubscribe();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchSubject.next(filterValue?.trim());
  }

}
